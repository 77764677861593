import { ISortOrder } from "../../angular-common";
import { LongNameShortName } from "../../angular-common/longnameshortname/longname-shortname";
import { ILongNameShortName } from "../../angular-common/longnameshortname/longname-shortname.interface";
import { ElementTypeDto } from "../models/dto/ElementType-dto";
import { ExogenousVariableDto } from "../models/dto/ExogenousVariableDto-dto";
import { ModelElementDto } from "../models/dto/ModelElementDto-dto";
import { ModelElementOptionDto } from "../models/dto/ModelElementOptionDto-dto";
import { ModelElementSpecificationOption } from "./modelelementsspecification-option";

export class ModelElementSpecification implements ILongNameShortName, ISortOrder {
  public constructor() {}

  public keyId: number;
  public shortName: string;
  public longName: string;
  public elementType: ElementTypeDto;
  public dataSetId: number;
  public options: ModelElementSpecificationOption[];
  public sortOrder: number;

  public get displayName(): string {
    return LongNameShortName.NameFor(this);
  }

  public copyFromDto(dto: ModelElementDto) {
    this.shortName = dto.ShortName;
    this.longName = dto.LongName;
    this.elementType = dto.TypeOfElement;
    if (this.elementType === ElementTypeDto.Exogenous) {
      const dataSetId = (<ExogenousVariableDto>dto).DataSetId;
      if (dataSetId) {
        this.dataSetId = dataSetId;
      }
    }
    this.sortOrder = dto.SortOrder;
    this.keyId = dto.KeyId;

    const newOptions: ModelElementSpecificationOption[] = [];
    dto.Options.forEach((o) => {
      const newOption = new ModelElementSpecificationOption();
      newOption.copyFromDto(o);
      newOptions.push(newOption);
    });

    this.options = newOptions;
  }

  public toDto(element: ModelElementSpecification): ModelElementDto {
    const dto = new ModelElementDto();
    dto.KeyId = element.keyId;
    dto.ShortName = element.shortName;
    dto.LongName = element.longName;

    const options: ModelElementOptionDto[] = [];
    element.options.forEach((o) => {
      options.push(ModelElementSpecificationOption.toDto(o));
    });

    dto.Options = options;
    dto.TypeOfElement = element.elementType;
    (<any>dto).DataSetId = element.dataSetId;
    dto.SortOrder = element.sortOrder;

    return dto;
  }

  public static filterAvailableOptions(usedOptions: ModelElementSpecification[], allOptions: ModelElementSpecification[]): ModelElementSpecification[] {
    if (allOptions === undefined) {
      return [];
    }

    const options = allOptions || [];
    const available: ModelElementSpecification[] = [];

    options.forEach((option) => {
      const o = usedOptions.find((element) => element.shortName === option.shortName);
      if (o === undefined) {
        available.push(option);
      }
    });
    return available;
  }
}
