import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { CMSService } from "../cms/cms.service";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";
import { EbsOverviewData } from "./ebs-overview/ebs-overview-data";
import { EbsChartItem } from "./_models/ebs-chart-item.model";
import { EbsResult } from "./_models/ebs-result.model";

export class EbsDoughnutCreator {
  static createOverviewDto(ebs: EbsResult, selectLanguage: PlLanguageSelection, cmsService: CMSService) {
    const palette = new Array<string>();
    const groupsForChart = new Array<EbsChartItem>();

    for (const group of ebs.groups) {
      if (group.value.appliesToTotal) {
        const chartItem = new EbsChartItem(selectLanguage, group.id, group._title, group.value.valueAsNumber);
        groupsForChart.push(chartItem);
        if (group.value.valueAsNumber) {
          palette.push(group.color);
        }
      }
    }
    let ebsOverviewDto: EbsOverviewData;

    ebsOverviewDto = new EbsOverviewData(ebs, palette, groupsForChart, null, null);

    if (ebs.cmsMessageId && ebs.cmsMessageId >= 0) {
      cmsService.getMessage(MessageTypeDto.ebsintro, ebs.cmsMessageId).subscribe((cmsMessage) => {
        if (cmsMessage && cmsMessage !== null) {
          ebsOverviewDto.cmsIntro = cmsMessage.summary;
          ebsOverviewDto.cmsTitel = cmsMessage.title;
        }
      });
    }
    return ebsOverviewDto;
  }
}
