import { ModelElementOptionDto } from "../../models/dto/ModelElementOptionDto-dto";

export class ModelElementOption {
  public static toDto(option: ModelElementOption): ModelElementOptionDto {
    const newOption = new ModelElementOptionDto();
    newOption.Id = option.id;
    newOption.Text = option.text;
    return newOption;
  }
  public constructor(public id: number, public text: string) {}

  public copyToDto(): ModelElementOptionDto {
    const result = new ModelElementOptionDto();
    result.Id = this.id;
    result.Text = this.text;
    return result;
  }
}
