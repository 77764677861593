import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from "ngx-image-cropper";

import { AspectRatio } from "./aspect-ratio.enum";
import { WebLanguage } from "../../../common/language/weblanguage.service";

@Component({
  selector: "app-pl-image-cropper",
  templateUrl: "image-cropper.component.html",
  styleUrls: ["image-cropper.component.scss"],
})
export class PlImageCropperComponent {
  constructor(public language: WebLanguage) {}

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  imageChangedEvent: any = "";
  croppedImage: any = "";
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  maintainAspectRatio = true;
  transform: ImageTransform = {};

  private _aspectRatio: AspectRatio = AspectRatio.WIDE;
  @Input() public set aspectRatio(value: AspectRatio) {
    if (value === AspectRatio.FREE) {
      this.maintainAspectRatio = false;
    }
    this._aspectRatio = value;
  }
  public get aspectRatio() {
    return this._aspectRatio;
  }

  @Input() imageUrl: any;
  @Output() public cropperData = new EventEmitter<string>(true);

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {
    console.log("Load failed");
  }

  public save() {
    if (this.croppedImage) {
      this.cropperData.emit(this.croppedImage);
    }
  }
}
