import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { EbsLanguage } from "../../ebs/language/ebslanguage.service";
import { GroupOptionsDto } from "../../models/cms/dto/GroupOptions-dto";
import { EbsGroupInformationDto } from "../../models/ebs/dto/EbsGroupInformationDto-dto";

export class EbsGroupInformation {
  public static applyDefaults(languageService: EbsLanguage, selectLanguage: PlLanguageSelection) {
    const newNode = new EbsGroupInformation();
    newNode.additionalModelElementHideValue = false;
    newNode.additionalModelElementShortName = "";
    newNode.cmsGroupId = -1;
    newNode.color = null;
    newNode.linkId = -1;
    newNode.modelElementAppliesToTotal = false;
    newNode.modelElementHideValue = false;
    newNode.modelElementShortName = "";
    newNode.options = GroupOptionsDto.None;
    newNode.parentId = -1;
    newNode.sortOrder = -1;
    newNode.visibilityModelElementShortName = "";
    newNode.selectLanguage = selectLanguage;
    newNode.messageTitle = languageService.getEntry("f3MeWebApiNoGroup");

    return newNode;
  }

  public constructor() {}

  public toDto(values: EbsGroupInformation): EbsGroupInformationDto {
    const dto = new EbsGroupInformationDto();
    dto.AdditionalModelElementShortName = values.additionalModelElementShortName;
    dto.CmsGroupId = values.cmsGroupId;
    dto.Color = values.color;
    dto.LinkId = values.linkId;
    dto.MessageTitle = values.messageTitle;
    dto.ModelElementAppliesToTotal = values.modelElementAppliesToTotal;
    dto.ModelElementHideValue = values.modelElementHideValue;
    dto.ModelElementShortName = values.modelElementShortName;
    dto.Options = values.options;
    dto.ParentId = values.parentId;
    dto.SortOrder = values.sortOrder;
    dto.VisibilityModelElementShortName = values.visibilityModelElementShortName;
    dto.AdditionalModelElementHideValue = values.additionalModelElementHideValue;

    return dto;
  }

  public fromDto(dto: EbsGroupInformationDto, selectLanguage: PlLanguageSelection) {
    this.selectLanguage = selectLanguage;
    this.color = dto.Color;
    this.linkId = dto.LinkId;
    this.additionalModelElementHideValue = dto.AdditionalModelElementHideValue;
    this.additionalModelElementShortName = dto.AdditionalModelElementShortName;
    this.cmsGroupId = dto.CmsGroupId;
    this.messageTitle = dto.MessageTitle;
    this.modelElementAppliesToTotal = dto.ModelElementAppliesToTotal;
    this.modelElementHideValue = dto.ModelElementHideValue;
    this.modelElementShortName = dto.ModelElementShortName;
    this.options = dto.Options;
    this.parentId = dto.ParentId;
    this.sortOrder = dto.SortOrder;
    this.visibilityModelElementShortName = dto.VisibilityModelElementShortName;
  }

  public get title(): string {
    return this.selectLanguage.currentValueFor(this.messageTitle);
  }

  private selectLanguage: PlLanguageSelection;
  public modelElementShortName: string;
  public sortOrder: number;
  public additionalModelElementShortName: string;
  public visibilityModelElementShortName: string;
  public linkId: number;
  public options: GroupOptionsDto;
  public color: string;
  public cmsGroupId: number;
  public modelElementAppliesToTotal: boolean;
  public modelElementHideValue: boolean;
  public additionalModelElementHideValue: boolean;
  public parentId: number;
  public messageTitle: LanguageItem;
}
