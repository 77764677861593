import { NgModule } from "@angular/core";
import { LabourConditionLinkService } from "./labourcondition-link.service";

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [LabourConditionLinkService],
})
export class LabourConditionLinkModule {}
