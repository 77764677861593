import { BenefitGroupDto } from "../../models/ebs/dto/BenefitGroup-dto";
import { BenefitValue } from "./benefit-value.model";
import { ISortOrder } from "../../../angular-common";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { Link } from "../../components/links/link";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class BenefitItem implements ISortOrder {
  public constructor(private selectLanguage: PlLanguageSelection) {
    this.link = new Link(selectLanguage);
  }

  private _title: LanguageItem;
  public link: Link;
  public sortOrder: number;
  public value: BenefitValue;
  public additionalValue: BenefitValue;

  public get title(): string {
    return this.selectLanguage.currentValueFor(this._title);
  }

  public copyFromDTO(dto: BenefitGroupDto) {
    this._title = dto.Title;
    this.sortOrder = dto.SortOrder;
    this.value = new BenefitValue(dto.Value.ValueAsText, dto.Value.ValueAsNumber, dto.Value.AppliesToTotal, dto.Value.Visible);
    this.additionalValue = new BenefitValue(dto.AdditionalValue.ValueAsText, dto.AdditionalValue.ValueAsNumber, dto.AdditionalValue.AppliesToTotal, dto.AdditionalValue.Visible);
    this.link.copyFromDTO(dto.Link);
  }
}
