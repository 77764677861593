import { DataTypeDto } from './DataType-dto';
import { ModelElementOptionDto } from './ModelElementOptionDto-dto';
import { ModelPresentableElementDto } from './ModelPresentableElement-dto';

// t4tsCodeFromCs": "0.8.2.0 for file ModelElementDto.cs
// Don't adjust manually!

export class ModelElementDto extends ModelPresentableElementDto {
    public ReferenceCategoryId: number;
    public ModelCategoryId: number;
    public DataType: DataTypeDto;
    public Options: ModelElementOptionDto [] = [];
}

