import { DxButtonModule, DxPopupModule, DxScrollViewModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SafeDataDirectiveModule } from "../../../../../angular-common/utils/safe-data-directive-module";
import { LabourConditionDetailWithChoicesComponent } from "../../../../../employee-common/choices/labourcondtition-detail/labour-detail-with-choices.component";
import { ChoicesModule } from "../../../../../employee-common/choices/targets/choices.module";
import { AppComponentSpecificViewModule } from "../../../../../employee-common/components/appcomponentspecific-content/appcomponentspecific-view-module";
import { LinkPopupComponent } from "../../../../../employee-common/components/linkpopup/linkpopup.component";
import { EmailModule } from "../../../../../employee-common/email/consume-mail/email-module";
import { LabourConditionDetailMessageComponent } from "../../../../../employee-common/labourconditions/labourcondtition-detail/labour-detail-message.component";
import { LabourConditionDetailWithoutChoicesComponent } from "../../../../../employee-common/labourconditions/labourcondtition-detail/labour-detail-without-choices.component";
import { InspireCmsLinkComponent } from "./inspire-cms-link.component";

@NgModule({
  imports: [DxButtonModule, CommonModule, DxPopupModule, DxScrollViewModule, EmailModule, ChoicesModule, AppComponentSpecificViewModule, SafeDataDirectiveModule],
  exports: [InspireCmsLinkComponent, LinkPopupComponent, LabourConditionDetailMessageComponent, LabourConditionDetailWithChoicesComponent, LabourConditionDetailWithoutChoicesComponent],
  declarations: [InspireCmsLinkComponent, LinkPopupComponent, LabourConditionDetailMessageComponent, LabourConditionDetailWithChoicesComponent, LabourConditionDetailWithoutChoicesComponent],
})
export class InspireCmsLinkModule {}
