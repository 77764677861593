import { LanguageMetaDataDto } from './LanguageMetaData-dto';

// t4tsCodeFromCs": "0.8.2.0 for file LanguageMetaDatas.cs
// Don't adjust manually!

export class LanguageMetaDatasDto {
    public Language: string;
    public Languages: LanguageMetaDataDto [] = [];
}

