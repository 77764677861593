<dx-button *ngIf="template" icon="far fa-paper-plane" [text]="buttonTitle" [hint]="hintText" (click)="showPopup()"></dx-button>
<dx-popup [showTitle]="true" [title]="language.f3MeWebApiEmailCaption" [(visible)]="isVisible" [height]="430" [width]="550">
  <div *dxTemplate="let data of 'content'" style="height: 100%; width: 100%">
    <div style="height: 100%; width: 100%">
      <label>{{ language.f3MeWebApiEmailHeader }}</label>
      <br />
      <label>{{ subject }}</label>
      <br />
      <div class="email-body-preview">
        <app-htmleditor [(text)]="body" [disabled]="disableHtmlEditor" [showToolbar]="false"></app-htmleditor>
      </div>
      <br />
      <span class="closebuttonarea">
        <dx-button [text]="language.ButtonOk" (click)="sendEmail()" icon="check"></dx-button>
        <dx-button [text]="language.ButtonCancel" (click)="closePopup()" icon="remove"></dx-button>
      </span>
    </div>
  </div>
</dx-popup>
