import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { LoginWithDisclaimerComponent } from "./login-with-disclaimer.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: CommonRoutesDto.Login,
        component: LoginWithDisclaimerComponent,
        data: [{ menuType: MenuItemTypesDto.Login }],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
