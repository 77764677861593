import { NgModule, Type } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { CmsFeaturesModel } from "../../cms/cms-features-model";
import { CMSService } from "../../cms/cms.service";
import { CmsLinkModule } from "../../cms/links/cms-link.module";
import { AppComponentSpecificComponentFactory } from "../../components/appcomponentspecific-content/appcomponentspecific-component-factory";
import { AppComponentSpecificSpecification } from "../../components/appcomponentspecific-content/appcomponentspecific-component-specification";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { AppComponentSpecificViewModule } from "../../components/appcomponentspecific-content/appcomponentspecific-view-module";
import { EmailModule } from "../../email/consume-mail/email-module";
import { CmsRoutesDto } from "../../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { LabourConditionDetailWithoutChoicesComponent } from "./labour-detail-without-choices.component";
import { LabourDetailScreenComponent } from "./labourcondition-detail-route/labour-detail-screen.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.LabourConditionDetail + "/:id",
    component: LabourDetailScreenComponent,
    data: [
      {
        menuType: MenuItemTypesDto.MainMenu,
        inheritRolesFrom: CmsRoutesDto.LabourConditions,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: CmsRoutesDto.LabourConditionDetail + "/:id/:targetid",
    component: LabourDetailScreenComponent,
    data: [
      {
        menuType: MenuItemTypesDto.MainMenu,
        inheritRolesFrom: CmsRoutesDto.LabourConditions,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [LabourDetailScreenComponent],
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, HttpClientModule, EmailModule, CmsLinkModule, AppComponentSpecificViewModule],
  providers: [CMSService],
  exports: [LabourDetailScreenComponent],
})
export class LabourConditionDetailModule {
  constructor() {
    var features = new CmsFeaturesModel();
    if (features.choices.enabled) {
      // When choices is enabled, the choices module will load additional information.
    } else {
      LabourConditionDetailModule.registerDetailClass(LabourConditionDetailWithoutChoicesComponent);
    }
  }

  public static registerDetailClass(componentClass: Type<any>) {
    AppComponentSpecificComponentFactory.registerComponent(new AppComponentSpecificSpecification(AppComponentSpecificContentType.LabourConditionDetail, componentClass));
  }
}
