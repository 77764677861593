import { Component, Input } from "@angular/core";

import { AspectRatio } from "../../angular-common/components/image-cropper/aspect-ratio.enum";
import { CmsLanguage } from "../cms/language/cmslanguage.service";
import { EditToggle } from "../components/edittoggle/edittoggle";
import { ImageChanges } from "../components/images/image-changes.model";
import { ImageService } from "../components/images/image.service";
import { FixedImageModel } from "./fixedimage.model";

@Component({
  selector: "app-fixed-image-edit",
  templateUrl: "./fixedimage-edit.component.html",
  styleUrls: ["./fixedimage-edit.component.scss"],
  providers: [],
})
export class FixedImageEditComponent {
  public constructor(public languageService: CmsLanguage, public imageService: ImageService) {
    this.editToggle = new EditToggle();
    this.editToggle.stopEditing.subscribe((save) => {
      if (save && this.imageChanges.uploaded) {
        this.imageService.replaceFixedImage(this.imageChanges).subscribe((imageUrl: string) => {
          //
        });
      } else {
        this.reset();
      }
    });
  }

  public aspectRatio = AspectRatio.FREE;

  @Input()
  public get location(): FixedImageModel {
    return this._location;
  }
  public set location(newLocation: FixedImageModel) {
    this._location = newLocation;
    this.reset();
  }
  private _location: FixedImageModel;

  @Input() public title: string;

  private _imageChanges: ImageChanges;
  public get imageChanges() {
    return this._imageChanges;
  }

  public editToggle: EditToggle;

  private reset() {
    this._imageChanges = new ImageChanges(this.location.originalUri, this.location.protectedUri);
  }
}
