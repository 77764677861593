<div class="editMessageBoard">
  <app-language-component (languageChange)="languageChanged($event)"></app-language-component>
  <div class="messageProperties">
    <input class="textinput" [(ngModel)]="message.newsTitle[currentLanguage]" placeholder="{{ message.newsTitle[currentLanguage] }}" />
    <dx-date-box class="dx-datebox" [(value)]="message.date" type="date" *ngIf="message.hasStartDate"></dx-date-box>
  </div>
  <div class="imageeditor">
    <app-cms-image-edit-edit-component [aspectRatio]="aspectRatio" *ngIf="message.canHaveImage" [canDelete]="message.canDelete" [imageChanges]="imageChanges"> </app-cms-image-edit-edit-component>
  </div>

  <div class="newseditor" *ngIf="message.canAdjustSummary && !message.canAdjustContent">
    <app-htmleditor [text]="htmlText" (textChange)="setHtmlText($event)"> </app-htmleditor>
  </div>
  <div class="editor" *ngIf="message.canAdjustSummary && message.canAdjustContent">
    <dx-tabs
      [width]="500"
      [dataSource]="[
        { id: 0, text: language.f3MeWebApiCMSMessageSummary },
        { id: 1, text: language.f3MeWebApiCMSMessageContent }
      ]"
      [(selectedIndex)]="selectedTabIndex"
    >
    </dx-tabs>
    <app-htmleditor [class]="additonalMessageClass" [text]="htmlText" (textChange)="setHtmlText($event)"> </app-htmleditor>
  </div>

  <div class="messageAdditionalProperties" *ngIf="message.canHaveLink">
    <app-cms-link-edit-edit-component [link]="message.link" [addLinkToSelf]="message.isLabourCondition && message.isNew" [currentLanguage]="currentLanguage"> </app-cms-link-edit-edit-component>

    <span class="additionalPropertyInput" *ngIf="allowVisibilityEdit">
      <div class="inputlineheader">
        <label>{{ language.f3MeWebApiCMSMessageVisibility }}</label>
      </div>
      <input class="textinput" [(ngModel)]="message.visibilityExpression" [placeholder]="language.f3MeWebApiCMSMessageVisibilityPlaceHolder" />
    </span>

    <!-- Labour condition group -->
    <div class="groupinputs" *ngIf="message.isLabourCondition">
      <span class="groupinputline">
        <div class="inputlineheader">
          <label>{{ language.f3MeWebApiCMSMessageGroup }}</label>
        </div>
        <select class="selectinput additionalPropertyInput" [(ngModel)]="selectedGroup">
          <option *ngFor="let gr of groups" [ngValue]="gr">{{ gr.title }}</option>
        </select>
      </span>
    </div>
  </div>
</div>
