<div class="buttons-bottom-margin align-right">
  <dx-button [disabled]="!isEnabled" id="saveButton" icon="save" [hint]="language.ButtonSave" (onClick)="saveChanges()"> </dx-button>
  <dx-button [disabled]="!isEnabled" id="undoButton" icon="undo" [hint]="language.Undo" (onClick)="undoChanges()"> </dx-button>
</div>

<app-cardview [title]="title" *ngIf="rolesCopy">
  <dx-tree-view
    showCheckBoxesMode="normal"
    [dataSource]="rolesCopy"
    [searchEnabled]="true"
    searchMode="contains"
    [selectNodesRecursive]="true"
    displayExpr="role.name"
    itemsExpr="Items"
    [disabled]="!isEnabled"
    itemTemplate="roleTemplate"
    keyExpr="id"
  >
    <div *dxTemplate="let item of 'roleTemplate'">
      <div class="role-data-container">
        <div class="role-item-content">{{ item.role.name }}</div>
        <div class="role-item-label">{{language.authorizationRolesMaintenanceValidFrom}}</div>
        <div class="role-item-content">
          <dx-date-box [(value)]="item.role.from" [showClearButton]="true" [dateSerializationFormat]="dateSerializationFormat" [disabled]="userHasRoleAssigned(item.role.id) === false"> </dx-date-box>
        </div>
        <div class="role-item-label">{{language.authorizationRolesMaintenanceValidUntil}}</div>
        <div class="role-item-content">
          <dx-date-box [(value)]="item.role.until" [showClearButton]="true" [dateSerializationFormat]="dateSerializationFormat" [disabled]="userHasRoleAssigned(item.role.id) === false"> </dx-date-box>
        </div>
      </div>
    </div>
  </dx-tree-view>
</app-cardview>
