import { LanguageEditItemDTO } from "../../../angular-common/language/language-dto.model";

export class LanguageEditItemBase {
  public constructor() {
    this.editable = true;
    this.supportsHtml = false;
  }

  public editable: boolean;
  public id: string;
  public descriptionId: string;
  public supportsHtml: boolean;

  public copyFrom(other: LanguageEditItemBase) {
    this.id = other.id;
    this.descriptionId = other.descriptionId;
    this.editable = other.editable;
    this.supportsHtml = other.supportsHtml;
  }

  public copyFromDto(dto: LanguageEditItemDTO) {
    this.editable = dto.Editable;
    this.descriptionId = dto.DescriptionID;
    this.id = dto.ID;
    this.supportsHtml = dto.SupportsHtml;
  }

  public toDto(): LanguageEditItemDTO {
    const result = new LanguageEditItemDTO();
    result.SupportsHtml = this.supportsHtml;
    result.ID = this.id;
    result.Editable = this.editable;
    result.DescriptionID = this.descriptionId;
    return result;
  }
}
