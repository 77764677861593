import { LongNameShortName } from "../longnameshortname/longname-shortname";
import { ILongNameShortName } from "../longnameshortname/longname-shortname.interface";
import { ISortOrder } from "./sortorder";

export class Sorter {
  public static compareSortOrder(a: ISortOrder, b: ISortOrder): number {
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }

    return 0;
  }

  private static compareText(a: string, b: string) {
    const aIsNull = !a || a === null;
    const bIsNull = !b || b === null;

    if (aIsNull && bIsNull) {
      return 0;
    } else if (bIsNull) {
      return 1;
    } else if (aIsNull) {
      return -1;
    }

    return a.localeCompare(b);
  }

  public static sortOfDisplayName<T extends ILongNameShortName>(arrayToSort: T[]) {
    if (arrayToSort === undefined) return;

    return this.sortAlphabetically(arrayToSort, (i) => LongNameShortName.NameFor(i));
  }

  public static sortAlphabetically<T>(arrayToSort: T[], getTextProp: (inst: T) => string) {
    if (arrayToSort === undefined) return;

    arrayToSort.sort((a, b) => {
      return Sorter.compareText(getTextProp(a), getTextProp(b));
    });
  }

  public static sortSortOrderThenAlpha<T extends ISortOrder>(arrayToSort: T[], getTextProp: (inst: T) => string) {
    if (arrayToSort === undefined) return;

    arrayToSort.sort((a, b) => {
      let result = Sorter.compareSortOrder(a, b);
      if (result === 0) {
        result = Sorter.compareText(getTextProp(a), getTextProp(b));
      }
      return result;
    });
  }
}
