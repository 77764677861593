import { NavigationEnd, NavigationStart, Router, RoutesRecognized } from "@angular/router";

import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";

@Injectable()
export class MenuTypeProvider {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._navigatingTo = MenuItemTypesDto.Unknown;
      }
      if (event instanceof NavigationEnd) {
        if (this._navigatingTo !== MenuItemTypesDto.Unknown) {
          this.currentMenuItemType = this._navigatingTo;
          this._menuItemTypeSource.next(this._navigatingTo);
        } else {
          console.log("Navigation did not find main route", event);
        }
      }
      if (event instanceof RoutesRecognized) {
        let result = MenuItemTypesDto.Unknown;
        if (event.state.root.children.length > 0) {
          const dataForChild = event.state.root.children[0];
          const data = dataForChild.data[0];
          if (data) {
            const menuType = data["menuType"];
            if (menuType) {
              result = menuType as number;
            }
          }
        }

        this._navigatingTo = result;
      }
    });
  }

  private _navigatingTo: MenuItemTypesDto;

  private _menuItemTypeSource = new BehaviorSubject<MenuItemTypesDto>(MenuItemTypesDto.Unknown);
  public menuItemType = this._menuItemTypeSource.asObservable();

  public currentMenuItemType: MenuItemTypesDto = MenuItemTypesDto.Unknown;
}
