import { ColumnItemDto } from "../../../common/models/dto/ColumnItem-dto";
import { DataRows } from "../../../f3Me/src/app/approvetargets/_model/data-rows";
import { SmartFilterDto } from "../../models/choices/dto/SmartFilter-dto";
import { EmployeeDataForGridDto } from "../../models/cms/dto/EmployeeDataForGrid-dto";

export class EmployeeDataForGrid {
  public constructor() {}

  // We use the DTO objects here because this information is readonly anyway.
  public columns: ColumnItemDto[] = [];
  public filter: SmartFilterDto = new SmartFilterDto();

  public data: DataRows = new DataRows();

  public copyFromDTO(dto: EmployeeDataForGridDto) {
    this.columns = dto.Columns;
    this.data.copyFromDTO(dto.Rows);
  }
}
