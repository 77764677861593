import { Component, OnInit } from "@angular/core";

import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { PaginatedDxData } from "../../../angular-common/paginated-dx-data";
import { CmsLanguage } from "../language/cmslanguage.service";
import { LanguageEditManagementService } from "../languageedit-management/languagedit-management.service";
import { LanguageEditItemRow } from "../languageedit-management/models/language-edit-item-row.model";

@Component({
  selector: "app-manage-languageedit",
  templateUrl: "./manage-languageedit.component.html",
  styleUrls: ["./manage-languageedit.component.scss"],
  providers: [LanguageEditManagementService],
})
export class LanguageEditManagementComponent implements OnInit {
  constructor(public language: CmsLanguage, private languageEditManagementService: LanguageEditManagementService) {}

  ngOnInit(): void {
    this.languageEditManagementService.getEditItems().subscribe((e) => {
      const newRows = [];
      e.forEach((dto) => {
        const newRow = LanguageEditItemRow.createFor(dto);
        newRows.push(newRow);
      });
      this.rows = newRows;
    });

    const newKeys = [];
    for (const key in this.language.basicLanguage.source.Values) {
      if (ElementHelper.isNullOrUndefined(key) === false) {
        newKeys.push(key);
      }
    }
    newKeys.sort();
    this.keys = new PaginatedDxData(newKeys);
  }

  public rows: LanguageEditItemRow[];
  public keys: PaginatedDxData = null;

  public get contentLoaded(): boolean {
    return ElementHelper.isNullOrUndefined(this.rows) === false && ElementHelper.isNullOrUndefined(this.keys) === false;
  }

  public onInitNewRow(e) {
    const newItem = LanguageEditItemRow.createEmpty();
    e.data = newItem;
  }

  public onSaveNewRow(e) {
    const row = e.data as LanguageEditItemRow;
    this.languageEditManagementService.insert(row.copy).subscribe(() => {
      row.acceptChanges();
    });
  }

  public onUpdatedRow(e) {
    const row = e.data as LanguageEditItemRow;
    this.languageEditManagementService.save(row.original.id, row.copy).subscribe(() => {
      row.acceptChanges();
    });
  }

  public onDeletedRow(e) {
    const row = e.data as LanguageEditItemRow;
    this.languageEditManagementService.delete(row.original).subscribe();
  }
}
