import { LanguageBuilder } from "../../common/language/language-builder";
import { LanguageItemTypeGuard } from "./pl-languageitemtype.guard";

export class PlLanguageSelectionCopier {
  private static readonly copyPostFix = "Copy";

  private static isCopyPropertyName(propertyName: string) {
    return propertyName.endsWith(PlLanguageSelectionCopier.copyPostFix);
  }

  public static copyFromOriginal(targetItem: any) {
    for (const propertyKey of Object.keys(targetItem)) {
      if (LanguageItemTypeGuard.isLanguageItem(targetItem[propertyKey])) {
        if (PlLanguageSelectionCopier.isCopyPropertyName(propertyKey) === false) {
          targetItem[propertyKey + PlLanguageSelectionCopier.copyPostFix] = LanguageBuilder.duplicate(targetItem[propertyKey]);
        }
      }
    }
  }

  public static copyToOriginal(targetData: any) {
    function removeCopyPostFix(name: string): string {
      return name.toString().substring(0, name.toString().indexOf(PlLanguageSelectionCopier.copyPostFix, 0));
    }

    for (const possiblePropertyWithCopyName of Object.keys(targetData)) {
      if (PlLanguageSelectionCopier.isCopyPropertyName(possiblePropertyWithCopyName)) {
        targetData[removeCopyPostFix(possiblePropertyWithCopyName)] = LanguageBuilder.duplicate(targetData[possiblePropertyWithCopyName]);
      }
    }
  }
}
