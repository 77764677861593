import { ROUTES, RouterModule } from "@angular/router";

import { NgModule } from "@angular/core";
import { canActivateUserAcknowledgementGuard } from "../auth/user-acknowledgemen.guard";
import { CmsRoutesDto } from "../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../models/cms/dto/MenuItemTypes-dto";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";
import { CmsFeaturesModel } from "./cms-features-model";
import { CMSComponent } from "./cms.component";
import { LanguageEditComponent } from "./languagedit/languageedit.component";

function routesFactory(featuresModel: CmsFeaturesModel) {
  return [
    {
      path: CmsRoutesDto.Reports,
      data: [{ menuType: MenuItemTypesDto.MainMenu }],
      loadChildren: () => {
        const enabled = featuresModel.Reporting.enabled;
        console.log("reporting-enabled", enabled);
        return enabled ? import("../reporting/report-viewer.module").then((m) => m.ReportViewerModule) : "";
      },
    },
    {
      path: CmsRoutesDto.Plugin,
      data: [{ menuType: MenuItemTypesDto.MainMenu }],
      loadChildren: () => {
        const enabled = featuresModel.Cms.plugins_enabled;
        console.log("plugins_enabled", enabled);
        return enabled ? import("./plugin/view/plugin-view.module").then((m) => m.PluginViewModule) : "";
      },
    },
    {
      path: CmsRoutesDto.News,
      component: CMSComponent,
      data: [
        {
          menuType: MenuItemTypesDto.CMS,
          allowEdit: true,
          messageType: MessageTypeDto.news,
        },
      ],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
    {
      path: CmsRoutesDto.EbsIntro,
      component: CMSComponent,
      data: [
        {
          menuType: MenuItemTypesDto.CMS,
          allowEdit: true,
          messageType: MessageTypeDto.ebsintro,
        },
      ],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
    {
      path: CmsRoutesDto.Carousel,
      component: CMSComponent,
      data: [
        {
          menuType: MenuItemTypesDto.CMS,
          allowEdit: true,
          messageType: MessageTypeDto.carousel,
        },
      ],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
    {
      path: CmsRoutesDto.HomePageBanner,
      component: CMSComponent,
      data: [
        {
          menuType: MenuItemTypesDto.CMS,
          allowEdit: true,
          messageType: MessageTypeDto.homepagebanner,
        },
      ],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
    {
      path: CmsRoutesDto.Language,
      component: LanguageEditComponent,
      data: [{ menuType: MenuItemTypesDto.CMS }],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot([], { useHash: true })],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [CmsFeaturesModel],
    },
  ],
  exports: [RouterModule],
})
export class CmsRoutingModule {}
