import { Component, Input } from "@angular/core";
import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { ExogenousInputSource } from "../exogenous-input/exogenous-input-source.model";

@Component({
  selector: "app-target-detail-sources-component",
  templateUrl: "./sources.component.html",
  styleUrls: ["./sources.component.scss", "./shared.scss"],
  providers: [],
})
export class TargetDetailSourcesComponent {
  public constructor(public languageService: CmsLanguage) {}

  @Input()
  public sources: ExogenousInputSource[];
}
