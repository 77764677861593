import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ElementHelper } from "../../../../angular-common/helpers/element.helper";
import { LongNameShortName } from "../../../../angular-common/longnameshortname/longname-shortname";
import { UserDto } from "../../../../common/models/dto/UserDto-dto";
import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { PreferedUserEmployeeMappingDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingDto-dto";
import { PreferedUserEmployeeMappingMetaDataDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingMetaData-dto";
import { UserEmployeeMappingService } from "./user-employee-mapping.service";

@Component({
  selector: "app-user-employee-screen",
  templateUrl: "user-employee-mapping.component.html",
  styleUrls: ["user-employee-mapping.component.scss"],
})
export class UserEmployeeMappingComponent {
  constructor(private route: ActivatedRoute, private service: UserEmployeeMappingService, public language: CmsLanguage) {
    this.route.data.subscribe((data: { metaData: PreferedUserEmployeeMappingMetaDataDto }) => {
      this.availableUsers = data.metaData.Users;
      this.currentMapping = data.metaData.Mapping;
    });
    this.userName = this.userName.bind(this);
    this.employeeDescription = this.employeeDescription.bind(this);
  }

  availableUsers: UserDto[] = [];
  currentMapping: PreferedUserEmployeeMappingDto[] = [];
  popupVisible = false;
  selectedEmployeeIdentification: string;
  currentUserDto: UserDto;
  currentUserMapping: PreferedUserEmployeeMappingDto;
  isUserSelected: boolean = false;
  private _employeeEditCellInfo: any;
  isAdding: boolean = false;

  userName(r: PreferedUserEmployeeMappingDto) {
    return LongNameShortName.NameForDto(r.UserToEmployeeMapping.User);
  }

  public employeeDescription(r: PreferedUserEmployeeMappingDto) {
    return r?.UserToEmployeeMapping?.Employee?.EmployeeIdentificationDescription;
  }

  public employeeDescriptionFromEmployeeId(value: string) {
    if (value) {
      return decodeURIComponent(value);
    }
    return "<none>";
  }

  returnLongNameOrShortName(user: UserDto) {
    return LongNameShortName.NameForDto(user);
  }

  cellInfoLog(r: any) {
    console.log(r);
  }

  onRowInserted(r: PreferedUserEmployeeMappingDto) {
    this.service.addNewMapping(r).subscribe(() => {
      this.service.retrieveUserData().subscribe((d) => {
        this.currentMapping = d.Mapping;
      });
    });
  }

  onRowUpdated(r: PreferedUserEmployeeMappingDto) {
    if (r.KeyId !== -1) {
      this.service.updateMapping(r).subscribe(() => {
        this.service.retrieveUserData().subscribe((d) => {
          this.currentMapping = d.Mapping;
        });
      });
    }
  }

  onRowDeleted(r: PreferedUserEmployeeMappingDto) {
    this.service.deleteMapping(r).subscribe();
  }

  onInitNewRow(r: any) {
    this.isAdding = true;
    const newMapping = UserEmployeeMappingService.newPreferedUserEmployeeMappingDto();
    r.data = newMapping;
  }

  showPopupAndSetUser(currentUserMapping: any) {
    this._employeeEditCellInfo = currentUserMapping;
    this.popupVisible = !this.popupVisible;
    this.currentUserMapping = currentUserMapping.data;
    this.isUserSelected = true;

    if (ElementHelper.isNullOrUndefined(this.currentUserMapping) === true) {
      this.currentUserMapping = this.createInitialPreferedUserEmployeeMapping();
      this.currentUserMapping.UserToEmployeeMapping.User = this.currentUserDto;
    }
  }

  private createInitialPreferedUserEmployeeMapping(): PreferedUserEmployeeMappingDto {
    return UserEmployeeMappingService.newPreferedUserEmployeeMappingDto();
  }

  public get isEmployeeMappingSelected(): boolean {
    if (ElementHelper.isNullOrUndefined(this.selectedEmployeeIdentification) === false && this.selectedEmployeeIdentification.length !== 0) {
      return true;
    }
    return false;
  }

  setEmployee(employeeIdentification: any) {
    this.selectedEmployeeIdentification = employeeIdentification;
    if (this.isEmployeeMappingSelected === false) {
      return;
    }
    this._employeeEditCellInfo.setValue(employeeIdentification);
    this.popupVisible = !this.popupVisible;
  }
}
