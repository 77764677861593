import { Component, Input, OnInit } from "@angular/core";

import { CmsFeaturesModel } from "../cms-features-model";
import { CMSMessageList } from "../cms-messagelist.model";

@Component({
  selector: "app-carousel-component",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit {
  public constructor(cmsFeaturesModel: CmsFeaturesModel) {
    this.widerWidthNewsBeforeCarousel = cmsFeaturesModel.Cms.wider_width_news_before_carousel || false;
  }

  ngOnInit(): void {
    if (this.messageList.messages.length > 1) {
      this.hasMultiple = true;
    }
  }

  private widerWidthNewsBeforeCarousel: boolean;

  @Input()
  public useFullWidth: boolean = false;

  public interval = 5000;
  public hasMultiple = false;

  @Input() messageList: CMSMessageList;

  public get featureToggleBasedStyle(): string {
    if (this.widerWidthNewsBeforeCarousel === true) {
      return "carouselWiderWidthNewsBeforeCarousel";
    }
    return this.useFullWidth ? "carouselWide" : "carouselNormal";
  }
}
