import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxFormModule, DxRadioGroupModule, DxSelectBoxModule } from "devextreme-angular";
import { PreferredModelRoutesDto } from "../../../common/models/dto/PreferredModelRoutes-dto";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { canActivateAuthGuard } from "../../authentication-guard/authentication-guard";

import { MenuVisibilityGuard, canActivateMenuVisibilityGuard } from "../../menus/menu-visibility-guard";
import { CardViewModule } from "../cardview/cardview.module";
import { PreferredModelComponent } from "./preferred-model.component";
import { preferredModelResolver } from "./preferred-model.resolver";
import { PreferredModelService } from "./preferred-model.service";

const routes: Routes = [
  {
    path: PreferredModelRoutesDto.PreferredModel,
    component: PreferredModelComponent,
    data: [{ menuType: MenuItemTypesDto.PreferredModel }],
    canActivate: [canActivateMenuVisibilityGuard, canActivateAuthGuard],
    resolve: {
      metaData: preferredModelResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, DxButtonModule, DxButtonModule, CardViewModule, DxFormModule, DxSelectBoxModule, DxRadioGroupModule],
  providers: [PreferredModelService, MenuVisibilityGuard],
  declarations: [PreferredModelComponent],
  exports: [PreferredModelComponent],
})
export class PreferredModelModule {}
