<div>
    <div *ngFor="let nbr of numbers" class="rating-icon" [id]="componentId">
        <app-rating-icon [iconStyle]="getIconStyle(nbr)" (isMouseOver)="hoverOver(nbr, $event)" (onClick)="click(nbr)">
        </app-rating-icon>
        <dx-tooltip *ngIf="toolTip" target="#{{componentId}}" position="top"
            [showEvent]="{ name: 'mouseenter', delay: 100 }" hideEvent="mouseleave">
            <div *dxTemplate="let data = data; of: 'content'">
                {{toolTip}}
            </div>
        </dx-tooltip>
    </div>
    <dx-toast [(visible)]="showThanks" type="success" [message]="language.ratingConfirmRating" [height]="55"
        [width]="300">
        <dxo-position my="center" at="bottom" of="#{{componentId}}">
        </dxo-position>
    </dx-toast>
</div>