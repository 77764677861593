import { ErrorHandler, NgModule } from "@angular/core";

import {
  DxButtonModule,
  DxPopupModule,
  DxTemplateModule,
} from "devextreme-angular";

import { PlErrorFeedbackComponent } from "./plerrorfeedback.component";
import { PlErrorHandler } from "./plerrorhandler";

@NgModule({
  declarations: [PlErrorFeedbackComponent],
  imports: [DxButtonModule, DxPopupModule, DxTemplateModule],
  providers: [{ provide: ErrorHandler, useClass: PlErrorHandler }],
  exports: [PlErrorFeedbackComponent],
  schemas: [],
})
export class PlErrorHandlerModule {}
