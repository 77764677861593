import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { canActivateUserAcknowledgementGuard } from "../auth/user-acknowledgemen.guard";
import { CMSModule } from "../cms/cms.module";
import { CmsLinkModule } from "../cms/links/cms-link.module";
import { WhatIfModule } from "../cms/what-if/what-if.module";
import { CmsRoutesDto } from "../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../models/cms/dto/MenuItemTypes-dto";
import { CapitalComponent } from "./capital.component";
import { HomeComponent } from "./home.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.Home,
    component: HomeComponent,
    data: [{ menuType: MenuItemTypesDto.MainMenu }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [HomeComponent, CapitalComponent],
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, CMSModule, HttpClientModule, CmsLinkModule, WhatIfModule],
  providers: [],
})
export class HomeModule {}
