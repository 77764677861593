import { ElementHelper } from "../../angular-common/helpers/element.helper";
import { PresentableElement } from "../../common/model/models/presentable-element.model";
import { MutationDto } from "../../common/models/dto/MutationDto-dto";
import { DateTranslator } from "./date-translator";

export class TimePeriod extends PresentableElement {
  public startdate: number;
  public enddate: number;

  constructor() {
    super();
  }

  static getMaxDate(periodsForPeriodSelection: TimePeriod[]) {
    let endDates = [];
    periodsForPeriodSelection.forEach((period) => endDates.push(DateTranslator.fromSortableInt(period.enddate)));
    let maxDate = new Date(Math.max(...endDates.map((date) => date.getTime())));
    return maxDate;
  }

  static getMinDate(periodsForPeriodSelection: TimePeriod[]) {
    let startDates = [];
    periodsForPeriodSelection.forEach((period) => startDates.push(DateTranslator.fromSortableInt(period.startdate)));
    let minDate = new Date(Math.min(...startDates.map((date) => date.getTime())));
    return minDate;
  }

  static TimePeriodSelector(mutation: MutationDto, periodsForPeriodSelection: TimePeriod[]) {
    if (periodsForPeriodSelection.length === 0 || ElementHelper.isNullOrUndefined(mutation)) return;
    return periodsForPeriodSelection.find((period) => period.startdate <= mutation.KeyStartDate.DateAsSortableNumber && period.enddate >= mutation.KeyStartDate.DateAsSortableNumber);
  }

  static TimePeriodDisplayNameSelector(mutation: MutationDto, periodsForPeriodSelection: TimePeriod[]) {
    let period = this.TimePeriodSelector(mutation, periodsForPeriodSelection);
    if (period && ElementHelper.isNullOrUndefined(period.displayName) === false) return period.displayName;
    else return mutation.StartDateText;
  }

  static GetPeriodsInUse(mutations: MutationDto[], periodsForPeriodSelection: TimePeriod[]) {
    const periodsInUse: TimePeriod[] = [];
    for (let mutation of mutations) {
      let periodTakenByThisMutation = this.TimePeriodSelector(mutation, periodsForPeriodSelection);
      if (ElementHelper.isNullOrUndefined(periodTakenByThisMutation) === false) periodsInUse.push(periodTakenByThisMutation);
    }
    return periodsInUse;
  }
}
