import { Component, Input } from "@angular/core";

import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { AppComponentSpecificContentType } from "../appcomponentspecific-content/appcomponentspecific-content-type";
import { LinkPopupSettings } from "./linkpop.settings";

@Component({
  selector: "app-link-popup",
  templateUrl: "linkpopup.component.html",
  styleUrls: ["./linkpopup.component.scss"],
})
export class LinkPopupComponent {
  public dynamicComponentType: AppComponentSpecificContentType;

  constructor(public language: CmsLanguage) {
    this.dynamicComponentType = AppComponentSpecificContentType.LabourConditionDetail;
  }

  public get popupWidth(): number {
    return this.popupSettings.windowWidth * 0.9;
  }

  public get popupHeight(): number {
    return this.popupSettings.windowHeight * 0.7;
  }

  @Input() popupSettings: LinkPopupSettings;
}
