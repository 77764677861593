import { Component, OnDestroy, OnInit } from "@angular/core";
import { MenuItem, MenuService } from "../../../menus";

import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, of } from "rxjs";
import { WebLanguage } from "../../../../common/language/weblanguage.service";
import { CommonRoutesDto } from "../../../../common/models/dto/CommonRoutes-dto";
import { PreferredModelRoutesDto } from "../../../../common/models/dto/PreferredModelRoutes-dto";
import { RoleGuard } from "../../../authentication-guard/role-guard";
import { PlAuthenticationService } from "../../../authentication/authentication.service";
import { PlUserStorage } from "../../../baseservice/pluserstorage";
import { RouteNavigation } from "../../../baseservice/route-navigation";
import { ElementHelper } from "../../../helpers/element.helper";
import { StringHelper } from "../../../helpers/string.helper";
import { MenuItemFilterFactory } from "../../../menus/filters/MenuItemFilterFactory";
import { Sorter } from "../../../sorting/sorter";
import { AppRouteBasedSettings } from "../../app-routebased-settings";
import { LoginStorageSettings } from "../login-storage-settings";

@Component({
  selector: "app-login-landingpage",
  templateUrl: "./login.landingpage.component.html",
  styleUrls: ["../_login.scss"],
})
export class LoginLandingPageComponent implements OnInit, OnDestroy {
  public get activeMenuItems() {
    if (this._activeMenuItems) {
      return this._activeMenuItems;
    }
    return [];
  }

  public get menuReady() {
    if (this._activeMenuItems) {
      return true;
    } else {
      return false;
    }
  }

  public get hasUser() {
    if (PlUserStorage.user) {
      return true;
    } else {
      return false;
    }
  }

  public get itemsWithoutAuthentication() {
    return this._itemsOutOfAuthenticationRoutes;
  }

  public get hasItemsOutOfAuthenticationRoutes() {
    return this._itemsOutOfAuthenticationRoutes.length > 0;
  }

  private static isAuthenticationRoute(routeItem: MenuItem): boolean {
    if (routeItem) {
      if (routeItem.route === CommonRoutesDto.SignOff) {
        return true;
      }
      if (routeItem.route === CommonRoutesDto.InsufficientRights) {
        return true;
      }
      if (routeItem.route === CommonRoutesDto.Login) {
        return true;
      }
      if (routeItem.route === CommonRoutesDto.Claims) {
        return true;
      }
    }
    return false;
  }
  private _activeMenuItems: MenuItem[];
  private _itemsOutOfAuthenticationRoutes: MenuItem[];
  private _menuServiceSubscription: Subscription = new Subscription();

  constructor(private menuService: MenuService, private authService: PlAuthenticationService, private routeNavigation: RouteNavigation, public language: WebLanguage, activatedRoute: ActivatedRoute) {
    AppRouteBasedSettings.configureFromRoute(activatedRoute);
  }
  ngOnDestroy(): void {
    if (this._menuServiceSubscription) {
      this._menuServiceSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.hasUser) {
      console.log("loading menu");
      this.ensureUserRolesLoaded().subscribe((result) => {
        if (result === true) {
          this._menuServiceSubscription = this.loadMenuItems();
        }
      });
    } else {
      this.authService.retrieveCurrentUserValue(PlUserStorage.token).subscribe((r) => {
        if (this.hasUser) {
          this._menuServiceSubscription = this.loadMenuItems();
        } else {
          console.log("No user, so go to login");
          this.gotoLogin();
        }
      });
    }
  }

  private ensureUserRolesLoaded(): Observable<boolean> {
    if (this.hasUser) {
      if (ElementHelper.isNullOrUndefined(PlUserStorage.user.Roles) || PlUserStorage.user.Roles.length === 0) {
        return this.authService.refreshUserData();
      }
    }

    return of(true);
  }

  private loadMenuItems() {
    LoginStorageSettings.clearExternalLoginRoundTripRunning();

    return this.menuService.getMenuItems().subscribe((items) => {
      const activeItems: MenuItem[] = [];
      items.forEach((item) => {
        const childrenForUser = RoleGuard.filterForActiveUser(item.getAllSubMenuItemsIncludingSelf());
        childrenForUser.forEach((child) => {
          if (child.hasRoute) {
            const menuItemFilterFactory = MenuItemFilterFactory.createFor(child.route);
            if (menuItemFilterFactory.shouldShow() === true) {
              activeItems.push(child);
            }
          }
        });
      });
      this._activeMenuItems = activeItems;

      this._itemsOutOfAuthenticationRoutes = this._activeMenuItems.filter((mi) => LoginLandingPageComponent.isAuthenticationRoute(mi) === false);

      Sorter.sortSortOrderThenAlpha(this._itemsOutOfAuthenticationRoutes, (mi) => mi.displayTitle);

      this.routeConditionalNavigation();
    });
  }

  private routeConditionalNavigation() {
    if (this._itemsOutOfAuthenticationRoutes.length > 0) {
      if (this.shouldNavigateToPreferredModelSelection() === true) {
        console.log("Preferred model selection required. Navigate to: " + PreferredModelRoutesDto.PreferredModel);
        this.routeNavigation.navigate(PreferredModelRoutesDto.PreferredModel);
        return;
      } else {
        const firstActive = this._itemsOutOfAuthenticationRoutes[0];
        console.log("Open first active menu item: " + firstActive.title[PlUserStorage.language]);
        this.routeNavigation.navigate(firstActive.route);
        return;
      }
    }
  }

  private shouldNavigateToPreferredModelSelection() {
    if (this.hasUser === false) {
      return false;
    }

    if (ElementHelper.isNullOrUndefined(PlUserStorage.user.AvailableModels) || PlUserStorage.user.AvailableModels.length <= 1) {
      return false;
    }

    if (StringHelper.hasNonEmptyText(PlUserStorage.user.PreferredModel)) {
      return false;
    }

    return true;
  }

  public gotoClaims() {
    this.routeNavigation.navigate(CommonRoutesDto.Claims);
  }

  public gotoLogin(): void {
    this.routeNavigation.navigate(CommonRoutesDto.Login);
  }
}
