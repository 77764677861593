import { ModelSelectionType } from "./model-selection-type";

export class ModelSelectionSection {
  constructor(type: ModelSelectionType, name: string) {
    this.type = type;
    this.name = name;
  }
  public type: ModelSelectionType;
  public name: string;
}
