import { Component, Input, OnInit } from "@angular/core";

import { CMSMessage } from "../../cms/cms-message.model";
import { CMSService } from "../../cms/cms.service";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { ILabourCondtionComponent } from "./i-labourcondition-components";
import { ILabourConditionData } from "./i-labourcondtion-data";

@Component({
  selector: "app-labour-detail-component-withoutchoices-component",
  templateUrl: "./labour-detail-without-choices.component.html",
  styleUrls: ["./labour-detail.component.scss"],
})
export class LabourConditionDetailWithoutChoicesComponent implements OnInit, ILabourCondtionComponent {
  private _message: CMSMessage = null;

  public constructor(private cmsService: CMSService, public language: CmsLanguage) {
    this.data = <any>{};
  }

  @Input() data: ILabourConditionData;

  public ngOnInit(): void {
    this.cmsService.getMessage(MessageTypeDto.labourcondition, this.data.messageId).subscribe((message) => {
      this._message = message;
    });
  }

  public get message(): CMSMessage {
    return this._message;
  }

  public get showMessageContent(): boolean {
    return this.data && this.data.messageId > -1;
  }
}
