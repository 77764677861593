import { CmsModel } from "./cmsmodel";
import { EbsConfiguration } from "./ebsconfig";
import { EbsConfigurationMetaDataDto } from "../../models/ebs/dto/EbsConfigurationMetaDataDto-dto";
import { ModelElement } from "../../../common/modelelements";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { Variant } from "./variant";

export class EbsConfigurationMetaData {
  public ebsConfiguration: EbsConfiguration[] = [];
  public variants: Variant[] = [];
  public cmsGroupModel: CmsModel[] = [];
  public modelElements: ModelElement[] = [];

  public copyFromDto(dto: EbsConfigurationMetaDataDto, selectLanguage: PlLanguageSelection) {
    const newConfigs: EbsConfiguration[] = [];
    dto.EbsConfiguration.forEach((config) => {
      const newConfig = new EbsConfiguration(selectLanguage);
      newConfig.copyFromDto(config);
      newConfigs.push(newConfig);
    });
    this.ebsConfiguration = newConfigs;

    const newVariants: Variant[] = [];
    dto.Variants.forEach((variant) => {
      const newVariant = new Variant();
      newVariant.copyFromDto(variant);
      newVariants.push(newVariant);
    });
    this.variants = newVariants;

    const newModels: CmsModel[] = [];
    dto.CmsGroupModel.forEach((model) => {
      const newModel = new CmsModel();
      newModel.copyFromDto(model, selectLanguage);
      newModels.push(newModel);
    });
    this.cmsGroupModel = newModels;

    const newElements: ModelElement[] = [];
    dto.ModelElements.forEach((element) => {
      const newElement = new ModelElement();
      newElement.copyFromDto(element);
      newElements.push(newElement);
    });
    this.modelElements = newElements;
  }
}
