import { Component, Input } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ButtonOptions } from "./buttonoptions";
import { WebLanguage } from "../../../../common/language/weblanguage.service";

@Component({
  selector: "app-form-login",
  templateUrl: "./login-form.component.html",
  styleUrls: ["../_login.scss"],
})
export class LoginFormComponent {
  @Input() loading: boolean;
  @Input() error: string;
  @Input() login: (usernameValue: string, passwordValue: string) => void;

  private userNameValueParameterName = "userName";
  private userPasswordValueParameterName = "userPassword";

  public usernameValue: string = "";
  public passwordValue: string = "";
  public buttonOptions: ButtonOptions;

  constructor(public language: WebLanguage, route: ActivatedRoute) {
    this.buttonOptions = new ButtonOptions();

    if (route.snapshot.queryParamMap.has(this.userNameValueParameterName)) {
      const uriValueEncoded = route.snapshot.queryParamMap.get(this.userNameValueParameterName);
      this.usernameValue = decodeURIComponent(uriValueEncoded);
    }
    if (route.snapshot.queryParamMap.has(this.userPasswordValueParameterName)) {
      const passwordValueEncoded = route.snapshot.queryParamMap.get(this.userPasswordValueParameterName);
      try {
        this.passwordValue = atob(decodeURIComponent(passwordValueEncoded));
        this.loginUser();
      } catch (err) {
        console.log("atob failed: Can not user the password");
      }
    }
  }

  onFormSubmit = (e) => {
    this.loginUser();
  };

  public loginUser() {
    this.login(this.usernameValue, this.passwordValue);
  }
}
