import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { RouteDataProviderModule } from "./components/routedata/routedata-provider.module";
import { WarningProviderModule } from "./warning-provider/warning-provider.module";

@NgModule({
  imports: [HttpClientModule, BrowserModule, FormsModule, RouteDataProviderModule, RouterModule, WarningProviderModule],
  exports: [HttpClientModule, BrowserModule, FormsModule, RouteDataProviderModule, RouterModule, WarningProviderModule],
})
export class AngularCommonConfigurationModule {}
