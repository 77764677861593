import { AppSettings } from "./app-settings";
import { UISettingsDto } from "../../models/cms/dto/UISettings-dto";
import { UserSettings } from "./user-settings";

export class UISettings {
  public constructor() {
    this.application = new AppSettings(false);
    this.user = new UserSettings(false);
  }

  public application: AppSettings;
  public user: UserSettings;

  public copyFromDTO(dto: UISettingsDto) {
    this.application.copyFromDTO(dto.Application);
    this.user.copyFromDTO(dto.User);
  }
}
