import { Component, ViewChild } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { DxFileUploaderComponent } from "devextreme-angular";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../angular-common/language/pl-language-selection-copier";
import { LongNameShortName } from "../../../angular-common/longnameshortname/longname-shortname";
import { Sorter } from "../../../angular-common/sorting/sorter";
import { Variant } from "../../../common/model/models/variant-model";
import { ModelElementSpecification } from "../../../common/modelelements/modelelementsspecification";
import { RoleDto } from "../../../common/models/dto/RoleDto-dto";
import { CmsSimEditMove } from "../../cms/edit/cms-sim-edit-move";
import { ReportDefinitionDto } from "../../models/reporting/dto/ReportDefinitionDto-dto";
import { ReportingInputDto } from "../../models/reporting/dto/ReportingInputDto-dto";
import { ReportingLanguage } from "../language/reporting.service";
import { ReportData } from "./models/reportdata";
import { ReportDefinition } from "./models/reportdefinition";
import { ReportMetaData } from "./models/reportmetadata";
import { ReportingManagementService } from "./reporting-management.service";

@Component({
  selector: "app-reporting-management",
  templateUrl: "reporting-management.component.html",
  styleUrls: ["reporting-management.component.scss"],
})
export class ReportingManagementComponent {
  @ViewChild("reportUpdateFileUploader") reportUpdateFileUploader: DxFileUploaderComponent;
  constructor(private route: ActivatedRoute, private reportingeditservice: ReportingManagementService, public language: ReportingLanguage, private languageSelection: PlLanguageSelection) {
    this.route.data.subscribe((data: { metaData: ReportMetaData }) => {
      this.roles = data.metaData.roles;
      this.variants = data.metaData.variants;
      this.modelElements = data.metaData.modelElements;
      this.reportNames = data.metaData.reportNames;

      data.metaData.reports.forEach((report) => {
        this.reports.push(report.toDto());
      });

      this.createExogsPerVariant();
      this.allowedFileTypes = data.metaData.allowedFileTypes;

      this.title = this.title.bind(this);
      this.description = this.description.bind(this);
    });
  }

  public showUploadPopup = false;
  public currentReportData: ReportData;
  public availableReportData: ReportData[] = [];

  public showManagePopup = false;

  public showExogPopup: boolean = false;
  public exogsPerVariant: { [key: number]: ModelElementSpecification[] } = {};
  public selectedElementsPerReport: { [key: number]: ModelElementSpecification[] } = {};
  public availableElementsForReport: { [key: number]: ModelElementSpecification[] } = {};

  public displayValue = "displayName";
  public searchValue = "displayName";
  allowedFileTypes: string[] = [];

  public reports: ReportDefinitionDto[] = [];
  public variants: Variant[] = [];
  public reportNames: string[] = [];
  public roles: RoleDto[] = [];
  public modelElements: ModelElementSpecification[] = [];

  private selectedVariantId: number = -1;

  validationCallbacks = [];

  reportNameAdapterConfig = {
    getValue: () => {
      return this.isReportNameAlreadyExist(this.currentReportData.shortName);
    },
    validationRequestsCallbacks: this.validationCallbacks,
  };

  revalidateReportName() {
    this.validationCallbacks.forEach((func) => {
      func();
    });
  }

  private isReportNameAlreadyExist(reportName: string): boolean {
    return this.reportNames.findIndex((r) => r === reportName) !== -1 ? false : true;
  }

  public get hasReportAssignment() {
    const reportsWithReportData = this.reports.filter((r) => r.ReportPath === this.currentReportData.shortName);

    return reportsWithReportData.length > 0 ? true : false;
  }

  public title(r: ReportDefinitionDto) {
    return r.Title[this.languageSelection.currentLanguage];
  }

  public description(r: ReportDefinitionDto) {
    return r.Description[this.languageSelection.currentLanguage];
  }

  editExog(row) {
    this.selectedVariantId = row.data.VariantId;
    this.exogForEditingAllElement = this.exogsPerVariant[this.selectedVariantId];
    const currentExogShortnames: ReportingInputDto[] = row.value;
    let currentItems = this.exogForEditingAllElement.filter((m) => currentExogShortnames.find((ce) => ce.ShortName == m.shortName) !== undefined);

    for (let index = 0; index < currentExogShortnames.length; index++) {
      let item = currentItems.find((ci) => ci.shortName == currentExogShortnames[index].ShortName);
      if (item?.sortOrder !== undefined) {
        item.sortOrder = index;
      }
    }

    Sorter.sortSortOrderThenAlpha(currentItems, (r) => r.shortName);

    this.exogForEditing = new CmsSimEditMove(currentItems);
    this.showExogPopup = true;
  }

  public exogForEditing: CmsSimEditMove;
  public exogForEditingAllElement: ModelElementSpecification[] = [];

  public closeEditExogsPopup(dataGridRow) {
    const reportInputConfig = this.buildInputsFromExegos(dataGridRow.data.Inputs, dataGridRow.data.KeyId);
    dataGridRow.setValue(reportInputConfig);
    this.selectedElementsPerReport[dataGridRow.data.KeyId] = this.exogForEditing.currentConfig;
    this.showExogPopup = false;
  }

  private buildInputsFromExegos(currentInputsRowData: ReportingInputDto[], reportId: number): ReportingInputDto[] {
    return this.exogForEditing.currentConfig.map((m) => {
      let input = currentInputsRowData.find((r) => r.ShortName === m.shortName);

      if (ElementHelper.isNullOrUndefined(input)) {
        input = new ReportingInputDto();
        input.KeyId = -1;
        input.ReportId = reportId;
        input.ShortName = m.shortName;
        input.SortOrder = m.sortOrder;
      }

      input.SortOrder = m.sortOrder;
      return input;
    });
  }

  createExogsPerVariant() {
    this.variants.forEach((variant) => {
      const elements: ModelElementSpecification[] = [];
      if (variant) {
        this.modelElements.forEach((element) => {
          if (element.dataSetId === variant.dataSetId) {
            elements.push(element);
          }
        });

        this.exogsPerVariant[variant.id] = elements;
      }
    });
  }

  private configureFieldForReport(report: ReportDefinition) {
    this.selectedElementsPerReport[report.keyId] = this.createSelectedElementsForReport(report);
    this.availableElementsForReport[report.keyId] = this.filterModelElementsForReport(report);
  }

  public createSelectedElementsForReport(report: ReportDefinition) {
    const exogs: ModelElementSpecification[] = [];
    const exogsOfVariantOfReport = this.exogsPerVariant[report.variantId];

    if (exogsOfVariantOfReport === undefined) {
      return exogs;
    }

    exogsOfVariantOfReport.forEach((element) => {
      const o = report.inputs.find((x) => x.ShortName === element.shortName);
      if (o) {
        exogs.push(element);
      }
    });
    return exogs;
  }

  onEditingStart(r: ReportDefinitionDto) {
    PlLanguageSelectionCopier.copyFromOriginal(r);
  }

  filterModelElementsForReport(report: ReportDefinition) {
    if (this.exogsPerVariant[report.variantId] === undefined) {
      return [];
    }

    const options = this.exogsPerVariant[report.variantId] || [];
    const availableElements: ModelElementSpecification[] = [];

    options.forEach((option) => {
      const o = report.inputs.find((e) => e.ShortName == option.shortName);
      if (o === undefined) {
        availableElements.push(option);
      }
    });
    return availableElements;
  }

  isNew(r: ReportDefinitionDto) {
    var result = r.KeyId <= 0 && r.VariantId <= 0;
    return result;
  }

  onRowInserted(r: ReportDefinitionDto) {
    const newEntry = this.reportingeditservice.getDefault();
    PlLanguageSelectionCopier.copyToOriginal(r);

    newEntry.fromDefintionDto(r);

    this.reportingeditservice.addNewReport(newEntry).subscribe((data) => {
      const rowIndex = this.reports.findIndex((x) => x.KeyId === -1);

      if (rowIndex > -1) {
        this.reports[rowIndex] = data.toDto();
      }

      this.configureFieldForReport(data);
    });
  }

  public get hasReportName() {
    return this.currentReportData.shortName !== "";
  }

  public get hasReportData(): boolean {
    return this.currentReportData.fileUpload.hasFile;
  }

  public get canSave(): boolean {
    return this.hasReportName === true && this.hasReportData == true;
  }

  public get canUpdateReport(): boolean {
    return this.hasReportName === true && this.currentReportData.fileUpload.hasFile == true;
  }

  uploadNewReport() {
    this.currentReportData = new ReportData();
    this.currentReportData.fileUpload.allowedFileTypes = this.allowedFileTypes;
    this.showUploadPopup = true;
  }

  saveReportData(event: any) {
    this.currentReportData.lastEditedBy = PlUserStorage.user.Username;

    if (this.currentReportData.isNew) {
      let result = event.validationGroup.validate();
      if (result.isValid) {
        this.reportingeditservice.uploadNewReport(this.currentReportData).subscribe(() => {
          this.reportNames.push(this.currentReportData.shortName);
        });
      } else {
        return;
      }
    } else {
      this.reportingeditservice.uploadUpdatedReport(this.currentReportData).subscribe();
    }

    this.showUploadPopup = false;
    this.showManagePopup = false;
  }

  deleteReportData() {
    if (this.currentReportData !== undefined) {
      this.reportingeditservice.deleteReportData(this.currentReportData.keyid).subscribe((r) => {
        const index = this.availableReportData.findIndex((x) => x.keyid === this.currentReportData.keyid);
        this.availableReportData.splice(index, 1);
        this.showManagePopup = false;
        const reportNameIndex = this.reportNames.findIndex((r) => r === this.currentReportData.shortName);
        this.reportNames.splice(reportNameIndex, 1);
      });
    }
  }

  private exogElementsChangeDetected(usedOptions: ModelElementSpecification[], allOptions: ModelElementSpecification[]) {
    return ModelElementSpecification.filterAvailableOptions(usedOptions, allOptions);
  }

  updateExogElements() {
    const selectedVariant = this.variants.find((v) => v.id === this.selectedVariantId);
    let exegosForVariant: ModelElementSpecification[] = [];

    if (ElementHelper.isNullOrUndefined(selectedVariant)) {
      exegosForVariant = this.modelElements;
    } else {
      exegosForVariant = this.modelElements.filter((e) => e.dataSetId === selectedVariant.dataSetId);
    }

    this.exogForEditingAllElement = this.exogElementsChangeDetected(this.exogForEditing.currentConfig, exegosForVariant);
  }

  manageReports() {
    this.currentReportData = new ReportData();
    this.reportingeditservice.retrieveAllReportData().subscribe((reports) => {
      this.availableReportData = reports;
      this.showManagePopup = true;
    });
  }

  onRowUpdated(r: ReportDefinitionDto) {
    PlLanguageSelectionCopier.copyToOriginal(r);
    this.reportingeditservice.saveReport(r).subscribe();
  }

  onRowDeleted(r: ReportDefinitionDto) {
    this.reportingeditservice.deleteReport(r).subscribe();
  }

  onInitNewRow(event) {
    const newReport = this.reportingeditservice.getDefault().toDto();
    PlLanguageSelectionCopier.copyFromOriginal(newReport);

    event.data = newReport;
  }

  onReportSelected(selectedReport: any) {
    if (selectedReport && selectedReport.itemData) {
      selectedReport.itemData.fileUpload.allowedFileTypes = this.allowedFileTypes;
    }
  }

  onManagePopupShow(event: any) {
    if (this.reportUpdateFileUploader) {
      this.reportUpdateFileUploader.instance.option("value", []);
    }
  }

  onExogPopUpContentReady(event: any) {
    this.updateExogElements();
  }

  variantValidation = (e) => {
    if (e.data === undefined || e.data === null) {
      return false;
    }

    return e.data.VariantId > 0;
  };

  onVariantChanged(newData: any, value: any) {
    newData.VariantId = value;
    newData.ExogenousShortNames = [];
  }

  onRowSaved() {
    this.selectedVariantId = -1;
  }

  computedExegoName(exegoShortName: any, reportData: any): string {
    if (ElementHelper.isNullOrUndefined(exegoShortName) || ElementHelper.isNullOrUndefined(reportData)) {
      return "";
    }

    const exego = this.exogsPerVariant[reportData.VariantId].find((e) => e.shortName === exegoShortName);

    if (ElementHelper.isNullOrUndefined(exego)) {
      return this.language.ReportInputFieldNotFound;
    } else {
      return LongNameShortName.NameFor(exego);
    }
  }

  public inputsOrderChanged(data: ModelElementSpecification[]) {
    this.exogForEditing.currentConfig.forEach((element) => {
      element.sortOrder = data.indexOf(element);
    });
  }

  public downloadReportFile() {
    this.reportingeditservice.retrieveReportFileContent(this.currentReportData.keyid).subscribe(
      (blob) => {
        this.saveReportFile(blob, this.currentReportData);
      },
      (error) => {
        console.error("Error downloading report file.", error);
      },
    );
  }

  private saveReportFile(reportFileConttent: Blob, reportData: ReportData) {
    const blob = new Blob([reportFileConttent], { type: reportFileConttent.type });

    const anchorElement = window.document.createElement("a");
    anchorElement.href = window.URL.createObjectURL(blob);
    anchorElement.download = this.buildReportFileName(reportData.shortName);
    document.body.appendChild(anchorElement);

    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(anchorElement.href);
  }

  private buildReportFileName(reportShorName: string): string {
    if (ElementHelper.isNullOrUndefined(this.allowedFileTypes) || this.allowedFileTypes.length === 0) {
      return `${reportShorName}.repx`;
    }

    const firstAllowedExtension = this.allowedFileTypes[0];

    return `${reportShorName}${firstAllowedExtension}`;
  }
}
