<!-- card -->
<div class="card dx-popup-wrapper">
  <div class="dx-overlay-content card-overlay-content">
    <div *ngIf="title || (buttons && buttons.length > 0)" role="toolbar">
      <div class="dx-toolbar-items-container">
        <h3 class="dx-popup-title">
          {{ title }}
          <div class="float-right buttondiv" *ngFor="let btn of buttons">
            <dx-button [icon]="btn.icon" class="button-icon-m" [hint]="btn.hint" (onClick)="buttonClick(btn)">
            </dx-button>
          </div>
        </h3>
      </div>
    </div>
    <div class="card-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>