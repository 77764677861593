
// t4tsCode gen": "0.8.2.0 for file IdDescription.cs
// Don't adjust manually!

export interface IIdShortNameDto {
    Identification: number;
    ShortName: string;
    Name: string;
    Description: string;
}

export class IdDescriptionDto {
    public Identification: number;
    public Name: string;
    public Description: string;
    public SortOrder: number;
}

