import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RoleDto } from "../../../../common/models/dto/RoleDto-dto";
import { RoleHierarchyDeleteResultTypeDto } from "../../../../common/models/dto/RoleHierarchyDeleteResultType-dto";
import { RoleHierarchyDto } from "../../../../common/models/dto/RoleHierarchyDto-dto";
import { RolesHierarchyMetaDataDto } from "../../../../common/models/dto/RolesHierarchyMetaData-dto";
import { EntryCreated } from "../../../baseservice/entry-created";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { PlBaseService } from "../../../baseservice/plbase.service";
import { PlLoadIndicator } from "../../../loadindicator/loadindicator";
import { RoleHierarchy } from "./models";

@Injectable()
export class AuthorizationRolesMaintenanceService extends PlBaseService {
  private endPoint: string = "AuthorizationRolesHierarchy";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAuthorizationRolesHierarchyMetaData(): Observable<RolesHierarchyMetaDataDto> {
    return this.getData<RolesHierarchyMetaDataDto>(this.endPoint);
  }

  public saveRoleHierarchy(roleHierarchy: RoleHierarchy) {
    const dto = roleHierarchy.copyToDto();
    if (roleHierarchy.role.isNew) {
      this.saveNewRoleHierarchy(dto).subscribe((ec) => {
        roleHierarchy.role.id = ec.data.KeyId;
      });
    } else {
      this.saveExistingRoleHierarchy(dto).subscribe();
    }
  }

  public saveExistingRoleHierarchy(dto: RoleHierarchyDto): Observable<void> {
    const url = this.endPoint + "/" + dto.Role.KeyId;
    return this.update(dto, url);
  }

  public saveNewRoleHierarchy(dto: RoleHierarchyDto): Observable<EntryCreated<RoleDto>> {
    return this.createData<RoleDto>(this.endPoint, dto);
  }

  public deleteRoleHierarchy(dtoId: number): Observable<RoleHierarchyDeleteResultTypeDto> {
    const url = this.endPoint + "/" + dtoId;
    return this.remove(url);
  }
}
