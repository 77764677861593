<div class="authorization-rolesmaintenance" *ngIf="tabContent && rolesHierarchy">
  <div class="grid grid-template">
    <div class="grid-item column-one">
      <div class="buttons-bottom-margin align-right">
        <dx-button id="addButton" icon="fas fa-plus" [hint]="language.authorizationRolesMaintenanceAddRole" (onClick)="addRole()"></dx-button>
        <dx-button [disabled]="selectedRoleHierarchy === undefined" id="cloneButton" icon="far fa-copy" [hint]="language.Copy" (onClick)="copyRole()"></dx-button>
        <dx-button [disabled]="!canDelete" id="deleteButton" icon="fas fa-trash" [hint]="language.authorizationRolesMaintenanceDeleteHint" (onClick)="deleteRole()"></dx-button>
      </div>

      <div>
        <dx-list
          #rolesList
          [dataSource]="rolesHierarchy.all"
          [selectionMode]="'single'"
          [showSelectionControls]="true"
          [searchEnabled]="true"
          searchMode="contains"
          searchExpr="role.name"
          [showScrollbar]="'onHover'"
          (onSelectionChanged)="selectionChanged($event)"
        >
          <div *dxTemplate="let item of 'item'">
            <span>{{ item.role.name }}</span>
          </div>
        </dx-list>
      </div>
    </div>
    <div class="grid-item column-two treeview">
      <div>
        <dx-validation-group #roleValidationGroup>
          <app-authorization-content-role-details [roleHierarchy]="selectedRoleHierarchy" [rolesList]="rolesHierarchy" (saved)="saveRoleHierarchy($event)"></app-authorization-content-role-details>
        </dx-validation-group>
      </div>
    </div>
  </div>
</div>
