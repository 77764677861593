<div class="panel panel-default" *ngIf="benefitGroup">
  <div class="panel-heading" role="tab" id="headingOne">
    <div class="panel-title" (click)="toggleCollapsed()" [class.active.inactive]="collapsed === false">
      <a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false">
        <table class="table no-border nopadding">
          <tbody>
            <tr>
              <td class="headertitlespan"><span class="ebs-rectangle" style.border-color="{{ benefitGroup.color }}"></span>{{ benefitGroup.title }}</td>
              <td class="headerspan">
                <span *ngIf="benefitGroup.additionalValue.visible">{{ benefitGroup.additionalValue.valueAsText }}</span>
              </td>
              <td class="headerspan">
                <span *ngIf="benefitGroup.value.visible">{{ benefitGroup.value.valueAsText }}</span>
              </td>
              <td class="arrowbox">
                <div class="arrow" [ngClass]="{ 'expanded-arrow': collapsed === false }"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </a>
    </div>
    <div id="collapseOne" class="panel-collapse collapse" [class.in]="collapsed === false" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <table class="table no-border">
          <tbody>
            <tr *ngFor="let benefitItem of benefitGroup.items">
              <td class="valuetitlespan" *ngIf="benefitItem.link.hasVisibleLocation === false">
                {{ benefitItem.title }}
              </td>
              <td class="valuetitlespan" *ngIf="benefitItem.link.hasVisibleLocation">
                <app-cms-link class="ebsLinksItems" [link]="benefitItem.link" [supportPopup]="true" [displayText]="benefitItem.title" [displayStyle]="'text'"> </app-cms-link>
              </td>
              <td class="valuespan">
                <span *ngIf="benefitItem.additionalValue.visible">{{ benefitItem.additionalValue.valueAsText }}</span>
              </td>
              <td class="valuespan">
                <span *ngIf="benefitItem.value.visible">{{ benefitItem.value.valueAsText }}</span>
              </td>
              <td class="arrowbox">
                <div></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
