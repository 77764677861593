import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable()
export class PlLoadIndicator {
  public static readonly initialValue: boolean = false;

  public constructor() {
    console.log("Load indicator created");
  }

  public register<T>(observable: Observable<T>): Observable<T> {
    this._loadingSource.next(true);
    return observable.pipe(
      finalize(() => {
        this._loadingSource.next(false);
      }),
    );
  }

  public forceClose() {
    this._loadingSource.next(false);
  }

  private _loadingSource = new BehaviorSubject<boolean>(
    PlLoadIndicator.initialValue,
  );
  public loading = this._loadingSource.asObservable();
}
