
// t4tsCode gen": "0.8.2.0 for file PreferedUserEmployeeMappingRoutes.cs
// Don't adjust manually!

export class PreferedUserEmployeeMappingRoutesDto {
    public static PreferedUserEmployeeMapping: string = "PreferedUserEmployeeMapping";
    public static MeUserEmployeeMapping: string = "MeUserEmployeeMapping";
    public static PreferedUserEmployeeMappingConfigurtion: string = "PreferedUserEmployeeMappingConfigurtion";
}

