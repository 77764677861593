import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

import { confirm } from "devextreme/ui/dialog";
import { ImageChanges } from "../../components/images/image-changes.model";
import { LabourConditionGroup } from "../labourgroups/lc-group";
import { CmsLanguage } from "../language/cmslanguage.service";
import { CMSMessage } from "./../cms-message.model";

@Component({
  selector: "app-cmsmessageedit-component",
  templateUrl: "./cms-message-edit.component.html",
  styleUrls: ["./cms-message-edit.component.scss"],
  providers: [],
})
export class CMSMessageEditComponent implements OnInit, OnDestroy {
  public constructor(public language: CmsLanguage) {
    this._editMode = false;
  }

  public ngOnInit(): void {
    if (this.message.id < 0) {
      this.startEdit();
    }
  }

  public ngOnDestroy(): void {
    this.stopEditing();
  }

  @Input() groups: LabourConditionGroup[];
  @Input() message: CMSMessage;
  @Output() editingFinished = new EventEmitter();
  @Output() hasBeenDelete = new EventEmitter();

  private _messageCopy: CMSMessage;
  public get messageCopy() {
    return this._messageCopy;
  }

  private _imageChanges: ImageChanges;
  public get imageChanges() {
    return this._imageChanges;
  }

  public startEdit(): void {
    this._messageCopy = this.message.getCopy();
    this._imageChanges = new ImageChanges(this.messageCopy.originalImageUrl, this.messageCopy.imageURL);

    this._editMode = true;
  }

  public cancelEdit(): void {
    this.stopEditing();
    this.editingFinished.emit({ cancelled: true, message: this.message });
  }

  public saveEdit(): void {
    this.message.copyFrom(this.messageCopy, false);
    this.stopEditing();
    this.editingFinished.emit({
      cancelled: false,
      message: this.message,
      imageChanges: this.imageChanges,
    });
  }

  public delete(): void {
    confirm(this.language.f3MeWebApiCMSMessageConfirmDelete, this.language.getStringDef("applicationTitle")).then((answer) => {
      if (answer) {
        this.hasBeenDelete.emit({ message: this.message });
      }
    });
  }

  private _editMode: boolean;
  get editMode(): boolean {
    return this._editMode;
  }

  private stopEditing(): void {
    this._editMode = false;
  }
}
