import { Component, Input, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { LinkHandler } from "../../components/links/linkhandler";
import { ChoicesService } from "./choices.service";
import { IChoiceSelectionViewComponent } from "./i-choice-selection-view-component";
import { IChoiceSelectionViewData } from "./i-choice-selection-view-data";
import { ChoiceDefinition } from "./targetdefinition/target-definition.model";

@Component({
  selector: "app-choice-selection-component",
  templateUrl: "./choice-selection.component.html",
  styleUrls: ["./choice-selection.component.scss"],
  providers: [],
})
export class ChoiceSelectionComponent implements OnInit, IChoiceSelectionViewComponent {
  @Input()
  data: IChoiceSelectionViewData;

  public constructor(router: Router, private targetsService: ChoicesService) {
    this.linkHandler = new LinkHandler(router);
  }

  ngOnInit(): void {
    if (this.data && this.data.messageId) {
      this.targetsService.getTargetsByMessage(this.data.messageId).subscribe((t) => {
        this._choices = t;
      });
    }
  }

  private _choices: ChoiceDefinition[];

  public get targetDefinitions(): ChoiceDefinition[] {
    if (this._choices) {
      return this._choices;
    } else {
      return [];
    }
  }

  public linkHandler: LinkHandler;

  public targetDefinitionSelected(td: ChoiceDefinition) {
    this.linkHandler.go(td.link);
  }
}
