import { PresentableElementDto } from "../../common/models/dto/PresentableElement-dto";
import { ElementHelper } from "../helpers/element.helper";
import { BrowserStorage } from "../storage/browser-storage";
import { StringComparer } from "../stringcomparison/stringcomparer";
import { LongNameOrShortNameMode } from "./longname-shortname-mode.model";
import { ILongNameShortName } from "./longname-shortname.interface";

export class LongNameShortName {
  public static NameForDto(element: PresentableElementDto): string {
    if (ElementHelper.isNullOrUndefined(element)) {
      return "<missing>";
    }

    const hasLongName = StringComparer.isNullUndefinedOrEmpty(element.LongName) === false;
    if (hasLongName === false) {
      return element.ShortName;
    }

    return element.LongName;
  }

  public static NameFor(element: ILongNameShortName) {
    if (ElementHelper.isNullOrUndefined(element)) {
      return "<missing>";
    }

    const hasLongName = StringComparer.isNullUndefinedOrEmpty(element.longName) === false;
    if (hasLongName === false) {
      return element.shortName;
    }

    const setting = this.RetrieveUserSetting();
    switch (setting) {
      case LongNameOrShortNameMode.LongName: {
        return element.longName;
      }
      case LongNameOrShortNameMode.ShortName: {
        return element.shortName;
      }
      case LongNameOrShortNameMode.LongAndShortName: {
        return element.longName + " (" + element.shortName + ")";
      }
      case LongNameOrShortNameMode.ShortAndLongName: {
        return element.shortName + " (" + element.longName + ")";
      }
    }
  }

  public static RetrieveUserSetting(): LongNameOrShortNameMode {
    const result = BrowserStorage.getItem(this.userSettingKey);
    if (ElementHelper.isNullOrUndefined(result)) {
      return LongNameOrShortNameMode.LongName;
    } else {
      return LongNameOrShortNameMode[result];
    }
  }

  public static StoreUserSetting(newUserSetting: LongNameOrShortNameMode) {
    BrowserStorage.setItem(this.userSettingKey, LongNameOrShortNameMode[newUserSetting]);
  }

  private static userSettingKey = "LongNameOrShortName";

  public static DisplayField() {
    if (LongNameShortName.RetrieveUserSetting() === LongNameOrShortNameMode.LongName) {
      return "LongName";
    } else {
      return "ShortName";
    }
  }

  public static DisplayFieldPresentable() {
    if (LongNameShortName.RetrieveUserSetting() === LongNameOrShortNameMode.LongName) {
      return "longName";
    } else {
      return "shortName";
    }
  }
}
