import { HttpErrorResponse, HttpResponse, HttpResponseBase } from "@angular/common/http";

import { EnvironmentSettings } from "../baseservice/environment-settings";
import { ErrorDectedType } from "./ErrorDetectedType";

export class ErrorDetection {
  public static findErrorType(error: any, envSettings: EnvironmentSettings) {
    if (error !== undefined && error.rejection !== undefined) {
      console.log("Dev:We should handle errors on promises; unpacking rejection for error detection.");
      error = error.rejection;
    }

    {
      const respType = this.interperateHttpReponse(error as HttpResponse<any>, envSettings);
      if (respType !== ErrorDectedType.Unknown) {
        return respType;
      }
    }
    {
      const respType = this.interperateHttpReponse(error as HttpErrorResponse, envSettings);
      if (respType !== ErrorDectedType.Unknown) {
        return respType;
      }
    }
  }

  private static interperateHttpReponse(httpReponse: HttpResponseBase, envSettings: EnvironmentSettings) {
    if (httpReponse !== undefined && httpReponse.status !== undefined) {
      {
        const respType = this.detectAuthResponse(httpReponse, envSettings);
        if (respType !== ErrorDectedType.Unknown) {
          return respType;
        }
        if (httpReponse.status == 401) {
          return ErrorDectedType.NotFound;
        }
      }

      if (httpReponse.status === 0) {
        return ErrorDectedType.ApiOffline;
      }
    }

    return ErrorDectedType.Unknown;
  }

  private static detectAuthResponse(httpReponse: HttpResponseBase, envSettings: EnvironmentSettings) {
    if (httpReponse) {
      // 401 = unauthorized. Ga terug naar start en ontvang geen 20.000.
      if (httpReponse.status === 401 || httpReponse.status === 403) {
        console.log("Unauthorized call");
        return ErrorDectedType.ApiUnAuthorized;
      }

      if (httpReponse.status === 302 && envSettings.useExternalAuthentication() === true) {
        console.log("Recieved 302 error assuming unauthorized");
        return ErrorDectedType.ApiUnAuthorized;
      }

      if (envSettings.useExternalAuthentication()) {
        if (httpReponse.status === 200) {
          if (httpReponse.url) {
            const newLocation = httpReponse.url.toString();
            if (newLocation.indexOf("Account/AccessDenied") > -1) {
              console.log("received an unauthenticated redirect response.", newLocation);
              return ErrorDectedType.ApiUnAuthorized;
            }
          }
        }

        if (httpReponse.status === 0) {
          console.log("We might be running into a CORS error; e.g. authentication redirect (302) is blocked by browser.");
          return ErrorDectedType.ApiUnAuthorized;
        }
      }
    }

    return ErrorDectedType.Unknown;
  }
}
