import { EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { ApiResult } from "../../../../angular-common/baseservice/_models/apiresult.model";
import { Sorter } from "../../../../angular-common/sorting/sorter";
import { TargetDto } from "../../../models/choices/dto/Target-dto";
import { TargetStatusDto } from "../../../models/choices/dto/TargetStatus-dto";
import { ModifiedExogenousInputOptionDto } from "../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ModifiedExogenousInputOptionAttachmentDto } from "../../../models/me/dto/ModifiedExogenousInputOptionAttachment-dto";
import { ModifiedExogenousInputOptionsDto } from "../../../models/me/dto/ModifiedExogenousInputOptions-dto";
import { ChoicesService } from "../choices.service";
import { ExogenousInputComputedField } from "./exogenous-input-computedfield";
import { ExogenousInputSource } from "./exogenous-input-source.model";
import { ExogenousInputOptionCreator } from "./options/exogenous-input-option-creator";
import { ExogenousInputOptionObserver } from "./options/exogenous-input-option-observer";
import { TExogenousInputOptionSynchronizer } from "./options/exogenous-input-option-synchronizer";
import { ExogenousInputOption } from "./options/exogenous-input-option.model";

export class ExogenousInput {
  private observer: ExogenousInputOptionObserver = new ExogenousInputOptionObserver();

  public constructor(private targetsService: ChoicesService) {
    this._options = new Array<ExogenousInputOption>();
    this._sources = new Array<ExogenousInputSource>();

    this.observer.onOptionChanged.subscribe(() => this.optionsChanged());
  }

  public identification: number;
  public description: string;
  public required: ExogenousInputComputedField;
  public remaining: ExogenousInputComputedField;
  public invested: ExogenousInputComputedField;
  public submitAllowed: boolean;
  public status: TargetStatusDto;

  private synchronizePropertiesFromDTO(dto: TargetDto) {
    if (dto) {
      this.identification = dto.Identification;
      this.description = dto.Description;
      this.submitAllowed = dto.SubmitAllowed;
      this.status = dto.Status;

      if (dto.Required) {
        this.required = new ExogenousInputComputedField();
        this.required.copyFromDTO(dto.Required);
      }
      if (dto.Invested) {
        this.invested = new ExogenousInputComputedField();
        this.invested.copyFromDTO(dto.Invested);
      }
      if (dto.Remaining) {
        this.remaining = new ExogenousInputComputedField();
        this.remaining.copyFromDTO(dto.Remaining);
      }
    }
  }

  public copyFromDTO(dto: TargetDto) {
    if (dto) {
      this.synchronizePropertiesFromDTO(dto);

      this._options = ExogenousInputOptionCreator.createTargetOptions(dto.Options, this.observer);

      const newSources = new Array<ExogenousInputSource>();
      dto.Sources.forEach((sourceDto) => {
        const source = new ExogenousInputSource(this.observer);
        source.copyFromDTO(sourceDto);
        newSources.push(source);
      });
      this._sources = newSources.sort(Sorter.compareSortOrder);
    }
  }

  public toDTO(includeAttachmentData: boolean): ModifiedExogenousInputOptionsDto {
    const result = new ModifiedExogenousInputOptionsDto();
    result.Options = new Array<ModifiedExogenousInputOptionDto>();
    result.Attachments = new Array<ModifiedExogenousInputOptionAttachmentDto>();

    ExogenousInputOptionCreator.toDTO(this.options, result, includeAttachmentData);

    this.sources.forEach((source) => {
      source.toDTO(result, includeAttachmentData);
    });

    return result;
  }

  private _options: ExogenousInputOption[];
  public get options(): ExogenousInputOption[] {
    return this._options;
  }

  private _sources: ExogenousInputSource[];
  public get sources(): ExogenousInputSource[] {
    return this._sources;
  }

  private optionsChanged() {
    this.targetsService.updateTarget(this.identification, this.toDTO(false)).subscribe((dto) => {
      this.synchronizePropertiesFromDTO(dto);

      TExogenousInputOptionSynchronizer.synchronize(dto.Options, this._options, this.observer);

      // Simple synchronize, we expect the same sources after an update.
      this._sources.forEach((source) => {
        const dtoSource = dto.Sources.find((x) => x.Identification === source.identification);
        if (dtoSource !== undefined) {
          source.synchronizeFromDTO(dtoSource);
        }
      });
    });
  }

  public submit(): Observable<ApiResult> {
    // We use a 'share' statement here because we are subscribing twice to the same observable.
    // Once in this method, and once in the calling method. The 'share' prevents the post to the
    // server from executing twice, one for every subscribe. See also:
    // https://stackoverflow.com/questions/37241294/angular2-http-post-gets-executed-twice
    const result = this.targetsService.submitTarget(this.identification, this.toDTO(true)).pipe(share());

    result.subscribe((r) => {
      if (r.isSuccess) {
        this.onSubmitted.emit();
      }
    });

    return result;
  }

  public onSubmitted = new EventEmitter();
}
