
// t4tsCodeFromCs": "0.8.2.0 for file CalculatorPeriodMode.cs
// Don't adjust manually!
export enum CalculatorPeriodModeDto {
        FromStartAndEndDate = 0,
        CurrentYear = 1,
        NextYear = 2,
        CurrentPeriod = 3,
        ReferenceDate = 4,
        PreviousPeriod = 5,
        LastYearBeforeCurrentPeriod = 6,
}

