import { GroupOptionsDto } from './GroupOptions-dto';

export declare type LabourGroupDtoTitleDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file LabourGroupDto.cs
// Don't adjust manually!

export class LabourGroupDto {
    public Id: number;
    public Title: LabourGroupDtoTitleDto;
    public Options: GroupOptionsDto;
    public SortOrder: number;
    public Color: string;
    public MessageIds: number [] = [];
}

