import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";

import { Injectable, inject } from "@angular/core";
import { mergeMap } from "rxjs/operators";
import { CommonRoutesDto } from "../../common/models/dto/CommonRoutes-dto";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { AppRouteBasedSettings } from "../components/app-routebased-settings";

// Implementation of CanActivate is not integrated into the AuthenticationService because that
// results in a cyclic dependency for the router instance (router depends on AuthGuard, AuthenticationService depends on router)
@Injectable()
export class AuthGuard {
  constructor(public router: Router, private environmentSettings: EnvironmentSettings) {}

  private readonly insufficientRightRoute = "/" + CommonRoutesDto.InsufficientRights;

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    AppRouteBasedSettings.configureFromRouteSnapShot(route);
    const result = PlUserStorage.hasUser();
    const obs = of(result);
    return this.redirectToLogin(obs);
  }

  protected redirectToLogin(authorizationResult: Observable<boolean>): Observable<boolean> {
    const result = authorizationResult.pipe(
      mergeMap((r) => {
        if (r === false) {
          const routeLocation = this.environmentSettings.loginRoute();
          this.router.navigate([routeLocation]);
        } else if (PlUserStorage.hasUserWithData() === false) {
          console.log(`Route to: ${this.insufficientRightRoute}`);
          this.router.navigate([this.insufficientRightRoute, { noData: r }]);
        }
        return of(true);
      }),
    );

    return result;
  }

  protected redirectToMissingRoles(authorizationResult: Observable<string[]>): Observable<boolean> {
    const result = authorizationResult.pipe(
      mergeMap((r) => {
        if (r.length > 0) {
          console.log("Route to: " + this.insufficientRightRoute);
          this.router.navigate([this.insufficientRightRoute, { roles: r }]);
        }
        return of(true);
      }),
    );

    return result;
  }
}

export const canActivateAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(route, state);
};
