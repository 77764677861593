import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { EnvironmentSettings, PlBaseService, PlErrorHandler } from "../../../angular-common";

import { AuthorizationEditModule } from "../../../angular-common/components/authorization/authorization-edit.module";
import { PageHeaderModule } from "../../../angular-common/components/page-header/page-header.module";
import { TechnicalMaintainerModule } from "../../../angular-common/components/technicalmaintainer/technicalmaintainer.module";
import { EmployeeAuthenticationService } from "../../../employee-common/auth/employee-auth.service";
import { UserAcknowledgementGuard } from "../../../employee-common/auth/user-acknowledgemen.guard";
import { CmsFeaturesModel } from "../../../employee-common/cms/cms-features-model";
import { CmsManagementModule } from "../../../employee-common/cms/management/cms-management.module";
import { LanguageEditService } from "../../../employee-common/components/language/languageedit.service";
import { MenuTypeProvider } from "../../../employee-common/components/menus/menu-type-provider";
import { MenusModule } from "../../../employee-common/components/menus/menus.module";
import { UserSettingsService } from "../../../employee-common/components/usersettings/usersettings.service";
import { EbsManagementModule } from "../../../employee-common/ebs/management/ebs-management.module";
import { MainModule } from "./main/main.module";
import { ForAllLanguage } from "./services/language/forall-language.service";

export function initializeLanguage(langSrv: ForAllLanguage) {
  return () => langSrv.loadInspireLanguage().catch((e) => console.log("Error loading language", e));
}

@NgModule({
  imports: [MainModule, MenusModule, PageHeaderModule, AuthorizationEditModule],
  exports: [MenusModule],
  declarations: [],
  providers: [
    LanguageEditService,
    PlBaseService,
    MenusModule,
    MenuTypeProvider,
    EmployeeAuthenticationService,
    EnvironmentSettings,
    UserSettingsService,
    TechnicalMaintainerModule,
    EbsManagementModule,
    CmsManagementModule,
    UserAcknowledgementGuard,
    CmsFeaturesModel,
    { provide: ErrorHandler, useClass: PlErrorHandler },
    ForAllLanguage,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLanguage,
      deps: [ForAllLanguage],
      multi: true,
    },
  ],
})
export class AppForAllModule {}
