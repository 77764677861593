import { Component, Input } from "@angular/core";

import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EditToggle } from "../../components/edittoggle/edittoggle";
import { EmailTemplate } from "../consume-mail/email-template";
import { EmailTemplateEditService } from "./emailtemplate-edit.service";

@Component({
  selector: "app-emailtemplate-edit-template-component",
  templateUrl: "./emailtemplate-edit-template.component.html",
  styleUrls: ["./emailtemplate-edit-template.component.scss"],
  providers: [],
})
export class EmailTemplateEditTemplateComponent {
  public constructor(public languageService: CmsLanguage, private emailTemplateEditService: EmailTemplateEditService) {
    const builder = languageService.languageBuilder();
    this.editToggle = new EditToggle();
    this.editToggle.stopEditing.subscribe((save) => {
      if (save) {
        this.template.body = builder.copyLanguageItem(this.body);
        this.template.subject = builder.copyLanguageItem(this.subject);
        this.template.sendTo = this.sendTo;
        this.emailTemplateEditService.saveExistingTemplate(this.template.toDTO()).subscribe((r) => console.log("template updated: " + r.shortName));
      } else {
        this.createCopies();
      }
    });
  }

  public editToggle: EditToggle;

  @Input() public set enterInEditMode(value: boolean) {
    this.editToggle.editing = value;
  }

  @Input() public currentLanguage: string;
  @Input()
  public get template(): EmailTemplate {
    return this._template;
  }
  public set template(newTemplate: EmailTemplate) {
    this._template = newTemplate;
    this.createCopies();
  }
  private _template: EmailTemplate;

  private createCopies() {
    const builder = this.languageService.languageBuilder();

    if (this.template && this.template !== null) {
      this.subject = builder.copyLanguageItem(this.template.subject);
      this.body = builder.copyLanguageItem(this.template.body);
      this.sendTo = this.template.sendTo;
    } else {
      this.subject = undefined;
      this.body = undefined;
      this.sendTo = undefined;
    }
  }

  public subject: LanguageItem;
  public body: LanguageItem;
  public sendTo: string;
}
