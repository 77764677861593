import { ExogenousInputDateOptionDto } from "../../../../models/me/dto/ExogenousInputDateOption-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";
import { ExogenousInputOptionType } from "./option-type.model";

export class ExogenousInputOptionDate extends ExogenousInputOption {
  public constructor() {
    super(ExogenousInputOptionType.Date);
  }

  private _valueAsText: string;
  public get valueAsText(): string {
    return this._valueAsText;
  }
  public set valueAsText(v: string) {
    this._valueAsText = v;
  }

  private _value: Date;
  public get value(): string | number | Date {
    return this._value;
  }
  public set value(newValue: string | number | Date) {
    if (this._value === newValue) {
      return;
    }

    if (newValue < this.minValue) {
      this._value = this.minValue;
    } else {
      this._value = newValue as Date;
    }

    this.changed();
  }

  public minValue: Date;

  public copyFromDTO(dto: ExogenousInputDateOptionDto) {
    super.copyFromDTO(dto);

    this._value = dto.Value;
    this.valueAsText = dto.ValueAsText;

    if (dto.MinValue && dto.MinValue !== null) {
      this.minValue = dto.MinValue;
    } else {
      // We assume this will work in all situations
      this.minValue = new Date(1980, 1, 1);
    }
  }

  public toDTO(dto: ModifiedExogenousInputOptionDto) {
    super.toDTO(dto);
    dto.Type = ExogenousInputOptionTypes.typeDate;

    // Todo, check this and/or move to some shared library
    if (this.value && this.value !== null) {
      // Make sure we have a Date object to prevent 'date.getFullYear is not a function' errors
      const date = new Date(this.value);
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();
      dto.Value = yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]) + "T00:00:00";
    }
  }
}
