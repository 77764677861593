import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";
import { LanguageEditDataDTO, LanguageItem } from "../../../angular-common/language/language-dto.model";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { LanguageEditItem } from "./languageedititem.model";

@Injectable()
export class LanguageEditService extends PlBaseService {
  private endPoint: string = "LanguageEdit";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getEntries(): Observable<LanguageEditDataDTO> {
    return this.getDataTransformed<LanguageEditDataDTO>(this.endPoint);
  }

  public getEntriesForEditing(): Observable<LanguageEditDataDTO> {
    const location = this.envSettings.combinePath(this.envSettings.combinePath(this.endPoint, "edit"), "edit");
    return this.getDataTransformed<LanguageEditDataDTO>(location);
  }

  public getEditItems(source: LanguageEditDataDTO, languageService: CmsLanguage): LanguageEditItem[] {
    const result = new Array<LanguageEditItem>();
    source.MetaData.Items.forEach((itemDTO) => {
      const item = new LanguageEditItem(languageService, this, itemDTO.ID, itemDTO.DescriptionID, itemDTO.Editable, itemDTO.SupportsHtml, source.Entries[itemDTO.ID]);
      result.push(item);
    });

    return result;
  }

  public save(id: string, value: LanguageItem) {
    const location = this.endPoint + "/" + encodeURI(id);
    this.update(value, location).subscribe();
  }
}
