import { NgModule } from "@angular/core";
import { EmployeeGirdCellTemplatePipe } from "./employee-grid-cell-template";

@NgModule({
  declarations: [EmployeeGirdCellTemplatePipe],
  imports: [],
  providers: [],
  exports: [EmployeeGirdCellTemplatePipe],
})
export class EmployeeGridModule {}
