import { Component, Input } from "@angular/core";

import { ChoiceDefinition } from "../../choices/targets/targetdefinition/target-definition.model";
import { CMSMessage } from "../../cms/cms-message.model";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";

@Component({
  selector: "app-labour-detail-message-component",
  templateUrl: "./labour-detail-message.component.html",
  styleUrls: ["./labour-detail.component.scss"],
})
export class LabourConditionDetailMessageComponent {
  public constructor(public language: CmsLanguage) {}

  @Input() message: CMSMessage;
  @Input() targetDefinitions: ChoiceDefinition[];
}
