import { LanguageMetaDataDto } from "../../common/models/dto/LanguageMetaData-dto";
/* eslint-disable max-classes-per-file */
export type LanguageItem = [string, string];

// Id -> Language -> Value (ie. "AppName"" -> "EN"" -> "f3Me )
export type LanguageDictionary = [string, LanguageItem];

export class LanguageEditDataDTO {
  public Entries: LanguageDictionary;
  public MetaData: LanguageEditMetaDataDTO;
}

export class LanguageEditMetaDataDTO {
  public Items: LanguageEditItemDTO[];
  public Languages: LanguageMetaDataDto[];
}

export class LanguageEditItemDTO {
  public Editable: boolean;
  public ID: string;
  public DescriptionID: string;
  public SupportsHtml: boolean;
}
