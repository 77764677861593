import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { ElementHelper } from "../../angular-common/helpers/element.helper";
import { LanguageItem } from "../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { Link } from "../components/links/link";
import { CmsMessageDto } from "../models/cms/dto/CmsMessage-dto";
import { MessageOptionsDto } from "../models/cms/dto/MessageOptions-dto";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";
import { CmsLanguage } from "./language/cmslanguage.service";

export class CMSMessage {
  public static createEmpty(languageService: CmsLanguage, selectLanguage: PlLanguageSelection, domSanitizer: DomSanitizer, messageType: MessageTypeDto, newLink: Link): CMSMessage {
    const builder = languageService.languageBuilder();

    const message = new CMSMessage(
      languageService,
      selectLanguage,
      domSanitizer,
      -1,
      messageType,
      "",
      "",
      new Date(Date.now()),
      "",
      builder.createLanguageItem(),
      builder.createLanguageItem(),
      builder.createLanguageItem(),
      newLink,
      -1,
      -1,
      MessageOptionsDto.Delete,
    );

    message.newsSummary[selectLanguage.currentLanguage] = " ";
    message.newsContent[selectLanguage.currentLanguage] = " ";
    return message;
  }

  public imageLoaded: boolean = false;
  public imageUrlProtected: string;

  public constructor(
    private languageService: CmsLanguage,
    private selectLanguage: PlLanguageSelection,
    private domSanitizer: DomSanitizer,
    public id: number,
    public messageType: MessageTypeDto,
    public location: string,

    public visibilityExpression: string,

    public date: string | number | Date,
    public originalImageUrl: string,

    public newsTitle: LanguageItem,
    public newsSummary: LanguageItem,
    public newsContent: LanguageItem,

    public link: Link,
    public emailTemplateId: number,
    public groupId: number,

    public options: MessageOptionsDto,
  ) {}

  public get isNew() {
    return this.id === undefined || this.id === null || this.id < 0;
  }

  public get hasStartDate(): boolean {
    return this.messageType !== MessageTypeDto.homepagebanner && !this.isEbs;
  }

  public get canHaveImage(): boolean {
    return !this.isSimulation && !this.isEbs;
  }

  public get canAdjustContent(): boolean {
    return this.isLabourCondition;
  }

  public get canAdjustSummary(): boolean {
    return !this.isSimulation && this.messageType !== MessageTypeDto.unknown && this.messageType !== MessageTypeDto.carousel;
  }

  public get canHaveLink(): boolean {
    return !this.isEbs && this.messageType !== MessageTypeDto.homepagebanner && this.messageType !== MessageTypeDto.unknown;
  }

  public get isLabourCondition(): boolean {
    return this.messageType === MessageTypeDto.labourcondition;
  }

  public get isSimulation(): boolean {
    return this.messageType === MessageTypeDto.simulationblock;
  }

  public get isEbs(): boolean {
    return this.messageType === MessageTypeDto.ebsintro;
  }

  public get canDelete(): boolean {
    return this.messageType !== MessageTypeDto.homepagebanner && (MessageOptionsDto.Delete & this.options) === MessageOptionsDto.Delete;
  }

  public get hasImage(): boolean {
    return (this.originalImageUrl || "").length > 0;
  }

  public get title(): string {
    return this.selectLanguage.currentValueFor(this.newsTitle);
  }

  public get content(): string {
    return this.selectLanguage.currentValueFor(this.newsContent);
  }

  public get summary(): string {
    return this.selectLanguage.currentValueFor(this.newsSummary);
  }

  public get summarySanitized(): SafeHtml {
    return this.getSanitized(this.summary);
  }

  public get contentSanitized(): SafeHtml {
    return this.getSanitized(this.content);
  }

  public get imageURL(): string {
    if (this.hasImage) {
      if (ElementHelper.isNullOrUndefined(this.imageUrlProtected) == false) {
        return this.imageUrlProtected;
      }

      return this.originalImageUrl;
    }
  }

  private getSanitized(content: string): SafeHtml {
    if (content && content !== null) {
      return this.domSanitizer.bypassSecurityTrustHtml(content);
    }

    return content;
  }

  public copyFrom(source: CMSMessage, includeId: boolean): void {
    if (includeId) {
      this.id = source.id;
    }

    this.messageType = source.messageType;
    const builder = this.languageService.languageBuilder();
    this.newsTitle = builder.copyLanguageItem(source.newsTitle);
    this.newsSummary = builder.copyLanguageItem(source.newsSummary);
    this.newsContent = builder.copyLanguageItem(source.newsContent);
    this.originalImageUrl = source.originalImageUrl;
    this.imageUrlProtected = source.imageUrlProtected;
    this.imageLoaded = source.imageLoaded;
    this.location = source.location;
    this.visibilityExpression = source.visibilityExpression;
    this.date = source.date;
    this.groupId = source.groupId;

    this.link.copyFrom(source.link);
    this.emailTemplateId = source.emailTemplateId;
    this.options = source.options;
  }

  public getCopy(): CMSMessage {
    const builder = this.languageService.languageBuilder();
    const result = new CMSMessage(
      this.languageService,
      this.selectLanguage,
      this.domSanitizer,
      this.id,
      this.messageType,
      this.location,
      this.visibilityExpression,
      this.date,
      this.imageURL,
      builder.copyLanguageItem(this.newsTitle),
      builder.copyLanguageItem(this.newsSummary),
      builder.copyLanguageItem(this.newsContent),
      this.link.getCopy(),
      this.emailTemplateId,
      this.groupId,
      this.options,
    );

    result.originalImageUrl = this.originalImageUrl;
    result.imageLoaded = this.imageLoaded;
    result.imageUrlProtected = this.imageUrlProtected;

    return result;
  }

  public toDTO(): CmsMessageDto {
    const result = new CmsMessageDto();

    result.Title = this.newsTitle;
    result.Date = this.date as Date;
    result.Id = this.id;
    result.MessageType = this.messageType;
    result.ImageURL = this.originalImageUrl;
    result.Location = this.location;
    result.VisibilityExpression = this.visibilityExpression;
    result.Content = this.newsContent;
    result.Summary = this.newsSummary;
    result.EmailTemplateId = this.emailTemplateId;
    result.Options = this.options;
    result.GroupId = this.groupId;
    result.Link = this.link.toDTO();

    return result;
  }
}
