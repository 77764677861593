import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class InternalLink {
  public constructor(private selectLanguage: PlLanguageSelection) {}

  public text: LanguageItem;
  public location: string;
  public isNavigationRoute: boolean = false;

  public get title() {
    return this.selectLanguage.currentValueFor(this.text);
  }
}
