import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";

import { map } from "rxjs/operators";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { TargetSettings } from "../../choices/targets/targetsettings";
import { CMSMessage } from "../../cms/cms-message.model";
import { CMSService } from "../../cms/cms.service";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EmailTemplate } from "../../email/consume-mail/email-template";
import { EmailTemplateEditService } from "../../email/emailtemplateedit/emailtemplate-edit.service";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { LabourConditionLink } from "../labourcondition-link/labourcondition-link.model";
import { LabourConditionLinkService } from "../labourcondition-link/labourcondition-link.service";

@Component({
  selector: "app-arrange-labourconditions-choices-as-input",
  templateUrl: "./cms-labourcondtion-link-edit.component.html",
  styleUrls: ["./cms-labourcondtion-link-edit.component.scss"],
})
export class CmsLabourConditionLinkEditChoicesAsInputComponent implements OnInit {
  public lcLinks: LabourConditionLink[];
  public emailTemplates: EmailTemplate[] = [];
  public labourConditions: CMSMessage[] = [];

  @Input()
  public targets: TargetSettings[] = [];

  constructor(public language: CmsLanguage, private lcLinkService: LabourConditionLinkService, private emailService: EmailTemplateEditService, private messageService: CMSService) {
    this.targets = [];
  }

  ngOnInit() {
    const obs = [this.getLabourConditions(), this.getEmailTemplates()];
    forkJoin(obs).subscribe(() => {
      this.getLinks();
    });
  }

  public get hasTargets() {
    return this.targets && this.targets.length > 0;
  }

  public get contentReady(): boolean {
    return ElementHelper.isNullOrUndefined(this.lcLinks) === false;
  }

  getLabourConditions(): Observable<void> {
    return this.messageService.getMessages(MessageTypeDto.labourcondition).pipe(
      map((m) => {
        this.labourConditions = m;
      }),
    );
  }

  getEmailTemplates(): Observable<void> {
    return this.emailService.getAll().pipe(
      map((e) => {
        this.emailTemplates = e;
      }),
    );
  }

  getLinks() {
    this.lcLinkService.retrieveLinks().subscribe((r) => {
      this.lcLinks = r;
    });
  }

  public newLcLink() {
    const newLink = LabourConditionLink.createDefault();
    this.lcLinks.push(newLink);
  }

  public rowUpdated(input: { data: LabourConditionLink }) {
    if (input.data) {
      if (input.data.keyId) {
        this.lcLinkService.updateLink(input.data).subscribe();
      } else {
        this.lcLinkService.createLink(input.data).subscribe((v) => {
          input.data.keyId = v.data.KeyId;
        });
      }
    }
  }

  public rowDeleted(input: { data: LabourConditionLink }) {
    if (input.data) {
      this.lcLinkService.deleteLink(input.data).subscribe();
    }
  }
}
