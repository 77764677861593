import { Observable } from "rxjs";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { MessageRatingService } from "./message-rating.service";
import { MessageRatingMetaData } from "./models/message-rating-metadata.model";
import { MessageRating } from "./models/message-rating.model";
import { MessageRatings } from "./models/message-ratings.model";

export class MessageRatingBuffer {
  private _buffer = [];
  public constructor(private messageRatingService: MessageRatingService) {}

  private containsRatingsFor(messageType: MessageTypeDto): boolean {
    return this._buffer[messageType] !== undefined;
  }

  private _getRatingsObservable: Observable<MessageRatings> = undefined;

  public getRatingMetaDataFor(messageType: MessageTypeDto): MessageRatingMetaData {
    this.updateBufferFor(messageType);

    return this.getMetaDataFromBufferOrEmpty(messageType);
  }

  public getRatingFor(messageType: MessageTypeDto, messageId: number): MessageRating {
    this.updateBufferFor(messageType);

    return this.getRatingFromBufferOrEmpty(messageType, messageId);
  }

  private updateBufferFor(messageType: MessageTypeDto) {
    if (this.containsRatingsFor(messageType) === false && this._getRatingsObservable === undefined) {
      this._getRatingsObservable = this.messageRatingService.getRatings(messageType);
      this._getRatingsObservable.subscribe((newRatings) => {
        this._buffer[messageType] = newRatings;
        this._getRatingsObservable = undefined;
        this.messageRatingService.notifyDataAvailable();
      });
    }
  }

  private getMetaDataFromBufferOrEmpty(messageType: MessageTypeDto): MessageRatingMetaData {
    if (this.containsRatingsFor(messageType)) {
      const ratings = this._buffer[messageType] as MessageRatings;
      return ratings.metaData;
    }

    const result = new MessageRatingMetaData();
    result.maxScore = 5;
    return result;
  }

  private getRatingFromBufferOrEmpty(messageType: MessageTypeDto, messageId: number): MessageRating {
    if (this.containsRatingsFor(messageType)) {
      const ratings = this._buffer[messageType] as MessageRatings;
      const existingRating = ratings.ratings.find((r) => r.messageId === messageId);
      if (ElementHelper.isNullOrUndefined(existingRating) === false) {
        return existingRating;
      }
    }

    const result = new MessageRating();
    result.messageId = messageId;
    return result;
  }

  public invalidateFor(messageType: MessageTypeDto) {
    this._buffer[messageType] = undefined;
    this.messageRatingService.notifyDataAvailable();
  }
}
