import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EbsConfigurationDto } from "../../models/ebs/dto/EbsConfigurationDto-dto";
import { ModelElementWithTitle } from "../../../common/modelelements/element-with-title";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class EbsConfiguration extends ModelElementWithTitle {
  public static createDefault(languageService: CmsLanguage, selectLanguage: PlLanguageSelection): EbsConfiguration {
    const newDefault = new EbsConfiguration(selectLanguage);
    newDefault.isSummery = false;
    newDefault.cmsGroupingId = null;
    newDefault.shortName = "";
    newDefault.variantId = null;
    newDefault.id = -1;
    newDefault.cmsMessageId = null;
    newDefault.titles = languageService.getEntry("f3MeWebApiNoGroup");

    return newDefault;
  }

  public toDto(): EbsConfigurationDto {
    const dto = new EbsConfigurationDto();
    dto.VariantId = this.variantId ?? 0;
    dto.ShortName = this.shortName;
    dto.IsSummery = this.isSummery;
    dto.CmsMessageId = this.cmsMessageId ?? -1;
    dto.Id = this.id;
    dto.CmsGroupingId = this.cmsGroupingId ?? -1;
    dto.CmsTitle = this.titles;
    return dto;
  }

  /**
   * copies the values from another EbsConfiguration
   */
  public copyFrom(config: EbsConfiguration) {
    this.cmsGroupingId = config.cmsGroupingId;
    this.cmsMessageId = config.cmsMessageId;
    this.titles = config.titles;
    this.isSummery = config.isSummery;
    this.shortName = config.shortName;
    this.variantId = config.variantId;

    if (config.id > 0) {
      this.id = config.id;
    }
  }

  public constructor(selectLanguage: PlLanguageSelection) {
    super(selectLanguage);
  }

  public id: number;
  public shortName: string;
  public variantId: number;
  public cmsGroupingId: number;
  public isSummery: boolean;
  public cmsMessageId: number;

  public copyFromDto(dto: EbsConfigurationDto) {
    this.id = dto.Id;
    this.shortName = dto.ShortName;
    this.variantId = dto.VariantId;
    this.isSummery = dto.IsSummery;
    this.cmsGroupingId = dto.CmsGroupingId;
    this.cmsMessageId = dto.CmsMessageId;
    this.titles = dto.CmsTitle;
  }

  public createCopy() {
    const copy = new EbsConfiguration(super.selectLanguage);
    copy.cmsGroupingId = this.cmsGroupingId;
    copy.titles = this.titles;
    copy.id = this.id;
    copy.shortName = this.shortName;
    copy.variantId = this.variantId;
    copy.isSummery = this.isSummery;
    copy.cmsMessageId = this.cmsMessageId;
    copy.titles = this.titles;

    return copy;
  }
}
