import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appSafeData]",
})
export class SafeDataDirective implements OnInit {
  @Input() passedHtmlData;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const elem: Element = this.el.nativeElement;
    elem.innerHTML = this.passedHtmlData;
  }
}
