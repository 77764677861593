<dx-popup [showTitle]="true" [title]="interaction.title" [(visible)]="interaction.isVisible" [width]="popupWidth"
  [height]="'auto'">
  <div *dxTemplate="let data of 'content'">
    <p *ngIf="hasShortMessage">{{ interaction.message }}</p>
    <dx-form id="form">
      <dxi-item *ngIf="!interaction.showTextInput && !hasShortMessage">
        <textarea readonly class="messagebody dx-theme-generic-typography">{{ interaction.message }}</textarea>
      </dxi-item>
      <dxi-item *ngIf="interaction.showTextInput" [dataField]="interaction.message">
        <dx-text-box valueChangeEvent="keyup" [(value)]="interaction.textInput"></dx-text-box>
      </dxi-item>
    </dx-form>
    <div class="button-container bottom-container align-right">
      <dx-button [disabled]="!interaction.okEnabled" [text]="interaction.okButton"
        (click)="interaction.closePopup(true)" *ngIf="interaction.showOkButton === true"></dx-button>
      <dx-button [text]="interaction.cancelButton" (click)="interaction.closePopup(false)"></dx-button>
    </div>
  </div>
</dx-popup>