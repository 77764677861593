
// t4tsCode gen": "0.8.2.0 for file LabourConditionLinkDto.cs
// Don't adjust manually!

export class LabourConditionLinkDto {
    public KeyId: number;
    public CMSMessageId: number;
    public TargetId: number;
    public EmailTemplateId: number;
    public ConfirmationEmailId: number;
}

