
// t4tsCode gen": "0.8.2.0 for file MessageType.cs
// Don't adjust manually!
export enum MessageTypeDto {
        unknown = 0,
        news = 1,
        carousel = 2,
        labourcondition = 3,
        homepagebanner = 4,
        simulationblock = 5,
        ebsintro = 6,
}

