import { IMove } from "../../../angular-common/components/listselector/imove";
import { ModelElementSpecification } from "../../../common/modelelements/modelelementsspecification";

export class CmsSimEditMove implements IMove {
  constructor(public currentConfig: ModelElementSpecification[]) {}
  moveLeft(selectedItems: ModelElementSpecification[]): boolean {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach((si) => {
        this.currentConfig.splice(
          this.currentConfig.findIndex((s) => s.shortName === si.shortName),
          1,
        );
      });
    }
    return true;
  }

  moveRight(selectedItems: ModelElementSpecification[]): boolean {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach((s) => {
        s.sortOrder = this.currentConfig.length;
        s.keyId = -1;
        this.currentConfig.push(s);
      });
      return true;
    }
  }
}
