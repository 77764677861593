<div *ngIf="targetProperties" class="optionBox">
  <div *ngIf="targetProperties.exogenousInput.description" class="header subHeader" appSafeData [passedHtmlData]="targetProperties.exogenousInput.description"></div>

  <div *ngIf="showOptionControls">
    <div class="subHeader" *ngIf="submitted === false">
      <app-exogenous-input-detail-options-component [options]="targetProperties.exogenousInput.options"> </app-exogenous-input-detail-options-component>

      <div class="header" id="targetRequired" *ngIf="targetProperties.exogenousInput.required">
        <p>{{ languageService.f3MeWebApiTargetRequired }}{{ targetProperties.exogenousInput.required.value }}</p>
      </div>
    </div>

    <div class="subHeader" *ngIf="submitted === false && targetProperties.exogenousInput.sources.length > 0">
      <app-target-detail-sources-component [sources]="targetProperties.exogenousInput.sources"> </app-target-detail-sources-component>

      <div class="header" *ngIf="targetProperties.exogenousInput.invested || targetProperties.exogenousInput.remaining">
        <p id="targetInvested" *ngIf="targetProperties.exogenousInput.invested">{{ languageService.f3MeWebApiTargetInvested }}{{ targetProperties.exogenousInput.invested.value }}</p>
        <p id="targetRemaining" *ngIf="targetProperties.exogenousInput.remaining">{{ languageService.f3MeWebApiTargetRemaining }}{{ targetProperties.exogenousInput.remaining.value }}</p>
      </div>
    </div>

    <div *ngIf="submitted" class="submittedHeader">
      <p>{{ submitConfirmation }}</p>
    </div>

    <div class="buttonsBox" *ngIf="submitted === false">
      <div class="buttons">
        <dx-button [text]="languageService.f3MeWebApiTargetOrder" [disabled]="!targetProperties.exogenousInput.submitAllowed && submitted === false" (click)="submit()" icon="check"></dx-button>
      </div>
    </div>
  </div>
</div>
