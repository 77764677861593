<app-textpopup-component [interaction]="disclaimerPopup"></app-textpopup-component>

<div>
  <h1>{{ disclaimerPopup.title }}</h1>

  <p>{{ disclaimerPopup.message }}</p>

  <dx-check-box [disabled]="true" [value]="userHasAcknowledged" [text]="languageService.f3MeWebApiDisclaimerAccepted"> </dx-check-box>
  <hr />

  <dx-button
    [disabled]="!shouldConfirm"
    id="addButton"
    icon="fas fa-unlock"
    [text]="languageService.f3MeWebApiDisclaimerAccept"
    [hint]="disclaimerPopup.message"
    (onClick)="showDisclaimerPopup()"
  ></dx-button>
</div>
