export class BenefitValue {
  public constructor(
    public valueAsText: string,
    public valueAsNumber: number,
    public appliesToTotal: boolean,
    public visible: boolean,
  ) { }

  public get isEmpty(): boolean {
    return (
      this.valueAsText === undefined ||
      this.valueAsText === null ||
      this.valueAsText === ""
    );
  }
}
