import { Component, OnInit } from "@angular/core";

import { LabourgroupService } from "../../cms/labourgroups/labourgroup.service";
import { LabourConditionGroup } from "../../cms/labourgroups/lc-group";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";

@Component({
  selector: "app-labour-component",
  templateUrl: "./labour.component.html",
  styleUrls: ["./labour.component.scss"],
})
export class LabourConditionComponent implements OnInit {
  public groups: LabourConditionGroup[];

  public constructor(private groupService: LabourgroupService, public languageService: CmsLanguage) {}

  public ngOnInit(): void {
    this.groupService.getGroups().subscribe((r) => {
      this.groups = r;
    });
  }
}
