<form name="form" (submit)="onFormSubmit($event)">
  <dx-form [colCount]="2" validationGroup="loginData">
    <dxi-item [colSpan]="2">
      <dxo-label [text]="language.loginUserName"></dxo-label>
      <div *dxTemplate>
        <dx-text-box name="username" [(value)]="usernameValue" class="align-right">
          <dx-validator>
            <dxi-validation-rule type="required" [message]="language.loginUserNameRequired"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </dxi-item>
    <dxi-item [colSpan]="2">
      <dxo-label [text]="language.loginPassword"></dxo-label>
      <div *dxTemplate>
        <dx-text-box name="password" [(mode)]="buttonOptions.passwordMode" [(value)]="passwordValue" class="align-right">
          <dxi-button name="eye" [options]="buttonOptions.options" location="after"></dxi-button>
          <dx-validator>
            <dxi-validation-rule type="required" [message]="language.loginPasswordRequired"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </dxi-item>
    <dxi-item [colSpan]="2">
      <div *dxTemplate>
        <div class="align-right">
          <dx-validation-summary id="summary"></dx-validation-summary>
        </div>
      </div>
    </dxi-item>
    <dxi-item [colSpan]="2">
      <div *dxTemplate>
        <div class="button-container bottom-container align-right">
          <dx-button [disabled]="loading" type="default" icon="user" [useSubmitBehavior]="true" [text]="language.loginButtonLogin"> </dx-button>
        </div>
      </div>
    </dxi-item>
  </dx-form>
  <p *ngIf="error" class="warning">{{ error }}</p>
</form>
