
// t4tsCode gen": "0.8.2.0 for file CmsRolesNames.cs
// Don't adjust manually!

export class CmsRolesNamesDto {
    public static ContentDesigner: string = "Content designer";
    public static ArrangeDesigner: string = "Arrange designer";
    public static EmployeeSupervisor: string = "Employee Supervisor";
    public static ViewOtherUsersData: string = "View data of other users";
}

