import { ElementHelper } from "../../angular-common/helpers/element.helper";
import { DateDto } from "../models/dto/DateDto-dto";
import { DateTimeDto } from "../models/dto/DateTimeDto-dto";

export class DateTranslator {
  static toTextDate(value: Date, emptyValue: string): string {
    if (ElementHelper.isNullOrUndefined(value)) {
      return emptyValue;
    } else {
      return value.toLocaleDateString() + " " + value.toLocaleTimeString();
    }
  }
  static fromDto(value: DateTimeDto): Date {
    if (ElementHelper.isNullOrUndefined(value)) {
      return undefined;
    } else {
      const yearMonthDay = DateTranslator.splitIntoThreeNumbers(value.DateAsSortableNumber);
      const hourMinuteSeconds = DateTranslator.splitIntoThreeNumbers(value.TimeAsSortableNumber);

      const date = new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0], hourMinuteSeconds[2], hourMinuteSeconds[1], hourMinuteSeconds[0]);
      return date;
    }
  }
  static fromDateDto(value: DateDto): Date {
    return DateTranslator.fromDto(<DateTimeDto>value);
  }

  private static splitIntoThreeNumbers(value: number) {
    const values: number[] = [];

    if (value !== undefined) {
      let left = value;

      let value1 = left % 100;
      left = (left - value1) / 100;

      let value2 = left % 100;
      left = (left - value2) / 100;

      values.push(value1, value2, left);
    } else {
      values.push(0, 0, 0);
    }
    return values;
  }

  public static toDto(value: Date) {
    let dto = new DateTimeDto();
    dto.DateAsSortableNumber = DateTranslator.toSortableIntDate(value);
    dto.TimeAsSortableNumber = DateTranslator.toSortableIntTime(value);
    return dto;
  }

  public static toDateDto(value: Date) {
    let dto = new DateDto();
    dto.DateAsSortableNumber = DateTranslator.toSortableIntDate(value);
    return dto;
  }

  public static toSortableIntDate(value: Date) {
    if (ElementHelper.isNullOrUndefined(value)) {
      return 0;
    }

    let number = 0;
    number += value.getDate();
    number += 100 * (value.getMonth() + 1);
    number += 100 * 100 * value.getFullYear();

    return number;
  }

  public static toSortableIntTime(value: Date) {
    if (ElementHelper.isNullOrUndefined(value)) {
      return 0;
    }

    let number = 0;
    number += value.getSeconds();
    number += 100 * value.getMinutes();
    number += 100 * 100 * value.getHours();

    return number;
  }

  public static fromSortableInt(value: number) {
    const year = Math.floor(value / 10000);
    const month = Math.floor((value % 10000) / 100) - 1;
    const day = value % 100;
    return new Date(year, month, day);
  }
}
