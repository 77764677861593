import { Observable, ReplaySubject } from "rxjs";

// Class to help with switching a view from editing to not editing.
export class EditToggle {
  constructor() {
    this._stopEditingSource = new ReplaySubject<boolean>();
    this.stopEditing = this._stopEditingSource.asObservable();
  }

  public get editing(): boolean {
    return this._editing;
  }
  public set editing(newEditing: boolean) {
    this._editing = newEditing;
  }
  private _editing: boolean = false;

  public startEditing() {
    this.editing = true;
  }

  public cancelEditing() {
    this.editing = false;
    this._stopEditingSource.next(false);
  }

  public saveAndStopEditing() {
    this.editing = false;
    this._stopEditingSource.next(true);
  }

  private _stopEditingSource: ReplaySubject<boolean>;
  public stopEditing: Observable<boolean>;
}
