import { ExogenousInputOptionDto } from "../../../../models/me/dto/ExogenousInputOption-dto";
import { ExogenousInputOptionCreator } from "./exogenous-input-option-creator";
import { ExogenousInputOptionObserver } from "./exogenous-input-option-observer";
import { ExogenousInputOption } from "./exogenous-input-option.model";

export class TExogenousInputOptionSynchronizer {
  public static synchronize(source: ExogenousInputOptionDto[], target: ExogenousInputOption[], targetObserver: ExogenousInputOptionObserver) {
    // Remove items no longer present
    for (let i = 0; i < target.length; i++) {
      const sourceOption = source.find((x) => x.Identification === target[i].identification);
      if (sourceOption === undefined) {
        targetObserver.remove(target[i]);
        target.splice(i);
      }
    }

    // Add new items or update existing
    source.forEach((s) => {
      const targetOption = target.find((x) => x.identification === s.Identification);
      if (targetOption === undefined) {
        const newOption = ExogenousInputOptionCreator.createTargetOption(s, targetObserver);
        TExogenousInputOptionSynchronizer.insertOption(newOption, target);
      } else {
        targetOption.copyFromDTO(s);
      }
    });
  }

  // Insert, respecting sort order
  private static insertOption(option: ExogenousInputOption, target: ExogenousInputOption[]) {
    if (target.length === 0) {
      target.push(option);
    } else {
      for (let i = 0; i < target.length; i++) {
        if (target[i].sortOrder > option.sortOrder) {
          target.splice(i, 0, option);
          return;
        }
      }

      target.push(option);
    }
  }
}
