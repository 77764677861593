<div class="targetOptionContainer" [id]="option.shortName" *ngIf="option.isVisible">
  <div [ngSwitch]="option.type">
    <div class="targetOption" *ngSwitchCase="targetOptionType.Text">
      <app-exogeous-input-text-option [valueOnly]="valueOnly" [option]="option | cast"></app-exogeous-input-text-option>
    </div>
    <div class="targetOption" *ngSwitchCase="targetOptionType.Date">
      <app-exogeous-input-date-option [valueOnly]="valueOnly" [option]="option | cast"></app-exogeous-input-date-option>
    </div>
    <div class="targetOption" *ngSwitchCase="targetOptionType.Choice">
      <app-exogeous-input-choice-option [valueOnly]="valueOnly" [option]="option | cast"></app-exogeous-input-choice-option>
    </div>
    <div class="targetOption" *ngSwitchCase="targetOptionType.Numeric">
      <app-exogeous-input-numeric-option [valueOnly]="valueOnly" [option]="option | cast"></app-exogeous-input-numeric-option>
    </div>
    <div class="targetOption" *ngSwitchCase="targetOptionType.Bool">
      <app-exogeous-input-bool-option [valueOnly]="valueOnly" [option]="option | cast"></app-exogeous-input-bool-option>
    </div>
    <div class="targetOption" *ngSwitchCase="targetOptionType.Attachment">
      <app-exogeous-input-attachment-option [valueOnly]="valueOnly" [option]="option | cast"></app-exogeous-input-attachment-option>
    </div>
    <div *ngSwitchDefault>No rendering for {{ option.name }}</div>
  </div>
  <div class="targetOptionMessage">
    <div *ngIf="option.error.hasMessage" class="optionError">
      <div class="error">
        <i class="fa fa-thumbs-down" id="{{ 'err' + option.shortName }}" (mouseenter)="option.errTooltipVisible = true" (mouseleave)="option.errTooltipVisible = false"></i>
        <dx-tooltip target="#{{ 'err' + option.shortName }}" [(visible)]="option.errTooltipVisible">
          <div *dxTemplate="let data = data; of: 'content'">
            {{ option.error.text }}
          </div>
        </dx-tooltip>
      </div>
    </div>

    <div *ngIf="option.warning.hasMessage" class="optionWarning">
      <div class="warning">
        <i class="fa fa-info-circle" id="{{ 'warn' + option.shortName }}" (mouseenter)="option.warnTooltipVisible = true" (mouseleave)="option.warnTooltipVisible = false"></i>
        <dx-tooltip target="#{{ 'warn' + option.shortName }}" [(visible)]="option.warnTooltipVisible">
          <div *dxTemplate="let data = data; of: 'content'">
            {{ option.warning.text }}
          </div>
        </dx-tooltip>
      </div>
    </div>

    <div *ngIf="option.hasHelpUrl" class="optionHelp">
      <a href="{{ option.helpUrl }}" target="_blank">
        <i class="fas fa-external-link-square-alt optionHelp-link-icon" id="{{ 'help' + option.shortName }}"></i>
      </a>
    </div>
  </div>
</div>
