<div [ngClass]="isOddIndex === false ? 'cb' : ''" class="col-md-6 col-sm-6 col-xs-12 blocks mw-50" *ngIf="message">
  <a routerLinkActive="active">
    <div>
      <h6 class="lc-header" style="word-wrap: break-word">{{ message.title }}</h6>
    </div>
    <figure *ngIf="message.imageLoaded">
      <img [src]="message.imageURL" />
    </figure>
  </a>
  <div *ngIf="message.summary" appSafeData [passedHtmlData]="message.summary"></div>
  <div class="lc-link-on-image-filler">
    <app-cms-link class="lc-link-on-image-link" [link]="message.link"></app-cms-link>
  </div>
  <app-appcomponentspecific-content-view [type]="choiceDynamicComponentType" [data]="choiceData"></app-appcomponentspecific-content-view>
  <app-rating-view *ngIf="ratingEnabled" [message]="message"></app-rating-view>
</div>
