<div *ngIf="options" class="maindiv">
  <div *ngFor="let option of options">
    <div [id]="option.shortName" class="option">
      <div class="grid-container">
        <!-- General info about the option -->
        <div class="optionDescr">
          <p>{{ option.description }}</p>
        </div>
        <div></div>

        <!-- Specific input control(s) -->
        <app-exogeous-input-option class="optionValue" [valueOnly]="true" [option]="option"></app-exogeous-input-option>
      </div>
    </div>
  </div>
</div>
