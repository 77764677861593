import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PreferedUserEmployeeLoginComponent } from "./prefered-user-employee-login.component";
import { PreferedUserToEmployeeLoginService } from "./prefered-user-employee-login.service";

@NgModule({
  imports: [CommonModule],
  exports: [PreferedUserEmployeeLoginComponent],
  declarations: [PreferedUserEmployeeLoginComponent],
  providers: [PreferedUserToEmployeeLoginService],
})
export class PreferedUserEmployeeLoginModule {}
