import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { Variant } from "../../../common/model/models/variant-model";
import { ModelElementSpecification } from "../../../common/modelelements/modelelementsspecification";
import { ReportDataDto } from "../../models/reporting/dto/ReportDataDto-dto";
import { ReportDefinitionDto } from "../../models/reporting/dto/ReportDefinitionDto-dto";
import { ReportMetaDataDto } from "../../models/reporting/dto/ReportMetaDataDto-dto";
import { ReportingLanguage } from "../language/reporting.service";
import { ReportData } from "./models/reportdata";
import { ReportDefinition } from "./models/reportdefinition";
import { ReportMetaData } from "./models/reportmetadata";

@Injectable()
export class ReportingManagementService extends PlBaseService {
  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private languageService: ReportingLanguage) {
    super(http, envSettings, loadIndicator);
  }
  private endpoint = "reportedit";
  private reportDataEndPoint = "reportdata";

  public retrieveAllReports(): Observable<ReportMetaData> {
    return this.getDataTransformed<ReportMetaDataDto>(this.endpoint).pipe(map((r) => this.translateDefinitions(r)));
  }

  translateDefinitions(r: ReportMetaDataDto): ReportMetaData {
    if (r) {
      const results: ReportMetaData = new ReportMetaData();
      r.Reports.forEach((dto) => {
        results.reports.push(this.translateSingleDefinition(dto));
      });
      r.Variants.forEach((v) => {
        const variant = new Variant();
        variant.copyFromDto(v);
        results.variants.push(variant);
      });
      r.ModelElements.forEach((e) => {
        const modelElement = new ModelElementSpecification();
        modelElement.copyFromDto(e);
        results.modelElements.push(modelElement);
      });
      results.roles = r.Roles;
      results.reportNames = r.AvailableReports;
      results.allowedFileTypes = r.FileTypesAllowed;

      return results;
    }
  }

  translateSingleDefinition(dto: ReportDefinitionDto): ReportDefinition {
    if (dto) {
      const definition = new ReportDefinition();
      definition.fromDefintionDto(dto);

      return definition;
    }
  }

  addNewReport(newEntry: ReportDefinition): Observable<ReportDefinition> {
    return this.postDataTransformed<ReportDefinitionDto, ReportDefinition>(this.endpoint, newEntry.toDto(), (r) => this.translateSingleDefinition(r.body));
  }

  saveReport(report: ReportDefinitionDto): Observable<ReportDefinition> {
    return this.updateTransformedResponse<ReportDefinitionDto>(report, this.endpoint).pipe(map((r) => this.translateSingleDefinition(r)));
  }

  deleteReport(report: ReportDefinitionDto): Observable<Boolean> {
    const url = this.endpoint + "/" + report.KeyId;
    return this.remove<boolean>(url);
  }

  uploadNewReport(report: ReportData): Observable<ReportDataDto> {
    return this.postData(this.reportDataEndPoint, report.toDto());
  }

  retrieveReportFileContent(id: number): Observable<Blob> {
    return this.getDataAsBlob(this.reportDataEndPoint + "/" + id);
  }

  uploadUpdatedReport(report: ReportData): Observable<ReportDataDto> {
    const url = this.reportDataEndPoint + "/" + report.keyid;
    return this.update(report.toDto(), url);
  }

  retrieveAllReportData(): Observable<ReportData[]> {
    return this.getDataTransformed<ReportDataDto[]>(this.reportDataEndPoint).pipe(map((r) => this.translateReportData(r)));
  }
  translateReportData(reports: ReportDataDto[]): ReportData[] {
    const data: ReportData[] = [];
    reports.forEach((r) => {
      const report = new ReportData();
      report.fromDto(r);
      data.push(report);
    });
    return data;
  }

  deleteReportData(keyid: number): Observable<void> {
    const url = `${this.reportDataEndPoint}/${keyid}`;
    return this.remove(url);
  }

  public getDefault() {
    return ReportDefinition.createDefaultValue(this.languageService);
  }
}
