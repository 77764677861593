<app-user-employee-login-translator></app-user-employee-login-translator>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="false"
  [title]="language.f3MeWebApiMenuManagementPreferedUserEmployeeMapping"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="popupVisible"
  [position]="{ my: 'center', at: 'center', of: '#form' }"
>
  <app-employee *ngIf="isUserSelected" [currentUserMapping]="currentUserMapping.UserToEmployeeMapping" (assignClicked)="setEmployee($event)"></app-employee>
</dx-popup>

<dx-data-grid
  [dataSource]="currentMapping"
  [columnAutoWidth]="true"
  (onRowInserted)="onRowInserted($event.data)"
  (onInitNewRow)="onInitNewRow($event)"
  (onRowUpdated)="onRowUpdated($event.data)"
  (onRowRemoved)="onRowDeleted($event.data)"
>
  <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true" editCellTemplate="assignButtons"> </dxo-editing>

  <dxi-column dataField="UserToEmployeeMapping.User" [calculateDisplayValue]="userName" editCellTemplate="userEditTemplate" [caption]="language.loginUserName"></dxi-column>

  <dxi-column
    dataField="UserToEmployeeMapping.Employee.EmployeeIdentificationString"
    [caption]="language.f3MeWebApiUserToEmployeeViewDataAs"
    [calculateDisplayValue]="employeeDescription"
    editCellTemplate="employeeEditTemplate"
  ></dxi-column>

  <div *dxTemplate="let cellInfo of 'userEditTemplate'">
    <div *ngIf="!isAdding">{{ returnLongNameOrShortName(cellInfo.value) }}</div>
    <dx-lookup *ngIf="isAdding" [dataSource]="availableUsers" displayExpr="LongName" (onValueChanged)="cellInfo.setValue($event.value)"></dx-lookup>
  </div>

  <div *dxTemplate="let cellInfo of 'employeeEditTemplate'">
    {{ employeeDescriptionFromEmployeeId(cellInfo.value) }}
    <dx-button icon="chevronnext" [text]="language.f3MeWebApiUserToEmployeeButtonAssign" (onClick)="showPopupAndSetUser(cellInfo)"></dx-button>
  </div>
</dx-data-grid>
