import { DxButtonModule, DxScrollViewModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CmsLinkComponent } from "./cms-link.component";
@NgModule({
  imports: [DxButtonModule, CommonModule, DxScrollViewModule],
  exports: [CmsLinkComponent],
  declarations: [CmsLinkComponent],
})
export class CmsLinkModule {}
