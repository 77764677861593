import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColumnItemDto } from "../../../../../common/models/dto/ColumnItem-dto";
import { DataRows } from "../../../../../f3Me/src/app/approvetargets/_model/data-rows";
import { CmsLanguage } from "../../../../cms/language/cmslanguage.service";
import { EmployeeGridConfiguration } from "../../../../components/employee-grid/employee-grid-configuration";
import { EmployeeGridDateFilter } from "../../../../components/employee-grid/employee-grid-date-filter";
import { UserEmployeeMappingService } from "../user-employee-mapping.service";

@Component({
  selector: "app-employee",
  templateUrl: "employees-grid.component.html",
  styleUrls: ["employees-grid.component.scss"],
})
export class EmployeesGridComponent {
  @Input() currentUserMapping: any;
  @Output() assignClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(public language: CmsLanguage, private service: UserEmployeeMappingService) {
    this.dateFilter = new EmployeeGridDateFilter();
    this.gridConfiguration = new EmployeeGridConfiguration(this.language, this.dateFilter);
  }

  ngOnInit(): void {
    this.retrieveEmployeeData();
  }

  dateFilter: EmployeeGridDateFilter;
  gridConfiguration: EmployeeGridConfiguration;
  data: DataRows;
  columns: ColumnItemDto[];
  currentEmployeeIdentification: string;

  isActionColumn(data: any) {
    if (data.column && this.gridConfiguration.isActionColumn(data.column.dataField)) {
      return true;
    }
    return false;
  }

  private retrieveEmployeeData() {
    this.service.retrieveEmployeeData().subscribe((t) => {
      this.gridConfiguration.initialize(t.columns);
      this.columns = t.columns;
      this.data = t.data;
      this.gridConfiguration.hideModifiedColumn();
    });
  }

  emitAssign(employeeIdentification: any) {
    this.assignClicked.emit(decodeURIComponent(employeeIdentification));
  }

  isAssigned(employeeIdentification: any): boolean {
    this.currentEmployeeIdentification = this.currentUserMapping.Employee.EmployeeIdentificationString;
    return decodeURIComponent(employeeIdentification) === decodeURIComponent(this.currentEmployeeIdentification);
  }
}
