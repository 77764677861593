<div *ngIf="groups">
  <div class="buttonBar">
    <dx-button (click)="onAddRow()" [text]="language.ButtonNew" [hint]="language.ButtonNew" icon="fas fa-plus"> </dx-button>
  </div>
  <dx-data-grid
    id="groupContainer"
    [dataSource]="groups"
    autoWidth="true"
    [showBorders]="true"
    (onRowRemoved)="onRowRemoved($event.data)"
    (onEditingStart)="onEditingStart($event.data)"
    (onRowUpdated)="onRowUpdated($event.data, $event.component)"
  >
    <dxo-editing [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>
    <dxi-column dataField="Title" [caption]="language.f3MeWebApiLabourConditionGroupTitle" editCellTemplate="editCellTemplate" [calculateDisplayValue]="title"> </dxi-column>
    <dxi-column width="100px" dataField="SortOrder" [caption]="language.SortOrder"></dxi-column>

    <div *dxTemplate="let d of 'editCellTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.TitleCopy"></app-languageitem-editor-grid></div
  ></dx-data-grid>
</div>
