import { Injectable } from "@angular/core";
import { LanguageServiceBase } from "../../components/language/language-base.service";
import { PersonlizedLanguageService } from "../../components/language/personlized-language.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../angular-common";

@Injectable()
//t4Languages": "0.8.2.0
export class CmsLanguage extends LanguageServiceBase {
  public constructor(plLanguageService: PlLanguageService, languageSelection: PlLanguageSelection, personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService, languageSelection, personlizedLanguageService);
  }

  /**
   * f3MeWebApiMenuHome from Cms.csv (in EN: Home )
   */
  public key_f3MeWebApiMenuHome = "f3MeWebApiMenuHome";
  /**
   * f3MeWebApiMenuHome from Cms.csv (in EN: Home )
   */
  public get f3MeWebApiMenuHome(): string { return this.getStringDef(this.key_f3MeWebApiMenuHome, "Home"); }

  /**
   * f3MeWebApiMenuCmsLabourConditions from Cms.csv (in EN: Labour conditions )
   */
  public key_f3MeWebApiMenuCmsLabourConditions = "f3MeWebApiMenuCmsLabourConditions";
  /**
   * f3MeWebApiMenuCmsLabourConditions from Cms.csv (in EN: Labour conditions )
   */
  public get f3MeWebApiMenuCmsLabourConditions(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsLabourConditions, "Labour conditions"); }

  /**
   * f3MeWebApiMenuLabourConditions from Cms.csv (in EN: Labour conditions )
   */
  public key_f3MeWebApiMenuLabourConditions = "f3MeWebApiMenuLabourConditions";
  /**
   * f3MeWebApiMenuLabourConditions from Cms.csv (in EN: Labour conditions )
   */
  public get f3MeWebApiMenuLabourConditions(): string { return this.getStringDef(this.key_f3MeWebApiMenuLabourConditions, "Labour conditions"); }

  /**
   * f3MeWebApiMenuManagementLCGroups from Cms.csv (in EN: Labour condition groups )
   */
  public key_f3MeWebApiMenuManagementLCGroups = "f3MeWebApiMenuManagementLCGroups";
  /**
   * f3MeWebApiMenuManagementLCGroups from Cms.csv (in EN: Labour condition groups )
   */
  public get f3MeWebApiMenuManagementLCGroups(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementLCGroups, "Labour condition groups"); }

  /**
   * f3MeWebApiLCHeader from Cms.csv (in EN: Labour conditions )
   */
  public key_f3MeWebApiLCHeader = "f3MeWebApiLCHeader";
  /**
   * f3MeWebApiLCHeader from Cms.csv (in EN: Labour conditions )
   */
  public get f3MeWebApiLCHeader(): string { return this.getStringDef(this.key_f3MeWebApiLCHeader, "Labour conditions"); }

  /**
   * f3MeWebApiLCIntro from Cms.csv (in EN: Labourconditions for employees )
   */
  public key_f3MeWebApiLCIntro = "f3MeWebApiLCIntro";
  /**
   * f3MeWebApiLCIntro from Cms.csv (in EN: Labourconditions for employees )
   */
  public get f3MeWebApiLCIntro(): string { return this.getStringDef(this.key_f3MeWebApiLCIntro, "Labourconditions for employees"); }

  /**
   * f3MeWebApiLCIntroEditHeader from Cms.csv (in EN: Labour conditions intro: )
   */
  public key_f3MeWebApiLCIntroEditHeader = "f3MeWebApiLCIntroEditHeader";
  /**
   * f3MeWebApiLCIntroEditHeader from Cms.csv (in EN: Labour conditions intro: )
   */
  public get f3MeWebApiLCIntroEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiLCIntroEditHeader, "Labour conditions intro:"); }

  /**
   * f3MeWebApiAppLCHeader from Cms.csv (in EN: My benefits )
   */
  public key_f3MeWebApiAppLCHeader = "f3MeWebApiAppLCHeader";
  /**
   * f3MeWebApiAppLCHeader from Cms.csv (in EN: My benefits )
   */
  public get f3MeWebApiAppLCHeader(): string { return this.getStringDef(this.key_f3MeWebApiAppLCHeader, "My benefits"); }

  /**
   * f3MeWebApiAppLCHeaderEditHeader from Cms.csv (in EN: Labour conditions intro )
   */
  public key_f3MeWebApiAppLCHeaderEditHeader = "f3MeWebApiAppLCHeaderEditHeader";
  /**
   * f3MeWebApiAppLCHeaderEditHeader from Cms.csv (in EN: Labour conditions intro )
   */
  public get f3MeWebApiAppLCHeaderEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiAppLCHeaderEditHeader, "Labour conditions intro"); }

  /**
   * f3MeWebApiApproveTargetsChanged from Cms.csv (in EN: Modified )
   */
  public key_f3MeWebApiApproveTargetsChanged = "f3MeWebApiApproveTargetsChanged";
  /**
   * f3MeWebApiApproveTargetsChanged from Cms.csv (in EN: Modified )
   */
  public get f3MeWebApiApproveTargetsChanged(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChanged, "Modified"); }

  /**
   * f3MeWebApiApproveTargetsChangedYes from Cms.csv (in EN: Yes )
   */
  public key_f3MeWebApiApproveTargetsChangedYes = "f3MeWebApiApproveTargetsChangedYes";
  /**
   * f3MeWebApiApproveTargetsChangedYes from Cms.csv (in EN: Yes )
   */
  public get f3MeWebApiApproveTargetsChangedYes(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangedYes, "Yes"); }

  /**
   * f3MeWebApiApproveTargetsChangedNo from Cms.csv (in EN: No )
   */
  public key_f3MeWebApiApproveTargetsChangedNo = "f3MeWebApiApproveTargetsChangedNo";
  /**
   * f3MeWebApiApproveTargetsChangedNo from Cms.csv (in EN: No )
   */
  public get f3MeWebApiApproveTargetsChangedNo(): string { return this.getStringDef(this.key_f3MeWebApiApproveTargetsChangedNo, "No"); }

  /**
   * f3MeWebApiLCOrder from Cms.csv (in EN: Order )
   */
  public key_f3MeWebApiLCOrder = "f3MeWebApiLCOrder";
  /**
   * f3MeWebApiLCOrder from Cms.csv (in EN: Order )
   */
  public get f3MeWebApiLCOrder(): string { return this.getStringDef(this.key_f3MeWebApiLCOrder, "Order"); }

  /**
   * f3MeWebApiMenuArrangeLabourConditions from Cms.csv (in EN: Arrange Labourconditions )
   */
  public key_f3MeWebApiMenuArrangeLabourConditions = "f3MeWebApiMenuArrangeLabourConditions";
  /**
   * f3MeWebApiMenuArrangeLabourConditions from Cms.csv (in EN: Arrange Labourconditions )
   */
  public get f3MeWebApiMenuArrangeLabourConditions(): string { return this.getStringDef(this.key_f3MeWebApiMenuArrangeLabourConditions, "Arrange Labourconditions"); }

  /**
   * f3MeWebApiCMSEditMessage from Cms.csv (in EN: Edit )
   */
  public key_f3MeWebApiCMSEditMessage = "f3MeWebApiCMSEditMessage";
  /**
   * f3MeWebApiCMSEditMessage from Cms.csv (in EN: Edit )
   */
  public get f3MeWebApiCMSEditMessage(): string { return this.getStringDef(this.key_f3MeWebApiCMSEditMessage, "Edit"); }

  /**
   * f3MeWebApiCMSDeleteMessage from Cms.csv (in EN: Delete )
   */
  public key_f3MeWebApiCMSDeleteMessage = "f3MeWebApiCMSDeleteMessage";
  /**
   * f3MeWebApiCMSDeleteMessage from Cms.csv (in EN: Delete )
   */
  public get f3MeWebApiCMSDeleteMessage(): string { return this.getStringDef(this.key_f3MeWebApiCMSDeleteMessage, "Delete"); }

  /**
   * f3MeWebApiCMSSaveMessage from Cms.csv (in EN: Save )
   */
  public key_f3MeWebApiCMSSaveMessage = "f3MeWebApiCMSSaveMessage";
  /**
   * f3MeWebApiCMSSaveMessage from Cms.csv (in EN: Save )
   */
  public get f3MeWebApiCMSSaveMessage(): string { return this.getStringDef(this.key_f3MeWebApiCMSSaveMessage, "Save"); }

  /**
   * f3MeWebApiCMSCancelMessage from Cms.csv (in EN: Cancel )
   */
  public key_f3MeWebApiCMSCancelMessage = "f3MeWebApiCMSCancelMessage";
  /**
   * f3MeWebApiCMSCancelMessage from Cms.csv (in EN: Cancel )
   */
  public get f3MeWebApiCMSCancelMessage(): string { return this.getStringDef(this.key_f3MeWebApiCMSCancelMessage, "Cancel"); }

  /**
   * f3MeWebApiCMSMessageContent from Cms.csv (in EN: Content )
   */
  public key_f3MeWebApiCMSMessageContent = "f3MeWebApiCMSMessageContent";
  /**
   * f3MeWebApiCMSMessageContent from Cms.csv (in EN: Content )
   */
  public get f3MeWebApiCMSMessageContent(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageContent, "Content"); }

  /**
   * f3MeWebApiCMSMessageSummary from Cms.csv (in EN: Summary )
   */
  public key_f3MeWebApiCMSMessageSummary = "f3MeWebApiCMSMessageSummary";
  /**
   * f3MeWebApiCMSMessageSummary from Cms.csv (in EN: Summary )
   */
  public get f3MeWebApiCMSMessageSummary(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageSummary, "Summary"); }

  /**
   * f3MeWebApiCMSMessageDeleteImage from Cms.csv (in EN: Delete )
   */
  public key_f3MeWebApiCMSMessageDeleteImage = "f3MeWebApiCMSMessageDeleteImage";
  /**
   * f3MeWebApiCMSMessageDeleteImage from Cms.csv (in EN: Delete )
   */
  public get f3MeWebApiCMSMessageDeleteImage(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageDeleteImage, "Delete"); }

  /**
   * f3MeWebApiCMSMessageUploadImage from Cms.csv (in EN: Upload image )
   */
  public key_f3MeWebApiCMSMessageUploadImage = "f3MeWebApiCMSMessageUploadImage";
  /**
   * f3MeWebApiCMSMessageUploadImage from Cms.csv (in EN: Upload image )
   */
  public get f3MeWebApiCMSMessageUploadImage(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageUploadImage, "Upload image"); }

  /**
   * f3MeWebApiCMSMessageImageToLarge from Cms.csv (in EN: Max. size of image is {0} mb )
   */
  public key_f3MeWebApiCMSMessageImageToLarge = "f3MeWebApiCMSMessageImageToLarge";
  /**
   * f3MeWebApiCMSMessageImageToLarge from Cms.csv (in EN: Max. size of image is {0} mb )
   */
  public f3MeWebApiCMSMessageImageToLarge(val1): string { return this.getStringDef("f3MeWebApiCMSMessageImageToLarge", "Max. size of image is {0} mb").replace("{0}", val1); }

  /**
   * f3MeWebApiCMSMessageLinkTo from Cms.csv (in EN: Link to: )
   */
  public key_f3MeWebApiCMSMessageLinkTo = "f3MeWebApiCMSMessageLinkTo";
  /**
   * f3MeWebApiCMSMessageLinkTo from Cms.csv (in EN: Link to: )
   */
  public get f3MeWebApiCMSMessageLinkTo(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkTo, "Link to:"); }

  /**
   * f3MeWebApiCMSMessageVisibility from Cms.csv (in EN: Display when )
   */
  public key_f3MeWebApiCMSMessageVisibility = "f3MeWebApiCMSMessageVisibility";
  /**
   * f3MeWebApiCMSMessageVisibility from Cms.csv (in EN: Display when )
   */
  public get f3MeWebApiCMSMessageVisibility(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageVisibility, "Display when"); }

  /**
   * f3MeWebApiCMSMessageVisibilityPlaceHolder from Cms.csv (in EN: Always )
   */
  public key_f3MeWebApiCMSMessageVisibilityPlaceHolder = "f3MeWebApiCMSMessageVisibilityPlaceHolder";
  /**
   * f3MeWebApiCMSMessageVisibilityPlaceHolder from Cms.csv (in EN: Always )
   */
  public get f3MeWebApiCMSMessageVisibilityPlaceHolder(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageVisibilityPlaceHolder, "Always"); }

  /**
   * f3MeWebApiCMSMessageLinkText from Cms.csv (in EN: Link description: )
   */
  public key_f3MeWebApiCMSMessageLinkText = "f3MeWebApiCMSMessageLinkText";
  /**
   * f3MeWebApiCMSMessageLinkText from Cms.csv (in EN: Link description: )
   */
  public get f3MeWebApiCMSMessageLinkText(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkText, "Link description:"); }

  /**
   * f3MeWebApiCMSMessageLinkTextPlaceholder from Cms.csv (in EN: Description )
   */
  public key_f3MeWebApiCMSMessageLinkTextPlaceholder = "f3MeWebApiCMSMessageLinkTextPlaceholder";
  /**
   * f3MeWebApiCMSMessageLinkTextPlaceholder from Cms.csv (in EN: Description )
   */
  public get f3MeWebApiCMSMessageLinkTextPlaceholder(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkTextPlaceholder, "Description"); }

  /**
   * f3MeWebApiCMSMessageLinkExternal from Cms.csv (in EN: External website: )
   */
  public key_f3MeWebApiCMSMessageLinkExternal = "f3MeWebApiCMSMessageLinkExternal";
  /**
   * f3MeWebApiCMSMessageLinkExternal from Cms.csv (in EN: External website: )
   */
  public get f3MeWebApiCMSMessageLinkExternal(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkExternal, "External website:"); }

  /**
   * f3MeWebApiCMSMessageLinkInternal from Cms.csv (in EN: Internal page: )
   */
  public key_f3MeWebApiCMSMessageLinkInternal = "f3MeWebApiCMSMessageLinkInternal";
  /**
   * f3MeWebApiCMSMessageLinkInternal from Cms.csv (in EN: Internal page: )
   */
  public get f3MeWebApiCMSMessageLinkInternal(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkInternal, "Internal page:"); }

  /**
   * f3MeWebApiCMSMessageLinkPopup from Cms.csv (in EN: In Ebs show as popup: )
   */
  public key_f3MeWebApiCMSMessageLinkPopup = "f3MeWebApiCMSMessageLinkPopup";
  /**
   * f3MeWebApiCMSMessageLinkPopup from Cms.csv (in EN: In Ebs show as popup: )
   */
  public get f3MeWebApiCMSMessageLinkPopup(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkPopup, "In Ebs show as popup:"); }

  /**
   * f3MeWebApiCMSMessageLinkLabourCondition from Cms.csv (in EN: Content )
   */
  public key_f3MeWebApiCMSMessageLinkLabourCondition = "f3MeWebApiCMSMessageLinkLabourCondition";
  /**
   * f3MeWebApiCMSMessageLinkLabourCondition from Cms.csv (in EN: Content )
   */
  public get f3MeWebApiCMSMessageLinkLabourCondition(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageLinkLabourCondition, "Content"); }

  /**
   * f3MeWebApiCMSMessageGroup from Cms.csv (in EN: Attach to group: )
   */
  public key_f3MeWebApiCMSMessageGroup = "f3MeWebApiCMSMessageGroup";
  /**
   * f3MeWebApiCMSMessageGroup from Cms.csv (in EN: Attach to group: )
   */
  public get f3MeWebApiCMSMessageGroup(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageGroup, "Attach to group:"); }

  /**
   * f3MeWebApiCMSMessageConfirmDelete from Cms.csv (in EN: Delete? )
   */
  public key_f3MeWebApiCMSMessageConfirmDelete = "f3MeWebApiCMSMessageConfirmDelete";
  /**
   * f3MeWebApiCMSMessageConfirmDelete from Cms.csv (in EN: Delete? )
   */
  public get f3MeWebApiCMSMessageConfirmDelete(): string { return this.getStringDef(this.key_f3MeWebApiCMSMessageConfirmDelete, "Delete?"); }

  /**
   * f3MeWebApiPluginAdd from Cms.csv (in EN: Add plugin )
   */
  public key_f3MeWebApiPluginAdd = "f3MeWebApiPluginAdd";
  /**
   * f3MeWebApiPluginAdd from Cms.csv (in EN: Add plugin )
   */
  public get f3MeWebApiPluginAdd(): string { return this.getStringDef(this.key_f3MeWebApiPluginAdd, "Add plugin"); }

  /**
   * f3MeWebApiPluginShortname from Cms.csv (in EN: Name )
   */
  public key_f3MeWebApiPluginShortname = "f3MeWebApiPluginShortname";
  /**
   * f3MeWebApiPluginShortname from Cms.csv (in EN: Name )
   */
  public get f3MeWebApiPluginShortname(): string { return this.getStringDef(this.key_f3MeWebApiPluginShortname, "Name"); }

  /**
   * f3MeWebApiPluginMenuText from Cms.csv (in EN: Menu text )
   */
  public key_f3MeWebApiPluginMenuText = "f3MeWebApiPluginMenuText";
  /**
   * f3MeWebApiPluginMenuText from Cms.csv (in EN: Menu text )
   */
  public get f3MeWebApiPluginMenuText(): string { return this.getStringDef(this.key_f3MeWebApiPluginMenuText, "Menu text"); }

  /**
   * f3MeWebApiPluginExternalUrl from Cms.csv (in EN: External url )
   */
  public key_f3MeWebApiPluginExternalUrl = "f3MeWebApiPluginExternalUrl";
  /**
   * f3MeWebApiPluginExternalUrl from Cms.csv (in EN: External url )
   */
  public get f3MeWebApiPluginExternalUrl(): string { return this.getStringDef(this.key_f3MeWebApiPluginExternalUrl, "External url"); }

  /**
   * f3MeWebApiPluginHeight from Cms.csv (in EN: Height )
   */
  public key_f3MeWebApiPluginHeight = "f3MeWebApiPluginHeight";
  /**
   * f3MeWebApiPluginHeight from Cms.csv (in EN: Height )
   */
  public get f3MeWebApiPluginHeight(): string { return this.getStringDef(this.key_f3MeWebApiPluginHeight, "Height"); }

  /**
   * f3MeWebApiPluginWidth from Cms.csv (in EN: Width )
   */
  public key_f3MeWebApiPluginWidth = "f3MeWebApiPluginWidth";
  /**
   * f3MeWebApiPluginWidth from Cms.csv (in EN: Width )
   */
  public get f3MeWebApiPluginWidth(): string { return this.getStringDef(this.key_f3MeWebApiPluginWidth, "Width"); }

  /**
   * f3MeWebApiPluginRoles from Cms.csv (in EN: Roles )
   */
  public key_f3MeWebApiPluginRoles = "f3MeWebApiPluginRoles";
  /**
   * f3MeWebApiPluginRoles from Cms.csv (in EN: Roles )
   */
  public get f3MeWebApiPluginRoles(): string { return this.getStringDef(this.key_f3MeWebApiPluginRoles, "Roles"); }

  /**
   * inspireGridActionsColumnName from Cms.csv (in EN: Actions )
   */
  public key_inspireGridActionsColumnName = "inspireGridActionsColumnName";
  /**
   * inspireGridActionsColumnName from Cms.csv (in EN: Actions )
   */
  public get inspireGridActionsColumnName(): string { return this.getStringDef(this.key_inspireGridActionsColumnName, "Actions"); }

  /**
   * f3MeWebApiPluginSave from Cms.csv (in EN: Save )
   */
  public key_f3MeWebApiPluginSave = "f3MeWebApiPluginSave";
  /**
   * f3MeWebApiPluginSave from Cms.csv (in EN: Save )
   */
  public get f3MeWebApiPluginSave(): string { return this.getStringDef(this.key_f3MeWebApiPluginSave, "Save"); }

  /**
   * f3MeWebApiPluginNew from Cms.csv (in EN: New plugin )
   */
  public key_f3MeWebApiPluginNew = "f3MeWebApiPluginNew";
  /**
   * f3MeWebApiPluginNew from Cms.csv (in EN: New plugin )
   */
  public get f3MeWebApiPluginNew(): string { return this.getStringDef(this.key_f3MeWebApiPluginNew, "New plugin"); }

  /**
   * f3MeWebApiPluginEditing from Cms.csv (in EN: Editing plugin )
   */
  public key_f3MeWebApiPluginEditing = "f3MeWebApiPluginEditing";
  /**
   * f3MeWebApiPluginEditing from Cms.csv (in EN: Editing plugin )
   */
  public get f3MeWebApiPluginEditing(): string { return this.getStringDef(this.key_f3MeWebApiPluginEditing, "Editing plugin"); }

  /**
   * f3MeWebApiPluginEnableTopbar from Cms.csv (in EN: Enable topbar )
   */
  public key_f3MeWebApiPluginEnableTopbar = "f3MeWebApiPluginEnableTopbar";
  /**
   * f3MeWebApiPluginEnableTopbar from Cms.csv (in EN: Enable topbar )
   */
  public get f3MeWebApiPluginEnableTopbar(): string { return this.getStringDef(this.key_f3MeWebApiPluginEnableTopbar, "Enable topbar"); }

  /**
   * f3MeWebApiMenuPluginManage from Cms.csv (in EN: Plugins )
   */
  public key_f3MeWebApiMenuPluginManage = "f3MeWebApiMenuPluginManage";
  /**
   * f3MeWebApiMenuPluginManage from Cms.csv (in EN: Plugins )
   */
  public get f3MeWebApiMenuPluginManage(): string { return this.getStringDef(this.key_f3MeWebApiMenuPluginManage, "Plugins"); }

  /**
   * f3MeWebApiMiniEbsTotalBenefits from Cms.csv (in EN: Your total benefits )
   */
  public key_f3MeWebApiMiniEbsTotalBenefits = "f3MeWebApiMiniEbsTotalBenefits";
  /**
   * f3MeWebApiMiniEbsTotalBenefits from Cms.csv (in EN: Your total benefits )
   */
  public get f3MeWebApiMiniEbsTotalBenefits(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsTotalBenefits, "Your total benefits"); }

  /**
   * f3MeWebApiMiniEbsTotalBenefitsEditHeader from Cms.csv (in EN: Text for mini ebs )
   */
  public key_f3MeWebApiMiniEbsTotalBenefitsEditHeader = "f3MeWebApiMiniEbsTotalBenefitsEditHeader";
  /**
   * f3MeWebApiMiniEbsTotalBenefitsEditHeader from Cms.csv (in EN: Text for mini ebs )
   */
  public get f3MeWebApiMiniEbsTotalBenefitsEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsTotalBenefitsEditHeader, "Text for mini ebs"); }

  /**
   * f3MeWebApiMiniEbsSalary from Cms.csv (in EN: Compensation )
   */
  public key_f3MeWebApiMiniEbsSalary = "f3MeWebApiMiniEbsSalary";
  /**
   * f3MeWebApiMiniEbsSalary from Cms.csv (in EN: Compensation )
   */
  public get f3MeWebApiMiniEbsSalary(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsSalary, "Compensation"); }

  /**
   * f3MeWebApiMiniEbsOther from Cms.csv (in EN: Other )
   */
  public key_f3MeWebApiMiniEbsOther = "f3MeWebApiMiniEbsOther";
  /**
   * f3MeWebApiMiniEbsOther from Cms.csv (in EN: Other )
   */
  public get f3MeWebApiMiniEbsOther(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsOther, "Other"); }

  /**
   * f3MeWebApiMiniEbsDetail from Cms.csv (in EN: Detail )
   */
  public key_f3MeWebApiMiniEbsDetail = "f3MeWebApiMiniEbsDetail";
  /**
   * f3MeWebApiMiniEbsDetail from Cms.csv (in EN: Detail )
   */
  public get f3MeWebApiMiniEbsDetail(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsDetail, "Detail"); }

  /**
   * f3MeWebApiMiniEbsLeave from Cms.csv (in EN: Leave )
   */
  public key_f3MeWebApiMiniEbsLeave = "f3MeWebApiMiniEbsLeave";
  /**
   * f3MeWebApiMiniEbsLeave from Cms.csv (in EN: Leave )
   */
  public get f3MeWebApiMiniEbsLeave(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsLeave, "Leave"); }

  /**
   * f3MeWebApiMiniEbsDays from Cms.csv (in EN: days )
   */
  public key_f3MeWebApiMiniEbsDays = "f3MeWebApiMiniEbsDays";
  /**
   * f3MeWebApiMiniEbsDays from Cms.csv (in EN: days )
   */
  public get f3MeWebApiMiniEbsDays(): string { return this.getStringDef(this.key_f3MeWebApiMiniEbsDays, "days"); }

  /**
   * f3MeWebApiMenuManagementEmailTemplate from Cms.csv (in EN: e-Template config )
   */
  public key_f3MeWebApiMenuManagementEmailTemplate = "f3MeWebApiMenuManagementEmailTemplate";
  /**
   * f3MeWebApiMenuManagementEmailTemplate from Cms.csv (in EN: e-Template config )
   */
  public get f3MeWebApiMenuManagementEmailTemplate(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementEmailTemplate, "e-Template config"); }

  /**
   * f3MeWebApiEmailCaption from Cms.csv (in EN: E-mail )
   */
  public key_f3MeWebApiEmailCaption = "f3MeWebApiEmailCaption";
  /**
   * f3MeWebApiEmailCaption from Cms.csv (in EN: E-mail )
   */
  public get f3MeWebApiEmailCaption(): string { return this.getStringDef(this.key_f3MeWebApiEmailCaption, "E-mail"); }

  /**
   * f3MeWebApiEmailHeader from Cms.csv (in EN: Fill in e-mail: )
   */
  public key_f3MeWebApiEmailHeader = "f3MeWebApiEmailHeader";
  /**
   * f3MeWebApiEmailHeader from Cms.csv (in EN: Fill in e-mail: )
   */
  public get f3MeWebApiEmailHeader(): string { return this.getStringDef(this.key_f3MeWebApiEmailHeader, "Fill in e-mail:"); }

  /**
   * f3MeWebApiEmailConfirm from Cms.csv (in EN: Send e-mail? )
   */
  public key_f3MeWebApiEmailConfirm = "f3MeWebApiEmailConfirm";
  /**
   * f3MeWebApiEmailConfirm from Cms.csv (in EN: Send e-mail? )
   */
  public get f3MeWebApiEmailConfirm(): string { return this.getStringDef(this.key_f3MeWebApiEmailConfirm, "Send e-mail?"); }

  /**
   * f3MeWebApiEmailSubject from Cms.csv (in EN: Subject )
   */
  public key_f3MeWebApiEmailSubject = "f3MeWebApiEmailSubject";
  /**
   * f3MeWebApiEmailSubject from Cms.csv (in EN: Subject )
   */
  public get f3MeWebApiEmailSubject(): string { return this.getStringDef(this.key_f3MeWebApiEmailSubject, "Subject"); }

  /**
   * f3MeWebApiEmailSent from Cms.csv (in EN: The e-mail is sent, you will soon receive a reply. )
   */
  public key_f3MeWebApiEmailSent = "f3MeWebApiEmailSent";
  /**
   * f3MeWebApiEmailSent from Cms.csv (in EN: The e-mail is sent, you will soon receive a reply. )
   */
  public get f3MeWebApiEmailSent(): string { return this.getStringDef(this.key_f3MeWebApiEmailSent, "The e-mail is sent, you will soon receive a reply."); }

  /**
   * f3MeWebApiEmailEditTo from Cms.csv (in EN: To: )
   */
  public key_f3MeWebApiEmailEditTo = "f3MeWebApiEmailEditTo";
  /**
   * f3MeWebApiEmailEditTo from Cms.csv (in EN: To: )
   */
  public get f3MeWebApiEmailEditTo(): string { return this.getStringDef(this.key_f3MeWebApiEmailEditTo, "To:"); }

  /**
   * f3MeWebApiEmailTest from Cms.csv (in EN: Test )
   */
  public key_f3MeWebApiEmailTest = "f3MeWebApiEmailTest";
  /**
   * f3MeWebApiEmailTest from Cms.csv (in EN: Test )
   */
  public get f3MeWebApiEmailTest(): string { return this.getStringDef(this.key_f3MeWebApiEmailTest, "Test"); }

  /**
   * f3MeWebApiImage_fixed_login from Cms.csv (in EN: Login background )
   */
  public key_f3MeWebApiImage_fixed_login = "f3MeWebApiImage_fixed_login";
  /**
   * f3MeWebApiImage_fixed_login from Cms.csv (in EN: Login background )
   */
  public get f3MeWebApiImage_fixed_login(): string { return this.getStringDef(this.key_f3MeWebApiImage_fixed_login, "Login background"); }

  /**
   * f3MeWebApiImage_fixed_logo from Cms.csv (in EN: Company logo )
   */
  public key_f3MeWebApiImage_fixed_logo = "f3MeWebApiImage_fixed_logo";
  /**
   * f3MeWebApiImage_fixed_logo from Cms.csv (in EN: Company logo )
   */
  public get f3MeWebApiImage_fixed_logo(): string { return this.getStringDef(this.key_f3MeWebApiImage_fixed_logo, "Company logo"); }

  /**
   * f3MeWebApiImage_fixed_logo_login from Cms.csv (in EN: Company logo login )
   */
  public key_f3MeWebApiImage_fixed_logo_login = "f3MeWebApiImage_fixed_logo_login";
  /**
   * f3MeWebApiImage_fixed_logo_login from Cms.csv (in EN: Company logo login )
   */
  public get f3MeWebApiImage_fixed_logo_login(): string { return this.getStringDef(this.key_f3MeWebApiImage_fixed_logo_login, "Company logo login"); }

  /**
   * f3MeWebApiImage_fixed_mugshot from Cms.csv (in EN: No picture of person )
   */
  public key_f3MeWebApiImage_fixed_mugshot = "f3MeWebApiImage_fixed_mugshot";
  /**
   * f3MeWebApiImage_fixed_mugshot from Cms.csv (in EN: No picture of person )
   */
  public get f3MeWebApiImage_fixed_mugshot(): string { return this.getStringDef(this.key_f3MeWebApiImage_fixed_mugshot, "No picture of person"); }

  /**
   * f3MeWebApiImage_fixed_imageplaceholder from Cms.csv (in EN: Place holder picture )
   */
  public key_f3MeWebApiImage_fixed_imageplaceholder = "f3MeWebApiImage_fixed_imageplaceholder";
  /**
   * f3MeWebApiImage_fixed_imageplaceholder from Cms.csv (in EN: Place holder picture )
   */
  public get f3MeWebApiImage_fixed_imageplaceholder(): string { return this.getStringDef(this.key_f3MeWebApiImage_fixed_imageplaceholder, "Place holder picture"); }

  /**
   * f3MeWebApiNews from Cms.csv (in EN: News )
   */
  public key_f3MeWebApiNews = "f3MeWebApiNews";
  /**
   * f3MeWebApiNews from Cms.csv (in EN: News )
   */
  public get f3MeWebApiNews(): string { return this.getStringDef(this.key_f3MeWebApiNews, "News"); }

  /**
   * f3MeWebApiMenuCmsNews from Cms.csv (in EN: News )
   */
  public key_f3MeWebApiMenuCmsNews = "f3MeWebApiMenuCmsNews";
  /**
   * f3MeWebApiMenuCmsNews from Cms.csv (in EN: News )
   */
  public get f3MeWebApiMenuCmsNews(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsNews, "News"); }

  /**
   * f3MeWebApiMenuCmsCarousel from Cms.csv (in EN: Carousel )
   */
  public key_f3MeWebApiMenuCmsCarousel = "f3MeWebApiMenuCmsCarousel";
  /**
   * f3MeWebApiMenuCmsCarousel from Cms.csv (in EN: Carousel )
   */
  public get f3MeWebApiMenuCmsCarousel(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsCarousel, "Carousel"); }

  /**
   * f3MeWebApiMenuCmsFixedImages from Cms.csv (in EN: Fixed images )
   */
  public key_f3MeWebApiMenuCmsFixedImages = "f3MeWebApiMenuCmsFixedImages";
  /**
   * f3MeWebApiMenuCmsFixedImages from Cms.csv (in EN: Fixed images )
   */
  public get f3MeWebApiMenuCmsFixedImages(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsFixedImages, "Fixed images"); }

  /**
   * f3MeWebApiMenuCmsEmailTemplates from Cms.csv (in EN: E-mail templates )
   */
  public key_f3MeWebApiMenuCmsEmailTemplates = "f3MeWebApiMenuCmsEmailTemplates";
  /**
   * f3MeWebApiMenuCmsEmailTemplates from Cms.csv (in EN: E-mail templates )
   */
  public get f3MeWebApiMenuCmsEmailTemplates(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsEmailTemplates, "E-mail templates"); }

  /**
   * f3MeWebApiMenuCmsLanguage from Cms.csv (in EN: Texts )
   */
  public key_f3MeWebApiMenuCmsLanguage = "f3MeWebApiMenuCmsLanguage";
  /**
   * f3MeWebApiMenuCmsLanguage from Cms.csv (in EN: Texts )
   */
  public get f3MeWebApiMenuCmsLanguage(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsLanguage, "Texts"); }

  /**
   * f3MeWebApiMenuCmsWhatIf from Cms.csv (in EN: Simulation block )
   */
  public key_f3MeWebApiMenuCmsWhatIf = "f3MeWebApiMenuCmsWhatIf";
  /**
   * f3MeWebApiMenuCmsWhatIf from Cms.csv (in EN: Simulation block )
   */
  public get f3MeWebApiMenuCmsWhatIf(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsWhatIf, "Simulation block"); }

  /**
   * f3MeWebApiMenuCmsHomePageBanner from Cms.csv (in EN: Homepage )
   */
  public key_f3MeWebApiMenuCmsHomePageBanner = "f3MeWebApiMenuCmsHomePageBanner";
  /**
   * f3MeWebApiMenuCmsHomePageBanner from Cms.csv (in EN: Homepage )
   */
  public get f3MeWebApiMenuCmsHomePageBanner(): string { return this.getStringDef(this.key_f3MeWebApiMenuCmsHomePageBanner, "Homepage"); }

  /**
   * f3MeWebApiImageEdit from Cms.csv (in EN: Edit image )
   */
  public key_f3MeWebApiImageEdit = "f3MeWebApiImageEdit";
  /**
   * f3MeWebApiImageEdit from Cms.csv (in EN: Edit image )
   */
  public get f3MeWebApiImageEdit(): string { return this.getStringDef(this.key_f3MeWebApiImageEdit, "Edit image"); }

  /**
   * f3MeWebApiImageCrop from Cms.csv (in EN: Crop image )
   */
  public key_f3MeWebApiImageCrop = "f3MeWebApiImageCrop";
  /**
   * f3MeWebApiImageCrop from Cms.csv (in EN: Crop image )
   */
  public get f3MeWebApiImageCrop(): string { return this.getStringDef(this.key_f3MeWebApiImageCrop, "Crop image"); }

  /**
   * f3MeWebApiImageSave from Cms.csv (in EN: Save )
   */
  public key_f3MeWebApiImageSave = "f3MeWebApiImageSave";
  /**
   * f3MeWebApiImageSave from Cms.csv (in EN: Save )
   */
  public get f3MeWebApiImageSave(): string { return this.getStringDef(this.key_f3MeWebApiImageSave, "Save"); }

  /**
   * f3MeWebApiWhatIfHeader from Cms.csv (in EN: What happens if... )
   */
  public key_f3MeWebApiWhatIfHeader = "f3MeWebApiWhatIfHeader";
  /**
   * f3MeWebApiWhatIfHeader from Cms.csv (in EN: What happens if... )
   */
  public get f3MeWebApiWhatIfHeader(): string { return this.getStringDef(this.key_f3MeWebApiWhatIfHeader, "What happens if..."); }

  /**
   * f3MeWebApiEditToggleEdit from Cms.csv (in EN: Edit )
   */
  public key_f3MeWebApiEditToggleEdit = "f3MeWebApiEditToggleEdit";
  /**
   * f3MeWebApiEditToggleEdit from Cms.csv (in EN: Edit )
   */
  public get f3MeWebApiEditToggleEdit(): string { return this.getStringDef(this.key_f3MeWebApiEditToggleEdit, "Edit"); }

  /**
   * f3MeWebApiLabourConditions from Cms.csv (in EN: Labour conditions )
   */
  public key_f3MeWebApiLabourConditions = "f3MeWebApiLabourConditions";
  /**
   * f3MeWebApiLabourConditions from Cms.csv (in EN: Labour conditions )
   */
  public get f3MeWebApiLabourConditions(): string { return this.getStringDef(this.key_f3MeWebApiLabourConditions, "Labour conditions"); }

  /**
   * f3MeWebApiLabourConditionGroupTitle from Cms.csv (in EN: Title )
   */
  public key_f3MeWebApiLabourConditionGroupTitle = "f3MeWebApiLabourConditionGroupTitle";
  /**
   * f3MeWebApiLabourConditionGroupTitle from Cms.csv (in EN: Title )
   */
  public get f3MeWebApiLabourConditionGroupTitle(): string { return this.getStringDef(this.key_f3MeWebApiLabourConditionGroupTitle, "Title"); }

  /**
   * f3MeWebApiTargetExogenousCategory from Cms.csv (in EN: Exogenous Category )
   */
  public key_f3MeWebApiTargetExogenousCategory = "f3MeWebApiTargetExogenousCategory";
  /**
   * f3MeWebApiTargetExogenousCategory from Cms.csv (in EN: Exogenous Category )
   */
  public get f3MeWebApiTargetExogenousCategory(): string { return this.getStringDef(this.key_f3MeWebApiTargetExogenousCategory, "Exogenous Category"); }

  /**
   * f3MeWebApiTargetCaption from Cms.csv (in EN: Target )
   */
  public key_f3MeWebApiTargetCaption = "f3MeWebApiTargetCaption";
  /**
   * f3MeWebApiTargetCaption from Cms.csv (in EN: Target )
   */
  public get f3MeWebApiTargetCaption(): string { return this.getStringDef(this.key_f3MeWebApiTargetCaption, "Target"); }

  /**
   * f3MeWebApiExogenous from Cms.csv (in EN: Exogenous )
   */
  public key_f3MeWebApiExogenous = "f3MeWebApiExogenous";
  /**
   * f3MeWebApiExogenous from Cms.csv (in EN: Exogenous )
   */
  public get f3MeWebApiExogenous(): string { return this.getStringDef(this.key_f3MeWebApiExogenous, "Exogenous"); }

  /**
   * f3MeWebApiTargetDelete from Cms.csv (in EN: Are you sure you want to delete this target? )
   */
  public key_f3MeWebApiTargetDelete = "f3MeWebApiTargetDelete";
  /**
   * f3MeWebApiTargetDelete from Cms.csv (in EN: Are you sure you want to delete this target? )
   */
  public get f3MeWebApiTargetDelete(): string { return this.getStringDef(this.key_f3MeWebApiTargetDelete, "Are you sure you want to delete this target?"); }

  /**
   * f3MeWebApiTargetFileToLarge from Cms.csv (in EN: Max. size is 5 mb )
   */
  public key_f3MeWebApiTargetFileToLarge = "f3MeWebApiTargetFileToLarge";
  /**
   * f3MeWebApiTargetFileToLarge from Cms.csv (in EN: Max. size is 5 mb )
   */
  public get f3MeWebApiTargetFileToLarge(): string { return this.getStringDef(this.key_f3MeWebApiTargetFileToLarge, "Max. size is 5 mb"); }

  /**
   * f3MeWebApiTargetSelectSources from Cms.csv (in EN: Which sources would you like to use? )
   */
  public key_f3MeWebApiTargetSelectSources = "f3MeWebApiTargetSelectSources";
  /**
   * f3MeWebApiTargetSelectSources from Cms.csv (in EN: Which sources would you like to use? )
   */
  public get f3MeWebApiTargetSelectSources(): string { return this.getStringDef(this.key_f3MeWebApiTargetSelectSources, "Which sources would you like to use?"); }

  /**
   * f3MeWebApiTargetCurrent from Cms.csv (in EN: Current )
   */
  public key_f3MeWebApiTargetCurrent = "f3MeWebApiTargetCurrent";
  /**
   * f3MeWebApiTargetCurrent from Cms.csv (in EN: Current )
   */
  public get f3MeWebApiTargetCurrent(): string { return this.getStringDef(this.key_f3MeWebApiTargetCurrent, "Current"); }

  /**
   * f3MeWebApiTargetCurrentEmbedded from Cms.csv (in EN: Current )
   */
  public key_f3MeWebApiTargetCurrentEmbedded = "f3MeWebApiTargetCurrentEmbedded";
  /**
   * f3MeWebApiTargetCurrentEmbedded from Cms.csv (in EN: Current )
   */
  public get f3MeWebApiTargetCurrentEmbedded(): string { return this.getStringDef(this.key_f3MeWebApiTargetCurrentEmbedded, "Current"); }

  /**
   * f3MeWebApiTargetInput from Cms.csv (in EN: Input )
   */
  public key_f3MeWebApiTargetInput = "f3MeWebApiTargetInput";
  /**
   * f3MeWebApiTargetInput from Cms.csv (in EN: Input )
   */
  public get f3MeWebApiTargetInput(): string { return this.getStringDef(this.key_f3MeWebApiTargetInput, "Input"); }

  /**
   * f3MeWebApiTargetRequired from Cms.csv (in EN: This will cost you:  )
   */
  public key_f3MeWebApiTargetRequired = "f3MeWebApiTargetRequired";
  /**
   * f3MeWebApiTargetRequired from Cms.csv (in EN: This will cost you:  )
   */
  public get f3MeWebApiTargetRequired(): string { return this.getStringDef(this.key_f3MeWebApiTargetRequired, "This will cost you: "); }

  /**
   * f3MeWebApiTargetRemaining from Cms.csv (in EN: Remaining:  )
   */
  public key_f3MeWebApiTargetRemaining = "f3MeWebApiTargetRemaining";
  /**
   * f3MeWebApiTargetRemaining from Cms.csv (in EN: Remaining:  )
   */
  public get f3MeWebApiTargetRemaining(): string { return this.getStringDef(this.key_f3MeWebApiTargetRemaining, "Remaining: "); }

  /**
   * f3MeWebApiTargetInvested from Cms.csv (in EN: Invested:  )
   */
  public key_f3MeWebApiTargetInvested = "f3MeWebApiTargetInvested";
  /**
   * f3MeWebApiTargetInvested from Cms.csv (in EN: Invested:  )
   */
  public get f3MeWebApiTargetInvested(): string { return this.getStringDef(this.key_f3MeWebApiTargetInvested, "Invested: "); }

  /**
   * f3MeWebApiTargetOrder from Cms.csv (in EN: Order )
   */
  public key_f3MeWebApiTargetOrder = "f3MeWebApiTargetOrder";
  /**
   * f3MeWebApiTargetOrder from Cms.csv (in EN: Order )
   */
  public get f3MeWebApiTargetOrder(): string { return this.getStringDef(this.key_f3MeWebApiTargetOrder, "Order"); }

  /**
   * f3MeWebApiTargetConfirmOrder from Cms.csv (in EN: Order? )
   */
  public key_f3MeWebApiTargetConfirmOrder = "f3MeWebApiTargetConfirmOrder";
  /**
   * f3MeWebApiTargetConfirmOrder from Cms.csv (in EN: Order? )
   */
  public get f3MeWebApiTargetConfirmOrder(): string { return this.getStringDef(this.key_f3MeWebApiTargetConfirmOrder, "Order?"); }

  /**
   * f3MeWebApiTargetStatusOrder from Cms.csv (in EN: Order )
   */
  public key_f3MeWebApiTargetStatusOrder = "f3MeWebApiTargetStatusOrder";
  /**
   * f3MeWebApiTargetStatusOrder from Cms.csv (in EN: Order )
   */
  public get f3MeWebApiTargetStatusOrder(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusOrder, "Order"); }

  /**
   * f3MeWebApiTargetStatusWaitingOrders from Cms.csv (in EN: Waiting Orders )
   */
  public key_f3MeWebApiTargetStatusWaitingOrders = "f3MeWebApiTargetStatusWaitingOrders";
  /**
   * f3MeWebApiTargetStatusWaitingOrders from Cms.csv (in EN: Waiting Orders )
   */
  public get f3MeWebApiTargetStatusWaitingOrders(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusWaitingOrders, "Waiting Orders"); }

  /**
   * f3MeWebApiTargetStatusRequested from Cms.csv (in EN: Check )
   */
  public key_f3MeWebApiTargetStatusRequested = "f3MeWebApiTargetStatusRequested";
  /**
   * f3MeWebApiTargetStatusRequested from Cms.csv (in EN: Check )
   */
  public get f3MeWebApiTargetStatusRequested(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusRequested, "Check"); }

  /**
   * f3MeWebApiTargetStatusRequestedExplain from Cms.csv (in EN: The order is being checked )
   */
  public key_f3MeWebApiTargetStatusRequestedExplain = "f3MeWebApiTargetStatusRequestedExplain";
  /**
   * f3MeWebApiTargetStatusRequestedExplain from Cms.csv (in EN: The order is being checked )
   */
  public get f3MeWebApiTargetStatusRequestedExplain(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusRequestedExplain, "The order is being checked"); }

  /**
   * f3MeWebApiTargetStatusApproved from Cms.csv (in EN: Processed )
   */
  public key_f3MeWebApiTargetStatusApproved = "f3MeWebApiTargetStatusApproved";
  /**
   * f3MeWebApiTargetStatusApproved from Cms.csv (in EN: Processed )
   */
  public get f3MeWebApiTargetStatusApproved(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusApproved, "Processed"); }

  /**
   * f3MeWebApiTargetStatusApprovedExplain from Cms.csv (in EN: The order has been processed )
   */
  public key_f3MeWebApiTargetStatusApprovedExplain = "f3MeWebApiTargetStatusApprovedExplain";
  /**
   * f3MeWebApiTargetStatusApprovedExplain from Cms.csv (in EN: The order has been processed )
   */
  public get f3MeWebApiTargetStatusApprovedExplain(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusApprovedExplain, "The order has been processed"); }

  /**
   * f3MeWebApiTargetStatusExog from Cms.csv (in EN: Status )
   */
  public key_f3MeWebApiTargetStatusExog = "f3MeWebApiTargetStatusExog";
  /**
   * f3MeWebApiTargetStatusExog from Cms.csv (in EN: Status )
   */
  public get f3MeWebApiTargetStatusExog(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusExog, "Status"); }

  /**
   * f3MeWebApiTargetStatusExogDate from Cms.csv (in EN: Status date )
   */
  public key_f3MeWebApiTargetStatusExogDate = "f3MeWebApiTargetStatusExogDate";
  /**
   * f3MeWebApiTargetStatusExogDate from Cms.csv (in EN: Status date )
   */
  public get f3MeWebApiTargetStatusExogDate(): string { return this.getStringDef(this.key_f3MeWebApiTargetStatusExogDate, "Status date"); }

  /**
   * f3MeWebApiTargetAllowSubmit from Cms.csv (in EN: Allow submit )
   */
  public key_f3MeWebApiTargetAllowSubmit = "f3MeWebApiTargetAllowSubmit";
  /**
   * f3MeWebApiTargetAllowSubmit from Cms.csv (in EN: Allow submit )
   */
  public get f3MeWebApiTargetAllowSubmit(): string { return this.getStringDef(this.key_f3MeWebApiTargetAllowSubmit, "Allow submit"); }

  /**
   * f3MeWebApiEditSourcesResultValue from Cms.csv (in EN: Result )
   */
  public key_f3MeWebApiEditSourcesResultValue = "f3MeWebApiEditSourcesResultValue";
  /**
   * f3MeWebApiEditSourcesResultValue from Cms.csv (in EN: Result )
   */
  public get f3MeWebApiEditSourcesResultValue(): string { return this.getStringDef(this.key_f3MeWebApiEditSourcesResultValue, "Result"); }

  /**
   * f3MeWebApiEditSourceEntriesHeader from Cms.csv (in EN: Attached targets )
   */
  public key_f3MeWebApiEditSourceEntriesHeader = "f3MeWebApiEditSourceEntriesHeader";
  /**
   * f3MeWebApiEditSourceEntriesHeader from Cms.csv (in EN: Attached targets )
   */
  public get f3MeWebApiEditSourceEntriesHeader(): string { return this.getStringDef(this.key_f3MeWebApiEditSourceEntriesHeader, "Attached targets"); }

  /**
   * f3MeWebApiEditSourceCannotAddError from Cms.csv (in EN: * You first need to save the source before you can add targets )
   */
  public key_f3MeWebApiEditSourceCannotAddError = "f3MeWebApiEditSourceCannotAddError";
  /**
   * f3MeWebApiEditSourceCannotAddError from Cms.csv (in EN: * You first need to save the source before you can add targets )
   */
  public get f3MeWebApiEditSourceCannotAddError(): string { return this.getStringDef(this.key_f3MeWebApiEditSourceCannotAddError, "* You first need to save the source before you can add targets"); }

  /**
   * f3MeWebApiMyFiles from Cms.csv (in EN: My Files )
   */
  public key_f3MeWebApiMyFiles = "f3MeWebApiMyFiles";
  /**
   * f3MeWebApiMyFiles from Cms.csv (in EN: My Files )
   */
  public get f3MeWebApiMyFiles(): string { return this.getStringDef(this.key_f3MeWebApiMyFiles, "My Files"); }

  /**
   * f3MeWebApiMenuManagementUserFieldsEdit from Cms.csv (in EN: User fields )
   */
  public key_f3MeWebApiMenuManagementUserFieldsEdit = "f3MeWebApiMenuManagementUserFieldsEdit";
  /**
   * f3MeWebApiMenuManagementUserFieldsEdit from Cms.csv (in EN: User fields )
   */
  public get f3MeWebApiMenuManagementUserFieldsEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementUserFieldsEdit, "User fields"); }

  /**
   * f3MeWebApiUserFieldsHeader from Cms.csv (in EN: Choose the exogenous variable for the corresponding field )
   */
  public key_f3MeWebApiUserFieldsHeader = "f3MeWebApiUserFieldsHeader";
  /**
   * f3MeWebApiUserFieldsHeader from Cms.csv (in EN: Choose the exogenous variable for the corresponding field )
   */
  public get f3MeWebApiUserFieldsHeader(): string { return this.getStringDef(this.key_f3MeWebApiUserFieldsHeader, "Choose the exogenous variable for the corresponding field"); }

  /**
   * f3MeWebApiUserField_e_mail from Cms.csv (in EN: E-mail )
   */
  public key_f3MeWebApiUserField_e_mail = "f3MeWebApiUserField_e_mail";
  /**
   * f3MeWebApiUserField_e_mail from Cms.csv (in EN: E-mail )
   */
  public get f3MeWebApiUserField_e_mail(): string { return this.getStringDef(this.key_f3MeWebApiUserField_e_mail, "E-mail"); }

  /**
   * f3MeWebApiUserField_e_mail_bcc from Cms.csv (in EN: E-mail bcc )
   */
  public key_f3MeWebApiUserField_e_mail_bcc = "f3MeWebApiUserField_e_mail_bcc";
  /**
   * f3MeWebApiUserField_e_mail_bcc from Cms.csv (in EN: E-mail bcc )
   */
  public get f3MeWebApiUserField_e_mail_bcc(): string { return this.getStringDef(this.key_f3MeWebApiUserField_e_mail_bcc, "E-mail bcc"); }

  /**
   * f3MeWebApiUserField_username from Cms.csv (in EN: Login )
   */
  public key_f3MeWebApiUserField_username = "f3MeWebApiUserField_username";
  /**
   * f3MeWebApiUserField_username from Cms.csv (in EN: Login )
   */
  public get f3MeWebApiUserField_username(): string { return this.getStringDef(this.key_f3MeWebApiUserField_username, "Login"); }

  /**
   * f3MeWebApiLcLinkLabourCondition from Cms.csv (in EN: Labourcondition )
   */
  public key_f3MeWebApiLcLinkLabourCondition = "f3MeWebApiLcLinkLabourCondition";
  /**
   * f3MeWebApiLcLinkLabourCondition from Cms.csv (in EN: Labourcondition )
   */
  public get f3MeWebApiLcLinkLabourCondition(): string { return this.getStringDef(this.key_f3MeWebApiLcLinkLabourCondition, "Labourcondition"); }

  /**
   * f3MeWebApiLcLinkRequestMail from Cms.csv (in EN: Request email )
   */
  public key_f3MeWebApiLcLinkRequestMail = "f3MeWebApiLcLinkRequestMail";
  /**
   * f3MeWebApiLcLinkRequestMail from Cms.csv (in EN: Request email )
   */
  public get f3MeWebApiLcLinkRequestMail(): string { return this.getStringDef(this.key_f3MeWebApiLcLinkRequestMail, "Request email"); }

  /**
   * f3MeWebApiLcLinkConfirmEmail from Cms.csv (in EN: Confirmation email )
   */
  public key_f3MeWebApiLcLinkConfirmEmail = "f3MeWebApiLcLinkConfirmEmail";
  /**
   * f3MeWebApiLcLinkConfirmEmail from Cms.csv (in EN: Confirmation email )
   */
  public get f3MeWebApiLcLinkConfirmEmail(): string { return this.getStringDef(this.key_f3MeWebApiLcLinkConfirmEmail, "Confirmation email"); }

  /**
   * f3MeWebApiLinkLabourCondition from Cms.csv (in EN: {0} labour condition )
   */
  public key_f3MeWebApiLinkLabourCondition = "f3MeWebApiLinkLabourCondition";
  /**
   * f3MeWebApiLinkLabourCondition from Cms.csv (in EN: {0} labour condition )
   */
  public f3MeWebApiLinkLabourCondition(val1): string { return this.getStringDef("f3MeWebApiLinkLabourCondition", "{0} labour condition").replace("{0}", val1); }

  /**
   * f3MeWebApiLinkLabourConditionNoTitle from Cms.csv (in EN: {no title} )
   */
  public key_f3MeWebApiLinkLabourConditionNoTitle = "f3MeWebApiLinkLabourConditionNoTitle";
  /**
   * f3MeWebApiLinkLabourConditionNoTitle from Cms.csv (in EN: {no title} )
   */
  public get f3MeWebApiLinkLabourConditionNoTitle(): string { return this.getStringDef(this.key_f3MeWebApiLinkLabourConditionNoTitle, "<no title>"); }

  /**
   * f3MeWebApiSimulateParts from Cms.csv (in EN: Parts )
   */
  public key_f3MeWebApiSimulateParts = "f3MeWebApiSimulateParts";
  /**
   * f3MeWebApiSimulateParts from Cms.csv (in EN: Parts )
   */
  public get f3MeWebApiSimulateParts(): string { return this.getStringDef(this.key_f3MeWebApiSimulateParts, "Parts"); }

  /**
   * f3MeWebApiSimulateNow from Cms.csv (in EN: Current )
   */
  public key_f3MeWebApiSimulateNow = "f3MeWebApiSimulateNow";
  /**
   * f3MeWebApiSimulateNow from Cms.csv (in EN: Current )
   */
  public get f3MeWebApiSimulateNow(): string { return this.getStringDef(this.key_f3MeWebApiSimulateNow, "Current"); }

  /**
   * f3MeWebApiSimulateSimulated from Cms.csv (in EN: Simulated )
   */
  public key_f3MeWebApiSimulateSimulated = "f3MeWebApiSimulateSimulated";
  /**
   * f3MeWebApiSimulateSimulated from Cms.csv (in EN: Simulated )
   */
  public get f3MeWebApiSimulateSimulated(): string { return this.getStringDef(this.key_f3MeWebApiSimulateSimulated, "Simulated"); }

  /**
   * f3MeWebApiSimulateGross from Cms.csv (in EN: Gross )
   */
  public key_f3MeWebApiSimulateGross = "f3MeWebApiSimulateGross";
  /**
   * f3MeWebApiSimulateGross from Cms.csv (in EN: Gross )
   */
  public get f3MeWebApiSimulateGross(): string { return this.getStringDef(this.key_f3MeWebApiSimulateGross, "Gross"); }

  /**
   * f3MeWebApiSimulateNet from Cms.csv (in EN: Net )
   */
  public key_f3MeWebApiSimulateNet = "f3MeWebApiSimulateNet";
  /**
   * f3MeWebApiSimulateNet from Cms.csv (in EN: Net )
   */
  public get f3MeWebApiSimulateNet(): string { return this.getStringDef(this.key_f3MeWebApiSimulateNet, "Net"); }

  /**
   * f3MeWebApiSimulateEditInputHeader from Cms.csv (in EN: Input )
   */
  public key_f3MeWebApiSimulateEditInputHeader = "f3MeWebApiSimulateEditInputHeader";
  /**
   * f3MeWebApiSimulateEditInputHeader from Cms.csv (in EN: Input )
   */
  public get f3MeWebApiSimulateEditInputHeader(): string { return this.getStringDef(this.key_f3MeWebApiSimulateEditInputHeader, "Input"); }

  /**
   * f3MeWebApiSimulateEditOutputHeader from Cms.csv (in EN: Output )
   */
  public key_f3MeWebApiSimulateEditOutputHeader = "f3MeWebApiSimulateEditOutputHeader";
  /**
   * f3MeWebApiSimulateEditOutputHeader from Cms.csv (in EN: Output )
   */
  public get f3MeWebApiSimulateEditOutputHeader(): string { return this.getStringDef(this.key_f3MeWebApiSimulateEditOutputHeader, "Output"); }

  /**
   * f3MeWebApiAppHeaderFor from Cms.csv (in EN: Text for {0} )
   */
  public key_f3MeWebApiAppHeaderFor = "f3MeWebApiAppHeaderFor";
  /**
   * f3MeWebApiAppHeaderFor from Cms.csv (in EN: Text for {0} )
   */
  public f3MeWebApiAppHeaderFor(val1): string { return this.getStringDef("f3MeWebApiAppHeaderFor", "Text for {0}").replace("{0}", val1); }

  /**
   * f3MeWebApiNoGroup from Cms.csv (in EN: No group )
   */
  public key_f3MeWebApiNoGroup = "f3MeWebApiNoGroup";
  /**
   * f3MeWebApiNoGroup from Cms.csv (in EN: No group )
   */
  public get f3MeWebApiNoGroup(): string { return this.getStringDef(this.key_f3MeWebApiNoGroup, "No group"); }

  /**
   * f3MeWebApiMainMenu from Cms.csv (in EN: Main menu )
   */
  public key_f3MeWebApiMainMenu = "f3MeWebApiMainMenu";
  /**
   * f3MeWebApiMainMenu from Cms.csv (in EN: Main menu )
   */
  public get f3MeWebApiMainMenu(): string { return this.getStringDef(this.key_f3MeWebApiMainMenu, "Main menu"); }

  /**
   * f3MeWebApiAppManagementHeader from Cms.csv (in EN: My management portal )
   */
  public key_f3MeWebApiAppManagementHeader = "f3MeWebApiAppManagementHeader";
  /**
   * f3MeWebApiAppManagementHeader from Cms.csv (in EN: My management portal )
   */
  public get f3MeWebApiAppManagementHeader(): string { return this.getStringDef(this.key_f3MeWebApiAppManagementHeader, "My management portal"); }

  /**
   * f3MeWebApiMenuManagementHeader from Cms.csv (in EN: Arrange )
   */
  public key_f3MeWebApiMenuManagementHeader = "f3MeWebApiMenuManagementHeader";
  /**
   * f3MeWebApiMenuManagementHeader from Cms.csv (in EN: Arrange )
   */
  public get f3MeWebApiMenuManagementHeader(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementHeader, "Arrange"); }

  /**
   * f3MeWebApiMenuMessageRatingOverview from Cms.csv (in EN: Dashboards )
   */
  public key_f3MeWebApiMenuMessageRatingOverview = "f3MeWebApiMenuMessageRatingOverview";
  /**
   * f3MeWebApiMenuMessageRatingOverview from Cms.csv (in EN: Dashboards )
   */
  public get f3MeWebApiMenuMessageRatingOverview(): string { return this.getStringDef(this.key_f3MeWebApiMenuMessageRatingOverview, "Dashboards"); }

  /**
   * f3MeWebApiManagementLanguageEditSupportsHtml from Cms.csv (in EN: Allow html )
   */
  public key_f3MeWebApiManagementLanguageEditSupportsHtml = "f3MeWebApiManagementLanguageEditSupportsHtml";
  /**
   * f3MeWebApiManagementLanguageEditSupportsHtml from Cms.csv (in EN: Allow html )
   */
  public get f3MeWebApiManagementLanguageEditSupportsHtml(): string { return this.getStringDef(this.key_f3MeWebApiManagementLanguageEditSupportsHtml, "Allow html"); }

  /**
   * f3MeWebApiDisclaimerTitle from Cms.csv (in EN: Disclaimer )
   */
  public key_f3MeWebApiDisclaimerTitle = "f3MeWebApiDisclaimerTitle";
  /**
   * f3MeWebApiDisclaimerTitle from Cms.csv (in EN: Disclaimer )
   */
  public get f3MeWebApiDisclaimerTitle(): string { return this.getStringDef(this.key_f3MeWebApiDisclaimerTitle, "Disclaimer"); }

  /**
   * f3MeWebApiDisclaimerAccept from Cms.csv (in EN: Accept )
   */
  public key_f3MeWebApiDisclaimerAccept = "f3MeWebApiDisclaimerAccept";
  /**
   * f3MeWebApiDisclaimerAccept from Cms.csv (in EN: Accept )
   */
  public get f3MeWebApiDisclaimerAccept(): string { return this.getStringDef(this.key_f3MeWebApiDisclaimerAccept, "Accept"); }

  /**
   * f3MeWebApiDisclaimerAccepted from Cms.csv (in EN: Accepted )
   */
  public key_f3MeWebApiDisclaimerAccepted = "f3MeWebApiDisclaimerAccepted";
  /**
   * f3MeWebApiDisclaimerAccepted from Cms.csv (in EN: Accepted )
   */
  public get f3MeWebApiDisclaimerAccepted(): string { return this.getStringDef(this.key_f3MeWebApiDisclaimerAccepted, "Accepted"); }

  /**
   * f3MeWebApiDisclaimerMessageLogin from Cms.csv (in EN: (1) The amounts you received part of the compensation and benefits sections are dependent on several components, including but not limited to: your employments terms and conditions, Full Time Equivalent (FTE) percentage, amount of overtime work, employee and company performance, and employee benefit contracts. These amounts are subject to change in next year's statement based on payments you receive against the mentioned components or any other components not listed in this disclaimer and/or statement. (2) If you have doubts against any figure or if you discovered a mismatch between a number reported in this statement and any other statement, please report this to HR Personnel for investigation and correction. (3) Training events, forums and assessments reported in this statement are derived from Learning [and] Development database. Any other event individually arranged may not be reported in this statement. )
   */
  public key_f3MeWebApiDisclaimerMessageLogin = "f3MeWebApiDisclaimerMessageLogin";
  /**
   * f3MeWebApiDisclaimerMessageLogin from Cms.csv (in EN: (1) The amounts you received part of the compensation and benefits sections are dependent on several components, including but not limited to: your employments terms and conditions, Full Time Equivalent (FTE) percentage, amount of overtime work, employee and company performance, and employee benefit contracts. These amounts are subject to change in next year's statement based on payments you receive against the mentioned components or any other components not listed in this disclaimer and/or statement. (2) If you have doubts against any figure or if you discovered a mismatch between a number reported in this statement and any other statement, please report this to HR Personnel for investigation and correction. (3) Training events, forums and assessments reported in this statement are derived from Learning [and] Development database. Any other event individually arranged may not be reported in this statement. )
   */
  public get f3MeWebApiDisclaimerMessageLogin(): string { return this.getStringDef(this.key_f3MeWebApiDisclaimerMessageLogin, "(1) The amounts you received part of the compensation and benefits sections are dependent on several components, including but not limited to: your employments terms and conditions, Full Time Equivalent (FTE) percentage, amount of overtime work, employee and company performance, and employee benefit contracts. These amounts are subject to change in next year's statement based on payments you receive against the mentioned components or any other components not listed in this disclaimer and/or statement. (2) If you have doubts against any figure or if you discovered a mismatch between a number reported in this statement and any other statement, please report this to HR Personnel for investigation and correction. (3) Training events, forums and assessments reported in this statement are derived from Learning & Development database. Any other event individually arranged may not be reported in this statement."); }

  /**
   * f3MeWebApiMenuManagementLanguageEdit from Cms.csv (in EN: Texts )
   */
  public key_f3MeWebApiMenuManagementLanguageEdit = "f3MeWebApiMenuManagementLanguageEdit";
  /**
   * f3MeWebApiMenuManagementLanguageEdit from Cms.csv (in EN: Texts )
   */
  public get f3MeWebApiMenuManagementLanguageEdit(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementLanguageEdit, "Texts"); }

  /**
   * f3MeWebApiUserToEmployeeLoginWarning from Cms.csv (in EN: You are currently logged in as user: [es_pers_nr] )
   */
  public key_f3MeWebApiUserToEmployeeLoginWarning = "f3MeWebApiUserToEmployeeLoginWarning";
  /**
   * f3MeWebApiUserToEmployeeLoginWarning from Cms.csv (in EN: You are currently logged in as user: [es_pers_nr] )
   */
  public get f3MeWebApiUserToEmployeeLoginWarning(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeLoginWarning, "You are currently logged in as user: [es_pers_nr]"); }

  /**
   * f3MeWebApiMenuManagementPreferedUserEmployeeMapping from Cms.csv (in EN: View data of other employee )
   */
  public key_f3MeWebApiMenuManagementPreferedUserEmployeeMapping = "f3MeWebApiMenuManagementPreferedUserEmployeeMapping";
  /**
   * f3MeWebApiMenuManagementPreferedUserEmployeeMapping from Cms.csv (in EN: View data of other employee )
   */
  public get f3MeWebApiMenuManagementPreferedUserEmployeeMapping(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementPreferedUserEmployeeMapping, "View data of other employee"); }

  /**
   * f3MeWebApiMenuManagementPreferedUserEmployeeMappingConfiguration from Cms.csv (in EN: View data of other employee configuration )
   */
  public key_f3MeWebApiMenuManagementPreferedUserEmployeeMappingConfiguration = "f3MeWebApiMenuManagementPreferedUserEmployeeMappingConfiguration";
  /**
   * f3MeWebApiMenuManagementPreferedUserEmployeeMappingConfiguration from Cms.csv (in EN: View data of other employee configuration )
   */
  public get f3MeWebApiMenuManagementPreferedUserEmployeeMappingConfiguration(): string { return this.getStringDef(this.key_f3MeWebApiMenuManagementPreferedUserEmployeeMappingConfiguration, "View data of other employee configuration"); }

  /**
   * f3MeWebApiUserToEmployeeViewDataAs from Cms.csv (in EN: View data as: )
   */
  public key_f3MeWebApiUserToEmployeeViewDataAs = "f3MeWebApiUserToEmployeeViewDataAs";
  /**
   * f3MeWebApiUserToEmployeeViewDataAs from Cms.csv (in EN: View data as: )
   */
  public get f3MeWebApiUserToEmployeeViewDataAs(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeViewDataAs, "View data as:"); }

  /**
   * f3MeWebApiUserToEmployeeButtonAssign from Cms.csv (in EN: Assign )
   */
  public key_f3MeWebApiUserToEmployeeButtonAssign = "f3MeWebApiUserToEmployeeButtonAssign";
  /**
   * f3MeWebApiUserToEmployeeButtonAssign from Cms.csv (in EN: Assign )
   */
  public get f3MeWebApiUserToEmployeeButtonAssign(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeButtonAssign, "Assign"); }

  /**
   * f3MeWebApiUserToEmployeeButtonRevokeAssignment from Cms.csv (in EN: Revoke assignment )
   */
  public key_f3MeWebApiUserToEmployeeButtonRevokeAssignment = "f3MeWebApiUserToEmployeeButtonRevokeAssignment";
  /**
   * f3MeWebApiUserToEmployeeButtonRevokeAssignment from Cms.csv (in EN: Revoke assignment )
   */
  public get f3MeWebApiUserToEmployeeButtonRevokeAssignment(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeButtonRevokeAssignment, "Revoke assignment"); }

  /**
   * f3MeWebApiUserToEmployeeUserDataNotAvailable from Cms.csv (in EN: Current user data are not available. The assignment of view of other user is not possible )
   */
  public key_f3MeWebApiUserToEmployeeUserDataNotAvailable = "f3MeWebApiUserToEmployeeUserDataNotAvailable";
  /**
   * f3MeWebApiUserToEmployeeUserDataNotAvailable from Cms.csv (in EN: Current user data are not available. The assignment of view of other user is not possible )
   */
  public get f3MeWebApiUserToEmployeeUserDataNotAvailable(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeUserDataNotAvailable, "Current user data are not available. The assignment of view of other user is not possible"); }

  /**
   * f3MeWebApiUserToEmployeeLoginWarningHeader from Cms.csv (in EN: Login warning message )
   */
  public key_f3MeWebApiUserToEmployeeLoginWarningHeader = "f3MeWebApiUserToEmployeeLoginWarningHeader";
  /**
   * f3MeWebApiUserToEmployeeLoginWarningHeader from Cms.csv (in EN: Login warning message )
   */
  public get f3MeWebApiUserToEmployeeLoginWarningHeader(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeLoginWarningHeader, "Login warning message"); }

  /**
   * f3MeWebApiUserToEmployeeIdentification from Cms.csv (in EN: [es_email] )
   */
  public key_f3MeWebApiUserToEmployeeIdentification = "f3MeWebApiUserToEmployeeIdentification";
  /**
   * f3MeWebApiUserToEmployeeIdentification from Cms.csv (in EN: [es_email] )
   */
  public get f3MeWebApiUserToEmployeeIdentification(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeIdentification, "[es_email]"); }

  /**
   * f3MeWebApiUserToEmployeeIdentificationHeader from Cms.csv (in EN: 'View data as' field )
   */
  public key_f3MeWebApiUserToEmployeeIdentificationHeader = "f3MeWebApiUserToEmployeeIdentificationHeader";
  /**
   * f3MeWebApiUserToEmployeeIdentificationHeader from Cms.csv (in EN: 'View data as' field )
   */
  public get f3MeWebApiUserToEmployeeIdentificationHeader(): string { return this.getStringDef(this.key_f3MeWebApiUserToEmployeeIdentificationHeader, "'View data as' field"); }


}
