import { ILongNameShortName } from "../../../angular-common/longnameshortname/longname-shortname.interface";
import { ISortOrder } from "../../../angular-common/sorting/sortorder";
import { LongNameShortName } from "../../../angular-common/longnameshortname/longname-shortname";
import { PresentableElementDto } from "../../models/dto/PresentableElement-dto";

export class PresentableElement implements ISortOrder, ILongNameShortName {
  public id: number;
  public shortName: string;
  public longName: string;
  public sortOrder: number;

  public constructor() {}

  public get displayName(): string {
    return LongNameShortName.NameFor(this);
  }

  public getCopy(): PresentableElement {
    const res = new PresentableElement();
    res.copyFrom(this);
    return res;
  }

  public copyFrom(other: PresentableElement) {
    if (other) {
      this.id = other.id;
      this.shortName = other.shortName;
      this.longName = other.longName;
      this.sortOrder = other.sortOrder;
    }
  }

  public copyFromDto(other: PresentableElementDto) {
    if (other) {
      this.id = other.KeyId;
      this.shortName = other.ShortName;
      this.longName = other.LongName;
      this.sortOrder = other.SortOrder;
    }
  }

  public assignDto(result: PresentableElementDto) {
    result.KeyId = this.id;
    result.ShortName = this.shortName;
    result.LongName = this.longName;
    result.SortOrder = this.sortOrder;
  }

  public toDto(): PresentableElementDto {
    const result = new PresentableElementDto();
    this.assignDto(result);
    return result;
  }
}
