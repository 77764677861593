import { FileUpload } from "./fileupload";

export class ImageFileupload extends FileUpload {
    public constructor(public maxFileSizeMb: number, public urlDefaultImage: string, public allowedFileTypes: string[]) {
        super(maxFileSizeMb, allowedFileTypes);
      }

      public get fileDataOrDefaultImage(): any {
        if (this.hasFile) {
          return this.fileData;
        } else if (this.urlDefaultImage && this.urlDefaultImage !== null) {
          return this.urlDefaultImage;
        }
      }
}