import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { LongNameShortName } from "../../../angular-common/longnameshortname/longname-shortname";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { ElementTypeDto } from "../../../common/models/dto/ElementType-dto";
import { ModelPresentableElementDto } from "../../../common/models/dto/ModelPresentableElement-dto";
import { VariantDto } from "../../../common/models/dto/VariantDTO-dto";
import { CalculatorPeriodModeDto } from "../../models/dto/CalculatorPeriodMode-dto";
import { TimePeriodDto } from "../../models/dto/TimePeriodDto-dto";
import { VariantCalculationDto } from "../../models/dto/VariantCalculationDto-dto";
import { ModelElementFactory } from "./model-element-factory";
import { ModelPresentableElement } from "./model-presentablel-element-model";
import { PresentableElement } from "./presentable-element.model";

export class Variant extends ModelPresentableElement {
  public dataSetId: number;
  periodMode: PresentableElement;
  periodEnd: PresentableElement;
  periodStart: PresentableElement;

  public constructor() {
    super(ElementTypeDto.Variant);
    this.id = -1;
    this.periodStart = new PresentableElement();
    this.periodEnd = new PresentableElement();
    this.periodMode = new PresentableElement();
  }

  public static createFromDto(value: VariantDto) {
    const result = new Variant();
    result.copyFromDto(value);
    return result;
  }

  public getVariantCopy(): Variant {
    const result = new Variant();
    result.copyFrom(this);
    return result;
  }

  public getCopy(): ModelPresentableElement {
    return this.getVariantCopy();
  }

  public get isBaseModel() {
    return this.id === 0;
  }

  public copyFromDto(other: VariantDto) {
    super.copyFromDto(other);
    if (other) {
      this.dataSetId = other.DataSetId;
    }

    this.periodMode = new PresentableElement();
    this.periodStart = new PresentableElement();
    this.periodEnd = new PresentableElement();

    if (other.Calculations) {
      this.periodStart.copyFrom(ModelElementFactory.createPeriod(other.Calculations.Start));
      this.periodEnd.copyFrom(ModelElementFactory.createPeriod(other.Calculations.End));
      this.periodMode.copyFromDto(other.Calculations.Mode);
    }
  }

  public calculationPeriodConfigurationText(language: WebLanguage) {
    const startText = LongNameShortName.NameFor(this.periodStart);
    const endText = LongNameShortName.NameFor(this.periodEnd);

    const periodDateText = language.VariantCalculationPeriodConfigurationDates(startText, endText);
    if (this.periodMode && this.periodMode.id !== 0) {
      const typeText = LongNameShortName.NameFor(this.periodMode);
      return language.VariantCalculationPeriodConfigurationDatePeriodType(typeText, periodDateText);
    } else {
      return periodDateText;
    }
  }

  public get canEditPeriodStart() {
    return this.periodMode && (this.periodMode.id == CalculatorPeriodModeDto.ReferenceDate || this.periodMode.id == CalculatorPeriodModeDto.FromStartAndEndDate);
  }

  public get canEditPeriodEnd() {
    return this.periodMode && this.periodMode.id == CalculatorPeriodModeDto.FromStartAndEndDate;
  }

  public copyFrom(other: Variant) {
    if (other) {
      super.copyFrom(other);
      this.dataSetId = other.dataSetId;
      this.periodStart = other.periodStart.getCopy();
      this.periodEnd = other.periodEnd.getCopy();
      this.periodMode = other.periodMode.getCopy();
    }
  }

  public toDto(): ModelPresentableElementDto {
    return this.toVariantDto();
  }

  public toVariantDto() {
    const result = new VariantDto();
    super.assignDto(result);
    result.DataSetId = this.dataSetId;
    result.Calculations = new VariantCalculationDto();
    if (this.canEditPeriodStart) {
      result.Calculations.Start = Variant.toTimePeriod(this.periodStart);
    }
    if (this.canEditPeriodEnd) {
      result.Calculations.End = Variant.toTimePeriod(this.periodEnd);
    }
    result.Calculations.Mode = this.periodMode.toDto();

    return result;
  }

  private static toTimePeriod(value: PresentableElement) {
    var r: TimePeriodDto = <any>{};
    if (value) {
      r.PeriodNumber = value.id;
    }
    return r;
  }

  public valuesNotEqual(other: Variant) {
    if (ElementHelper.isNullOrUndefined(other)) {
      return false;
    }

    if (this.shortName !== other.shortName) {
      return true;
    } else if (this.dataSetId !== other.dataSetId) {
      return true;
    } else if (this.periodMode.id !== other.periodMode.id) {
      return true;
    }
    if (this.canEditPeriodStart) {
      if (this.periodStart.id !== other.periodStart.id) {
        return true;
      }
    }
    if (this.canEditPeriodEnd) {
      if (this.periodEnd.id !== other.periodEnd.id) {
        return true;
      }
    }

    return false;
  }
}
