<div class="ebs-overview doughnut" *ngIf="hasGroups()">
    <div class="col-md-4 col-sm-12">
        <figure>
          <dx-pie-chart class="full-width" [dataSource]="ebsOverviewDto.groupsForChart" [series]="series" [legend]="legend" [size]="size"
            [palette]="ebsOverviewDto.palette" [tooltip]="tooltip" [type]="'donut'" [innerRadius]="0.72" [startAngle]="-270"
            (onPointClick)="groupClick($event)"></dx-pie-chart>
        </figure>
        <div>{{ ebsOverviewDto.ebsResult.totalLabourCondition.valueAsText }}</div>
      </div>
    
        <div class="col-md-8 col-sm-12">
    
            <div *ngIf="showPrintButton == true">
                <div class="printOverview">
                    <i class="fa fa-print fa-2x" *ngIf="showPrintButton === true" aria-hidden="true" (click)="sendPrintEvent()"></i>
                </div>
            </div>
        <app-ebs-benefitgroups [groups]="ebsOverviewDto.ebsResult.groups"></app-ebs-benefitgroups>
        <span class="ebsDisclaimer">{{ languageService.f3MeWebApiDisclaimerMessageEbs }}</span>
    </div>
</div>




