import { DateSpanDto } from './DateSpanDto-dto';
import { IPresentableElementDto } from './IPresentableElement-dto';
import { RoleAssignableFromDto } from './RoleAssignableFromDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file RoleDto.cs
// Don't adjust manually!

export class RoleDto implements IPresentableElementDto {
    public AssignableFrom: RoleAssignableFromDto;
    public ValidityPeriod: DateSpanDto;
    public KeyId: number;
    public LongName: string;
    public ShortName: string;
    public SortOrder: number;
}

