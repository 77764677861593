<div *ngIf="!isLoginPage">
  <div *ngIf="menuItems.length > 1; else hideBlock">
    <div class="mobile-menu" [class.mobile-menu-border-bottom]="!isCollapsed">
      <button class="mobile-menu-button" type="button" data-toggle="collapse" data-target="#main-menu-items" (click)="toggleMenuVisibility()">
        <span [class.arrow-up]="isCollapsed" [class.arrow-down]="!isCollapsed">{{ languageService.f3MeWebApiMainMenu }}</span>
      </button>
    </div>

    <div id="main-menu-items" class="menu-items-container collapse" [class.in]="getMenuVisibility()">
      <ul class="menu-items">
        <li *ngFor="let menuItem of menuItems" class="menu-item-container">
          <a class="menu-item" routerLink="{{ menuItem.route }}" [routerLinkActive]="['active-menu-item']">{{ menuItem.displayTitle }}</a>
        </li>
      </ul>
    </div>
  </div>
  <ng-template #hideBlock>
    <div class="HideBecauseOneMenuItem"></div>
  </ng-template>
</div>
