import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxFormModule,
  DxListModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTextBoxModule,
  DxTreeViewModule,
  DxValidationGroupModule,
  DxValidatorModule,
} from "devextreme-angular";
import { AuthorizationLanguage } from "../../../common/language/Authorizationlanguage.service";
import { AuthorizationRoutesDto } from "../../../common/models/dto/AuthorizationRoutes-dto";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { canActivateRoleGuard } from "../../authentication-guard/role-guard";
import { CardViewModule } from "../cardview/cardview.module";
import { AuthorizationScreenComponent } from "./component/authorization-screen.component";
import { AuthorizationRolesMaintenanceComponent } from "./component/rolesmaintenance/authorization-content-component-rolesmaintenance";
import { RoleDetailsComponent } from "./component/rolesmaintenance/details/authorization-content.component-role-details";
import { AuthBaseContentComponent } from "./component/tabs/authorization-tabs/auth-content-base.component";
import { AuthorizationContentComponent } from "./component/tabs/authorization-tabs/authorization-content.component";
import { AuthorizationTabsComponent } from "./component/tabs/authorization-tabs/authorization-tabs.component";
import { AuthorizationUsersComponent } from "./component/users/authorization-content.component-users";
import { UserDetailsFormComponent } from "./component/users/details/authorization-content.component-user-details";
import { UserListComponent } from "./component/users/list/authorization-content.component-userlist";
import { RolesTreeViewComponent } from "./component/users/roles/authorization-content.component-roles-treeview";
import { AuthorizationRolesDataModule } from "./services/authorization-roles.module";

const routes: Routes = [
  {
    path: AuthorizationRoutesDto.Authorization,
    data: [{ menuType: MenuItemTypesDto.Authorization }],
    component: AuthorizationScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CardViewModule,
    DxButtonModule,
    DxFormModule,
    DxValidatorModule,
    DxTextBoxModule,
    DxListModule,
    AuthorizationRolesDataModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTreeViewModule,
    DxTabsModule,
    DxValidationGroupModule,
    DxDateBoxModule,
  ],
  exports: [AuthorizationScreenComponent, AuthBaseContentComponent],
  declarations: [
    RoleDetailsComponent,
    AuthorizationRolesMaintenanceComponent,
    RoleDetailsComponent,
    UserListComponent,
    AuthorizationUsersComponent,
    UserDetailsFormComponent,
    RolesTreeViewComponent,
    AuthorizationTabsComponent,
    AuthorizationScreenComponent,
    AuthBaseContentComponent,
    AuthorizationContentComponent,
  ],
  providers: [AuthorizationLanguage],
})
export class AuthorizationEditModule {}
