import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { CommonRoutesDto } from "../../../../common/models/dto/CommonRoutes-dto";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { ErrorDetection } from "../../../errorhandler/ErrorDetection";
import { StringComparer } from "../../../stringcomparison";
import { Subscription } from "rxjs";
import { UserInformationService } from "../../../authentication/user-information.service";
import { WebLanguage } from "../../../../common/language/weblanguage.service";

@Component({
  selector: "app-error-insufficient-rights",
  templateUrl: "./error-insufficient-rights.component.html",
  styleUrls: ["./error-insufficient-rights.component.scss"],
})
export class ErrorInsufficientRightsComponent implements OnDestroy, OnInit {
  private _roleMissing: string;
  private _apiLocationNotAllowed: string;
  private _noData: string;
  private routeSubscription: Subscription;

  public userStatusChecked: boolean;
  public userIsValid: boolean;

  constructor(public language: WebLanguage, activatedroute: ActivatedRoute, private router: Router, private userInfoServer: UserInformationService, private envSettings: EnvironmentSettings) {
    this.userStatusChecked = false;
    this.userIsValid = false;

    this.routeSubscription = activatedroute.params.subscribe((params) => {
      this._roleMissing = params["roles"];
      this._apiLocationNotAllowed = params["api"];
      this._noData = params["noData"];
    });
  }
  ngOnInit(): void {
    this.userInfoServer
      .checkUserStatus()
      .catch((reason) => {
        ErrorDetection.findErrorType(reason, this.envSettings);
        return false;
      })
      .then((userValid) => {
        this.userStatusChecked = true;
        this.userIsValid = userValid;
        return userValid;
      });
  }

  public gotoSignout() {
    this.router.navigate(["/" + CommonRoutesDto.SignOff]);
  }

  public gotoClaims() {
    this.router.navigate(["/" + CommonRoutesDto.Claims]);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  public get userHasData() {
    return StringComparer.isNullUndefinedOrEmpty(this._noData);
  }

  public get errorText() {
    if (this._apiLocationNotAllowed) {
      return this.language.error_insufficientrights_text_api(this._apiLocationNotAllowed);
    } else if (this._roleMissing) {
      return this.language.error_insufficientrights_text_role(this._roleMissing);
    } else if (this._noData) {
      return this.language.loginNoDataForUser;
    }
    return "";
  }
}
