import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../angular-common";

import { EbsResult } from "./_models/ebs-result.model";
import { EbsResultDto } from "../models/ebs/dto/EbsResult-dto";
import { EbsResults } from "./_models/ebs-results.model";
import { EbsSummary } from "./_models/ebs-summary.model";
import { EbsSummaryDto } from "..//models/ebs/dto/EbsSummary-dto";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { map } from "rxjs/operators";

@Injectable()
export class EBSService extends PlBaseService {
  readonly endPoint = "ebs";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, private selectLanguage: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getEbs(useLoadIndicator: boolean): Observable<EbsResult[]> {
    if (useLoadIndicator) {
      return this.getDataTransformed<EbsResultDto[]>(this.endPoint).pipe(map((r) => this.transformResult(r)));
    } else {
      return this.getDataTransformedWithoutLoader<EbsResult[]>(this.endPoint);
    }
  }

  public getEbsSummary(): Observable<EbsSummary> {
    const apiUrl = this.endPoint + "summary/";
    return this.getDataTransformed<EbsSummaryDto>(apiUrl).pipe(map((r) => this.transformEbsResult(r)));
  }

  private transformEbsResult(dto: EbsSummaryDto): EbsSummary {
    const result = new EbsSummary();
    result.copyFromDTO(dto);
    return result;
  }

  public getEbsById(id: string): Observable<EbsResult> {
    return this.getDataTransformed<EbsResultDto>(this.endPoint + "/" + id).pipe(map((r) => this.transformSingleResult(r)));
  }

  public transformResult(dto: EbsResultDto[]): EbsResult[] {
    const result = new EbsResults(this.selectLanguage);
    result.copyFromDTO(dto);
    return result.ebsResultList;
  }

  private transformSingleResult(dto: EbsResultDto): EbsResult {
    const result = new EbsResult(this.selectLanguage);
    result.copyFromDTO(dto);
    return result;
  }
}
