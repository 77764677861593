import { ModelElementDto } from './../../../../common/models/dto/ModelElementDto-dto';

// t4tsCode gen": "0.8.2.0 for file PreferedUserEmployeeMappingConfigurationDto.cs
// Don't adjust manually!

export class PreferedUserEmployeeMappingConfigurationDto {
    public AvailableModelElements: ModelElementDto [] = [];
    public ConfiguredModelElements: ModelElementDto [] = [];
}

