import { ExogenousVariables } from "../../../../common/modelelements/exogenousvariables/exogenous-variables.model";
import { UserFieldsEditMetaDataDto } from "../../../models/cms/dto/UserFieldsEditMetaData-dto";
import { UserField } from "./userfield.model";

export class UserFieldsEditMetaData {
  public constructor() {
    this.exogenousVariables = new ExogenousVariables([]);
    this.userFields = [];
  }

  public exogenousVariables: ExogenousVariables;
  public userFields: UserField[];

  public copyFromDto(dto: UserFieldsEditMetaDataDto) {
    this.exogenousVariables.copyFromDto(dto.ExogenousVariables);
    dto.UserFields.forEach((ufDto) => {
      const newField = new UserField();
      newField.copyFromDto(ufDto);
      this.userFields.push(newField);
    });
  }
}
