
// t4tsCode gen": "0.8.2.0 for file UserFieldDto.cs
// Don't adjust manually!

export class UserFieldDto {
    public Title: string;
    public Key: string;
    public ExogenousVariableShortName: string;
}

