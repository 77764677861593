import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { PreferredModelDto } from "../../../common/models/dto/PreferredModelDto-dto";
import { PreferredModelMetaDataDto } from "../../../common/models/dto/PreferredModelMetaDataDto-dto";
import { PlAuthenticationService } from "../../authentication/authentication.service";
import { PlUserStorage } from "../../baseservice/pluserstorage";
import { ElementHelper } from "../../helpers/element.helper";
import { StringHelper } from "../../helpers/string.helper";
import { BrowserStorage } from "../../storage/browser-storage";
import { BrowserStorageType } from "../../storage/browser-storage-type";
import { WarningProvider } from "../../warning-provider/warning-provider";
import { WarningType } from "../../warning-provider/warning-type";
import { ModelSelectionSection } from "./model-selection-section";
import { ModelSelectionType } from "./model-selection-type";
import { PreferredModelService } from "./preferred-model.service";

@Component({
  selector: "app-preferred-model",
  templateUrl: "./preferred-model.component.html",
  styleUrls: ["./preferred-model.component.scss"],
})
export class PreferredModelComponent {
  constructor(
    private route: ActivatedRoute,
    private preferredModelService: PreferredModelService,
    public language: WebLanguage,
    private authenticationService: PlAuthenticationService,
    public warningProvider: WarningProvider,
  ) {
    this.route.data.subscribe((data: { metaData: PreferredModelMetaDataDto }) => {
      this.availableModels = data.metaData.AvailableModels;
      this.userPreferredModel = data.metaData.UserPreferredModel;
    });

    this.sections.push(new ModelSelectionSection(ModelSelectionType.Storage, this.language.preferredModelStorageSelection));
    this.sections.push(new ModelSelectionSection(ModelSelectionType.Session, this.language.preferredModelSessionSelection));
  }

  public readonly sections: ModelSelectionSection[] = [];
  public selectedSection: ModelSelectionType = ModelSelectionType.Storage;

  public get isStorageSectionSelected() {
    return this.selectedSection === ModelSelectionType.Storage;
  }

  public get isSessionSectionSelected() {
    return this.selectedSection === ModelSelectionType.Session;
  }

  public availableModels: string[];
  public userPreferredModel: PreferredModelDto;

  public get userModel(): string {
    if (this.userPreferredModel.KeyId !== -1) {
      return this.userPreferredModel.ConnectionName;
    }
    return "";
  }

  public set userModel(value: string) {
    this.userPreferredModel.ConnectionName = value;
  }

  private _selectedSessionModel: string;

  public get sessionModel() {
    return PlUserStorage.getPreferredModelFrom(BrowserStorageType.Session);
  }

  public set sessionModel(value: string) {
    this._selectedSessionModel = value;
  }

  public get sessionModelWarning(): string {
    return this.warningProvider.warningFor(WarningType.SessionModelActive);
  }

  public get sessionModelActive(): boolean {
    return StringHelper.hasNonEmptyText(this.sessionModel);
  }

  public onSaveModel() {
    if (this.userPreferredModel.KeyId === -1 && ElementHelper.isNullOrUndefined(this.userPreferredModel.ConnectionName) === false) {
      this.preferredModelService.createPreferredModel(this.userPreferredModel).subscribe((result) => {
        this.userPreferredModel = result;
        PlUserStorage.updatePreferredModel(result.ConnectionName);
        this.authenticationService.refreshUserData().subscribe();
      });
    } else {
      if (ElementHelper.isNullOrUndefined(this.userPreferredModel.ConnectionName)) {
        this.preferredModelService.deletePreferredModel(this.userPreferredModel).subscribe(() => {
          this.clearCurrentModel();
          PlUserStorage.updatePreferredModel("");
          this.authenticationService.refreshUserData().subscribe();
        });
      } else {
        this.preferredModelService.updatePreferredModel(this.userPreferredModel).subscribe((result) => {
          this.userPreferredModel = result;
          PlUserStorage.updatePreferredModel(result.ConnectionName);
          this.authenticationService.refreshUserData().subscribe();
        });
      }
    }
  }

  public onUseOnSession() {
    if (StringHelper.hasNonEmptyText(this._selectedSessionModel) === true) {
      BrowserStorage.setSessionStorage();
      PlUserStorage.updatePreferredModel(this._selectedSessionModel);
      this.authenticationService.refreshUserData().subscribe();
    }
  }

  public onRemoveSessionChoice() {
    BrowserStorage.clearSessionStorage();
    this.userPreferredModel.ConnectionName = PlUserStorage.getPreferredModelFrom(BrowserStorageType.Local);
    this.authenticationService.refreshUserData().subscribe((data) => {});
  }

  private clearCurrentModel() {
    this.userPreferredModel.KeyId = -1;
    this.userPreferredModel.ConnectionName = null;
  }
}
