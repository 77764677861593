import { Component, Input } from "@angular/core";
import { alert, confirm } from "devextreme/ui/dialog";

import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EmailTemplate } from "./email-template";
import { EmailService } from "./email.service";

@Component({
  selector: "app-email-component",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
  providers: [],
})
export class EmailComponent {
  public constructor(public language: CmsLanguage, private selectLanguage: PlLanguageSelection, private emailService: EmailService) {}

  private _template: EmailTemplate = null;
  public body: string = null;
  public subject: string = null;

  public showPopup() {
    if (this.refreshMailOnPopup) {
      this.loadEmailTemplate(true);
    } else {
      this.isVisible = true;
    }
  }

  public closePopup(): void {
    this.isVisible = false;
  }

  public sendEmail(): void {
    const result = confirm(this.language.f3MeWebApiEmailConfirm, this.language.applicationTitle).then((answer) => {
      if (answer) {
        // We include the language that was used to fill in the template.
        const emailDTO = this._template.toUserDTO();
        const language = this.selectLanguage.currentLanguage;
        emailDTO.Language = language;
        emailDTO.Body[language] = this.body;

        this.emailService.sendEmail(emailDTO).subscribe((voidValue) => {
          alert(this.language.f3MeWebApiEmailSent, this.language.applicationTitle);
          this.isVisible = false;
        });
      }
    });
  }

  public isVisible: boolean = false;

  private loadEmailTemplate(popupAfterLoad: boolean) {
    if (this.emailTemplateId !== undefined && this.emailTemplateId > -1) {
      this.emailService.getTemplate(this.emailTemplateId).subscribe((t) => {
        this._template = t;
        this.body = this.selectLanguage.currentValueFor(t.body);
        this.subject = this.selectLanguage.currentValueFor(t.subject);

        if (popupAfterLoad) {
          this.isVisible = true;
        }
      });
    }
  }

  public get template() {
    return this._template;
  }

  private _emailTemplateId: number = -1;
  @Input()
  public set emailTemplateId(newEmailTemplateId: number) {
    if (newEmailTemplateId !== undefined && newEmailTemplateId !== null) {
      this._emailTemplateId = newEmailTemplateId;
      this.loadEmailTemplate(false);
    }
  }
  public get emailTemplateId() {
    return this._emailTemplateId;
  }

  @Input() disableHtmlEditor: boolean;
  @Input() buttonTitle: string;
  @Input() hintText: string;
  @Input() refreshMailOnPopup: boolean = false;
}
