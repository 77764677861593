<div class="preferred-model">
  <div class="section-selection">
    <dx-radio-group [items]="sections" layout="horizontal" [(value)]="selectedSection" displayExpr="name" valueExpr="type"> </dx-radio-group>
  </div>

  <app-cardview [title]="language.preferredModelStorageSelection" *ngIf="isStorageSectionSelected">
    <dx-form [colCount]="2">
      <dxi-item [colSpan]="2">
        <dxo-label [text]="language.preferredModelAvailableModels"></dxo-label>
        <div *dxTemplate>
          <dx-select-box [(value)]="userModel" [dataSource]="availableModels" [showClearButton]="true" [disabled]="sessionModelActive"> </dx-select-box>
        </div>
      </dxi-item>
    </dx-form>
    <div>
      <div class="buttons-bottom-margin align-right">
        <dx-button id="saveButton" icon="save" [text]="language.ButtonSave" class="button-save-stored-model" (onClick)="onSaveModel()" [disabled]="sessionModelActive"></dx-button>
      </div>
    </div>
    <div *ngIf="sessionModelActive" class="warning-container">
      {{ sessionModelWarning }}
    </div>
  </app-cardview>

  <app-cardview [title]="language.preferredModelSessionSelection" *ngIf="isSessionSectionSelected">
    <dx-form [colCount]="2">
      <dxi-item [colSpan]="2">
        <dxo-label [text]="language.preferredModelAvailableModels"></dxo-label>
        <div *dxTemplate>
          <dx-select-box [(value)]="sessionModel" [dataSource]="availableModels" [showClearButton]="true"> </dx-select-box>
        </div>
      </dxi-item>
    </dx-form>
    <div>
      <div class="buttons-bottom-margin align-right">
        <dx-button id="useForCurrentSession" icon="fas fa-bookmark" [text]="language.preferredModelUseOnSession" class="button-session" (onClick)="onUseOnSession()"></dx-button>
        <dx-button
          id="resetCurrrentSession"
          icon="fas fa-unlink"
          [text]="language.preferredModelRemoveSessionChoice"
          class="button-session"
          (onClick)="onRemoveSessionChoice()"
          [disabled]="!sessionModelActive"
        ></dx-button>
      </div>
    </div>
  </app-cardview>
</div>
