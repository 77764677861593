import { Component, Input, OnInit } from "@angular/core";

import { Observable } from "rxjs";
import { StringHelper } from "../../../angular-common/helpers/string.helper";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { PlAuthenticationService } from "../../authentication/authentication.service";
import { EnvironmentSettings } from "../../baseservice/environment-settings";
import { PlUserStorage } from "../../baseservice/pluserstorage";
import { RouteNavigation } from "../../baseservice/route-navigation";
import { ElementHelper } from "../../helpers/element.helper";
import { PlLoadIndicator } from "../../loadindicator/loadindicator";

@Component({
  selector: "app-pllogin-signin",
  templateUrl: "./pllogin-signin.component.html",
  styleUrls: ["./_login.scss"],
})
export class PlLoginSingInComponent implements OnInit {
  @Input() afterLogin: () => Observable<boolean>;
  @Input() logoImageUrl: string;

  constructor(
    private environmentSettings: EnvironmentSettings,
    public authenticationService: PlAuthenticationService,
    public router: RouteNavigation,
    private loadIndicator: PlLoadIndicator,
    public language: WebLanguage,
  ) {
    this.login = this.login.bind(this);
    this.loginExternalAuth = this.loginExternalAuth.bind(this);
    this.loading = false;
  }

  onSuccesfullLogin() {
    const user = PlUserStorage.user;
    if (user && (user.DataInSystem === false || user.Identification === null || user.Identification === undefined)) {
      this.error = this.language.loginNoDataForUser;
    } else {
      if (this.afterLogin) {
        let afterLoginHook = this.afterLogin();
        if (afterLoginHook) {
          console.debug("Running after login hook.");
          afterLoginHook.subscribe((v) => {
            if (v) {
              this.goToLandingPage();
            }
          });

          return;
        } else {
          console.error("Login hook bound, but not returning an obverable");
        }
      }

      this.goToLandingPage();
    }
  }

  private goToLandingPage() {
    console.debug("landing page from login");
    this.router.gotToLandingPage();
  }

  ngOnInit(): void {
    this.setLogoUrl();
  }

  public loading: boolean;
  public error: string = "";

  private setLogoUrl() {
    if (ElementHelper.isNullOrUndefined(this.logoImageUrl)) {
      this.logoImageUrl = "assets/images/logo.png";
    }
  }

  public get utilizeBasicAuth(): boolean {
    return this.environmentSettings.useExternalAuthentication() === false;
  }

  public loginExternalAuth() {
    PlUserStorage.logOut();
    console.log("login external");
    let obs = this.authenticationService.windowsLogin();
    obs = this.loadIndicator.register(obs);
    this.onLoginAttempt(obs);
  }

  public login(usernameValue: string, passwordValue: string) {
    PlUserStorage.logOut();
    console.log("login basic");

    if (this.utilizeBasicAuth === false) {
      this.loginExternalAuth();
      return;
    }

    this.loading = true;
    let obs = this.authenticationService.login(usernameValue, passwordValue);
    obs = this.loadIndicator.register(obs);
    this.onLoginAttempt(obs);
  }

  private onLoginAttempt(obs: Observable<boolean>) {
    obs.subscribe(
      (result) => {
        if (result === true) {
          this.onSuccesfullLogin();
          this.loading = false;
        } else {
          if (this.utilizeBasicAuth) {
            this.error = this.language.loginInvalidCredentials;
            this.loading = false;
          }
          if (!this.utilizeBasicAuth) {
            this.error = this.language.loginRedirect;
            this.loading = true;
          }
        }
      },
      (e) => {
        console.log(e);
        if (e && StringHelper.hasNonEmptyText(e.error)) {
          this.error = e.error;
        } else {
          this.error = this.language.loginInvalidCredentials;
        }
        this.loading = false;
      },
    );
  }
}
