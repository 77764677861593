import { Observable, of } from "rxjs";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { map } from "rxjs/operators";
import { HeaderInterceptor } from "../../../angular-common/baseservice/httpinterceptor";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { WellknownAppNamesDto } from "../../../common/models/dto/WellknownAppNames-dto";
import { FixedImageModel } from "../../fixedimages/fixedimage.model";
import { FixedImageDto } from "../../models/cms/dto/FixedImage-dto";
import { FixedImageNamesDto } from "../../models/cms/dto/FixedImageNames-dto";
import { ImagesMetaDataDto } from "../../models/cms/dto/ImagesMetaData-dto";
import { ImageChanges } from "./image-changes.model";

@Injectable()
export class ImageService extends PlBaseService {
  private endPoint: string = "Image";
  private endPointFixed: string = "FixedImage";

  private _imagineInformation: ImagesMetaDataDto;

  public defaultImagineExtensions: string[];

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private sanitizer: DomSanitizer) {
    super(http, envSettings, loadIndicator);
    this._imagineInformation = undefined;
    this.defaultImagineExtensions = [".jpg", ".jpeg", ".gif", ".png"];
  }

  private imageInformation(): Observable<ImagesMetaDataDto> {
    if (this._imagineInformation !== undefined) {
      return of(this._imagineInformation);
    } else {
      const uri = this.envSettings.getUri(this.endPointFixed);
      return this.getDataTransformedWithoutLoader<ImagesMetaDataDto>(uri).pipe(
        map((f) => {
          this._imagineInformation = f;
          return this._imagineInformation;
        }),
      );
    }
  }

  public fixedImages(): Observable<FixedImageDto[]> {
    return this.imageInformation().pipe(
      map((f) => {
        return f.FixedImages;
      }),
    );
  }

  public allowedImageExtensions(): Observable<string[]> {
    return this.imageInformation().pipe(
      map((f) => {
        return f.AllowedExtensions;
      }),
    );
  }

  public uriFor(image: FixedImageDto) {
    return this.envSettings.getUri(this.endPoint) + "/" + image.Id;
  }

  private imageUriFor(id: string) {
    return this.envSettings.getUri(this.endPointFixed) + "/" + id;
  }

  public get urlDefaultImage(): Observable<FixedImageModel> {
    const model = new FixedImageModel(this.imageUriFor(FixedImageNamesDto.PlaceHolder), "");
    return this.transformProtectedUri(model);
  }
  public get urlMugshot(): Observable<FixedImageModel> {
    const model = new FixedImageModel(this.imageUriFor(FixedImageNamesDto.MugShot), "");
    return this.transformProtectedUri(model);
  }
  public get urlLogo(): Observable<FixedImageModel> {
    const model = new FixedImageModel(this.imageUriFor(FixedImageNamesDto.Logo), "");
    return this.transformProtectedUri(model);
  }
  public get urlLogoLogin(): string {
    if (this.envSettings.appkey == WellknownAppNamesDto.ForAll) {
      return ImageService.createAssetsUrl(FixedImageNamesDto.LogoLogin);
    } else {
      return this.imageUriFor(FixedImageNamesDto.LogoLogin);
    }
  }
  public get urlLogin(): string {
    if (this.envSettings.appkey == WellknownAppNamesDto.ForAll) {
      return ImageService.createAssetsUrl(FixedImageNamesDto.Login);
    } else {
      return this.imageUriFor(FixedImageNamesDto.Login);
    }
  }

  public static createAssetsUrl(shortName: string) {
    return `assets/images/${shortName}`;
  }

  public transformProtectedUri(imageModel: FixedImageModel): Observable<FixedImageModel> {
    if (PlUserStorage.hasUser() === false) {
      imageModel.isLoaded = true;
      imageModel.protectedUri = imageModel.originalUri;
      return of(imageModel);
    }

    return this.fetchImage(imageModel.originalUri).pipe(
      map((data) => {
        const urlObject = URL.createObjectURL(data);
        imageModel.protectedUri = this.sanitizer.bypassSecurityTrustResourceUrl(urlObject) as string;
        imageModel.isLoaded = true;

        return imageModel;
      }),
    );
  }

  public fetchImage(url: string): Observable<Blob> {
    if (this.envSettings.useExternalAuthentication() === true) {
      const options = HeaderInterceptor.createHttpOptionsForImage();
      return this.http.get<Blob>(url, options);
    } else {
      const options = HeaderInterceptor.createHttpOptionsForTokenProtectedResource();
      return this.http.get<Blob>(url, options);
    }
  }

  public deleteImage(imageUrl: string): Observable<string> {
    if (this.isEmptyLocation(imageUrl)) {
      return of("");
    }

    return this.remove(imageUrl);
  }

  // Processes changes made to an image, returns the new url of the image. This can be an empty
  // string when the image was deleted.
  public processImageChanges(imageChanges: ImageChanges): Observable<string> {
    if (imageChanges.deleted) {
      if (imageChanges.originalUrl === this.imageUriFor(FixedImageNamesDto.PlaceHolder)) {
        // Image was deleted but it was the default image.
        return of("");
      } else if (imageChanges.hasOriginalImage) {
        return this.deleteImage(imageChanges.originalUrl);
      }
    } else if (imageChanges.uploaded) {
      const imageChangesDto = imageChanges.toDTO();
      if (imageChanges.hasOriginalImage === false || imageChanges.originalUrl === this.imageUriFor(FixedImageNamesDto.PlaceHolder)) {
        return this.createData(this.endPoint, imageChangesDto).pipe(map((d) => d.location));
      } else {
        return this.updateTransformedResponse(imageChangesDto, imageChanges.originalUrl).pipe(map((r) => imageChanges.originalUrl));
      }
    }
  }

  public replaceFixedImage(imageChanges: ImageChanges): Observable<string> {
    // Fixed images can't be deleted, only replaced.
    if (imageChanges.deleted === false) {
      const imageChangesDto = imageChanges.toDTO();
      return this.updateTransformedResponse(imageChangesDto, imageChanges.originalUrl).pipe(map((r) => imageChanges.originalUrl));
    } else {
      return of(imageChanges.originalUrl);
    }
  }
}
