
// t4tsCodeFromCs": "0.8.2.0 for file WellKnownRoleNames.cs
// Don't adjust manually!

export class WellKnownRoleNamesDto {
    public static CorporateAdministrator: string = "Administrator";
    public static User: string = "user";
    public static WebAdmin: string = "webadmin";
    public static TechnicalMaintainer: string = "TechnicalMaintainer";
    public static UserInsights: string = "UserInsights";
    public static AuditLogsViewer: string = "AuditLogsViewer";
    public static Data: string = "Data";
    public static Authorization: string = "Authorization";
    public static ApplicationConsultant: string = "Application consultant";
    public static ModelEditing: string = "ModelEditing";
}

