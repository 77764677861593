import { DxButtonModule, DxFormModule, DxValidatorModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UserInformationScreenComponent } from "./user-information-screen.component";

@NgModule({
  declarations: [UserInformationScreenComponent],
  imports: [CommonModule, DxFormModule, DxValidatorModule, DxButtonModule],
})
export class UserInformationScreenModule {}
