import { Component, EventEmitter, Input, Output } from "@angular/core";

import { CHART_CONFIG } from "../_models/ebs-chart-configuration.model";
import { EbsLanguage } from "../language/ebslanguage.service";
import { EbsOverviewData } from "./ebs-overview-data";

@Component({
  selector: "app-ebs-doughnut-component",
  templateUrl: "./ebs-doughnut-component.html",
  styleUrls: ["./ebs-doughnut-component.scss"],
})
export class EbsDoughnutComponent {
  constructor(public languageService: EbsLanguage) {}

  public series: any = CHART_CONFIG.series;
  public legend: any = CHART_CONFIG.legend;
  public size: any = CHART_CONFIG.size;
  public tooltip: any = CHART_CONFIG.tooltip;

  @Input()
  ebsOverviewDto: EbsOverviewData;

  @Input()
  showPrintButton: boolean;

  @Output() printEvent: EventEmitter<void> = new EventEmitter<void>();

  public groupClick(e) {
    const id: number = this.ebsOverviewDto.groupsForChart[e.target.index].id;

    for (const group of this.ebsOverviewDto.ebsResult.groups) {
      if (group.id === id) {
        group.click();
      }
    }
  }

  sendPrintEvent() {
    this.printEvent.next();
  }

  hasGroups() {
    if (this.ebsOverviewDto.ebsResult !== undefined && this.ebsOverviewDto.ebsResult !== null && this.ebsOverviewDto.ebsResult.groups.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
