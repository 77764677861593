import { LanguageDictionary, LanguageItem } from "../../../angular-common/language/language-dto.model";

import { Injectable } from "@angular/core";
import { LanguageData } from "./languagedata.model";
import { LanguageEditService } from "./languageedit.service";
import { PlLanguageData } from "../../../angular-common/language/pllanguagedata.model";
import { PlUserStorage } from "../../../angular-common";

@Injectable({ providedIn: "root" })
export class PersonlizedLanguageService {
  constructor(private languageEditService: LanguageEditService) {}

  private localSource: LanguageData = new LanguageData();
  private personalizedSource: LanguageDictionary = null;

  public transForm(dto: PlLanguageData) {
    this.localSource.MetaData = dto.MetaData;
    this.localSource.Values = dto.Values;

    if (PlUserStorage.hasUser()) {
      return this.getEntries()
        .toPromise()
        .then((leResult) => {
          this.addPersonalizedText(leResult.Entries);
          return dto;
        });
    } else {
      return dto;
    }
  }

  public addPersonalizedText(personalizedTexts: LanguageDictionary) {
    this.personalizedSource = personalizedTexts;
  }

  public removePersonalizedText() {
    this.personalizedSource = null;
  }

  public getEntry(id: string): LanguageItem {
    let entry: LanguageItem;
    if (this.personalizedSource !== null) {
      entry = this.personalizedSource[id];
    }
    if (this.localSource && this.localSource.Values && !entry) {
      entry = this.localSource.Values[id];
    }

    return entry;
  }

  public getEntries() {
    return this.languageEditService.getEntries();
  }

  getStringDef(id: string, currentLanguage: string): string {
    if (this.personalizedSource !== null) {
      const entry = this.personalizedSource[id];
      if (entry) {
        const textForUser = entry[currentLanguage];
        if (textForUser) {
          return textForUser;
        }
      }
    }
  }
}
