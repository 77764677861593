
// t4tsCodeFromCs": "0.8.2.0 for file CommonRoutes.cs
// Don't adjust manually!

export class CommonRoutesDto {
    public static Login: string = "login";
    public static SignOff: string = "signoff";
    public static Claims: string = "claims";
    public static LandingPage: string = "landingpage";
    public static InsufficientRights: string = "insufficient-rights";
    public static UserInformationEdit: string = "user-information-edit";
    public static UserLoginsOverviewDashboard: string = "userloginsoverviewdashboard";
    public static UserAcknowledgement: string = "user-acknowledgement";
}

