import { BenefitValue } from "./benefit-value.model";
import { BenefitValueDto } from "../../models/ebs/dto/BenefitValue-dto";
import { EbsSummaryDto } from "../../models/ebs/dto/EbsSummary-dto";

export class EbsSummary {
  public constructor() {}

  public shortName: string;
  public totalLabourCondition: BenefitValue;
  public totalSalary: BenefitValue;
  public labourConditionMinusSalary: BenefitValue;
  public salaryPercentage: BenefitValue;
  public availableLeaveDays: BenefitValue;

  public get isEmpty(): boolean {
    return this.totalLabourCondition.isEmpty && this.totalSalary.isEmpty && this.labourConditionMinusSalary.isEmpty && this.salaryPercentage.isEmpty && this.availableLeaveDays.isEmpty;
  }

  public copyFromDTO(dto: EbsSummaryDto) {
    this.shortName = dto.ShortName;
    this.totalLabourCondition = this.createValue(dto.TotalLabourCondition);
    this.totalSalary = this.createValue(dto.TotalSalary);
    this.labourConditionMinusSalary = this.createValue(dto.LabourConditionMinusSalary);
    this.salaryPercentage = this.createValue(dto.SalaryPercentage);
    this.availableLeaveDays = this.createValue(dto.AvailableLeaveDays);
  }

  private createValue(dto: BenefitValueDto): BenefitValue {
    const result = new BenefitValue(dto.ValueAsText, dto.ValueAsNumber, dto.AppliesToTotal, dto.Visible);
    return result;
  }
}
