import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { RatingLanguage } from "../language/ratinglanguage.service";
import { IRatingViewData } from "../view/i-rating-view-data";
import { IRatingViewComponent } from "../view/i-ratingview-components";
import { MessageRatingService } from "./message-rating.service";
import { MessageRatingMetaData } from "./models/message-rating-metadata.model";
import { MessageRating } from "./models/message-rating.model";

@Component({
  selector: "app-message-rating",
  templateUrl: "./message-rating.component.html",
  styleUrls: ["./message-rating.component.scss"],
})
export class MessageRatingComponent implements OnInit, OnDestroy, IRatingViewComponent {
  private _ratingServiceSubscription: Subscription;

  constructor(private messageRatingService: MessageRatingService, public languageService: RatingLanguage) {
    this.data = { messageId: -1, messageType: MessageTypeDto.unknown };
  }

  @Input()
  data: IRatingViewData;

  ngOnInit() {
    this._ratingServiceSubscription = this.messageRatingService.dataAvailable.subscribe(() => {
      this.updateRating();
    });
  }

  ngOnDestroy() {
    if (this._ratingServiceSubscription) {
      this._ratingServiceSubscription.unsubscribe();
    }
  }

  private updateRating() {
    this.metaData = this.messageRatingService.getMetaData(this.data.messageType);

    const oldRating = this.rating;
    const newRating = this.messageRatingService.getRating(this.data.messageType, this.data.messageId);

    if (ElementHelper.isNullOrUndefined(oldRating) === false && oldRating.hasAverageScore && newRating.hasAverageScore === false) {
      // Prevent unnecesary refresh if we're waiting for new data
      return;
    }

    this.rating = newRating;
  }

  public metaData: MessageRatingMetaData;
  public rating: MessageRating;
  public get contentLoaded(): boolean {
    return ElementHelper.isNullOrUndefined(this.rating) === false && ElementHelper.isNullOrUndefined(this.metaData) === false;
  }

  public get toolTip(): string {
    if (this.contentLoaded) {
      let result = "";
      if (this.rating.hasUserScore && this.rating.hasAverageScore) {
        result = this.languageService.f3MeWebApiRatingTooltipAverageWithUser(this.rating.averageScoreAsString, this.rating.numberOfScoresAsString, this.rating.userScoreAsString);
      } else if (this.rating.hasAverageScore) {
        result = this.languageService.f3MeWebApiRatingTooltipAverage(this.rating.averageScoreAsString, this.rating.numberOfScoresAsString);
      }
      return result + " " + this.languageService.f3MeWebApiRatingClickToRate;
    }
    return undefined;
  }

  public setScore(newScore: number) {
    this.messageRatingService.postRating(this.data.messageType, this.data.messageId, newScore).subscribe();
  }

  public get ratingAsText(): string {
    if (this.contentLoaded && this.rating.hasAverageScore) {
      return this.languageService.f3MeWebApiRatingAsText(this.rating.averageScoreAsString, this.rating.numberOfScoresAsString);
    }

    return "";
  }
}
