import { Component, Input } from "@angular/core";
import { ElementText } from "../../../angular-common/utils/element-text";
import { ExogenousInputOptionChoice } from "../targets/exogenous-input/options/exogenous-input-option-choice.model";

@Component({
  selector: "app-exogeous-input-choice-option",
  templateUrl: "./exogeous-input-choice-option.component.html",
  styleUrls: ["./exogeous-input-choice-option.component.scss"],
})
export class ExogeousInputChoiceOptionComponent {
  constructor() {}

  @Input()
  public valueOnly = false;

  @Input() option: ExogenousInputOptionChoice;

  private selectBoxDropDownWidth: string = "auto";
  private isSelectboxDropdownWidthCalculated: boolean = false;

  public get dropDownOptions(): object {
    return {
      minWidth: this.selectBoxDropDownWidth,
    };
  }

  public onContentReady(event: any) {
    if (this.isSelectboxDropdownWidthCalculated === true) {
      return;
    }

    const calculatedWidth = ElementText.calculateElementSizeFrom(
      this.option.values.map((v) => v.text),
      event.element,
      40,
      400,
    );

    this.selectBoxDropDownWidth = `${calculatedWidth}`;

    this.isSelectboxDropdownWidthCalculated = true;
  }
}
