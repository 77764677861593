import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthorizationLanguage } from "../../../../../../common/language/Authorizationlanguage.service";
import { AuthorizationRoutesDto } from "../../../../../../common/models/dto/AuthorizationRoutes-dto";
import { RouteNavigation } from "../../../../../baseservice/route-navigation";
import { Tabs } from "../../../../../tabs/tabs";
import { AuthorizationContentType } from "../../../models/authorization-content.types";
import { AuthorizationContentComponent } from "./authorization-content.component";
import { AuthorizationTabsBaseDirective } from "./authorization-tabs-base.component";

@Component({
  selector: "app-authorization-tabs",
  templateUrl: "./authorization-tabs-base.component.html",
  styleUrls: ["./authorization-tabs-base.component.scss"],
  providers: [],
})
export class AuthorizationTabsComponent extends AuthorizationTabsBaseDirective {
  public constructor(routeNavigation: RouteNavigation, route: ActivatedRoute, private language: AuthorizationLanguage) {
    super(AuthorizationRoutesDto.Authorization, routeNavigation, route);
  }

  readonly componentToPush = AuthorizationContentComponent;

  createTabs(tabstoBuild: Tabs) {
    tabstoBuild.addNew(AuthorizationContentType.Roles, this.language.authorizationRolesTitle, "spinup", AuthorizationContentType.Roles);
    tabstoBuild.addNew(AuthorizationContentType.UserDetails, this.language.authorizationRolesDetailsTitle, "spinup", AuthorizationContentType.UserDetails);
    tabstoBuild.addNew(AuthorizationContentType.RolesMaintenance, this.language.authorizationRolesMaintenanceTitle, "spinup", AuthorizationContentType.RolesMaintenance);
  }
}
