import { CmsGroupModelDto } from "../../models/cms/dto/CmsGroupModelDto-dto";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class CmsModel {
  private languageSelection: PlLanguageSelection;
  public cmsGroupId: number;
  public cmsTitle: LanguageItem;

  public get title(): string {
    return this.languageSelection.currentValueFor(this.cmsTitle);
  }

  public copyFromDto(dto: CmsGroupModelDto, languageSelection: PlLanguageSelection) {
    this.languageSelection = languageSelection;
    this.cmsTitle = dto.Title;
    this.cmsGroupId = dto.CmsGroupId;
  }
}
