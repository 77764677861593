import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { AppComponentSpecificContentType } from "../../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { ILabourConditionData } from "../i-labourcondtion-data";

@Component({
  selector: "app-labour-detail-screen",
  templateUrl: "./labour-detail-screen.component.html",
  styleUrls: ["./labour-detail-screen.component.scss"],
})
export class LabourDetailScreenComponent implements OnInit {
  public dynamicComponentType: AppComponentSpecificContentType;

  constructor(private route: ActivatedRoute) {
    this.dynamicComponentType = AppComponentSpecificContentType.LabourConditionDetail;
  }

  public lcData: ILabourConditionData = { messageId: -1, targetId: -1 };

  public get hasMessage() {
    return this.lcData && this.lcData.messageId > -1;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      // Extract parameters from url, targetid is optional
      this.lcData.messageId = params["id"];
      if ("targetid" in params) {
        this.lcData.targetId = params["targetid"];
      }
    });
  }
}
