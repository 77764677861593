<div>
  <app-cardview [title]="language.loginSignoutTitle">
    <div>
      <p>{{ language.loginSignoutMessage }}</p>
    </div>

    <div class="button-container align-right">
      <dx-button [text]="language.loginSignoutTitle" (onClick)="doSignOut()"></dx-button>
    </div>
  </app-cardview>
</div>
