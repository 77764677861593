import { PreferredModelRoutesDto } from "../../../common/models/dto/PreferredModelRoutes-dto";

import { IMenuItemFilter } from "./IMenuItemFilter";
import { PreferredModelMenuItemFilter } from "./PreferredModelMenuItemFilter";
import { ShowAlwaysMenuItemFilter } from "./ShowAlwaysMenuItemFilter";

export class MenuItemFilterFactory {
  public static createFor(route: string): IMenuItemFilter {
    switch (route) {
      case PreferredModelRoutesDto.PreferredModel:
        return new PreferredModelMenuItemFilter();
      default:
        return new ShowAlwaysMenuItemFilter();
    }
  }
}
