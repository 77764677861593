import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxLookupModule, DxPopupModule, DxSelectBoxModule } from "devextreme-angular";
import { DxoLabelModule } from "devextreme-angular/ui/nested";
import { canActivateAuthGuard } from "../../../../angular-common/authentication-guard/authentication-guard";
import { CardViewModule } from "../../../../angular-common/components/cardview/cardview.module";
import { ListSelectorModule } from "../../../../angular-common/components/listselector/listselector.module";
import { LanguageEditModule } from "../../../cms/languagedit/languageedit.module";
import { EmployeeGridModule } from "../../../components/employee-grid/employee-grid.module";
import { LanguageModule } from "../../../components/language/language.module";
import { LanguageItemEditorModule } from "../../../components/language/languageitem-editor/languageitem-editor.module";
import { MenuItemTypesDto } from "../../../models/cms/dto/MenuItemTypes-dto";
import { PreferedUserEmployeeMappingRoutesDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingRoutes-dto";
import { EmployeesGridComponent } from "./employees-grid/employees-grid.component";
import { MeUserEmployeeMappingComponent } from "./me-user-employee-mapping.component";
import { PreferedUserEmployeeLoginTranslatorComponent } from "./prefered-user-employee-login-translator/prefered-user-employee-login-translator.component";
import { UserEmployeeMappingConfigurationComponent } from "./user-employee-mapping-configuration/user-employee-mapping-configuration.component";
import { UserEmployeeMappingComponent } from "./user-employee-mapping.component";
import { userEmployeeMappingResolver } from "./user-employee-mapping.resolver";
import { UserEmployeeMappingService } from "./user-employee-mapping.service";

const routes: Routes = [
  {
    path: PreferedUserEmployeeMappingRoutesDto.PreferedUserEmployeeMapping,
    component: UserEmployeeMappingComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateAuthGuard],
    resolve: {
      metaData: userEmployeeMappingResolver,
    },
  },
  {
    path: PreferedUserEmployeeMappingRoutesDto.MeUserEmployeeMapping,
    component: MeUserEmployeeMappingComponent,
    data: [{ menuType: MenuItemTypesDto.CMS }],
    canActivate: [canActivateAuthGuard],
    resolve: {
      metaData: userEmployeeMappingResolver,
    },
  },
  {
    path: PreferedUserEmployeeMappingRoutesDto.PreferedUserEmployeeMappingConfigurtion,
    component: UserEmployeeMappingConfigurationComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateAuthGuard],
    resolve: {
      metaData: userEmployeeMappingResolver,
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DxDataGridModule,
    DxLookupModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxoLabelModule,
    DxPopupModule,
    CardViewModule,
    ListSelectorModule,
    EmployeeGridModule,
    LanguageItemEditorModule,
    LanguageEditModule,
    LanguageModule,
  ],
  exports: [],
  declarations: [UserEmployeeMappingComponent, MeUserEmployeeMappingComponent, UserEmployeeMappingConfigurationComponent, EmployeesGridComponent, PreferedUserEmployeeLoginTranslatorComponent],
  providers: [UserEmployeeMappingService],
})
export class UserEmployeeMappingModule {}
