import { IdDescriptionDto } from './IdDescription-dto';
import { IIdShortNameDto } from './IdDescription-dto';
import { MessageDto } from './Message-dto';

// t4tsCode gen": "0.8.2.0 for file ExogenousInputOption.cs
// Don't adjust manually!

export class ExogenousInputOptionDto extends IdDescriptionDto implements IIdShortNameDto {
    public Type: string;
    public ShortName: string;
    public ValidationExpression: string;
    public IsEnabled: boolean;
    public IsVisible: boolean = true;
    public Warning: MessageDto;
    public Error: MessageDto;
    public HelpUrl: string;
}

