import { Component, Input, OnInit } from "@angular/core";

import { CMSMessageList } from "../cms-messagelist.model";
import { CmsLanguage } from "../language/cmslanguage.service";
import { LabourConditionGroup } from "../labourgroups/lc-group";
import { LabourgroupService } from "../labourgroups/labourgroup.service";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";

@Component({
  selector: "app-cmsedit-component",
  templateUrl: "./cms-edit.component.html",
  styleUrls: ["./cms-edit.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class CMSEditComponent implements OnInit {
  public constructor(public language: CmsLanguage, private groupService: LabourgroupService) {}

  public ngOnInit(): void {
    if (this.messageType === MessageTypeDto.labourcondition) {
      this.groupService.getGroupsForEdit().subscribe((g) => {
        this.groups = g;
      });
    } else {
      this.groups = new Array();
    }
  }

  public get canAddNew(): boolean {
    return this.messageList.messages.length === 0 || (this.messageType !== MessageTypeDto.homepagebanner && this.messageType !== MessageTypeDto.ebsintro);
  }

  @Input() messageList: CMSMessageList;
  @Input() messageType: MessageTypeDto;

  public get groups(): LabourConditionGroup[] {
    return this._groups;
  }
  public set groups(newGroups: LabourConditionGroup[]) {
    this._groups = newGroups;

    if (this._groups && this._groups !== null) {
      this._groups.splice(0, 0, this.groupService.getDefaultGroup<number>());
    }
  }
  private _groups: LabourConditionGroup[];
}
