<div class="carousel" [ngClass]="featureToggleBasedStyle">
  <dx-gallery [dataSource]="messageList.messages" [height]="275" width="100%" [slideshowDelay]="interval" [loop]="hasMultiple" [showIndicator]="hasMultiple" [showNavButtons]="hasMultiple">
    <div *dxTemplate="let galleryItem of 'item'">
      <div class="carousel-inner" *ngIf="galleryItem.imageLoaded">
        <img class="imageInCarousel" [src]="galleryItem.imageURL" alt="image" />
        <div class="carousel-caption topbar">
          <p>{{ galleryItem.title }}</p>
        </div>
      </div>
      <div class="carousel-link">
        <app-cms-link [link]="galleryItem.link"> </app-cms-link>
      </div>
    </div>
  </dx-gallery>
</div>
