import { PreferredModelRoutesDto } from "../../../common/models/dto/PreferredModelRoutes-dto";
import { PlUserStorage } from "../../baseservice/pluserstorage";
import { ElementHelper } from "../../helpers/element.helper";
import { IMenuItemFilter } from "./IMenuItemFilter";

export class PreferredModelMenuItemFilter implements IMenuItemFilter {
  public readonly route: string = PreferredModelRoutesDto.PreferredModel;
  public shouldShow(): boolean {
    if (PlUserStorage.hasUser() === false) {
      return false;
    }

    if (ElementHelper.isNullOrUndefined(PlUserStorage.user.AvailableModels) === true) {
      return false;
    }

    return PlUserStorage.user.AvailableModels.length > 1;
  }
}
