import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";

import { Injectable, inject } from "@angular/core";
import { mergeMap } from "rxjs/operators";
import { CommonRoutesDto } from "../../common/models/dto/CommonRoutes-dto";
import { UserInformationValuesDto } from "../../common/models/dto/UserInformationValues-dto";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { ElementHelper } from "../helpers/element.helper";
import { AuthGuard } from "./authentication-guard";

@Injectable()
export class UserInformationGuard extends AuthGuard {
  constructor(public router: Router, environmentSettings: EnvironmentSettings) {
    super(router, environmentSettings);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let result = super.canActivate(route, state);
    result = result.pipe(
      mergeMap((superResult) => {
        if (superResult == true) {
          return this.redirectToUserInfoEdit(PlUserStorage.user);
        }
        return of(false);
      }),
    );
    return result;
  }

  redirectToUserInfoEdit(user: UserInformationValuesDto): Observable<boolean> | Promise<boolean> {
    if (user.DataInSystem) {
      // We can only update user information if the user is actually present.
      if (user.LongName === "" || ElementHelper.isNullOrUndefined(user.LongName)) {
        const routeLocation = CommonRoutesDto.UserInformationEdit;
        return this.router.navigate([routeLocation]);
      }
    }
    return of(true);
  }
}

export const canActivateUserInformationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(UserInformationGuard).canActivate(route, state);
};
