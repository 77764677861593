import { Component, Input } from "@angular/core";

import { Router } from "@angular/router";
import { IChoiceSelectionViewData } from "../../choices/targets/i-choice-selection-view-data";
import { ChoiceDefinition } from "../../choices/targets/targetdefinition/target-definition.model";
import { CmsFeaturesModel } from "../../cms/cms-features-model";
import { CMSMessage } from "../../cms/cms-message.model";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { LinkHandler } from "../../components/links/linkhandler";
import { MessageRatingFeatureModel } from "../../rating/messagerating/message-rating-feature-model";

@Component({
  selector: "app-labour-block",
  templateUrl: "./labour-overview-block.html",
  styleUrls: ["./labour-overview-block.scss"],
})
export class LabourBlockComponent {
  public choiceDynamicComponentType: AppComponentSpecificContentType;
  private ratingFeatures: MessageRatingFeatureModel;

  public constructor(router: Router, features: CmsFeaturesModel) {
    this.linkHandler = new LinkHandler(router);
    this.ratingFeatures = new MessageRatingFeatureModel(features);
    this.choiceDynamicComponentType = AppComponentSpecificContentType.ChoiceSelection;
  }

  public get ratingEnabled(): boolean {
    return this.ratingFeatures.rateNews;
  }

  @Input() index: number;

  public get isOddIndex(): boolean {
    return Math.abs(this.index % 2) === 1;
  }

  @Input()
  public message: CMSMessage;

  public linkHandler: LinkHandler;
  public targets: ChoiceDefinition[];

  public get choiceData(): IChoiceSelectionViewData {
    return {
      messageId: (this.message || {}).id,
    };
  }
}
