<div class="panel-with-header-and-content">
  <h3 class="panel-header">
    <app-edit-toggle [editToggle]="editToggle"></app-edit-toggle>
    {{ title }}
  </h3>

  <div class="panel-content">
    <div class="editor">
      <app-cms-image-edit-edit-component
        *ngIf="imageChanges"
        [showCurrentDimensions]="true"
        [readOnly]="editToggle.editing === false"
        [canDelete]="false"
        [imageChanges]="imageChanges"
        [aspectRatio]="aspectRatio"
      >
      </app-cms-image-edit-edit-component>
    </div>
  </div>
</div>
