import { Component, Input, OnInit } from "@angular/core";

import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { InternalLink } from "../../components/internallinks/internallink";
import { LinkService } from "../../components/internallinks/link.service";
import { Link } from "../../components/links/link";
import { LinkTypeDto } from "../../models/cms/dto/LinkType-dto";
import { CmsLanguage } from "../language/cmslanguage.service";

@Component({
  selector: "app-cms-link-edit-edit-component",
  templateUrl: "./cms-link-edit-edit.component.html",
  styleUrls: ["./cms-link-edit-edit.component.scss"],
  providers: [],
})
export class CmsLinkEditEditComponent implements OnInit {
  // We declare this enum so we can use it inside our html template
  public linkType = LinkTypeDto;
  public showInPopup: boolean = false;
  public linkTypes = [
    {
      text: this.language.f3MeWebApiCMSMessageLinkInternal,
      value: LinkTypeDto.InternalLink,
    },
    {
      text: this.language.f3MeWebApiCMSMessageLinkPopup,
      value: LinkTypeDto.PopupLink,
    },
    {
      text: this.language.f3MeWebApiCMSMessageLinkExternal,
      value: LinkTypeDto.ExternalLink,
    },
  ];

  public constructor(public language: CmsLanguage, private selectLanguage: PlLanguageSelection, private linKService: LinkService) {
    this.addLinkToSelf = false;
  }

  private _selectedType: LinkTypeDto;
  public get selectedType(): number {
    return this._selectedType;
  }
  public set selectedType(v: number) {
    this._selectedType = v;
    this.link.linkType = v;
  }

  @Input() public addLinkToSelf: boolean;
  @Input() link: Link;
  @Input() currentLanguage: string;

  public itemName(item: InternalLink) {
    return this.selectLanguage.currentValueFor(item.text);
  }

  public get internalType() {
    return this.link.linkType === this.linkType.PopupLink || this.link.linkType === this.linkType.InternalLink;
  }

  public get enabledInternal() {
    return this.enabled && this.internalType;
  }

  public get enabledExternal() {
    return this.enabled && !this.internalType;
  }

  public get enabled(): boolean {
    return this.link.visible;
  }
  public set enabled(value: boolean) {
    if (this.link.linkType === LinkTypeDto.None) {
      this.link.linkType = LinkTypeDto.InternalLink;
    }
    this.link.visible = value;
  }

  public ngOnInit(): void {
    this.linKService.getInternalLinks().subscribe((links: InternalLink[]) => {
      this.internalLinks = links;
      this.internalLinksCache = links;
      if (this.addLinkToSelf) {
        const linkToSelf = new InternalLink(this.selectLanguage);
        linkToSelf.text = this.language.getEntry(this.language.key_f3MeWebApiCMSMessageLinkLabourCondition);
        linkToSelf.location = "";
        this.internalLinks.push(linkToSelf);
      }
      if (this.internalType) {
        for (const l of links) {
          if (l.location === this.link.location) {
            this._internalLink = l;
          }
        }
      }

      this.internalLinks = this.filterMessagesOnly(this.internalLinksCache, this.link.linkType);
    });
    if (this.link.linkType === LinkTypeDto.ExternalLink) {
      this._linkLocationExternal = this.link.location;
    }
    this.selectedType = this.link.linkType;
  }

  public internalLinks: InternalLink[] = [];
  private internalLinksCache: InternalLink[] = [];

  private _internalLink: InternalLink;
  public get selectedLink(): InternalLink {
    return this._internalLink;
  }
  public set selectedLink(newLink: InternalLink) {
    this._internalLink = newLink;
    this.setLinkType(this.selectedType ?? LinkTypeDto.InternalLink);
  }

  public setLinkType(newLinkType: LinkTypeDto) {
    this.link.linkType = newLinkType;
    this.saveLinkProperties();
  }

  private saveLinkProperties() {
    if (this.link.linkType === LinkTypeDto.ExternalLink) {
      this.link.location = this.linkLocationExternal;
    } else {
      if (this.selectedLink && this.selectedLink !== null) {
        this.link.location = this.selectedLink.location;

        const linkDesc = this.link.description[this.currentLanguage];
        if (linkDesc === "" || linkDesc === undefined) {
          this.language.transferLanguageItem(this.selectedLink.text, this.link.description);
        }
      }
    }
  }

  public get linkLocationExternal(): string {
    return this._linkLocationExternal;
  }
  public set linkLocationExternal(newLinkLocationExternal: string) {
    this._linkLocationExternal = newLinkLocationExternal;
    this.setLinkType(LinkTypeDto.ExternalLink);
  }

  private _linkLocationExternal: string;

  public onLinkTypeChanged(event: any) {
    this.internalLinks = this.filterMessagesOnly(this.internalLinksCache, event.value);
  }

  private filterMessagesOnly(links: InternalLink[], linkType: LinkTypeDto): InternalLink[] {
    if (linkType !== LinkTypeDto.PopupLink) {
      return links;
    }

    if (ElementHelper.isNullOrUndefined(links)) {
      return [] as InternalLink[];
    }

    return links.filter((l) => l.isNavigationRoute === false);
  }
}
