import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { CMSModule } from "../../cms/cms.module";
import { CmsPageHeaderModule } from "../../cms/cmspageheaders/cmspage-header.module";
import { LabourGroupModule } from "../../cms/labourgroups/labourgroup.module";
import { CmsLinkModule } from "../../cms/links/cms-link.module";
import { CmsRoutesDto } from "../../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { MessageRatingModule } from "../../rating/messagerating/message-rating.module";
import { LabourConditionsOverviewModule } from "../labour-overview/labourconditions-overview.module";
import { LabourConditionComponent } from "./labour.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.LabourConditions,
    component: LabourConditionComponent,
    data: [{ menuType: MenuItemTypesDto.MainMenu }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [LabourConditionComponent],
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, LabourGroupModule, CmsPageHeaderModule, CMSModule, CmsLinkModule, MessageRatingModule, LabourConditionsOverviewModule],
  providers: [],
  exports: [LabourConditionComponent],
})
export class LabourConditionMainModule {}
