import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { ExogenousInputComputedField } from "../exogenous-input/exogenous-input-computedfield";
import { ExogenousInputOption } from "../exogenous-input/options/exogenous-input-option.model";
import { IGetOptionCurrentValue } from "./get-option-current-value.model";

export class OptionWithCurrentValue {
  public constructor(public languageService: CmsLanguage, public option: ExogenousInputOption, private getOptionCurrentValueImpl: IGetOptionCurrentValue) {}

  public get currentValueString(): string {
    if (this.currentValue && this.currentValue.value) {
      return "(" + this.languageService.f3MeWebApiTargetCurrentEmbedded + " " + this.currentValue.value + ")";
    }

    return null;
  }

  public get currentValue(): ExogenousInputComputedField {
    return this.getOptionCurrentValueImpl.getOptionCurrentValue(this.option.shortName);
  }
}
