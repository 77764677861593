import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router, RoutesRecognized } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { RouteData } from "./models/route-data.model";

@Injectable()
export class RouteDataProvider {
  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe((event: RoutesRecognized) => {
      this._newData = new RouteData(event.state.root.children[0].routeConfig.path);
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.currentData = this._newData;
      this._dataChanged.next(this._newData);
    });

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      this._newData = undefined;
    });
  }

  private _newData: RouteData;

  private _dataChanged = new BehaviorSubject<RouteData>(undefined);
  public dataChanged = this._dataChanged.asObservable();

  public currentData: RouteData = undefined;
}
