import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { map, tap } from "rxjs/operators";
import { PlAuthenticationService } from "../../../../angular-common/authentication/authentication.service";
import { EnvironmentSettings } from "../../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../../angular-common/baseservice/plbase.service";
import { PlUserStorage } from "../../../../angular-common/baseservice/pluserstorage";
import { PlLoadIndicator } from "../../../../angular-common/loadindicator/loadindicator";
import { ModelElementSpecification } from "../../../../common/modelelements/modelelementsspecification";
import { UserDto } from "../../../../common/models/dto/UserDto-dto";
import { EmployeeDataForGrid } from "../../../components/employee-grid/employee-data-for-grid.model";
import { EmployeeDataForGridDto } from "../../../models/cms/dto/EmployeeDataForGrid-dto";
import { EmployeeIdentificationReferenceDto } from "../../../models/cms/dto/EmployeeIdentificationReferenceDto-dto";
import { PreferedUserEmployeeMappingConfigurationDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingConfigurationDto-dto";
import { PreferedUserEmployeeMappingDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingDto-dto";
import { PreferedUserEmployeeMappingMetaDataDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingMetaData-dto";
import { PreferedUserEmployeeMappingRoutesDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingRoutes-dto";
import { UserToEmployeeMappingDto } from "../../../models/cms/dto/UserToEmployeeMapping-dto";
import { PreferedUserEmployeeMappingConfigurationGrouping } from "./prefered-user-employee-mapping-configuration-grouping";

@Injectable()
export class UserEmployeeMappingService extends PlBaseService {
  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private authenticationService: PlAuthenticationService) {
    super(http, envSettings, loadIndicator);
  }
  private endpoint: string;
  private readonly endpointPreferedEmployeeConfiguration: string = "PreferedEmployeeConfiguration";
  private readonly endpointMeEmployeeConfiguration: string = "MeEmployeeConfiguration";

  configureEndpoint(routePath: string) {
    switch (routePath) {
      case PreferedUserEmployeeMappingRoutesDto.PreferedUserEmployeeMapping:
        this.endpoint = this.endpointPreferedEmployeeConfiguration;
        break;
      case PreferedUserEmployeeMappingRoutesDto.MeUserEmployeeMapping:
        this.endpoint = this.endpointMeEmployeeConfiguration;
        break;
      default:
        this.endpoint = this.endpointMeEmployeeConfiguration;
        break;
    }
  }

  public static newPreferedUserEmployeeMappingDto() {
    var dto = new PreferedUserEmployeeMappingDto();
    dto.KeyId = -1;
    dto.UserToEmployeeMapping = UserEmployeeMappingService.newUserToEmployeeMappingDto();
    return dto;
  }

  public static newUserToEmployeeMappingDto() {
    var dto = new UserToEmployeeMappingDto();

    dto.Employee = new EmployeeIdentificationReferenceDto();
    dto.Employee.EmployeeIdentificationString = "";
    dto.User = new UserDto();

    return dto;
  }

  retrieveUserData(): Observable<PreferedUserEmployeeMappingMetaDataDto> {
    return this.getData(this.endpoint);
  }

  public retrieveEmployeeData(): Observable<EmployeeDataForGrid> {
    return this.getDataTransformed<EmployeeDataForGridDto>(this.endpoint + "/employee").pipe(map((r) => this.extractEmployeeData(r)));
  }

  private extractEmployeeData(dto: EmployeeDataForGridDto): EmployeeDataForGrid {
    if (dto) {
      const result = new EmployeeDataForGrid();
      result.copyFromDTO(dto);
      return result;
    } else {
      return null;
    }
  }

  retrieveConfiguration(): Observable<PreferedUserEmployeeMappingConfigurationGrouping> {
    return this.getDataTransformed<PreferedUserEmployeeMappingConfigurationDto>(this.endpointPreferedEmployeeConfiguration + "/config").pipe(map((r) => this.extractAllData(r)));
  }

  private extractAllData(dto: PreferedUserEmployeeMappingConfigurationDto): PreferedUserEmployeeMappingConfigurationGrouping {
    if (dto) {
      const result = new PreferedUserEmployeeMappingConfigurationGrouping();
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public saveConfiguration(currentConfiguration: ModelElementSpecification[]): Observable<any> {
    const preferedUserEmployeeMappingConfigurationGrouping = new PreferedUserEmployeeMappingConfigurationGrouping();
    const url = this.endpointPreferedEmployeeConfiguration + "/config";
    return this.update(preferedUserEmployeeMappingConfigurationGrouping.toDto(currentConfiguration), url);
  }

  addNewMapping(dto: PreferedUserEmployeeMappingDto): Observable<PreferedUserEmployeeMappingDto> {
    const data: Observable<PreferedUserEmployeeMappingDto> = this.postData(this.endpoint, dto);

    return data.pipe(
      tap(() => {
        this.postMappingActions(dto);
      }),
    );
  }

  updateMapping(dto: PreferedUserEmployeeMappingDto): Observable<PreferedUserEmployeeMappingDto> {
    const data: Observable<PreferedUserEmployeeMappingDto> = this.update(dto, this.endpoint);

    return data.pipe(
      tap(() => {
        this.postMappingActions(dto);
      }),
    );
  }

  deleteMapping(dto: PreferedUserEmployeeMappingDto): Observable<void> {
    const url = `${this.endpoint}/${dto.KeyId}`;
    const data: Observable<void> = this.remove(url);

    return data.pipe(
      tap(() => {
        this.postMappingActions(dto);
      }),
    );
  }

  private postMappingActions(mappingData: PreferedUserEmployeeMappingDto) {
    if (PlUserStorage.isCurrentUserNameEqualTo(mappingData.UserToEmployeeMapping.User.ShortName)) {
      this.authenticationService.refreshUserData().subscribe();
    }
    this._mappingChanged.next(true);
  }

  private _mappingChanged = new BehaviorSubject<boolean>(false);
  public mappingChanged = this._mappingChanged.asObservable();
}
