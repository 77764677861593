import { Component, Input } from "@angular/core";

import { BenefitGroup } from "../_models/benefit-group.model";

@Component({
  selector: "app-ebs-benefitgroups",
  templateUrl: "./ebs-benefit-groups.component.html",
  styleUrls: ["./../ebs-overview/ebs-benefits.scss"],
})
export class BenefitGroupsComponent {
  @Input() public groups: BenefitGroup[];
}
