import { NgModule } from "@angular/core";
import { AuthenticationClaimsService } from "./authentication-claims.service";
import { PlAuthenticationService } from "./authentication.service";
import { UserInformationService } from "./user-information.service";

@NgModule({
  declarations: [],
  providers: [PlAuthenticationService, AuthenticationClaimsService, UserInformationService],
  schemas: [],
})
export class AuthenticationModule { }
