import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { Sorter } from "../../angular-common";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { CMSService } from "../cms/cms.service";
import { CHART_CONFIG } from "./_models/ebs-chart-configuration.model";
import { EbsChartItem } from "./_models/ebs-chart-item.model";
import { EbsResult } from "./_models/ebs-result.model";
import { EbsDoughnutCreator } from "./ebs-doughnut-creator";
import { EbsFeaturesModel } from "./ebs-features-model";
import { EbsOverviewData } from "./ebs-overview/ebs-overview-data";
import { EBSService } from "./ebs-services";
import { EbsLanguage } from "./language/ebslanguage.service";

@Component({
  selector: "app-ebs-component",
  templateUrl: "./ebs.component.html",
  styleUrls: ["./ebs.component.scss"],
})
export class EbsComponent implements OnInit {
  private _ebsId: string = "";

  public ebs: EbsResult;
  public groupsForChart: EbsChartItem[];

  public series: any = CHART_CONFIG.series;
  public legend: any = CHART_CONFIG.legend;
  public size: any = CHART_CONFIG.size;
  public tooltip: any = CHART_CONFIG.tooltip;
  public palette: string[];

  // eslint-disable-next-line max-len
  public constructor(
    private route: ActivatedRoute,
    private ebsService: EBSService,
    private cmsService: CMSService,
    private languageService: EbsLanguage,
    private selectLanguage: PlLanguageSelection,
    public features: EbsFeaturesModel,
  ) {}

  public get ebsDisclaimer() {
    return this.languageService.f3MeWebApiDisclaimerMessageEbs;
  }

  public ebsOverviewDto: EbsOverviewData;

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this._ebsId = params["id"];

      const obs = this.ebsService
        .getEbsById(this._ebsId)
        .pipe(
          map((ebs) => {
            this.ebsOverviewDto = EbsDoughnutCreator.createOverviewDto(ebs, this.selectLanguage, this.cmsService);
            Sorter.sortSortOrderThenAlpha(this.ebsOverviewDto.ebsResult.groups, (r) => r.title);
          }),
        )
        .subscribe();
    });
  }
  public printEbs(): void {
    window.print();
  }
}
