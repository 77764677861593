import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { RatingIconStyle } from "./rating-icon-style";

@Component({
  selector: "app-rating-icon",
  templateUrl: "./rating-icon.component.html",
  styleUrls: ["./rating-icon.component.scss"],
})
export class RatingIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @Input()
  public iconStyle: RatingIconStyle = RatingIconStyle.Empty;
  public RatingIconStyle = RatingIconStyle;

  public get iconClass(): string {
    if (this.iconStyle === RatingIconStyle.Empty) {
      return "far fa-star";
    } else if (this.iconStyle === RatingIconStyle.Half) {
      return "fas fa-star-half-alt";
    } else {
      return "fas fa-star";
    }
  }

  @Output()
  public isMouseOver = new EventEmitter<boolean>();

  public mouseOver(isOver: boolean) {
    this.isMouseOver.emit(isOver);
  }

  @Output()
  public onClick = new EventEmitter();

  public click() {
    this.onClick.emit();
  }
}
