<app-cms-component *ngIf="homgePageBannerEnabled" [messageType]="messageTypes.homepagebanner" [allowEdit]="false"> </app-cms-component>

<app-capital (isEbsSummaryAvailable)="onEbsSummaryRetreived($event)" *ngIf="ebsEnabled"></app-capital>

<div *ngIf="widerWidthNewsBeforeCarousel == false; else ShowWiderWidthNewsBeforeCarousel">
  <app-cms-component
    *ngIf="courselEnabled"
    (isComponentVisible)="updateCarouselVisibility($event)"
    [messageType]="messageTypes.carousel"
    [hideWhenEmpty]="true"
    [allowEdit]="false"
    [useFullWidth]="!newsEnabled && isEbsSummaryEmpty"
  >
  </app-cms-component>

  <app-cms-component
    *ngIf="newsEnabled"
    [messageType]="messageTypes.news"
    [allowEdit]="false"
    [useFullWidth]="(!isEbsSummaryEmpty && courselEnabled) || (isEbsSummaryEmpty && (!courselEnabled || !isCarouselVisible))"
  ></app-cms-component>
</div>

<app-what-if></app-what-if>

<ng-template #ShowWiderWidthNewsBeforeCarousel>
  <app-cms-component *ngIf="newsEnabled" [messageType]="messageTypes.news" [allowEdit]="false" [useFullWidth]="!courselEnabled && isEbsSummaryEmpty"></app-cms-component>

  <app-cms-component
    *ngIf="courselEnabled"
    [messageType]="messageTypes.carousel"
    [hideWhenEmpty]="true"
    [allowEdit]="false"
    [useFullWidth]="(!isEbsSummaryEmpty && newsEnabled) || (isEbsSummaryEmpty && !newsEnabled)"
  >
  </app-cms-component>
</ng-template>
