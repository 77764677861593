import { Injectable } from "@angular/core";
import { RoleGuard } from "../../authentication-guard/role-guard";
import { MenuItem } from "../models/menuitem";
import { MenuItemFilterFactory } from "./MenuItemFilterFactory";

@Injectable()
export class MenuFilterService {
  public shouldShow(menuItem: MenuItem): boolean {
    if (menuItem.items.length > 0) {
      return this.shouldShowForSubitems(menuItem.items);
    }

    return this.shouldShowItem(menuItem);
  }

  private shouldShowForSubitems(menuItems: MenuItem[]): boolean {
    if (this.subItemsAvailableForUser(menuItems) === false) {
      return false;
    }

    const result: MenuItem[] = [];
    menuItems.forEach((subItem) => {
      const menuItemFilter = MenuItemFilterFactory.createFor(subItem.route);
      if (menuItemFilter.shouldShow() === true) {
        result.push(subItem);
      }
    });

    return result.length > 0;
  }

  private shouldShowItem(menuItem: MenuItem): boolean {
    if (this.itemAvailableForUser(menuItem) === false) {
      return false;
    }

    return MenuItemFilterFactory.createFor(menuItem.route).shouldShow();
  }

  private itemAvailableForUser(menuItem: MenuItem): boolean {
    return RoleGuard.currentUserCanActivateMenuItem(menuItem);
  }

  private subItemsAvailableForUser(menuItems: MenuItem[]): boolean {
    return RoleGuard.filterForActiveUser(menuItems).length > 0;
  }
}
