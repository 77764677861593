import { AfterViewInit, Component, Input } from "@angular/core";
import { WebLanguage } from "../../../../common/language/weblanguage.service";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { LoginStorageSettings } from "../login-storage-settings";

@Component({
  selector: "app-external-auth-login",
  templateUrl: "./login-external-auth.component.html",
  styleUrls: ["../_login.scss"],
})
export class LoginExternalAuthComponent implements AfterViewInit {
  @Input() loading: boolean;
  @Input() error: string;

  private _loginAction: () => void;

  @Input()
  public set login(act: () => void) {
    this._loginAction = act;
  }
  public get login() {
    LoginStorageSettings.markExternalLoginRoundTripRunning();
    return this._loginAction;
  }

  constructor(public language: WebLanguage, private envSettings: EnvironmentSettings) { }

  ngAfterViewInit(): void {
    if (this.envSettings.autoRedirectOnExternalLogin()) {
      if (LoginStorageSettings.externalLoginAutoRedirectEnabled) {
        this.login();
      } else {
        console.log("External auto login blocked; don't auto login but wait for user input");
      }
    } else {
      console.log("No auto redirect on external login. Wait for user navigation.");
    }
  }
}
