<div *ngIf="message" class="top lcDetail">
  <div class="header col-md-4 col-sm-12 col-xs-12">
    <div>
      <h1>{{ message.title }}</h1>
      <p class="target-sub-header" *ngIf="targetProperties">{{ targetProperties.definition.title }}</p>
      <div class="img-block" *ngIf="message.imageLoaded">
        <img [src]="message.imageURL" />
      </div>
      <app-exogenous-input-order-progress-component [targetProperties]="targetProperties"></app-exogenous-input-order-progress-component>
    </div>
  </div>

  <div class="col-sm-12 col-xs-12 col-lg-8" *ngIf="showMessageContent">
    <app-labour-detail-message-component [message]="message" [targetDefinitions]="targets"></app-labour-detail-message-component>

    <div>
      <app-choice-selection-component [data]="choiceData"></app-choice-selection-component>
    </div>
  </div>

  <div class="col-sm-12 col-xs-12 col-lg-8" *ngIf="showTargetDetails">
    <div *ngIf="targetProperties">
      <app-exogenous-input-detail-component [targetProperties]="targetProperties"></app-exogenous-input-detail-component>
    </div>
  </div>
</div>
