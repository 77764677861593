import { ModelElement } from "../../model/models/model-element.model";
import { ExogenousVariableDto } from "../../models/dto/ExogenousVariableDto-dto";

export class ExogenousVariable extends ModelElement {
  public copyFromDto(exogenousVariableDto: ExogenousVariableDto) {
    super.copyFromDto(exogenousVariableDto);
    this.visibleTriggerFormulaText = exogenousVariableDto.VisibleTriggerFormulaText;
    this.errorTriggerFormulaText = exogenousVariableDto.ErrorTriggerFormulaText;
    this.warningTriggerFormulaText = exogenousVariableDto.WarningTriggerFormulaText;
    this.readOnlyTriggerFormulaText = exogenousVariableDto.ReadOnlyTriggerFormulaText;
    this.sortOrder = exogenousVariableDto.SortOrder;
    this.categoryId = exogenousVariableDto.CategoryId;
    this.dataSetId = exogenousVariableDto.DataSetId;
  }

  public visibleTriggerFormulaText: string;
  public warningTriggerFormulaText: string;
  public errorTriggerFormulaText: string;
  public readOnlyTriggerFormulaText: string;
  public sortOrder: number;
  public categoryId: number;

  public copyPropertiesToDto(dto: ExogenousVariableDto) {
    super.copyPropertiesToDto(dto);
    dto.VisibleTriggerFormulaText = this.visibleTriggerFormulaText;
    dto.WarningTriggerFormulaText = this.warningTriggerFormulaText;
    dto.ErrorTriggerFormulaText = this.errorTriggerFormulaText;
    dto.ReadOnlyTriggerFormulaText = this.readOnlyTriggerFormulaText;
    dto.CategoryId = this.categoryId;
    dto.SortOrder = this.sortOrder;
    dto.DataSetId = this.dataSetId;
  }

  public copyToDto(): ExogenousVariableDto {
    const result = new ExogenousVariableDto();
    this.copyPropertiesToDto(result);
    return result;
  }
}
