import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../angular-common";

import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { ImageService } from "../components/images/image.service";
import { Link } from "../components/links/link";
import { CmsMessageDto } from "../models/cms/dto/CmsMessage-dto";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";
import { CMSMessage } from "./cms-message.model";
import { CmsLanguage } from "./language/cmslanguage.service";

@Injectable()
export class CMSService extends PlBaseService {
  private endPoint: string = "cms";

  constructor(
    http: HttpClient,
    envSettings: EnvironmentSettings,
    private selectLanguage: PlLanguageSelection,
    private languageService: CmsLanguage,
    loadIndicator: PlLoadIndicator,
    private domSanitizer: DomSanitizer,
    private imageService: ImageService,
  ) {
    super(http, envSettings, loadIndicator);
  }

  private createEndPoint(messageType: MessageTypeDto): string {
    return this.envSettings.combinePath(this.endPoint, messageType.toString());
  }

  private dtoToMessage(dto: CmsMessageDto) {
    if (dto === undefined) {
      return null;
    }

    const link = new Link(this.selectLanguage);
    link.copyFromDTO(dto.Link);

    const message = new CMSMessage(
      this.languageService,
      this.selectLanguage,
      this.domSanitizer,
      dto.Id,
      dto.MessageType,
      dto.Location,
      dto.VisibilityExpression,
      dto.Date,
      dto.ImageURL,
      dto.Title,
      dto.Summary,
      dto.Content,
      link,
      dto.EmailTemplateId,
      dto.GroupId,
      dto.Options,
    );

    this.transofrmImageUrlForInternalAuthentication(message);

    return message;
  }

  public transofrmImageUrlForInternalAuthentication(message: CMSMessage) {
    if (message.hasImage === false) {
      message.imageUrlProtected = undefined;
      message.imageLoaded = false;
      return;
    }

    this.imageService
      .fetchImage(message.originalImageUrl)
      .pipe(catchError(this.handleImageProblem))
      .subscribe((data) => {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            message.imageUrlProtected = reader.result as string;
            message.imageLoaded = true;
          },
          false,
        );
        if (data) {
          reader.readAsDataURL(data);
        }
      });
  }
  handleImageProblem(error: HttpErrorResponse) {
    console.log("Error on retrieval of image: ", error);
    return fetch(ImageService.createAssetsUrl("notfound.jpg")).then((r) => r.blob());
  }

  private extractSingleMessage(entry: EntryCreated<CmsMessageDto>): CMSMessage {
    if (entry) {
      return this.dtoToMessage(entry.data);
    } else {
      return null;
    }
  }

  private extractMultipleMessages(dto: CmsMessageDto[]): CMSMessage[] {
    if (dto) {
      const list = new Array<CMSMessage>();
      for (const d of dto) {
        const msg = this.dtoToMessage(d);
        list.push(msg);
      }

      return list;
    } else {
      return [];
    }
  }

  public saveNewMessage(message: CMSMessage): Observable<CMSMessage> {
    const dtoToSend = message.toDTO();
    return this.createDataTransformed<CmsMessageDto>(this.createEndPoint(message.messageType), dtoToSend).pipe(map((r) => this.extractSingleMessage(r)));
  }

  public saveExistingMessage(message: CMSMessage): Observable<any> {
    const dtoToSend = message.toDTO();
    return this.update(dtoToSend, message.location);
  }

  public getMessage(messageType: MessageTypeDto, id: number): Observable<CMSMessage> {
    return this.getDataTransformed<CmsMessageDto>(this.createEndPoint(messageType) + "/" + id).pipe(map((r) => this.dtoToMessage(r)));
  }

  public getMessagesForEditing(messageType: MessageTypeDto) {
    const location = this.envSettings.combinePath(this.envSettings.combinePath(this.endPoint, "edit"), messageType.toString());
    return this.getDataTransformed<CmsMessageDto[]>(location).pipe(map((r) => this.extractMultipleMessages(r)));
  }

  public getMessages(messageType: MessageTypeDto): Observable<CMSMessage[]> {
    return this.getDataTransformed<CmsMessageDto[]>(this.createEndPoint(messageType)).pipe(map((r) => this.extractMultipleMessages(r)));
  }

  public deleteMessage(message: CMSMessage): Observable<void> {
    return this.remove(message.location);
  }
}
