import { ModelElementOptionDto } from "../models/dto/ModelElementOptionDto-dto";

export class ModelElementSpecificationOption {
  public static toDto(value: ModelElementSpecificationOption): ModelElementOptionDto {
    const dto = new ModelElementOptionDto();
    dto.Id = value.id;
    dto.Text = value.text;

    return dto;
  }
  public constructor() {}

  public id: number;
  public text: string;

  public copyFromDto(dto: ModelElementOptionDto) {
    this.id = dto.Id;
    this.text = dto.Text;
  }
}
