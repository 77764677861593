import { AfterContentInit, Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
import { AuthorizationContentType } from "../../../models/authorization-content.types";
import { IAuthorizationContent } from "./IAuthorizationContent";

@Component({
  selector: "app-auth-contentbase",
  template: `
    <div>
      <div #entry></div>
    </div>
  `,
})
export class AuthBaseContentComponent implements AfterContentInit {
  constructor() {}

  @Input() authorizationContentType: AuthorizationContentType;
  @Input() component: any;

  ngAfterContentInit(): void {
    const component = this.entry.createComponent<IAuthorizationContent>(this.component);
    component.instance.authorizationContentType = this.authorizationContentType;
  }
  @ViewChild("entry", { read: ViewContainerRef, static: true }) entry: ViewContainerRef;

  ngOnInit() {}
}
