<div *ngIf="languageSelector && numberOfLanguages > 1" class="flags-container">
  <ul class="list">
    <li *ngFor="let language of languageSelector.languages">
      <div *ngIf="languageSelector.currentLanguage === language.Id">
        <dx-button [icon]="language.ImageUrl" (onClick)="toggleIsDroppedDown()"> </dx-button>
      </div>
    </li>
  </ul>

  <span class="popup" [class.popup-shown]="getIsDroppedDown()" [class.popup-hidden]="!getIsDroppedDown()"
    *ngIf="languageSelector">
    <ul class="list">
      <li class="popup-flag" *ngFor="let language of languageSelector.languages">
        <dx-button [icon]="language.ImageUrl" [hint]="languageService.getString(language.DisplayName)"
          (onClick)="languageSelector.currentLanguage = language.Id"> </dx-button>
      </li>
    </ul>
  </span>
</div>