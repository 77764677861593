import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { LanguageEditItemDTO } from "../../../angular-common/language/language-dto.model";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { LanguageEditItemDto } from "../../models/cms/dto/LanguageEditItem-dto";
import { LanguageEditItemRowData } from "./models/language-edit-item-rowdata.model";

@Injectable({ providedIn: null })
export class LanguageEditManagementService extends PlBaseService {
  private endPoint = "languageeditmanagement";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getEditItems(): Observable<LanguageEditItemDTO[]> {
    const thisService = this;
    return this.getData<LanguageEditItemDTO[]>(this.endPoint).pipe(map(this.extract, this));
  }

  private extract(items: LanguageEditItemDTO[]): LanguageEditItemDTO[] {
    if (items) {
      return items;
    } else {
      return [];
    }
  }

  public delete(rowData: LanguageEditItemRowData): Observable<any> {
    const url = this.endPoint + "/" + encodeURI(rowData.id) + "/";
    return this.remove(url);
  }

  public insert(rowData: LanguageEditItemRowData): Observable<any> {
    const url = this.endPoint;
    return this.createData<LanguageEditItemDto>(url, rowData.toDto());
  }

  public save(id: string, rowData: LanguageEditItemRowData): Observable<any> {
    const url = this.endPoint + "/" + encodeURI(id) + "/";
    return this.update(rowData.toDto(), url);
  }
}
