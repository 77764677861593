import { Injectable } from "@angular/core";
import { LanguageConstants } from "./language-constants";
import { LanguageItem } from "./language-dto.model";
import { LanguageMetaDataDto } from "../../common/models/dto/LanguageMetaData-dto";
import { LanguageMetaDatasDto } from "../../common/models/dto/LanguageMetaDatas-dto";
import { PlLanguageService } from "./pllanguage.service";

@Injectable()
export class PlLanguageSelection {
  constructor(private plLanguageService: PlLanguageService) {}
  public get languages(): LanguageMetaDatasDto {
    return this.plLanguageService.languages();
  }

  public get languagesMetaData(): LanguageMetaDataDto[] {
    return this.languages.Languages;
  }

  public get currentLanguage() {
    if (this.plLanguageService.languageSelector) {
      return this.plLanguageService.languageSelector.currentLanguage;
    }
    return LanguageConstants.DEFAULT_LANGUAGE;
  }
  public set currentLanguage(value: string) {
    this.plLanguageService.languageSelector.currentLanguage = value;
  }

  public get hasCurrentLanguage() {
    if (this.plLanguageService.languageSelector) {
      const curLang = this.plLanguageService.languageSelector.currentLanguage;
      if (curLang && curLang.length > 0) {
        return true;
      }
    }
    return false;
  }

  public currentValueFor(item: LanguageItem) {
    if (item === null || item === undefined) {
      return "";
    }

    const textInCurrentLanguage = this.currentValueForLanguageInternal(item, this.currentLanguage);
    if (textInCurrentLanguage) {
      return textInCurrentLanguage;
    }
    if (this.currentLanguage !== this.languages.Language) {
      const textInDefaultLanguage = this.currentValueForLanguageInternal(item, this.languages.Language);
      if (textInDefaultLanguage) {
        return textInDefaultLanguage;
      }
    }

    const firstNonEmpty = this.firstNonEmpty(item);
    if (firstNonEmpty) {
      return firstNonEmpty;
    }
    return "";
  }

  private firstNonEmpty(item: LanguageItem) {
    if (item === null || item === undefined) {
      return undefined;
    }
    for (const languageKey of Object.keys(item)) {
      const valueOfkey = item[languageKey];
      if (this.hasValue(valueOfkey)) {
        return valueOfkey;
      }
    }

    return undefined;
  }

  private currentValueForLanguageInternal(item: LanguageItem, language: string) {
    if (item === null || item === undefined) {
      return undefined;
    }
    const textInLanguage = item[language];
    if (this.hasValue(textInLanguage)) {
      return textInLanguage;
    }
  }

  private hasValue(value: string) {
    if (value) {
      return value != null && value.length > 0;
    }
    return false;
  }

  public currentValueForLanguage(item: LanguageItem, language: string) {
    const result = this.currentValueForLanguageInternal(item, language);
    if (this.hasValue(result)) {
      return result;
    } else {
      return "";
    }
  }

  public currentLanguageMetadata(): LanguageMetaDataDto {
    const findCurrentLanguageMeta = this.languages.Languages.find((l) => l.Id === this.currentLanguage);
    if (findCurrentLanguageMeta) {
      return findCurrentLanguageMeta;
    } else {
      return PlLanguageService.fallbackDefaultLanguageMetadata();
    }
  }
}
