
// t4tsCode gen": "0.8.2.0 for file EbsRoutes.cs
// Don't adjust manually!

export class EbsRoutesDto {
    public static EbsEdit: string = "ebsedit";
    public static EbsTreeEdit: string = "ebstreeedit";
    public static Ebs: string = "ebs";
    public static EbsOverview: string = "ebsoverview";
}

