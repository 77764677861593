<div *ngIf="contentReady">
  <div class="buttonBar">
    <dx-button icon="fas fa-plus" class="svg" [hint]="language.ButtonNew" [text]="language.ButtonNew" (onClick)="newLcLink()"></dx-button>
  </div>

  <dx-data-grid [dataSource]="lcLinks" [columnAutoWidth]="true" (onRowUpdated)="rowUpdated($event)" (onRowRemoved)="rowDeleted($event)">
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"> </dxo-editing>
    <dxi-column dataField="cmsMessageId" [caption]="language.f3MeWebApiLcLinkLabourCondition">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="labourConditions" displayExpr="title" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column [visible]="hasTargets" dataField="targetId" [caption]="language.f3MeWebApiTargetCaption">
      <dxo-lookup [dataSource]="targets" displayExpr="shortName" valueExpr="keyId"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="emailTemplateId" [caption]="language.f3MeWebApiLcLinkRequestMail">
      <dxo-lookup [dataSource]="emailTemplates" displayExpr="title" valueExpr="id" [allowClearing]="true" showClearButton="true"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="confirmationEmailId" [caption]="language.f3MeWebApiLcLinkConfirmEmail">
      <dxo-lookup [dataSource]="emailTemplates" displayExpr="title" valueExpr="id"></dxo-lookup>
    </dxi-column>
  </dx-data-grid>
</div>
