import { Injectable } from "@angular/core";
import { LanguageServiceBase } from "../../components/language/language-base.service";
import { PersonlizedLanguageService } from "../../components/language/personlized-language.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../angular-common";

@Injectable()
//t4Languages": "0.8.2.0
export class RatingLanguage extends LanguageServiceBase {
  public constructor(plLanguageService: PlLanguageService, languageSelection: PlLanguageSelection, personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService, languageSelection, personlizedLanguageService);
  }

  /**
   * f3MeWebApiRatingTooltipAverageWithUser from Rating.csv (in EN: Average rating {0} (based on {1} ratings). Your rating is {2}. )
   */
  public key_f3MeWebApiRatingTooltipAverageWithUser = "f3MeWebApiRatingTooltipAverageWithUser";
  /**
   * f3MeWebApiRatingTooltipAverageWithUser from Rating.csv (in EN: Average rating {0} (based on {1} ratings). Your rating is {2}. )
   */
  public f3MeWebApiRatingTooltipAverageWithUser(val1, val2, val3): string { return this.getStringDef("f3MeWebApiRatingTooltipAverageWithUser", "Average rating {0} (based on {1} ratings). Your rating is {2}.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * f3MeWebApiRatingTooltipAverage from Rating.csv (in EN: Average rating {0} (based on {1} ratings). )
   */
  public key_f3MeWebApiRatingTooltipAverage = "f3MeWebApiRatingTooltipAverage";
  /**
   * f3MeWebApiRatingTooltipAverage from Rating.csv (in EN: Average rating {0} (based on {1} ratings). )
   */
  public f3MeWebApiRatingTooltipAverage(val1, val2): string { return this.getStringDef("f3MeWebApiRatingTooltipAverage", "Average rating {0} (based on {1} ratings).").replace("{1}", val2).replace("{0}", val1); }

  /**
   * f3MeWebApiRatingClickToRate from Rating.csv (in EN: Click to add or change your rating. )
   */
  public key_f3MeWebApiRatingClickToRate = "f3MeWebApiRatingClickToRate";
  /**
   * f3MeWebApiRatingClickToRate from Rating.csv (in EN: Click to add or change your rating. )
   */
  public get f3MeWebApiRatingClickToRate(): string { return this.getStringDef(this.key_f3MeWebApiRatingClickToRate, "Click to add or change your rating."); }

  /**
   * f3MeWebApiRatingAsText from Rating.csv (in EN: ({0})/({1}) )
   */
  public key_f3MeWebApiRatingAsText = "f3MeWebApiRatingAsText";
  /**
   * f3MeWebApiRatingAsText from Rating.csv (in EN: ({0})/({1}) )
   */
  public f3MeWebApiRatingAsText(val1, val2): string { return this.getStringDef("f3MeWebApiRatingAsText", "({0})/({1})").replace("{1}", val2).replace("{0}", val1); }

  /**
   * f3MeWebApiAxisNumberOfRatings from Rating.csv (in EN: # rating )
   */
  public key_f3MeWebApiAxisNumberOfRatings = "f3MeWebApiAxisNumberOfRatings";
  /**
   * f3MeWebApiAxisNumberOfRatings from Rating.csv (in EN: # rating )
   */
  public get f3MeWebApiAxisNumberOfRatings(): string { return this.getStringDef(this.key_f3MeWebApiAxisNumberOfRatings, "# rating"); }

  /**
   * f3MeWebApiAxisRating from Rating.csv (in EN: avg. rating )
   */
  public key_f3MeWebApiAxisRating = "f3MeWebApiAxisRating";
  /**
   * f3MeWebApiAxisRating from Rating.csv (in EN: avg. rating )
   */
  public get f3MeWebApiAxisRating(): string { return this.getStringDef(this.key_f3MeWebApiAxisRating, "avg. rating"); }

  /**
   * ratingConfirmRating from Rating.csv (in EN: Thanks for your vote! )
   */
  public key_ratingConfirmRating = "ratingConfirmRating";
  /**
   * ratingConfirmRating from Rating.csv (in EN: Thanks for your vote! )
   */
  public get ratingConfirmRating(): string { return this.getStringDef(this.key_ratingConfirmRating, "Thanks for your vote!"); }


}
