<div class="plugin-management-container">
  <dx-button icon="add" [text]="language.f3MeWebApiPluginNew" (click)="openAddPluginPopup()"></dx-button>
  <table>
    <tr>
      <th>{{ language.f3MeWebApiPluginShortname }}</th>
      <th>{{ language.f3MeWebApiPluginMenuText }}</th>
      <th>{{ language.f3MeWebApiPluginExternalUrl }}</th>
      <th>{{ language.f3MeWebApiPluginRoles }}</th>
      <th>{{ language.inspireGridActionsColumnName }}</th>
    </tr>
    <tr *ngFor="let plugin of plugins">
      <td>{{ plugin.ShortName }}</td>
      <td><app-languageitem-editor-grid [cellInfo]="plugin" [item]="plugin.Name" [editable]="false"></app-languageitem-editor-grid></td>
      <td>{{ plugin.Location }}</td>
      <td>{{ getRoles(plugin.Roles) }}</td>
      <td>
        <div class="actions"><dx-button icon="edit" (click)="openEditPluginPopup(plugin)"></dx-button> <dx-button icon="fas fa-trash" (click)="deletePlugin(plugin.KeyId)"></dx-button></div>
      </td>
    </tr>
  </table>
</div>

<dx-popup *ngIf="currentPlugin" [width]="400" [height]="'auto'" [title]="pluginPopupTitle" [(visible)]="pluginFormPopupEnabled">
  <div *dxTemplate="let data of 'content'">
    <form name="form" (submit)="savePlugin($event)">
      <dx-form [colCount]="2">
        <dxi-item [colSpan]="2">
          <dxo-label [text]="language.f3MeWebApiPluginShortname"></dxo-label>
          <div *dxTemplate>
            <dx-text-box name="shortName" [(value)]="currentPlugin.ShortName" [disabled]="checkFormTypeEditing(formType)">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </dxi-item>
        <dxi-item [colSpan]="2">
          <dxo-label [text]="language.f3MeWebApiPluginMenuText"></dxo-label>
          <div *dxTemplate>
            <app-languageitem-editor-grid [cellInfo]="currentPlugin" [item]="currentPlugin.Name"></app-languageitem-editor-grid>
          </div>
        </dxi-item>

        <dxi-item [colSpan]="2">
          <dxo-label [text]="language.f3MeWebApiPluginExternalUrl"></dxo-label>
          <div *dxTemplate>
            <dx-text-box name="location" [(value)]="currentPlugin.Location">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </dxi-item>

        <dxi-item [colSpan]="2">
          <dxo-label [text]="language.f3MeWebApiPluginHeight"></dxo-label>
          <div *dxTemplate>
            <dx-number-box name="height" [showClearButton]="true" format="#" [(value)]="currentPlugin.Height"> </dx-number-box>
          </div>
        </dxi-item>

        <dxi-item [colSpan]="2">
          <dxo-label [text]="language.f3MeWebApiPluginWidth"></dxo-label>
          <div *dxTemplate>
            <dx-number-box name="width" [showClearButton]="true" format="#" [(value)]="currentPlugin.Width"> </dx-number-box>
          </div>
        </dxi-item>

        <dxi-item [colSpan]="2">
          <dxo-label [text]="language.f3MeWebApiPluginRoles"></dxo-label>
          <div *dxTemplate>
            <dx-drop-down-box [(value)]="selectedRoles" placeholder="Select a value..." [dataSource]="roles" displayExpr="ShortName">
              <div *dxTemplate="let data of 'content'">
                <dx-list
                  [dataSource]="roles"
                  displayExpr="ShortName"
                  [searchEnabled]="true"
                  searchExpr="ShortName"
                  searchMode="contains"
                  selectionMode="multiple"
                  [showSelectionControls]="true"
                  [(selectedItemKeys)]="selectedRoles"
                  (onSelectionChanged)="roleToggled()"
                ></dx-list>
              </div>
            </dx-drop-down-box>
          </div>
        </dxi-item>

        <dxi-item [colSpan]="2">
          <div *dxTemplate>
            <dx-button icon="save" [text]="language.f3MeWebApiPluginSave" [useSubmitBehavior]="true"></dx-button>
          </div>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
