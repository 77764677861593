import { GroupOptionsDto } from './../../cms/dto/GroupOptions-dto';

export declare type EbsGroupInformationDtoMessageTitleDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file EbsGroupInformationDto.cs
// Don't adjust manually!

export class EbsGroupInformationDto {
    public ModelElementShortName: string;
    public SortOrder: number;
    public AdditionalModelElementShortName: string;
    public VisibilityModelElementShortName: string;
    public LinkId: number;
    public Options: GroupOptionsDto;
    public Color: string;
    public CmsGroupId: number;
    public ModelElementAppliesToTotal: boolean;
    public ModelElementHideValue: boolean;
    public AdditionalModelElementHideValue: boolean;
    public ParentId: number;
    public MessageTitle: EbsGroupInformationDtoMessageTitleDto;
}

