import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntryCreated } from "../../../angular-common/baseservice/entry-created";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { EmailTemplateDto } from "../../models/cms/dto/EmailTemplate-dto";
import { EmailTemplate } from "./email-template";

@Injectable()
export class EmailService extends PlBaseService {
  private endPoint: string = "emailtemplate";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, private selectLanguage: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  private extractTemplate(emailTemplateDTO: EmailTemplateDto): EmailTemplate {
    const email = new EmailTemplate(this.selectLanguage);
    email.fromDto(emailTemplateDTO);
    return email;
  }

  public getTemplate(id: number): Observable<EmailTemplate> {
    if (id === undefined || id === null) {
      id = -1;
    }
    const url = this.envSettings.combinePath(this.endPoint, id.toLocaleString());
    return this.getData<EmailTemplateDto>(url).pipe(map(this.extractTemplate, this));
  }

  public sendEmail(emailTemplateDTO: EmailTemplateDto): Observable<EntryCreated<void>> {
    return this.createData<void>(this.endPoint, emailTemplateDTO);
  }
}
