<div *ngIf="item">
    <div class="panel-with-header-and-content">
        <h3 class="panel-header">
            {{item.description}}
            <app-edit-toggle [editToggle]="editToggle"></app-edit-toggle>
        </h3>

        <div class="panel-content">
            <app-languageitem-editor [currentLanguage]="currentLanguage" [isEditing]="editToggle.editing"
                [supportsHtml]="item.supportsHtml" [languageItem]="languageItemValue"></app-languageitem-editor>
        </div>
    </div>
</div>