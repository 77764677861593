<div *ngIf="targetProperties">
  <div *ngIf="showProgressControls">
    <ul class="order-status">
      <li [ngClass]="{ current: targetProperties.exogenousInput.status === targetStatus.AbleToSelect }">
        <span [ngClass]="{ spannotcurrent: targetProperties.exogenousInput.status !== targetStatus.AbleToSelect }">{{ languageService.f3MeWebApiTargetStatusOrder }}</span>
      </li>
      <li [ngClass]="{ current: targetProperties.exogenousInput.status === targetStatus.RequestedForApproval }">
        <span [ngClass]="{ spannotcurrent: targetProperties.exogenousInput.status !== targetStatus.RequestedForApproval }">{{ languageService.f3MeWebApiTargetStatusRequested }}</span>
        <span *ngIf="targetProperties.exogenousInput.status === targetStatus.RequestedForApproval" class="spanexplain">{{ languageService.f3MeWebApiTargetStatusRequestedExplain }}</span>
      </li>
      <li [ngClass]="{ current: targetProperties.exogenousInput.status === targetStatus.Approval }">
        <span [ngClass]="{ spannotcurrent: targetProperties.exogenousInput.status !== targetStatus.Approval }">{{ languageService.f3MeWebApiTargetStatusApproved }}</span>
        <span *ngIf="targetProperties.exogenousInput.status === targetStatus.Approval" class="spanexplain">{{ languageService.f3MeWebApiTargetStatusApprovedExplain }}</span>
      </li>
    </ul>
  </div>
</div>
