<div class="buttons-bottom-margin align-right">
  <dx-button [disabled]="!canSave" id="saveButton" icon="save" [hint]="language.ButtonSave" (onClick)="saveRole()"> </dx-button>
  <dx-button [disabled]="!hasRole" id="undoButton" icon="undo" [hint]="language.Undo" (onClick)="reset()"> </dx-button>
</div>

<div class="role-details">
  <app-cardview [title]="language.authorizationRolesMaintenanceDetailsTitle">
    <dx-form [colCount]="6">
      <dxi-item dataField="roleName" [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesMaintenanceDetailsRolename"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="roleName" [disabled]="!hasRole">
            <dx-validator>
              <dxi-validation-rule type="custom" [validationCallback]="validateRoleNameIsUnique" [message]="language.authorizationRolesMaintenanceValidationRolenameUnique"></dxi-validation-rule>
              <dxi-validation-rule type="custom" [validationCallback]="validateRoleName" [message]="language.authorizationRolesMaintenanceValidationRolenameRequired"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item dataField="roleDescription" [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesMaintenanceDetailsRoleDescription"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="roleDescription" [disabled]="!hasRole"> </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesMaintenanceValidAssignableFrom"></dxo-label>
        <div *dxTemplate>
          <dx-select-box [items]="rolesList.all" [(value)]="assignableFromRole" valueExpr="role.id" displayExpr="role.name" [disabled]="!hasRole || !canChangeAssignableFrom" [showClearButton]="true">
          </dx-select-box>
        </div>
      </dxi-item>
    </dx-form>
  </app-cardview>
</div>
