<div *ngIf="!valueOnly" class="dx-field">
  <div class="dx-field-label">{{ option.name }}:</div>
  <div class="dx-field-value">
    <div *ngIf="option.isEnabled; else readOnlyTextBox">
      <dx-date-box [(value)]="option.value" [showClearButton]="true"></dx-date-box>
    </div>
  </div>
</div>
<div *ngIf="valueOnly">
  <div *ngIf="option.isEnabled; else readOnlyTextBox">
    <dx-date-box [(value)]="option.value" [showClearButton]="true"></dx-date-box>
  </div>
</div>
<ng-template #readOnlyTextBox>
  <dx-text-box [value]="option.valueAsText" [disabled]="true" [hoverStateEnabled]="false"></dx-text-box>
</ng-template>
