import { Component, Input } from "@angular/core";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { LanguageMetaDataDto } from "../../../common/models/dto/LanguageMetaData-dto";
import { PlLanguageSelection } from "../../language/pl-language-selection";
import { PlLanguageSelector } from "../../language/pllanguageselector";

@Component({
  selector: "app-language-selection",
  templateUrl: "./language-selection.component.html",
  styleUrls: ["./language-selection.component.scss"],
})
export class LanguageSelectionComponent {
  public popupVisible = false;
  _lanuageselector: PlLanguageSelector;

  @Input()
  public showTitles: boolean;

  constructor(public language: WebLanguage, public languageSelection: PlLanguageSelection) {
    this.showTitles = true;
  }

  public get currentLanguage(): LanguageMetaDataDto {
    return this.languageSelection.currentLanguageMetadata();
  }

  public get languages() {
    return this.languageSelection.languages;
  }

  public selectLanguage(newLanguage: LanguageMetaDataDto) {
    if (newLanguage && newLanguage != null && this.languageSelection.currentLanguage) {
      this.languageSelection.currentLanguage = newLanguage.Id;
    }
  }

  showPopup() {
    this.popupVisible = true;
  }

  hidePopup() {
    this.popupVisible = false;
  }
}
