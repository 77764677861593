import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DxButtonModule, DxPopupModule } from "devextreme-angular";
import { LanguageSelectionComponent } from "./language-selection.component";

@NgModule({
  imports: [CommonModule, DxButtonModule, DxPopupModule],
  declarations: [LanguageSelectionComponent],
  exports: [LanguageSelectionComponent],
})
export class LanguageSelectionModule {}
