
// t4tsCode gen": "0.8.2.0 for file FixedImageNames.cs
// Don't adjust manually!

export class FixedImageNamesDto {
    public static Logo: string = "fixed_logo.gif";
    public static LogoLogin: string = "fixed_logo_login.gif";
    public static PlaceHolder: string = "fixed_imageplaceholder.jpg";
    public static MugShot: string = "fixed_mugshot.png";
    public static Login: string = "fixed_login.jpg";
}

