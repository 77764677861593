import { Component, OnInit } from "@angular/core";

import { CmsLanguage } from "../language/cmslanguage.service";
import { LanguageEditItem } from "../../../employee-common/components/language/languageedititem.model";
import { LanguageEditService } from "../../../employee-common/components/language/languageedit.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

@Component({
  selector: "app-languageddit-component",
  templateUrl: "./languageedit.component.html",
  styleUrls: ["./languageedit.component.scss"],
  providers: [],
})
export class LanguageEditComponent implements OnInit {
  public constructor(selectLanguage: PlLanguageSelection, public languageService: CmsLanguage, private languageEditService: LanguageEditService) {
    this._currentLanguage = selectLanguage.currentLanguage;
  }

  public ngOnInit() {
    this.languageEditService.getEntriesForEditing().subscribe((l) => {
      this.items = this.languageEditService.getEditItems(l, this.languageService);
    });
  }

  public items: LanguageEditItem[];

  private _currentLanguage: string;
  public get currentLanguage(): string {
    return this._currentLanguage;
  }

  public languageChanged(newLanguage) {
    this._currentLanguage = newLanguage;
  }
}
