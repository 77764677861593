import { ModelPresentableElementDto } from './ModelPresentableElement-dto';
import { VariantCalculationDto } from './VariantCalculationDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file VariantDTO.cs
// Don't adjust manually!

export class VariantDto extends ModelPresentableElementDto {
    public DataSetId: number;
    public Calculations: VariantCalculationDto;
}

