import { RouteLocations } from "../route-locations";
import { MenuItem } from "./models/menuitem";

export class MenuFunctions {
  public static findMenuItem(route: string, menuItemsFromApi: MenuItem[]): MenuItem {
    let result: MenuItem;
    menuItemsFromApi.forEach(menuItem => {
      if (!result) {
        if (menuItem.hasRoute && RouteLocations.routesAreSame(menuItem.route, route)) {
          result = menuItem;
        } else if (menuItem.hasSubMenuItems) {
          result = this.findMenuItem(route, menuItem.items);
        }
      }
    });
    return result;
  }
}
