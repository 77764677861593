import { NgModule } from "@angular/core";
import { ImageService } from "./image.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [ImageService],
  exports: [],
})
export class ImageModule {}
