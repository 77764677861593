import { ApplicationSettingsDto } from "../../models/cms/dto/ApplicationSettings-dto";

export class AppSettings {
  public constructor(public requireAcknowledgeDisclaimer: boolean) {}

  public copyFromDTO(dto: ApplicationSettingsDto) {
    this.requireAcknowledgeDisclaimer = dto.RequireAcknowledgeDisclaimer;
  }

  public toDTO(): ApplicationSettingsDto {
    const result = new ApplicationSettingsDto();
    result.RequireAcknowledgeDisclaimer = this.requireAcknowledgeDisclaimer;
    return result;
  }
}
