import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { PlLoadIndicator } from "./loadindicator";

@Component({
  selector: "app-loadindicator-component",
  template: `
    <dx-load-indicator
      class="plLoadIndicatorDefaultStyle"
      [visible]="isVisible"
    ></dx-load-indicator>
  `,
  styles: [
    `
      .plLoadIndicatorDefaultStyle {
        height: 60px;
        width: 60px;
        left: 50%;
        top: 50%;
        margin-left: -30px;
        margin-top: -30px;
        position: fixed;
      }
    `,
  ],
  providers: [],
})
export class PlLoadIndicatorComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private loadingCount: number = 0;

  public constructor(private loadIndicator: PlLoadIndicator) {}

  public ngOnInit(): void {
    this.closeIndicator();
    this.subscription = this.loadIndicator.loading.subscribe(isLoading =>
      this.updateIndicator(isLoading),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isVisible: boolean;

  public closeIndicator(): void {
    this.isVisible = false;
    this.loadingCount = 0;
  }

  public showIndicator(): void {
    this.isVisible = true;
  }

  private updateIndicator(isLoading: boolean): void {
    if (isLoading) {
      this.loadingCount++;
    } else {
      this.loadingCount--;
    }

    if (this.loadingCount <= 0) {
      this.closeIndicator();
    } else {
      this.showIndicator();
    }
  }
}
