import { ModelElementSpecification } from "../../../../common/modelelements/modelelementsspecification";
import { ModelElementDto } from "../../../../common/models/dto/ModelElementDto-dto";
import { PreferedUserEmployeeMappingConfigurationDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingConfigurationDto-dto";

export class PreferedUserEmployeeMappingConfigurationGrouping {
  public constructor() {}

  public availableOptions: ModelElementSpecification[] = [];
  public currentConfiguration: ModelElementSpecification[] = [];

  public copyFromDto(dto: PreferedUserEmployeeMappingConfigurationDto) {
    const newAvailableOptions: ModelElementSpecification[] = [];
    dto.AvailableModelElements.forEach((element) => {
      const newAvailableOption = new ModelElementSpecification();
      newAvailableOption.copyFromDto(element);
      newAvailableOptions.push(newAvailableOption);
    });
    this.availableOptions = newAvailableOptions;

    const newConfigurations: ModelElementSpecification[] = [];
    dto.ConfiguredModelElements.forEach((element) => {
      const newConfiguration = new ModelElementSpecification();
      newConfiguration.copyFromDto(element);
      newConfigurations.push(newConfiguration);
    });
    this.currentConfiguration = newConfigurations;
  }

  public toDto(currentConfiguration: ModelElementSpecification[]): PreferedUserEmployeeMappingConfigurationDto {
    const dto = new PreferedUserEmployeeMappingConfigurationDto();
    const newConfigurations: ModelElementDto[] = [];
    currentConfiguration.forEach((element) => {
      newConfigurations.push(element.toDto(element));
    });

    dto.ConfiguredModelElements = newConfigurations;

    return dto;
  }
}
