import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { DxListComponent } from "devextreme-angular";
import { confirm } from "devextreme/ui/dialog";
import { Sorter } from "../../../../../../angular-common/sorting/sorter";
import { AuthorizationLanguage } from "../../../../../../common/language/Authorizationlanguage.service";
import { EnvironmentSettings } from "../../../../../baseservice/environment-settings";
import { User } from "../../../services/models/authorization-user-model";
import { UserStatus } from "../../../services/models/authorization-user-status-model";
import { AuthorizationRolesTabContent } from "../../tabs/authorization-tabs.tab-content-roles";

@Component({
  selector: "app-authorization-content-userlist",
  templateUrl: "./authorization-content.component-userlist.html",
  styleUrls: ["./authorization-content.component-userlist.scss"],
})
export class UserListComponent {
  @ViewChild(DxListComponent, { static: true }) userList: DxListComponent;

  @Input()
  public tabContent: AuthorizationRolesTabContent;

  @Input() selectedUser: User;
  @Output() selectedUserChange = new EventEmitter<User>();

  private _users: User[];

  @Input()
  public get users(): User[] {
    Sorter.sortOfDisplayName(this._users);
    return this._users;
  }
  public set users(users: User[]) {
    this._users = users;
  }

  @Input() deleteUser: (user: User) => void;

  constructor(public language: AuthorizationLanguage, private envSettings: EnvironmentSettings) {
    this.onUserChanged = this.onUserChanged.bind(this);
  }

  public selectionChanged(event) {
    this.onUserChanged(event.addedItems[0]);
  }

  public selectUser(index: number) {
    this.userList.instance.selectItem(index);
  }

  private onUserChanged(user: User) {
    this.selectedUser = user;
    this.selectedUserChange.emit(this.selectedUser);
    this.selectedUserChange.subscribe(() => {
      // We want the subscribe to be present.
      console.log("Selection changed");
    });
  }

  public onDeleteUser() {
    const question = confirm(this.language.confirmDeleteHeader, this.language.confirmDeleteTitle);

    question.then((answer) => {
      if (answer) {
        this.selectedUser.status = UserStatus.Inactive;
        this.deleteUser(this.selectedUser);
      }
    });
  }

  public onCloneUser() {
    const newUser = User.cloneUser(this.selectedUser);

    this.addNewUser(newUser);
  }

  public onAddUser() {
    const newUser = User.newEmptyUser();
    newUser.osAuthenticated = this.envSettings.useExternalAuthentication();
    newUser.userName = this.language.authorizationRolesDetailsUsernameNewUserName;
    this.addNewUser(newUser);
  }

  private addNewUser(newUser: User) {
    this.users.unshift(newUser);

    this.selectedUser = newUser;
    this.userList.instance.reload();
    this.userList.instance.selectItem(newUser);
  }

  public get canDelete() {
    return this.selectedUser === undefined || this.tabContent.users.length === 0;
  }
}
