import { DxButtonModule, DxPopupModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HtmlEditorModule } from "../../../angular-common/components/htmleditor/htmleditor.module";
import { LanguageModule } from "../../components/language/language.module";
import { EmailComponent } from "./email.component";
import { EmailService } from "./email.service";

@NgModule({
  declarations: [EmailComponent],
  imports: [CommonModule, FormsModule, HttpClientModule, LanguageModule, HtmlEditorModule, DxButtonModule, DxPopupModule],
  providers: [EmailService],
  exports: [EmailComponent],
})
export class EmailModule {}
