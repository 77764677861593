import { AfterViewInit, Component } from "@angular/core";

import { WebLanguage } from "../../../../common/language/weblanguage.service";
import { PlAuthenticationService } from "../../../authentication/authentication.service";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { PlUserStorage } from "../../../baseservice/pluserstorage";
import { RouteNavigation } from "../../../baseservice/route-navigation";
import { RouteLocations } from "../../../route-locations";
import { LoginStorageSettings } from "../login-storage-settings";

@Component({
  selector: "app-login-signout",
  templateUrl: "./login-signout.component.html",
  styleUrls: ["./login-signout.component.scss"],
})
export class LoginSignoutComponent implements AfterViewInit {
  public language: WebLanguage;

  constructor(languageService: WebLanguage, private routeNavigation: RouteNavigation, private authService: PlAuthenticationService, private environmentSettings: EnvironmentSettings) {
    this.language = languageService;
  }

  ngAfterViewInit() {
    LoginStorageSettings.markExternalLoginWaitForRoundTripRunning();
    console.log("Block auto login after signout");
    setTimeout(() => {
      // To present Angular event bubling in a for now unknown relational binding, we wait for the next possible slot.
      this.doSignOut();
    });
  }

  public doSignOut(): void {
    if (PlUserStorage.user) {
      console.log("Clear user cache");
      this.authService.resetUserStorage();
    } else {
      console.log("Already no user present");
    }
    if (this.environmentSettings.hasCustomSignOffPath()) {
      console.log("Using custom signoutpath");
      RouteLocations.moveToLocation(this.environmentSettings.customSignOffPath());
    } else {
      console.log("No custom signout page.");
      this.routeNavigation.gotToLogin();
    }
  }
}
