import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DxHtmlEditorModule } from "devextreme-angular";
import { HtmlEditorComponent } from "./htmleditor.component";

@NgModule({
  imports: [CommonModule, FormsModule, DxHtmlEditorModule],
  declarations: [HtmlEditorComponent],
  exports: [HtmlEditorComponent],
  providers: [],
})
export class HtmlEditorModule {}
