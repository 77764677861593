import { Component, OnInit } from "@angular/core";

import DxDataGrid from "devextreme/ui/data_grid";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../angular-common/language/pl-language-selection-copier";
import { Sorter } from "../../../angular-common/sorting/sorter";
import { LanguageMetaDataDto } from "../../../common/models/dto/LanguageMetaData-dto";
import { LabourgroupService } from "../../cms/labourgroups/labourgroup.service";
import { LabourConditionGroup } from "../../cms/labourgroups/lc-group";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { LabourGroupDto } from "../../models/cms/dto/LabourGroupDto-dto";

@Component({
  selector: "app-cms-labourcondition-groups-edit-component",
  templateUrl: "./cms-labourcondition-groups-edit.component.html",
  styleUrls: ["./cms-labourcondition-groups-edit.component.scss"],
})
export class LabourConditionGroupsEditComponent implements OnInit {
  public groups: LabourGroupDto[] = [];
  public languages: LanguageMetaDataDto[] = [];

  public constructor(public language: CmsLanguage, private languageSelection: PlLanguageSelection, public groupService: LabourgroupService, private plLanguageSelection: PlLanguageSelection) {
    this.title = this.title.bind(this);
  }

  ngOnInit(): void {
    this.groupService.getGroupsForEdit().subscribe((g) => {
      this.createGroups(g);
    });
    this.languages = this.languageSelection.languagesMetaData;
  }

  public title(lc: LabourGroupDto) {
    return lc.Title[this.plLanguageSelection.currentLanguage];
  }

  public createGroups(g: LabourConditionGroup[]) {
    Sorter.sortSortOrderThenAlpha(g, (x) => x.title);
    g.forEach((group) => {
      const groupDto = group.toDto();
      this.groups.push(groupDto);
    });
  }

  onRowRemoved(lc: LabourGroupDto) {
    if (lc.Id >= -1) {
      this.groupService.deleteGroup(lc).subscribe((r) => {});
    }
  }

  onEditingStart(lc: LabourGroupDto) {
    PlLanguageSelectionCopier.copyFromOriginal(lc);
  }

  onRowUpdated(lc: LabourGroupDto, dxGrid: DxDataGrid) {
    PlLanguageSelectionCopier.copyToOriginal(lc);
    dxGrid.repaint();
    if (lc.Id === -1) {
      this.groupService.insertGroup(lc).subscribe();
    } else {
      this.groupService.saveGroup(lc).subscribe();
    }
  }

  onAddRow() {
    this.groups.push(this.groupService.getDefaultGroup().toDto());
  }
}
