import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule } from "devextreme-angular";
import { TechnicalManagementRoutesDto } from "../../../common/models/dto/TechnicalManagementRoutes-dto";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { canActivateRoleGuard } from "../../authentication-guard/role-guard";
import { TextPopupModule } from "../textpopup/textpopup.module";
import { ApplicationAboutInformationComponent } from "./applicationinformation/applicationinformation.component";
import { ApplicationInformationService } from "./applicationinformation/applicationinformation.service";
import { LogDownloadComponent } from "./logdownload/log-download.component";
import { LogDownloadService } from "./logdownload/log-download.service";

const routes: Routes = [
  {
    path: TechnicalManagementRoutesDto.LogDownload,
    component: LogDownloadComponent,
    data: [{ menuType: MenuItemTypesDto.Technical }],
    canActivate: [canActivateRoleGuard],
  },
  {
    path: TechnicalManagementRoutesDto.ApplicationInformation,
    component: ApplicationAboutInformationComponent,
    data: [{ menuType: MenuItemTypesDto.Technical }],
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, DxButtonModule, TextPopupModule],
  providers: [LogDownloadService, ApplicationInformationService],
  declarations: [LogDownloadComponent, ApplicationAboutInformationComponent],
  exports: [LogDownloadComponent, ApplicationAboutInformationComponent],
})
export class TechnicalMaintainerModule {}
