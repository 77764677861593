import { Injectable } from "@angular/core";
import { LanguageServiceBase } from "../../components/language/language-base.service";
import { PersonlizedLanguageService } from "../../components/language/personlized-language.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../angular-common";

@Injectable()
//t4Languages": "0.8.2.0
export class ReportingLanguage extends LanguageServiceBase {
  public constructor(plLanguageService: PlLanguageService, languageSelection: PlLanguageSelection, personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService, languageSelection, personlizedLanguageService);
  }

  /**
   * ReportingMenu from Reporting.csv (in EN: Reporting management )
   */
  public key_ReportingMenu = "ReportingMenu";
  /**
   * ReportingMenu from Reporting.csv (in EN: Reporting management )
   */
  public get ReportingMenu(): string { return this.getStringDef(this.key_ReportingMenu, "Reporting management"); }

  /**
   * ReportingReportPath from Reporting.csv (in EN: Path of report )
   */
  public key_ReportingReportPath = "ReportingReportPath";
  /**
   * ReportingReportPath from Reporting.csv (in EN: Path of report )
   */
  public get ReportingReportPath(): string { return this.getStringDef(this.key_ReportingReportPath, "Path of report"); }

  /**
   * ReportingReportRoles from Reporting.csv (in EN: Required role )
   */
  public key_ReportingReportRoles = "ReportingReportRoles";
  /**
   * ReportingReportRoles from Reporting.csv (in EN: Required role )
   */
  public get ReportingReportRoles(): string { return this.getStringDef(this.key_ReportingReportRoles, "Required role"); }

  /**
   * ReportingReportInputs from Reporting.csv (in EN: Input fields )
   */
  public key_ReportingReportInputs = "ReportingReportInputs";
  /**
   * ReportingReportInputs from Reporting.csv (in EN: Input fields )
   */
  public get ReportingReportInputs(): string { return this.getStringDef(this.key_ReportingReportInputs, "Input fields"); }

  /**
   * NoTitle from Reporting.csv (in EN: No title )
   */
  public key_NoTitle = "NoTitle";
  /**
   * NoTitle from Reporting.csv (in EN: No title )
   */
  public get NoTitle(): string { return this.getStringDef(this.key_NoTitle, "No title"); }

  /**
   * NoDescription from Reporting.csv (in EN: No description )
   */
  public key_NoDescription = "NoDescription";
  /**
   * NoDescription from Reporting.csv (in EN: No description )
   */
  public get NoDescription(): string { return this.getStringDef(this.key_NoDescription, "No description"); }

  /**
   * NoVariantSaved from Reporting.csv (in EN: You need to save first )
   */
  public key_NoVariantSaved = "NoVariantSaved";
  /**
   * NoVariantSaved from Reporting.csv (in EN: You need to save first )
   */
  public get NoVariantSaved(): string { return this.getStringDef(this.key_NoVariantSaved, "You need to save first"); }

  /**
   * ReportingViewerMenu from Reporting.csv (in EN: Reports )
   */
  public key_ReportingViewerMenu = "ReportingViewerMenu";
  /**
   * ReportingViewerMenu from Reporting.csv (in EN: Reports )
   */
  public get ReportingViewerMenu(): string { return this.getStringDef(this.key_ReportingViewerMenu, "Reports"); }

  /**
   * ReportingOptionNotSupported from Reporting.csv (in EN: This option is not supported )
   */
  public key_ReportingOptionNotSupported = "ReportingOptionNotSupported";
  /**
   * ReportingOptionNotSupported from Reporting.csv (in EN: This option is not supported )
   */
  public get ReportingOptionNotSupported(): string { return this.getStringDef(this.key_ReportingOptionNotSupported, "This option is not supported"); }

  /**
   * ReportGenerationGenerate from Reporting.csv (in EN: Generate )
   */
  public key_ReportGenerationGenerate = "ReportGenerationGenerate";
  /**
   * ReportGenerationGenerate from Reporting.csv (in EN: Generate )
   */
  public get ReportGenerationGenerate(): string { return this.getStringDef(this.key_ReportGenerationGenerate, "Generate"); }

  /**
   * ReportDesignerTitle from Reporting.csv (in EN: Report designer )
   */
  public key_ReportDesignerTitle = "ReportDesignerTitle";
  /**
   * ReportDesignerTitle from Reporting.csv (in EN: Report designer )
   */
  public get ReportDesignerTitle(): string { return this.getStringDef(this.key_ReportDesignerTitle, "Report designer"); }

  /**
   * ReportNotFound from Reporting.csv (in EN: Report {0} not found )
   */
  public key_ReportNotFound = "ReportNotFound";
  /**
   * ReportNotFound from Reporting.csv (in EN: Report {0} not found )
   */
  public ReportNotFound(val1): string { return this.getStringDef("ReportNotFound", "Report {0} not found").replace("{0}", val1); }

  /**
   * ReportGenerationStart from Reporting.csv (in EN: Starting generation for {0} )
   */
  public key_ReportGenerationStart = "ReportGenerationStart";
  /**
   * ReportGenerationStart from Reporting.csv (in EN: Starting generation for {0} )
   */
  public ReportGenerationStart(val1): string { return this.getStringDef("ReportGenerationStart", "Starting generation for {0}").replace("{0}", val1); }

  /**
   * ReportGenerationLoadedReport from Reporting.csv (in EN: Loaded report {0} )
   */
  public key_ReportGenerationLoadedReport = "ReportGenerationLoadedReport";
  /**
   * ReportGenerationLoadedReport from Reporting.csv (in EN: Loaded report {0} )
   */
  public ReportGenerationLoadedReport(val1): string { return this.getStringDef("ReportGenerationLoadedReport", "Loaded report {0}").replace("{0}", val1); }

  /**
   * ReportGenerationFileNameTemplate from Reporting.csv (in EN: File name template )
   */
  public key_ReportGenerationFileNameTemplate = "ReportGenerationFileNameTemplate";
  /**
   * ReportGenerationFileNameTemplate from Reporting.csv (in EN: File name template )
   */
  public get ReportGenerationFileNameTemplate(): string { return this.getStringDef(this.key_ReportGenerationFileNameTemplate, "File name template"); }

  /**
   * ReportGeneartionLoadBatch from Reporting.csv (in EN: Loading batch {0} )
   */
  public key_ReportGeneartionLoadBatch = "ReportGeneartionLoadBatch";
  /**
   * ReportGeneartionLoadBatch from Reporting.csv (in EN: Loading batch {0} )
   */
  public ReportGeneartionLoadBatch(val1): string { return this.getStringDef("ReportGeneartionLoadBatch", "Loading batch {0}").replace("{0}", val1); }

  /**
   * ReportGenerationGeneratedRecord from Reporting.csv (in EN: Generated {0} )
   */
  public key_ReportGenerationGeneratedRecord = "ReportGenerationGeneratedRecord";
  /**
   * ReportGenerationGeneratedRecord from Reporting.csv (in EN: Generated {0} )
   */
  public ReportGenerationGeneratedRecord(val1): string { return this.getStringDef("ReportGenerationGeneratedRecord", "Generated {0}").replace("{0}", val1); }

  /**
   * ReportGenerationGeneratedFinished from Reporting.csv (in EN: Finished generating )
   */
  public key_ReportGenerationGeneratedFinished = "ReportGenerationGeneratedFinished";
  /**
   * ReportGenerationGeneratedFinished from Reporting.csv (in EN: Finished generating )
   */
  public get ReportGenerationGeneratedFinished(): string { return this.getStringDef(this.key_ReportGenerationGeneratedFinished, "Finished generating"); }

  /**
   * ReportDesignSaveToDisk from Reporting.csv (in EN: Save compatible )
   */
  public key_ReportDesignSaveToDisk = "ReportDesignSaveToDisk";
  /**
   * ReportDesignSaveToDisk from Reporting.csv (in EN: Save compatible )
   */
  public get ReportDesignSaveToDisk(): string { return this.getStringDef(this.key_ReportDesignSaveToDisk, "Save compatible"); }

  /**
   * ReportDesignSaved from Reporting.csv (in EN: Report saved )
   */
  public key_ReportDesignSaved = "ReportDesignSaved";
  /**
   * ReportDesignSaved from Reporting.csv (in EN: Report saved )
   */
  public get ReportDesignSaved(): string { return this.getStringDef(this.key_ReportDesignSaved, "Report saved"); }

  /**
   * ReportName from Reporting.csv (in EN: Report name )
   */
  public key_ReportName = "ReportName";
  /**
   * ReportName from Reporting.csv (in EN: Report name )
   */
  public get ReportName(): string { return this.getStringDef(this.key_ReportName, "Report name"); }

  /**
   * ReportUpload from Reporting.csv (in EN: Upload new report )
   */
  public key_ReportUpload = "ReportUpload";
  /**
   * ReportUpload from Reporting.csv (in EN: Upload new report )
   */
  public get ReportUpload(): string { return this.getStringDef(this.key_ReportUpload, "Upload new report"); }

  /**
   * ReportDownload from Reporting.csv (in EN: Download report )
   */
  public key_ReportDownload = "ReportDownload";
  /**
   * ReportDownload from Reporting.csv (in EN: Download report )
   */
  public get ReportDownload(): string { return this.getStringDef(this.key_ReportDownload, "Download report"); }

  /**
   * ReportManage from Reporting.csv (in EN: Manage reports )
   */
  public key_ReportManage = "ReportManage";
  /**
   * ReportManage from Reporting.csv (in EN: Manage reports )
   */
  public get ReportManage(): string { return this.getStringDef(this.key_ReportManage, "Manage reports"); }

  /**
   * ReportDateAdded from Reporting.csv (in EN: Uploaded on: )
   */
  public key_ReportDateAdded = "ReportDateAdded";
  /**
   * ReportDateAdded from Reporting.csv (in EN: Uploaded on: )
   */
  public get ReportDateAdded(): string { return this.getStringDef(this.key_ReportDateAdded, "Uploaded on:"); }

  /**
   * ReportLastEditedBy from Reporting.csv (in EN: Last edited by: )
   */
  public key_ReportLastEditedBy = "ReportLastEditedBy";
  /**
   * ReportLastEditedBy from Reporting.csv (in EN: Last edited by: )
   */
  public get ReportLastEditedBy(): string { return this.getStringDef(this.key_ReportLastEditedBy, "Last edited by:"); }

  /**
   * ReportLastEditedOn from Reporting.csv (in EN: Last edited on: )
   */
  public key_ReportLastEditedOn = "ReportLastEditedOn";
  /**
   * ReportLastEditedOn from Reporting.csv (in EN: Last edited on: )
   */
  public get ReportLastEditedOn(): string { return this.getStringDef(this.key_ReportLastEditedOn, "Last edited on:"); }

  /**
   * ReportVariantSelectionValidationMessage from Reporting.csv (in EN: Variant selection is required )
   */
  public key_ReportVariantSelectionValidationMessage = "ReportVariantSelectionValidationMessage";
  /**
   * ReportVariantSelectionValidationMessage from Reporting.csv (in EN: Variant selection is required )
   */
  public get ReportVariantSelectionValidationMessage(): string { return this.getStringDef(this.key_ReportVariantSelectionValidationMessage, "Variant selection is required"); }

  /**
   * ReportNameAlreadyExists from Reporting.csv (in EN: The report name already exists. Please change report name. )
   */
  public key_ReportNameAlreadyExists = "ReportNameAlreadyExists";
  /**
   * ReportNameAlreadyExists from Reporting.csv (in EN: The report name already exists. Please change report name. )
   */
  public get ReportNameAlreadyExists(): string { return this.getStringDef(this.key_ReportNameAlreadyExists, "The report name already exists. Please change report name."); }

  /**
   * ReportDataInUseMessaage from Reporting.csv (in EN: The report is in use and cannot be deleted. )
   */
  public key_ReportDataInUseMessaage = "ReportDataInUseMessaage";
  /**
   * ReportDataInUseMessaage from Reporting.csv (in EN: The report is in use and cannot be deleted. )
   */
  public get ReportDataInUseMessaage(): string { return this.getStringDef(this.key_ReportDataInUseMessaage, "The report is in use and cannot be deleted."); }

  /**
   * ReportInputFieldNotFound from Reporting.csv (in EN: Input field not found )
   */
  public key_ReportInputFieldNotFound = "ReportInputFieldNotFound";
  /**
   * ReportInputFieldNotFound from Reporting.csv (in EN: Input field not found )
   */
  public get ReportInputFieldNotFound(): string { return this.getStringDef(this.key_ReportInputFieldNotFound, "Input field not found"); }

  /**
   * ReportingTextsMenu from Reporting.csv (in EN: Reporting texts )
   */
  public key_ReportingTextsMenu = "ReportingTextsMenu";
  /**
   * ReportingTextsMenu from Reporting.csv (in EN: Reporting texts )
   */
  public get ReportingTextsMenu(): string { return this.getStringDef(this.key_ReportingTextsMenu, "Reporting texts"); }

  /**
   * ReportInputValueName from Reporting.csv (in EN: Input value name )
   */
  public key_ReportInputValueName = "ReportInputValueName";
  /**
   * ReportInputValueName from Reporting.csv (in EN: Input value name )
   */
  public get ReportInputValueName(): string { return this.getStringDef(this.key_ReportInputValueName, "Input value name"); }

  /**
   * ReportInputValueTranslation from Reporting.csv (in EN: Translation )
   */
  public key_ReportInputValueTranslation = "ReportInputValueTranslation";
  /**
   * ReportInputValueTranslation from Reporting.csv (in EN: Translation )
   */
  public get ReportInputValueTranslation(): string { return this.getStringDef(this.key_ReportInputValueTranslation, "Translation"); }


}
