<div class="buttons-bottom-margin align-right">
  <dx-button [disabled]="selectedUser === undefined" id="saveButton" icon="save" [hint]="language.ButtonSave" (onClick)="onSaveUser()"></dx-button>
  <dx-button [disabled]="selectedUser === undefined" id="undoButton" icon="undo" [hint]="language.Undo" (onClick)="onUndoChanges()"></dx-button>
</div>

<div class="user-details">
  <app-cardview [title]="language.authorizationRolesDetailsOfUser((selectedUser || {}).userNameIfNoLongname ?? '')">
    <dx-form [colCount]="6">
      <dxi-item dataField="userName" [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesDetailsUsername"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="username" [disabled]="!canEdit">
            <dx-validator>
              <dxi-validation-rule type="custom" [validationCallback]="validateUsername" [message]="language.authorizationRolesValidationUsernameUnique"></dxi-validation-rule>
              <dxi-validation-rule type="required" [message]="language.authorizationRolesValidationUsernameRequired"> </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item dataField="longName" [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesDetailsLongname"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="longname" [disabled]="!canEdit"></dx-text-box>
        </div>
      </dxi-item>
      <dxi-item dataField="OSAuthenticated" [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesDetailsOSAuthenticated"></dxo-label>
        <div *dxTemplate>
          <dx-check-box [disabled]="!allowAdjustingExternalAuth" [(value)]="osAuthenticated"> </dx-check-box>
        </div>
      </dxi-item>
      <dxi-item dataField="userStatus" [colSpan]="6">
        <dxo-label [text]="language.authorizationRolesDetailsStatuses"></dxo-label>
        <div *dxTemplate>
          <dx-select-box [disabled]="!canEdit" [value]="getUserStatus" [dataSource]="userStatuses" (onValueChanged)="onUserStatusChanged($event)"> </dx-select-box>
        </div>
      </dxi-item>
    </dx-form>
  </app-cardview>
</div>
