import { ApplicationAboutInformationDto } from "../../../../common/models/dto/ApplicationAboutInformationDto-dto";

export class AboutInformation {
  constructor() {}

  public productInformationHeader: string;
  public productInformationText: string;
  public userName: string;
  public userInformationHeader: string;
  public userInformationText: string;
  public connectionsHeader: string;
  public connectionsText: string;

  public fromDto(dto: ApplicationAboutInformationDto) {
    this.connectionsHeader = dto.ConnectionsHeader;
    this.connectionsText = dto.ConnectionsText;
    this.productInformationHeader = dto.ProductInformationHeader;
    this.productInformationText = dto.ProductInformationText;
    this.userInformationHeader = dto.UserInformationHeader;
    this.userInformationText = dto.UserInformationText;
    this.userName = dto.UserName;
  }
}
