import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PlUserStorage, RoleGuard } from "../../../../angular-common";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { MenuItem, MenuService } from "../../../../angular-common/menus";
import { EmployeeAuthenticationService } from "../../../auth/employee-auth.service";
import { ImageService } from "../../../components/images/image.service";
import { FixedImageModel } from "../../../fixedimages/fixedimage.model";
import { MenuItemTypesDto } from "../../../models/cms/dto/MenuItemTypes-dto";

@Component({
  selector: "app-personal-menu",
  templateUrl: "./personal-menu.component.html",
  styleUrls: ["./personal-menu.component.scss"],
})
export class PersonalMenuComponent implements OnInit {
  public isPersonalMenuCollapsed: boolean = false;
  // public users: Array<UserInformationValuesDto> = [];
  public menuItems: MenuItem[] = [];
  // private userSubscription: Subscription;

  public constructor(
    private menuService: MenuService,
    private router: Router,
    private selectLanguage: PlLanguageSelection,
    private imageService: ImageService,
    private authenticationService: EmployeeAuthenticationService,
  ) {}

  ngOnInit(): void {
    this.imageService.urlMugshot.subscribe((data) => {
      this.urlMugShot = data;
    });
  }

  @Input()
  public set isLoginPage(value: boolean) {
    if (!value) {
      this.menuService.getSubMenuItems(MenuItemTypesDto.PersonalMenu).subscribe((m) => {
        this.menuItems = RoleGuard.filterForActiveUser(m);
      });
    }
  }

  public get user() {
    return PlUserStorage.user;
  }
  // For now we return a fixed url until we support loading a custom image per user.
  public urlMugShot: FixedImageModel = FixedImageModel.createEmpty();

  public togglePMenuState = (): void => {
    this.isPersonalMenuCollapsed = this.isPersonalMenuCollapsed ? false : true;
  };

  public getPMenuVisibility = (): boolean => this.isPersonalMenuCollapsed;

  public languageChanged(newLanguage: string): void {
    if (this.selectLanguage.currentLanguage !== newLanguage) {
      this.selectLanguage.currentLanguage = newLanguage;
      window.location.reload();
    }
  }

  logOut = () => {
    this.authenticationService.logout();
  };

  public goTo(menuItem: MenuItem): void {
    if (menuItem) {
      console.log("move to: " + this.selectLanguage.currentValueFor(menuItem.title) + " at " + menuItem.route);
      this.router.navigate([menuItem.route]);
    } else {
      console.log("No route passed");
    }
  }
}
