<dx-form id="form" [colCount]="6">
  <!-- List all options available for the selection -->
  <dxi-item [colSpan]="3">
    <dxo-label [text]="language.columnSelection"></dxo-label>
    <div *dxTemplate>
      <dx-list
        #list
        [dataSource]="availableItems"
        [height]="400"
        [selectionMode]="selectionModeValue"
        [selectAllMode]="selectAllModeValue"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        [searchExpr]="searchValue"
        searchMode="contains"
        [displayExpr]="displayValue"
        [(selectedItems)]="selectedAvailableItems"
      >
        <div *dxTemplate="let item of 'item'; let i = index" class="available{{ i }}">
          {{ retrieveDisplayValue(item) }}
        </div>
      </dx-list>
    </div>
  </dxi-item>

  <!-- Buttons between the 2 lists -->
  <dxi-item [colSpan]="1">
    <div class="button-container buttons-between-lists">
      <dx-button text=">>" class="dx-button" hint="Add all options" (onClick)="addItems()"></dx-button>
      <dx-button text="<<" class="dx-button" hint="Remove all options" (onClick)="removeItems()"></dx-button>
    </div>
  </dxi-item>

  <!-- List with enabled options -->
  <dxi-item [colSpan]="2">
    <div *dxTemplate>
      <dx-list
        #list
        [dataSource]="itemsInUse"
        [height]="400"
        [selectionMode]="selectionModeValue"
        [selectAllMode]="selectAllModeValue"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        (onItemReordered)="onItemReordered($event)"
        [searchExpr]="searchValue"
        [displayExpr]="displayValue"
        searchMode="contains"
        [(selectedItems)]="selectedInUseItems"
      >
        <dxo-item-dragging [data]="itemsInUse" [allowReordering]="true"></dxo-item-dragging>
        <div *dxTemplate="let item of 'item'; let i = index" class="selected{{ i }}">
          {{ retrieveDisplayValue(item) }}
        </div>
      </dx-list>
    </div>
  </dxi-item>
</dx-form>
