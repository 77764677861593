<div *ngIf="data">
  <dx-data-grid
    [dataSource]="data.all"
    [columns]="$any(gridConfiguration.columns)"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    id="gridContainer"
    [columnResizingMode]="'widget'"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
  >
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 100, 500, 1000, 10000]"></dxo-pager>
    <dxo-editing mode="cell" [allowUpdating]="false"> </dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-scrolling [showScrollbar]="'always'"></dxo-scrolling>
    <div *dxTemplate="let data of 'cellTemplate'">
      <span *ngIf="!isActionColumn(data)">{{ data | lookupCellTemplate }}</span>
      <span *ngIf="isActionColumn(data)">
        <dx-button
          *ngIf="gridConfiguration.hasUserIdentificationConfigured"
          icon="chevronnext"
          [text]="language.f3MeWebApiUserToEmployeeButtonAssign"
          (onClick)="emitAssign(data.row.data.Identification)"
          [disabled]="isAssigned(data.row.data.Identification)"
        ></dx-button>
      </span>
    </div>
  </dx-data-grid>
</div>
