import { NgModule } from "@angular/core";
import { AngularCommonConfigurationModule } from "../../../angular-common/angular-common-config-module";
import { CommonAppConfigurationModule } from "../../../angular-common/common-app-config-module";
import { CommonAuthConfigurationModule } from "../../../angular-common/common-auth-config-module";
import { PreferedUserEmployeeLoginModule } from "../../../employee-common/management/prefered-user-employee/prefered-user-employee-login.module";
import { AppForAllModule } from "./app-forall.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

@NgModule({
  declarations: [AppComponent],
  imports: [CommonAuthConfigurationModule, AppRoutingModule, CommonAppConfigurationModule, AngularCommonConfigurationModule, AppForAllModule, PreferedUserEmployeeLoginModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
