
// t4tsCode gen": "0.8.2.0 for file TargetStatus.cs
// Don't adjust manually!
export enum TargetStatusDto {
        Unknown = 0,
        AbleToSelect = 1,
        RequestedForApproval = 2,
        Approval = 3,
        NotAbleToSelect = 4,
}

