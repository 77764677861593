import { EventEmitter } from "@angular/core";

export class FileUpload {
  public uncroppedImage: string;

  public constructor(public maxFileSizeMb: number, public allowedFileTypes: string[]) {
    this.reset();
  }

  public get maxFileSizeBytes(): number {
    return this.maxFileSizeMb * 1024 * 1024;
  }

  private reset() {
    this._fileData = null;
    this._fileName = "";
    this._fileType = "";
    this._showFileSizeWarning = false;
    this._showFileExtensionWarning = false;
  }

  public deleteFile() {
    this.reset();

    this.onFileDeleted.emit();
  }

  public get allowedFileTypesAsString(): string {
    let result = "";
    this.allowedFileTypes.forEach((element) => {
      if (result.length > 0) {
        result = result + ",";
      }
      result = result + element;
    });
    return result;
  }

  private checkFile(file: File): boolean {
    this._showFileSizeWarning = false;
    this._showFileExtensionWarning = false;

    if (file.size > this.maxFileSizeBytes) {
      console.log("File is too large (max = " + this.maxFileSizeBytes + ")", file);
      this._showFileSizeWarning = true;
      return false;
    }

    let extensionCorrect = false;
    this.allowedFileTypes.forEach((element) => {
      if (file.name.toLowerCase().endsWith(element.toLowerCase())) {
        extensionCorrect = true;
      }
    });
    if (!extensionCorrect) {
      console.log("Extension for file is invald:", file.name);
      this._showFileExtensionWarning = true;
      return false;
    }

    return true;
  }

  public fileChange(input) {
    if (input === undefined) {
      console.log("No file input passed.");
      return;
    }
    let files = null;
    if (input.files) {
      // Case for 'input' type = 'file'.
      files = input.files;
    } else if (input.value) {
      //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxFileUploader/Configuration/#onValueChanged
      files = input.value;
    } else {
      console.log("Don't know how to select a file from the input: ", input);
      return;
    }

    if (files.length === 0) {
      console.log("Empty file list passed");
      return;
    } else if (files.length > 1) {
      console.log("We can only handle a single file (and will use first one), but received:", files.length);
    }
    let file = files[0];
    
    console.log("file supplied: " + file.name);

    if (this.checkFile(file)) {
      let reader: any;
      reader = new FileReader();

      reader.addEventListener(
        "load",
        (event: { target: { result: string } }) => {
          this.uncroppedImage = event.target.result;
          this._fileData = event.target.result;
          this._fileName = file.name;
          this._fileType = file.type;
          this.onFileUploaded.emit();
        },
        false,
      );

      reader.readAsDataURL(file);
    } else {
      console.log("File check failed, did not load new file, emit with current image");
      this.onFileUploaded.emit();
    }
  }

  private _showFileSizeWarning: boolean = false;
  public get showFileSizeWarning() {
    return this._showFileSizeWarning;
  }

  private _showFileExtensionWarning: boolean = false;
  public get showFileExtensionWarning() {
    return this._showFileExtensionWarning;
  }

  public get hasFile(): boolean {
    const hasFileName = this._fileName && this._fileName !== null && this._fileName.length > 0;
    const hasFileData = this._fileData && this._fileData !== null;

    return hasFileData || hasFileName;
  }

  private _fileName: string = "";
  public get fileName(): string {
    return this._fileName;
  }
  public set fileName(newFileName: string) {
    this._fileName = newFileName;
  }

  private _fileType: string = "";
  public get fileType(): string {
    return this._fileType;
  }
  public set fileType(newFileType: string) {
    this._fileType = newFileType;
  }

  private _fileData: any;
  public get fileData(): any {
    if (this.hasFile) {
      return this._fileData;
    }
  }
  public set fileData(newFileData: any) {
    this._fileData = newFileData;
  }

  public onFileUploaded = new EventEmitter();
  public onFileDeleted = new EventEmitter();
}
