import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { EnvironmentSettings, PlUserStorage, RoleGuard } from "../../angular-common";

import { Injectable, inject } from "@angular/core";
import { mergeMap } from "rxjs/operators";
import { MenuService } from "../../angular-common/menus";
import { CommonRoutesDto } from "../../common/models/dto/CommonRoutes-dto";
import { UserSettingsService } from "../components/usersettings/usersettings.service";
import { CmsRoutesDto } from "../models/cms/dto/CmsRoutes-dto";

@Injectable()
export class UserAcknowledgementGuard extends RoleGuard {
  constructor(router: Router, environmentSettings: EnvironmentSettings, menuService: MenuService, private userSettingService: UserSettingsService) {
    super(router, environmentSettings, menuService);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let result = super.canActivate(route, state);

    result = result.pipe(
      mergeMap((superResult) => {
        if (superResult === true) {
          return of(this.userAcknowledgedAndHasData());
        } else {
          return of(superResult);
        }
      }),
    );

    return result;
  }

  private userAcknowledgedAndHasData(): boolean {
    let result = PlUserStorage.hasUser();
    if (result && this.userSettingService.requireAcknowledgeDisclaimer) {
      result = this.userSettingService.acknowledgedDisclaimer;
    }

    if (PlUserStorage.hasUser()) {
      const user = PlUserStorage.user;
      if (user && user.DataInSystem === false) {
        console.log("No data in system for user; notify");
        this.router.navigate(["/" + CmsRoutesDto.NoDataForUser]);
      }
    }

    if (!result) {
      console.log("User should acknowledge before usage; reroute to " + CommonRoutesDto.UserAcknowledgement);

      this.router.navigate(["/" + CommonRoutesDto.UserAcknowledgement]);
    }

    return result;
  }
}

export const canActivateUserAcknowledgementGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(UserAcknowledgementGuard).canActivate(route, state);
};
