import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppComponentSpecificComponentFactory } from "./appcomponentspecific-component-factory";
import { AppComponentSpecificDynamicViewComponent } from "./appcomponentspecific-dynamicview.component";
import { AppComponentSpecificDynamicViewDirective } from "./appcomponentspecific-dynamicview.directive";
import { AppComponentSpecificEmptyComponent } from "./appcomponentspecific-empty.component";

@NgModule({
  declarations: [AppComponentSpecificEmptyComponent, AppComponentSpecificDynamicViewComponent, AppComponentSpecificDynamicViewDirective],
  imports: [CommonModule, FormsModule, HttpClientModule],
  providers: [AppComponentSpecificComponentFactory],
  exports: [AppComponentSpecificDynamicViewComponent],
})
export class AppComponentSpecificViewModule {
  constructor() {}
}
