<div>
  <div class="buttons align-right">
    <p class="savewarning" *ngIf="hasChanges">*Click to save changes</p>
    <div class="saveContainer">
      <dx-button [disabled]="!hasChanges" (click)="onSave()" icon="save" [text]="language.ButtonSave"></dx-button>
    </div>

    <div *ngIf="currentConfig">
      <app-cardview>
        <div>
          <h2>Config</h2>
          <app-list-selector
            [itemsInUse]="currentConfig"
            [availableItems]="allAvailableElements"
            [displayValue]="displayValue"
            [searchValue]="searchValue"
            [moveImplementation]="moveImplementation"
            (valuesChanged)="configChanged()"
            (sortOrderChanged)="configSortOrderChanged($event)"
          ></app-list-selector>
        </div>
      </app-cardview>
    </div>
  </div>
</div>
