import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { BenefitGroup } from "../_models/benefit-group.model";
import { EbsLanguage } from "../language/ebslanguage.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-ebs-benefitgroup",
  templateUrl: "./ebs-benefit-group.component.html",
  styleUrls: ["./../ebs-overview/ebs-benefits.scss", "ebs-benefit-group.component.scss"],
})
export class BenefitGroupComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  public constructor(public languageService: EbsLanguage) {}

  public ngOnInit(): void {
    if (this.benefitGroup) {
      this.subscription = this.benefitGroup.onClick.subscribe(() => this.toggleGroup());
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input() public benefitGroup: BenefitGroup;

  private toggleGroup() {
    this.toggleCollapsed();
  }

  public toggleCollapsed() {
    this._collapsed = !this._collapsed;
  }

  public get collapsed(): boolean {
    return this._collapsed;
  }
  private _collapsed = true;
}
