import { Component, Input, OnInit, ViewChild } from "@angular/core";

import { map } from "rxjs/operators";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { ImageFileupload } from "../../../angular-common/components/fileupload/image-fileupload";
import { AspectRatio } from "../../../angular-common/components/image-cropper/aspect-ratio.enum";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { ImageChanges } from "../../components/images/image-changes.model";
import { ImageService } from "../../components/images/image.service";
import { CmsRolesNamesDto } from "../../models/cms/dto/CmsRolesNames-dto";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { LabourConditionGroup } from "../labourgroups/lc-group";
import { CmsLanguage } from "../language/cmslanguage.service";
import { CMSMessage } from "./../cms-message.model";

@Component({
  selector: "app-cmsmessageedit-edit-component",
  templateUrl: "./cms-message-edit-edit.component.html",
  styleUrls: ["./cms-message-edit-edit.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class CMSMessageEditEditComponent implements OnInit {
  public constructor(public language: CmsLanguage, languageSelection: PlLanguageSelection, private imageService: ImageService, envSettings: EnvironmentSettings) {
    this._currentLanguage = languageSelection.currentLanguage;

    this.fileUpload = new ImageFileupload(envSettings.maxUploadSizeMb, "", this.imageService.defaultImagineExtensions);
  }

  public ngOnInit(): void {
    let groupId = -1;
    if (this.message.groupId && this.message.groupId !== null) {
      groupId = this.message.groupId;
    }
    for (const g of this.groups) {
      if (g.id === groupId) {
        this._selectedGroup = g;
      }
    }
    this.imageService.allowedImageExtensions().pipe(
      map((ext) => {
        this.fileUpload.allowedFileTypes = ext;
      }),
    );

    this.imageService.urlDefaultImage.subscribe((data) => {
      this.fileUpload.urlDefaultImage = data.protectedUri;
    });

    this.fileUpload.fileData = this.imageChanges.imageData;
    this.fileUpload.fileName = this.imageChanges.fileName;
    this.fileUpload.fileType = this.imageChanges.fileType;
    this.fileUpload.onFileUploaded.subscribe(() => this.fileUploadImageChanged());
    this.fileUpload.onFileDeleted.subscribe(() => this.fileUploadImageDeleted());
  }
  public showImageSizeWarning: boolean = false;

  @Input() message: CMSMessage;
  @Input() imageChanges: ImageChanges;
  @Input() groups: LabourConditionGroup[];
  @ViewChild("selectedImage") inputElementForImage;

  private _selectedTabIndex: number = 0;
  public get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }
  public set selectedTabIndex(newIndex: number) {
    this._selectedTabIndex = newIndex;
  }

  public get allowVisibilityEdit() {
    return PlUserStorage.hasRole(CmsRolesNamesDto.ArrangeDesigner);
  }

  public get aspectRatio(): AspectRatio {
    switch (this.message.messageType) {
      case MessageTypeDto.homepagebanner:
        return AspectRatio.STRETCHED;
      default:
        return AspectRatio.WIDE;
    }
  }

  public get additonalMessageClass() {
    if (this.message.messageType === MessageTypeDto.labourcondition) {
      return "lcDetail";
    }

    return "";
  }

  public get htmlText(): string {
    if (this.selectedTabIndex === 0) {
      return this.message.newsSummary[this.currentLanguage];
    } else {
      return this.message.newsContent[this.currentLanguage];
    }
  }

  public setHtmlText(newHtmlText: string) {
    if (this.selectedTabIndex === 0) {
      this.message.newsSummary[this.currentLanguage] = newHtmlText;
    } else {
      this.message.newsContent[this.currentLanguage] = newHtmlText;
    }
  }

  public get selectedGroup(): LabourConditionGroup {
    return this._selectedGroup;
  }
  public set selectedGroup(newGroup: LabourConditionGroup) {
    this._selectedGroup = newGroup;
    if (newGroup) {
      this.message.groupId = newGroup.id;
    } else {
      this.message.groupId = -1;
    }
  }
  private _selectedGroup: LabourConditionGroup;

  private _currentLanguage: string;
  public get currentLanguage(): string {
    return this._currentLanguage;
  }

  private fileUploadImageChanged() {
    this.imageChanges.setImage(this.fileUpload.fileName, this.fileUpload.fileType, this.fileUpload.fileData);
  }

  private fileUploadImageDeleted() {
    this.imageChanges.deleteImage();
    this.inputElementForImage.nativeElement.value = "";
  }

  public fileUpload: ImageFileupload;

  public languageChanged(newLanguage) {
    this._currentLanguage = newLanguage;
  }
}
