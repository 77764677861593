import { Observable, of, Subject, Subscription } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { PlBaseService } from "../baseservice/plbase.service";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { PlLanguageSelection } from "../language/pl-language-selection";
import { PlLoadIndicator } from "../loadindicator/loadindicator";
import { MenuItemDto } from "./../../common/models/dto/MenuItem-dto";
import { MenuItem } from "./models/menuitem";

@Injectable({
  providedIn: "root",
})
export class MenuService extends PlBaseService {
  private endPoint: string = "menu";
  private menuItems: MenuItem[] = undefined;

  private busyRetrievingMenu: boolean;
  private menuRetrievalSubject: Subject<MenuItem[]>;
  private subscriptions: Subscription = new Subscription();

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator, private languageSelection: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
    this.busyRetrievingMenu = false;
    this.menuRetrievalSubject = new Subject<MenuItem[]>();
    this.subscriptions.add(
      PlUserStorage.userChanged.subscribe(() => {
        console.log("User changed; reloading menu");
        this.forceReload();
      }),
    );
  }

  public forceReload() {
    this.menuItems = undefined;
    this.getMenuItems().subscribe();
  }

  public getMenuItems(): Observable<MenuItem[]> {
    if (this.menuItems) {
      return of(this.menuItems);
    } else {
      if (this.busyRetrievingMenu === false) {
        this.busyRetrievingMenu = true;

        this.getDataTransformed<MenuItemDto[]>(this.endPoint)
          // .subscribe(r => console.log(r));
          .pipe(
            map((r) => {
              this.menuItems = this.transformMenuItems(r);
              this.busyRetrievingMenu = false;

              this.menuRetrievalSubject.next(this.menuItems);
              return this.menuItems;
            }),
          )
          .subscribe((r) => {
            console.log("finished retrieving menu");
          });
        return this.menuRetrievalSubject;
      } else {
        return this.menuRetrievalSubject;
      }
    }
  }

  public getSubMenuItems(mainMenuId: any): Observable<MenuItem[]> {
    let result = this.getMenuItems();
    result = result.pipe(
      mergeMap((allMenuItems) => {
        const subMenuItems = allMenuItems.find((m) => m.id === mainMenuId);
        // we need this temp variable because we dont want to change this for Inspire/Imagine, this will resolve itself in 7.12
        const subMenuItemsTemp = allMenuItems.find((m) => m.route === mainMenuId);
        if (subMenuItems && subMenuItems !== null) {
          return of(subMenuItems.items);
        } else if (subMenuItemsTemp && subMenuItemsTemp !== null) {
          return of(subMenuItemsTemp.items);
        } else {
          console.log("Found no matching menu for: " + mainMenuId);
          return of([]);
        }
      }),
    );
    return result;
  }

  private transformMenuItems(dto: MenuItemDto[]): MenuItem[] {
    if (dto) {
      return this.dtoToMenuItems(dto);
    } else {
      return null;
    }
  }

  private dtoToMenuItems(jsonMenuItems: MenuItemDto[]): MenuItem[] {
    const result: MenuItem[] = [];

    if (jsonMenuItems && jsonMenuItems !== null) {
      jsonMenuItems.forEach((mi) => {
        if (mi !== null) {
          const name = mi.Title;
          const subItems = this.dtoToMenuItems(mi.Items);
          const menuItem = new MenuItem(mi.Id, name, mi.Route, mi.RequiredRoles, mi.Icon, subItems, mi.MenuType, mi.SortOrder, this.languageSelection);
          result.push(menuItem);
        }
      });
    }

    return result;
  }
}
