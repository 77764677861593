import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DxButtonModule } from "devextreme-angular";
import { TextPopupModule } from "../../../angular-common";
import { CardViewModule } from "../../../angular-common/components/cardview";
import { PlLoginModule } from "../../../angular-common/components/login/login.module";
import { LanguageModule } from "../language/language.module";
import { UserAcknowledgementModule } from "../user-acknowledgement/user-acknowledgement.module";
import { LoginNoUserDataComponent } from "./login-no-data-for-user.component";
import { LoginRoutingModule } from "./login-routing.module";
import { LoginWithDisclaimerComponent } from "./login-with-disclaimer.component";

@NgModule({
  declarations: [LoginNoUserDataComponent, LoginWithDisclaimerComponent],
  imports: [CommonModule, TextPopupModule, UserAcknowledgementModule, DxButtonModule, FormsModule, HttpClientModule, LoginRoutingModule, LanguageModule, RouterModule, PlLoginModule, CardViewModule],
  providers: [],
  exports: [LoginNoUserDataComponent],
})
export class EmployeeLoginModule {}
