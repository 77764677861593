import { Component } from "@angular/core";

@Component({
  selector: "app-pllogin",
  templateUrl: "./login.component.html",
  styleUrls: ["./_login.scss"],
})
export class PlLoginComponent {
  constructor() { }
}
