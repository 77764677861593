import { forkJoin, Observable } from "rxjs";

import { DomSanitizer } from "@angular/platform-browser";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { ImageChanges } from "../components/images/image-changes.model";
import { ImageService } from "../components/images/image.service";
import { LinkService } from "../components/internallinks/link.service";
import { Link } from "../components/links/link";
import { LinkTypeDto } from "../models/cms/dto/LinkType-dto";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";
import { CMSMessage } from "./cms-message.model";
import { CMSService } from "./cms.service";
import { CmsLanguage } from "./language/cmslanguage.service";

export class CMSMessageList {
  private cmsService: CMSService;
  private imageService: ImageService;
  private language: CmsLanguage;
  private domSanitizer: DomSanitizer;
  private languageSelection: PlLanguageSelection;
  private linkService: LinkService;

  public constructor(private allowEdit: boolean) {}

  public load(
    cmsService: CMSService,
    imageService: ImageService,
    language: CmsLanguage,
    linkService: LinkService,
    languageSelection: PlLanguageSelection,
    domSanitizer: DomSanitizer,
    messageType: MessageTypeDto,
  ) {
    this.loadServices(cmsService, imageService, language, linkService, languageSelection, domSanitizer);

    this.getMessages(messageType);
  }

  public loadWithIds(
    cmsService: CMSService,
    imageService: ImageService,
    language: CmsLanguage,
    linkService: LinkService,
    languageSelection: PlLanguageSelection,
    domSanitizer: DomSanitizer,
    messageType: MessageTypeDto,
    messageIds: number[],
  ) {
    this.loadServices(cmsService, imageService, language, linkService, languageSelection, domSanitizer);

    this.getMessagesWithIds(messageType, messageIds);
  }

  private loadServices(cmsService: CMSService, imageService: ImageService, language: CmsLanguage, linkService: LinkService, languageSelection: PlLanguageSelection, domSanitizer: DomSanitizer) {
    this.cmsService = cmsService;
    this.imageService = imageService;
    this.language = language;
    this.linkService = linkService;
    this.languageSelection = languageSelection;
    this.domSanitizer = domSanitizer;
  }

  public get messages(): CMSMessage[] {
    return this._messages;
  }
  public set messages(newMessages: CMSMessage[]) {
    this._messages = newMessages;
  }
  private _messages: CMSMessage[] = [];

  private compareMessage(a: CMSMessage, b: CMSMessage): number {
    if (a.id < 0) {
      return -1;
    }
    if (b.id < 0) {
      return 1;
    }

    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }

    return 0;
  }

  private sortMessages(messages: CMSMessage[]): CMSMessage[] {
    return messages.sort(this.compareMessage);
  }

  private resortMessages() {
    this.messages = this.sortMessages(this.messages);
  }

  private getMessages(messageType: MessageTypeDto) {
    const storeRetrievedMessagesFunc = (messages: CMSMessage[]) => {
      this.messages = this.sortMessages(messages);
    };

    if (this.allowEdit) {
      this.cmsService.getMessagesForEditing(messageType).subscribe(storeRetrievedMessagesFunc);
    } else {
      this.cmsService.getMessages(messageType).subscribe(storeRetrievedMessagesFunc);
    }
  }

  private getMessagesWithIds(messageType: MessageTypeDto, messageIds: number[]) {
    if (this.allowEdit) {
      throw new Error("Not implemented because we dont need it yet");
    }

    const observables = new Array<Observable<CMSMessage>>();
    for (const messageId of messageIds) {
      observables.push(this.cmsService.getMessage(messageType, messageId));
    }

    forkJoin(observables).subscribe((m) => (this.messages = this.sortMessages(m)));
  }

  addNewMessage(messageType: MessageTypeDto): void {
    const newLink = new Link(this.languageSelection);
    if (messageType === MessageTypeDto.labourcondition) {
      newLink.visible = true;
      newLink.linkType = LinkTypeDto.InternalLink;
      this.language.transferLanguageItem(this.language.getEntry(this.language.key_f3MeWebApiCMSMessageLinkLabourCondition), newLink.description);
    }
    const newMessage = CMSMessage.createEmpty(this.language, this.languageSelection, this.domSanitizer, messageType, newLink);
    this.messages.splice(0, 0, newMessage);
  }

  private deleteMessageFromList(message: CMSMessage) {
    const index = this.messages.indexOf(message, 0);
    if (index > -1) {
      this.messages.splice(index, 1);
    }
  }

  private deleteMessage(message: CMSMessage) {
    if (message.id >= 0) {
      this.imageService.deleteImage(message.originalImageUrl).subscribe(() => {
        this.cmsService.deleteMessage(message).subscribe(() => {
          this.deleteMessageFromList(message);
        });
      });
    } else {
      this.deleteMessageFromList(message);
    }
  }

  onDelete(event) {
    this.deleteMessage(event.message);
  }

  private processEditedMessage(editedMessage: CMSMessage) {
    if (editedMessage.id < 0) {
      this.cmsService.saveNewMessage(editedMessage).subscribe((createdMessage) => {
        this.cmsService.transofrmImageUrlForInternalAuthentication(editedMessage);
        editedMessage.id = createdMessage.id;
        editedMessage.location = createdMessage.location || createdMessage.location;
        editedMessage.link.id = createdMessage.link.id;
        this.resortMessages();
      });
    } else {
      this.cmsService.saveExistingMessage(editedMessage).subscribe(() => {
        this.cmsService.transofrmImageUrlForInternalAuthentication(editedMessage);
        this.resortMessages();
      });
    }
  }

  onEditingFinished(event) {
    if (event.cancelled && event.message.id < 0) {
      this.deleteMessage(event.message);
    } else if (event.cancelled === false) {
      const editedMessage = event.message as CMSMessage;
      const imageChanges = event.imageChanges as ImageChanges;
      const link = event.message.link;

      if (imageChanges.mustProcess === false) {
        // Image not edited, save message immediately
        this.processEditedMessage(editedMessage);
      } else {
        // Temporarily set image to an empty string, this forces a refresh after a new image is uploaded.
        // If we don't do that the imageURL stays the same and the new image isn't picked up by the UI.
        editedMessage.originalImageUrl = " ";
        this.imageService.processImageChanges(imageChanges).subscribe((imageUrl: string) => {
          editedMessage.originalImageUrl = imageUrl;

          if (editedMessage.hasImage == false) {
            editedMessage.imageLoaded = false;
            editedMessage.imageUrlProtected = undefined;
          }

          this.processEditedMessage(editedMessage);
        });
        this.linkService.storeLink(link).subscribe();
      }
    }
  }
}
