import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlLanguageSelector, PlLanguageService } from "../../../angular-common";

@Component({
  selector: "app-language-component",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
  providers: [],
})
export class LanguageComponent implements OnInit {
  private isDroppedDown: boolean = false;

  public toggleIsDroppedDown = (): boolean => (this.isDroppedDown = this.isDroppedDown ? false : true);

  public getIsDroppedDown = (): boolean => this.isDroppedDown;

  public constructor(public languageService: PlLanguageService) {}

  public ngOnInit(): void {
    if (this.languageService.languageSelector === null || this.languageService.languageSelector === undefined) {
      return;
    }

    this._languageSelector = new PlLanguageSelector(this.languageService.languages(), this.languageService.languageSelector.currentLanguage);
    this._languageSelector.languageChanged.subscribe((newValue) => {
      this.isDroppedDown = false;
      this.languageChange.emit(newValue);
    });
  }

  private _languageSelector: PlLanguageSelector;

  public get languageSelector(): PlLanguageSelector {
    return this._languageSelector;
  }

  @Output() languageChange = new EventEmitter();
  @Input() language: string;

  public get numberOfLanguages(): number {
    if (this.languageSelector && this.languageSelector !== null) {
      return this.languageSelector.languages.length;
    }
    return 0;
  }
}
