<div class="insufficient-rights">
  <app-cardview [title]="language.error_insufficientrights_title">
    <div>
      <p *ngIf="userStatusChecked === false">{{ language.error_insufficientrights_checkSession }}</p>
    </div>

    <div *ngIf="userStatusChecked">
      <p class="error" *ngIf="userIsValid === false">{{ language.error_insufficientrights_checkSessionExpired }}</p>

      <div *ngIf="userIsValid">
        <p>{{ language.error_insufficientrights_checkSessionValid }}</p>
        <div class="error">
          <p>{{ language.error_insufficientrights_text }}</p>
          <p>{{ errorText }}</p>
        </div>
        <div>
          <p>{{ language.accountClaimsInvestigate }}</p>
          <dx-button [text]="language.accountClaimsTitle" (onClick)="gotoClaims()"></dx-button>
        </div>
      </div>
    </div>
    <div *ngIf="userHasData">
      <p>{{ language.loginSignoutForDifferentUser }}</p>
      <dx-button [text]="language.loginSignOut" (onClick)="gotoSignout()"></dx-button>
    </div>
  </app-cardview>
</div>
