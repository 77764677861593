import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CMSModule } from "../cms.module";
import { WhatIfComponent } from "./what-if.component";

@NgModule({
  imports: [CommonModule, FormsModule, CMSModule],
  declarations: [WhatIfComponent],
  exports: [WhatIfComponent],
})
export class WhatIfModule {}
