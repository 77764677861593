import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LogDownloadMetaDataDto } from "../../../../common/models/dto/LogDownloadMetaData-dto";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { PlBaseService } from "../../../baseservice/plbase.service";
import { PlLoadIndicator } from "../../../loadindicator/loadindicator";

@Injectable()
export class LogDownloadService extends PlBaseService {
  private endPoint: string = "logdownload";

  public get latestLogItemId(): string {
    return "Latest";
  }

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getMetaData(): Observable<LogDownloadMetaDataDto> {
    return this.getData<LogDownloadMetaDataDto>(this.endPoint);
  }

  public getLogItem(name: string, maxLen: number): Observable<string> {
    let url: string;
    if (maxLen) {
      url = this.endPoint + "/" + encodeURI(name) + "/?maxLen=" + maxLen;
    } else {
      url = this.endPoint + "/" + encodeURI(name) + "/";
    }
    return this.getRawData(url);
  }

  public getDirectLink(historyItem: string): string {
    return this.envSettings.getUri(this.endPoint + "/" + encodeURI(historyItem) + "/");
  }
}
