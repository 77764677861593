import { Component, OnInit } from "@angular/core";
import { ModelElementSpecification } from "../../../../../common/modelelements/modelelementsspecification";
import { CmsSimEditMove } from "../../../../cms/edit/cms-sim-edit-move";
import { CmsLanguage } from "../../../../cms/language/cmslanguage.service";
import { UserEmployeeMappingService } from "../user-employee-mapping.service";

@Component({
  selector: "app-me-user-employee-screen",
  templateUrl: "user-employee-mapping-configuration.component.html",
  styleUrls: ["user-employee-mapping-configuration.component.scss"],
})
export class UserEmployeeMappingConfigurationComponent implements OnInit {
  public allElements: ModelElementSpecification[] = [];
  public allAvailableElements: ModelElementSpecification[] = [];
  public originalConfig: ModelElementSpecification[] = [];
  public currentConfig: ModelElementSpecification[] = [];
  public displayValue = "displayName";
  public searchValue = "displayName";
  private _moveImplementation: CmsSimEditMove;
  private _sortOrderChanged: boolean = false;

  constructor(public language: CmsLanguage, private userToEmployeeMappingService: UserEmployeeMappingService) {}

  public get moveImplementation() {
    return this._moveImplementation;
  }

  ngOnInit(): void {
    this.getAllElementsAndCurrentConfig();
  }

  public configSortOrderChanged(data: ModelElementSpecification[]) {
    this._sortOrderChanged = true;
    this.updateSortOrder(data, this.currentConfig);
  }

  private updateSortOrder(newData: ModelElementSpecification[], currentData: ModelElementSpecification[]) {
    for (let index = 0; index < newData.length; index++) {
      const element = newData[index];
      currentData.find((x) => x.keyId === element.keyId).sortOrder = index;
    }
  }

  private createCopyOfOriginalConfig() {
    const originalConfig: ModelElementSpecification[] = [];
    this.currentConfig.forEach((e) => {
      originalConfig.push(e);
    });
    return originalConfig;
  }

  private changeDetected(usedOptions: ModelElementSpecification[], allOptions: ModelElementSpecification[]) {
    return ModelElementSpecification.filterAvailableOptions(usedOptions, allOptions);
  }

  getAllElementsAndCurrentConfig() {
    this.userToEmployeeMappingService.retrieveConfiguration().subscribe((data) => {
      this.allElements = data.availableOptions;
      this.currentConfig = data.currentConfiguration;
      this.originalConfig = this.createCopyOfOriginalConfig();
      this.configAvailableOptions();
    });
  }

  configChanged() {
    this.allAvailableElements = this.changeDetected(this.currentConfig, this.allElements);
  }

  private configAvailableOptions() {
    this.configChanged();
    this._moveImplementation = new CmsSimEditMove(this.currentConfig);
  }

  public get hasChanges(): boolean {
    if (this.originalConfig && this.currentConfig) {
      if (this.currentConfig.length !== this.originalConfig.length || this._sortOrderChanged === true) {
        return true;
      }
      for (let i = 0; i < this.currentConfig.length; i++) {
        if (this.currentConfig[i] !== this.originalConfig[i]) {
          return true;
        }
      }
    }
    return false;
  }

  onSave() {
    this.userToEmployeeMappingService.saveConfiguration(this.currentConfig).subscribe((r) => {});
    this.originalConfig = this.createCopyOfOriginalConfig();
    this._sortOrderChanged = false;
  }
}
