import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { ExogenousInputChoiceOptionValueDto } from "../../../../models/me/dto/ExogenousInputChoiceOptionValue-dto";

export class ExogenousInputOptionChoiceValue {
  public constructor() {}

  public identification: number;
  public text: string;

  public copyFromDTO(dto: ExogenousInputChoiceOptionValueDto) {
    if (ElementHelper.isNullOrUndefined(dto) === false) {
      this.identification = dto.Identification;
      this.text = dto.Text;
    }
  }
}
