import { Component, Input } from "@angular/core";

import { IAppComponentSpecificComponent } from "./i-appcompnentspecific-component";

@Component({
  template: ` <div [class]="className"></div> `,
})
export class AppComponentSpecificEmptyComponent implements IAppComponentSpecificComponent {
  @Input() data: any;
  @Input() typeName: string;

  public get className() {
    return this.typeName + "-empty";
  }
}
