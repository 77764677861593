import { LinkDto } from './Link-dto';
import { MessageOptionsDto } from './MessageOptions-dto';
import { MessageTypeDto } from './MessageType-dto';

export declare type CmsMessageTitleDto = [string, string];
export declare type CmsMessageSummaryDto = [string, string];
export declare type CmsMessageContentDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file CmsMessage.cs
// Don't adjust manually!

export class CmsMessageDto {
    public Id: number;
    public MessageType: MessageTypeDto;
    public Location: string;
    public Date: Date;
    public ImageURL: string;
    public Title: CmsMessageTitleDto;
    public Summary: CmsMessageSummaryDto;
    public Content: CmsMessageContentDto;
    public Link: LinkDto;
    public EmailTemplateId: number;
    public Options: MessageOptionsDto;
    public GroupId: number;
    public VisibilityExpression: string;
    public Visible: boolean = true;
}

