<div *ngIf="combinedOptions" class="maindiv">
  <div *ngFor="let combinedOption of combinedOptions">
    <div [id]="combinedOption.option.shortName" class="option">
      <!-- General info about the option -->
      <div class="grid-container">
        <div class="optionDescr">
          <p>
            {{ combinedOption.option.description }}
            <label *ngIf="combinedOption.currentValue" class="optionDescrEmbedded">{{ combinedOption.currentValueString }}</label>
          </p>
        </div>

        <!-- Current value -->
        <div class="optionCurrentValue">
          <div *ngIf="combinedOption.currentValue">
            <p>{{ combinedOption.currentValue.value }}</p>
          </div>
        </div>
        <!-- Specific input control(s) -->
        <app-exogeous-input-option class="optionValue" [valueOnly]="true" [option]="combinedOption.option"></app-exogeous-input-option>
      </div>
    </div>
  </div>
</div>
