import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { UserToEmployeeLoginInformationDto } from "../../models/me/dto/UserToEmployeeLoginInformation-dto";
import { UserEmployeeMappingService } from "../mapping/prefered-user-employee-mapping/user-employee-mapping.service";
import { PreferedUserToEmployeeLoginService } from "./prefered-user-employee-login.service";

@Component({
  selector: "app-user-employee-login",
  templateUrl: "prefered-user-employee-login.component.html",
  styleUrls: ["prefered-user-employee-login.component.scss"],
})
export class PreferedUserEmployeeLoginComponent implements OnInit, OnDestroy {
  constructor(private preferedUserToEmployeeLoginService: PreferedUserToEmployeeLoginService, private userEmployeeMappingService: UserEmployeeMappingService, public language: CmsLanguage) {}

  private mappingChangesSubscription: Subscription = null;

  public loadedData: Boolean = false;
  public _userEmployeeLoginInfo: UserToEmployeeLoginInformationDto;

  public get userEmployeeLoginInfo(): UserToEmployeeLoginInformationDto {
    return this._userEmployeeLoginInfo;
  }

  public set userEmployeeLoginInfo(value: UserToEmployeeLoginInformationDto) {
    this._userEmployeeLoginInfo = value;
  }

  ngOnInit(): void {
    this.subscribeToMappingChanges();
    this.retrieveData();
  }

  ngOnDestroy(): void {
    if (this.mappingChangesSubscription) {
      this.mappingChangesSubscription.unsubscribe();
    }
  }

  private retrieveData(): void {
    PlUserStorage.userChanged.subscribe((user) => {
      if (user && user.DataInSystem) {
        this.preferedUserToEmployeeLoginService.retrieveUserEmployeeLoginInformation().subscribe((result) => {
          this.userEmployeeLoginInfo = result;
          this.loadedData = true;
        });
      }
    });
  }

  private subscribeToMappingChanges() {
    this.mappingChangesSubscription = this.userEmployeeMappingService.mappingChanged.subscribe((hasMappingChanged) => {
      if (hasMappingChanged === true) {
        this.loadedData = false;
        this.retrieveData();
      }
    });
  }
}
