<span class="avatarIcon" (click)="togglePMenuState()" [class.closeButton]="getPMenuVisibility()">
  <i class="far fa-user fa-2x"></i>
</span>

<div *ngIf="user" class="personalMenuWrapper" [class.in]="getPMenuVisibility()" id="bs-example-navbar-collapse-1">
  <div class="avatar">
    <figure>
      <img *ngIf="urlMugShot.isLoaded" [src]="urlMugShot.protectedUri" />
    </figure>
  </div>

  <div class="personalMenu" [class.in]="getPMenuVisibility()" id="bs-example-navbar-collapse-1">
    <ul *ngFor="let menuItem of menuItems">
      <li>
        <a (click)="goTo(menuItem)">{{ menuItem.displayTitle }}</a>
      </li>
    </ul>
  </div>
  <div class="userLanguage">
    <app-language-selection></app-language-selection>
  </div>

  <div class="userName">{{ user.ShortName | Initials }} {{ user.LongName }}</div>
</div>
