import { Component, Input } from "@angular/core";

import { IChoiceSelectionViewComponent } from "./i-choice-selection-view-component";
import { IChoiceSelectionViewData } from "./i-choice-selection-view-data";

@Component({
  template: ` <div class="no-choices"></div> `,
})
export class ChoiceSelectionNoneComponent implements IChoiceSelectionViewComponent {
  @Input()
  data: IChoiceSelectionViewData;
}
