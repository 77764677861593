<div *ngIf='items'>
    <app-language-component (languageChange)="languageChanged($event)"></app-language-component>
    <div class="tablediv">
        <ul class="list">
            <li *ngFor="let item of items">
                <app-languageedititem-component [item]="item" [currentLanguage]="currentLanguage">
                </app-languageedititem-component>
            </li>
        </ul>
    </div>
</div>