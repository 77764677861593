import { Observable, Subject } from "rxjs";

import { BrowserStorage } from "../../../angular-common/storage/browser-storage";

export class EmployeeGridDateFilter {
  private static readonly storageFromDateKey = "approveTargetsFrom";
  private static readonly storageToDateKey = "approveTargetsTo";

  public constructor() {
    const today = new Date();
    this.dateFrom = this.loadDateFromLocalStorage(EmployeeGridDateFilter.storageFromDateKey, new Date(today.setDate(today.getDate() - 7)));
    this.dateTo = this.loadDateFromLocalStorage(EmployeeGridDateFilter.storageToDateKey, new Date());

    this._onChanged = new Subject<void>();
    this.onChanged = this._onChanged.asObservable();

    this._onReset = new Subject<void>();
    this.onReset = this._onReset.asObservable();
  }

  public get dateFrom(): string | number | Date {
    return this._dateFrom;
  }
  public set dateFrom(newDate: string | number | Date) {
    this._dateFrom = new Date((newDate as Date).setHours(0, 0, 0, 0));
    BrowserStorage.setDate(EmployeeGridDateFilter.storageFromDateKey, this._dateFrom);
  }
  private _dateFrom: Date;

  public get dateTo(): string | number | Date {
    return this._dateTo;
  }
  public set dateTo(newDate: string | number | Date) {
    this._dateTo = new Date((newDate as Date).setHours(0, 0, 0, 0));
    BrowserStorage.setDate(EmployeeGridDateFilter.storageToDateKey, this._dateTo);
  }
  private _dateTo: Date;

  private loadDateFromLocalStorage(key: string, defaultValue: Date): Date {
    return BrowserStorage.getDate(key, defaultValue);
  }

  public applyFilter() {
    this.fireOnChanged();
  }

  public resetFilter() {
    if (this._onReset) {
      this._onReset.next();
    }
  }

  public get isValid(): boolean {
    return this.dateTo >= this.dateFrom;
  }

  private fireOnChanged() {
    if (this._onChanged) {
      this._onChanged.next();
    }
  }
  private _onChanged: Subject<void>;
  public onChanged: Observable<void>;

  private _onReset: Subject<void>;
  public onReset: Observable<void>;
}
