import { ExogenousInputBoolOptionDto } from "../../../../models/me/dto/ExogenousInputBoolOption-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";
import { ExogenousInputOptionType } from "./option-type.model";

export class ExogenousInputOptionBool extends ExogenousInputOption {
  public constructor() {
    super(ExogenousInputOptionType.Bool);
  }

  private _value: boolean;
  public get value(): boolean {
    return this._value;
  }
  public set value(newValue: boolean) {
    this._value = newValue as boolean;
    this.changed();
  }

  public copyFromDTO(dto: ExogenousInputBoolOptionDto) {
    super.copyFromDTO(dto);

    this._value = dto.Value;
  }

  public toDTO(dto: ModifiedExogenousInputOptionDto) {
    super.toDTO(dto);
    dto.Type = ExogenousInputOptionTypes.typeBool;
    dto.Value = this.value.toString();
  }
}
