import { EnvironmentSettings } from "../../../../../angular-common/baseservice/environment-settings";
import { FileUpload } from "../../../../../angular-common/components/fileupload/fileupload";
import { ExogenousInputAttachmentOptionDto } from "../../../../models/me/dto/ExogenousInputAttachmentOption-dto";
import { ModifiedExogenousInputOptionAttachmentDto } from "../../../../models/me/dto/ModifiedExogenousInputOptionAttachment-dto";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";
import { ExogenousInputOptionType } from "./option-type.model";

export class ExogenousInputOptionAttachment extends ExogenousInputOption {
  private fileTypeAllowed: string[] = [];

  public constructor() {
    super(ExogenousInputOptionType.Attachment);

    const envSetting = new EnvironmentSettings();
    this.fileUpload = new FileUpload(envSetting.maxUploadSizeMb, this.fileTypeAllowed);
    this.fileUpload.onFileUploaded.subscribe(() => this.changed());
  }

  public copyFromDTO(dto: ExogenousInputAttachmentOptionDto) {
    super.copyFromDTO(dto);

    this.fileUpload.fileName = dto.FileName;
    if (dto.MaxSizeKb && dto.MaxSizeKb != null) {
      this.fileUpload.maxFileSizeMb = dto.MaxSizeKb;
    }
    if (dto.AllowedFileTypes.length !== this.fileTypeAllowed.length) {
      this.fileTypeAllowed.splice(0, this.fileTypeAllowed.length);
      dto.AllowedFileTypes.forEach((a) => {
        this.fileTypeAllowed.push(a);
      });
    }
  }

  public fileUpload: FileUpload;

  public toDTO(dto: ModifiedExogenousInputOptionAttachmentDto) {
    super.toDTO(dto);

    dto.Type = ExogenousInputOptionTypes.typeAttachment;
    dto.Value = this.fileUpload.fileName;
    dto.FileData = this.fileUpload.fileData;
  }
}
