
// t4tsCode gen": "0.8.2.0 for file GroupOptions.cs
// Don't adjust manually!
export enum GroupOptionsDto {
        None = 0,
        Read = 1,
        Write = 2,
        Delete = 4,
}

