// This class is specific to DevExpress, we use it to place large chunks of data in a combobox for instance. Do not change the naming of
// the properties, DevExpress expects them.
export class PaginatedDxData {
  public constructor(data: any) {
    this.store = data;
  }

  public paginate: boolean = true;
  public pageSize: number = 20;
  public store: any;
}
