import { LanguageDictionary, LanguageItem } from "../../../angular-common/language/language-dto.model";

import { Injectable } from "@angular/core";
import { PlLanguageService } from "../../../angular-common";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageData } from "../../../angular-common/language/pllanguagedata.model";
import { LanguageBuilder } from "../../../common/language/language-builder";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { PersonlizedLanguageService } from "./personlized-language.service";

// Note; this class is intended to be the layer below the generated language strings.
// It includes all code changes required for the inspire edit part to work.
@Injectable()
export class LanguageServiceBase extends WebLanguage {
  constructor(plLanguageService: PlLanguageService, public languageSelection: PlLanguageSelection, private personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService);
  }

  public languageBuilder() {
    return new LanguageBuilder(this.languageSelection);
  }

  loadInspireLanguage(): Promise<PlLanguageData> {
    const promise = this.loadStaticLanguage();

    PlUserStorage.userChanged.subscribe((user) => {
      console.log("user changed; reloading personalized language");
      this.personlizedLanguageService
        .getEntries()
        .toPromise()
        .then((result) => {
          console.log("Reloaded personalized language.");
          this.personlizedLanguageService.addPersonalizedText(result.Entries);
          this.basicLanguage.setLanguageMetadata(result.MetaData.Languages);
        });
    });

    promise.then((prom) => {
      console.log("Begin loading personalized language.");
      if (PlUserStorage.hasUser()) {
        this.personlizedLanguageService
          .getEntries()
          .toPromise()
          .then((result) => {
            console.log("Reloaded personalized language.");
            this.personlizedLanguageService.addPersonalizedText(result.Entries);
          });
      } else {
        console.log("skip loading personalized language; no user yet.");
      }
    });

    return promise;
  }

  public addPersonalizedText(personalizedTexts: LanguageDictionary) {
    this.personlizedLanguageService.addPersonalizedText(personalizedTexts);
  }

  public removePersonalizedText() {
    this.personlizedLanguageService.removePersonalizedText();
  }

  public getStringDef(id: string, defaultValue?: string) {
    const currentLanguage = this.languageSelection.currentLanguage;

    const personlizedText = this.personlizedLanguageService.getStringDef(id, currentLanguage);

    if (personlizedText) {
      return personlizedText;
    } else {
      return this.getStringDefInternal(id, defaultValue);
    }
  }

  public getEntry(id: string): LanguageItem {
    const personlizedText = this.personlizedLanguageService.getEntry(id);
    if (personlizedText) {
      return personlizedText;
    } else {
      return this.languageBuilder().createLanguageItemWithValue(super.getStringDef(id, id));
    }
  }

  public transferLanguageItem(source: LanguageItem, target: LanguageItem) {
    // eslint-disable-next-line guard-for-in
    for (const key in source) {
      target[key] = source[key];
    }
  }
}
