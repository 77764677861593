
export declare type EbsConfigurationDtoCmsTitleDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file EbsConfigurationDto.cs
// Don't adjust manually!

export class EbsConfigurationDto {
    public Id: number;
    public ShortName: string = "";
    public VariantId: number;
    public CmsGroupingId: number;
    public IsSummery: boolean = false;
    public CmsMessageId: number;
    public CmsTitle: EbsConfigurationDtoCmsTitleDto;
}

