import { Component, Input } from "@angular/core";

import { TextPopupInteraction } from "./textpopup-interaction";

@Component({
  selector: "app-textpopup-component",
  templateUrl: "./textpopup.component.html",
  styleUrls: ["./textpopup.component.scss"],
  providers: [],
})
export class TextPopupComponent {
  private static createDefault() {
    return new TextPopupInteraction("DefaultTitle", "DefaultMessage", "DefaultOk", "DefaultCancel");
  }

  public constructor() {
    this._interaction = TextPopupComponent.createDefault();
  }

  public get hasShortMessage() {
    if (!this.interaction.showTextInput) {
      if (this.interaction.message && this.interaction.message.length < 100) {
        return true;
      }
    }
    return false;
  }

  @Input()
  public get interaction(): TextPopupInteraction {
    return this._interaction;
  }
  public set interaction(newInteraction: TextPopupInteraction) {
    if (newInteraction === undefined || newInteraction === null) {
      this._interaction = TextPopupComponent.createDefault();
    } else {
      this._interaction = newInteraction;
    }
  }
  private _interaction: TextPopupInteraction = null;

  public get popupWidth() {
    let result = 550;
    if (result > window.screen.width - 10) {
      result = window.screen.width - 10;
    }
    return result;
  }
}
