import { ExogenousInputTextOptionDto } from "../../../../models/me/dto/ExogenousInputTextOption-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";
import { ExogenousInputOptionType } from "./option-type.model";

export class ExogeousInputOptionText extends ExogenousInputOption {
  public constructor() {
    super(ExogenousInputOptionType.Text);
  }

  private _value: string;
  public get value(): string {
    return this._value;
  }
  public set value(newValue: string) {
    this._value = newValue;
    this.changed();
  }

  public maxLength: number;

  public copyFromDTO(dto: ExogenousInputTextOptionDto) {
    super.copyFromDTO(dto);

    this._value = dto.Value;
    this._previousValue = this._value;
    if (dto.MaxLength > 0) {
      this.maxLength = dto.MaxLength;
    } else {
      // Should be long enough for anyone, unless they want to input their life story or something.
      this.maxLength = 2048;
    }
  }

  public toDTO(dto: ModifiedExogenousInputOptionDto) {
    super.toDTO(dto);
    dto.Type = ExogenousInputOptionTypes.typeText;
    dto.Value = this.value;
  }

  public changed() {
    if (this._value !== this._previousValue) {
      super.changed();
      this._previousValue = this._value;
    }
  }

  private _previousValue: string;
}
