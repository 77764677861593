import { EbsChartItem } from "../_models/ebs-chart-item.model";
import { EbsResult } from "../_models/ebs-result.model";

export class EbsOverviewData {
  ebsResult: EbsResult;
  palette: string[];
  groupsForChart: EbsChartItem[];
  cmsTitel: string;
  cmsIntro: string;

  constructor(ebsResult: EbsResult, palatte: string[], groupForChart: EbsChartItem[], cmsTitle: string, cmsIntro: string) {
    this.ebsResult = ebsResult;
    this.palette = palatte;
    this.groupsForChart = groupForChart;
    this.cmsIntro = cmsIntro;
    this.cmsTitel = cmsTitle;
  }
}
