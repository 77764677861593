import {
  AuthenticationGuardModule,
  AuthenticationModule,
  ErrorInsufficientRightsComponent,
  LoginLandingPageComponent,
  LoginLandingPageModule,
  LoginModule,
  LoginSignoutComponent,
  UserClaimsComponent,
} from ".";
import { RouterModule, Routes } from "@angular/router";

import { CommonRoutesDto } from "../common/models/dto/CommonRoutes-dto";
import { NgModule } from "@angular/core";
import { UserInformationScreenComponent } from "./components/user-information-screen/user-information-screen.component";
import { UserInformationScreenModule } from "./components/user-information-screen/user-information-screen.module";

const routes: Routes = [
  { path: CommonRoutesDto.InsufficientRights, component: ErrorInsufficientRightsComponent },
  { path: CommonRoutesDto.SignOff, component: LoginSignoutComponent },
  { path: CommonRoutesDto.Claims, component: UserClaimsComponent },
  { path: CommonRoutesDto.LandingPage, component: LoginLandingPageComponent },
  { path: CommonRoutesDto.UserInformationEdit, component: UserInformationScreenComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AuthenticationGuardModule, AuthenticationModule, LoginModule],
  exports: [AuthenticationGuardModule, AuthenticationModule, LoginModule, LoginLandingPageModule, UserInformationScreenModule],
})
export class CommonAuthConfigurationModule {}
