import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxGalleryModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTextBoxModule,
} from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CardViewModule } from "../../angular-common/components/cardview/cardview.module";
import { HtmlEditorModule } from "../../angular-common/components/htmleditor/htmleditor.module";
import { PlImageCropperModule } from "../../angular-common/components/image-cropper/image-cropper.module";
import { ListSelectorModule } from "../../angular-common/components/listselector/listselector.module";
import { SafeDataDirectiveModule } from "../../angular-common/utils/safe-data-directive-module";
import { InspireCmsLinkModule } from "../../f3Me/src/app/shared/links/inspire-cms-link.module";
import { AppComponentSpecificViewModule } from "../components/appcomponentspecific-content/appcomponentspecific-view-module";
import { EditToggleModule } from "../components/edittoggle/edittoggle.module";
import { ImageModule } from "../components/images/image.module";
import { LinkService } from "../components/internallinks/link.service";
import { LanguageServiceBase } from "../components/language/language-base.service";
import { LanguageModule } from "../components/language/language.module";
import { LanguageItemEditorModule } from "../components/language/languageitem-editor/languageitem-editor.module";
import { EmployeeApplicationConfiguration } from "../employee-application-configuration";
import { RatingViewComponent } from "../rating/view/rating-view.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { CmsFeaturesModel } from "./cms-features-model";
import { CmsRoutingModule } from "./cms-routing.module";
import { CMSComponent } from "./cms.component";
import { CMSService } from "./cms.service";
import { CMSEditComponent } from "./edit/cms-edit.component";
import { CmsImageEditEditComponent } from "./edit/cms-image-edit-edit.component";
import { CmsLinkEditEditComponent } from "./edit/cms-link-edit-edit.component";
import { CMSMessageEditDisplayComponent } from "./edit/cms-message-edit-display.component";
import { CMSMessageEditEditComponent } from "./edit/cms-message-edit-edit.component";
import { CMSMessageEditComponent } from "./edit/cms-message-edit.component";
import { HomePageBannerMessageComponent } from "./home-banner/homepagebanner-message.component";
import { HomePageBannerComponent } from "./home-banner/homepagebanner.component";
import { LabourgroupService } from "./labourgroups/labourgroup.service";
import { CmsLanguage } from "./language/cmslanguage.service";
import { LanguageEditComponent } from "./languagedit/languageedit.component";
import { LanguageEditModule } from "./languagedit/languageedit.module";
import { CmsLinkModule } from "./links/cms-link.module";
import { NewsMessageComponent } from "./news/news-message.component";
import { NewsComponent } from "./news/news.component";
import { SimulationBlockMessageComponent } from "./simulationblock/simulationblock-message.component";
import { SimulationBlockComponent } from "./simulationblock/simulationblock.component";

@NgModule({
  declarations: [
    CMSComponent,
    NewsComponent,
    NewsMessageComponent,
    CMSEditComponent,
    CMSMessageEditComponent,
    CMSMessageEditDisplayComponent,
    CmsImageEditEditComponent,
    CmsLinkEditEditComponent,
    CMSMessageEditEditComponent,
    SimulationBlockComponent,
    SimulationBlockMessageComponent,
    CarouselComponent,
    RatingViewComponent,
    HomePageBannerComponent,
    HomePageBannerMessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ImageModule,
    EditToggleModule,
    HtmlEditorModule,
    LanguageItemEditorModule,
    ListSelectorModule,
    CardViewModule,
    DxButtonModule,
    DxTabsModule,
    DxDateBoxModule,
    DxTextBoxModule,
    DxRadioGroupModule,
    DxCheckBoxModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxPopupModule,
    EmployeeApplicationConfiguration.isInspire ? InspireCmsLinkModule : CmsLinkModule,
    DxGalleryModule,
    LanguageModule,
    PlImageCropperModule,
    AppComponentSpecificViewModule,
    SafeDataDirectiveModule,
    LanguageEditModule,
  ],
  providers: [CMSService, LinkService, LabourgroupService, CmsLanguage, CmsFeaturesModel, { provide: LanguageServiceBase, useExisting: LanguageServiceBase }],
  exports: [CMSComponent, LanguageEditComponent, CmsImageEditEditComponent, CmsLinkEditEditComponent, CmsRoutingModule, RatingViewComponent],
})
export class CMSModule {
  constructor() {}
}
