
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { PlLanguageService } from "../../angular-common/language/pllanguage.service";
import { PlLanguageData } from '../../angular-common/language/pllanguagedata.model';

@Injectable()
// t4Languages": "0.8.2.0
export class WebLanguage {
  private _loaded: boolean;

  public constructor(private plLanguageService: PlLanguageService) {
    this._loaded = false;
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  public async loadStaticLanguage(): Promise<PlLanguageData> {
    if (this._loaded === false) {
      const x = await this.plLanguageService.loadLanguage("staticlanguage");
      this._loaded = true;
      return x;
    } else {
      return this.plLanguageService.currentLanguageData;
    }
  }

  public get basicLanguage(): PlLanguageService {
    return this.plLanguageService;
  }

  private getString(key: string, defaultText: string) {
    return this.plLanguageService.getString(key, defaultText);
  }

  public getStringDef(id: string, defaultValue: string): string {
    return this.getStringDefInternal(id, defaultValue);
  }

  protected getStringDefInternal(id: string, defaultValue: string): string {
    let result = this.getString(id, defaultValue);
    if (result === undefined || result === null) {
      return "? " + defaultValue;
    }

    if (environment.production === false) {
      result = "* " + result;
    }

    return result;
  }

  /**
   * applicationTitle from f3Web.csv (in EN: Pointlogic )
   */
  public key_applicationTitle = "applicationTitle";
  /**
   * applicationTitle from f3Web.csv (in EN: Pointlogic )
   */
  public get applicationTitle(): string { return this.getStringDef(this.key_applicationTitle, "Pointlogic"); }

  /**
   * errorTitle from f3Web.csv (in EN: Pointlogic - An error occured )
   */
  public key_errorTitle = "errorTitle";
  /**
   * errorTitle from f3Web.csv (in EN: Pointlogic - An error occured )
   */
  public get errorTitle(): string { return this.getStringDef(this.key_errorTitle, "Pointlogic - An error occured"); }

  /**
   * errorSubtitle from f3Web.csv (in EN: An error occured )
   */
  public key_errorSubtitle = "errorSubtitle";
  /**
   * errorSubtitle from f3Web.csv (in EN: An error occured )
   */
  public get errorSubtitle(): string { return this.getStringDef(this.key_errorSubtitle, "An error occured"); }

  /**
   * errorMessage from f3Web.csv (in EN: We are sorry, an error occurred. Please try again, if this problems keeps occurring please contact support. )
   */
  public key_errorMessage = "errorMessage";
  /**
   * errorMessage from f3Web.csv (in EN: We are sorry, an error occurred. Please try again, if this problems keeps occurring please contact support. )
   */
  public get errorMessage(): string { return this.getStringDef(this.key_errorMessage, "We are sorry, an error occurred. Please try again, if this problems keeps occurring please contact support."); }

  /**
   * errorCouldnotconnecttoserver from f3Web.csv (in EN: Failed to connect to the server )
   */
  public key_errorCouldnotconnecttoserver = "errorCouldnotconnecttoserver";
  /**
   * errorCouldnotconnecttoserver from f3Web.csv (in EN: Failed to connect to the server )
   */
  public get errorCouldnotconnecttoserver(): string { return this.getStringDef(this.key_errorCouldnotconnecttoserver, "Failed to connect to the server"); }

  /**
   * accountLoggedOutTitle from f3Web.csv (in EN: Logged out )
   */
  public key_accountLoggedOutTitle = "accountLoggedOutTitle";
  /**
   * accountLoggedOutTitle from f3Web.csv (in EN: Logged out )
   */
  public get accountLoggedOutTitle(): string { return this.getStringDef(this.key_accountLoggedOutTitle, "Logged out"); }

  /**
   * accountLoggedOutAction from f3Web.csv (in EN: press here to login again )
   */
  public key_accountLoggedOutAction = "accountLoggedOutAction";
  /**
   * accountLoggedOutAction from f3Web.csv (in EN: press here to login again )
   */
  public get accountLoggedOutAction(): string { return this.getStringDef(this.key_accountLoggedOutAction, "press here to login again"); }

  /**
   * accountLoggedOutSubtitle from f3Web.csv (in EN: You have been logged out )
   */
  public key_accountLoggedOutSubtitle = "accountLoggedOutSubtitle";
  /**
   * accountLoggedOutSubtitle from f3Web.csv (in EN: You have been logged out )
   */
  public get accountLoggedOutSubtitle(): string { return this.getStringDef(this.key_accountLoggedOutSubtitle, "You have been logged out"); }

  /**
   * accountLoggedOutMessage from f3Web.csv (in EN: You have been logged out, you can either login again by following the link below. )
   */
  public key_accountLoggedOutMessage = "accountLoggedOutMessage";
  /**
   * accountLoggedOutMessage from f3Web.csv (in EN: You have been logged out, you can either login again by following the link below. )
   */
  public get accountLoggedOutMessage(): string { return this.getStringDef(this.key_accountLoggedOutMessage, "You have been logged out, you can either login again by following the link below."); }

  /**
   * accountLoginTitle from f3Web.csv (in EN: Pointlogic - Login )
   */
  public key_accountLoginTitle = "accountLoginTitle";
  /**
   * accountLoginTitle from f3Web.csv (in EN: Pointlogic - Login )
   */
  public get accountLoginTitle(): string { return this.getStringDef(this.key_accountLoginTitle, "Pointlogic - Login"); }

  /**
   * changePersonalData from f3Web.csv (in EN: Personal data )
   */
  public key_changePersonalData = "changePersonalData";
  /**
   * changePersonalData from f3Web.csv (in EN: Personal data )
   */
  public get changePersonalData(): string { return this.getStringDef(this.key_changePersonalData, "Personal data"); }

  /**
   * accountSettingsTitle from f3Web.csv (in EN: Pointlogic - Personal data )
   */
  public key_accountSettingsTitle = "accountSettingsTitle";
  /**
   * accountSettingsTitle from f3Web.csv (in EN: Pointlogic - Personal data )
   */
  public get accountSettingsTitle(): string { return this.getStringDef(this.key_accountSettingsTitle, "Pointlogic - Personal data"); }

  /**
   * landingpageTitle from f3Web.csv (in EN: Landingspage )
   */
  public key_landingpageTitle = "landingpageTitle";
  /**
   * landingpageTitle from f3Web.csv (in EN: Landingspage )
   */
  public get landingpageTitle(): string { return this.getStringDef(this.key_landingpageTitle, "Landingspage"); }

  /**
   * VariantLastCalculated from f3Web.csv (in EN: Variant was last calculated on {0} )
   */
  public key_VariantLastCalculated = "VariantLastCalculated";
  /**
   * VariantLastCalculated from f3Web.csv (in EN: Variant was last calculated on {0} )
   */
  public VariantLastCalculated(val1): string { return this.getStringDef("VariantLastCalculated", "Variant was last calculated on {0}").replace("{0}", val1); }

  /**
   * VariantCalculating from f3Web.csv (in EN: Variant is being calculated )
   */
  public key_VariantCalculating = "VariantCalculating";
  /**
   * VariantCalculating from f3Web.csv (in EN: Variant is being calculated )
   */
  public get VariantCalculating(): string { return this.getStringDef(this.key_VariantCalculating, "Variant is being calculated"); }

  /**
   * landingpageGoToLogin from f3Web.csv (in EN: Go to login )
   */
  public key_landingpageGoToLogin = "landingpageGoToLogin";
  /**
   * landingpageGoToLogin from f3Web.csv (in EN: Go to login )
   */
  public get landingpageGoToLogin(): string { return this.getStringDef(this.key_landingpageGoToLogin, "Go to login"); }

  /**
   * landingpageNoUser from f3Web.csv (in EN: You have not been logged in, you will be redirected. )
   */
  public key_landingpageNoUser = "landingpageNoUser";
  /**
   * landingpageNoUser from f3Web.csv (in EN: You have not been logged in, you will be redirected. )
   */
  public get landingpageNoUser(): string { return this.getStringDef(this.key_landingpageNoUser, "You have not been logged in, you will be redirected."); }

  /**
   * landingpageMessage from f3Web.csv (in EN: If you have sufficient rights, you will be directly redirected to your landing page. )
   */
  public key_landingpageMessage = "landingpageMessage";
  /**
   * landingpageMessage from f3Web.csv (in EN: If you have sufficient rights, you will be directly redirected to your landing page. )
   */
  public get landingpageMessage(): string { return this.getStringDef(this.key_landingpageMessage, "If you have sufficient rights, you will be directly redirected to your landing page."); }

  /**
   * landingpageNoValidMenuItems from f3Web.csv (in EN: You have been logged in, but you don't have sufficient rights for this application. )
   */
  public key_landingpageNoValidMenuItems = "landingpageNoValidMenuItems";
  /**
   * landingpageNoValidMenuItems from f3Web.csv (in EN: You have been logged in, but you don't have sufficient rights for this application. )
   */
  public get landingpageNoValidMenuItems(): string { return this.getStringDef(this.key_landingpageNoValidMenuItems, "You have been logged in, but you don't have sufficient rights for this application."); }

  /**
   * loginSignoutForDifferentUser from f3Web.csv (in EN: Signout and login with different credentials )
   */
  public key_loginSignoutForDifferentUser = "loginSignoutForDifferentUser";
  /**
   * loginSignoutForDifferentUser from f3Web.csv (in EN: Signout and login with different credentials )
   */
  public get loginSignoutForDifferentUser(): string { return this.getStringDef(this.key_loginSignoutForDifferentUser, "Signout and login with different credentials"); }

  /**
   * loginSignOut from f3Web.csv (in EN: Signout )
   */
  public key_loginSignOut = "loginSignOut";
  /**
   * loginSignOut from f3Web.csv (in EN: Signout )
   */
  public get loginSignOut(): string { return this.getStringDef(this.key_loginSignOut, "Signout"); }

  /**
   * loginSignoutTitle from f3Web.csv (in EN: Signing out )
   */
  public key_loginSignoutTitle = "loginSignoutTitle";
  /**
   * loginSignoutTitle from f3Web.csv (in EN: Signing out )
   */
  public get loginSignoutTitle(): string { return this.getStringDef(this.key_loginSignoutTitle, "Signing out"); }

  /**
   * loginSignoutMessage from f3Web.csv (in EN: You are being signed out. )
   */
  public key_loginSignoutMessage = "loginSignoutMessage";
  /**
   * loginSignoutMessage from f3Web.csv (in EN: You are being signed out. )
   */
  public get loginSignoutMessage(): string { return this.getStringDef(this.key_loginSignoutMessage, "You are being signed out."); }

  /**
   * loginInfoTitle from f3Web.csv (in EN: Welcome )
   */
  public key_loginInfoTitle = "loginInfoTitle";
  /**
   * loginInfoTitle from f3Web.csv (in EN: Welcome )
   */
  public get loginInfoTitle(): string { return this.getStringDef(this.key_loginInfoTitle, "Welcome"); }

  /**
   * loginUserName from f3Web.csv (in EN: Username )
   */
  public key_loginUserName = "loginUserName";
  /**
   * loginUserName from f3Web.csv (in EN: Username )
   */
  public get loginUserName(): string { return this.getStringDef(this.key_loginUserName, "Username"); }

  /**
   * loginPassword from f3Web.csv (in EN: Password )
   */
  public key_loginPassword = "loginPassword";
  /**
   * loginPassword from f3Web.csv (in EN: Password )
   */
  public get loginPassword(): string { return this.getStringDef(this.key_loginPassword, "Password"); }

  /**
   * loginInvalidCredentials from f3Web.csv (in EN: Username and/or password are incorrect. Please try again )
   */
  public key_loginInvalidCredentials = "loginInvalidCredentials";
  /**
   * loginInvalidCredentials from f3Web.csv (in EN: Username and/or password are incorrect. Please try again )
   */
  public get loginInvalidCredentials(): string { return this.getStringDef(this.key_loginInvalidCredentials, "Username and/or password are incorrect. Please try again"); }

  /**
   * loginFailed from f3Web.csv (in EN: Login failed )
   */
  public key_loginFailed = "loginFailed";
  /**
   * loginFailed from f3Web.csv (in EN: Login failed )
   */
  public get loginFailed(): string { return this.getStringDef(this.key_loginFailed, "Login failed"); }

  /**
   * loginRedirect from f3Web.csv (in EN: Redirecting login )
   */
  public key_loginRedirect = "loginRedirect";
  /**
   * loginRedirect from f3Web.csv (in EN: Redirecting login )
   */
  public get loginRedirect(): string { return this.getStringDef(this.key_loginRedirect, "Redirecting login"); }

  /**
   * accountClaimsInvestigate from f3Web.csv (in EN: View your current login information )
   */
  public key_accountClaimsInvestigate = "accountClaimsInvestigate";
  /**
   * accountClaimsInvestigate from f3Web.csv (in EN: View your current login information )
   */
  public get accountClaimsInvestigate(): string { return this.getStringDef(this.key_accountClaimsInvestigate, "View your current login information"); }

  /**
   * accountClaimsTitle from f3Web.csv (in EN: Current claims )
   */
  public key_accountClaimsTitle = "accountClaimsTitle";
  /**
   * accountClaimsTitle from f3Web.csv (in EN: Current claims )
   */
  public get accountClaimsTitle(): string { return this.getStringDef(this.key_accountClaimsTitle, "Current claims"); }

  /**
   * accountClaimsNumber from f3Web.csv (in EN: Number of claims: {0} )
   */
  public key_accountClaimsNumber = "accountClaimsNumber";
  /**
   * accountClaimsNumber from f3Web.csv (in EN: Number of claims: {0} )
   */
  public accountClaimsNumber(val1): string { return this.getStringDef("accountClaimsNumber", "Number of claims: {0}").replace("{0}", val1); }

  /**
   * accountClaimsRoleRefreshRequired from f3Web.csv (in EN: Role not loaded, refresh! )
   */
  public key_accountClaimsRoleRefreshRequired = "accountClaimsRoleRefreshRequired";
  /**
   * accountClaimsRoleRefreshRequired from f3Web.csv (in EN: Role not loaded, refresh! )
   */
  public get accountClaimsRoleRefreshRequired(): string { return this.getStringDef(this.key_accountClaimsRoleRefreshRequired, "Role not loaded, refresh!"); }

  /**
   * loginCurrentlyClosed from f3Web.csv (in EN: You are currently exclude from usage of this system. )
   */
  public key_loginCurrentlyClosed = "loginCurrentlyClosed";
  /**
   * loginCurrentlyClosed from f3Web.csv (in EN: You are currently exclude from usage of this system. )
   */
  public get loginCurrentlyClosed(): string { return this.getStringDef(this.key_loginCurrentlyClosed, "You are currently exclude from usage of this system."); }

  /**
   * loginNoDataAvailableTitle from f3Web.csv (in EN: Unfortunately )
   */
  public key_loginNoDataAvailableTitle = "loginNoDataAvailableTitle";
  /**
   * loginNoDataAvailableTitle from f3Web.csv (in EN: Unfortunately )
   */
  public get loginNoDataAvailableTitle(): string { return this.getStringDef(this.key_loginNoDataAvailableTitle, "Unfortunately"); }

  /**
   * loginNoDataAvailable from f3Web.csv (in EN: There is no data available for you )
   */
  public key_loginNoDataAvailable = "loginNoDataAvailable";
  /**
   * loginNoDataAvailable from f3Web.csv (in EN: There is no data available for you )
   */
  public get loginNoDataAvailable(): string { return this.getStringDef(this.key_loginNoDataAvailable, "There is no data available for you"); }

  /**
   * loginUnknownError from f3Web.csv (in EN: We could not validate the authentication at this moment. )
   */
  public key_loginUnknownError = "loginUnknownError";
  /**
   * loginUnknownError from f3Web.csv (in EN: We could not validate the authentication at this moment. )
   */
  public get loginUnknownError(): string { return this.getStringDef(this.key_loginUnknownError, "We could not validate the authentication at this moment."); }

  /**
   * menuManagement from f3Web.csv (in EN: Management )
   */
  public key_menuManagement = "menuManagement";
  /**
   * menuManagement from f3Web.csv (in EN: Management )
   */
  public get menuManagement(): string { return this.getStringDef(this.key_menuManagement, "Management"); }

  /**
   * menuTechnicalmanagement from f3Web.csv (in EN: Technical management )
   */
  public key_menuTechnicalmanagement = "menuTechnicalmanagement";
  /**
   * menuTechnicalmanagement from f3Web.csv (in EN: Technical management )
   */
  public get menuTechnicalmanagement(): string { return this.getStringDef(this.key_menuTechnicalmanagement, "Technical management"); }

  /**
   * menuLogout from f3Web.csv (in EN: Logout )
   */
  public key_menuLogout = "menuLogout";
  /**
   * menuLogout from f3Web.csv (in EN: Logout )
   */
  public get menuLogout(): string { return this.getStringDef(this.key_menuLogout, "Logout"); }

  /**
   * menuClaims from f3Web.csv (in EN: Claims )
   */
  public key_menuClaims = "menuClaims";
  /**
   * menuClaims from f3Web.csv (in EN: Claims )
   */
  public get menuClaims(): string { return this.getStringDef(this.key_menuClaims, "Claims"); }

  /**
   * menuSettings from f3Web.csv (in EN: Settings )
   */
  public key_menuSettings = "menuSettings";
  /**
   * menuSettings from f3Web.csv (in EN: Settings )
   */
  public get menuSettings(): string { return this.getStringDef(this.key_menuSettings, "Settings"); }

  /**
   * menuPersonal from f3Web.csv (in EN: Pointlogic )
   */
  public key_menuPersonal = "menuPersonal";
  /**
   * menuPersonal from f3Web.csv (in EN: Pointlogic )
   */
  public get menuPersonal(): string { return this.getStringDef(this.key_menuPersonal, "Pointlogic"); }

  /**
   * fileUploadFileTooLarge from f3Web.csv (in EN: The file is too large (allowed maximum {0} mb). )
   */
  public key_fileUploadFileTooLarge = "fileUploadFileTooLarge";
  /**
   * fileUploadFileTooLarge from f3Web.csv (in EN: The file is too large (allowed maximum {0} mb). )
   */
  public fileUploadFileTooLarge(val1): string { return this.getStringDef("fileUploadFileTooLarge", "The file is too large (allowed maximum {0} mb).").replace("{0}", val1); }

  /**
   * fileUploadFileNameTooLong from f3Web.csv (in EN: The filename contains too many characters and cannot be uploaded. )
   */
  public key_fileUploadFileNameTooLong = "fileUploadFileNameTooLong";
  /**
   * fileUploadFileNameTooLong from f3Web.csv (in EN: The filename contains too many characters and cannot be uploaded. )
   */
  public get fileUploadFileNameTooLong(): string { return this.getStringDef(this.key_fileUploadFileNameTooLong, "The filename contains too many characters and cannot be uploaded."); }

  /**
   * fileUploadFileNameInvalidType from f3Web.csv (in EN: This filetype is not allowed. The file should be of type {0}. )
   */
  public key_fileUploadFileNameInvalidType = "fileUploadFileNameInvalidType";
  /**
   * fileUploadFileNameInvalidType from f3Web.csv (in EN: This filetype is not allowed. The file should be of type {0}. )
   */
  public fileUploadFileNameInvalidType(val1): string { return this.getStringDef("fileUploadFileNameInvalidType", "This filetype is not allowed. The file should be of type {0}.").replace("{0}", val1); }

  /**
   * fileUploadFileNameInvalidTypeSeparator from f3Web.csv (in EN:  or  )
   */
  public key_fileUploadFileNameInvalidTypeSeparator = "fileUploadFileNameInvalidTypeSeparator";
  /**
   * fileUploadFileNameInvalidTypeSeparator from f3Web.csv (in EN:  or  )
   */
  public get fileUploadFileNameInvalidTypeSeparator(): string { return this.getStringDef(this.key_fileUploadFileNameInvalidTypeSeparator, " or "); }

  /**
   * fileUploadFileAlreadyExists from f3Web.csv (in EN: Unable to upload file because there is already file with same name. Please provide a file with different name. )
   */
  public key_fileUploadFileAlreadyExists = "fileUploadFileAlreadyExists";
  /**
   * fileUploadFileAlreadyExists from f3Web.csv (in EN: Unable to upload file because there is already file with same name. Please provide a file with different name. )
   */
  public get fileUploadFileAlreadyExists(): string { return this.getStringDef(this.key_fileUploadFileAlreadyExists, "Unable to upload file because there is already file with same name. Please provide a file with different name."); }

  /**
   * statusRequestForApprovalFailed from f3Web.csv (in EN: Your request has not been sent, please try again )
   */
  public key_statusRequestForApprovalFailed = "statusRequestForApprovalFailed";
  /**
   * statusRequestForApprovalFailed from f3Web.csv (in EN: Your request has not been sent, please try again )
   */
  public get statusRequestForApprovalFailed(): string { return this.getStringDef(this.key_statusRequestForApprovalFailed, "Your request has not been sent, please try again"); }

  /**
   * statusRequestForApprovalFailedEmpty from f3Web.csv (in EN: Your request has not been sent, your attachments are probable too large (max {0} kb). )
   */
  public key_statusRequestForApprovalFailedEmpty = "statusRequestForApprovalFailedEmpty";
  /**
   * statusRequestForApprovalFailedEmpty from f3Web.csv (in EN: Your request has not been sent, your attachments are probable too large (max {0} kb). )
   */
  public statusRequestForApprovalFailedEmpty(val1): string { return this.getStringDef("statusRequestForApprovalFailedEmpty", "Your request has not been sent, your attachments are probable too large (max {0} kb).").replace("{0}", val1); }

  /**
   * statusRequestForApprovalSucces from f3Web.csv (in EN: Your request has been sent, you will shortly receive an e-mail. )
   */
  public key_statusRequestForApprovalSucces = "statusRequestForApprovalSucces";
  /**
   * statusRequestForApprovalSucces from f3Web.csv (in EN: Your request has been sent, you will shortly receive an e-mail. )
   */
  public get statusRequestForApprovalSucces(): string { return this.getStringDef(this.key_statusRequestForApprovalSucces, "Your request has been sent, you will shortly receive an e-mail."); }

  /**
   * statusRequestForApprovalLoggedAsOtherUserEmailTitle from f3Web.csv (in EN: {0} by {1} )
   */
  public key_statusRequestForApprovalLoggedAsOtherUserEmailTitle = "statusRequestForApprovalLoggedAsOtherUserEmailTitle";
  /**
   * statusRequestForApprovalLoggedAsOtherUserEmailTitle from f3Web.csv (in EN: {0} by {1} )
   */
  public statusRequestForApprovalLoggedAsOtherUserEmailTitle(val1, val2): string { return this.getStringDef("statusRequestForApprovalLoggedAsOtherUserEmailTitle", "{0} by {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * statusRequestForApprovalLoggedAsOtherUserEmailBody from f3Web.csv (in EN: A change has been made on behalf of you by {0}{1} )
   */
  public key_statusRequestForApprovalLoggedAsOtherUserEmailBody = "statusRequestForApprovalLoggedAsOtherUserEmailBody";
  /**
   * statusRequestForApprovalLoggedAsOtherUserEmailBody from f3Web.csv (in EN: A change has been made on behalf of you by {0}{1} )
   */
  public statusRequestForApprovalLoggedAsOtherUserEmailBody(val1, val2): string { return this.getStringDef("statusRequestForApprovalLoggedAsOtherUserEmailBody", "A change has been made on behalf of you by {0}{1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * managementPageinfo from f3Web.csv (in EN: Page )
   */
  public key_managementPageinfo = "managementPageinfo";
  /**
   * managementPageinfo from f3Web.csv (in EN: Page )
   */
  public get managementPageinfo(): string { return this.getStringDef(this.key_managementPageinfo, "Page"); }

  /**
   * loginUserNameRequired from f3Web.csv (in EN: Username is required. )
   */
  public key_loginUserNameRequired = "loginUserNameRequired";
  /**
   * loginUserNameRequired from f3Web.csv (in EN: Username is required. )
   */
  public get loginUserNameRequired(): string { return this.getStringDef(this.key_loginUserNameRequired, "Username is required."); }

  /**
   * loginPasswordRequired from f3Web.csv (in EN: Password is required. )
   */
  public key_loginPasswordRequired = "loginPasswordRequired";
  /**
   * loginPasswordRequired from f3Web.csv (in EN: Password is required. )
   */
  public get loginPasswordRequired(): string { return this.getStringDef(this.key_loginPasswordRequired, "Password is required."); }

  /**
   * loginButtonLogin from f3Web.csv (in EN: Login )
   */
  public key_loginButtonLogin = "loginButtonLogin";
  /**
   * loginButtonLogin from f3Web.csv (in EN: Login )
   */
  public get loginButtonLogin(): string { return this.getStringDef(this.key_loginButtonLogin, "Login"); }

  /**
   * loginUsesNtlm from f3Web.csv (in EN: Press the Login button below to enter Pointlogic. )
   */
  public key_loginUsesNtlm = "loginUsesNtlm";
  /**
   * loginUsesNtlm from f3Web.csv (in EN: Press the Login button below to enter Pointlogic. )
   */
  public get loginUsesNtlm(): string { return this.getStringDef(this.key_loginUsesNtlm, "Press the Login button below to enter Pointlogic."); }

  /**
   * loginNoDataForUser from f3Web.csv (in EN: The system contains no data for you. )
   */
  public key_loginNoDataForUser = "loginNoDataForUser";
  /**
   * loginNoDataForUser from f3Web.csv (in EN: The system contains no data for you. )
   */
  public get loginNoDataForUser(): string { return this.getStringDef(this.key_loginNoDataForUser, "The system contains no data for you."); }

  /**
   * TargetStatusUnknown from f3Web.csv (in EN: Unknown )
   */
  public key_TargetStatusUnknown = "TargetStatusUnknown";
  /**
   * TargetStatusUnknown from f3Web.csv (in EN: Unknown )
   */
  public get TargetStatusUnknown(): string { return this.getStringDef(this.key_TargetStatusUnknown, "Unknown"); }

  /**
   * TargetStatusAbleToSelect from f3Web.csv (in EN: Able to select )
   */
  public key_TargetStatusAbleToSelect = "TargetStatusAbleToSelect";
  /**
   * TargetStatusAbleToSelect from f3Web.csv (in EN: Able to select )
   */
  public get TargetStatusAbleToSelect(): string { return this.getStringDef(this.key_TargetStatusAbleToSelect, "Able to select"); }

  /**
   * TargetStatusRequestedForApproval from f3Web.csv (in EN: Request for approval )
   */
  public key_TargetStatusRequestedForApproval = "TargetStatusRequestedForApproval";
  /**
   * TargetStatusRequestedForApproval from f3Web.csv (in EN: Request for approval )
   */
  public get TargetStatusRequestedForApproval(): string { return this.getStringDef(this.key_TargetStatusRequestedForApproval, "Request for approval"); }

  /**
   * TargetStatusApproval from f3Web.csv (in EN: Approved )
   */
  public key_TargetStatusApproval = "TargetStatusApproval";
  /**
   * TargetStatusApproval from f3Web.csv (in EN: Approved )
   */
  public get TargetStatusApproval(): string { return this.getStringDef(this.key_TargetStatusApproval, "Approved"); }

  /**
   * TargetStatusNotAbleToSelect from f3Web.csv (in EN: Not able to select )
   */
  public key_TargetStatusNotAbleToSelect = "TargetStatusNotAbleToSelect";
  /**
   * TargetStatusNotAbleToSelect from f3Web.csv (in EN: Not able to select )
   */
  public get TargetStatusNotAbleToSelect(): string { return this.getStringDef(this.key_TargetStatusNotAbleToSelect, "Not able to select"); }

  /**
   * companyUrl from f3Web.csv (in EN: https://www.pointlogichr.nl )
   */
  public key_companyUrl = "companyUrl";
  /**
   * companyUrl from f3Web.csv (in EN: https://www.pointlogichr.nl )
   */
  public get companyUrl(): string { return this.getStringDef(this.key_companyUrl, "https://www.pointlogichr.nl"); }

  /**
   * menuPageSelection from f3Web.csv (in EN: Selection )
   */
  public key_menuPageSelection = "menuPageSelection";
  /**
   * menuPageSelection from f3Web.csv (in EN: Selection )
   */
  public get menuPageSelection(): string { return this.getStringDef(this.key_menuPageSelection, "Selection"); }

  /**
   * menuProfile from f3Web.csv (in EN: My profile )
   */
  public key_menuProfile = "menuProfile";
  /**
   * menuProfile from f3Web.csv (in EN: My profile )
   */
  public get menuProfile(): string { return this.getStringDef(this.key_menuProfile, "My profile"); }

  /**
   * confirmDeleteHeader from f3Web.csv (in EN: Delete )
   */
  public key_confirmDeleteHeader = "confirmDeleteHeader";
  /**
   * confirmDeleteHeader from f3Web.csv (in EN: Delete )
   */
  public get confirmDeleteHeader(): string { return this.getStringDef(this.key_confirmDeleteHeader, "Delete"); }

  /**
   * confirmDeleteTitle from f3Web.csv (in EN: Delete ? )
   */
  public key_confirmDeleteTitle = "confirmDeleteTitle";
  /**
   * confirmDeleteTitle from f3Web.csv (in EN: Delete ? )
   */
  public get confirmDeleteTitle(): string { return this.getStringDef(this.key_confirmDeleteTitle, "Delete ?"); }

  /**
   * error_insufficientrights_title from f3Web.csv (in EN: Insufficient rights )
   */
  public key_error_insufficientrights_title = "error_insufficientrights_title";
  /**
   * error_insufficientrights_title from f3Web.csv (in EN: Insufficient rights )
   */
  public get error_insufficientrights_title(): string { return this.getStringDef(this.key_error_insufficientrights_title, "Insufficient rights"); }

  /**
   * error_insufficientrights_checkSession from f3Web.csv (in EN: Validating login status... )
   */
  public key_error_insufficientrights_checkSession = "error_insufficientrights_checkSession";
  /**
   * error_insufficientrights_checkSession from f3Web.csv (in EN: Validating login status... )
   */
  public get error_insufficientrights_checkSession(): string { return this.getStringDef(this.key_error_insufficientrights_checkSession, "Validating login status..."); }

  /**
   * error_insufficientrights_checkSessionValid from f3Web.csv (in EN: Your login is still valid )
   */
  public key_error_insufficientrights_checkSessionValid = "error_insufficientrights_checkSessionValid";
  /**
   * error_insufficientrights_checkSessionValid from f3Web.csv (in EN: Your login is still valid )
   */
  public get error_insufficientrights_checkSessionValid(): string { return this.getStringDef(this.key_error_insufficientrights_checkSessionValid, "Your login is still valid"); }

  /**
   * error_insufficientrights_checkSessionExpired from f3Web.csv (in EN: Your session has expired, please sign-in again. )
   */
  public key_error_insufficientrights_checkSessionExpired = "error_insufficientrights_checkSessionExpired";
  /**
   * error_insufficientrights_checkSessionExpired from f3Web.csv (in EN: Your session has expired, please sign-in again. )
   */
  public get error_insufficientrights_checkSessionExpired(): string { return this.getStringDef(this.key_error_insufficientrights_checkSessionExpired, "Your session has expired, please sign-in again."); }

  /**
   * error_insufficientrights_text from f3Web.csv (in EN: You have insufficient rights. )
   */
  public key_error_insufficientrights_text = "error_insufficientrights_text";
  /**
   * error_insufficientrights_text from f3Web.csv (in EN: You have insufficient rights. )
   */
  public get error_insufficientrights_text(): string { return this.getStringDef(this.key_error_insufficientrights_text, "You have insufficient rights."); }

  /**
   * error_insufficientrights_text_role from f3Web.csv (in EN: You are missing role {0}. )
   */
  public key_error_insufficientrights_text_role = "error_insufficientrights_text_role";
  /**
   * error_insufficientrights_text_role from f3Web.csv (in EN: You are missing role {0}. )
   */
  public error_insufficientrights_text_role(val1): string { return this.getStringDef("error_insufficientrights_text_role", "You are missing role {0}.").replace("{0}", val1); }

  /**
   * error_insufficientrights_text_api from f3Web.csv (in EN: You have no permission to access {0}. )
   */
  public key_error_insufficientrights_text_api = "error_insufficientrights_text_api";
  /**
   * error_insufficientrights_text_api from f3Web.csv (in EN: You have no permission to access {0}. )
   */
  public error_insufficientrights_text_api(val1): string { return this.getStringDef("error_insufficientrights_text_api", "You have no permission to access {0}.").replace("{0}", val1); }

  /**
   * error_insufficientrights_gotoLogin from f3Web.csv (in EN: Go to login )
   */
  public key_error_insufficientrights_gotoLogin = "error_insufficientrights_gotoLogin";
  /**
   * error_insufficientrights_gotoLogin from f3Web.csv (in EN: Go to login )
   */
  public get error_insufficientrights_gotoLogin(): string { return this.getStringDef(this.key_error_insufficientrights_gotoLogin, "Go to login"); }

  /**
   * pleaseWaitLoading from f3Web.csv (in EN: One moment please, the data is being loaded )
   */
  public key_pleaseWaitLoading = "pleaseWaitLoading";
  /**
   * pleaseWaitLoading from f3Web.csv (in EN: One moment please, the data is being loaded )
   */
  public get pleaseWaitLoading(): string { return this.getStringDef(this.key_pleaseWaitLoading, "One moment please, the data is being loaded"); }

  /**
   * selectLanguage from f3Web.csv (in EN: Select language )
   */
  public key_selectLanguage = "selectLanguage";
  /**
   * selectLanguage from f3Web.csv (in EN: Select language )
   */
  public get selectLanguage(): string { return this.getStringDef(this.key_selectLanguage, "Select language"); }

  /**
   * languageSelectionSelectLanguage from f3Web.csv (in EN: language selection: )
   */
  public key_languageSelectionSelectLanguage = "languageSelectionSelectLanguage";
  /**
   * languageSelectionSelectLanguage from f3Web.csv (in EN: language selection: )
   */
  public get languageSelectionSelectLanguage(): string { return this.getStringDef(this.key_languageSelectionSelectLanguage, "language selection:"); }

  /**
   * ClaimType from f3Web.csv (in EN: Type )
   */
  public key_ClaimType = "ClaimType";
  /**
   * ClaimType from f3Web.csv (in EN: Type )
   */
  public get ClaimType(): string { return this.getStringDef(this.key_ClaimType, "Type"); }

  /**
   * ClaimValue from f3Web.csv (in EN: Value )
   */
  public key_ClaimValue = "ClaimValue";
  /**
   * ClaimValue from f3Web.csv (in EN: Value )
   */
  public get ClaimValue(): string { return this.getStringDef(this.key_ClaimValue, "Value"); }

  /**
   * ClaimUsage from f3Web.csv (in EN: Used for )
   */
  public key_ClaimUsage = "ClaimUsage";
  /**
   * ClaimUsage from f3Web.csv (in EN: Used for )
   */
  public get ClaimUsage(): string { return this.getStringDef(this.key_ClaimUsage, "Used for"); }

  /**
   * ClaimGoToSignOut from f3Web.csv (in EN: Sign out )
   */
  public key_ClaimGoToSignOut = "ClaimGoToSignOut";
  /**
   * ClaimGoToSignOut from f3Web.csv (in EN: Sign out )
   */
  public get ClaimGoToSignOut(): string { return this.getStringDef(this.key_ClaimGoToSignOut, "Sign out"); }

  /**
   * ClaimNoUserLoaded from f3Web.csv (in EN: No user loaded )
   */
  public key_ClaimNoUserLoaded = "ClaimNoUserLoaded";
  /**
   * ClaimNoUserLoaded from f3Web.csv (in EN: No user loaded )
   */
  public get ClaimNoUserLoaded(): string { return this.getStringDef(this.key_ClaimNoUserLoaded, "No user loaded"); }

  /**
   * ClaimNotAuthenticated from f3Web.csv (in EN: User has not been authenticated )
   */
  public key_ClaimNotAuthenticated = "ClaimNotAuthenticated";
  /**
   * ClaimNotAuthenticated from f3Web.csv (in EN: User has not been authenticated )
   */
  public get ClaimNotAuthenticated(): string { return this.getStringDef(this.key_ClaimNotAuthenticated, "User has not been authenticated"); }

  /**
   * exportHeader from f3Web.csv (in EN: Available downloads: )
   */
  public key_exportHeader = "exportHeader";
  /**
   * exportHeader from f3Web.csv (in EN: Available downloads: )
   */
  public get exportHeader(): string { return this.getStringDef(this.key_exportHeader, "Available downloads:"); }

  /**
   * exportHeaderNoDownloads from f3Web.csv (in EN: There are no downloads currently available to you. )
   */
  public key_exportHeaderNoDownloads = "exportHeaderNoDownloads";
  /**
   * exportHeaderNoDownloads from f3Web.csv (in EN: There are no downloads currently available to you. )
   */
  public get exportHeaderNoDownloads(): string { return this.getStringDef(this.key_exportHeaderNoDownloads, "There are no downloads currently available to you."); }

  /**
   * exportDataIsReady from f3Web.csv (in EN: Data is ready )
   */
  public key_exportDataIsReady = "exportDataIsReady";
  /**
   * exportDataIsReady from f3Web.csv (in EN: Data is ready )
   */
  public get exportDataIsReady(): string { return this.getStringDef(this.key_exportDataIsReady, "Data is ready"); }

  /**
   * exportDataIsNotReadyHint from f3Web.csv (in EN: Data is not yet ready, downloading can take a while longer. )
   */
  public key_exportDataIsNotReadyHint = "exportDataIsNotReadyHint";
  /**
   * exportDataIsNotReadyHint from f3Web.csv (in EN: Data is not yet ready, downloading can take a while longer. )
   */
  public get exportDataIsNotReadyHint(): string { return this.getStringDef(this.key_exportDataIsNotReadyHint, "Data is not yet ready, downloading can take a while longer."); }

  /**
   * exportDataIsNotReady from f3Web.csv (in EN: Data is not yet ready )
   */
  public key_exportDataIsNotReady = "exportDataIsNotReady";
  /**
   * exportDataIsNotReady from f3Web.csv (in EN: Data is not yet ready )
   */
  public get exportDataIsNotReady(): string { return this.getStringDef(this.key_exportDataIsNotReady, "Data is not yet ready"); }

  /**
   * exportDataIsOutOfDate from f3Web.csv (in EN: Data is not up to date  )
   */
  public key_exportDataIsOutOfDate = "exportDataIsOutOfDate";
  /**
   * exportDataIsOutOfDate from f3Web.csv (in EN: Data is not up to date  )
   */
  public get exportDataIsOutOfDate(): string { return this.getStringDef(this.key_exportDataIsOutOfDate, "Data is not up to date "); }

  /**
   * exportStartDownload from f3Web.csv (in EN: Start download )
   */
  public key_exportStartDownload = "exportStartDownload";
  /**
   * exportStartDownload from f3Web.csv (in EN: Start download )
   */
  public get exportStartDownload(): string { return this.getStringDef(this.key_exportStartDownload, "Start download"); }

  /**
   * exportDownloadStarted from f3Web.csv (in EN: Download has started, this might take some time. )
   */
  public key_exportDownloadStarted = "exportDownloadStarted";
  /**
   * exportDownloadStarted from f3Web.csv (in EN: Download has started, this might take some time. )
   */
  public get exportDownloadStarted(): string { return this.getStringDef(this.key_exportDownloadStarted, "Download has started, this might take some time."); }

  /**
   * exportNothingDownloaded from f3Web.csv (in EN: Error )
   */
  public key_exportNothingDownloaded = "exportNothingDownloaded";
  /**
   * exportNothingDownloaded from f3Web.csv (in EN: Error )
   */
  public get exportNothingDownloaded(): string { return this.getStringDef(this.key_exportNothingDownloaded, "Error"); }

  /**
   * ApiMenuTechnicalManagement from f3Web.csv (in EN: Technical management )
   */
  public key_ApiMenuTechnicalManagement = "ApiMenuTechnicalManagement";
  /**
   * ApiMenuTechnicalManagement from f3Web.csv (in EN: Technical management )
   */
  public get ApiMenuTechnicalManagement(): string { return this.getStringDef(this.key_ApiMenuTechnicalManagement, "Technical management"); }

  /**
   * ApiMenuManagementLogDownload from f3Web.csv (in EN: Log download )
   */
  public key_ApiMenuManagementLogDownload = "ApiMenuManagementLogDownload";
  /**
   * ApiMenuManagementLogDownload from f3Web.csv (in EN: Log download )
   */
  public get ApiMenuManagementLogDownload(): string { return this.getStringDef(this.key_ApiMenuManagementLogDownload, "Log download"); }

  /**
   * ApiMenuApplicationInformation from f3Web.csv (in EN: Application information )
   */
  public key_ApiMenuApplicationInformation = "ApiMenuApplicationInformation";
  /**
   * ApiMenuApplicationInformation from f3Web.csv (in EN: Application information )
   */
  public get ApiMenuApplicationInformation(): string { return this.getStringDef(this.key_ApiMenuApplicationInformation, "Application information"); }

  /**
   * ApiMenuPreferredModel from f3Web.csv (in EN: Preferred model )
   */
  public key_ApiMenuPreferredModel = "ApiMenuPreferredModel";
  /**
   * ApiMenuPreferredModel from f3Web.csv (in EN: Preferred model )
   */
  public get ApiMenuPreferredModel(): string { return this.getStringDef(this.key_ApiMenuPreferredModel, "Preferred model"); }

  /**
   * logDownloadFileList from f3Web.csv (in EN: The following log files were found: )
   */
  public key_logDownloadFileList = "logDownloadFileList";
  /**
   * logDownloadFileList from f3Web.csv (in EN: The following log files were found: )
   */
  public get logDownloadFileList(): string { return this.getStringDef(this.key_logDownloadFileList, "The following log files were found:"); }

  /**
   * logDownloadNoFiles from f3Web.csv (in EN: No log files were found. )
   */
  public key_logDownloadNoFiles = "logDownloadNoFiles";
  /**
   * logDownloadNoFiles from f3Web.csv (in EN: No log files were found. )
   */
  public get logDownloadNoFiles(): string { return this.getStringDef(this.key_logDownloadNoFiles, "No log files were found."); }

  /**
   * logDownloadStartDownload from f3Web.csv (in EN: start download )
   */
  public key_logDownloadStartDownload = "logDownloadStartDownload";
  /**
   * logDownloadStartDownload from f3Web.csv (in EN: start download )
   */
  public get logDownloadStartDownload(): string { return this.getStringDef(this.key_logDownloadStartDownload, "start download"); }

  /**
   * logDownloadPreview from f3Web.csv (in EN: preview )
   */
  public key_logDownloadPreview = "logDownloadPreview";
  /**
   * logDownloadPreview from f3Web.csv (in EN: preview )
   */
  public get logDownloadPreview(): string { return this.getStringDef(this.key_logDownloadPreview, "preview"); }

  /**
   * logDownloadReady from f3Web.csv (in EN: ready! )
   */
  public key_logDownloadReady = "logDownloadReady";
  /**
   * logDownloadReady from f3Web.csv (in EN: ready! )
   */
  public get logDownloadReady(): string { return this.getStringDef(this.key_logDownloadReady, "ready!"); }

  /**
   * logDownloadDirectLink from f3Web.csv (in EN: direct link )
   */
  public key_logDownloadDirectLink = "logDownloadDirectLink";
  /**
   * logDownloadDirectLink from f3Web.csv (in EN: direct link )
   */
  public get logDownloadDirectLink(): string { return this.getStringDef(this.key_logDownloadDirectLink, "direct link"); }

  /**
   * logDownloadNewTab from f3Web.csv (in EN: Open in new tab )
   */
  public key_logDownloadNewTab = "logDownloadNewTab";
  /**
   * logDownloadNewTab from f3Web.csv (in EN: Open in new tab )
   */
  public get logDownloadNewTab(): string { return this.getStringDef(this.key_logDownloadNewTab, "Open in new tab"); }

  /**
   * edittriggerCategory from f3Web.csv (in EN: Category )
   */
  public key_edittriggerCategory = "edittriggerCategory";
  /**
   * edittriggerCategory from f3Web.csv (in EN: Category )
   */
  public get edittriggerCategory(): string { return this.getStringDef(this.key_edittriggerCategory, "Category"); }

  /**
   * edittriggerNocategory from f3Web.csv (in EN: None )
   */
  public key_edittriggerNocategory = "edittriggerNocategory";
  /**
   * edittriggerNocategory from f3Web.csv (in EN: None )
   */
  public get edittriggerNocategory(): string { return this.getStringDef(this.key_edittriggerNocategory, "None"); }

  /**
   * edittriggerVisibleTrigger from f3Web.csv (in EN: Visible )
   */
  public key_edittriggerVisibleTrigger = "edittriggerVisibleTrigger";
  /**
   * edittriggerVisibleTrigger from f3Web.csv (in EN: Visible )
   */
  public get edittriggerVisibleTrigger(): string { return this.getStringDef(this.key_edittriggerVisibleTrigger, "Visible"); }

  /**
   * edittriggerWarningTrigger from f3Web.csv (in EN: Warning )
   */
  public key_edittriggerWarningTrigger = "edittriggerWarningTrigger";
  /**
   * edittriggerWarningTrigger from f3Web.csv (in EN: Warning )
   */
  public get edittriggerWarningTrigger(): string { return this.getStringDef(this.key_edittriggerWarningTrigger, "Warning"); }

  /**
   * edittriggerErrorTrigger from f3Web.csv (in EN: Error )
   */
  public key_edittriggerErrorTrigger = "edittriggerErrorTrigger";
  /**
   * edittriggerErrorTrigger from f3Web.csv (in EN: Error )
   */
  public get edittriggerErrorTrigger(): string { return this.getStringDef(this.key_edittriggerErrorTrigger, "Error"); }

  /**
   * edittriggerReadOnlyTrigger from f3Web.csv (in EN: Read only )
   */
  public key_edittriggerReadOnlyTrigger = "edittriggerReadOnlyTrigger";
  /**
   * edittriggerReadOnlyTrigger from f3Web.csv (in EN: Read only )
   */
  public get edittriggerReadOnlyTrigger(): string { return this.getStringDef(this.key_edittriggerReadOnlyTrigger, "Read only"); }

  /**
   * settingsDisplayModelElements from f3Web.csv (in EN: Display model elements: )
   */
  public key_settingsDisplayModelElements = "settingsDisplayModelElements";
  /**
   * settingsDisplayModelElements from f3Web.csv (in EN: Display model elements: )
   */
  public get settingsDisplayModelElements(): string { return this.getStringDef(this.key_settingsDisplayModelElements, "Display model elements:"); }

  /**
   * settingsDisplayModelElementsLongShortName from f3Web.csv (in EN: Both (long name and short name) )
   */
  public key_settingsDisplayModelElementsLongShortName = "settingsDisplayModelElementsLongShortName";
  /**
   * settingsDisplayModelElementsLongShortName from f3Web.csv (in EN: Both (long name and short name) )
   */
  public get settingsDisplayModelElementsLongShortName(): string { return this.getStringDef(this.key_settingsDisplayModelElementsLongShortName, "Both (long name and short name)"); }

  /**
   * settingsDisplayModelElementsShortLongName from f3Web.csv (in EN: Both (short name and long name) )
   */
  public key_settingsDisplayModelElementsShortLongName = "settingsDisplayModelElementsShortLongName";
  /**
   * settingsDisplayModelElementsShortLongName from f3Web.csv (in EN: Both (short name and long name) )
   */
  public get settingsDisplayModelElementsShortLongName(): string { return this.getStringDef(this.key_settingsDisplayModelElementsShortLongName, "Both (short name and long name)"); }

  /**
   * wizardNextStage from f3Web.csv (in EN: Next stage )
   */
  public key_wizardNextStage = "wizardNextStage";
  /**
   * wizardNextStage from f3Web.csv (in EN: Next stage )
   */
  public get wizardNextStage(): string { return this.getStringDef(this.key_wizardNextStage, "Next stage"); }

  /**
   * wizardPreviousStage from f3Web.csv (in EN: Previous stage )
   */
  public key_wizardPreviousStage = "wizardPreviousStage";
  /**
   * wizardPreviousStage from f3Web.csv (in EN: Previous stage )
   */
  public get wizardPreviousStage(): string { return this.getStringDef(this.key_wizardPreviousStage, "Previous stage"); }

  /**
   * wizardFinalize from f3Web.csv (in EN: Finalize )
   */
  public key_wizardFinalize = "wizardFinalize";
  /**
   * wizardFinalize from f3Web.csv (in EN: Finalize )
   */
  public get wizardFinalize(): string { return this.getStringDef(this.key_wizardFinalize, "Finalize"); }

  /**
   * wizardCurrentStage from f3Web.csv (in EN: Stage {0} of {1} )
   */
  public key_wizardCurrentStage = "wizardCurrentStage";
  /**
   * wizardCurrentStage from f3Web.csv (in EN: Stage {0} of {1} )
   */
  public wizardCurrentStage(val1, val2): string { return this.getStringDef("wizardCurrentStage", "Stage {0} of {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * longNameMissing from f3Web.csv (in EN: Your personal data is not yet complete. Please fill in your first and last name to continue. )
   */
  public key_longNameMissing = "longNameMissing";
  /**
   * longNameMissing from f3Web.csv (in EN: Your personal data is not yet complete. Please fill in your first and last name to continue. )
   */
  public get longNameMissing(): string { return this.getStringDef(this.key_longNameMissing, "Your personal data is not yet complete. Please fill in your first and last name to continue."); }

  /**
   * UploadDate from f3Web.csv (in EN: Upload date )
   */
  public key_UploadDate = "UploadDate";
  /**
   * UploadDate from f3Web.csv (in EN: Upload date )
   */
  public get UploadDate(): string { return this.getStringDef(this.key_UploadDate, "Upload date"); }

  /**
   * File from f3Web.csv (in EN: File )
   */
  public key_File = "File";
  /**
   * File from f3Web.csv (in EN: File )
   */
  public get File(): string { return this.getStringDef(this.key_File, "File"); }

  /**
   * Extension from f3Web.csv (in EN: Extension )
   */
  public key_Extension = "Extension";
  /**
   * Extension from f3Web.csv (in EN: Extension )
   */
  public get Extension(): string { return this.getStringDef(this.key_Extension, "Extension"); }

  /**
   * UserLoginDashboardName from f3Web.csv (in EN: Login overview )
   */
  public key_UserLoginDashboardName = "UserLoginDashboardName";
  /**
   * UserLoginDashboardName from f3Web.csv (in EN: Login overview )
   */
  public get UserLoginDashboardName(): string { return this.getStringDef(this.key_UserLoginDashboardName, "Login overview"); }

  /**
   * UserLoginGroupByTitle from f3Web.csv (in EN: Group by )
   */
  public key_UserLoginGroupByTitle = "UserLoginGroupByTitle";
  /**
   * UserLoginGroupByTitle from f3Web.csv (in EN: Group by )
   */
  public get UserLoginGroupByTitle(): string { return this.getStringDef(this.key_UserLoginGroupByTitle, "Group by"); }

  /**
   * UserLoginGroupByUnitsHour from f3Web.csv (in EN: Hour )
   */
  public key_UserLoginGroupByUnitsHour = "UserLoginGroupByUnitsHour";
  /**
   * UserLoginGroupByUnitsHour from f3Web.csv (in EN: Hour )
   */
  public get UserLoginGroupByUnitsHour(): string { return this.getStringDef(this.key_UserLoginGroupByUnitsHour, "Hour"); }

  /**
   * UserLoginGroupByUnitsDay from f3Web.csv (in EN: Day )
   */
  public key_UserLoginGroupByUnitsDay = "UserLoginGroupByUnitsDay";
  /**
   * UserLoginGroupByUnitsDay from f3Web.csv (in EN: Day )
   */
  public get UserLoginGroupByUnitsDay(): string { return this.getStringDef(this.key_UserLoginGroupByUnitsDay, "Day"); }

  /**
   * UserLoginGroupByUnitsMonth from f3Web.csv (in EN: Month )
   */
  public key_UserLoginGroupByUnitsMonth = "UserLoginGroupByUnitsMonth";
  /**
   * UserLoginGroupByUnitsMonth from f3Web.csv (in EN: Month )
   */
  public get UserLoginGroupByUnitsMonth(): string { return this.getStringDef(this.key_UserLoginGroupByUnitsMonth, "Month"); }

  /**
   * UserLoginGroupByUnitsYear from f3Web.csv (in EN: Year )
   */
  public key_UserLoginGroupByUnitsYear = "UserLoginGroupByUnitsYear";
  /**
   * UserLoginGroupByUnitsYear from f3Web.csv (in EN: Year )
   */
  public get UserLoginGroupByUnitsYear(): string { return this.getStringDef(this.key_UserLoginGroupByUnitsYear, "Year"); }

  /**
   * UserLoginFrom from f3Web.csv (in EN: From )
   */
  public key_UserLoginFrom = "UserLoginFrom";
  /**
   * UserLoginFrom from f3Web.csv (in EN: From )
   */
  public get UserLoginFrom(): string { return this.getStringDef(this.key_UserLoginFrom, "From"); }

  /**
   * UserLoginTo from f3Web.csv (in EN: Untill )
   */
  public key_UserLoginTo = "UserLoginTo";
  /**
   * UserLoginTo from f3Web.csv (in EN: Untill )
   */
  public get UserLoginTo(): string { return this.getStringDef(this.key_UserLoginTo, "Untill"); }

  /**
   * VariantStatusOverview from f3Web.csv (in EN: Variant status )
   */
  public key_VariantStatusOverview = "VariantStatusOverview";
  /**
   * VariantStatusOverview from f3Web.csv (in EN: Variant status )
   */
  public get VariantStatusOverview(): string { return this.getStringDef(this.key_VariantStatusOverview, "Variant status"); }

  /**
   * VariantDataSetUsed from f3Web.csv (in EN: Dataset used: {0} )
   */
  public key_VariantDataSetUsed = "VariantDataSetUsed";
  /**
   * VariantDataSetUsed from f3Web.csv (in EN: Dataset used: {0} )
   */
  public VariantDataSetUsed(val1): string { return this.getStringDef("VariantDataSetUsed", "Dataset used: {0}").replace("{0}", val1); }

  /**
   * NoVariants from f3Web.csv (in EN: No variants )
   */
  public key_NoVariants = "NoVariants";
  /**
   * NoVariants from f3Web.csv (in EN: No variants )
   */
  public get NoVariants(): string { return this.getStringDef(this.key_NoVariants, "No variants"); }

  /**
   * VariantCalculatedConfirmForce from f3Web.csv (in EN: Variant(s) are already up to date, force recalculation? )
   */
  public key_VariantCalculatedConfirmForce = "VariantCalculatedConfirmForce";
  /**
   * VariantCalculatedConfirmForce from f3Web.csv (in EN: Variant(s) are already up to date, force recalculation? )
   */
  public get VariantCalculatedConfirmForce(): string { return this.getStringDef(this.key_VariantCalculatedConfirmForce, "Variant(s) are already up to date, force recalculation?"); }

  /**
   * VariantCalculationProgressSummary from f3Web.csv (in EN: {0} of {1} variants have been calculated )
   */
  public key_VariantCalculationProgressSummary = "VariantCalculationProgressSummary";
  /**
   * VariantCalculationProgressSummary from f3Web.csv (in EN: {0} of {1} variants have been calculated )
   */
  public VariantCalculationProgressSummary(val1, val2): string { return this.getStringDef("VariantCalculationProgressSummary", "{0} of {1} variants have been calculated").replace("{1}", val2).replace("{0}", val1); }

  /**
   * VariantCalculationPeriodConfigurationDates from f3Web.csv (in EN: from {0} until {1} )
   */
  public key_VariantCalculationPeriodConfigurationDates = "VariantCalculationPeriodConfigurationDates";
  /**
   * VariantCalculationPeriodConfigurationDates from f3Web.csv (in EN: from {0} until {1} )
   */
  public VariantCalculationPeriodConfigurationDates(val1, val2): string { return this.getStringDef("VariantCalculationPeriodConfigurationDates", "from {0} until {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * VariantCalculationPeriodConfigurationDatePeriodType from f3Web.csv (in EN: {0} ({1}) )
   */
  public key_VariantCalculationPeriodConfigurationDatePeriodType = "VariantCalculationPeriodConfigurationDatePeriodType";
  /**
   * VariantCalculationPeriodConfigurationDatePeriodType from f3Web.csv (in EN: {0} ({1}) )
   */
  public VariantCalculationPeriodConfigurationDatePeriodType(val1, val2): string { return this.getStringDef("VariantCalculationPeriodConfigurationDatePeriodType", "{0} ({1})").replace("{1}", val2).replace("{0}", val1); }

  /**
   * VariantCalculationDataConfiguration from f3Web.csv (in EN: period {0} based on {1} )
   */
  public key_VariantCalculationDataConfiguration = "VariantCalculationDataConfiguration";
  /**
   * VariantCalculationDataConfiguration from f3Web.csv (in EN: period {0} based on {1} )
   */
  public VariantCalculationDataConfiguration(val1, val2): string { return this.getStringDef("VariantCalculationDataConfiguration", "period {0} based on {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * VariantPeriodModel from f3Web.csv (in EN: Period type )
   */
  public key_VariantPeriodModel = "VariantPeriodModel";
  /**
   * VariantPeriodModel from f3Web.csv (in EN: Period type )
   */
  public get VariantPeriodModel(): string { return this.getStringDef(this.key_VariantPeriodModel, "Period type"); }

  /**
   * HideToolbar from f3Web.csv (in EN: Hide toolbar )
   */
  public key_HideToolbar = "HideToolbar";
  /**
   * HideToolbar from f3Web.csv (in EN: Hide toolbar )
   */
  public get HideToolbar(): string { return this.getStringDef(this.key_HideToolbar, "Hide toolbar"); }

  /**
   * UnHideToolbar from f3Web.csv (in EN: Show toolbar )
   */
  public key_UnHideToolbar = "UnHideToolbar";
  /**
   * UnHideToolbar from f3Web.csv (in EN: Show toolbar )
   */
  public get UnHideToolbar(): string { return this.getStringDef(this.key_UnHideToolbar, "Show toolbar"); }

  /**
   * FileExtNotAllowed from f3Web.csv (in EN: File type not supported, only {0} is allowed )
   */
  public key_FileExtNotAllowed = "FileExtNotAllowed";
  /**
   * FileExtNotAllowed from f3Web.csv (in EN: File type not supported, only {0} is allowed )
   */
  public FileExtNotAllowed(val1): string { return this.getStringDef("FileExtNotAllowed", "File type not supported, only {0} is allowed").replace("{0}", val1); }

  /**
   * ImageUpload from f3Web.csv (in EN: Upload image )
   */
  public key_ImageUpload = "ImageUpload";
  /**
   * ImageUpload from f3Web.csv (in EN: Upload image )
   */
  public get ImageUpload(): string { return this.getStringDef(this.key_ImageUpload, "Upload image"); }

  /**
   * ImageUploadOther from f3Web.csv (in EN: Upload another image )
   */
  public key_ImageUploadOther = "ImageUploadOther";
  /**
   * ImageUploadOther from f3Web.csv (in EN: Upload another image )
   */
  public get ImageUploadOther(): string { return this.getStringDef(this.key_ImageUploadOther, "Upload another image"); }

  /**
   * SortOrder from f3Web.csv (in EN: Sortorder )
   */
  public key_SortOrder = "SortOrder";
  /**
   * SortOrder from f3Web.csv (in EN: Sortorder )
   */
  public get SortOrder(): string { return this.getStringDef(this.key_SortOrder, "Sortorder"); }

  /**
   * InvalidShortname from f3Web.csv (in EN: Invalid shortname )
   */
  public key_InvalidShortname = "InvalidShortname";
  /**
   * InvalidShortname from f3Web.csv (in EN: Invalid shortname )
   */
  public get InvalidShortname(): string { return this.getStringDef(this.key_InvalidShortname, "Invalid shortname"); }

  /**
   * ShortnameModifiedNotification from f3Web.csv (in EN: Your submitted name was being corrected for being invalid )
   */
  public key_ShortnameModifiedNotification = "ShortnameModifiedNotification";
  /**
   * ShortnameModifiedNotification from f3Web.csv (in EN: Your submitted name was being corrected for being invalid )
   */
  public get ShortnameModifiedNotification(): string { return this.getStringDef(this.key_ShortnameModifiedNotification, "Your submitted name was being corrected for being invalid"); }

  /**
   * NoVariantSelected from f3Web.csv (in EN: No variant selected )
   */
  public key_NoVariantSelected = "NoVariantSelected";
  /**
   * NoVariantSelected from f3Web.csv (in EN: No variant selected )
   */
  public get NoVariantSelected(): string { return this.getStringDef(this.key_NoVariantSelected, "No variant selected"); }

  /**
   * ShortName from f3Web.csv (in EN: Short name )
   */
  public key_ShortName = "ShortName";
  /**
   * ShortName from f3Web.csv (in EN: Short name )
   */
  public get ShortName(): string { return this.getStringDef(this.key_ShortName, "Short name"); }

  /**
   * auditLogsSubjectType from f3Web.csv (in EN: Subject type )
   */
  public key_auditLogsSubjectType = "auditLogsSubjectType";
  /**
   * auditLogsSubjectType from f3Web.csv (in EN: Subject type )
   */
  public get auditLogsSubjectType(): string { return this.getStringDef(this.key_auditLogsSubjectType, "Subject type"); }

  /**
   * auditLogsSubjectName from f3Web.csv (in EN: Subject name )
   */
  public key_auditLogsSubjectName = "auditLogsSubjectName";
  /**
   * auditLogsSubjectName from f3Web.csv (in EN: Subject name )
   */
  public get auditLogsSubjectName(): string { return this.getStringDef(this.key_auditLogsSubjectName, "Subject name"); }

  /**
   * auditLogsAction from f3Web.csv (in EN: Action )
   */
  public key_auditLogsAction = "auditLogsAction";
  /**
   * auditLogsAction from f3Web.csv (in EN: Action )
   */
  public get auditLogsAction(): string { return this.getStringDef(this.key_auditLogsAction, "Action"); }

  /**
   * auditLogsTarget from f3Web.csv (in EN: Target )
   */
  public key_auditLogsTarget = "auditLogsTarget";
  /**
   * auditLogsTarget from f3Web.csv (in EN: Target )
   */
  public get auditLogsTarget(): string { return this.getStringDef(this.key_auditLogsTarget, "Target"); }

  /**
   * auditLogsTriggeredBy from f3Web.csv (in EN: Triggered by )
   */
  public key_auditLogsTriggeredBy = "auditLogsTriggeredBy";
  /**
   * auditLogsTriggeredBy from f3Web.csv (in EN: Triggered by )
   */
  public get auditLogsTriggeredBy(): string { return this.getStringDef(this.key_auditLogsTriggeredBy, "Triggered by"); }

  /**
   * auditLogsTimestamp from f3Web.csv (in EN: Timestamp )
   */
  public key_auditLogsTimestamp = "auditLogsTimestamp";
  /**
   * auditLogsTimestamp from f3Web.csv (in EN: Timestamp )
   */
  public get auditLogsTimestamp(): string { return this.getStringDef(this.key_auditLogsTimestamp, "Timestamp"); }

  /**
   * auditLogsActionValue from f3Web.csv (in EN: Action value )
   */
  public key_auditLogsActionValue = "auditLogsActionValue";
  /**
   * auditLogsActionValue from f3Web.csv (in EN: Action value )
   */
  public get auditLogsActionValue(): string { return this.getStringDef(this.key_auditLogsActionValue, "Action value"); }

  /**
   * columnSelection from f3Web.csv (in EN: Columns )
   */
  public key_columnSelection = "columnSelection";
  /**
   * columnSelection from f3Web.csv (in EN: Columns )
   */
  public get columnSelection(): string { return this.getStringDef(this.key_columnSelection, "Columns"); }

  /**
   * preferredModelStorageSelection from f3Web.csv (in EN: Preferred model selection )
   */
  public key_preferredModelStorageSelection = "preferredModelStorageSelection";
  /**
   * preferredModelStorageSelection from f3Web.csv (in EN: Preferred model selection )
   */
  public get preferredModelStorageSelection(): string { return this.getStringDef(this.key_preferredModelStorageSelection, "Preferred model selection"); }

  /**
   * preferredModelSessionSelection from f3Web.csv (in EN: Current session model selection )
   */
  public key_preferredModelSessionSelection = "preferredModelSessionSelection";
  /**
   * preferredModelSessionSelection from f3Web.csv (in EN: Current session model selection )
   */
  public get preferredModelSessionSelection(): string { return this.getStringDef(this.key_preferredModelSessionSelection, "Current session model selection"); }

  /**
   * preferredModelAvailableModels from f3Web.csv (in EN: Available models )
   */
  public key_preferredModelAvailableModels = "preferredModelAvailableModels";
  /**
   * preferredModelAvailableModels from f3Web.csv (in EN: Available models )
   */
  public get preferredModelAvailableModels(): string { return this.getStringDef(this.key_preferredModelAvailableModels, "Available models"); }

  /**
   * preferredModelCurrentSessioNWarningMessage from f3Web.csv (in EN: Session model: {0} )
   */
  public key_preferredModelCurrentSessioNWarningMessage = "preferredModelCurrentSessioNWarningMessage";
  /**
   * preferredModelCurrentSessioNWarningMessage from f3Web.csv (in EN: Session model: {0} )
   */
  public preferredModelCurrentSessioNWarningMessage(val1): string { return this.getStringDef("preferredModelCurrentSessioNWarningMessage", "Session model: {0}").replace("{0}", val1); }

  /**
   * preferredModelUseOnSession from f3Web.csv (in EN: Use on session )
   */
  public key_preferredModelUseOnSession = "preferredModelUseOnSession";
  /**
   * preferredModelUseOnSession from f3Web.csv (in EN: Use on session )
   */
  public get preferredModelUseOnSession(): string { return this.getStringDef(this.key_preferredModelUseOnSession, "Use on session"); }

  /**
   * preferredModelRemoveSessionChoice from f3Web.csv (in EN: Remove session choice )
   */
  public key_preferredModelRemoveSessionChoice = "preferredModelRemoveSessionChoice";
  /**
   * preferredModelRemoveSessionChoice from f3Web.csv (in EN: Remove session choice )
   */
  public get preferredModelRemoveSessionChoice(): string { return this.getStringDef(this.key_preferredModelRemoveSessionChoice, "Remove session choice"); }

  /**
   * VariantPeriodsModel from f3Web.csv (in EN: Periods )
   */
  public key_VariantPeriodsModel = "VariantPeriodsModel";
  /**
   * VariantPeriodsModel from f3Web.csv (in EN: Periods )
   */
  public get VariantPeriodsModel(): string { return this.getStringDef(this.key_VariantPeriodsModel, "Periods"); }

  /**
   * AppName from f3.csv (in EN: Imagine )
   */
  public key_AppName = "AppName";
  /**
   * AppName from f3.csv (in EN: Imagine )
   */
  public get AppName(): string { return this.getStringDef(this.key_AppName, "Imagine"); }

  /**
   * Caption from f3.csv (in EN: Welcome to Imagine.  )
   */
  public key_Caption = "Caption";
  /**
   * Caption from f3.csv (in EN: Welcome to Imagine.  )
   */
  public get Caption(): string { return this.getStringDef(this.key_Caption, "Welcome to Imagine. "); }

  /**
   * Lang_Unknown from f3.csv (in EN: Unknown )
   */
  public key_Lang_Unknown = "Lang_Unknown";
  /**
   * Lang_Unknown from f3.csv (in EN: Unknown )
   */
  public get Lang_Unknown(): string { return this.getStringDef(this.key_Lang_Unknown, "Unknown"); }

  /**
   * Lang_EN from f3.csv (in EN: English )
   */
  public key_Lang_EN = "Lang_EN";
  /**
   * Lang_EN from f3.csv (in EN: English )
   */
  public get Lang_EN(): string { return this.getStringDef(this.key_Lang_EN, "English"); }

  /**
   * Lang_NL from f3.csv (in EN: Dutch )
   */
  public key_Lang_NL = "Lang_NL";
  /**
   * Lang_NL from f3.csv (in EN: Dutch )
   */
  public get Lang_NL(): string { return this.getStringDef(this.key_Lang_NL, "Dutch"); }

  /**
   * Lang_FR from f3.csv (in EN: French )
   */
  public key_Lang_FR = "Lang_FR";
  /**
   * Lang_FR from f3.csv (in EN: French )
   */
  public get Lang_FR(): string { return this.getStringDef(this.key_Lang_FR, "French"); }

  /**
   * Lang_FI from f3.csv (in EN: Finnish )
   */
  public key_Lang_FI = "Lang_FI";
  /**
   * Lang_FI from f3.csv (in EN: Finnish )
   */
  public get Lang_FI(): string { return this.getStringDef(this.key_Lang_FI, "Finnish"); }

  /**
   * Lang_IT from f3.csv (in EN: Italian )
   */
  public key_Lang_IT = "Lang_IT";
  /**
   * Lang_IT from f3.csv (in EN: Italian )
   */
  public get Lang_IT(): string { return this.getStringDef(this.key_Lang_IT, "Italian"); }

  /**
   * Lang_DE from f3.csv (in EN: German )
   */
  public key_Lang_DE = "Lang_DE";
  /**
   * Lang_DE from f3.csv (in EN: German )
   */
  public get Lang_DE(): string { return this.getStringDef(this.key_Lang_DE, "German"); }

  /**
   * Name from f3.csv (in EN: Name: )
   */
  public key_Name = "Name";
  /**
   * Name from f3.csv (in EN: Name: )
   */
  public get Name(): string { return this.getStringDef(this.key_Name, "Name:"); }

  /**
   * FileOpen from f3.csv (in EN: Open )
   */
  public key_FileOpen = "FileOpen";
  /**
   * FileOpen from f3.csv (in EN: Open )
   */
  public get FileOpen(): string { return this.getStringDef(this.key_FileOpen, "Open"); }

  /**
   * FileSave from f3.csv (in EN: Save )
   */
  public key_FileSave = "FileSave";
  /**
   * FileSave from f3.csv (in EN: Save )
   */
  public get FileSave(): string { return this.getStringDef(this.key_FileSave, "Save"); }

  /**
   * ButtonOk from f3.csv (in EN: OK )
   */
  public key_ButtonOk = "ButtonOk";
  /**
   * ButtonOk from f3.csv (in EN: OK )
   */
  public get ButtonOk(): string { return this.getStringDef(this.key_ButtonOk, "OK"); }

  /**
   * ButtonCancel from f3.csv (in EN: Cancel )
   */
  public key_ButtonCancel = "ButtonCancel";
  /**
   * ButtonCancel from f3.csv (in EN: Cancel )
   */
  public get ButtonCancel(): string { return this.getStringDef(this.key_ButtonCancel, "Cancel"); }

  /**
   * ButtonClose from f3.csv (in EN: Close )
   */
  public key_ButtonClose = "ButtonClose";
  /**
   * ButtonClose from f3.csv (in EN: Close )
   */
  public get ButtonClose(): string { return this.getStringDef(this.key_ButtonClose, "Close"); }

  /**
   * ButtonEdit from f3.csv (in EN: Edit )
   */
  public key_ButtonEdit = "ButtonEdit";
  /**
   * ButtonEdit from f3.csv (in EN: Edit )
   */
  public get ButtonEdit(): string { return this.getStringDef(this.key_ButtonEdit, "Edit"); }

  /**
   * ButtonNew from f3.csv (in EN: New )
   */
  public key_ButtonNew = "ButtonNew";
  /**
   * ButtonNew from f3.csv (in EN: New )
   */
  public get ButtonNew(): string { return this.getStringDef(this.key_ButtonNew, "New"); }

  /**
   * ButtonSave from f3.csv (in EN: Save )
   */
  public key_ButtonSave = "ButtonSave";
  /**
   * ButtonSave from f3.csv (in EN: Save )
   */
  public get ButtonSave(): string { return this.getStringDef(this.key_ButtonSave, "Save"); }

  /**
   * ButtonDelete from f3.csv (in EN: Delete )
   */
  public key_ButtonDelete = "ButtonDelete";
  /**
   * ButtonDelete from f3.csv (in EN: Delete )
   */
  public get ButtonDelete(): string { return this.getStringDef(this.key_ButtonDelete, "Delete"); }

  /**
   * ButtonDownload from f3.csv (in EN: Download )
   */
  public key_ButtonDownload = "ButtonDownload";
  /**
   * ButtonDownload from f3.csv (in EN: Download )
   */
  public get ButtonDownload(): string { return this.getStringDef(this.key_ButtonDownload, "Download"); }

  /**
   * ButtonMoveUp from f3.csv (in EN: Move up )
   */
  public key_ButtonMoveUp = "ButtonMoveUp";
  /**
   * ButtonMoveUp from f3.csv (in EN: Move up )
   */
  public get ButtonMoveUp(): string { return this.getStringDef(this.key_ButtonMoveUp, "Move up"); }

  /**
   * ButtonMoveDown from f3.csv (in EN: Move down )
   */
  public key_ButtonMoveDown = "ButtonMoveDown";
  /**
   * ButtonMoveDown from f3.csv (in EN: Move down )
   */
  public get ButtonMoveDown(): string { return this.getStringDef(this.key_ButtonMoveDown, "Move down"); }

  /**
   * ButtonApply from f3.csv (in EN: Apply )
   */
  public key_ButtonApply = "ButtonApply";
  /**
   * ButtonApply from f3.csv (in EN: Apply )
   */
  public get ButtonApply(): string { return this.getStringDef(this.key_ButtonApply, "Apply"); }

  /**
   * ButtonSelectAll from f3.csv (in EN: Select all )
   */
  public key_ButtonSelectAll = "ButtonSelectAll";
  /**
   * ButtonSelectAll from f3.csv (in EN: Select all )
   */
  public get ButtonSelectAll(): string { return this.getStringDef(this.key_ButtonSelectAll, "Select all"); }

  /**
   * MenuExit from f3.csv (in EN: Exit )
   */
  public key_MenuExit = "MenuExit";
  /**
   * MenuExit from f3.csv (in EN: Exit )
   */
  public get MenuExit(): string { return this.getStringDef(this.key_MenuExit, "Exit"); }

  /**
   * MenuAbout from f3.csv (in EN: About )
   */
  public key_MenuAbout = "MenuAbout";
  /**
   * MenuAbout from f3.csv (in EN: About )
   */
  public get MenuAbout(): string { return this.getStringDef(this.key_MenuAbout, "About"); }

  /**
   * MenuHelp from f3.csv (in EN: Help )
   */
  public key_MenuHelp = "MenuHelp";
  /**
   * MenuHelp from f3.csv (in EN: Help )
   */
  public get MenuHelp(): string { return this.getStringDef(this.key_MenuHelp, "Help"); }

  /**
   * MenuFile from f3.csv (in EN: File )
   */
  public key_MenuFile = "MenuFile";
  /**
   * MenuFile from f3.csv (in EN: File )
   */
  public get MenuFile(): string { return this.getStringDef(this.key_MenuFile, "File"); }

  /**
   * MenuNew from f3.csv (in EN: New )
   */
  public key_MenuNew = "MenuNew";
  /**
   * MenuNew from f3.csv (in EN: New )
   */
  public get MenuNew(): string { return this.getStringDef(this.key_MenuNew, "New"); }

  /**
   * MenuOpen from f3.csv (in EN: Open )
   */
  public key_MenuOpen = "MenuOpen";
  /**
   * MenuOpen from f3.csv (in EN: Open )
   */
  public get MenuOpen(): string { return this.getStringDef(this.key_MenuOpen, "Open"); }

  /**
   * MenuSave from f3.csv (in EN: Save )
   */
  public key_MenuSave = "MenuSave";
  /**
   * MenuSave from f3.csv (in EN: Save )
   */
  public get MenuSave(): string { return this.getStringDef(this.key_MenuSave, "Save"); }

  /**
   * MenuSaveAs from f3.csv (in EN: Save As )
   */
  public key_MenuSaveAs = "MenuSaveAs";
  /**
   * MenuSaveAs from f3.csv (in EN: Save As )
   */
  public get MenuSaveAs(): string { return this.getStringDef(this.key_MenuSaveAs, "Save As"); }

  /**
   * MenuClose from f3.csv (in EN: Close )
   */
  public key_MenuClose = "MenuClose";
  /**
   * MenuClose from f3.csv (in EN: Close )
   */
  public get MenuClose(): string { return this.getStringDef(this.key_MenuClose, "Close"); }

  /**
   * MenuSettings from f3.csv (in EN: Settings )
   */
  public key_MenuSettings = "MenuSettings";
  /**
   * MenuSettings from f3.csv (in EN: Settings )
   */
  public get MenuSettings(): string { return this.getStringDef(this.key_MenuSettings, "Settings"); }

  /**
   * PleaseWait from f3.csv (in EN: Please wait )
   */
  public key_PleaseWait = "PleaseWait";
  /**
   * PleaseWait from f3.csv (in EN: Please wait )
   */
  public get PleaseWait(): string { return this.getStringDef(this.key_PleaseWait, "Please wait"); }

  /**
   * Cut from f3.csv (in EN: Cut )
   */
  public key_Cut = "Cut";
  /**
   * Cut from f3.csv (in EN: Cut )
   */
  public get Cut(): string { return this.getStringDef(this.key_Cut, "Cut"); }

  /**
   * Paste from f3.csv (in EN: Paste )
   */
  public key_Paste = "Paste";
  /**
   * Paste from f3.csv (in EN: Paste )
   */
  public get Paste(): string { return this.getStringDef(this.key_Paste, "Paste"); }

  /**
   * Copy from f3.csv (in EN: Copy )
   */
  public key_Copy = "Copy";
  /**
   * Copy from f3.csv (in EN: Copy )
   */
  public get Copy(): string { return this.getStringDef(this.key_Copy, "Copy"); }

  /**
   * Undo from f3.csv (in EN: Undo )
   */
  public key_Undo = "Undo";
  /**
   * Undo from f3.csv (in EN: Undo )
   */
  public get Undo(): string { return this.getStringDef(this.key_Undo, "Undo"); }

  /**
   * Redo from f3.csv (in EN: Redo )
   */
  public key_Redo = "Redo";
  /**
   * Redo from f3.csv (in EN: Redo )
   */
  public get Redo(): string { return this.getStringDef(this.key_Redo, "Redo"); }

  /**
   * Error from f3.csv (in EN: Error )
   */
  public key_Error = "Error";
  /**
   * Error from f3.csv (in EN: Error )
   */
  public get Error(): string { return this.getStringDef(this.key_Error, "Error"); }

  /**
   * Warning from f3.csv (in EN: Warning )
   */
  public key_Warning = "Warning";
  /**
   * Warning from f3.csv (in EN: Warning )
   */
  public get Warning(): string { return this.getStringDef(this.key_Warning, "Warning"); }

  /**
   * Errors from f3.csv (in EN: Error )
   */
  public key_Errors = "Errors";
  /**
   * Errors from f3.csv (in EN: Error )
   */
  public get Errors(): string { return this.getStringDef(this.key_Errors, "Error"); }

  /**
   * Warnings from f3.csv (in EN: Warnings )
   */
  public key_Warnings = "Warnings";
  /**
   * Warnings from f3.csv (in EN: Warnings )
   */
  public get Warnings(): string { return this.getStringDef(this.key_Warnings, "Warnings"); }

  /**
   * Password from f3.csv (in EN: Password: )
   */
  public key_Password = "Password";
  /**
   * Password from f3.csv (in EN: Password: )
   */
  public get Password(): string { return this.getStringDef(this.key_Password, "Password:"); }

  /**
   * ProjectFile from f3.csv (in EN: Project: )
   */
  public key_ProjectFile = "ProjectFile";
  /**
   * ProjectFile from f3.csv (in EN: Project: )
   */
  public get ProjectFile(): string { return this.getStringDef(this.key_ProjectFile, "Project:"); }

  /**
   * ButtonLogin from f3.csv (in EN: login }}} )
   */
  public key_ButtonLogin = "ButtonLogin";
  /**
   * ButtonLogin from f3.csv (in EN: login }}} )
   */
  public get ButtonLogin(): string { return this.getStringDef(this.key_ButtonLogin, "login >>>"); }

  /**
   * LogOpenProjectFailed from f3.csv (in EN: Error opening project '{0}' for user '{1}', because: {2}. )
   */
  public key_LogOpenProjectFailed = "LogOpenProjectFailed";
  /**
   * LogOpenProjectFailed from f3.csv (in EN: Error opening project '{0}' for user '{1}', because: {2}. )
   */
  public LogOpenProjectFailed(val1, val2, val3): string { return this.getStringDef("LogOpenProjectFailed", "Error opening project '{0}' for user '{1}', because: {2}.").replace("{2}", val3).replace("{1}", val2).replace("{0}", val1); }

  /**
   * ModelShortNameInvalidAdjusted from f3.csv (in EN: The model element shortname is incorrect; valid characters: letters, numbers, underscore; the name has been adjusted accordingly. )
   */
  public key_ModelShortNameInvalidAdjusted = "ModelShortNameInvalidAdjusted";
  /**
   * ModelShortNameInvalidAdjusted from f3.csv (in EN: The model element shortname is incorrect; valid characters: letters, numbers, underscore; the name has been adjusted accordingly. )
   */
  public get ModelShortNameInvalidAdjusted(): string { return this.getStringDef(this.key_ModelShortNameInvalidAdjusted, "The model element shortname is incorrect; valid characters: letters, numbers, underscore; the name has been adjusted accordingly."); }

  /**
   * FileTypeUnknownFilter from f3.csv (in EN: All files (*.*)|*.* )
   */
  public key_FileTypeUnknownFilter = "FileTypeUnknownFilter";
  /**
   * FileTypeUnknownFilter from f3.csv (in EN: All files (*.*)|*.* )
   */
  public get FileTypeUnknownFilter(): string { return this.getStringDef(this.key_FileTypeUnknownFilter, "All files (*.*)|*.*"); }

  /**
   * FileTypeProjectFilter from f3.csv (in EN: Project files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypeProjectFilter = "FileTypeProjectFilter";
  /**
   * FileTypeProjectFilter from f3.csv (in EN: Project files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypeProjectFilter(val1): string { return this.getStringDef("FileTypeProjectFilter", "Project files (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeImportDefinitionFilter from f3.csv (in EN: Connector files (*.{0})|*.{0} )
   */
  public key_FileTypeImportDefinitionFilter = "FileTypeImportDefinitionFilter";
  /**
   * FileTypeImportDefinitionFilter from f3.csv (in EN: Connector files (*.{0})|*.{0} )
   */
  public FileTypeImportDefinitionFilter(val1): string { return this.getStringDef("FileTypeImportDefinitionFilter", "Connector files (*.{0})|*.{0}").replace("{0}", val1); }

  /**
   * FileTypePatchFilter from f3.csv (in EN: Patch files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypePatchFilter = "FileTypePatchFilter";
  /**
   * FileTypePatchFilter from f3.csv (in EN: Patch files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypePatchFilter(val1): string { return this.getStringDef("FileTypePatchFilter", "Patch files (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeDataSetFileFilter from f3.csv (in EN: Dataset export file (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypeDataSetFileFilter = "FileTypeDataSetFileFilter";
  /**
   * FileTypeDataSetFileFilter from f3.csv (in EN: Dataset export file (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypeDataSetFileFilter(val1): string { return this.getStringDef("FileTypeDataSetFileFilter", "Dataset export file (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeModelFilter from f3.csv (in EN: Model export file (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypeModelFilter = "FileTypeModelFilter";
  /**
   * FileTypeModelFilter from f3.csv (in EN: Model export file (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypeModelFilter(val1): string { return this.getStringDef("FileTypeModelFilter", "Model export file (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeModelBackupFilter from f3.csv (in EN: Model backup files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypeModelBackupFilter = "FileTypeModelBackupFilter";
  /**
   * FileTypeModelBackupFilter from f3.csv (in EN: Model backup files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypeModelBackupFilter(val1): string { return this.getStringDef("FileTypeModelBackupFilter", "Model backup files (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeInspireCmsBackupFilter from f3.csv (in EN: Inspire Cms backup files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypeInspireCmsBackupFilter = "FileTypeInspireCmsBackupFilter";
  /**
   * FileTypeInspireCmsBackupFilter from f3.csv (in EN: Inspire Cms backup files (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypeInspireCmsBackupFilter(val1): string { return this.getStringDef("FileTypeInspireCmsBackupFilter", "Inspire Cms backup files (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeGridLayoutFilter from f3.csv (in EN: Souplesse table layout (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public key_FileTypeGridLayoutFilter = "FileTypeGridLayoutFilter";
  /**
   * FileTypeGridLayoutFilter from f3.csv (in EN: Souplesse table layout (*.{0})|*.{0}|All files (*.*)|*.* )
   */
  public FileTypeGridLayoutFilter(val1): string { return this.getStringDef("FileTypeGridLayoutFilter", "Souplesse table layout (*.{0})|*.{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeDashletFilter from f3.csv (in EN: Dashlets (*{0})|*{0}|All files (*.*)|*.* )
   */
  public key_FileTypeDashletFilter = "FileTypeDashletFilter";
  /**
   * FileTypeDashletFilter from f3.csv (in EN: Dashlets (*{0})|*{0}|All files (*.*)|*.* )
   */
  public FileTypeDashletFilter(val1): string { return this.getStringDef("FileTypeDashletFilter", "Dashlets (*{0})|*{0}|All files (*.*)|*.*").replace("{0}", val1); }

  /**
   * FileTypeExcelFilter from f3.csv (in EN: Excel bestanden (*.xlsx,*.xls)|*.xlsx;*.xls|All files (*.*)|*.* )
   */
  public key_FileTypeExcelFilter = "FileTypeExcelFilter";
  /**
   * FileTypeExcelFilter from f3.csv (in EN: Excel bestanden (*.xlsx,*.xls)|*.xlsx;*.xls|All files (*.*)|*.* )
   */
  public get FileTypeExcelFilter(): string { return this.getStringDef(this.key_FileTypeExcelFilter, "Excel bestanden (*.xlsx,*.xls)|*.xlsx;*.xls|All files (*.*)|*.*"); }

  /**
   * FileTypeCsvFilter from f3.csv (in EN: Csv bestanden (*.csv)|*.csv|All files (*.*)|*.* )
   */
  public key_FileTypeCsvFilter = "FileTypeCsvFilter";
  /**
   * FileTypeCsvFilter from f3.csv (in EN: Csv bestanden (*.csv)|*.csv|All files (*.*)|*.* )
   */
  public get FileTypeCsvFilter(): string { return this.getStringDef(this.key_FileTypeCsvFilter, "Csv bestanden (*.csv)|*.csv|All files (*.*)|*.*"); }

  /**
   * FileTypeImagesFilter from f3.csv (in EN: Images (*.bmp, *.jpg, *.gif, *.png, *.ico)|*.bmp;*.jpg;*.gif;*.png;*.ico )
   */
  public key_FileTypeImagesFilter = "FileTypeImagesFilter";
  /**
   * FileTypeImagesFilter from f3.csv (in EN: Images (*.bmp, *.jpg, *.gif, *.png, *.ico)|*.bmp;*.jpg;*.gif;*.png;*.ico )
   */
  public get FileTypeImagesFilter(): string { return this.getStringDef(this.key_FileTypeImagesFilter, "Images (*.bmp, *.jpg, *.gif, *.png, *.ico)|*.bmp;*.jpg;*.gif;*.png;*.ico"); }

  /**
   * FileTypeUnknown from f3.csv (in EN: Unknown )
   */
  public key_FileTypeUnknown = "FileTypeUnknown";
  /**
   * FileTypeUnknown from f3.csv (in EN: Unknown )
   */
  public get FileTypeUnknown(): string { return this.getStringDef(this.key_FileTypeUnknown, "Unknown"); }

  /**
   * FileTypeExcel from f3.csv (in EN: Excel )
   */
  public key_FileTypeExcel = "FileTypeExcel";
  /**
   * FileTypeExcel from f3.csv (in EN: Excel )
   */
  public get FileTypeExcel(): string { return this.getStringDef(this.key_FileTypeExcel, "Excel"); }

  /**
   * FileTypeCsv from f3.csv (in EN: CSV )
   */
  public key_FileTypeCsv = "FileTypeCsv";
  /**
   * FileTypeCsv from f3.csv (in EN: CSV )
   */
  public get FileTypeCsv(): string { return this.getStringDef(this.key_FileTypeCsv, "CSV"); }

  /**
   * FileTypeCsv2 from f3.csv (in EN: CSV (new edition) )
   */
  public key_FileTypeCsv2 = "FileTypeCsv2";
  /**
   * FileTypeCsv2 from f3.csv (in EN: CSV (new edition) )
   */
  public get FileTypeCsv2(): string { return this.getStringDef(this.key_FileTypeCsv2, "CSV (new edition)"); }

  /**
   * FileTypeExcelRaw from f3.csv (in EN: Excel raw )
   */
  public key_FileTypeExcelRaw = "FileTypeExcelRaw";
  /**
   * FileTypeExcelRaw from f3.csv (in EN: Excel raw )
   */
  public get FileTypeExcelRaw(): string { return this.getStringDef(this.key_FileTypeExcelRaw, "Excel raw"); }

  /**
   * FileTypePointlogicData from f3.csv (in EN: SQL Server )
   */
  public key_FileTypePointlogicData = "FileTypePointlogicData";
  /**
   * FileTypePointlogicData from f3.csv (in EN: SQL Server )
   */
  public get FileTypePointlogicData(): string { return this.getStringDef(this.key_FileTypePointlogicData, "SQL Server"); }

  /**
   * FileTypePlugin from f3.csv (in EN: Plugin )
   */
  public key_FileTypePlugin = "FileTypePlugin";
  /**
   * FileTypePlugin from f3.csv (in EN: Plugin )
   */
  public get FileTypePlugin(): string { return this.getStringDef(this.key_FileTypePlugin, "Plugin"); }

  /**
   * FileEncodingType from f3.csv (in EN: Encoding type: )
   */
  public key_FileEncodingType = "FileEncodingType";
  /**
   * FileEncodingType from f3.csv (in EN: Encoding type: )
   */
  public get FileEncodingType(): string { return this.getStringDef(this.key_FileEncodingType, "Encoding type:"); }

  /**
   * FileTypeImages from f3.csv (in EN: Images )
   */
  public key_FileTypeImages = "FileTypeImages";
  /**
   * FileTypeImages from f3.csv (in EN: Images )
   */
  public get FileTypeImages(): string { return this.getStringDef(this.key_FileTypeImages, "Images"); }

  /**
   * LoginUserName from f3.csv (in EN: User name: )
   */
  public key_LoginUserName = "LoginUserName";
  /**
   * LoginUserName from f3.csv (in EN: User name: )
   */
  public get LoginUserName(): string { return this.getStringDef(this.key_LoginUserName, "User name:"); }

  /**
   * LoginPassword from f3.csv (in EN: Password: )
   */
  public key_LoginPassword = "LoginPassword";
  /**
   * LoginPassword from f3.csv (in EN: Password: )
   */
  public get LoginPassword(): string { return this.getStringDef(this.key_LoginPassword, "Password:"); }

  /**
   * LoginCaption from f3.csv (in EN: Login )
   */
  public key_LoginCaption = "LoginCaption";
  /**
   * LoginCaption from f3.csv (in EN: Login )
   */
  public get LoginCaption(): string { return this.getStringDef(this.key_LoginCaption, "Login"); }

  /**
   * LoginProjectFile from f3.csv (in EN: Project file: )
   */
  public key_LoginProjectFile = "LoginProjectFile";
  /**
   * LoginProjectFile from f3.csv (in EN: Project file: )
   */
  public get LoginProjectFile(): string { return this.getStringDef(this.key_LoginProjectFile, "Project file:"); }

  /**
   * Rollen from f3.csv (in EN: Roles )
   */
  public key_Rollen = "Rollen";
  /**
   * Rollen from f3.csv (in EN: Roles )
   */
  public get Rollen(): string { return this.getStringDef(this.key_Rollen, "Roles"); }

  /**
   * AboutCaption from f3.csv (in EN: About )
   */
  public key_AboutCaption = "AboutCaption";
  /**
   * AboutCaption from f3.csv (in EN: About )
   */
  public get AboutCaption(): string { return this.getStringDef(this.key_AboutCaption, "About"); }

  /**
   * AboutCopyright from f3.csv (in EN:  © Pointlogic HR BV. )
   */
  public key_AboutCopyright = "AboutCopyright";
  /**
   * AboutCopyright from f3.csv (in EN:  © Pointlogic HR BV. )
   */
  public get AboutCopyright(): string { return this.getStringDef(this.key_AboutCopyright, " © Pointlogic HR BV."); }

  /**
   * AboutGroupDatabase from f3.csv (in EN: Database )
   */
  public key_AboutGroupDatabase = "AboutGroupDatabase";
  /**
   * AboutGroupDatabase from f3.csv (in EN: Database )
   */
  public get AboutGroupDatabase(): string { return this.getStringDef(this.key_AboutGroupDatabase, "Database"); }

  /**
   * AboutGroupUser from f3.csv (in EN: User information )
   */
  public key_AboutGroupUser = "AboutGroupUser";
  /**
   * AboutGroupUser from f3.csv (in EN: User information )
   */
  public get AboutGroupUser(): string { return this.getStringDef(this.key_AboutGroupUser, "User information"); }

  /**
   * AboutUser from f3.csv (in EN: User: )
   */
  public key_AboutUser = "AboutUser";
  /**
   * AboutUser from f3.csv (in EN: User: )
   */
  public get AboutUser(): string { return this.getStringDef(this.key_AboutUser, "User:"); }

  /**
   * AboutRole from f3.csv (in EN:  Role: )
   */
  public key_AboutRole = "AboutRole";
  /**
   * AboutRole from f3.csv (in EN:  Role: )
   */
  public get AboutRole(): string { return this.getStringDef(this.key_AboutRole, " Role:"); }

  /**
   * AboutServer from f3.csv (in EN: Server: )
   */
  public key_AboutServer = "AboutServer";
  /**
   * AboutServer from f3.csv (in EN: Server: )
   */
  public get AboutServer(): string { return this.getStringDef(this.key_AboutServer, "Server:"); }

  /**
   * AboutDatabase from f3.csv (in EN: Database: )
   */
  public key_AboutDatabase = "AboutDatabase";
  /**
   * AboutDatabase from f3.csv (in EN: Database: )
   */
  public get AboutDatabase(): string { return this.getStringDef(this.key_AboutDatabase, "Database:"); }

  /**
   * AboutGroupProduct from f3.csv (in EN: Product )
   */
  public key_AboutGroupProduct = "AboutGroupProduct";
  /**
   * AboutGroupProduct from f3.csv (in EN: Product )
   */
  public get AboutGroupProduct(): string { return this.getStringDef(this.key_AboutGroupProduct, "Product"); }

  /**
   * AboutProductVersion from f3.csv (in EN: Product version: )
   */
  public key_AboutProductVersion = "AboutProductVersion";
  /**
   * AboutProductVersion from f3.csv (in EN: Product version: )
   */
  public get AboutProductVersion(): string { return this.getStringDef(this.key_AboutProductVersion, "Product version:"); }

  /**
   * AboutModelVersion from f3.csv (in EN: Model version: )
   */
  public key_AboutModelVersion = "AboutModelVersion";
  /**
   * AboutModelVersion from f3.csv (in EN: Model version: )
   */
  public get AboutModelVersion(): string { return this.getStringDef(this.key_AboutModelVersion, "Model version:"); }

  /**
   * AboutFileVersion from f3.csv (in EN: File version: )
   */
  public key_AboutFileVersion = "AboutFileVersion";
  /**
   * AboutFileVersion from f3.csv (in EN: File version: )
   */
  public get AboutFileVersion(): string { return this.getStringDef(this.key_AboutFileVersion, "File version:"); }

  /**
   * AboutBuildNumber from f3.csv (in EN: Build number: )
   */
  public key_AboutBuildNumber = "AboutBuildNumber";
  /**
   * AboutBuildNumber from f3.csv (in EN: Build number: )
   */
  public get AboutBuildNumber(): string { return this.getStringDef(this.key_AboutBuildNumber, "Build number:"); }

  /**
   * AboutCommitHash from f3.csv (in EN: Commit hash: )
   */
  public key_AboutCommitHash = "AboutCommitHash";
  /**
   * AboutCommitHash from f3.csv (in EN: Commit hash: )
   */
  public get AboutCommitHash(): string { return this.getStringDef(this.key_AboutCommitHash, "Commit hash:"); }

  /**
   * AboutUnknown from f3.csv (in EN: unknown )
   */
  public key_AboutUnknown = "AboutUnknown";
  /**
   * AboutUnknown from f3.csv (in EN: unknown )
   */
  public get AboutUnknown(): string { return this.getStringDef(this.key_AboutUnknown, "unknown"); }

  /**
   * AboutMachine from f3.csv (in EN: Machine: )
   */
  public key_AboutMachine = "AboutMachine";
  /**
   * AboutMachine from f3.csv (in EN: Machine: )
   */
  public get AboutMachine(): string { return this.getStringDef(this.key_AboutMachine, "Machine:"); }

  /**
   * AboutDataConnectionStatus from f3.csv (in EN: Status: {0} )
   */
  public key_AboutDataConnectionStatus = "AboutDataConnectionStatus";
  /**
   * AboutDataConnectionStatus from f3.csv (in EN: Status: {0} )
   */
  public AboutDataConnectionStatus(val1): string { return this.getStringDef("AboutDataConnectionStatus", "Status: {0}").replace("{0}", val1); }

  /**
   * MainNoActiveFileInUse from f3.csv (in EN: No project is active, use menu to open a project. )
   */
  public key_MainNoActiveFileInUse = "MainNoActiveFileInUse";
  /**
   * MainNoActiveFileInUse from f3.csv (in EN: No project is active, use menu to open a project. )
   */
  public get MainNoActiveFileInUse(): string { return this.getStringDef(this.key_MainNoActiveFileInUse, "No project is active, use menu to open a project."); }

  /**
   * MainCloseActiveViewModel from f3.csv (in EN: Close )
   */
  public key_MainCloseActiveViewModel = "MainCloseActiveViewModel";
  /**
   * MainCloseActiveViewModel from f3.csv (in EN: Close )
   */
  public get MainCloseActiveViewModel(): string { return this.getStringDef(this.key_MainCloseActiveViewModel, "Close"); }

  /**
   * menuChangeToNL from f3.csv (in EN: Application in Dutch )
   */
  public key_menuChangeToNL = "menuChangeToNL";
  /**
   * menuChangeToNL from f3.csv (in EN: Application in Dutch )
   */
  public get menuChangeToNL(): string { return this.getStringDef(this.key_menuChangeToNL, "Application in Dutch"); }

  /**
   * menuChangeToEN from f3.csv (in EN: Application in English )
   */
  public key_menuChangeToEN = "menuChangeToEN";
  /**
   * menuChangeToEN from f3.csv (in EN: Application in English )
   */
  public get menuChangeToEN(): string { return this.getStringDef(this.key_menuChangeToEN, "Application in English"); }

  /**
   * menuChangeToFR from f3.csv (in EN: Application in French )
   */
  public key_menuChangeToFR = "menuChangeToFR";
  /**
   * menuChangeToFR from f3.csv (in EN: Application in French )
   */
  public get menuChangeToFR(): string { return this.getStringDef(this.key_menuChangeToFR, "Application in French"); }

  /**
   * menuChangeToDE from f3.csv (in EN: Application in German )
   */
  public key_menuChangeToDE = "menuChangeToDE";
  /**
   * menuChangeToDE from f3.csv (in EN: Application in German )
   */
  public get menuChangeToDE(): string { return this.getStringDef(this.key_menuChangeToDE, "Application in German"); }

  /**
   * ErrorName from f3.csv (in EN: There is no username. )
   */
  public key_ErrorName = "ErrorName";
  /**
   * ErrorName from f3.csv (in EN: There is no username. )
   */
  public get ErrorName(): string { return this.getStringDef(this.key_ErrorName, "There is no username."); }

  /**
   * ErrorPassword from f3.csv (in EN: There is no password. )
   */
  public key_ErrorPassword = "ErrorPassword";
  /**
   * ErrorPassword from f3.csv (in EN: There is no password. )
   */
  public get ErrorPassword(): string { return this.getStringDef(this.key_ErrorPassword, "There is no password."); }

  /**
   * ErrorProjectFileNotSelected from f3.csv (in EN: No projectfile selected. )
   */
  public key_ErrorProjectFileNotSelected = "ErrorProjectFileNotSelected";
  /**
   * ErrorProjectFileNotSelected from f3.csv (in EN: No projectfile selected. )
   */
  public get ErrorProjectFileNotSelected(): string { return this.getStringDef(this.key_ErrorProjectFileNotSelected, "No projectfile selected."); }

  /**
   * ErrorProjectFileNotFound from f3.csv (in EN: Error opening projectfile: '{0}'. )
   */
  public key_ErrorProjectFileNotFound = "ErrorProjectFileNotFound";
  /**
   * ErrorProjectFileNotFound from f3.csv (in EN: Error opening projectfile: '{0}'. )
   */
  public ErrorProjectFileNotFound(val1): string { return this.getStringDef("ErrorProjectFileNotFound", "Error opening projectfile: '{0}'.").replace("{0}", val1); }

  /**
   * IniFileNotFound from f3.csv (in EN: f3.ini not found: '{0}'. )
   */
  public key_IniFileNotFound = "IniFileNotFound";
  /**
   * IniFileNotFound from f3.csv (in EN: f3.ini not found: '{0}'. )
   */
  public IniFileNotFound(val1): string { return this.getStringDef("IniFileNotFound", "f3.ini not found: '{0}'.").replace("{0}", val1); }

  /**
   * LinkedApplicationNotFound from f3.csv (in EN: Linked application could not be found: {0}. )
   */
  public key_LinkedApplicationNotFound = "LinkedApplicationNotFound";
  /**
   * LinkedApplicationNotFound from f3.csv (in EN: Linked application could not be found: {0}. )
   */
  public LinkedApplicationNotFound(val1): string { return this.getStringDef("LinkedApplicationNotFound", "Linked application could not be found: {0}.").replace("{0}", val1); }

  /**
   * WelcomeText from f3.csv (in EN:  Please login with your name and password.Select a project file. )
   */
  public key_WelcomeText = "WelcomeText";
  /**
   * WelcomeText from f3.csv (in EN:  Please login with your name and password.Select a project file. )
   */
  public get WelcomeText(): string { return this.getStringDef(this.key_WelcomeText, " Please login with your name and password.Select a project file."); }

  /**
   * ProjectSelection from f3.csv (in EN: ... )
   */
  public key_ProjectSelection = "ProjectSelection";
  /**
   * ProjectSelection from f3.csv (in EN: ... )
   */
  public get ProjectSelection(): string { return this.getStringDef(this.key_ProjectSelection, "..."); }

  /**
   * ProjectSelectionHint from f3.csv (in EN: This button enables you to choose a project file. )
   */
  public key_ProjectSelectionHint = "ProjectSelectionHint";
  /**
   * ProjectSelectionHint from f3.csv (in EN: This button enables you to choose a project file. )
   */
  public get ProjectSelectionHint(): string { return this.getStringDef(this.key_ProjectSelectionHint, "This button enables you to choose a project file."); }

  /**
   * CopyrightIncludeCompany from f3.csv (in EN: Copyright {0} - Pointlogic HR B.V. )
   */
  public key_CopyrightIncludeCompany = "CopyrightIncludeCompany";
  /**
   * CopyrightIncludeCompany from f3.csv (in EN: Copyright {0} - Pointlogic HR B.V. )
   */
  public CopyrightIncludeCompany(val1): string { return this.getStringDef("CopyrightIncludeCompany", "Copyright {0} - Pointlogic HR B.V.").replace("{0}", val1); }

  /**
   * FunctionalityUnknown from f3.csv (in EN: Not assigned )
   */
  public key_FunctionalityUnknown = "FunctionalityUnknown";
  /**
   * FunctionalityUnknown from f3.csv (in EN: Not assigned )
   */
  public get FunctionalityUnknown(): string { return this.getStringDef(this.key_FunctionalityUnknown, "Not assigned"); }

  /**
   * FunctionalityViewDataSet from f3.csv (in EN: View dataset )
   */
  public key_FunctionalityViewDataSet = "FunctionalityViewDataSet";
  /**
   * FunctionalityViewDataSet from f3.csv (in EN: View dataset )
   */
  public get FunctionalityViewDataSet(): string { return this.getStringDef(this.key_FunctionalityViewDataSet, "View dataset"); }

  /**
   * FunctionalityEditBaseModel from f3.csv (in EN: Modify base model )
   */
  public key_FunctionalityEditBaseModel = "FunctionalityEditBaseModel";
  /**
   * FunctionalityEditBaseModel from f3.csv (in EN: Modify base model )
   */
  public get FunctionalityEditBaseModel(): string { return this.getStringDef(this.key_FunctionalityEditBaseModel, "Modify base model"); }

  /**
   * FunctionalityManageModel from f3.csv (in EN: Model management )
   */
  public key_FunctionalityManageModel = "FunctionalityManageModel";
  /**
   * FunctionalityManageModel from f3.csv (in EN: Model management )
   */
  public get FunctionalityManageModel(): string { return this.getStringDef(this.key_FunctionalityManageModel, "Model management"); }

  /**
   * FunctionalityManageDataSets from f3.csv (in EN: Dataset management )
   */
  public key_FunctionalityManageDataSets = "FunctionalityManageDataSets";
  /**
   * FunctionalityManageDataSets from f3.csv (in EN: Dataset management )
   */
  public get FunctionalityManageDataSets(): string { return this.getStringDef(this.key_FunctionalityManageDataSets, "Dataset management"); }

  /**
   * FunctionalityManageTriggers from f3.csv (in EN: Trigger management )
   */
  public key_FunctionalityManageTriggers = "FunctionalityManageTriggers";
  /**
   * FunctionalityManageTriggers from f3.csv (in EN: Trigger management )
   */
  public get FunctionalityManageTriggers(): string { return this.getStringDef(this.key_FunctionalityManageTriggers, "Trigger management"); }

  /**
   * FunctionalityManageCategories from f3.csv (in EN: Category management )
   */
  public key_FunctionalityManageCategories = "FunctionalityManageCategories";
  /**
   * FunctionalityManageCategories from f3.csv (in EN: Category management )
   */
  public get FunctionalityManageCategories(): string { return this.getStringDef(this.key_FunctionalityManageCategories, "Category management"); }

  /**
   * FunctionalityManageAuthorisation from f3.csv (in EN: Authorisation management )
   */
  public key_FunctionalityManageAuthorisation = "FunctionalityManageAuthorisation";
  /**
   * FunctionalityManageAuthorisation from f3.csv (in EN: Authorisation management )
   */
  public get FunctionalityManageAuthorisation(): string { return this.getStringDef(this.key_FunctionalityManageAuthorisation, "Authorisation management"); }

  /**
   * FunctionalityManageUsers from f3.csv (in EN: User management )
   */
  public key_FunctionalityManageUsers = "FunctionalityManageUsers";
  /**
   * FunctionalityManageUsers from f3.csv (in EN: User management )
   */
  public get FunctionalityManageUsers(): string { return this.getStringDef(this.key_FunctionalityManageUsers, "User management"); }

  /**
   * FunctionalityCreatePatch from f3.csv (in EN: Create patch )
   */
  public key_FunctionalityCreatePatch = "FunctionalityCreatePatch";
  /**
   * FunctionalityCreatePatch from f3.csv (in EN: Create patch )
   */
  public get FunctionalityCreatePatch(): string { return this.getStringDef(this.key_FunctionalityCreatePatch, "Create patch"); }

  /**
   * FunctionalityExecutePatch from f3.csv (in EN: Execute patch )
   */
  public key_FunctionalityExecutePatch = "FunctionalityExecutePatch";
  /**
   * FunctionalityExecutePatch from f3.csv (in EN: Execute patch )
   */
  public get FunctionalityExecutePatch(): string { return this.getStringDef(this.key_FunctionalityExecutePatch, "Execute patch"); }

  /**
   * VariantNameHeader from f3.csv (in EN: Variant )
   */
  public key_VariantNameHeader = "VariantNameHeader";
  /**
   * VariantNameHeader from f3.csv (in EN: Variant )
   */
  public get VariantNameHeader(): string { return this.getStringDef(this.key_VariantNameHeader, "Variant"); }

  /**
   * FunctionalityEditNonOwnedVariants from f3.csv (in EN: Modify non-owned variants )
   */
  public key_FunctionalityEditNonOwnedVariants = "FunctionalityEditNonOwnedVariants";
  /**
   * FunctionalityEditNonOwnedVariants from f3.csv (in EN: Modify non-owned variants )
   */
  public get FunctionalityEditNonOwnedVariants(): string { return this.getStringDef(this.key_FunctionalityEditNonOwnedVariants, "Modify non-owned variants"); }

  /**
   * FunctionalityImportVariants from f3.csv (in EN: Import or paste variants )
   */
  public key_FunctionalityImportVariants = "FunctionalityImportVariants";
  /**
   * FunctionalityImportVariants from f3.csv (in EN: Import or paste variants )
   */
  public get FunctionalityImportVariants(): string { return this.getStringDef(this.key_FunctionalityImportVariants, "Import or paste variants"); }

  /**
   * FunctionalityAddVariants from f3.csv (in EN: Add variants )
   */
  public key_FunctionalityAddVariants = "FunctionalityAddVariants";
  /**
   * FunctionalityAddVariants from f3.csv (in EN: Add variants )
   */
  public get FunctionalityAddVariants(): string { return this.getStringDef(this.key_FunctionalityAddVariants, "Add variants"); }

  /**
   * FunctionalityCopyVariantTo from f3.csv (in EN: Copy variant to... )
   */
  public key_FunctionalityCopyVariantTo = "FunctionalityCopyVariantTo";
  /**
   * FunctionalityCopyVariantTo from f3.csv (in EN: Copy variant to... )
   */
  public get FunctionalityCopyVariantTo(): string { return this.getStringDef(this.key_FunctionalityCopyVariantTo, "Copy variant to..."); }

  /**
   * FunctionalityAddModelElements from f3.csv (in EN: Add model elements )
   */
  public key_FunctionalityAddModelElements = "FunctionalityAddModelElements";
  /**
   * FunctionalityAddModelElements from f3.csv (in EN: Add model elements )
   */
  public get FunctionalityAddModelElements(): string { return this.getStringDef(this.key_FunctionalityAddModelElements, "Add model elements"); }

  /**
   * FunctionalityAddMutations from f3.csv (in EN: Add mutations )
   */
  public key_FunctionalityAddMutations = "FunctionalityAddMutations";
  /**
   * FunctionalityAddMutations from f3.csv (in EN: Add mutations )
   */
  public get FunctionalityAddMutations(): string { return this.getStringDef(this.key_FunctionalityAddMutations, "Add mutations"); }

  /**
   * FunctionalityManageDisplayUnits from f3.csv (in EN: Modify display model elements )
   */
  public key_FunctionalityManageDisplayUnits = "FunctionalityManageDisplayUnits";
  /**
   * FunctionalityManageDisplayUnits from f3.csv (in EN: Modify display model elements )
   */
  public get FunctionalityManageDisplayUnits(): string { return this.getStringDef(this.key_FunctionalityManageDisplayUnits, "Modify display model elements"); }

  /**
   * FunctionalityModelMaintenance from f3.csv (in EN: Model maintenance )
   */
  public key_FunctionalityModelMaintenance = "FunctionalityModelMaintenance";
  /**
   * FunctionalityModelMaintenance from f3.csv (in EN: Model maintenance )
   */
  public get FunctionalityModelMaintenance(): string { return this.getStringDef(this.key_FunctionalityModelMaintenance, "Model maintenance"); }

  /**
   * FunctionalityManageLanguageItems from f3.csv (in EN: Modify language items )
   */
  public key_FunctionalityManageLanguageItems = "FunctionalityManageLanguageItems";
  /**
   * FunctionalityManageLanguageItems from f3.csv (in EN: Modify language items )
   */
  public get FunctionalityManageLanguageItems(): string { return this.getStringDef(this.key_FunctionalityManageLanguageItems, "Modify language items"); }

  /**
   * FunctionalityModelBackup from f3.csv (in EN: Create model backup )
   */
  public key_FunctionalityModelBackup = "FunctionalityModelBackup";
  /**
   * FunctionalityModelBackup from f3.csv (in EN: Create model backup )
   */
  public get FunctionalityModelBackup(): string { return this.getStringDef(this.key_FunctionalityModelBackup, "Create model backup"); }

  /**
   * FunctionalityModelRestore from f3.csv (in EN: Restore model backup )
   */
  public key_FunctionalityModelRestore = "FunctionalityModelRestore";
  /**
   * FunctionalityModelRestore from f3.csv (in EN: Restore model backup )
   */
  public get FunctionalityModelRestore(): string { return this.getStringDef(this.key_FunctionalityModelRestore, "Restore model backup"); }

  /**
   * FunctionalityInspireCmsBackup from f3.csv (in EN: Inspire CMS backup )
   */
  public key_FunctionalityInspireCmsBackup = "FunctionalityInspireCmsBackup";
  /**
   * FunctionalityInspireCmsBackup from f3.csv (in EN: Inspire CMS backup )
   */
  public get FunctionalityInspireCmsBackup(): string { return this.getStringDef(this.key_FunctionalityInspireCmsBackup, "Inspire CMS backup"); }

  /**
   * FunctionalityInspireCmsRestore from f3.csv (in EN: Inspire CMS restore )
   */
  public key_FunctionalityInspireCmsRestore = "FunctionalityInspireCmsRestore";
  /**
   * FunctionalityInspireCmsRestore from f3.csv (in EN: Inspire CMS restore )
   */
  public get FunctionalityInspireCmsRestore(): string { return this.getStringDef(this.key_FunctionalityInspireCmsRestore, "Inspire CMS restore"); }

  /**
   * FunctionalityManageSystem from f3.csv (in EN: Manage system settings )
   */
  public key_FunctionalityManageSystem = "FunctionalityManageSystem";
  /**
   * FunctionalityManageSystem from f3.csv (in EN: Manage system settings )
   */
  public get FunctionalityManageSystem(): string { return this.getStringDef(this.key_FunctionalityManageSystem, "Manage system settings"); }

  /**
   * FunctionalityManagePublishedExportItems from f3.csv (in EN: Manage export definitions )
   */
  public key_FunctionalityManagePublishedExportItems = "FunctionalityManagePublishedExportItems";
  /**
   * FunctionalityManagePublishedExportItems from f3.csv (in EN: Manage export definitions )
   */
  public get FunctionalityManagePublishedExportItems(): string { return this.getStringDef(this.key_FunctionalityManagePublishedExportItems, "Manage export definitions"); }

  /**
   * FunctionalityManagePublishedImportItems from f3.csv (in EN: Manage import definitions )
   */
  public key_FunctionalityManagePublishedImportItems = "FunctionalityManagePublishedImportItems";
  /**
   * FunctionalityManagePublishedImportItems from f3.csv (in EN: Manage import definitions )
   */
  public get FunctionalityManagePublishedImportItems(): string { return this.getStringDef(this.key_FunctionalityManagePublishedImportItems, "Manage import definitions"); }

  /**
   * ComponentGemalImportName from f3.csv (in EN: GEMAL connector )
   */
  public key_ComponentGemalImportName = "ComponentGemalImportName";
  /**
   * ComponentGemalImportName from f3.csv (in EN: GEMAL connector )
   */
  public get ComponentGemalImportName(): string { return this.getStringDef(this.key_ComponentGemalImportName, "GEMAL connector"); }

  /**
   * ShortNameExists from f3.csv (in EN: An element with this short name already exists. )
   */
  public key_ShortNameExists = "ShortNameExists";
  /**
   * ShortNameExists from f3.csv (in EN: An element with this short name already exists. )
   */
  public get ShortNameExists(): string { return this.getStringDef(this.key_ShortNameExists, "An element with this short name already exists."); }

  /**
   * buttonNewWithDialog from f3.csv (in EN: New... )
   */
  public key_buttonNewWithDialog = "buttonNewWithDialog";
  /**
   * buttonNewWithDialog from f3.csv (in EN: New... )
   */
  public get buttonNewWithDialog(): string { return this.getStringDef(this.key_buttonNewWithDialog, "New..."); }

  /**
   * newItemDefaultText from f3.csv (in EN: New... )
   */
  public key_newItemDefaultText = "newItemDefaultText";
  /**
   * newItemDefaultText from f3.csv (in EN: New... )
   */
  public get newItemDefaultText(): string { return this.getStringDef(this.key_newItemDefaultText, "New..."); }

  /**
   * modelShortName from f3.csv (in EN: Short name )
   */
  public key_modelShortName = "modelShortName";
  /**
   * modelShortName from f3.csv (in EN: Short name )
   */
  public get modelShortName(): string { return this.getStringDef(this.key_modelShortName, "Short name"); }

  /**
   * modelShortNameExists from f3.csv (in EN: Short name already exists. )
   */
  public key_modelShortNameExists = "modelShortNameExists";
  /**
   * modelShortNameExists from f3.csv (in EN: Short name already exists. )
   */
  public get modelShortNameExists(): string { return this.getStringDef(this.key_modelShortNameExists, "Short name already exists."); }

  /**
   * modelLongName from f3.csv (in EN: Long name )
   */
  public key_modelLongName = "modelLongName";
  /**
   * modelLongName from f3.csv (in EN: Long name )
   */
  public get modelLongName(): string { return this.getStringDef(this.key_modelLongName, "Long name"); }

  /**
   * modelSortOrder from f3.csv (in EN: Sort order )
   */
  public key_modelSortOrder = "modelSortOrder";
  /**
   * modelSortOrder from f3.csv (in EN: Sort order )
   */
  public get modelSortOrder(): string { return this.getStringDef(this.key_modelSortOrder, "Sort order"); }

  /**
   * modelDescription from f3.csv (in EN: Description )
   */
  public key_modelDescription = "modelDescription";
  /**
   * modelDescription from f3.csv (in EN: Description )
   */
  public get modelDescription(): string { return this.getStringDef(this.key_modelDescription, "Description"); }

  /**
   * modelVariant from f3.csv (in EN: Variant )
   */
  public key_modelVariant = "modelVariant";
  /**
   * modelVariant from f3.csv (in EN: Variant )
   */
  public get modelVariant(): string { return this.getStringDef(this.key_modelVariant, "Variant"); }

  /**
   * modelName from f3.csv (in EN: Name )
   */
  public key_modelName = "modelName";
  /**
   * modelName from f3.csv (in EN: Name )
   */
  public get modelName(): string { return this.getStringDef(this.key_modelName, "Name"); }

  /**
   * modelTitle from f3.csv (in EN: Title )
   */
  public key_modelTitle = "modelTitle";
  /**
   * modelTitle from f3.csv (in EN: Title )
   */
  public get modelTitle(): string { return this.getStringDef(this.key_modelTitle, "Title"); }

  /**
   * ConfirmDeleteSelectedItem from f3.csv (in EN: Are you sure you want to delete this item? )
   */
  public key_ConfirmDeleteSelectedItem = "ConfirmDeleteSelectedItem";
  /**
   * ConfirmDeleteSelectedItem from f3.csv (in EN: Are you sure you want to delete this item? )
   */
  public get ConfirmDeleteSelectedItem(): string { return this.getStringDef(this.key_ConfirmDeleteSelectedItem, "Are you sure you want to delete this item?"); }

  /**
   * ConfirmDeleteSelectedItems from f3.csv (in EN: Are you sure you want to delete these items? )
   */
  public key_ConfirmDeleteSelectedItems = "ConfirmDeleteSelectedItems";
  /**
   * ConfirmDeleteSelectedItems from f3.csv (in EN: Are you sure you want to delete these items? )
   */
  public get ConfirmDeleteSelectedItems(): string { return this.getStringDef(this.key_ConfirmDeleteSelectedItems, "Are you sure you want to delete these items?"); }

  /**
   * ConfigurationSettingMissing from f3.csv (in EN: Required setting {0} is missing. )
   */
  public key_ConfigurationSettingMissing = "ConfigurationSettingMissing";
  /**
   * ConfigurationSettingMissing from f3.csv (in EN: Required setting {0} is missing. )
   */
  public ConfigurationSettingMissing(val1): string { return this.getStringDef("ConfigurationSettingMissing", "Required setting {0} is missing.").replace("{0}", val1); }

  /**
   * ConfigurationSettingsMissing from f3.csv (in EN: Settings are missing )
   */
  public key_ConfigurationSettingsMissing = "ConfigurationSettingsMissing";
  /**
   * ConfigurationSettingsMissing from f3.csv (in EN: Settings are missing )
   */
  public get ConfigurationSettingsMissing(): string { return this.getStringDef(this.key_ConfigurationSettingsMissing, "Settings are missing"); }

  /**
   * id from f3.csv (in EN: Identification )
   */
  public key_id = "id";
  /**
   * id from f3.csv (in EN: Identification )
   */
  public get id(): string { return this.getStringDef(this.key_id, "Identification"); }

  /**
   * description from f3.csv (in EN: Description )
   */
  public key_description = "description";
  /**
   * description from f3.csv (in EN: Description )
   */
  public get description(): string { return this.getStringDef(this.key_description, "Description"); }

  /**
   * dxPivot_Total from f3.csv (in EN: Total )
   */
  public key_dxPivot_Total = "dxPivot_Total";
  /**
   * dxPivot_Total from f3.csv (in EN: Total )
   */
  public get dxPivot_Total(): string { return this.getStringDef(this.key_dxPivot_Total, "Total"); }

  /**
   * Config from f3.csv (in EN: Configurate )
   */
  public key_Config = "Config";
  /**
   * Config from f3.csv (in EN: Configurate )
   */
  public get Config(): string { return this.getStringDef(this.key_Config, "Configurate"); }

  /**
   * StartupToolNone from f3.csv (in EN: Dashboards )
   */
  public key_StartupToolNone = "StartupToolNone";
  /**
   * StartupToolNone from f3.csv (in EN: Dashboards )
   */
  public get StartupToolNone(): string { return this.getStringDef(this.key_StartupToolNone, "Dashboards"); }

  /**
   * StartupToolManagement from f3.csv (in EN: Advanced )
   */
  public key_StartupToolManagement = "StartupToolManagement";
  /**
   * StartupToolManagement from f3.csv (in EN: Advanced )
   */
  public get StartupToolManagement(): string { return this.getStringDef(this.key_StartupToolManagement, "Advanced"); }

  /**
   * ShortNameRequired from f3.csv (in EN: Short name is required )
   */
  public key_ShortNameRequired = "ShortNameRequired";
  /**
   * ShortNameRequired from f3.csv (in EN: Short name is required )
   */
  public get ShortNameRequired(): string { return this.getStringDef(this.key_ShortNameRequired, "Short name is required"); }

  /**
   * DialogFileNameLabel from f3.csv (in EN: File name: )
   */
  public key_DialogFileNameLabel = "DialogFileNameLabel";
  /**
   * DialogFileNameLabel from f3.csv (in EN: File name: )
   */
  public get DialogFileNameLabel(): string { return this.getStringDef(this.key_DialogFileNameLabel, "File name:"); }

  /**
   * CalculateVariant from f3.csv (in EN: Compute )
   */
  public key_CalculateVariant = "CalculateVariant";
  /**
   * CalculateVariant from f3.csv (in EN: Compute )
   */
  public get CalculateVariant(): string { return this.getStringDef(this.key_CalculateVariant, "Compute"); }

  /**
   * CalculateVariantIncDescendants from f3.csv (in EN: Compute including decendant(s) )
   */
  public key_CalculateVariantIncDescendants = "CalculateVariantIncDescendants";
  /**
   * CalculateVariantIncDescendants from f3.csv (in EN: Compute including decendant(s) )
   */
  public get CalculateVariantIncDescendants(): string { return this.getStringDef(this.key_CalculateVariantIncDescendants, "Compute including decendant(s)"); }

  /**
   * NotFound from f3.csv (in EN: Not found )
   */
  public key_NotFound = "NotFound";
  /**
   * NotFound from f3.csv (in EN: Not found )
   */
  public get NotFound(): string { return this.getStringDef(this.key_NotFound, "Not found"); }

  /**
   * RefreshTokenConfirmationTitle from f3.csv (in EN: Your session is about to expire )
   */
  public key_RefreshTokenConfirmationTitle = "RefreshTokenConfirmationTitle";
  /**
   * RefreshTokenConfirmationTitle from f3.csv (in EN: Your session is about to expire )
   */
  public get RefreshTokenConfirmationTitle(): string { return this.getStringDef(this.key_RefreshTokenConfirmationTitle, "Your session is about to expire"); }

  /**
   * RefreshTokenConfirmationMessage from f3.csv (in EN: Would you like to extend your current session? )
   */
  public key_RefreshTokenConfirmationMessage = "RefreshTokenConfirmationMessage";
  /**
   * RefreshTokenConfirmationMessage from f3.csv (in EN: Would you like to extend your current session? )
   */
  public get RefreshTokenConfirmationMessage(): string { return this.getStringDef(this.key_RefreshTokenConfirmationMessage, "Would you like to extend your current session?"); }

  /**
   * ConfigurationLevelApplication from f3.csv (in EN: Application )
   */
  public key_ConfigurationLevelApplication = "ConfigurationLevelApplication";
  /**
   * ConfigurationLevelApplication from f3.csv (in EN: Application )
   */
  public get ConfigurationLevelApplication(): string { return this.getStringDef(this.key_ConfigurationLevelApplication, "Application"); }

  /**
   * ConfigurationLevelModel from f3.csv (in EN: Model )
   */
  public key_ConfigurationLevelModel = "ConfigurationLevelModel";
  /**
   * ConfigurationLevelModel from f3.csv (in EN: Model )
   */
  public get ConfigurationLevelModel(): string { return this.getStringDef(this.key_ConfigurationLevelModel, "Model"); }

  /**
   * ConfigurationLevelNone from f3.csv (in EN: None )
   */
  public key_ConfigurationLevelNone = "ConfigurationLevelNone";
  /**
   * ConfigurationLevelNone from f3.csv (in EN: None )
   */
  public get ConfigurationLevelNone(): string { return this.getStringDef(this.key_ConfigurationLevelNone, "None"); }

  /**
   * ConfigurationLevelItemType from f3.csv (in EN: Per type )
   */
  public key_ConfigurationLevelItemType = "ConfigurationLevelItemType";
  /**
   * ConfigurationLevelItemType from f3.csv (in EN: Per type )
   */
  public get ConfigurationLevelItemType(): string { return this.getStringDef(this.key_ConfigurationLevelItemType, "Per type"); }

  /**
   * ConfigurationLevelItemInstance from f3.csv (in EN: Per item )
   */
  public key_ConfigurationLevelItemInstance = "ConfigurationLevelItemInstance";
  /**
   * ConfigurationLevelItemInstance from f3.csv (in EN: Per item )
   */
  public get ConfigurationLevelItemInstance(): string { return this.getStringDef(this.key_ConfigurationLevelItemInstance, "Per item"); }

  /**
   * DataConnectionStateActive from f3.csv (in EN: Active )
   */
  public key_DataConnectionStateActive = "DataConnectionStateActive";
  /**
   * DataConnectionStateActive from f3.csv (in EN: Active )
   */
  public get DataConnectionStateActive(): string { return this.getStringDef(this.key_DataConnectionStateActive, "Active"); }

  /**
   * DataConnectionStateInactive from f3.csv (in EN: Inactive )
   */
  public key_DataConnectionStateInactive = "DataConnectionStateInactive";
  /**
   * DataConnectionStateInactive from f3.csv (in EN: Inactive )
   */
  public get DataConnectionStateInactive(): string { return this.getStringDef(this.key_DataConnectionStateInactive, "Inactive"); }

  /**
   * DataConnectionStateMarkedForDeletion from f3.csv (in EN: Marked for deletion )
   */
  public key_DataConnectionStateMarkedForDeletion = "DataConnectionStateMarkedForDeletion";
  /**
   * DataConnectionStateMarkedForDeletion from f3.csv (in EN: Marked for deletion )
   */
  public get DataConnectionStateMarkedForDeletion(): string { return this.getStringDef(this.key_DataConnectionStateMarkedForDeletion, "Marked for deletion"); }

  /**
   * DataConnectionStateUnknown from f3.csv (in EN: Unknown )
   */
  public key_DataConnectionStateUnknown = "DataConnectionStateUnknown";
  /**
   * DataConnectionStateUnknown from f3.csv (in EN: Unknown )
   */
  public get DataConnectionStateUnknown(): string { return this.getStringDef(this.key_DataConnectionStateUnknown, "Unknown"); }

  /**
   * MutationStackLowerBound from f3.csv (in EN: Lower bound )
   */
  public key_MutationStackLowerBound = "MutationStackLowerBound";
  /**
   * MutationStackLowerBound from f3.csv (in EN: Lower bound )
   */
  public get MutationStackLowerBound(): string { return this.getStringDef(this.key_MutationStackLowerBound, "Lower bound"); }

  /**
   * MutationStackUpperBound from f3.csv (in EN: Upper bound (up to and including) )
   */
  public key_MutationStackUpperBound = "MutationStackUpperBound";
  /**
   * MutationStackUpperBound from f3.csv (in EN: Upper bound (up to and including) )
   */
  public get MutationStackUpperBound(): string { return this.getStringDef(this.key_MutationStackUpperBound, "Upper bound (up to and including)"); }

  /**
   * MutationStackInput from f3.csv (in EN: Input value )
   */
  public key_MutationStackInput = "MutationStackInput";
  /**
   * MutationStackInput from f3.csv (in EN: Input value )
   */
  public get MutationStackInput(): string { return this.getStringDef(this.key_MutationStackInput, "Input value"); }

  /**
   * MutationStackOutput from f3.csv (in EN: Output value )
   */
  public key_MutationStackOutput = "MutationStackOutput";
  /**
   * MutationStackOutput from f3.csv (in EN: Output value )
   */
  public get MutationStackOutput(): string { return this.getStringDef(this.key_MutationStackOutput, "Output value"); }

  /**
   * MutationStackRules from f3.csv (in EN: Rules )
   */
  public key_MutationStackRules = "MutationStackRules";
  /**
   * MutationStackRules from f3.csv (in EN: Rules )
   */
  public get MutationStackRules(): string { return this.getStringDef(this.key_MutationStackRules, "Rules"); }

  /**
   * MutationStackRulesCreateNewRule from f3.csv (in EN: Add rule )
   */
  public key_MutationStackRulesCreateNewRule = "MutationStackRulesCreateNewRule";
  /**
   * MutationStackRulesCreateNewRule from f3.csv (in EN: Add rule )
   */
  public get MutationStackRulesCreateNewRule(): string { return this.getStringDef(this.key_MutationStackRulesCreateNewRule, "Add rule"); }

  /**
   * MutationStackRulesValueCannotBeEmpty from f3.csv (in EN: Value cannot be empty )
   */
  public key_MutationStackRulesValueCannotBeEmpty = "MutationStackRulesValueCannotBeEmpty";
  /**
   * MutationStackRulesValueCannotBeEmpty from f3.csv (in EN: Value cannot be empty )
   */
  public get MutationStackRulesValueCannotBeEmpty(): string { return this.getStringDef(this.key_MutationStackRulesValueCannotBeEmpty, "Value cannot be empty"); }

  /**
   * ModelElementCategoryColumnHeader from f3.csv (in EN: Model category )
   */
  public key_ModelElementCategoryColumnHeader = "ModelElementCategoryColumnHeader";
  /**
   * ModelElementCategoryColumnHeader from f3.csv (in EN: Model category )
   */
  public get ModelElementCategoryColumnHeader(): string { return this.getStringDef(this.key_ModelElementCategoryColumnHeader, "Model category"); }

  /**
   * ModelElementNameColumnHeader from f3.csv (in EN: Element )
   */
  public key_ModelElementNameColumnHeader = "ModelElementNameColumnHeader";
  /**
   * ModelElementNameColumnHeader from f3.csv (in EN: Element )
   */
  public get ModelElementNameColumnHeader(): string { return this.getStringDef(this.key_ModelElementNameColumnHeader, "Element"); }

  /**
   * ModelElementAddButton from f3.csv (in EN: Add )
   */
  public key_ModelElementAddButton = "ModelElementAddButton";
  /**
   * ModelElementAddButton from f3.csv (in EN: Add )
   */
  public get ModelElementAddButton(): string { return this.getStringDef(this.key_ModelElementAddButton, "Add"); }

  /**
   * DataSpanAlways from f3.csv (in EN: Permanent )
   */
  public key_DataSpanAlways = "DataSpanAlways";
  /**
   * DataSpanAlways from f3.csv (in EN: Permanent )
   */
  public get DataSpanAlways(): string { return this.getStringDef(this.key_DataSpanAlways, "Permanent"); }

  /**
   * DataSpanFrom from f3.csv (in EN: From: {0} )
   */
  public key_DataSpanFrom = "DataSpanFrom";
  /**
   * DataSpanFrom from f3.csv (in EN: From: {0} )
   */
  public DataSpanFrom(val1): string { return this.getStringDef("DataSpanFrom", "From: {0}").replace("{0}", val1); }

  /**
   * DateSpanUntil from f3.csv (in EN: Until: {0} )
   */
  public key_DateSpanUntil = "DateSpanUntil";
  /**
   * DateSpanUntil from f3.csv (in EN: Until: {0} )
   */
  public DateSpanUntil(val1): string { return this.getStringDef("DateSpanUntil", "Until: {0}").replace("{0}", val1); }

  /**
   * MutationParamMutationDate from f3.csv (in EN: Period/year )
   */
  public key_MutationParamMutationDate = "MutationParamMutationDate";
  /**
   * MutationParamMutationDate from f3.csv (in EN: Period/year )
   */
  public get MutationParamMutationDate(): string { return this.getStringDef(this.key_MutationParamMutationDate, "Period/year"); }

}
