<div class="col-xs-12 col-md-6 blocks" *ngIf="loaded && ebsSummary.isEmpty === false">
  <div>
    <div class="block">
      <div>
        <span>
          <h4>{{ language.f3MeWebApiMiniEbsTotalBenefits }}</h4>
        </span>
        <span class="largelc">{{ ebsSummary.totalLabourCondition.valueAsText }}</span>
      </div>

      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
          [style.width]="ebsSummary.salaryPercentage.valueAsNumber * 100 + '%'"></div>
        <span class="part">{{ language.f3MeWebApiMiniEbsSalary }} {{ ebsSummary.totalSalary.valueAsText }}</span>
        <span class="part">{{ language.f3MeWebApiMiniEbsOther }}
          {{ ebsSummary.labourConditionMinusSalary.valueAsText }}</span>
      </div>

      <div class="button-bar">
        <app-cms-link displayStyle="text" [displayText]="language.f3MeWebApiMiniEbsDetail" [link]="ebsLink">
        </app-cms-link>
      </div>
    </div>
    <div class="block">
      <div>
        <span class="part">
          <h4>{{ language.f3MeWebApiMiniEbsLeave }}</h4>
        </span>
        <span class="part largelc">{{ ebsSummary.availableLeaveDays.valueAsText }}
          <span>{{ language.f3MeWebApiMiniEbsDays }}</span></span>
      </div>
    </div>
  </div>
</div>