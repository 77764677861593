// export type DataRow = [string, any];

export class DataRow {
  public get isChanged(): boolean {
    return this.changedColumns.length > 0;
  }

  public changedColumns: string[] = [];

  public get identification(): string {
    return this["Identification"];
  }

  public addToChangedColumns(col: string) {
    const idx = this.indexOfChangedColumn(col);
    if (idx < 0) {
      this.changedColumns.push(col);
    }
  }

  public indexOfChangedColumn(col: string): number {
    return this.changedColumns.findIndex(x => x.toLowerCase() === col.toLowerCase());
  }

  public clearChanges() {
    this.changedColumns = [];
  }
}
