import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgModule } from "@angular/core";
import { PlImageCropperComponent } from "./image-cropper.component";

@NgModule({
  imports: [CommonModule, ImageCropperModule, DxButtonModule],
  exports: [PlImageCropperComponent],
  declarations: [PlImageCropperComponent],
  providers: [],
})
export class PlImageCropperModule {}
