import { Component, Input, OnDestroy } from "@angular/core";

import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { RoleGuard } from "../../../../angular-common";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../../angular-common/language/pllanguage.service";
import { MenuItem } from "../../../../angular-common/menus";
import { MenuFilterService } from "../../../../angular-common/menus/filters/MenuFilterService";
import { MenuService } from "../../../../angular-common/menus/menu.service";
import { ImageService } from "../../../components/images/image.service";
import { MenuItemTypesDto } from "../../../models/cms/dto/MenuItemTypes-dto";
import { MenuTypeProvider } from "../menu-type-provider";
import { PortalMenuItem } from "./portal-menu-item";

@Component({
  selector: "app-portal-menu",
  templateUrl: "./portal-menu.component.html",
  styleUrls: ["./portal-menu.component.scss"],
})
export class PortalMenuComponent implements OnDestroy {
  private languageSubscription: Subscription = null;
  private menuTypeSubscription: Subscription = null;
  private manuServiceSubscription: Subscription = new Subscription();

  public constructor(
    public languageService: PlLanguageService,
    private menuTypeProvider: MenuTypeProvider,
    private router: Router,
    private menuService: MenuService,
    public imageService: ImageService,
    private languageSelection: PlLanguageSelection,
    private menuFilterService: MenuFilterService,
  ) {}

  @Input()
  public set isLoginPage(value: Boolean) {
    if (!value) {
      if (this.languageService.loaded) {
        this.languageSubscription = this.languageService.languageSelector.languageChanged.subscribe((lang) => {
          this.menuTypeSubscription = this.menuTypeProvider.menuItemType.subscribe((type) => {
            if ((type & MenuItemTypesDto.TopMenuItems) === type) {
              this.menuItemType = type;
            }
            this.refresh();
          });
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }

    if (this.menuTypeSubscription) {
      this.menuTypeSubscription.unsubscribe();
    }

    if (this.manuServiceSubscription) {
      this.manuServiceSubscription.unsubscribe();
    }
  }

  public toggleMenuVisibility = (): void => {
    if (this.menuItems.length > 0) {
      this.popupVisible = this.popupVisible ? false : true;
    }
  };
  public popupVisible: boolean = false;

  public switchToMenu(menuItem: PortalMenuItem): void {
    this.popupVisible = false;

    // Open the first menu item after switching menu items
    this.menuService.getSubMenuItems(menuItem.type).subscribe((menuItems) => {
      const activeItems = RoleGuard.filterForActiveUser(menuItems);
      if (activeItems.length > 0) {
        this.router.navigate([activeItems[0].route]);
      }
    });
  }

  private mustShowMenu(menuItem: MenuItem) {
    const isTopMenuItem = (MenuItemTypesDto.TopMenuItems & menuItem.id) === menuItem.id;
    if (!menuItem.hasSubMenuItems || !isTopMenuItem) {
      return false;
    }
    return this.menuFilterService.shouldShow(menuItem);
  }

  private refresh() {
    this.manuServiceSubscription = this.menuService.getMenuItems().subscribe((m) => {
      const newMenuItems: PortalMenuItem[] = [];
      let newTitle = "";
      m.forEach((menuItem) => {
        if (this.mustShowMenu(menuItem)) {
          if (menuItem.id === this.menuItemType) {
            newTitle = this.languageSelection.currentValueFor(menuItem.title);
          }
          const newMenuItem = new PortalMenuItem(menuItem.title, menuItem.id, menuItem.icon, this.languageSelection);
          newMenuItems.push(newMenuItem);
        }
      });
      this.menuItems = newMenuItems;
      this.title = newTitle;
    });
  }

  public get canShowMenu(): boolean {
    return this.menuItems && this.menuItems.length > 0 && this.isHomeMenuItemOnly() === false;
  }

  private isHomeMenuItemOnly(): boolean {
    if (this.menuItems.length === 1) {
      if (this.menuItems[0].type === MenuItemTypesDto.MainMenu) {
        return true;
      }
    }

    return false;
  }

  public menuItems: PortalMenuItem[];
  public title: string;

  private _menuItemType: MenuItemTypesDto;
  public get menuItemType() {
    return this._menuItemType;
  }
  public set menuItemType(newMenuItemType: MenuItemTypesDto) {
    this._menuItemType = newMenuItemType;
  }
}
