import { ISortOrder, Sorter } from "../../../angular-common";

import { BenefitGroupDto } from "../../models/ebs/dto/BenefitGroup-dto";
import { BenefitItem } from "./benefit-item.model";
import { BenefitValue } from "./benefit-value.model";
import { EventEmitter } from "@angular/core";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class BenefitGroup implements ISortOrder {
  public constructor(private selectLanguage: PlLanguageSelection) {}

  public id: number;
  public _title: LanguageItem;
  public color: string;
  public items: BenefitItem[];
  public sortOrder: number;

  public value: BenefitValue;
  public additionalValue: BenefitValue;

  public get title(): string {
    return this.selectLanguage.currentValueFor(this._title);
  }

  public copyFromDTO(dto: BenefitGroupDto) {
    const itemList = new Array<BenefitItem>();
    for (const jsonItem of dto.Items) {
      const item = new BenefitItem(this.selectLanguage);
      item.copyFromDTO(jsonItem);
      itemList.push(item);
    }

    this.items = itemList.sort(Sorter.compareSortOrder);
    this.id = dto.Id;
    this._title = dto.Title;
    this.color = dto.Color;
    this.value = new BenefitValue(dto.Value.ValueAsText, dto.Value.ValueAsNumber, dto.Value.AppliesToTotal, dto.Value.Visible);
    this.additionalValue = new BenefitValue(dto.AdditionalValue.ValueAsText, dto.AdditionalValue.ValueAsNumber, dto.AdditionalValue.AppliesToTotal, dto.AdditionalValue.Visible);
    this.sortOrder = dto.SortOrder;
  }

  public click() {
    this.onClick.emit();
  }

  public onClick = new EventEmitter();
}
