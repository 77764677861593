import { BehaviorSubject } from "rxjs";
import { Sorter } from "../sorting/sorter";
import { ISelectableItem } from "./iselectable-item";

export class SelectableItemsBase<SelectableType extends ISelectableItem> {
  public constructor() {
    this.selectedItemChanged = new BehaviorSubject(null);
  }

  public items: SelectableType[] = [];
  public selectedItemChanged: BehaviorSubject<SelectableType>;

  public get selectedItem(): SelectableType {
    return this._selectedItem;
  }
  public set selectedItem(newItem: SelectableType) {
    this._selectedItem = newItem;
    this.doSelectedItemChanged(this._selectedItem);
  }
  private _selectedItem: SelectableType;

  public addItem(item: SelectableType) {
    this.items.push(item);
  }

  public deleteItemById(id: number) {
    const item = this.items.find(x => x.id === id);
    if (item && item !== null) {
      const idx = this.items.indexOf(item);

      if (this.selectedItem === item) {
        this.selectedItem = null;
        if (this.items.length > 0) {
          this.selectedItem = this.items[0];
        } else {
          this.selectedItem = null;
        }
      }

      this.items.splice(idx, 1);
    }
  }

  public get selectedContent(): any {
    if (this.selectedItem && this.selectedItem !== null) {
      return this.selectedItem.content;
    }
    return undefined;
  }

  protected doSelectedItemChanged(selectedItem: SelectableType) {
    this.selectedItemChanged.next(selectedItem);
  }

  public sort() {
    Sorter.sortAlphabetically(this.items, i => i.text);
  }
}
