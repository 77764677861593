import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LanguageMetaDataDto } from "../../common/models/dto/LanguageMetaData-dto";
import { LanguageMetaDatasDto } from "../../common/models/dto/LanguageMetaDatas-dto";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { ErrorDetection } from "../errorhandler/ErrorDetection";
import { ElementHelper } from "../helpers/element.helper";
import { LanguageConstants } from "./language-constants";
import { PlLanguageData } from "./pllanguagedata.model";
import { PlLanguageSelector } from "./pllanguageselector";

// ==================================================================
// LanguageService does not derive from base class. This is because we
// want the base class to handle errors in the form of a popup. However
// language is loaded as part of the application when we have no ui yet.
// ==================================================================
@Injectable()
export class PlLanguageService {
  public static fallbackDefaultLanguageMetadata(): LanguageMetaDataDto {
    const newItem = new LanguageMetaDataDto();
    newItem.Id = LanguageConstants.DEFAULT_LANGUAGE;
    newItem.DisplayName = "Nederlands";
    newItem.ImageUrl = "flag-icon flag-icon-nl";
    return newItem;
  }

  private readonly languageMetaDataEndpoint: string = "staticlanguage/metadata";

  public source: PlLanguageData = null;

  constructor(public http: HttpClient, public envSettings: EnvironmentSettings) {}

  public get currentLanguageData(): PlLanguageData {
    return this.source;
  }

  public get loaded(): boolean {
    return this.source && this.source !== null;
  }

  public loadMetaData(): Promise<LanguageMetaDatasDto> {
    return this.http.get<LanguageMetaDatasDto>(this.envSettings.getUri(this.languageMetaDataEndpoint)).toPromise();
  }

  public loadLanguage(endPoint: string): Promise<PlLanguageData> {
    let uri = endPoint;
    const language = PlUserStorage.language;
    if (language && language !== "" && language !== null) {
      uri = this.envSettings.combinePath(endPoint, language);
    }

    let promise = this.http.get<PlLanguageData>(this.envSettings.getUri(uri)).toPromise();

    promise.then((dto) => {
      this.source = dto;
      // The requested language might not exist, when that happens we switch to the default. User can change language trough the UI.
      if (language !== this.source.MetaData.Language) {
        PlUserStorage.language = this.source.MetaData.Language;
      }
      return dto;
    });

    promise.then((dto) => {
      this.languageSelector = new PlLanguageSelector(this.languages(), this.source.MetaData.Language);
      this.languageSelector.languageChanged.subscribe((lang) => {
        if (lang !== this.source.MetaData.Language) {
          PlUserStorage.language = lang;
          window.location.reload();
        }
      });
      return dto;
    });

    promise = promise.catch((reason) => {
      console.log("Error retrieving language");
      ErrorDetection.findErrorType(reason, this.envSettings);
      return new PlLanguageData();
    });

    return promise;
  }

  public languages(): LanguageMetaDatasDto {
    if (this.source) {
      return this.source.MetaData;
    } else {
      const fallBack = PlLanguageService.fallbackDefaultLanguageMetadata();
      const metas = new LanguageMetaDatasDto();
      metas.Language = fallBack.Id;
      metas.Languages = [fallBack];
      return metas;
    }
  }

  public setLanguageMetadata(languages: LanguageMetaDataDto[]) {
    if (ElementHelper.isNullOrUndefined(this.source) === false) {
      this.source.MetaData.Languages = languages;
    }
  }

  public getLanguageMetaData(): LanguageMetaDataDto[] {
    return this.languages().Languages;
  }

  private _languageSelector: PlLanguageSelector;
  public get languageSelector(): PlLanguageSelector {
    if (this._languageSelector === undefined) {
      this._languageSelector = new PlLanguageSelector(this.languages(), PlUserStorage.language || LanguageConstants.DEFAULT_LANGUAGE);
    }

    return this._languageSelector;
  }
  public set languageSelector(v: PlLanguageSelector) {
    this._languageSelector = v;
  }

  public getString(id: string, defaultValue?: string) {
    if (!this.source) {
      return defaultValue || "";
    }

    let result: string;
    result = this.source.Values[id];
    if (!result) {
      return defaultValue;
    }
    return result;
  }
}
