import { ComputedFieldDto } from "../../../models/me/dto/ComputedField-dto";

export class ExogenousInputComputedField {
  public constructor() {}

  public shortName: string;
  public value: string;
  public valueAsNumber: number;
  public relatedSourceName: string;

  public copyFromDTO(dto: ComputedFieldDto) {
    this.shortName = dto.ShortName;
    this.value = dto.ModelValue.Value;
    this.valueAsNumber = dto.ModelValue.ValueAsNumber;
    this.relatedSourceName = dto.RelatedSourceName;
  }
}
