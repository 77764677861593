import { Component } from "@angular/core";
import { LanguageEditDataDTO } from "../../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../../angular-common/language/pl-language-selection";
import { CmsLanguage } from "../../../../cms/language/cmslanguage.service";
import { LanguageEditService } from "../../../../components/language/languageedit.service";
import { LanguageEditItem } from "../../../../components/language/languageedititem.model";

@Component({
  selector: "app-user-employee-login-translator",
  templateUrl: "prefered-user-employee-login-translator.component.html",
  styleUrls: ["prefered-user-employee-login-translator.component.scss"],
})
export class PreferedUserEmployeeLoginTranslatorComponent {
  public constructor(selectLanguage: PlLanguageSelection, public languageService: CmsLanguage, private languageEditService: LanguageEditService) {
    this._currentLanguage = selectLanguage.currentLanguage;
  }

  public ngOnInit() {
    this.languageEditService.getEntriesForEditing().subscribe((l) => {
      this.languageEditService.getEditItems(l, this.languageService).forEach((x) => {
        if (x.id === this.languageService.key_f3MeWebApiUserToEmployeeLoginWarning || x.id === this.languageService.key_f3MeWebApiUserToEmployeeIdentification) this.items.push(x);
      });
    });
  }

  public items: LanguageEditItem[] = new Array<LanguageEditItem>();
  public elements: LanguageEditDataDTO;

  private _currentLanguage: string;
  public get currentLanguage(): string {
    return this._currentLanguage;
  }

  public languageChanged(newLanguage) {
    this._currentLanguage = newLanguage;
  }
}
