import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class EbsChartItem {
  public constructor(private selectLanguage: PlLanguageSelection, public id: number, private _title: LanguageItem, public value: number) {}

  public get title(): string {
    return this.selectLanguage.currentValueFor(this._title);
  }
}
