import { ROUTES, RouterModule } from "@angular/router";

import { NgModule } from "@angular/core";
import { canActivateUserAcknowledgementGuard } from "../auth/user-acknowledgemen.guard";
import { MenuItemTypesDto } from "../models/cms/dto/MenuItemTypes-dto";
import { EbsRoutesDto } from "../models/ebs/dto/EbsRoutes-dto";
import { EbsFeaturesModel } from "./ebs-features-model";
import { EbsOverviewComponent } from "./ebs-overview/ebs-overview-component";
import { EbsComponent } from "./ebs.component";

function routesFactory(featuresModel: EbsFeaturesModel) {
  return [
    { path: "ebs", component: EbsComponent, data: [{ menuType: MenuItemTypesDto.MainMenu }] },
    {
      path: EbsRoutesDto.EbsOverview,
      component: EbsOverviewComponent,
      data: [{ menuType: MenuItemTypesDto.MainMenu }],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
    {
      path: EbsRoutesDto.Ebs + "/:id",
      component: EbsComponent,
      data: [{ menuType: MenuItemTypesDto.MainMenu }],
      canActivate: [canActivateUserAcknowledgementGuard],
    },
  ];
}

@NgModule({
  imports: [RouterModule.forChild([])],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [EbsFeaturesModel],
    },
  ],
  exports: [RouterModule],
})
export class EbsRoutingModule {}
