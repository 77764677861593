import { Component, Input } from "@angular/core";

@Component({
  selector: "app-cmspage-header",
  styleUrls: ["./cmspage-header.component.scss"],
  templateUrl: "./cmspage-header.component.html",
})
export class CmsPageHeaderComponent {
  @Input() public headerText: string;
  @Input() public introText: string;

  public constructor() {}
}
