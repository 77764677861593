import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { LanguageItemTypeGuard } from "../../../angular-common/language/pl-languageitemtype.guard";
import { EbsLanguage } from "../../../employee-common/ebs/language/ebslanguage.service";
import { EbsNodeDto } from "../../../employee-common/models/ebs/dto/EbsNodeDto-dto";
import { EbsGroupInformation } from "./ebs-treeinfo";

export class EbsTreeNode {
  public static createDefault(languageService: EbsLanguage, languageSelection: PlLanguageSelection): EbsTreeNode {
    const newNode = new EbsTreeNode(languageService);
    newNode.details = EbsGroupInformation.applyDefaults(languageService, languageSelection);
    newNode.children = [];
    return newNode;
  }

  public static toDto(value: EbsTreeNode, language: EbsLanguage): EbsNodeDto {
    const newDto = new EbsNodeDto();
    newDto.Details = value.details.toDto(value.details);
    const newChildren: EbsNodeDto[] = [];
    value.children.forEach((child) => {
      let newChild = new EbsNodeDto();
      newChild = EbsTreeNode.toDto(child, language);
      newChildren.push(newChild);
    });
    newDto.Children = newChildren;

    if (LanguageItemTypeGuard.isLanguageItem(newDto.Details.MessageTitle) === false) {
      newDto.Details.MessageTitle = language.getEntry("f3MeWebApiNoGroup");
    }

    return newDto;
  }

  public fromDto(dto: EbsNodeDto, languageSelection: PlLanguageSelection) {
    const children: EbsTreeNode[] = [];
    dto.Children.forEach((c) => {
      const child = new EbsTreeNode(this.languageService);
      child.fromDto(c, languageSelection);
      children.push(child);
    });

    this.children = children;
    this.details.fromDto(dto.Details, languageSelection);
  }

  public constructor(private languageService: EbsLanguage) {}

  public details: EbsGroupInformation = new EbsGroupInformation();
  public children: EbsTreeNode[] = [];
}
