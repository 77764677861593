<div class="datagrid-container">
  <dx-data-grid
    [dataSource]="reportingInputTranslations"
    (onEditingStart)="onEditingStart($event.data)"
    (onRowUpdated)="onRowUpdated($event.data, $event.component)"
    (onRowRemoved)="onRowDeleted($event.data)"
  >
    <dxo-editing [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>
    <dxi-column dataField="ReportId" [caption]="language.ReportName" [calculateDisplayValue]="getReportName" [allowEditing]="false" alignment="left"></dxi-column>
    <dxi-column dataField="ShortName" [caption]="language.ReportInputValueName" [allowEditing]="false" cellTemplate="formattedExogenousName"></dxi-column>
    <dxi-column dataField="Text" [caption]="language.ReportInputValueTranslation" editCellTemplate="editCellTextLanguageTemplate" [calculateDisplayValue]="text"></dxi-column>
    <div *dxTemplate="let d of 'editCellTextLanguageTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.TextCopy"></app-languageitem-editor-grid>
    </div>
    <div *dxTemplate="let d of 'formattedExogenousName'">
      {{ computedExegoName(d.data) }}
    </div>
  </dx-data-grid>
</div>
