import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { HtmlEditorModule } from "../../../../angular-common/components/htmleditor/htmleditor.module";
import { SafeDataDirectiveModule } from "../../../../angular-common/utils/safe-data-directive-module";
import { LanguageItemEditorComponent } from "./languageitem-editor.component";

@NgModule({
  imports: [CommonModule, FormsModule, HtmlEditorModule, SafeDataDirectiveModule],
  declarations: [LanguageItemEditorComponent],
  exports: [LanguageItemEditorComponent],
  providers: [],
})
export class LanguageItemEditorModule {}
