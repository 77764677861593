
// t4tsCode gen": "0.8.2.0 for file UserRating.cs
// Don't adjust manually!

export class UserRatingDto {
    public MessageId: number;
    public UserScore: number;
    public UserName: string;
    public RatingDate: Date;
}

