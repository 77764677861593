import { Pipe, PipeTransform } from "@angular/core";
import { DataRow } from "../../../f3Me/src/app/approvetargets/_model/data-row";

@Pipe({ name: "lookupCellTemplate" })
export class EmployeeGirdCellTemplatePipe implements PipeTransform {
  transform(gridData: any) {
    const dr: DataRow = gridData.data as DataRow;
    const col: string = gridData.column.dataField;
    const isChanged = dr.indexOfChangedColumn(col) > -1;

    if (isChanged) {
      return gridData.text + " *";
    }
    return gridData.text;
  }
}
