import { Component, OnInit } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { CmsLanguage } from "../cms/language/cmslanguage.service";
import { ImageService } from "../components/images/image.service";
import { FixedImageDto } from "../models/cms/dto/FixedImage-dto";
import { FixedImageModel } from "./fixedimage.model";

@Component({
  selector: "app-fixed-images",
  templateUrl: "./fixedimages.component.html",
  styleUrls: ["./fixedimages.component.scss"],
  providers: [],
})
export class FixedImagesComponent implements OnInit {
  public contentLoaded: boolean;
  public fixedImages: FixedImageDto[];
  public fixedImagesModels: FixedImageModel[];

  ngOnInit(): void {
    this.imageService.fixedImages().subscribe((f) => {
      this.fixedImages = f;
      this.fixedImages.forEach((f) => {
        const model = new FixedImageModel(this.imageService.uriFor(f), this.languageService.getStringDef(f.LanguageId));
        this.imageService.transformProtectedUri(model).subscribe((d) => {
          this.fixedImagesModels.push(d);
        });
      });
    });

    this.contentLoaded = true;
  }

  public constructor(public languageService: CmsLanguage, public imageService: ImageService, private http: HttpClient) {
    this.contentLoaded = false;
    this.fixedImages = [];
    this.fixedImagesModels = [];
  }

  public get imagesLoaded(): boolean {
    return this.fixedImages.length === this.fixedImagesModels.length;
  }
}
