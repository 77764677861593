import { AuthorizationContentType } from "../../models/authorization-content.types";

export class AuthorizationTabContent {
  constructor(public authorizationContentType: AuthorizationContentType) {}

  public get isContentFilter(): boolean {
    return this.authorizationContentType === AuthorizationContentType.FilterRole;
  }

  public get isContentRoles(): boolean {
    return this.authorizationContentType === AuthorizationContentType.Roles;
  }

  public get isContentRolesMaintenance(): boolean {
    return this.authorizationContentType === AuthorizationContentType.RolesMaintenance;
  }

  public get isContentUserDetails(): boolean {
    return this.authorizationContentType === AuthorizationContentType.UserDetails;
  }
}
