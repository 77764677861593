import { Component, Input } from "@angular/core";

import { CMSMessage } from "../cms-message.model";
import { BaseCMSMessageComponent } from "../shared/cms.basemessage.component";

@Component({
  selector: "app-homepagebannermessage-component",
  templateUrl: "./homepagebanner-message.component.html",
  styleUrls: ["./homepagebanner-message.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class HomePageBannerMessageComponent extends BaseCMSMessageComponent {
  public constructor() {
    super();
  }

  @Input() message: CMSMessage;
  @Input() index: number;

  public get imageSettings() {
    if (this.message.imageLoaded) {
      return { "background-image": "url(" + this.message.imageURL + ")" };
    }
  }
}
