import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthorizationContentType } from "../../models/authorization-content.types";
import { Role } from "../../models/authorization-role-model";
import { AuthorizationRolesMaintenanceService } from "../../services/authorization-rolesmaintenance.service";
import { RoleHierarchyList } from "../../services/models/authorization-roleHierarchy-list-model";
import { AuthorizationTabContent } from "./authorization-tabs.tab-content";

export class AuthorizationRolesMaintenanceTabContent extends AuthorizationTabContent {
  public roles: RoleHierarchyList;
  public rolesInUse: number[] = [];

  constructor(public authorizationService: AuthorizationRolesMaintenanceService) {
    super(AuthorizationContentType.RolesMaintenance);
  }

  public getRoles(): Observable<void> {
    const obs = this.authorizationService.getAuthorizationRolesHierarchyMetaData().pipe(
      map((metaData) => {
        if (metaData) {
          const newRoles = new RoleHierarchyList();
          newRoles.copyFromDto(metaData.Hierarchy);

          this.roles = newRoles;
          this.rolesInUse = metaData.RolesInUse;
        }
      }),
    );

    return obs;
  }

  public roleIsInUse(role: Role) {
    if (!role || role === null || role.isNew || !this.rolesInUse || this.rolesInUse === null) {
      return false;
    }

    if (this.rolesInUse.findIndex((ri) => ri === role.id) > -1) {
      return true;
    }

    return false;
  }
}
