import { CommonModule } from "@angular/common";
import { EnvironmentSettings } from "./environment-settings";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HeaderInterceptor } from "./httpinterceptor";
import { NgModule } from "@angular/core";
import { PlBaseService } from "./plbase.service";
import { RouteNavigation } from "./route-navigation";

@NgModule({
  imports: [CommonModule],
  providers: [
    RouteNavigation,
    PlBaseService,
    EnvironmentSettings,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
  ],
  declarations: [],
})
export class PlBaseServiceModule {}
