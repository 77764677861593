import { Component, OnDestroy } from "@angular/core";
import { PluginSpecificationDto, PluginSpecificationDtoNameDto } from "../models/dto/PluginSpecificationDto-dto";

import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { RoleDto } from "../../../../common/models/dto/RoleDto-dto";
import { CmsLanguage } from "../../../../employee-common/cms/language/cmslanguage.service";
import { PluginSpecificationMetaDataDto } from "../models/dto/PluginSpecificationMetaDataDto-dto";
import { PluginService } from "../service/plugin.service";

enum FormType {
  ADDING,
  EDITING,
}

@Component({
  selector: "app-plugin-management",
  templateUrl: "./plugin-management.component.html",
  styleUrls: ["./plugin-management.component.scss"],
})
export class PluginManagementComponent implements OnDestroy {
  public plugins: PluginSpecificationDto[];
  public roles: RoleDto[];
  public currentPlugin: PluginSpecificationDto;
  public pluginFormPopupEnabled: boolean = false;
  public formType: FormType;
  public subscriptions = new Subscription();
  public selectedRoles: RoleDto[] = [];

  constructor(private route: ActivatedRoute, private pluginService: PluginService, private languageSelection: PlLanguageSelection, public language: CmsLanguage) {
    this.route.data.subscribe((data: { plugins: PluginSpecificationMetaDataDto }) => {
      this.plugins = data.plugins.PluginSpecifications;
      this.roles = data.plugins.Roles;
    });
  }

  public get pluginPopupTitle(): string {
    return this.language.f3MeWebApiMenuPluginManage;
  }

  getRoles(roles: RoleDto[]): string {
    const roleNames = roles.map((role) => role.ShortName);
    return roleNames.join(", ");
  }

  openAddPluginPopup(): void {
    this.currentPlugin = {
      KeyId: -1,
      ShortName: null,
      Name: this.getEmptyNames(),
      Location: null,
      Roles: [],
      IsSystem: false,
      Height: null,
      Width: null,
    };

    this.pluginFormPopupEnabled = true;
    this.formType = FormType.ADDING;
  }

  openEditPluginPopup(plugin: PluginSpecificationDto): void {
    this.currentPlugin = plugin;
    this.pluginFormPopupEnabled = true;
    this.formType = FormType.EDITING;
    this.syncViews();
  }

  public syncViews() {
    this.currentPlugin.Roles.forEach((role) => {
      const tempRole = this.roles.find((x) => x.KeyId === role.KeyId);
      this.selectedRoles.push(tempRole);
    });
  }

  savePlugin(e) {
    if (this.formType === FormType.ADDING) {
      this.subscriptions.add(
        this.pluginService.createPlugin(this.currentPlugin).subscribe((plugin) => {
          this.plugins.push(plugin.data);
          this.pluginFormPopupEnabled = false;
        }),
      );
    } else {
      this.subscriptions.add(
        this.pluginService.updatePlugin(this.currentPlugin).subscribe((plugin) => {
          this.plugins = this.plugins.filter((p) => p.ShortName !== this.currentPlugin.ShortName);
          this.plugins.push(plugin);
          this.pluginFormPopupEnabled = false;
        }),
      );
    }

    e.preventDefault();
  }

  deletePlugin(id: number): void {
    this.subscriptions.add(
      this.pluginService.deletePlugin(id).subscribe((_) => {
        this.plugins = this.plugins.filter((plugin) => plugin.KeyId !== id);
      }),
    );
  }

  getEmptyNames(): PluginSpecificationDtoNameDto {
    const names = {};

    this.languageSelection.languages.Languages.forEach((language) => {
      Object.assign(names, { [language.Id]: null });
    });

    return (names as unknown) as PluginSpecificationDtoNameDto;
  }

  roleToggled(): void {
    this.currentPlugin.Roles = this.selectedRoles;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkFormTypeEditing(formType: FormType) {
    if (formType === FormType.EDITING) {
      return true;
    } else {
      return false;
    }
  }
}
