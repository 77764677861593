import { Component } from "@angular/core";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";

@Component({
  selector: "app-message-rating-dashboard",
  templateUrl: "./message-rating-dashboard.component.html",
  styleUrls: ["./message-rating-dashboard.component.scss"],
})
export class MessageRatingDashboardComponent {
  constructor(public languageService: CmsLanguage) {}

  public messageType = MessageTypeDto;
}
