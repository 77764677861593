import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ElementHelper } from "../helpers/element.helper";
import { TokenData } from "./_models/token.model";
import { EnvironmentSettings } from "./environment-settings";
import { PlUserStorage } from "./pluserstorage";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  public static getOptionsCustomToken(token: TokenData) {
    let headers = new HttpHeaders();

    if (PlUserStorage.hasLanguage) {
      headers = headers.append("Accept-Language", PlUserStorage.language);
    }

    if (this.environmentSettings.useExternalAuthentication() === false) {
      if (token !== null && token !== undefined) {
        const authHeaderValue = HeaderInterceptor.createAuthenticationTokenValue(token);
        headers = headers.append("Authorization", authHeaderValue);
      }
    }

    headers = headers.append("Content-Type", "application/json");

    let withCredentials = false;
    if (this.environmentSettings.useExternalAuthentication()) {
      withCredentials = true;
    }

    const options = { headers, withCredentials };
    return options;
  }

  private static environmentSettings = new EnvironmentSettings();

  public constructor() {}

  public static createAuthenticationTokenValue(token: TokenData) {
    if (token !== undefined) {
      return token.token_type + " " + token.access_token;
    } else {
      return undefined;
    }
  }

  public static createHttpOptionsForTokenProtectedResource() {
    let headers = new HttpHeaders();
    headers.set("Authorization", `Bearer ${HeaderInterceptor.createAuthenticationTokenValue(PlUserStorage.token)}`);
    return { headers, responseType: this.createHttpOptionsForImage().responseType };
  }

  public static createHttpOptionsForImage() {
    return { responseType: "blob" as "json" };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersToSet: { [name: string]: string | string[] } = {};

    if (req.headers.has("Authorization") === false) {
      const authHeader = HeaderInterceptor.createAuthenticationTokenValue(PlUserStorage.token);
      if (authHeader !== undefined) {
        headersToSet["Authorization"] = authHeader;
      }
    }
    if (PlUserStorage.hasLanguage) {
      headersToSet["Accept-Language"] = PlUserStorage.language;
    }

    if (PlUserStorage.hasUser()) {
      const model = PlUserStorage.user.PreferredModel;
      if (ElementHelper.isNullOrUndefined(model) == false) {
        headersToSet["pl-hr-preferredModel"] = model;
      }
    }

    if (Object.keys(headersToSet).length > 0) {
      req = req.clone({
        setHeaders: headersToSet,
        withCredentials: true,
      });
    }

    return next.handle(req);
  }
}
