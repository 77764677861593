import { BehaviorSubject } from "rxjs";
import { UserInformationValuesDto } from "../../common/models/dto/UserInformationValues-dto";
import { WellKnownRoleNamesDto } from "../../common/models/dto/WellKnownRoleNames-dto";
import { ElementHelper } from "../helpers/element.helper";
import { StringHelper } from "../helpers/string.helper";
import { BrowserStorage } from "../storage/browser-storage";
import { BrowserStorageType } from "../storage/browser-storage-type";
import { TokenData } from "./_models/token.model";
import { IUserWithToken } from "./_models/user-with-token.model";

export class PlUserStorage {
  private static readonly storageUserKey: string = "CurrentUser";
  private static readonly languageKey: string = "Language";
  private static readonly hideTopbarKey: string = "HideTopbar";

  public static hasRole(role: string): boolean {
    if (!role || role === null) {
      return false;
    }
    const roleLower = role.trim().toLowerCase();

    const usr = PlUserStorage.user;
    if (usr && usr.Roles && usr.Roles !== null && usr.Roles.length > 0) {
      const foundRole = usr.Roles.find((r) => r.toLowerCase() === roleLower);
      if (foundRole) {
        return true;
      }
    }

    return false;
  }

  public static get isWebAdmin(): boolean {
    return this.hasRole(WellKnownRoleNamesDto.WebAdmin);
  }

  private static showDebugToolsKey = "showDebugTools";

  public static get showDebugTools(): boolean {
    const value = BrowserStorage.getItem(PlUserStorage.showDebugToolsKey) || "";
    return value == "true";
  }
  public static set showDebugTools(value: boolean) {
    if (value) {
      BrowserStorage.setItem(PlUserStorage.showDebugToolsKey, "true");
    } else {
      BrowserStorage.removeItem(PlUserStorage.showDebugToolsKey);
    }
  }

  public static get token(): TokenData {
    if (PlUserStorage.hasUser()) {
      return PlUserStorage.readUserWithToken().token;
    }
    return undefined;
  }

  public static get user(): UserInformationValuesDto {
    if (PlUserStorage.hasUser()) {
      return PlUserStorage.readUserWithToken().user;
    } else {
      return undefined;
    }
  }

  public static isCurrentUserNameEqualTo(userShortName: string): boolean {
    if (this.hasUser()) {
      return PlUserStorage.user.Username === userShortName;
    }
    return false;
  }

  public static store(username: string, token: TokenData, user: UserInformationValuesDto) {
    var previousUser = PlUserStorage.readUserWithToken();

    const data: IUserWithToken = { username, token, user };
    BrowserStorage.setItem(PlUserStorage.storageUserKey, JSON.stringify(data));

    if (PlUserStorage.usersEquals(previousUser?.user, user) === false) {
      PlUserStorage._userChangedSource.next(user);
    }
  }

  static usersEquals(user1: UserInformationValuesDto, user2: UserInformationValuesDto) {
    if (ElementHelper.isNullOrUndefined(user1) && ElementHelper.isNullOrUndefined(user2)) {
      return true;
    }

    if (ElementHelper.isNullOrUndefined(user1) || ElementHelper.isNullOrUndefined(user2)) {
      return false;
    }

    if (user1.Username !== user2.Username) {
      return false;
    }

    if (user1.PreferredModel !== user2.PreferredModel) {
      return false;
    }

    if (user1.Roles && user2.Roles) {
      if (user1.Roles.length !== user2.Roles.length) {
        return false;
      }

      var filteredRoles = user1.Roles.filter(function (n) {
        return user2.Roles.indexOf(n) !== -1;
      });

      return filteredRoles.length == user1.Roles.length;
    }

    return true;
  }

  public static getPreferredModelFrom(storageType: BrowserStorageType): string {
    const content = BrowserStorage.getItemFor(storageType, PlUserStorage.storageUserKey);
    const userData = this.parseUserData(content);

    if (ElementHelper.isNullOrUndefined(userData) === false && ElementHelper.isNullOrUndefined(userData.user) === false) {
      return StringHelper.hasNonEmptyText(userData.user.PreferredModel) === true ? userData.user.PreferredModel : "";
    }

    return "";
  }

  public static updatePreferredModel(preferredModel: string) {
    const user = PlUserStorage.user;
    user.PreferredModel = preferredModel;
    this._preferredModelChangedSource.next(true);
    PlUserStorage.store(user.Username, PlUserStorage.token, user);
  }

  private static readUserWithToken(): IUserWithToken {
    const content = BrowserStorage.getItem(PlUserStorage.storageUserKey);
    return this.parseUserData(content);
  }

  private static parseUserData(data: string) {
    if (data !== "" && data !== null) {
      const dataWithUser: IUserWithToken = JSON.parse(data);
      if (dataWithUser !== null) {
        return dataWithUser;
      }
    }

    return undefined;
  }

  public static hasUser(): boolean {
    const current = BrowserStorage.getItem(PlUserStorage.storageUserKey);
    if (current !== null) {
      return true;
    }

    return false;
  }

  public static hasUserWithData(): boolean {
    const user = PlUserStorage.user;
    let result = this.hasUser();
    if (user && (user.DataInSystem === false || user.Identification === null || user.Identification === undefined)) {
      result = false;
    }
    return result;
  }

  public static logOut() {
    BrowserStorage.clearStorageFrom(PlUserStorage.storageUserKey);
    BrowserStorage.clearSessionStorage();
    PlUserStorage._userChangedSource.next(null);
  }
  public static get language(): string {
    const result = BrowserStorage.getItem(PlUserStorage.languageKey);
    if (result !== null) {
      return result;
    }
    return "";
  }

  public static set language(language: string) {
    BrowserStorage.setItem(PlUserStorage.languageKey, language);
  }

  public static get hasLanguage(): boolean {
    const lang = PlUserStorage.language;
    if (lang !== null && lang.length > 0) {
      return true;
    }
    return false;
  }

  public static set hideTopbar(value: boolean) {
    BrowserStorage.setItem(PlUserStorage.hideTopbarKey, String(value));
    this._hideTopbarSource.next(value);
  }

  public static get hideTopbar(): boolean {
    const result = BrowserStorage.getItem(PlUserStorage.hideTopbarKey);
    if (result == "true") {
      return true;
    }
    return false;
  }

  private static _userChangedSource = new BehaviorSubject<UserInformationValuesDto>(PlUserStorage.user);
  public static userChanged = PlUserStorage._userChangedSource.asObservable();

  private static _preferredModelChangedSource = new BehaviorSubject<boolean>(false);
  public static preferredModelChanged = this._preferredModelChangedSource.asObservable();

  private static _hideTopbarSource = new BehaviorSubject<boolean>(PlUserStorage.hideTopbar);
  public static hideTopbarChanged = PlUserStorage._hideTopbarSource.asObservable();
}
