import { Component, Input } from "@angular/core";

import { CmsFeaturesModel } from "../cms-features-model";
import { CMSMessageList } from "../cms-messagelist.model";

@Component({
  selector: "app-news-component",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class NewsComponent {
  public constructor(cmsFeaturesModel: CmsFeaturesModel) {
    this.widerWidthNewsBeforeCarousel = cmsFeaturesModel.Cms.wider_width_news_before_carousel || false;
  }

  private widerWidthNewsBeforeCarousel: boolean;

  @Input() messageList: CMSMessageList;
  @Input() public useFullWidth: boolean = false;

  public get featureToggleBasedStyle(): string {
    if (this.widerWidthNewsBeforeCarousel === true) {
      return "messagesWiderWidthNewsBeforeCarousel";
    }
    return this.useFullWidth ? "messagesWide" : "messagesNormal";
  }
}
