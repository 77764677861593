import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonRoutesDto } from "../../common/models/dto/CommonRoutes-dto";

@Injectable()
export class RouteNavigation {
  constructor(private router: Router) {}

  public navigate(shortRoute: string, id?: any) {
    let location = "/" + shortRoute;
    if (id !== undefined) {
      location = location + "/" + id;
    }

    this.router.navigate([location]);
  }

  public navigateWithParameterId(shortRoute: string, id: any) {
    this.router.navigate(["/" + shortRoute, { id: id }]);
  }

  public goToRoot() {
    return this.navigate("");
  }

  public gotToLandingPage() {
    this.navigate(CommonRoutesDto.LandingPage);
  }

  public gotToLogin() {
    this.navigate(CommonRoutesDto.Login);
  }
}
