import { RouterModule, Routes } from "@angular/router";
import { DxChartModule, DxDateBoxModule, DxSelectBoxModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { MessageRatingRoutesDto } from "../../models/cms/dto/MessageRatingRoutes-dto";
import { MessageRatingModule } from "./../messagerating/message-rating.module";
import { MessageRatingChartComponent } from "./message-rating-chart.component";
import { MessageRatingDashboardComponent } from "./message-rating-dashboard.component";

const routes: Routes = [
  {
    path: MessageRatingRoutesDto.MessageRatingOverviewDashboard,
    component: MessageRatingDashboardComponent,
    data: [{ menuType: MenuItemTypesDto.Dashboarding }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, MessageRatingModule, DxChartModule, DxSelectBoxModule, DxDateBoxModule],
  exports: [MessageRatingDashboardComponent],
  declarations: [MessageRatingDashboardComponent, MessageRatingChartComponent],
  providers: [MessageRatingModule],
})
export class MessageRatingDashboardsModule {}
