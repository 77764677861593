import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "Initials" })
export class ShowInitials implements PipeTransform {
  transform(name: string): string {
    let initials = "";

    if (name && name.length > 0) {
      initials += name.charAt(0);

      for (let i = 0; i < name.length; i++) {
        if (name[i] === " ") {
          initials += "." + name[i + 1];
        }
      }

      if (initials.length > 1) {
        initials += ".";
      }
    }

    return initials;
  }
}
