import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EbsConfigurationDto } from "../../models/ebs/dto/EbsConfigurationDto-dto";
import { EbsConfigurationMetaDataDto } from "../../models/ebs/dto/EbsConfigurationMetaDataDto-dto";
import { EbsConfiguration } from "./ebsconfig";
import { EbsConfigurationMetaData } from "./ebsgrouping";

@Injectable({ providedIn: null })
export class EbsEditService extends PlBaseService {
  private endPoint = "ebsedit";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, private languageService: CmsLanguage, private selectLanguage: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getEbsConfig(): Observable<EbsConfigurationMetaData> {
    return this.getData<EbsConfigurationMetaDataDto>(this.endPoint).pipe(map(this.convertData, this));
  }
  convertData(data: EbsConfigurationMetaDataDto): EbsConfigurationMetaData {
    if (data) {
      const newGrouping = new EbsConfigurationMetaData();
      newGrouping.copyFromDto(data, this.selectLanguage);
      return newGrouping;
    } else {
      return null;
    }
  }

  public saveEbsConfig(ebsConfig: EbsConfigurationDto): Observable<EbsConfiguration> {
    const url = this.endPoint + "/" + ebsConfig.Id;
    return this.updateTransformedResponse<EbsConfigurationDto>(ebsConfig, url).pipe(map(this.convertDto, this));
  }

  private convertDto(dto: EbsConfigurationDto): EbsConfiguration {
    if (dto) {
      const newEbsConfiguration = new EbsConfiguration(this.selectLanguage);
      newEbsConfiguration.copyFromDto(dto);
      return newEbsConfiguration;
    }
    return null;
  }

  public getDefault(): EbsConfiguration {
    return EbsConfiguration.createDefault(this.languageService, this.selectLanguage);
  }

  public deleteConfiguration(id: number): Observable<void> {
    const url = this.endPoint + "/" + id;
    return this.remove<void>(url);
  }
}
