import { ModelCategoryDto } from "../../models/dto/ModelCategoryDto-dto";
import { CategoryBase } from "./category-base.model";

export class ModelCategory extends CategoryBase {
  public copyFromDto(dto: ModelCategoryDto) {
    this.keyId = dto.KeyId;
    this.shortName = dto.ShortName;
    this.sortOrder = dto.SortOrder;
  }
}
