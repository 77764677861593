import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { RouteNavigation } from "../../../angular-common/baseservice/route-navigation";
import { RouteDataProvider } from "../../../angular-common/components/routedata/routedata-provider";
import { CmsFeaturesModel } from "../../../employee-common/cms/cms-features-model";
import { CmsLanguage } from "../../../employee-common/cms/language/cmslanguage.service";
import { ImageService } from "../../../employee-common/components/images/image.service";
import { MenuTypeProvider } from "../../../employee-common/components/menus/menu-type-provider";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();
  public isLoginPage: boolean = true;
  private urlBackgroundImage: string = "";
  private menuTypeSubscription: Subscription = null;

  @HostBinding("style.background-image") public backgroundImage = "";

  constructor(
    private routeDataProvider: RouteDataProvider,
    private menuTypeProvider: MenuTypeProvider,
    public imageService: ImageService,
    public cmsLanguage: CmsLanguage,
    private routeNavigation: RouteNavigation,
    private featureSettings: CmsFeaturesModel,
  ) {
    this.subscriptions.add(
      this.routeDataProvider.dataChanged.subscribe((d) => {
        this.isLoginPage = d && d.isLoginPage;
      }),
    );
  }
  ngOnInit(): void {
    this.urlBackgroundImage = this.imageService.urlLogin;
    this.subscribeToMenuTypeChanges();
  }

  public get hasUser(): boolean {
    if (PlUserStorage.user) {
      return true;
    } else {
      return false;
    }
  }

  public searchEnabled() {
    if (this.featureSettings.searchEnabled) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.menuTypeSubscription) {
      this.menuTypeSubscription.unsubscribe();
    }
  }

  private subscribeToMenuTypeChanges() {
    this.menuTypeSubscription = this.menuTypeProvider.menuItemType.subscribe((type) => {
      this.setBackGroundMatchingCurrentLocation(type);
      this.setBrowserTabTitle(type);
    });
  }

  private setBackGroundMatchingCurrentLocation(menuType: MenuItemTypesDto) {
    let img = "";
    if (this.isLoginPage) {
      if (this.urlBackgroundImage !== "") {
        img = "url(" + this.urlBackgroundImage + ")";
      }
    }
    this.backgroundImage = img;
  }

  private setBrowserTabTitle(menuType: MenuItemTypesDto) {
    let title = this.cmsLanguage.applicationTitle;

    if (menuType === MenuItemTypesDto.Management) {
      title = `${title} - ${this.cmsLanguage.f3MeWebApiMenuManagementHeader}`;
    }

    if (menuType === MenuItemTypesDto.Technical) {
      title = `${title} - ${this.cmsLanguage.ApiMenuTechnicalManagement}`;
    }

    if (menuType === MenuItemTypesDto.CMS) {
      title = `${title} - ${this.cmsLanguage.f3MeWebApiAppManagementHeader}`;
    }

    document.title = title;
  }

  title = "For All";
}
