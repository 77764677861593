import { NgModule, Type } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { CMSComponent } from "../../cms/cms.component";
import { LabourGroupModule } from "../../cms/labourgroups/labourgroup.module";
import { LanguageItemEditorGridModule } from "../../cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { AppComponentSpecificComponentFactory } from "../../components/appcomponentspecific-content/appcomponentspecific-component-factory";
import { AppComponentSpecificSpecification } from "../../components/appcomponentspecific-content/appcomponentspecific-component-specification";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { AppComponentSpecificViewModule } from "../../components/appcomponentspecific-content/appcomponentspecific-view-module";
import { CmsRoutesDto } from "../../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { LabourConditionGroupsEditComponent } from "./cms-labourcondition-groups-edit.component";
import { CmsLabourConditionLinkEditDynamicComponent } from "./cms-labourcondtion-link-edit-dynamic.component";
import { CmsLabourConditionLinkEditChoicesAsInputComponent } from "./cms-labourcondtion-link-edit.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.LabourConditionsGroupEdit,
    component: LabourConditionGroupsEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },

  {
    path: CmsRoutesDto.LabourConditionEdit,
    component: CMSComponent,
    data: [
      {
        menuType: MenuItemTypesDto.CMS,
        allowEdit: true,
        messageType: MessageTypeDto.labourcondition,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: CmsRoutesDto.LinkLabourConditions,
    component: CmsLabourConditionLinkEditDynamicComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, LabourGroupModule, DxButtonModule, DxDataGridModule, LanguageItemEditorGridModule, AppComponentSpecificViewModule],
  exports: [LabourConditionGroupsEditComponent, CmsLabourConditionLinkEditDynamicComponent, CmsLabourConditionLinkEditChoicesAsInputComponent],
  declarations: [LabourConditionGroupsEditComponent, CmsLabourConditionLinkEditDynamicComponent, CmsLabourConditionLinkEditChoicesAsInputComponent],
  providers: [],
})
export class LabourConditionManagementModule {
  constructor() {
    LabourConditionManagementModule.registerDetailClass(CmsLabourConditionLinkEditChoicesAsInputComponent);
  }

  public static registerDetailClass(componentClass: Type<any>) {
    AppComponentSpecificComponentFactory.registerComponent(new AppComponentSpecificSpecification(AppComponentSpecificContentType.LabourConditionLink, componentClass));
  }
}
