import { WellKnownLanguageDto } from "../../common/models/dto/WellKnownLanguage-dto";
import { EnumHelper } from "../helpers/enum.helper";
import { LanguageItem } from "./language-dto.model";
export class LanguageItemTypeGuard {
  public static isLanguageItem(item: any): boolean {
    if (item === undefined || item === null) {
      return false;
    }

    for (const propertyKey of EnumHelper.getEnumNames(WellKnownLanguageDto)) {
      if (typeof (item as LanguageItem)[propertyKey] === "string") {
        return true;
      }
    }
    return false;
  }
}
