import { Component, EventEmitter, Input, Output } from "@angular/core";

import { CardViewButton } from "./cardview-button";

@Component({
  selector: "app-cardview",
  templateUrl: "./cardview.component.html",
  styleUrls: ["./cardview.component.scss"],
})
export class CardViewComponent {
  public constructor() {}

  @Input()
  public title: string;

  @Input()
  public buttons: CardViewButton[];

  @Output()
  public buttonClicked = new EventEmitter();

  public buttonClick(btn: CardViewButton) {
    this.buttonClicked.emit(btn);
  }
}
