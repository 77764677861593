import { Component, Input } from "@angular/core";

import { ExogenousInputOption } from "../exogenous-input/options/exogenous-input-option.model";

@Component({
  selector: "app-exogenous-input-detail-options-component",
  templateUrl: "./exogenous-input-detail-options.component.html",
  styleUrls: ["./exogenous-input-detail-options.component.scss", "./shared.scss"],
  providers: [],
})
export class ExogenousInputDetailOptionsComponent {
  public constructor() {}

  @Input()
  public get options(): ExogenousInputOption[] {
    return this._options;
  }
  public set options(newOptions: ExogenousInputOption[]) {
    this._options = newOptions;
  }
  private _options: ExogenousInputOption[];
}
