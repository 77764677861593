import { IModelWithIdDto } from './../../../../common/models/dto/IModelWithId-dto';
import { ReportingInputDto } from './ReportingInputDto-dto';
import { RoleDto } from './../../../../common/models/dto/RoleDto-dto';

export declare type ReportDefinitionDtoTitleDto = [string, string];
export declare type ReportDefinitionDtoDescriptionDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file ReportDefinitionDto.cs
// Don't adjust manually!

export class ReportDefinitionDto implements IModelWithIdDto {
    public KeyId: number;
    public ShortName: string;
    public ReportPath: string;
    public VariantId: number;
    public Title: ReportDefinitionDtoTitleDto;
    public Description: ReportDefinitionDtoDescriptionDto;
    public Roles: RoleDto [] = [];
    public Inputs: ReportingInputDto [] = [];
}

