import { DataTypeDto } from "../../models/dto/DataType-dto";
import { ElementTypeDto } from "../../models/dto/ElementType-dto";
import { ModelElementDto } from "../../models/dto/ModelElementDto-dto";
import { ModelPresentableElementDto } from "../../models/dto/ModelPresentableElement-dto";
import { PresentableElement } from "./presentable-element.model";

export class ModelPresentableElement extends PresentableElement {
  public elementType: ElementTypeDto;
  public dataType: DataTypeDto;

  public constructor(elementType: ElementTypeDto) {
    super();
    this.elementType = elementType;
  }

  public getCopy(): ModelPresentableElement {
    const res = new ModelPresentableElement(this.elementType);
    res.copyFrom(this);
    return res;
  }

  public copyFrom(other: ModelPresentableElement) {
    if (other) {
      super.copyFrom(other);
      this.elementType = other.elementType;
      this.dataType = other.dataType;
    }
  }

  public copyFromDto(other: ModelPresentableElementDto) {
    if (other) {
      super.copyFromDto(other);
      this.elementType = other.TypeOfElement;
      const asModelElement = <ModelElementDto>other;
      if (asModelElement && asModelElement.DataType) {
        this.dataType = asModelElement.DataType;
      }
    }
  }

  public assignDto(result: ModelPresentableElementDto) {
    super.assignDto(result);
    result.TypeOfElement = this.elementType;
    const resultAsDto = <ModelElementDto>result;
    resultAsDto.DataType = this.dataType;
  }

  public toDto(): ModelPresentableElementDto {
    const result = new ModelPresentableElementDto();
    this.assignDto(result);
    return result;
  }
}
