import { Component, Input } from "@angular/core";

import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { TargetStatusDto } from "../../../models/choices/dto/TargetStatus-dto";
import { TargetProperties } from "../targetproperties/target-properties.model";

@Component({
  selector: "app-exogenous-input-order-progress-component",
  templateUrl: "./exogenous-input-order-progress.component.html",
  styleUrls: ["./exogenous-input-order-progress.component.scss", "./shared.scss"],
  providers: [],
})
export class ExogenousInputOrderProgressComponent {
  public constructor(public languageService: CmsLanguage) {}

  @Input()
  public targetProperties: TargetProperties;

  // We need to do this in order to use the TargetStatus enum in our template. This is the way Angular2 works.
  private _targetStatus = TargetStatusDto;
  public get targetStatus() {
    return this._targetStatus;
  }
  public set targetStatus(value) {
    this._targetStatus = value;
  }

  public get showProgressControls(): boolean {
    return this.targetProperties.exogenousInput.status !== TargetStatusDto.Unknown && this.targetProperties.exogenousInput.status !== TargetStatusDto.NotAbleToSelect;
  }
}
