import { ManagementGridRowDataDto } from "../../../../../employee-common/models/cms/dto/ManagementGridRowData-dto";
import { DataRow } from "./data-row";

export class DataRows {
  public rows: DataRow[] = [];

  public copyFromDTO(dto: ManagementGridRowDataDto[]) {
    dto.forEach((rowDTO) => {
      const newRow = new DataRow();
      for (const key in rowDTO) {
        if (key) {
          newRow[key] = rowDTO[key];
        }
      }
      this.rows.push(newRow);
    });
  }

  public get all(): DataRow[] {
    return this.rows;
  }

  public clearChanges() {
    this.rows.forEach((r) => {
      r.clearChanges();
    });
  }
}
