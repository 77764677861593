import { AfterContentInit, Component, ErrorHandler, Input, OnDestroy } from "@angular/core";
import { EMPTY, Subscription } from "rxjs";
import { PlErrorHandler } from "./plerrorhandler";

@Component({
  selector: "app-pl-errorfeedback",
  template: `
    <dx-popup [showTitle]="true" [title]="title" [(visible)]="isVisible" [height]="230" [width]="popupWidth">
      <div *dxTemplate="let data of 'content'" style="height:100%; width: 100%;">
        <div style="height: 100%; width: 100%;">
          <label>{{ description }}</label>
          <br />
          <textarea readonly class="errorMessage">{{ errorMessage }}</textarea>
          <br />
          <span class="closebutton">
            <dx-button [text]="buttonOk" (click)="closePopup()"></dx-button>
          </span>
        </div>
      </div>
    </dx-popup>
  `,
  styles: [
    `
      .errorMessage {
        width: 100%;
        height: 50%;
        overflow-y: scroll;
        overflow-x: hidden;
        resize: none;
      }
    `,
  ],
  providers: [],
})
export class PlErrorFeedbackComponent implements AfterContentInit, OnDestroy {
  private subscription: Subscription;

  public constructor(private errorHandler: ErrorHandler) {}

  public ngAfterContentInit(): void {
    const customHandler = this.errorHandler as PlErrorHandler;
    if (customHandler.errorMsg !== undefined) {
      this.subscription = customHandler.errorMsg.subscribe((error) => {
        this.showError(error);
      });
    } else {
      this.subscription = EMPTY.subscribe((error) => this.showError(error));
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public isVisible: boolean = false;
  public errorMessage: string = "";

  @Input()
  public title: string = "Error";

  @Input()
  public description: string = "An error occured";

  @Input()
  public buttonOk: string = "OK";

  public closePopup(): void {
    this.isVisible = false;
    this.errorMessage = "";
  }

  private showError(error: string): void {
    if (error === PlErrorHandler.initialValue) {
      this.closePopup();
    } else if (error !== "") {
      this.isVisible = true;
      this.errorMessage = error;
    } else {
      this.closePopup();
      console.log("We received an empty error.");
    }
  }

  public get popupWidth() {
    let result = 550;
    if (result > window.screen.width - 10) {
      result = window.screen.width - 10;
    }
    return result;
  }
}
