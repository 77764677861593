import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";

import { HttpResponseBase } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { WebLanguage } from "../../common/language/weblanguage.service";
import { CommonRoutesDto } from "../../common/models/dto/CommonRoutes-dto";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { StringHelper } from "../helpers/string.helper";
import { PlLoadIndicator } from "../loadindicator/loadindicator";
import { ErrorDectedType } from "./ErrorDetectedType";
import { ErrorDetection } from "./ErrorDetection";

@Injectable()
export class PlErrorHandler implements ErrorHandler {
  // We use an initial value for the error message. This is because when subscribing to an Observable
  // the observable gets called immediately.
  public static readonly initialValue: string = "initial error string";

  private router: Router;

  public constructor(private injector: Injector, private envSettings: EnvironmentSettings, private loadIndicator: PlLoadIndicator, private zone: NgZone, private language: WebLanguage) {
    console.log("Error handler created");
  }

  public handleError(error: any) {
    this.loadIndicator.forceClose();
    console.log("Running error handling");
    const errorType = ErrorDetection.findErrorType(error, this.envSettings);

    if (errorType !== undefined) {
      if (error.rejection !== undefined) {
        console.log("Dev:We should handle errors on promises.");
        error = error.rejection;
      }

      const errorTypeDetectedTypeValue = ErrorDectedType[errorType];
      if (errorTypeDetectedTypeValue !== undefined) {
        console.log("Error detected: " + errorTypeDetectedTypeValue);
        if (errorType === ErrorDectedType.ApiUnAuthorized) {
          if (this.handleAuthError(error)) {
            return;
          }
        }
        if (errorType === ErrorDectedType.ApiOffline) {
          this._errorMsgSource.next(this.language.errorCouldnotconnecttoserver);
          return true;
        }
      }
    }

    if (error) {
      console.log(error);

      if (StringHelper.hasNonEmptyText(error.error)) {
        error = error.error;
      } else if (StringHelper.hasNonEmptyText(error.message)) {
        error = error.message;
      } else if (StringHelper.hasNonEmptyText(error.statusText)) {
        error = error.statusText;
      }

      this._errorMsgSource.next(error);
    } else {
      this._errorMsgSource.next("Unknown error");
    }
  }

  private handleAuthError(httpReponse: HttpResponseBase) {
    if (httpReponse !== undefined && httpReponse.status !== undefined) {
      // Router depends on ErrorHandler (this class) so we can't inject router in the constructor. Therefore
      // we retrieve it manually/lazy via the Injector.
      if (this.router === undefined || this.router === null) {
        this.router = this.injector.get(Router);
      }

      let location = httpReponse.url;
      if (location) {
        const decodeLocation = decodeURIComponent(location);
        const indexOfApi = decodeLocation.indexOf("api");
        if (indexOfApi >= 0) {
          location = decodeLocation.substring(indexOfApi + 4);
        }
      }

      console.log("Unauthorized; navigate to " + CommonRoutesDto.InsufficientRights);
      this.zone.run(() => this.router.navigate([CommonRoutesDto.InsufficientRights, { api: location }]));
      return true;
    }

    return false;
  }

  private _errorMsgSource = new BehaviorSubject<string>(PlErrorHandler.initialValue);
  public errorMsg = this._errorMsgSource.asObservable();
}
