<div class="log-container" *ngIf="contentReady">
  <p *ngIf="numberOfItems > 0">{{ language.logDownloadFileList }}</p>
  <p *ngIf="numberOfItems <= 0">{{ language.logDownloadNoFiles }}</p>
  <table cellpadding="3" class="log-items">
    <tr *ngFor="let logItem of logItems">
      <td class="log-item-name">{{ logItem }}</td>
      <td class="log-item-link">
        <label class="download-link" (click)="showContent(logItem)">{{ language.logDownloadPreview }}</label>
      </td>
      <td class="log-item-link">
        <label class="download-link" *ngIf="hasDownloadUrl(logItem) === false" (click)="downloadContent(logItem)">{{ language.logDownloadStartDownload }}</label>
        <a *ngIf="hasDownloadUrl(logItem) === true" [attr.href]="downloadableLogs[logItem]" [download]="logItem">{{ language.logDownloadReady }}</a>
      </td>
      <td class="log-item-link">
        <label class="download-link" (click)="openInNewTab(logItem)"> {{ language.logDownloadNewTab }}</label>
      </td>
      <td *ngIf="allowDirectApiLink" class="log-item-link">
        <a [attr.href]="getDirectLink(logItem)" target="_blank">{{ language.logDownloadDirectLink }}</a>
      </td>
    </tr>
  </table>
</div>
<app-textpopup-component [interaction]="popupInteraction"></app-textpopup-component>
