import { Component, Input } from "@angular/core";

import { EditToggle } from "../../../employee-common/components/edittoggle/edittoggle";
import { LanguageBuilder } from "../../../common/language/language-builder";
import { LanguageEditItem } from "../../../employee-common/components/language/languageedititem.model";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

@Component({
  selector: "app-languageedititem-component",
  templateUrl: "./languageedit-item.component.html",
  styleUrls: ["./languageedit-item.component.scss"],
  providers: [],
})
export class LanguageEditItemComponent {
  public constructor(private selectLanguage: PlLanguageSelection) {
    this.editToggle = new EditToggle();
    this.editToggle.stopEditing.subscribe((save) => {
      if (save === true && this.item && this.item !== null) {
        this.item.setValueAndSendToServer(this.languageItemValue);
      } else {
        this.initLanguageItemValue();
      }
    });
  }

  public editToggle: EditToggle;
  public languageItemValue: LanguageItem;

  @Input() currentLanguage: string;

  @Input()
  public get item(): LanguageEditItem {
    return this._item;
  }
  public set item(newItem: LanguageEditItem) {
    this._item = newItem;
    this.initLanguageItemValue();
  }
  private _item: LanguageEditItem;

  private initLanguageItemValue() {
    const builder = new LanguageBuilder(this.selectLanguage);
    // Make a copy of the item that we're editing so we can also support 'cancel' operations
    if (this.item && this.item !== null) {
      this.languageItemValue = builder.copyLanguageItem(this.item.value);
    } else {
      this.languageItemValue = builder.createLanguageItem();
    }
  }
}
