import { Injectable } from "@angular/core";
import { LanguageServiceBase } from "../../components/language/language-base.service";
import { PersonlizedLanguageService } from "../../components/language/personlized-language.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageService } from "../../../angular-common";

@Injectable()
//t4Languages": "0.8.2.0
export class EbsLanguage extends LanguageServiceBase {
  public constructor(plLanguageService: PlLanguageService, languageSelection: PlLanguageSelection, personlizedLanguageService: PersonlizedLanguageService) {
    super(plLanguageService, languageSelection, personlizedLanguageService);
  }

  /**
   * f3MeWebApiEbsHeader from Ebs.csv (in EN: Employee benefit statement )
   */
  public key_f3MeWebApiEbsHeader = "f3MeWebApiEbsHeader";
  /**
   * f3MeWebApiEbsHeader from Ebs.csv (in EN: Employee benefit statement )
   */
  public get f3MeWebApiEbsHeader(): string { return this.getStringDef(this.key_f3MeWebApiEbsHeader, "Employee benefit statement"); }

  /**
   * f3MeWebApiEbsHeaderEditHeader from Ebs.csv (in EN: Ebs intro )
   */
  public key_f3MeWebApiEbsHeaderEditHeader = "f3MeWebApiEbsHeaderEditHeader";
  /**
   * f3MeWebApiEbsHeaderEditHeader from Ebs.csv (in EN: Ebs intro )
   */
  public get f3MeWebApiEbsHeaderEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiEbsHeaderEditHeader, "Ebs intro"); }

  /**
   * f3MeWebApiEbsIntro from Ebs.csv (in EN: Ebs intro )
   */
  public key_f3MeWebApiEbsIntro = "f3MeWebApiEbsIntro";
  /**
   * f3MeWebApiEbsIntro from Ebs.csv (in EN: Ebs intro )
   */
  public get f3MeWebApiEbsIntro(): string { return this.getStringDef(this.key_f3MeWebApiEbsIntro, "Ebs intro"); }

  /**
   * f3MeWebApiEbsIntroEditHeader from Ebs.csv (in EN: EBS intro: )
   */
  public key_f3MeWebApiEbsIntroEditHeader = "f3MeWebApiEbsIntroEditHeader";
  /**
   * f3MeWebApiEbsIntroEditHeader from Ebs.csv (in EN: EBS intro: )
   */
  public get f3MeWebApiEbsIntroEditHeader(): string { return this.getStringDef(this.key_f3MeWebApiEbsIntroEditHeader, "EBS intro:"); }

  /**
   * f3MeWebApiEbsIntroIsSummery from Ebs.csv (in EN: Use as mini ebs )
   */
  public key_f3MeWebApiEbsIntroIsSummery = "f3MeWebApiEbsIntroIsSummery";
  /**
   * f3MeWebApiEbsIntroIsSummery from Ebs.csv (in EN: Use as mini ebs )
   */
  public get f3MeWebApiEbsIntroIsSummery(): string { return this.getStringDef(this.key_f3MeWebApiEbsIntroIsSummery, "Use as mini ebs"); }

  /**
   * f3MeWebApiEbsEditTreeHeader from Ebs.csv (in EN: Edit EBS elements )
   */
  public key_f3MeWebApiEbsEditTreeHeader = "f3MeWebApiEbsEditTreeHeader";
  /**
   * f3MeWebApiEbsEditTreeHeader from Ebs.csv (in EN: Edit EBS elements )
   */
  public get f3MeWebApiEbsEditTreeHeader(): string { return this.getStringDef(this.key_f3MeWebApiEbsEditTreeHeader, "Edit EBS elements"); }

  /**
   * f3MeWebApiEbsTreeModelElement from Ebs.csv (in EN: Value )
   */
  public key_f3MeWebApiEbsTreeModelElement = "f3MeWebApiEbsTreeModelElement";
  /**
   * f3MeWebApiEbsTreeModelElement from Ebs.csv (in EN: Value )
   */
  public get f3MeWebApiEbsTreeModelElement(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeModelElement, "Value"); }

  /**
   * f3MeWebApiEbsTreeAdditionalModelElement from Ebs.csv (in EN: Additional value )
   */
  public key_f3MeWebApiEbsTreeAdditionalModelElement = "f3MeWebApiEbsTreeAdditionalModelElement";
  /**
   * f3MeWebApiEbsTreeAdditionalModelElement from Ebs.csv (in EN: Additional value )
   */
  public get f3MeWebApiEbsTreeAdditionalModelElement(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeAdditionalModelElement, "Additional value"); }

  /**
   * f3MeWebApiEbsTreeVisibilityModelElementText from Ebs.csv (in EN: Visible with: {0} )
   */
  public key_f3MeWebApiEbsTreeVisibilityModelElementText = "f3MeWebApiEbsTreeVisibilityModelElementText";
  /**
   * f3MeWebApiEbsTreeVisibilityModelElementText from Ebs.csv (in EN: Visible with: {0} )
   */
  public f3MeWebApiEbsTreeVisibilityModelElementText(val1): string { return this.getStringDef("f3MeWebApiEbsTreeVisibilityModelElementText", "Visible with: {0}").replace("{0}", val1); }

  /**
   * f3MeWebApiEbsTreeVisibilityModelElement from Ebs.csv (in EN: Visibility )
   */
  public key_f3MeWebApiEbsTreeVisibilityModelElement = "f3MeWebApiEbsTreeVisibilityModelElement";
  /**
   * f3MeWebApiEbsTreeVisibilityModelElement from Ebs.csv (in EN: Visibility )
   */
  public get f3MeWebApiEbsTreeVisibilityModelElement(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeVisibilityModelElement, "Visibility"); }

  /**
   * f3MeWebApiEbsTreeHasLink from Ebs.csv (in EN: Link )
   */
  public key_f3MeWebApiEbsTreeHasLink = "f3MeWebApiEbsTreeHasLink";
  /**
   * f3MeWebApiEbsTreeHasLink from Ebs.csv (in EN: Link )
   */
  public get f3MeWebApiEbsTreeHasLink(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeHasLink, "Link"); }

  /**
   * f3MeWebApiEbsTreeNewLink from Ebs.csv (in EN: New link )
   */
  public key_f3MeWebApiEbsTreeNewLink = "f3MeWebApiEbsTreeNewLink";
  /**
   * f3MeWebApiEbsTreeNewLink from Ebs.csv (in EN: New link )
   */
  public get f3MeWebApiEbsTreeNewLink(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeNewLink, "New link"); }

  /**
   * f3MeWebApiEbsTreeEditLink from Ebs.csv (in EN: Edit link )
   */
  public key_f3MeWebApiEbsTreeEditLink = "f3MeWebApiEbsTreeEditLink";
  /**
   * f3MeWebApiEbsTreeEditLink from Ebs.csv (in EN: Edit link )
   */
  public get f3MeWebApiEbsTreeEditLink(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeEditLink, "Edit link"); }

  /**
   * f3MeWebApiEbsTreeColor from Ebs.csv (in EN: Color )
   */
  public key_f3MeWebApiEbsTreeColor = "f3MeWebApiEbsTreeColor";
  /**
   * f3MeWebApiEbsTreeColor from Ebs.csv (in EN: Color )
   */
  public get f3MeWebApiEbsTreeColor(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeColor, "Color"); }

  /**
   * f3MeWebApiEbsTreeAppliesToTotal from Ebs.csv (in EN: Applies to total )
   */
  public key_f3MeWebApiEbsTreeAppliesToTotal = "f3MeWebApiEbsTreeAppliesToTotal";
  /**
   * f3MeWebApiEbsTreeAppliesToTotal from Ebs.csv (in EN: Applies to total )
   */
  public get f3MeWebApiEbsTreeAppliesToTotal(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeAppliesToTotal, "Applies to total"); }

  /**
   * f3MeWebApiEbsTreeHideModelElement from Ebs.csv (in EN: Hide value )
   */
  public key_f3MeWebApiEbsTreeHideModelElement = "f3MeWebApiEbsTreeHideModelElement";
  /**
   * f3MeWebApiEbsTreeHideModelElement from Ebs.csv (in EN: Hide value )
   */
  public get f3MeWebApiEbsTreeHideModelElement(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeHideModelElement, "Hide value"); }

  /**
   * f3MeWebApiEbsTreeHideAdditionalModelElement from Ebs.csv (in EN: Hide additional value )
   */
  public key_f3MeWebApiEbsTreeHideAdditionalModelElement = "f3MeWebApiEbsTreeHideAdditionalModelElement";
  /**
   * f3MeWebApiEbsTreeHideAdditionalModelElement from Ebs.csv (in EN: Hide additional value )
   */
  public get f3MeWebApiEbsTreeHideAdditionalModelElement(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeHideAdditionalModelElement, "Hide additional value"); }

  /**
   * f3MeWebApiEbsTreeModelElementSettings from Ebs.csv (in EN: Value settings )
   */
  public key_f3MeWebApiEbsTreeModelElementSettings = "f3MeWebApiEbsTreeModelElementSettings";
  /**
   * f3MeWebApiEbsTreeModelElementSettings from Ebs.csv (in EN: Value settings )
   */
  public get f3MeWebApiEbsTreeModelElementSettings(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeModelElementSettings, "Value settings"); }

  /**
   * f3MeWebApiEbsTreeAdditionalModelElementSettings from Ebs.csv (in EN: Additional value settings )
   */
  public key_f3MeWebApiEbsTreeAdditionalModelElementSettings = "f3MeWebApiEbsTreeAdditionalModelElementSettings";
  /**
   * f3MeWebApiEbsTreeAdditionalModelElementSettings from Ebs.csv (in EN: Additional value settings )
   */
  public get f3MeWebApiEbsTreeAdditionalModelElementSettings(): string { return this.getStringDef(this.key_f3MeWebApiEbsTreeAdditionalModelElementSettings, "Additional value settings"); }

  /**
   * f3MeWebApiMenuEbs from Ebs.csv (in EN: Ebs )
   */
  public key_f3MeWebApiMenuEbs = "f3MeWebApiMenuEbs";
  /**
   * f3MeWebApiMenuEbs from Ebs.csv (in EN: Ebs )
   */
  public get f3MeWebApiMenuEbs(): string { return this.getStringDef(this.key_f3MeWebApiMenuEbs, "Ebs"); }

  /**
   * f3MeWebApiMenuEbs_default from Ebs.csv (in EN: Ebs )
   */
  public key_f3MeWebApiMenuEbs_default = "f3MeWebApiMenuEbs_default";
  /**
   * f3MeWebApiMenuEbs_default from Ebs.csv (in EN: Ebs )
   */
  public get f3MeWebApiMenuEbs_default(): string { return this.getStringDef(this.key_f3MeWebApiMenuEbs_default, "Ebs"); }

  /**
   * f3MeWebApiEbsOverview from Ebs.csv (in EN: Ebs overview )
   */
  public key_f3MeWebApiEbsOverview = "f3MeWebApiEbsOverview";
  /**
   * f3MeWebApiEbsOverview from Ebs.csv (in EN: Ebs overview )
   */
  public get f3MeWebApiEbsOverview(): string { return this.getStringDef(this.key_f3MeWebApiEbsOverview, "Ebs overview"); }

  /**
   * f3MeWebApiEbsOverviewIntroText from Ebs.csv (in EN: On this page you see an overview of all ebs's )
   */
  public key_f3MeWebApiEbsOverviewIntroText = "f3MeWebApiEbsOverviewIntroText";
  /**
   * f3MeWebApiEbsOverviewIntroText from Ebs.csv (in EN: On this page you see an overview of all ebs's )
   */
  public get f3MeWebApiEbsOverviewIntroText(): string { return this.getStringDef(this.key_f3MeWebApiEbsOverviewIntroText, "On this page you see an overview of all ebs's"); }

  /**
   * f3MeWebApiDisclaimerMessageEbs from Ebs.csv (in EN: This information is informative and is not in any form legal information. )
   */
  public key_f3MeWebApiDisclaimerMessageEbs = "f3MeWebApiDisclaimerMessageEbs";
  /**
   * f3MeWebApiDisclaimerMessageEbs from Ebs.csv (in EN: This information is informative and is not in any form legal information. )
   */
  public get f3MeWebApiDisclaimerMessageEbs(): string { return this.getStringDef(this.key_f3MeWebApiDisclaimerMessageEbs, "This information is informative and is not in any form legal information."); }


}
