import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { EbsLanguage } from "../../ebs/language/ebslanguage.service";
import { EbsNodeDto } from "../../models/ebs/dto/EbsNodeDto-dto";
import { EbsTreeNode } from "./ebsnode";

@Injectable({ providedIn: null })
export class EbsTreeEditService extends PlBaseService {
  private endpoint = "ebstreeedit";

  constructor(private language: EbsLanguage, http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private languageSelection: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveRoots(): Observable<EbsTreeNode[]> {
    return this.getDataTransformed<EbsNodeDto[]>(this.endpoint).pipe(map((r) => this.transformData(r)));
  }
  transformData(dto: EbsNodeDto[]): EbsTreeNode[] {
    if (dto) {
      const result: EbsTreeNode[] = [];
      dto.forEach((node) => {
        const newNode = new EbsTreeNode(this.language);
        newNode.fromDto(node, this.languageSelection);
        result.push(newNode);
      });
      return result;
    } else {
      return null;
    }
  }

  public saveRoot(ebsnode: EbsNodeDto): Observable<EbsTreeNode> {
    const url = this.endpoint;
    return this.updateTransformedResponse<EbsNodeDto>(ebsnode, url).pipe(map((r) => this.transformSingle(r)));
  }

  public deleteNode(ebsnode: EbsNodeDto): Observable<any> {
    const url = this.endpoint + "/" + ebsnode.Details.CmsGroupId;
    return this.remove(url);
  }

  retrieveRoot(cmsId: number): Observable<EbsTreeNode> {
    const url = this.endpoint + "/" + cmsId;
    return this.getDataTransformed<EbsNodeDto>(url).pipe(map((r) => this.transformSingle(r)));
  }
  transformSingle(dto: EbsNodeDto): EbsTreeNode {
    if (dto) {
      const result = new EbsTreeNode(this.language);
      result.fromDto(dto, this.languageSelection);
      return result;
    } else {
      return null;
    }
  }
}
