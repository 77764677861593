import { Component } from "@angular/core";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";

@Component({
  selector: "app-arrange-labourconditions",
  template: ` <app-appcomponentspecific-content-view [type]="dynamicComponentType"></app-appcomponentspecific-content-view> `,
})
export class CmsLabourConditionLinkEditDynamicComponent {
  dynamicComponentType: any;
  constructor() {
    this.dynamicComponentType = AppComponentSpecificContentType.LabourConditionLink;
  }
}
