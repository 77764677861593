import { RouterModule, Routes } from "@angular/router";
import { DxButtonGroupModule, DxDataGridModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CmsRoutesDto } from "../../../employee-common/models/cms/dto/CmsRoutes-dto";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { CMSComponent } from "../cms.component";
import { LanguageEditManagementService } from "../languageedit-management/languagedit-management.service";
import { LanguageEditManagementComponent } from "./manage-languageedit.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.LanguageEditManagement,
    component: LanguageEditManagementComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },

  {
    path: CmsRoutesDto.WhatIfEdit,
    component: CMSComponent,
    data: [
      {
        menuType: MenuItemTypesDto.CMS,
        allowEdit: true,
        messageType: MessageTypeDto.simulationblock,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), DxDataGridModule, DxButtonGroupModule, CommonModule],
  exports: [],
  declarations: [LanguageEditManagementComponent],
  providers: [LanguageEditManagementService],
})
export class CmsManagementModule {}
