import { Component, Input } from "@angular/core";
import { ExogenousInputOptionDate } from "../targets/exogenous-input/options/exogenous-input-option-date.model";

@Component({
  selector: "app-exogeous-input-date-option",
  templateUrl: "./exogeous-input-date-option.component.html",
  styleUrls: ["./exogeous-input-date-option.component.scss"],
})
export class ExogeousInputDateOptionComponent {
  constructor() {}

  @Input()
  public valueOnly = false;

  @Input() option: ExogenousInputOptionDate;
}
