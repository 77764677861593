<dx-popup
  [width]="popupWidth"
  [height]="popupHeight"
  maxWidth="1000px"
  maxHeight="500px"
  [dragEnabled]="true"
  [resizeEnabled]="false"
  [showCloseButton]="true"
  [hideOnOutsideClick]="true"
  [(visible)]="popupSettings.isVisible"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view [showScrollbar]="'always'" [useNative]="false">
      <div class="popupContent">
        <app-appcomponentspecific-content-view [type]="dynamicComponentType" [data]="popupSettings"></app-appcomponentspecific-content-view>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
