<div class="welcomeMsg">
  <div class="welcomeImage" [ngStyle]="imageSettings">
    <div class="welcomeMsgText">
      <h4>{{ message.title }}</h4>
    </div>
    <div class="welcomeMsgText">
      <div *ngIf="message.summary" appSafeData [passedHtmlData]="message.summary"></div>
    </div>
  </div>
</div>
