import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

import { PlUserStorage } from "../baseservice/pluserstorage";

export class AppRouteBasedSettings {
  static configureFromRouteSnapShot(route: ActivatedRouteSnapshot) {
    AppRouteBasedSettings.configureFromRouteParamsInternal(route.queryParams);
  }
  static configureFromRoute(route: ActivatedRoute) {
    AppRouteBasedSettings.configureFromRouteParamsInternal(route.queryParams);
  }

  private static configureFromRouteParamsInternal(data: any) {
    const showDebugTools = data.showDebugTools || data.showDebug;
    if (showDebugTools) {
      console.log("AppRouteBasedSettings:showDebugTools", showDebugTools);
      PlUserStorage.showDebugTools = showDebugTools == "true";
    }

    const hideTop = data.hidetop || data.hideTop;
    if (hideTop) {
      console.log("AppRouteBasedSettings:hideTop", hideTop);
      PlUserStorage.hideTopbar = hideTop === "true";
    }
  }
}
