import { LanguageItem } from "../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";

export class LanguageBuilder {
  public static duplicate(source: LanguageItem): LanguageItem {
    const result: LanguageItem = {} as LanguageItem;
    this.copy(source, result);
    return result;
  }

  public static copy(source: LanguageItem, target: LanguageItem) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  }

  constructor(private selectLanguage: PlLanguageSelection) {}

  public createLanguageItemWithValue(value: string): LanguageItem {
    const result: LanguageItem = {} as LanguageItem;
    this.selectLanguage.languages.Languages.forEach((l) => {
      result[l.Id] = value;
    });

    return result;
  }

  public createLanguageItem(): LanguageItem {
    return this.createLanguageItemWithValue("");
  }

  public copyLanguageItem(source: LanguageItem): LanguageItem {
    const result: LanguageItem = {} as LanguageItem;
    this.selectLanguage.languages.Languages.forEach((l) => (result[l.Id] = this.selectLanguage.currentValueForLanguage(source, l.Id)));
    return result;
  }
}
