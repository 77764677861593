import { DxChartModule, DxPieChartModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PageHeaderModule } from "../../angular-common/components/page-header/page-header.module";
import { SafeDataDirectiveModule } from "../../angular-common/utils/safe-data-directive-module";
import { InspireCmsLinkModule } from "../../f3Me/src/app/shared/links/inspire-cms-link.module";
import { CMSModule } from "../cms/cms.module";
import { CmsPageHeaderModule } from "../cms/cmspageheaders/cmspage-header.module";
import { CmsLinkModule } from "../cms/links/cms-link.module";
import { EmployeeApplicationConfiguration } from "../employee-application-configuration";
import { BenefitGroupComponent } from "./ebs-benefit-group/ebs-benefit-group.component";
import { BenefitGroupsComponent } from "./ebs-benefit-group/ebs-benefit-groups.component";
import { EbsFeaturesModel } from "./ebs-features-model";
import { EbsDoughnutComponent } from "./ebs-overview/ebs-doughnut-component";
import { EbsOverviewComponent } from "./ebs-overview/ebs-overview-component";
import { EbsRoutingModule } from "./ebs-routing.module";
import { EBSService } from "./ebs-services";
import { EbsComponent } from "./ebs.component";
import { EbsLanguage } from "./language/ebslanguage.service";
import { DecimalWithPointsPipe } from "./numberWithPoints.pipe";

@NgModule({
  declarations: [EbsComponent, BenefitGroupsComponent, BenefitGroupComponent, DecimalWithPointsPipe, EbsOverviewComponent, EbsComponent, EbsDoughnutComponent],
  imports: [
    CmsPageHeaderModule,
    CommonModule,
    FormsModule,
    DxChartModule,
    DxPieChartModule,
    HttpClientModule,
    EbsRoutingModule,
    PageHeaderModule,
    CMSModule,
    EmployeeApplicationConfiguration.isInspire ? InspireCmsLinkModule : CmsLinkModule,
    CommonModule,
    SafeDataDirectiveModule,
  ],
  providers: [EBSService, EbsFeaturesModel, EbsLanguage],
  exports: [EbsComponent, EbsRoutingModule],
})
export class EbsModule {}
