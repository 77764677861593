<div>
  <app-cardview [title]="language.landingpageTitle">
    <div>
      <p>{{ language.landingpageMessage }}</p>
      <p *ngIf="hasUser === false">{{ language.landingpageNoUser }}</p>
      <p *ngIf="menuReady === false">{{ language.PleaseWait }}</p>
    </div>

    <div *ngIf="menuReady">
      <div *ngIf="hasItemsOutOfAuthenticationRoutes === false">
        <p>{{ language.landingpageNoValidMenuItems }}</p>
        <p>{{ language.accountClaimsInvestigate }}</p>
        <dx-button [text]="language.accountClaimsTitle" (onClick)="gotoClaims()"></dx-button>
      </div>
      <p *ngIf="hasItemsOutOfAuthenticationRoutes"></p>
      <ul class="icon-list">
        <li *ngFor="let menuItem of itemsWithoutAuthentication">
          <a class="button-link" [routerLink]="[menuItem.route]" routerLinkActive="active">
            <dx-button [icon]="menuItem.icon" [hint]="menuItem.displayTitle"></dx-button>{{ menuItem.displayTitle }}
          </a>
        </li>
      </ul>
    </div>
  </app-cardview>
</div>
