import { Component, Input } from "@angular/core";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { ExogenousInputOptionAttachment } from "../targets/exogenous-input/options/exogenous-input-option-attachment.model";

@Component({
  selector: "app-exogeous-input-attachment-option",
  templateUrl: "exogeous-input-attachment-option.component.html",
  styleUrls: ["exogeous-input-attachment-option.component.scss"],
})
export class ExogeousInputAttachmentOptionComponent {
  constructor(public language: CmsLanguage) {}

  @Input()
  public valueOnly = false;

  @Input() option: ExogenousInputOptionAttachment;
}
