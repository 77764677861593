import { LanguageBuilder } from "../../common/language/language-builder";
import { LanguageItem } from "../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";

export class ModelElementWithTitle {
  constructor(public selectLanguage: PlLanguageSelection) {}
  public titles: LanguageItem;
  public titleCopy: LanguageItem;

  public get title(): string {
    return this.selectLanguage.currentValueFor(this.titles);
  }

  public copyTitleToOriginal() {
    if (this.titleCopy) {
      LanguageBuilder.copy(this.titleCopy, this.titles);
    }
  }

  public createTitleCopy() {
    this.titleCopy = LanguageBuilder.duplicate(this.titles);
  }
}
