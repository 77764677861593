<div class="cropper-container">
  <div class="option-buttons">
    <dx-button icon="save" [text]="language.ButtonSave" (onClick)="save()"></dx-button>
  </div>
  <image-cropper
    #cropper
    [imageURL]="imageUrl"
    [aspectRatio]="aspectRatio"
    [maintainAspectRatio]="maintainAspectRatio"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>

  <div class="preview-image">
    <h1>Preview:</h1>
    <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
  </div>
</div>
