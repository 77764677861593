import { ApiResultDto } from "../../../common/models/dto/ApiResult-dto";

export class ApiResult {
  public constructor() {}

  public isSuccess: boolean;
  public message: string;

  public copyFromDTO(dto: ApiResultDto) {
    this.isSuccess = dto.IsSuccess;
    this.message = dto.Message;
  }
}
