import { Component, Input } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { CMSMessageList } from "../../cms/cms-messagelist.model";
import { CMSService } from "../../cms/cms.service";
import { LabourConditionGroup } from "../../cms/labourgroups/lc-group";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { ImageService } from "../../components/images/image.service";
import { LinkService } from "../../components/internallinks/link.service";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";

@Component({
  selector: "app-labour-topic",
  templateUrl: "./labour-overview-topic.html",
  styleUrls: ["./labour-overview-topic.scss"],
})
export class LabourTopicComponent {
  public constructor(
    private languageSelection: PlLanguageSelection,
    private cmsService: CMSService,
    private imageService: ImageService,
    private language: CmsLanguage,
    private linkService: LinkService,
    private domSanitizer: DomSanitizer,
  ) {
    this.messageList = new CMSMessageList(false);
  }

  @Input()
  public get group(): LabourConditionGroup {
    return this._group;
  }
  public set group(newGroup: LabourConditionGroup) {
    this._group = newGroup;

    if (this._group) {
      this.messageList.loadWithIds(this.cmsService, this.imageService, this.language, this.linkService, this.languageSelection, this.domSanitizer, MessageTypeDto.labourcondition, newGroup.messageIds);
    }
  }
  private _group: LabourConditionGroup;

  public messageList: CMSMessageList;
}
