export class StringComparer {
  public static areEqual(a: string, b: string): boolean {
    const aIsNull = a === undefined || a === null;
    const bIsNull = b === undefined || a === null;

    if (aIsNull && bIsNull) {
      return true;
    } else if (!aIsNull && !bIsNull) {
      return a.toLowerCase() === b.toLowerCase();
    }
    return false;
  }

  public static isNullUndefinedOrEmpty(value: string) {
    return value === null || value === undefined || value.length === 0;
  }
}
