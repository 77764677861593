import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxPopupModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HtmlEditorModule } from "../../angular-common/components/htmleditor/htmleditor.module";
import { canActivateUserAcknowledgementGuard } from "../auth/user-acknowledgemen.guard";
import { LanguageItemEditorGridModule } from "../cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { EditToggleModule } from "../components/edittoggle/edittoggle.module";
import { LanguageModule } from "../components/language/language.module";
import { LanguageItemEditorModule } from "../components/language/languageitem-editor/languageitem-editor.module";
import { CmsRoutesDto } from "../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../models/cms/dto/MenuItemTypes-dto";
import { EmailModule } from "./consume-mail/email-module";
import { EmailTemplateEditTemplateComponent } from "./emailtemplateedit/emailtemplate-edit-template.component";
import { EmailTemplateEditModule } from "./emailtemplateedit/emailtemplate-edit.module";
import { EmailTemplateConfigComponent } from "./management/cms-etemplate-config.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.EmailTemplateConfig,
    component: EmailTemplateConfigComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [EmailTemplateConfigComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    EmailModule,
    EmailTemplateEditModule,
    FormsModule,
    HttpClientModule,
    HtmlEditorModule,
    LanguageModule,
    LanguageItemEditorGridModule,
    EditToggleModule,
    LanguageItemEditorModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
  ],
  providers: [],
  exports: [EmailTemplateEditTemplateComponent],
})
export class EmployeeEmailModule {}
