import { Pipe, PipeTransform } from "@angular/core";
import { ExogenousInputOption } from "../targets/exogenous-input/options/exogenous-input-option.model";

@Pipe({
  name: "cast",
})
export class OptionsCast implements PipeTransform {
  transform<T>(value: ExogenousInputOption): T {
    return <T>(<unknown>value);
  }
}
