import { LanguageItem } from "../../../../angular-common/language/language-dto.model";
import { ISortOrder } from "../../../../angular-common/sorting/sortorder";
import { RoleDto } from "../../../../common/models/dto/RoleDto-dto";
import { ExogenousInputOptionCreator } from "../../../choices/targets/exogenous-input/options/exogenous-input-option-creator";
import { ExogenousInputOptionObserver } from "../../../choices/targets/exogenous-input/options/exogenous-input-option-observer";
import { ExogenousInputOption } from "../../../choices/targets/exogenous-input/options/exogenous-input-option.model";
import { ReportDefinitionDto } from "../../../models/reporting/dto/ReportDefinitionDto-dto";
import { ReportDefinitionWithFieldsDto } from "../../../models/reporting/dto/ReportDefinitionWithFieldsDto-dto";
import { ReportingInputDto } from "../../../models/reporting/dto/ReportingInputDto-dto";
import { ReportingLanguage } from "../../language/reporting.service";

export class ReportDefinition implements ISortOrder {
  public static createDefaultValue(languageService: ReportingLanguage): ReportDefinition {
    const defaultReport = new ReportDefinition();
    defaultReport.title = languageService.getEntry(languageService.key_NoTitle);
    defaultReport.description = languageService.getEntry(languageService.key_NoDescription);
    defaultReport.keyId = -1;
    defaultReport.inputs = [];
    defaultReport.reportPath = "";
    defaultReport.shortName = "";
    defaultReport.variantId = -1;
    defaultReport.roles = [];

    return defaultReport;
  }

  sortOrder: number;

  public keyId: number;
  public shortName: string;
  public reportPath: string;
  public variantId: number;
  public roles: RoleDto[] = [];
  public inputs: ReportingInputDto[] = [];
  public options: ExogenousInputOption[] = [];
  private observer: ExogenousInputOptionObserver = new ExogenousInputOptionObserver();
  public title: LanguageItem;
  public description: LanguageItem;

  public toDto(): ReportDefinitionDto {
    const dto = new ReportDefinitionDto();
    dto.Description = this.description;
    dto.Title = this.title;
    dto.KeyId = this.keyId;
    dto.ShortName = this.shortName;
    dto.VariantId = this.variantId;
    dto.Inputs = this.inputs;
    dto.ReportPath = this.reportPath;

    dto.Roles = this.roles;
    return dto;
  }

  public fromDefintionDto(dto: ReportDefinitionDto) {
    this.keyId = dto.KeyId;
    this.shortName = dto.ShortName;
    this.reportPath = dto.ReportPath;
    this.variantId = dto.VariantId;
    this.title = dto.Title;
    this.description = dto.Description;

    if (dto.Roles) {
      dto.Roles.forEach((role) => {
        this.roles.push(role);
      });
    }

    if (dto.Inputs) {
      dto.Inputs.forEach((input) => {
        this.inputs.push(input);
      });
    }
  }

  public fromDto(dto: ReportDefinitionWithFieldsDto) {
    this.fromDefintionDto(dto);

    const newoptions = ExogenousInputOptionCreator.createTargetOptions(dto.Options, this.observer);
    newoptions.forEach((option) => {
      this.options.push(option);
    });
  }

  public copyFrom(copy: ReportDefinition) {
    this.keyId = copy.keyId;
    this.description = copy.description;
    this.title = copy.title;
    this.shortName = copy.shortName;
    this.reportPath = copy.reportPath;
    this.variantId = copy.variantId;
    this.roles = copy.roles;
    this.inputs = copy.inputs;
    this.options = copy.options;
  }
}
