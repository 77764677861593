import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PlUserStorage } from "../../../../angular-common/baseservice/pluserstorage";
import { ElementHelper } from "../../../../angular-common/helpers/element.helper";
import { StringHelper } from "../../../../angular-common/helpers/string.helper";
import { UserDto } from "../../../../common/models/dto/UserDto-dto";
import { UserInformationValuesDto } from "../../../../common/models/dto/UserInformationValues-dto";
import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { PreferedUserEmployeeMappingDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingDto-dto";
import { PreferedUserEmployeeMappingMetaDataDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingMetaData-dto";
import { UserEmployeeMappingService } from "./user-employee-mapping.service";

@Component({
  selector: "app-me-user-employee-screen",
  templateUrl: "me-user-employee-mapping.component.html",
  styleUrls: ["me-user-employee-mapping.component.scss"],
})
export class MeUserEmployeeMappingComponent {
  constructor(private route: ActivatedRoute, private service: UserEmployeeMappingService, public language: CmsLanguage) {
    this.route.data.subscribe((data: { metaData: PreferedUserEmployeeMappingMetaDataDto }) => {
      this.retrieveCurrentUserInformation();
      this.currentUserDto = data.metaData.Users.find((u) => StringHelper.equalsIgnoreCase(u.ShortName, this.currentUserInformation.Username));
      this.currentUserMapping = data.metaData.Mapping.find((m) => m.UserToEmployeeMapping.User.KeyId === this.currentUserDto.KeyId);

      if (ElementHelper.isNullOrUndefined(this.currentUserMapping) === true) {
        this.currentUserMapping = this.createInitialPreferedUserEmployeeMapping();
        this.currentUserMapping.UserToEmployeeMapping.User = this.currentUserDto;
      }
    });
  }

  currentUserDto: UserDto;
  currentUserInformation: UserInformationValuesDto;
  currentUserMapping: PreferedUserEmployeeMappingDto;
  selectedEmployeeIdentification: string;

  private createInitialPreferedUserEmployeeMapping(): PreferedUserEmployeeMappingDto {
    return UserEmployeeMappingService.newPreferedUserEmployeeMappingDto();
  }

  public get currentUserDataAvailable(): boolean {
    return this.currentUserDto !== undefined;
  }

  public get currentEmployeeIdentification() {
    if (this.currentUserMapping && this.currentUserMapping.UserToEmployeeMapping && this.currentUserMapping.UserToEmployeeMapping.Employee) {
      return this.currentUserMapping.UserToEmployeeMapping.Employee.EmployeeIdentificationString;
    }
    return undefined;
  }
  public set currentEmployeeIdentification(value: string) {
    if (this.currentUserMapping && this.currentUserMapping.UserToEmployeeMapping && this.currentUserMapping.UserToEmployeeMapping.Employee) {
      this.currentUserMapping.UserToEmployeeMapping.Employee.EmployeeIdentificationString = value;
    }
  }

  public get isUserToEmployeeMappingSet(): boolean {
    if (ElementHelper.isNullOrUndefined(this.currentUserMapping) === false) {
      if (this.currentUserMapping.KeyId !== -1) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  public get isEmployeeMappingSelected(): boolean {
    if (ElementHelper.isNullOrUndefined(this.selectedEmployeeIdentification) === false && this.selectedEmployeeIdentification.length !== 0) {
      return true;
    }
    return false;
  }

  retrieveCurrentUserInformation() {
    const hasUser = PlUserStorage.hasUser();
    if (hasUser) {
      this.currentUserInformation = PlUserStorage.user;
    }
  }

  onEmployeeIdentificationSelected(e: any) {
    this.selectedEmployeeIdentification = e.value;
  }

  assign(employeeIdentification: any) {
    this.selectedEmployeeIdentification = employeeIdentification;

    if (this.currentUserMapping && this.currentUserMapping.UserToEmployeeMapping && this.currentUserMapping.UserToEmployeeMapping.Employee) {
      this.currentUserMapping.UserToEmployeeMapping.Employee.EmployeeIdentificationString = encodeURIComponent(employeeIdentification);
    }

    if (this.isEmployeeMappingSelected === false) {
      return;
    }

    if (this.currentUserMapping.KeyId !== -1) {
      this.service.updateMapping(this.currentUserMapping).subscribe((updatedData) => {
        this.currentUserMapping = updatedData;
      });
    } else {
      this.service.addNewMapping(this.currentUserMapping).subscribe((newData) => {
        this.currentUserMapping = newData;
      });
    }
  }

  revokeAssignement() {
    this.service.deleteMapping(this.currentUserMapping).subscribe(() => {
      this.selectedEmployeeIdentification = "";
      this.currentUserMapping = this.createInitialPreferedUserEmployeeMapping();
      this.currentUserMapping.UserToEmployeeMapping.User = this.currentUserDto;
    });
  }
}
