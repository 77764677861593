<div class="linkinputs">
  <!-- Checkbox to use a link or not -->
  <dx-check-box [(value)]="enabled" id="linkText"> </dx-check-box>

  <!-- Header 'link to:' -->
  <label class="inputlineheader" for="linkText">{{ language.f3MeWebApiCMSMessageLinkTo }}</label>

  <span class="linkinputline">
    <div class="inputlineheader linkinputlineheader">
      <dx-radio-group
        [items]="linkTypes"
        [(value)]="selectedType"
        displayExpr="text"
        valueExpr="value"
        layout="vertical"
        [disabled]="!enabled"
        (onValueChanged)="onLinkTypeChanged($event)"
      ></dx-radio-group>
    </div>
    <div class="inputlineheader additionalPropertyInput">
      <!-- internal/popup link settings -->
      <dx-select-box class="selectinput additionalPropertyInput" [dataSource]="internalLinks" [(value)]="selectedLink" displayExpr="title" [disabled]="!enabledInternal"> </dx-select-box>
      <!-- external link settings -->
      <dx-text-box class="textinput additionalPropertyInput" [(value)]="linkLocationExternal" placeholder="http://" [disabled]="!enabledExternal"></dx-text-box>
    </div>
  </span>
  <label class="inputlineheader">{{ language.f3MeWebApiCMSMessageLinkText }}</label>
  <dx-text-box
    class="textinput additionalPropertyInput"
    [(value)]="link.description[currentLanguage]"
    [placeholder]="language.f3MeWebApiCMSMessageLinkTextPlaceholder"
    [disabled]="!enabled"
  ></dx-text-box>
</div>
