<div>
  <dx-html-editor [(value)]="text" [(disabled)]="disabled" (onInitialized)="onInitialized($event)">
    <dxo-toolbar [multiline]="true">
      <dxi-item name="undo"></dxi-item>
      <dxi-item name="redo"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="bold"></dxi-item>
      <dxi-item name="italic"></dxi-item>
      <dxi-item name="strike"></dxi-item>
      <dxi-item name="underline"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="alignLeft"></dxi-item>
      <dxi-item name="alignCenter"></dxi-item>
      <dxi-item name="alignRight"></dxi-item>
      <dxi-item name="alignJustify"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="orderedList"></dxi-item>
      <dxi-item name="bulletList"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="color"></dxi-item>
      <dxi-item name="background"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="link"></dxi-item>
      <dxi-item name="image"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="clear"></dxi-item>
      <dxi-item name="codeBlock"></dxi-item>
      <dxi-item name="blockquote"></dxi-item>
      <dxi-item name="separator"></dxi-item>
      <dxi-item name="insertTable"></dxi-item>
      <dxi-item name="deleteTable"></dxi-item>
      <dxi-item name="insertHeaderRow"></dxi-item>
      <dxi-item name="insertRowAbove"></dxi-item>
      <dxi-item name="insertRowBelow"></dxi-item>
      <dxi-item name="deleteRow"></dxi-item>
      <dxi-item name="insertColumnLeft"></dxi-item>
      <dxi-item name="insertColumnRight"></dxi-item>
      <dxi-item name="deleteColumn"></dxi-item>
    </dxo-toolbar>
  </dx-html-editor>
</div>
