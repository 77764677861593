
import { Injectable } from "@angular/core";
import { PlLanguageService } from "../../angular-common/language/pllanguage.service";
import { WebLanguage } from "./weblanguage.service";

@Injectable()
// t4Languages": "0.8.2.0
export class AuthorizationLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * ApiMenuAuditLogs from Authorization.csv (in EN: Audit logs )
   */
  public key_ApiMenuAuditLogs = "ApiMenuAuditLogs";
  /**
   * ApiMenuAuditLogs from Authorization.csv (in EN: Audit logs )
   */
  public get ApiMenuAuditLogs(): string { return this.getStringDef(this.key_ApiMenuAuditLogs, "Audit logs"); }

  /**
   * menuAuthorization from Authorization.csv (in EN: Authorization )
   */
  public key_menuAuthorization = "menuAuthorization";
  /**
   * menuAuthorization from Authorization.csv (in EN: Authorization )
   */
  public get menuAuthorization(): string { return this.getStringDef(this.key_menuAuthorization, "Authorization"); }

  /**
   * authorizationRolesMaintenanceTitle from Authorization.csv (in EN: Role maintenance )
   */
  public key_authorizationRolesMaintenanceTitle = "authorizationRolesMaintenanceTitle";
  /**
   * authorizationRolesMaintenanceTitle from Authorization.csv (in EN: Role maintenance )
   */
  public get authorizationRolesMaintenanceTitle(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceTitle, "Role maintenance"); }

  /**
   * authorizationRolesMaintenanceDetailsTitle from Authorization.csv (in EN: Rol details )
   */
  public key_authorizationRolesMaintenanceDetailsTitle = "authorizationRolesMaintenanceDetailsTitle";
  /**
   * authorizationRolesMaintenanceDetailsTitle from Authorization.csv (in EN: Rol details )
   */
  public get authorizationRolesMaintenanceDetailsTitle(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceDetailsTitle, "Rol details"); }

  /**
   * authorizationRolesMaintenanceDetailsRolename from Authorization.csv (in EN: Role name )
   */
  public key_authorizationRolesMaintenanceDetailsRolename = "authorizationRolesMaintenanceDetailsRolename";
  /**
   * authorizationRolesMaintenanceDetailsRolename from Authorization.csv (in EN: Role name )
   */
  public get authorizationRolesMaintenanceDetailsRolename(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceDetailsRolename, "Role name"); }

  /**
   * authorizationRolesMaintenanceDetailsRoleDescription from Authorization.csv (in EN: Role description )
   */
  public key_authorizationRolesMaintenanceDetailsRoleDescription = "authorizationRolesMaintenanceDetailsRoleDescription";
  /**
   * authorizationRolesMaintenanceDetailsRoleDescription from Authorization.csv (in EN: Role description )
   */
  public get authorizationRolesMaintenanceDetailsRoleDescription(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceDetailsRoleDescription, "Role description"); }

  /**
   * authorizationRolesMaintenanceValidationRolenameRequired from Authorization.csv (in EN: Role name is required. )
   */
  public key_authorizationRolesMaintenanceValidationRolenameRequired = "authorizationRolesMaintenanceValidationRolenameRequired";
  /**
   * authorizationRolesMaintenanceValidationRolenameRequired from Authorization.csv (in EN: Role name is required. )
   */
  public get authorizationRolesMaintenanceValidationRolenameRequired(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceValidationRolenameRequired, "Role name is required."); }

  /**
   * authorizationRolesMaintenanceValidationRolenameUnique from Authorization.csv (in EN: Role name is already in use. )
   */
  public key_authorizationRolesMaintenanceValidationRolenameUnique = "authorizationRolesMaintenanceValidationRolenameUnique";
  /**
   * authorizationRolesMaintenanceValidationRolenameUnique from Authorization.csv (in EN: Role name is already in use. )
   */
  public get authorizationRolesMaintenanceValidationRolenameUnique(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceValidationRolenameUnique, "Role name is already in use."); }

  /**
   * authorizationRolesMaintenanceAddRole from Authorization.csv (in EN: Add role )
   */
  public key_authorizationRolesMaintenanceAddRole = "authorizationRolesMaintenanceAddRole";
  /**
   * authorizationRolesMaintenanceAddRole from Authorization.csv (in EN: Add role )
   */
  public get authorizationRolesMaintenanceAddRole(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceAddRole, "Add role"); }

  /**
   * authorizationRolesMaintenanceNewRoleName from Authorization.csv (in EN: New role )
   */
  public key_authorizationRolesMaintenanceNewRoleName = "authorizationRolesMaintenanceNewRoleName";
  /**
   * authorizationRolesMaintenanceNewRoleName from Authorization.csv (in EN: New role )
   */
  public get authorizationRolesMaintenanceNewRoleName(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceNewRoleName, "New role"); }

  /**
   * authorizationRolesMaintenanceNotDeletedHeader from Authorization.csv (in EN: Not removed )
   */
  public key_authorizationRolesMaintenanceNotDeletedHeader = "authorizationRolesMaintenanceNotDeletedHeader";
  /**
   * authorizationRolesMaintenanceNotDeletedHeader from Authorization.csv (in EN: Not removed )
   */
  public get authorizationRolesMaintenanceNotDeletedHeader(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceNotDeletedHeader, "Not removed"); }

  /**
   * authorizationRolesMaintenanceDeleteInUseMessage from Authorization.csv (in EN: Role could not be removed because it is in use. )
   */
  public key_authorizationRolesMaintenanceDeleteInUseMessage = "authorizationRolesMaintenanceDeleteInUseMessage";
  /**
   * authorizationRolesMaintenanceDeleteInUseMessage from Authorization.csv (in EN: Role could not be removed because it is in use. )
   */
  public get authorizationRolesMaintenanceDeleteInUseMessage(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceDeleteInUseMessage, "Role could not be removed because it is in use."); }

  /**
   * authorizationRolesMaintenanceDeleteUnknownErrorMessage from Authorization.csv (in EN: Role could not be removed. )
   */
  public key_authorizationRolesMaintenanceDeleteUnknownErrorMessage = "authorizationRolesMaintenanceDeleteUnknownErrorMessage";
  /**
   * authorizationRolesMaintenanceDeleteUnknownErrorMessage from Authorization.csv (in EN: Role could not be removed. )
   */
  public get authorizationRolesMaintenanceDeleteUnknownErrorMessage(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceDeleteUnknownErrorMessage, "Role could not be removed."); }

  /**
   * authorizationRolesMaintenanceDeleteHint from Authorization.csv (in EN: Delete role, roles that are in use can not be deleted. )
   */
  public key_authorizationRolesMaintenanceDeleteHint = "authorizationRolesMaintenanceDeleteHint";
  /**
   * authorizationRolesMaintenanceDeleteHint from Authorization.csv (in EN: Delete role, roles that are in use can not be deleted. )
   */
  public get authorizationRolesMaintenanceDeleteHint(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceDeleteHint, "Delete role, roles that are in use can not be deleted."); }

  /**
   * authorizationRolesMaintenanceValidFrom from Authorization.csv (in EN: Valid from )
   */
  public key_authorizationRolesMaintenanceValidFrom = "authorizationRolesMaintenanceValidFrom";
  /**
   * authorizationRolesMaintenanceValidFrom from Authorization.csv (in EN: Valid from )
   */
  public get authorizationRolesMaintenanceValidFrom(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceValidFrom, "Valid from"); }

  /**
   * authorizationRolesMaintenanceValidUntil from Authorization.csv (in EN: Valid until )
   */
  public key_authorizationRolesMaintenanceValidUntil = "authorizationRolesMaintenanceValidUntil";
  /**
   * authorizationRolesMaintenanceValidUntil from Authorization.csv (in EN: Valid until )
   */
  public get authorizationRolesMaintenanceValidUntil(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceValidUntil, "Valid until"); }

  /**
   * authorizationRolesMaintenanceValidAssignableFrom from Authorization.csv (in EN: Assignable from )
   */
  public key_authorizationRolesMaintenanceValidAssignableFrom = "authorizationRolesMaintenanceValidAssignableFrom";
  /**
   * authorizationRolesMaintenanceValidAssignableFrom from Authorization.csv (in EN: Assignable from )
   */
  public get authorizationRolesMaintenanceValidAssignableFrom(): string { return this.getStringDef(this.key_authorizationRolesMaintenanceValidAssignableFrom, "Assignable from"); }

  /**
   * authorizationRolesTitle from Authorization.csv (in EN: Change roles )
   */
  public key_authorizationRolesTitle = "authorizationRolesTitle";
  /**
   * authorizationRolesTitle from Authorization.csv (in EN: Change roles )
   */
  public get authorizationRolesTitle(): string { return this.getStringDef(this.key_authorizationRolesTitle, "Change roles"); }

  /**
   * authorizationRolesActionsDisableAlertHeader from Authorization.csv (in EN: User inactive )
   */
  public key_authorizationRolesActionsDisableAlertHeader = "authorizationRolesActionsDisableAlertHeader";
  /**
   * authorizationRolesActionsDisableAlertHeader from Authorization.csv (in EN: User inactive )
   */
  public get authorizationRolesActionsDisableAlertHeader(): string { return this.getStringDef(this.key_authorizationRolesActionsDisableAlertHeader, "User inactive"); }

  /**
   * authorizationRolesActionsDisableAlertMessage from Authorization.csv (in EN: The user could not be deleted, because the user made changes in the system. The user has been deactivated. )
   */
  public key_authorizationRolesActionsDisableAlertMessage = "authorizationRolesActionsDisableAlertMessage";
  /**
   * authorizationRolesActionsDisableAlertMessage from Authorization.csv (in EN: The user could not be deleted, because the user made changes in the system. The user has been deactivated. )
   */
  public get authorizationRolesActionsDisableAlertMessage(): string { return this.getStringDef(this.key_authorizationRolesActionsDisableAlertMessage, "The user could not be deleted, because the user made changes in the system. The user has been deactivated."); }

  /**
   * authorizationRolesDetailsTitle from Authorization.csv (in EN: User details )
   */
  public key_authorizationRolesDetailsTitle = "authorizationRolesDetailsTitle";
  /**
   * authorizationRolesDetailsTitle from Authorization.csv (in EN: User details )
   */
  public get authorizationRolesDetailsTitle(): string { return this.getStringDef(this.key_authorizationRolesDetailsTitle, "User details"); }

  /**
   * authorizationRolesDetailsOfUser from Authorization.csv (in EN: Details of user: {0} )
   */
  public key_authorizationRolesDetailsOfUser = "authorizationRolesDetailsOfUser";
  /**
   * authorizationRolesDetailsOfUser from Authorization.csv (in EN: Details of user: {0} )
   */
  public authorizationRolesDetailsOfUser(val1): string { return this.getStringDef("authorizationRolesDetailsOfUser", "Details of user: {0}").replace("{0}", val1); }

  /**
   * authorizationRolesDetailsUsernameNewUserName from Authorization.csv (in EN: New user )
   */
  public key_authorizationRolesDetailsUsernameNewUserName = "authorizationRolesDetailsUsernameNewUserName";
  /**
   * authorizationRolesDetailsUsernameNewUserName from Authorization.csv (in EN: New user )
   */
  public get authorizationRolesDetailsUsernameNewUserName(): string { return this.getStringDef(this.key_authorizationRolesDetailsUsernameNewUserName, "New user"); }

  /**
   * authorizationRolesDetailsUsername from Authorization.csv (in EN: Username )
   */
  public key_authorizationRolesDetailsUsername = "authorizationRolesDetailsUsername";
  /**
   * authorizationRolesDetailsUsername from Authorization.csv (in EN: Username )
   */
  public get authorizationRolesDetailsUsername(): string { return this.getStringDef(this.key_authorizationRolesDetailsUsername, "Username"); }

  /**
   * authorizationRolesDetailsLongname from Authorization.csv (in EN: Longname )
   */
  public key_authorizationRolesDetailsLongname = "authorizationRolesDetailsLongname";
  /**
   * authorizationRolesDetailsLongname from Authorization.csv (in EN: Longname )
   */
  public get authorizationRolesDetailsLongname(): string { return this.getStringDef(this.key_authorizationRolesDetailsLongname, "Longname"); }

  /**
   * authorizationRolesDetailsOSAuthenticated from Authorization.csv (in EN: Use external authentication )
   */
  public key_authorizationRolesDetailsOSAuthenticated = "authorizationRolesDetailsOSAuthenticated";
  /**
   * authorizationRolesDetailsOSAuthenticated from Authorization.csv (in EN: Use external authentication )
   */
  public get authorizationRolesDetailsOSAuthenticated(): string { return this.getStringDef(this.key_authorizationRolesDetailsOSAuthenticated, "Use external authentication"); }

  /**
   * authorizationRolesDetailsStatuses from Authorization.csv (in EN: Account status )
   */
  public key_authorizationRolesDetailsStatuses = "authorizationRolesDetailsStatuses";
  /**
   * authorizationRolesDetailsStatuses from Authorization.csv (in EN: Account status )
   */
  public get authorizationRolesDetailsStatuses(): string { return this.getStringDef(this.key_authorizationRolesDetailsStatuses, "Account status"); }

  /**
   * authorizationRolesDetailsPassword from Authorization.csv (in EN: Password )
   */
  public key_authorizationRolesDetailsPassword = "authorizationRolesDetailsPassword";
  /**
   * authorizationRolesDetailsPassword from Authorization.csv (in EN: Password )
   */
  public get authorizationRolesDetailsPassword(): string { return this.getStringDef(this.key_authorizationRolesDetailsPassword, "Password"); }

  /**
   * authorizationRolesDetailsConfirmPassword from Authorization.csv (in EN: Confirm password )
   */
  public key_authorizationRolesDetailsConfirmPassword = "authorizationRolesDetailsConfirmPassword";
  /**
   * authorizationRolesDetailsConfirmPassword from Authorization.csv (in EN: Confirm password )
   */
  public get authorizationRolesDetailsConfirmPassword(): string { return this.getStringDef(this.key_authorizationRolesDetailsConfirmPassword, "Confirm password"); }

  /**
   * authorizationRolesOfUser from Authorization.csv (in EN: Roles of user: {0} )
   */
  public key_authorizationRolesOfUser = "authorizationRolesOfUser";
  /**
   * authorizationRolesOfUser from Authorization.csv (in EN: Roles of user: {0} )
   */
  public authorizationRolesOfUser(val1): string { return this.getStringDef("authorizationRolesOfUser", "Roles of user: {0}").replace("{0}", val1); }

  /**
   * authorizationRolesValidationUsernameRequired from Authorization.csv (in EN: Username is required. )
   */
  public key_authorizationRolesValidationUsernameRequired = "authorizationRolesValidationUsernameRequired";
  /**
   * authorizationRolesValidationUsernameRequired from Authorization.csv (in EN: Username is required. )
   */
  public get authorizationRolesValidationUsernameRequired(): string { return this.getStringDef(this.key_authorizationRolesValidationUsernameRequired, "Username is required."); }

  /**
   * authorizationRolesValidationUsernameUnique from Authorization.csv (in EN: Username is already in use. )
   */
  public key_authorizationRolesValidationUsernameUnique = "authorizationRolesValidationUsernameUnique";
  /**
   * authorizationRolesValidationUsernameUnique from Authorization.csv (in EN: Username is already in use. )
   */
  public get authorizationRolesValidationUsernameUnique(): string { return this.getStringDef(this.key_authorizationRolesValidationUsernameUnique, "Username is already in use."); }

  /**
   * authorizationRolesValidationPasswordRequired from Authorization.csv (in EN: Password is required. )
   */
  public key_authorizationRolesValidationPasswordRequired = "authorizationRolesValidationPasswordRequired";
  /**
   * authorizationRolesValidationPasswordRequired from Authorization.csv (in EN: Password is required. )
   */
  public get authorizationRolesValidationPasswordRequired(): string { return this.getStringDef(this.key_authorizationRolesValidationPasswordRequired, "Password is required."); }

  /**
   * authorizationRolesValidationConfPasswordRequired from Authorization.csv (in EN: Confirm password is required. )
   */
  public key_authorizationRolesValidationConfPasswordRequired = "authorizationRolesValidationConfPasswordRequired";
  /**
   * authorizationRolesValidationConfPasswordRequired from Authorization.csv (in EN: Confirm password is required. )
   */
  public get authorizationRolesValidationConfPasswordRequired(): string { return this.getStringDef(this.key_authorizationRolesValidationConfPasswordRequired, "Confirm password is required."); }

  /**
   * authorizationRolesValidationPasswordMismatch from Authorization.csv (in EN: Password and Confirm password do not match. )
   */
  public key_authorizationRolesValidationPasswordMismatch = "authorizationRolesValidationPasswordMismatch";
  /**
   * authorizationRolesValidationPasswordMismatch from Authorization.csv (in EN: Password and Confirm password do not match. )
   */
  public get authorizationRolesValidationPasswordMismatch(): string { return this.getStringDef(this.key_authorizationRolesValidationPasswordMismatch, "Password and Confirm password do not match."); }

  /**
   * authorizationRolesTreeViewTitle from Authorization.csv (in EN: Assigned roles of {0} )
   */
  public key_authorizationRolesTreeViewTitle = "authorizationRolesTreeViewTitle";
  /**
   * authorizationRolesTreeViewTitle from Authorization.csv (in EN: Assigned roles of {0} )
   */
  public authorizationRolesTreeViewTitle(val1): string { return this.getStringDef("authorizationRolesTreeViewTitle", "Assigned roles of {0}").replace("{0}", val1); }

}
