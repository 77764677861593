import { DxDataGridComponent } from "devextreme-angular";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { ColumnItemDto } from "../../../common/models/dto/ColumnItem-dto";
import { DataSetWellKnownVariablesDto } from "../../../common/models/dto/DataSetWellKnownVariables-dto";
import { LookUpDto } from "../../../common/models/dto/LookUp-dto";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EmployeeGridDateFilter } from "./employee-grid-date-filter";

export class EmployeeGridConfiguration {
  private isChangedColumn;
  private actionColumn;

  public static ActionDataField: string = "actionsField";

  public constructor(private languageService: CmsLanguage, private filter: EmployeeGridDateFilter) {
    this.filter.onChanged.subscribe(() => this.applyFilter());
    this.filter.onReset.subscribe(() => this.resetFilter());
  }

  public columns: IDxColumnOfGrid[];
  public dxGrid: DxDataGridComponent;
  private editableColumns: string[] = [];
  public userIdFieldName: string;

  public get hasUserIdentificationConfigured() {
    return ElementHelper.isNullOrUndefined(this.userIdFieldName) === false;
  }

  // Creates the columns for the devexpress grid. Returns a class very similar that we already have however our API might not always
  // closely resemble what DevExpress expects.
  public initialize(columns: ColumnItemDto[]) {
    const newColumns: IDxColumnOfGrid[] = [];
    columns.forEach((columnSource) => {
      const newColumn = {
        dataField: columnSource.dataField,
        visible: columnSource.visible,
        dataType: columnSource.dataType,
        caption: columnSource.caption,
        allowEditing: columnSource.allowEditing,
        allowFiltering: columnSource.allowFiltering,
        lookup: this.createLookup(columnSource.lookup),
        cellTemplate: "cellTemplate",
      };
      newColumns.push(newColumn);

      if (columnSource.allowEditing) {
        this.editableColumns.push(columnSource.dataField);
      }
      if (columnSource.isUserId) {
        this.userIdFieldName = columnSource.dataField;
      }
    });

    if (columns.find((x) => x.isUserId) === undefined) {
      console.log("No field marked as user id, disabling user id based navigation");
    }

    this.isChangedColumn = {
      dataField: "isChanged",
      dataType: "boolean",
      caption: this.languageService.f3MeWebApiApproveTargetsChanged,
      falseText: this.languageService.f3MeWebApiApproveTargetsChangedNo,
      trueText: this.languageService.f3MeWebApiApproveTargetsChangedYes,
      visible: true,
      allowEditing: false,
    };

    this.actionColumn = {
      dataField: EmployeeGridConfiguration.ActionDataField,
      dataType: "buttons",
      caption: this.languageService.inspireGridActionsColumnName,
      visible: true,
      allowEditing: false,
      cellTemplate: "cellTemplate",
    };

    newColumns.push(this.isChangedColumn);
    newColumns.push(this.actionColumn);

    this.columns = newColumns;
  }
  private createLookup(lookupSource: LookUpDto) {
    if (lookupSource === undefined || lookupSource === null) {
      return lookupSource;
    }

    const result = {
      dataSource: lookupSource.dataSource,
      displayExpr: lookupSource.displayExpr,
      valueExpr: lookupSource.valueExpr,
    };
    return result;
  }

  private applyFilter() {
    const newFilter = [];
    const item = [];
    item.push([DataSetWellKnownVariablesDto.StartDateColumnName, ">=", this.filter.dateFrom]);
    item.push("and");
    item.push([DataSetWellKnownVariablesDto.StartDateColumnName, "<=", this.filter.dateTo]);

    newFilter.push(item);

    this.dxGrid.instance.clearFilter();

    this.dxGrid.filterValue = newFilter;
  }

  private resetFilter(): void {
    this.dxGrid.instance.clearFilter();
  }

  public isEditableColumn(columnName: string): boolean {
    if (this.editableColumns.findIndex((x) => x.toLowerCase() === columnName.toLowerCase()) >= 0) {
      return true;
    }
    return false;
  }

  public isActionColumn(columnName: string) {
    return EmployeeGridConfiguration.ActionDataField === columnName;
  }

  public hideModifiedColumn() {
    this.isChangedColumn.visible = false;
  }
}

interface IDxColumnOfGrid {
  dataField: string;
  visible: boolean;
  dataType: string;
  caption: string;
  allowEditing: boolean;
  allowFiltering: boolean;
  lookup: LookUpDto;
  cellTemplate: string;
}
