import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDropDownBoxModule, DxFormModule, DxListModule, DxNumberBoxModule, DxPopupModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";
import { DxiItemModule, DxiValidationRuleModule, DxoLabelModule } from "devextreme-angular/ui/nested";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateAuthGuard } from "../../../../angular-common/authentication-guard/authentication-guard";
import { LanguageItemEditorGridModule } from "../../../../employee-common/cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { CmsRoutesDto } from "../../../../employee-common/models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { PluginServiceModule } from "../service/plugin-service.module";
import { PluginManagementComponent } from "./plugin-management.component";
import { pluginManagementResolver } from "./plugin-management.resolver";

const routes: Routes = [
  {
    path: CmsRoutesDto.PluginEdit,
    component: PluginManagementComponent,
    data: [{ menuType: MenuItemTypesDto.CMS }],
    canActivate: [canActivateAuthGuard],
    resolve: {
      plugins: pluginManagementResolver,
    },
  },
];

@NgModule({
  declarations: [PluginManagementComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DxFormModule,
    DxiItemModule,
    DxButtonModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxPopupModule,
    DxListModule,
    DxValidatorModule,
    DxiValidationRuleModule,
    DxoLabelModule,
    LanguageItemEditorGridModule,
    DxNumberBoxModule,
    PluginServiceModule,
  ],
  providers: [],
  exports: [PluginManagementComponent],
})
export class PluginManagementModule {}
