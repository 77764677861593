import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RouteNavigation } from "../../../angular-common/baseservice/route-navigation";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { ImageService } from "../images/image.service";
import { UserSettingsService } from "../usersettings/usersettings.service";

@Component({
  selector: "app-inspirelogin",
  templateUrl: "./login-with-disclaimer.component.html",
  styleUrls: ["../../../angular-common/components/login/_login.scss"],
})
export class LoginWithDisclaimerComponent {
  public constructor(public imageService: ImageService, private routeNavigation: RouteNavigation, private userSettingsService: UserSettingsService) {}

  public get afterLoginDisclaimerCheck(): () => Observable<boolean> {
    return () =>
      this.userSettingsService.shouldRequireValidateActual().pipe(
        map((shouldConfirm) => {
          if (shouldConfirm) {
            console.log("Login succesfull, but we require confirmation.");
            this.routeNavigation.navigate(CommonRoutesDto.UserAcknowledgement);
            return false;
          } else {
            return true;
          }
        }),
      );
  }
}
