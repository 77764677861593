import { PresentableElementDto } from './PresentableElement-dto';
import { RoleDto } from './RoleDto-dto';
import { UserStatusDto } from './UserStatus-dto';

// t4tsCodeFromCs": "0.8.2.0 for file UserDto.cs
// Don't adjust manually!

export class UserDto extends PresentableElementDto {
    public OSAuthenticated: boolean = true;
    public Status: UserStatusDto;
    public AssignedRoles: RoleDto [] = [];
}

