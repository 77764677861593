import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EditToggleModule } from "../../components/edittoggle/edittoggle.module";
import { LanguageModule } from "../../components/language/language.module";
import { LanguageItemEditorModule } from "../../components/language/languageitem-editor/languageitem-editor.module";
import { LanguageEditItemComponent } from "./languageedit-item.component";
import { LanguageEditComponent } from "./languageedit.component";

@NgModule({
  declarations: [LanguageEditItemComponent, LanguageEditComponent],
  imports: [CommonModule, EditToggleModule, LanguageItemEditorModule, LanguageModule],
  providers: [],
  exports: [LanguageEditItemComponent, LanguageEditComponent],
})
export class LanguageEditModule {}
