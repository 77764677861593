import { ISortOrder } from "../../../../angular-common/sorting/sortorder";
import { SourceDto } from "../../../models/choices/dto/Source-dto";
import { ModifiedExogenousInputOptionsDto } from "../../../models/me/dto/ModifiedExogenousInputOptions-dto";
import { ExogenousInputComputedField } from "./exogenous-input-computedfield";
import { ExogenousInputOptionCreator } from "./options/exogenous-input-option-creator";
import { ExogenousInputOptionObserver } from "./options/exogenous-input-option-observer";
import { TExogenousInputOptionSynchronizer } from "./options/exogenous-input-option-synchronizer";
import { ExogenousInputOption } from "./options/exogenous-input-option.model";

export class ExogenousInputSource implements ISortOrder {
  public constructor(private observer: ExogenousInputOptionObserver) {}

  public sortOrder: number;
  public name: string;
  public identification: number;

  private _currentValues: ExogenousInputComputedField[];
  public get currentValues(): ExogenousInputComputedField[] {
    return this._currentValues;
  }

  private _options: ExogenousInputOption[];
  public get options(): ExogenousInputOption[] {
    return this._options;
  }

  public copyFromDTO(dto: SourceDto) {
    this.sortOrder = dto.SortOrder;
    this.name = dto.Name;
    this.identification = dto.Identification;
    this._options = ExogenousInputOptionCreator.createTargetOptions(dto.Options, this.observer);

    this.updateCurrentValues(dto);
  }

  public toDTO(dto: ModifiedExogenousInputOptionsDto, includeAttachmentData: boolean) {
    ExogenousInputOptionCreator.toDTO(this.options, dto, includeAttachmentData);
  }

  public synchronizeFromDTO(dto: SourceDto) {
    TExogenousInputOptionSynchronizer.synchronize(dto.Options, this.options, this.observer);

    this.updateCurrentValues(dto);
  }

  private updateCurrentValues(dto: SourceDto) {
    const cf = new Array<ExogenousInputComputedField>();

    dto.CurrentValues.forEach((currentValue) => {
      const f = new ExogenousInputComputedField();
      f.copyFromDTO(currentValue);
      cf.push(f);
    });

    this._currentValues = cf;
  }
}
