import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import dxTreeList from "devextreme/ui/tree_list";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../angular-common/language/pl-language-selection-copier";
import { PaginatedDxData } from "../../../angular-common/paginated-dx-data";
import { ModelElement } from "../../../common/model/models/model-element.model";
import { LanguageMetaDataDto } from "../../../common/models/dto/LanguageMetaData-dto";
import { LinkService } from "../../components/internallinks/link.service";
import { Link } from "../../components/links/link";
import { EbsNodeDto } from "../../models/ebs/dto/EbsNodeDto-dto";
import { EbsEditService } from "../ebs-edit/ebsedit.service";
import { EbsTreeNode } from "../ebstree-edit/ebsnode";
import { EbsTreeEditService } from "../ebstree-edit/ebstree-edit.service";
import { EbsLanguage } from "../language/ebslanguage.service";

@Component({
  selector: "app-ebs-tree-editor-component",
  templateUrl: "./cms-ebs-tree-editor.component.html",
  styleUrls: ["./cms-ebs-tree-editor.component.scss"],
  providers: [EbsTreeEditService, EbsEditService],
})
export class EbsTreeEditComponent implements OnInit {
  constructor(
    private ebsTreeEditService: EbsTreeEditService,
    private selectLanguage: PlLanguageSelection,
    public language: EbsLanguage,
    private activeRouter: ActivatedRoute,
    private ebsEditService: EbsEditService,
    private linkService: LinkService,
    private languageSelection: PlLanguageSelection,
  ) {
    this.getModelShortNameDisplayValue = this.getModelShortNameDisplayValue.bind(this);
    this.getVisualModelShortNameDisplayValue = this.getVisualModelShortNameDisplayValue.bind(this);
    this.linkDisplayValue = this.linkDisplayValue.bind(this);
    this.title = this.title.bind(this);
  }

  public languages: LanguageMetaDataDto[] = [];
  private treeNode: EbsNodeDto;
  public treeRow: EbsNodeDto[] = [];
  public modelElements: ModelElement[] = [];
  public paginatedModelElements: PaginatedDxData = null;
  private currentParentId: number;
  public showLinkEditPopup = false;
  public link: Link;
  public showEditModelPopup = false;
  public showExtraModelPopup = false;

  ngOnInit(): void {
    this.activeRouter.params.subscribe((params) => {
      const cmsGroupingId = params["id"];
      this.createEbsTree(cmsGroupingId);
      this.createModelElements();
    });
    this.languages = this.selectLanguage.languagesMetaData;
  }

  public get currentLanguage(): string {
    return this.languageSelection.currentLanguage;
  }

  public title(ebs: EbsNodeDto) {
    return ebs.Details.MessageTitle[this.languageSelection.currentLanguage];
  }

  public linkDisplayValue(current: EbsNodeDto): boolean {
    return Link.isNew(current.Details.LinkId);
  }

  private createEbsTree(cmsGroupingId: number) {
    this.ebsTreeEditService.retrieveRoot(cmsGroupingId).subscribe((node) => {
      this.treeNode = EbsTreeNode.toDto(node, this.language);
      this.generateTreeRows();
    });
  }

  public isNewLink(rowData: { data: EbsNodeDto }) {
    const linkId = rowData.data.Details.LinkId;
    return Link.isNew(linkId);
  }

  public editLink(rowData: { data: EbsNodeDto }) {
    const linkId = rowData.data.Details.LinkId;
    if (Link.isNew(linkId)) {
      this.link = Link.createDefault(this.selectLanguage);
      this.showLinkEditPopup = true;
    } else {
      this.linkService.getLink(linkId).subscribe((l) => {
        this.link = l;
        this.showLinkEditPopup = true;
      });
    }
  }

  public saveLink(e: any) {
    if (this.link === null || this.link === undefined) {
      return;
    }
    this.linkService.storeLink(this.link).subscribe((link) => {
      e.setValue(link.KeyId);
    });
    this.showLinkEditPopup = false;
  }

  public isNew(node: EbsNodeDto): boolean {
    return node.Details.CmsGroupId === -1 || node.Details.CmsGroupId === undefined;
  }

  private generateTreeRows() {
    this.treeRow.push(this.treeNode);
    this.treeNode.Children.map((child) => {
      this.checkAndPushChild(child);
    });
  }

  private checkAndPushChild(child: EbsNodeDto) {
    if (child.Children.length > 0) {
      this.treeRow.push(child);
      child.Children.forEach((nextchild) => {
        this.checkAndPushChild(nextchild);
      });
    } else {
      this.treeRow.push(child);
    }
  }

  createModelElements() {
    const newElements: ModelElement[] = [];
    this.ebsEditService.getEbsConfig().subscribe((configs) => {
      configs.modelElements.forEach((element) => {
        newElements.push(element);
      });
      this.modelElements = newElements;
      this.paginatedModelElements = new PaginatedDxData(newElements);
    });
  }

  public getModelShortNameDisplayValue(rowData: any) {
    const modelShortname = this.findModelElement(rowData.Details.ModelElementShortName);
    if (modelShortname) {
      return modelShortname.displayName;
    }
    return "";
  }

  public getVisualModelShortNameDisplayValue(rowData: any) {
    const modelShortname = this.findModelElement(rowData.Details.MisibilityModelElementShortName);
    if (modelShortname) {
      return modelShortname.displayName;
    }
    return "";
  }

  private findModelElement(shortName: string) {
    if (this.modelElements === null || this.modelElements === undefined) {
      return null;
    }
    return this.modelElements.find((x) => x.shortName === shortName);
  }

  public get contentReady(): boolean {
    if (this.treeRow.length > 0 && this.modelElements.length > 0) {
      return true;
    }
    return false;
  }

  onInitNewRow(row) {
    this.currentParentId = row.data.Details.ParentId;
    const newNode = EbsTreeNode.toDto(EbsTreeNode.createDefault(this.language, this.languageSelection), this.language);
    PlLanguageSelectionCopier.copyFromOriginal(newNode.Details);
    newNode.Details.ParentId = this.currentParentId;

    row.data = newNode;

    this._isContentNodeLevel = this.isNodeAtContentLevel(newNode);
  }

  onRowInserted(row: any) {
    const value = row.data;

    this.updateModelSettingsIfAvailable(value);
    this.updateModelExtraSettingsIfAvailable(value);

    PlLanguageSelectionCopier.copyToOriginal(value.Details);

    this.ebsTreeEditService.saveRoot(value).subscribe((m) => {
      const rowIndex = this.treeRow.findIndex((x) => this.isNew(x) === true);
      var newRowData = EbsTreeNode.toDto(m, this.language);
      this.treeRow[rowIndex] = newRowData;
    });
  }

  private updateModelSettingsIfAvailable(rowData: any) {
    if (rowData.ModelSettings) {
      const modelSettings = rowData.ModelSettings as IModelSettingsWrapper;
      rowData.Details.VisibilityModelElementShortName = modelSettings.VisibilityModelElementShortName;
      rowData.Details.ModelElementAppliesToTotal = modelSettings.ModelElementAppliesToTotal;
      rowData.Details.ModelElementHideValue = modelSettings.ModelElementHideValue;
    }
  }

  private updateModelExtraSettingsIfAvailable(rowData: any) {
    if (rowData.ModelExtraSettings) {
      const modelExtraSettings = rowData.ModelExtraSettings as IModelExtraSettingsWrapper;
      rowData.Details.AdditionalModelElementShortName = modelExtraSettings.AdditionalModelElementShortName;
      rowData.Details.AdditionalModelElementHideValue = modelExtraSettings.AdditionalModelElementHideValue;
    }
  }

  onRowUpdated(data: EbsNodeDto, dxTreeList: dxTreeList) {
    PlLanguageSelectionCopier.copyToOriginal(data.Details);
    dxTreeList.repaint();
    let value: EbsNodeDto;
    this.treeRow.forEach((tr) => {
      if (tr.Details.CmsGroupId === data.Details.CmsGroupId) {
        value = tr;
      }
    });
    this.ebsTreeEditService.saveRoot(value).subscribe((r) => {
      data.Details.CmsGroupId = r.details.cmsGroupId;
    });
  }

  private _isContentNodeLevel: boolean = false;

  public get isContentNodeLevel(): boolean {
    return this._isContentNodeLevel;
  }

  private isNodeAtContentLevel(ebsNode: EbsNodeDto): boolean {
    return ebsNode.Details.ParentId !== 0 && ebsNode.Details.ParentId !== this.treeNode.Details.CmsGroupId;
  }

  onRowRemoved(row: { data: EbsNodeDto }) {
    const value = row.data;
    this.ebsTreeEditService.deleteNode(value).subscribe(() => {});
  }

  onEditingStart(data: EbsNodeDto) {
    this._isContentNodeLevel = this.isNodeAtContentLevel(data);
    PlLanguageSelectionCopier.copyFromOriginal(data.Details);
  }

  onEditorPreparing(cell: any) {
    if (cell.dataField === "Details.Color") {
      cell.editorName = "dxColorBox";
    }
  }

  public editModelElement() {
    this.showEditModelPopup = true;
  }

  public editExtraModel() {
    this.showExtraModelPopup = true;
  }

  public closePopupModelSettings(e: any) {
    e.setValue(e.value);
    this.showEditModelPopup = false;
  }

  public closePopupModelExtraSettings(e: any) {
    e.setValue(e.value);
    this.showExtraModelPopup = false;
  }

  public setModelExtraSettingsCellValue(newData, value) {
    const extraSettingsWrapper: IModelExtraSettingsWrapper = {
      AdditionalModelElementShortName: value.AdditionalModelElementShortName,
      AdditionalModelElementHideValue: value.AdditionalModelElementHideValue,
    };

    newData.ModelExtraSettings = extraSettingsWrapper;
  }

  public setModelSettingsCellValue(newData, value) {
    const modelSettingsWrapper: IModelSettingsWrapper = {
      VisibilityModelElementShortName: value.VisibilityModelElementShortName,
      ModelElementAppliesToTotal: value.ModelElementAppliesToTotal,
      ModelElementHideValue: value.ModelElementHideValue,
    };

    newData.ModelSettings = modelSettingsWrapper;
  }
}

interface IModelSettingsWrapper {
  VisibilityModelElementShortName: string;
  ModelElementAppliesToTotal: boolean;
  ModelElementHideValue: boolean;
}

interface IModelExtraSettingsWrapper {
  AdditionalModelElementShortName: string;
  AdditionalModelElementHideValue: boolean;
}
