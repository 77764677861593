import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { EmailTemplateDto } from "../../models/cms/dto/EmailTemplate-dto";
import { UserEmailTemplateDto } from "../../models/cms/dto/UserEmailTemplate-dto";
import { EmailTemplate } from "../consume-mail/email-template";

@Injectable()
export class EmailTemplateEditService extends PlBaseService {
  private editEndPoint: string = "EmailTemplateEdit";
  private arrangeEndPoint: string = "ArrangeEmailTemplate";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, private selectLanguage: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAll(): Observable<EmailTemplate[]> {
    const thisService = this;
    return this.getDataTransformed<EmailTemplateDto[]>(this.editEndPoint).pipe(map((r) => thisService.extract(r)));
  }

  private extract(dtos: EmailTemplateDto[]): EmailTemplate[] {
    if (dtos) {
      const result: EmailTemplate[] = [];
      dtos.forEach((dto) => {
        const et = new EmailTemplate(this.selectLanguage);
        et.fromDto(dto);
        result.push(et);
      });
      return result;
    } else {
      return null;
    }
  }

  public getSingle(id: number): Observable<EmailTemplate> {
    const url = this.editEndPoint + "/" + id;
    return this.getDataTransformed<EmailTemplateDto>(url).pipe(map((r) => this.extractSingle(r)));
  }

  public saveExistingTemplate(dto: EmailTemplateDto): Observable<EmailTemplate> {
    const location = this.editEndPoint + "/" + dto.Id;
    return this.updateTransformedResponse<UserEmailTemplateDto>(dto, location).pipe(
      map((r) => {
        const template = this.extractSingle(r);
        return template;
      }),
    );
  }

  public newTemplate(): Observable<EmailTemplate> {
    return this.postDataTransformed<EmailTemplateDto, EmailTemplate>(this.arrangeEndPoint, this.defaultTemplate().toDTO(), (r) => this.extractSingle(r.body));
  }

  private defaultTemplate(): EmailTemplate {
    return new EmailTemplate(this.selectLanguage);
  }

  private extractSingle(dto: EmailTemplateDto): EmailTemplate {
    if (dto) {
      const result = new EmailTemplate(this.selectLanguage);
      result.fromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public deleteTemplate(template: EmailTemplateDto): Observable<any> {
    const url = this.arrangeEndPoint + "/" + template.Id;
    return this.remove<EmailTemplate>(url);
  }
}
