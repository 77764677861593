import { EbsResult } from "./ebs-result.model";
import { EbsResultDto } from "../../models/ebs/dto/EbsResult-dto";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class EbsResults {
  public constructor(private selectLanguage: PlLanguageSelection) {}

  public ebsResultList: EbsResult[];

  public copyFromDTO(dtos: EbsResultDto[]) {
    const list = new Array<EbsResult>();

    dtos.forEach((dto) => {
      const ebsResult = new EbsResult(this.selectLanguage);
      ebsResult.copyFromDTO(dto);
      list.push(ebsResult);
    });

    this.ebsResultList = list;
  }
}
