import { ModelElementDto } from './ModelElementDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file ExogenousVariableDto.cs
// Don't adjust manually!

export class ExogenousVariableDto extends ModelElementDto {
    public DataSetId: number;
    public VisibleTriggerFormulaText: string;
    public WarningTriggerFormulaText: string;
    public ErrorTriggerFormulaText: string;
    public ReadOnlyTriggerFormulaText: string;
    public CategoryId: number;
}

