import { ExogenousVariable } from "./exogenous-variable.model";
import { ExogenousVariableDto } from "../../models/dto/ExogenousVariableDto-dto";
import { ModelElements } from "../model-elements.model";

export class ExogenousVariables extends ModelElements<ExogenousVariable> {
  public copyFromDto(exogenousVariablesDto: ExogenousVariableDto[]) {
    if (!exogenousVariablesDto || exogenousVariablesDto === null) {
      return;
    }

    exogenousVariablesDto.forEach((exogDto) => {
      const newExog = new ExogenousVariable();
      newExog.copyFromDto(exogDto);
      this.add(newExog);
    });
  }

  public getFilteredItems(exogenousCategoryId: number): ExogenousVariables {
    return new ExogenousVariables(
      this.all.filter((exog) => {
        return exog.categoryId === exogenousCategoryId;
      }),
    );
  }
}
