import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/internal/Observable";
import { PreferredModelDto } from "../../../common/models/dto/PreferredModelDto-dto";
import { PreferredModelMetaDataDto } from "../../../common/models/dto/PreferredModelMetaDataDto-dto";
import { EnvironmentSettings } from "../../baseservice/environment-settings";
import { PlBaseService } from "../../baseservice/plbase.service";
import { PlLoadIndicator } from "../../loadindicator/loadindicator";

@Injectable({ providedIn: "root" })
export class PreferredModelService extends PlBaseService {
  readonly endpoint = "preferredModel";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getMetaData(): Observable<PreferredModelMetaDataDto> {
    return this.getData<PreferredModelMetaDataDto>(this.endpoint);
  }

  public createPreferredModel(preferredModel: PreferredModelDto): Observable<PreferredModelDto> {
    return this.postData(this.endpoint, preferredModel);
  }

  public deletePreferredModel(preferredModel: PreferredModelDto) {
    const url = this.endpoint + "/" + preferredModel.KeyId;
    return this.remove(url);
  }

  public updatePreferredModel(preferredModel: PreferredModelDto): Observable<PreferredModelDto> {
    return this.update(preferredModel, this.endpoint);
  }
}
