export class ElementText {
  public static calculateElementSizeFrom(textValues: string[], element: any, safeMargin: number, maxAllowedWidth: number) {
    
    const textValueLengths: number[] = [];

    const rect = element.getBoundingClientRect();
    const selectBoxWidth = Math.round(rect.width);
    const font = this.getElementFont(element);

    textValues.forEach((v) => {
      const val = this.calculateTextWidth(font, v);
      textValueLengths.push(val);
    });

    const textMaxWidth = Math.max(...textValueLengths);

    if (textMaxWidth > maxAllowedWidth) return maxAllowedWidth;

    if (selectBoxWidth < textMaxWidth) {
      return textMaxWidth + safeMargin;
    }

    return selectBoxWidth;
  }

  public static getElementFont(element: any) {
    const computedStyle = window.getComputedStyle(element);
    return computedStyle.getPropertyValue("font");
  }

  public static calculateTextWidth(font: any, text: string): number {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  }
}
