import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";
import { UserFieldsEditMetaDataDto } from "../../models/cms/dto/UserFieldsEditMetaData-dto";
import { UserField } from "./models/userfield.model";
import { UserFieldsEditMetaData } from "./models/userfields-edit-metadata.model";

@Injectable()
export class UserFieldsEditService extends PlBaseService {
  private endPoint = "userfieldsedit";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieve(): Observable<UserFieldsEditMetaData> {
    return this.getDataTransformed<UserFieldsEditMetaDataDto>(this.endPoint).pipe(map((r) => this.extract(r)));
  }

  public updateAssignment(userField: UserField): Observable<void> {
    const url = this.endPoint;
    return this.update(userField.toDto(), url);
  }

  public removeAssignment(userField: UserField): Observable<void> {
    const url = this.endPoint + "/" + userField.key;
    return this.remove(url);
  }

  private extract(dto: UserFieldsEditMetaDataDto): UserFieldsEditMetaData {
    const result = new UserFieldsEditMetaData();
    result.copyFromDto(dto);
    return result;
  }
}
