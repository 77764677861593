import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApplicationAboutInformationDto } from "../../../../common/models/dto/ApplicationAboutInformationDto-dto";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { PlBaseService } from "../../../baseservice/plbase.service";
import { PlLoadIndicator } from "../../../loadindicator/loadindicator";
import { AboutInformation } from "./about-info";

@Injectable()
export class ApplicationInformationService extends PlBaseService {
  private endpoint = "applicationinformation";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAppInformation(): Observable<AboutInformation> {
    return this.getDataTransformed<ApplicationAboutInformationDto>(this.endpoint).pipe(map(dto => this.extractData(dto)));
  }

  extractData(dto: ApplicationAboutInformationDto): AboutInformation {
    if (dto) {
      const result = new AboutInformation();
      result.fromDto(dto);
      return result;
    }
    return null;
  }
}
