<div>
  <dx-data-grid [dataSource]="allLogs" [columnAutoWidth]="true">
    <dxo-editing [allowUpdating]="false" [allowAdding]="false"></dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
    <dxo-paging [pageSize]="defaultPageSize"> </dxo-paging>
    <dxo-pager [visible]="true" [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="true" [showInfo]="true" [showNavigationButtons]="true"></dxo-pager>

    <dxi-column caption="{{ language.auditLogsTimestamp }}" dataField="TimeStamp" [sortIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column caption="{{ language.auditLogsSubjectType }}" dataField="SubjectType" [sortIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column caption="{{ language.auditLogsSubjectName }}" dataField="SubjectName" [sortIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column caption="{{ language.auditLogsAction }}" dataField="Action" [sortIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column caption="{{ language.auditLogsTarget }}" dataField="Target" [sortIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column caption="{{ language.auditLogsTriggeredBy }}" dataField="TriggeredBy" [sortIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column caption="{{ language.auditLogsActionValue }}" dataField="ActionValue" [sortIndex]="0" sortOrder="desc"></dxi-column>
  </dx-data-grid>
</div>
