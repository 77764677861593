import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { ImageService } from "../components/images/image.service";
import { LinkService } from "../components/internallinks/link.service";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";
import { CMSMessageList } from "./cms-messagelist.model";
import { CMSService } from "./cms.service";
import { CmsLanguage } from "./language/cmslanguage.service";

@Component({
  selector: "app-cms-component",
  templateUrl: "./cms.component.html",
  styleUrls: ["./cms.component.scss"],
  providers: [],
})
export class CMSComponent implements OnInit {
  // eslint-disable-next-line max-len
  public constructor(
    private cmsService: CMSService,
    private imageService: ImageService,
    private languageService: CmsLanguage,
    private linkService: LinkService,
    private languageSelection: PlLanguageSelection,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
  ) {
    if (this.route.snapshot.data[0]) {
      const allowEdit = this.route.snapshot.data[0]["allowEdit"];
      const messageType = this.route.snapshot.data[0]["messageType"];
      if (allowEdit) {
        this.allowEdit = allowEdit;
      }
      if (messageType) {
        this.messageType = messageType;
      }
    }
  }

  @Input()
  public useFullWidth: boolean = false;

  public messageTypes = MessageTypeDto;

  public ngOnInit(): void {
    this._messageList = new CMSMessageList(this.allowEdit);
    this._messageList.load(this.cmsService, this.imageService, this.languageService, this.linkService, this.languageSelection, this.domSanitizer, this.messageType);
  }

  private _messageList: CMSMessageList;
  public get messageList() {
    return this._messageList;
  }

  private _allowEdit: boolean = false;
  @Input()
  set allowEdit(newAllowEdit: boolean) {
    this._allowEdit = newAllowEdit;
  }
  get allowEdit() {
    return this._allowEdit;
  }

  private _messageType: MessageTypeDto;
  @Input()
  public set messageType(newMessageType: MessageTypeDto) {
    this._messageType = newMessageType;
  }
  public get messageType() {
    return this._messageType;
  }

  private _hideWhenEmpty: boolean = false;
  @Input()
  public get hideWhenEmpty(): boolean {
    return this._hideWhenEmpty;
  }
  public set hideWhenEmpty(newValue: boolean) {
    this._hideWhenEmpty = newValue;
  }

  public get visible(): boolean {
    if (this.messageList === undefined || this.messageList === null) {
      this.isComponentVisible.emit(false);
      return false;
    } else if (this.messageList.messages.length === 0 && this.hideWhenEmpty === true) {
      this.isComponentVisible.emit(false);
      return false;
    }

    this.isComponentVisible.emit(true);
    return true;
  }

  @Output() isComponentVisible = new EventEmitter<boolean>();
}
