import { EbsEditService } from "./ebsedit.service";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [EbsEditService],
  exports: [],
})
export class EbsEditModule {}
