import { EventEmitter } from "@angular/core";

import { ISortOrder } from "../../../../../angular-common/sorting/sortorder";
import { ExogenousInputOptionDto } from "../../../../models/me/dto/ExogenousInputOption-dto";
import { MessageDto } from "../../../../models/me/dto/Message-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ExogenousInputOptionMessage } from "./exogenous-input-option-message.model";
import { ExogenousInputOptionType } from "./option-type.model";

export abstract class ExogenousInputOption implements ISortOrder {
  public constructor(private _type: ExogenousInputOptionType) {
    this.error = new ExogenousInputOptionMessage(-1, "");
    this.warning = new ExogenousInputOptionMessage(-1, "");
  }

  public identification: number;
  public description: string;
  public sortOrder: number;
  public name: string;
  public shortName: string;
  public isEnabled: boolean;
  public isVisible: boolean;
  public error: ExogenousInputOptionMessage;
  public warning: ExogenousInputOptionMessage;
  public helpUrl: string;

  public get type(): ExogenousInputOptionType {
    return this._type;
  }

  public errTooltipVisible: boolean = false;

  public warnTooltipVisible: boolean = false;

  public helpTooltipVisible: boolean = false;

  public copyFromDTO(dto: ExogenousInputOptionDto) {
    this.identification = dto.Identification;
    this.description = dto.Description;
    this.sortOrder = dto.SortOrder;
    this.name = dto.Name;
    this.shortName = dto.ShortName;
    this.isEnabled = dto.IsEnabled;
    this.isVisible = dto.IsVisible;

    if (dto.Error && dto.Error !== null) {
      this.error = new ExogenousInputOptionMessage(dto.Error.Id, dto.Error.Text);
    } else {
      this.error = new ExogenousInputOptionMessage(-1, "");
    }

    if (dto.Warning && dto.Warning !== null) {
      this.warning = new ExogenousInputOptionMessage(dto.Warning.Id, dto.Warning.Text);
    } else {
      this.warning = new ExogenousInputOptionMessage(-1, "");
    }

    this.helpUrl = dto.HelpUrl;
  }

  public toDTO(dto: ModifiedExogenousInputOptionDto) {
    dto.Description = this.description;
    dto.Identification = this.identification;
    dto.IsEnabled = this.isEnabled;
    dto.Name = this.name;
    dto.ShortName = this.shortName;
    dto.SortOrder = this.sortOrder;

    dto.Error = new MessageDto();
    dto.Error.Id = this.error.id;
    dto.Error.Text = this.error.text;

    dto.Warning = new MessageDto();
    dto.Warning.Id = this.warning.id;
    dto.Warning.Text = this.warning.text;
    dto.HelpUrl = this.helpUrl;
  }

  // Angular doesn't work well with enums so instead we have these boolean properties here. Might not be
  // the nicest solution but it's the easiest.
  public get isTextOption(): boolean {
    return this.type === ExogenousInputOptionType.Text;
  }
  public get isDateOption(): boolean {
    return this.type === ExogenousInputOptionType.Date;
  }
  public get isChoiceOption(): boolean {
    return this.type === ExogenousInputOptionType.Choice;
  }
  public get isAttachmentOption(): boolean {
    return this.type === ExogenousInputOptionType.Attachment;
  }
  public get isNumericOption(): boolean {
    return this.type === ExogenousInputOptionType.Numeric;
  }
  public get isBooleanOption(): boolean {
    return this.type === ExogenousInputOptionType.Bool;
  }

  public get hasHelpUrl(): boolean {
    if (this.helpUrl === undefined || this.helpUrl === null) {
      return false;
    } else {
      return this.helpUrl.trim().length > 0;
    }
  }

  public onChanged = new EventEmitter();
  public changed() {
    this.onChanged.emit();
  }
}
