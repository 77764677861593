import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthorizationLanguage } from "../../../../../../common/language/Authorizationlanguage.service";
import { RoleAssignableFromDto } from "../../../../../../common/models/dto/RoleAssignableFromDto-dto";
import { PlUserStorage } from "../../../../../baseservice/pluserstorage";
import { ElementHelper } from "../../../../../helpers/element.helper";
import { RoleHierarchyList } from "../../../services/models/authorization-roleHierarchy-list-model";
import { RoleHierarchy } from "../../../services/models/authorization-roleHierarchy-model";

@Component({
  selector: "app-authorization-content-role-details",
  templateUrl: "./authorization-content.component-role-details.html",
  styleUrls: ["./authorization-content.component-role-details.scss"],
})
export class RoleDetailsComponent {
  public constructor(public language: AuthorizationLanguage) {}

  public readonly dateSerializationFormat: string = "yyyy-MM-dd";

  @Input("roleHierarchy")
  public get roleHierarchy(): RoleHierarchy {
    return this._roleHierarchy;
  }
  public set roleHierarchy(newRoleHierarchy: RoleHierarchy) {
    this._roleHierarchy = newRoleHierarchy;
    this.reset();
  }
  private _roleHierarchy: RoleHierarchy;

  @Input("rolesList")
  public get rolesList(): RoleHierarchyList {
    return this._rolesList;
  }
  public set rolesList(newRoles: RoleHierarchyList) {
    this._rolesList = newRoles;
  }
  private _rolesList: RoleHierarchyList;

  public get hasRole(): boolean {
    return this.roleHierarchy && this.roleHierarchy !== null;
  }

  public roleName: string;
  public roleDescription: string;

  public assignableFrom: RoleAssignableFromDto;

  public reset() {
    if (this.roleHierarchy && this.roleHierarchy !== null) {
      this.roleName = this.roleHierarchy.role.name;
      this.roleDescription = this.roleHierarchy.role.description;
      this.assignableFrom = this.roleHierarchy.role.assignableFrom;
    } else {
      this.roleName = "";
      this.roleDescription = "";
      this.assignableFrom = null;
    }
  }

  public get assignableFromRole(): number {
    if (ElementHelper.isNullOrUndefined(this.assignableFrom)) {
      return -1;
    }

    return this.assignableFrom.KeyId;
  }

  public set assignableFromRole(value: number) {
    if (ElementHelper.isNullOrUndefined(value) || value === -1) {
      this.assignableFrom = null;
      return;
    }

    if (ElementHelper.isNullOrUndefined(this.assignableFrom)) {
      this.assignableFrom = new RoleAssignableFromDto();
    }
    const roleHierarchy = this.rolesList.all.find((r) => r.role.id === value);

    if (ElementHelper.isNullOrUndefined(roleHierarchy) === false) {
      this.assignableFrom = roleHierarchy.role.copyToDto();
    }
  }

  public get canSave(): boolean {
    return this.hasRole && this.roleNameIsValid(this.roleName) && this.roleNameIsUnique(this.roleName);
  }

  public get canChangeAssignableFrom(): boolean {
    if (ElementHelper.isNullOrUndefined(this.assignableFrom) === false) {
      const userInformation = PlUserStorage.user;

      if (userInformation.Roles.findIndex((r) => r === this.assignableFrom.ShortName) === -1) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  }

  public saveRole() {
    if (!this.roleNameIsValid(this.roleName) || !this.roleNameIsUnique(this.roleName)) {
      return;
    }

    this.roleHierarchy.role.assignableFrom = this.assignableFrom;
    this.roleHierarchy.role.name = this.roleName;
    this.roleHierarchy.role.description = this.roleDescription;
    this.saved.emit(this.roleHierarchy);
  }

  @Output()
  public saved = new EventEmitter<RoleHierarchy>();

  private roleNameIsValid(newName: string): boolean {
    return newName && newName !== null && newName !== "";
  }

  private roleNameIsUnique(newName: string): boolean {
    if (!this.rolesList || this.rolesList === null) {
      return true;
    }

    if (!this.roleHierarchy || this.roleHierarchy === null) {
      return true;
    }

    if (this.roleHierarchy.role.name.toLowerCase() === newName.toLowerCase()) {
      return true;
    }

    const existingRole = this.rolesList.getByRoleName(newName);
    if (existingRole && existingRole !== null) {
      return false;
    }

    return true;
  }

  validateRoleNameIsUnique = (e) => this.roleNameIsUnique(e.value);

  validateRoleName = (e) => {
    if (!this.roleHierarchy || this.roleHierarchy === null) {
      return true;
    }
    return this.roleNameIsValid(e.value);
  };
}
