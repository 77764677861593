import { AuthGuard } from "./authentication-guard";
import { NgModule } from "@angular/core";
import { RoleGuard } from "./role-guard";
import { UserInformationGuard } from "./userinformation.guard";

@NgModule({
  declarations: [],
  providers: [AuthGuard, RoleGuard, UserInformationGuard],
  schemas: [],
})
export class AuthenticationGuardModule {}
