export class ElementHelper {
  public static isNullOrUndefined(obj: any): boolean {
    if (obj === null || obj === undefined) {
      return true;
    }
    return false;
  }

  public static invalidChars = /^[^<>:"/\\|?*.]+$/;
  public static noInvalidCharacters(obj: string): boolean {
    const result = obj.match(this.invalidChars);
    if (result.length > 0) {
      return false;
    } else {
      true;
    }
  }
}
