import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { CMSMessage } from "../../cms/cms-message.model";
import { CMSService } from "../../cms/cms.service";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { ILabourCondtionComponent } from "../../labourconditions/labourcondtition-detail/i-labourcondition-components";
import { ILabourConditionData } from "../../labourconditions/labourcondtition-detail/i-labourcondtion-data";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { ChoicesService } from "../targets/choices.service";
import { IChoiceSelectionViewData } from "../targets/i-choice-selection-view-data";
import { ChoiceDefinition } from "../targets/targetdefinition/target-definition.model";
import { TargetProperties } from "../targets/targetproperties/target-properties.model";

@Component({
  selector: "app-labour-detail-withchoices-component",
  templateUrl: "./labour-detail-with-choices.component.html",
  styleUrls: ["../../labourconditions/labourcondtition-detail/labour-detail.component.scss"],
})
export class LabourConditionDetailWithChoicesComponent implements OnInit, OnDestroy, ILabourCondtionComponent {
  private targetSubmitSubscription: Subscription = null;
  private _message: CMSMessage = null;
  public targets: ChoiceDefinition[];
  private _targetProperties: TargetProperties = null;

  public constructor(private cmsService: CMSService, private targetsService: ChoicesService, public language: CmsLanguage) {
    this.data = <any>{};
  }

  public get choiceData(): IChoiceSelectionViewData {
    return {
      messageId: this.data.messageId,
    };
  }

  @Input() data: ILabourConditionData;

  public ngOnInit(): void {
    this.cmsService.getMessage(MessageTypeDto.labourcondition, this.data.messageId).subscribe((message) => {
      this._message = message;
    });

    this.targetsService.getTargetsByMessage(this.data.messageId).subscribe((t) => {
      this.targets = t;
    });

    if (this.data.targetId > -1 && this.data.messageId > -1) {
      this.reloadTargetProperties();
    }
  }

  public ngOnDestroy(): void {
    this.disposeTargetSubscription();
  }

  public get message(): CMSMessage {
    return this._message;
  }

  private disposeTargetSubscription() {
    if (this.targetSubmitSubscription && this.targetSubmitSubscription !== null) {
      this.targetSubmitSubscription.unsubscribe();
      this.targetSubmitSubscription = null;
    }
  }

  private reloadTargetProperties() {
    this.disposeTargetSubscription();

    this.targetsService.getTargetDetails(this.data.targetId, this.data.messageId).subscribe((props) => {
      this._targetProperties = props;
      this.targetSubmitSubscription = this._targetProperties.exogenousInput.onSubmitted.subscribe((x) => this.reloadTargetProperties());
    });
  }

  public get targetProperties(): TargetProperties {
    return this._targetProperties;
  }

  public get showMessageContent(): boolean {
    return this.data.messageId > -1 && this.data.targetId <= -1;
  }

  public get showTargetDetails(): boolean {
    return this.data.messageId > -1 && this.data.targetId > -1;
  }
}
