
// t4tsCode gen": "0.8.2.0 for file LinkType.cs
// Don't adjust manually!
export enum LinkTypeDto {
        None = 0,
        InternalLink = 1,
        ExternalLink = 2,
        PopupLink = 3,
}

