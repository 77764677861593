import { EnvironmentSettings, PlBaseService, PlLoadIndicator, Sorter } from "../../../angular-common";

import { CmsLanguage } from "../language/cmslanguage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LabourConditionGroup } from "./lc-group";
import { LabourGroupDto } from "../../../employee-common/models/cms/dto/LabourGroupDto-dto";
import { Observable } from "rxjs";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { map } from "rxjs/operators";

@Injectable()
export class LabourgroupService extends PlBaseService {
  private endPoint: string = "labourgroup";

  // eslint-disable-next-line max-len
  constructor(http: HttpClient, envSettings: EnvironmentSettings, private languageService: CmsLanguage, private selectLanguage: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  private convertGroups(groups: LabourGroupDto[]): LabourConditionGroup[] {
    if (groups) {
      const list = new Array<LabourConditionGroup>();

      for (const groupDTO of groups) {
        const group = new LabourConditionGroup();
        group.copyFromDto(groupDTO, this.selectLanguage);
        list.push(group);
      }

      return list.sort(Sorter.compareSortOrder);
    } else {
      return [];
    }
  }

  public getGroups(): Observable<LabourConditionGroup[]> {
    return this.getData<LabourGroupDto[]>(this.endPoint).pipe(map(this.convertGroups, this));
  }

  public getGroupsForEdit(): Observable<LabourConditionGroup[]> {
    return this.getData<LabourGroupDto[]>(this.endPoint + "/edit").pipe(map(this.convertGroups, this));
  }

  // Gets an empty group that can serve as the 'no group' item in a combobox
  public getDefaultGroup<T>(): LabourConditionGroup {
    return LabourConditionGroup.newDefaultGroup(this.languageService, this.selectLanguage);
  }

  public saveGroup(labourgroup: LabourGroupDto): Observable<any> {
    const url = this.endPoint + "/" + labourgroup.Id;
    return this.update<LabourGroupDto>(labourgroup, url);
  }

  public deleteGroup(labourgroup: LabourGroupDto): Observable<any> {
    const url = this.endPoint + "/" + labourgroup.Id;
    return this.remove(url);
  }

  public insertGroup(labourgroup: LabourGroupDto): Observable<any> {
    const url = this.endPoint;
    return this.createData<LabourGroupDto>(url, labourgroup);
  }
}
