import { Component, Input } from "@angular/core";

import { CMSMessageList } from "../cms-messagelist.model";

@Component({
  selector: "app-homepagebanner-component",
  templateUrl: "./homepagebanner.component.html",
  styleUrls: ["./homepagebanner.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class HomePageBannerComponent {
  @Input() messageList: CMSMessageList;
}
