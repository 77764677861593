import { IModelWithIdDto } from './../../../../common/models/dto/IModelWithId-dto';
import { LinkTypeDto } from './LinkType-dto';

export declare type LinkDescriptionDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file Link.cs
// Don't adjust manually!

export class LinkDto implements IModelWithIdDto {
    public static NoLink: LinkDto;
    public KeyId: number;
    public LinkType: LinkTypeDto;
    public Location: string;
    public Description: LinkDescriptionDto;
    public Visible: boolean;
}

