
// t4tsCode gen": "0.8.2.0 for file CmsRoutes.cs
// Don't adjust manually!

export class CmsRoutesDto {
    public static Home: string = "home";
    public static NoDataForUser: string = "noDataForUser";
    public static EmailTemplateConfig: string = "emailtemplateconfig";
    public static HomePageBanner: string = "cms-homepagebanner";
    public static LabourConditionsGroupEdit: string = "labourcondition-group-edit";
    public static LabourConditionDetail: string = "lcdetail";
    public static Language: string = "cms-language";
    public static FixedImages: string = "cms-fixedimages";
    public static WhatIfEdit: string = "cms-simulationblock";
    public static News: string = "cms-news";
    public static Carousel: string = "cms-carousel";
    public static EmailTemplates: string = "cms-emailtemplates";
    public static EbsIntro: string = "cms-ebs";
    public static LabourConditions: string = "labourconditions";
    public static LinkLabourConditions: string = "link-labourconditions";
    public static LabourConditionEdit: string = "cms-labourcondition";
    public static Plugin: string = "plugin";
    public static PluginEdit: string = "plugin-edit";
    public static Search: string = "cms-search";
    public static Reports: string = "reports";
    public static LanguageEditManagement: string = "languageeditmanagement";
    public static UserFieldsEdit: string = "userfieldsedit";
}

