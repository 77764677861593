
// t4tsCodeFromCs": "0.8.2.0 for file ElementType.cs
// Don't adjust manually!
export enum ElementTypeDto {
        Unknown = 0,
        Parameter = 7,
        Stack = 8,
        Formula = 9,
        Exogenous = 10,
        Variant = 11,
        Aggregation = 12,
}

export class ElementTypesDto {
}

