import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SafeDataDirectiveModule } from "../../../angular-common/utils/safe-data-directive-module";
import { CmsPageHeaderComponent } from "./cmspage-header.component";

@NgModule({
  imports: [CommonModule, FormsModule, SafeDataDirectiveModule],
  declarations: [CmsPageHeaderComponent],
  exports: [CmsPageHeaderComponent],
  providers: [],
})
export class CmsPageHeaderModule {}
