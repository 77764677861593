
// t4tsCode gen": "0.8.2.0 for file MenuItemTypes.cs
// Don't adjust manually!
export enum MenuItemTypesDto {
        Unknown = 0,
        MainMenu = 1,
        PersonalMenu = 2,
        CMS = 4,
        Login = 8,
        Management = 16,
        Targets = 32,
        Import = 64,
        Technical = 128,
        Dashboarding = 256,
        Authorization = 512,
        AuditLogs = 1024,
        PreferredModel = 2048,
        TopMenuItems = MainMenu | CMS | Management | Targets | Import | Technical | Dashboarding | Authorization | AuditLogs | PreferredModel,
}

