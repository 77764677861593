import { UserFieldDto } from "../../../models/cms/dto/UserFieldDto-dto";
export class UserField {
  title: string;
  key: string;
  exogenousVariableShortName: string;

  public copyFromDto(dto: UserFieldDto) {
    this.title = dto.Title;
    this.key = dto.Key;
    this.exogenousVariableShortName = dto.ExogenousVariableShortName;
  }

  public toDto(): UserFieldDto {
    const result = new UserFieldDto();
    result.ExogenousVariableShortName = this.exogenousVariableShortName;
    result.Key = this.key;
    result.Title = this.title;
    return result;
  }
}
