import { Component, Input } from "@angular/core";
import { ExogeousInputOptionText } from "../targets/exogenous-input/options/exogenous-input-option-text.model";

@Component({
  selector: "app-exogeous-input-text-option",
  templateUrl: "./exogeous-input-text-option.component.html",
  styleUrls: ["./exogeous-input-text-option.component.scss"],
})
export class ExogeousInputTextOptionComponent {
  constructor() {}

  @Input()
  public valueOnly = false;

  @Input() option: ExogeousInputOptionText;
}
