import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResult } from "../../../angular-common/baseservice/_models/apiresult.model";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlUserStorage } from "../../../angular-common/baseservice/pluserstorage";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { ApiResultDto } from "../../../common/models/dto/ApiResult-dto";
import { TargetDto } from "../../models/choices/dto/Target-dto";
import { TargetPropertiesDto } from "../../models/choices/dto/TargetProperties-dto";
import { TargetDefinitionDto } from "../../models/cms/dto/TargetDefinition-dto";
import { ModifiedExogenousInputOptionsDto } from "../../models/me/dto/ModifiedExogenousInputOptions-dto";
import { CMSToTargetDictionaryDTO } from "./targetdefinition/cms-target-definitions-dto.model";
import { ICMSToTargetDictionary } from "./targetdefinition/cms-target-definitions.model";
import { ChoiceDefinition } from "./targetdefinition/target-definition.model";
import { TargetProperties } from "./targetproperties/target-properties.model";

@Injectable()
export class ChoicesService extends PlBaseService {
  private endPoint: string = "target";
  private buffer: ICMSToTargetDictionary = null;

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public languageSelection: PlLanguageSelection, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
    PlUserStorage.userChanged.subscribe((u) => {
      this.clearCache();
    });
  }

  private clearCache() {
    this.buffer = null;
  }

  public getTargets(): Observable<ICMSToTargetDictionary> {
    if (this.buffer !== null) {
      return of(this.buffer);
    }
    let result = this.getDataTransformed<CMSToTargetDictionaryDTO>(this.endPoint).pipe(map((r) => this.translateDictionairy(r)));
    result = result.pipe(
      map((r) => {
        this.buffer = r;
        return r;
      }),
    );
    return result;
  }

  public getTargetsByMessage(cmsMessageId: number): Observable<ChoiceDefinition[]> {
    if (this.buffer !== null) {
      return this.getTargetsFromBuffer(cmsMessageId);
    } else {
      return this.getTargets().pipe(mergeMap((result) => this.getTargetsFromBuffer(cmsMessageId)));
    }
  }

  private getTargetsFromBuffer(cmsMessageId: number): Observable<ChoiceDefinition[]> {
    if (cmsMessageId in this.buffer) {
      return of(this.buffer[cmsMessageId]);
    } else {
      return of([]);
    }
  }

  private translateDictionairy(dto: CMSToTargetDictionaryDTO): ICMSToTargetDictionary {
    const result: ICMSToTargetDictionary = {};

    if (dto) {
      for (const cmsMessageId in dto) {
        if (cmsMessageId) {
          const targetDefinitionsDTO: TargetDefinitionDto[] = dto[cmsMessageId] as TargetDefinitionDto[];

          const targetDefinitions = new Array<ChoiceDefinition>();
          result[cmsMessageId] = targetDefinitions;

          targetDefinitionsDTO.forEach((targetDefinitionDTO) => {
            const def = new ChoiceDefinition(this.languageSelection);
            def.copyFromDTO(targetDefinitionDTO);
            targetDefinitions.push(def);
          });
        }
      }

      return result;
    } else {
      return result;
    }
  }

  public getTargetDetails(targetId: number, cmsMessageId: number): Observable<TargetProperties> {
    const thisService = this;
    return this.getDataTransformed<TargetPropertiesDto>(this.endPoint + "/" + targetId + "/" + cmsMessageId).pipe(map((r) => this.transformProperties(r)));
  }

  private transformProperties(dto: TargetPropertiesDto): TargetProperties {
    if (dto) {
      const result = new TargetProperties(this.languageSelection, this);
      result.copyFromDTO(dto);

      return result;
    } else {
      return null;
    }
  }

  public updateTarget(targetId: number, options: ModifiedExogenousInputOptionsDto): Observable<TargetDto> {
    const location = this.endPoint + "/" + targetId;
    return this.updateTransformedResponse<TargetDto>(options, location);
  }

  public submitTarget(targetId: number, options: ModifiedExogenousInputOptionsDto): Observable<ApiResult> {
    const location = this.endPoint + "/" + targetId;
    const thisService = this;
    return this.postDataTransformed<ApiResultDto, ApiResult>(location, options, (r) => {
      var res = thisService.extractApiResult(r);
      if (res.isSuccess) {
        this.clearCache();
      }
      return res;
    });
  }
}
