import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LabourConditionsOverviewModule } from "./labour-overview/labourconditions-overview.module";
import { LabourConditionLinkModule } from "./labourcondition-link/labourcondition-link.module";
import { LabourConditionMainModule } from "./labourcondition-main/labourconditions-main.module";
import { LabourConditionManagementModule } from "./management/labourconditions-management.module";

@NgModule({
  imports: [CommonModule, FormsModule, LabourConditionManagementModule, LabourConditionMainModule, LabourConditionsOverviewModule, LabourConditionLinkModule],
  exports: [],
  declarations: [],
  providers: [],
})
export class LabourConditionsContentModule {}
