
// t4tsCodeFromCs": "0.8.2.0 for file WellknownAppNames.cs
// Don't adjust manually!

export class WellknownAppNamesDto {
    public static ForAll: string = "ForAll";
    public static Inspire: string = "Inspire";
    public static Imagine: string = "Imagine";
    public static Multitenant: string = "Multitenant";
    public static CMP: string = "CMP";
    public static Compute: string = "Compute";
}

