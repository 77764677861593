import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SafeDataDirectiveModule } from "../../../angular-common/utils/safe-data-directive-module";
import { CMSModule } from "../../cms/cms.module";
import { LabourGroupModule } from "../../cms/labourgroups/labourgroup.module";
import { CmsLinkModule } from "../../cms/links/cms-link.module";
import { AppComponentSpecificViewModule } from "../../components/appcomponentspecific-content/appcomponentspecific-view-module";
import { LabourBlockComponent } from "./labour-overview-block";
import { LabourTopicComponent } from "./labour-overview-topic";

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, LabourGroupModule, CMSModule, CmsLinkModule, AppComponentSpecificViewModule, SafeDataDirectiveModule],
  exports: [LabourTopicComponent, LabourBlockComponent],
  declarations: [LabourTopicComponent, LabourBlockComponent],
  providers: [],
})
export class LabourConditionsOverviewModule {}
