import { IModelWithIdDto } from './../../../../common/models/dto/IModelWithId-dto';

// t4tsCode gen": "0.8.2.0 for file ReportDataDto.cs
// Don't adjust manually!

export class ReportDataDto implements IModelWithIdDto {
    public Content: string = "";
    public FileName: string;
    public DateAdded: Date;
    public DateModified: Date;
    public LastEditedBy: string = "";
    public KeyId: number;
    public ShortName: string;
}

