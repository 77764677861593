import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DxButtonModule, DxFormModule, DxTextBoxModule, DxValidationSummaryModule, DxValidatorModule } from "devextreme-angular";
import { CardViewModule } from "../cardview/cardview.module";
import { LanguageSelectionModule } from "../languageselection/language-selection.module";
import { LoginExternalAuthComponent } from "./external-auth/login-external-auth.component";
import { LoginFormComponent } from "./form/login-form.component";
import { ErrorInsufficientRightsComponent } from "./insufficient-rights/error-insufficient-rights.component";
import { PlLoginComponent } from "./login.component";
import { PlLoginSingInComponent } from "./pllogin-signin.component";
import { LoginSignoutComponent } from "./signoutpage/login-signout.component";
import { UserClaimsComponent } from "./user-claims.component";

@NgModule({
  imports: [CommonModule, FormsModule, DxButtonModule, DxTextBoxModule, CardViewModule, RouterModule, LanguageSelectionModule, DxFormModule, DxValidatorModule, DxValidationSummaryModule],
  declarations: [PlLoginComponent, LoginFormComponent, LoginExternalAuthComponent, UserClaimsComponent, LoginSignoutComponent, PlLoginSingInComponent, ErrorInsufficientRightsComponent],
  exports: [PlLoginComponent, PlLoginSingInComponent, LoginFormComponent, LoginSignoutComponent, LoginExternalAuthComponent, UserClaimsComponent, ErrorInsufficientRightsComponent],
})
export class PlLoginModule {}
