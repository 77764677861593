import { forkJoin, Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { EnvironmentSettings, PlAuthenticationService, TokenData } from "../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RouteNavigation } from "../../angular-common/baseservice/route-navigation";
import { CmsLanguage } from "../cms/language/cmslanguage.service";
import { PersonlizedLanguageService } from "../components/language/personlized-language.service";
import { UserSettingsService } from "../components/usersettings/usersettings.service";

@Injectable()
export class EmployeeAuthenticationService extends PlAuthenticationService {
  public constructor(
    routeNavigation: RouteNavigation,
    http: HttpClient,
    envSettings: EnvironmentSettings,

    private languageService: CmsLanguage,
    private personlizedLanguageService: PersonlizedLanguageService,
    private userSettingService: UserSettingsService,
  ) {
    super(routeNavigation, http, envSettings, languageService);
  }

  public retrieveCurrentUserValue(temptoken: TokenData): Observable<boolean> {
    let obs = super.retrieveCurrentUserValue(temptoken);

    obs = obs.pipe(
      mergeMap((loginSucces) => {
        if (loginSucces) {
          return forkJoin([this.personlizedLanguageService.getEntries(), this.userSettingService.getSettings()]).pipe(
            map((result) => {
              this.languageService.addPersonalizedText(result[0].Entries);
              return true;
            }),
          );
        } else {
          return of(false);
        }
      }),
    );

    return obs;
  }

  public resetUserStorage(): void {
    this.languageService.removePersonalizedText();
    this.userSettingService.reset();

    super.resetUserStorage();
  }
}
