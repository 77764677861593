import { MessageRatingDto } from "../../dto/MessageRating-dto";

export class MessageRating {
  public constructor() {}

  public messageId: number;
  public averageScore: number = 0;
  public userScore: number = 0;
  public numberOfScores: number = 0;
  public averageScoreAsString: string = "";
  public userScoreAsString: string = "";
  public numberOfScoresAsString: string = "";
  public hasUserScore: boolean = false;
  public hasAverageScore: boolean = false;

  public copyFromDto(dto: MessageRatingDto) {
    this.averageScore = dto.AverageScore;
    this.messageId = dto.MessageId;
    this.numberOfScores = dto.NumberOfScores;
    this.userScore = dto.UserScore;
    this.averageScoreAsString = dto.AverageScoreAsString;
    this.numberOfScoresAsString = dto.NumberOfScoresAsString;
    this.userScoreAsString = dto.UserScoreAsString;
    this.hasAverageScore = dto.HasAverageScore;
    this.hasUserScore = dto.HasUserScore;
  }
}
