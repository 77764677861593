import { Component, Input } from "@angular/core";

import { CmsFeaturesModel } from "../../cms/cms-features-model";
import { CMSMessage } from "../../cms/cms-message.model";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { IRatingViewData } from "./i-rating-view-data";

@Component({
  selector: "app-rating-view",
  templateUrl: "./rating-view.component.html",
  styleUrls: ["./rating-view.component.scss"],
})
export class RatingViewComponent {
  public dynamicComponentType: AppComponentSpecificContentType;
  public enabled: boolean;

  constructor(cmsFeatureToggles: CmsFeaturesModel) {
    this.dynamicComponentType = AppComponentSpecificContentType.MessageRating;
    this.enabled = cmsFeatureToggles.Rating.enabled || false;
  }

  @Input()
  public message: CMSMessage;

  public get data(): IRatingViewData {
    if (this.message) {
      return { messageId: this.message.id, messageType: this.message.messageType };
    }
    return undefined;
  }

  public get hasMessage() {
    return this.message;
  }
}
