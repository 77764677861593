import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { AuditLogUserEntryDto } from "../../../common/models/dto/AuditLogUserEntryDto-dto";

@Component({
  selector: "app-audit-logs",
  templateUrl: "./audit-logs.component.html",
  styleUrls: ["./audit-logs.component.scss"],
})
export class AuditLogsComponent {
  constructor(private route: ActivatedRoute, public language: WebLanguage) {
    this.route.data.subscribe((data: { auditLogsData: AuditLogUserEntryDto[] }) => {
      this.allLogs = data.auditLogsData;
    });
  }

  readonly defaultPageSize = 20;
  readonly allowedPageSizes = [20, 40, 60, 80, "all"];

  public allLogs: AuditLogUserEntryDto[];
}
