import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

import { Subscription } from "rxjs";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { PlLanguageService } from "../../language/pllanguage.service";
import { HTMLEditorLinkPrefixHelper } from "./helpers/htmleditor-link-prefix-helper";

@Component({
  selector: "app-htmleditor",
  styleUrls: ["./htmleditor.component.scss"],
  templateUrl: "./htmleditor.component.html",
})
export class HtmlEditorComponent implements OnDestroy {
  private languageSubscription: Subscription = null;

  public constructor(public language: WebLanguage, private plLanguageService: PlLanguageService) {
    this.languageSubscription = this.plLanguageService.languageSelector.languageChanged.subscribe((lang) => {
      this.setEditorConfig();
    });

    // First time initialization
    this.setEditorConfig();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private setEditorConfig() {
    this.editorConfig = {
      language: this.plLanguageService.languageSelector.currentLanguage.toLowerCase(),
      title: false,
    };

    if (this.showToolbar === false) {
      // Note that if we hide the toolbar by removing all the toolbar groups then HTML is not rendered anymore. Therefore we collapse
      // the toolbar and in css we hide the button to un-collapse the toolbar. See in styles.scss: .cke_toolbox_collapser.
      this.editorConfig.toolbarCanCollapse = true;
      this.editorConfig.toolbarStartupExpanded = false;
    }
  }

  public editorConfig: any;

  @Input()
  public get showToolbar(): boolean {
    return this._showToolbar;
  }
  public set showToolbar(newShow: boolean) {
    this._showToolbar = newShow;
    this.setEditorConfig();
  }
  private _showToolbar: boolean = true;

  @Input()
  public get text(): string {
    return this._text;
  }
  public set text(newText: string) {
    this._text = newText;
    this.textChange.emit(newText);
  }
  private _text: string;

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = value;
    this.setEditorConfig();
  }
  private _disabled: boolean = false;

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();

  onInitialized(event: any) {
    const htmlEditor = event.component;
    const Link = htmlEditor.get("formats/link");
    class PrefixLink extends Link {
      static sanitize(url: string) {
        const sanitizedUrl = super.sanitize(url, this.PROTOCOL_WHITELIST);
        return HTMLEditorLinkPrefixHelper.addHttpsPrefixWhenRequired(sanitizedUrl, this.PROTOCOL_WHITELIST);
      }
    }
    htmlEditor.register({ "formats/link": PrefixLink });
  }
}
