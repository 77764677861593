import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DxTreeViewComponent } from "devextreme-angular";
import { AuthorizationLanguage } from "../../../../../../common/language/Authorizationlanguage.service";
import { ElementHelper } from "../../../../../helpers/element.helper";
import { Sorter } from "../../../../../sorting/sorter";
import { CopyingObjects } from "../../../../../utils/copying-objects";
import { Role } from "../../../models/authorization-role-model";
import { RoleHierarchyList } from "../../../services/models/authorization-roleHierarchy-list-model";
import { RoleHierarchy } from "../../../services/models/authorization-roleHierarchy-model";

@Component({
  selector: "app-authorization-content-roles-treeview",
  templateUrl: "./authorization-content.component-roles-treeview.html",
  styleUrls: ["./authorization-content.component-roles-treeview.scss"],
})
export class RolesTreeViewComponent {
  @ViewChild(DxTreeViewComponent) roleTreeView: DxTreeViewComponent;

  public readonly dateSerializationFormat: string = "yyyy-MM-dd";

  @Input()
  public get selectedRoles(): Role[] {
    return this._selectedRoles;
  }
  public set selectedRoles(_newRoles: Role[]) {
    this._selectedRoles = _newRoles;
    this.updateSelected();
  }

  private _selectedRoles: Role[] = [];

  @Output() selectedRolesChanged = new EventEmitter<Role[]>();

  @Input() public title: string;

  @Input("roles")
  public set roles(newRoles: RoleHierarchyList) {
    this.copyRoles(newRoles);
    this.updateSelected();
  }

  public userHasRoleAssigned(roleId: number): boolean {
    var userRoleIndex = this._selectedRoles.findIndex((r) => r.id === roleId);

    return userRoleIndex === -1 ? false : true;
  }

  private copyRoles(roleHierarchyList: RoleHierarchyList) {
    if (ElementHelper.isNullOrUndefined(this.rolesCopy) === false) {
      return;
    }

    if (ElementHelper.isNullOrUndefined(roleHierarchyList) === false) {
      this.rolesCopy = [];
      roleHierarchyList.all.forEach((roleHierarchy) => {
        const copy = new RoleHierarchy();
        copy.selected = roleHierarchy.selected;
        copy.role = this.createRoleFromHierarchy(roleHierarchy);
        copy.items = CopyingObjects.deepCopy(roleHierarchy.items);

        this.rolesCopy.push(copy);
      });
      Sorter.sortAlphabetically(this.rolesCopy, (v) => v.role.name);
    }
  }

  public rolesCopy: RoleHierarchy[];

  @Input("isEnabled")
  public get isEnabled(): boolean {
    return this._isEnabled;
  }
  public set isEnabled(newEnabled: boolean) {
    this._isEnabled = newEnabled;
  }
  private _isEnabled: boolean = true;

  constructor(public language: AuthorizationLanguage) {}

  private createRoleFromHierarchy(roleHierarchy: RoleHierarchy): Role {
    const role = new Role();
    role.assignableFrom = roleHierarchy.role.assignableFrom;
    role.description = roleHierarchy.role.description;
    role.from = roleHierarchy.role.from;
    role.id = roleHierarchy.role.id;
    role.name = roleHierarchy.role.name;
    role.until = roleHierarchy.role.until;

    return role;
  }

  private getSelectedItems(rolesHierarchy: RoleHierarchy[]): Role[] {
    let selectedRoles: Role[] = [];

    rolesHierarchy.forEach((roleHierarchy) => {
      if (roleHierarchy.selected) {
        const role = this.createRoleFromHierarchy(roleHierarchy);
        selectedRoles.push(role);
      }

      if (roleHierarchy.items.length === 0) {
        return;
      }

      const selectChildRoles = this.getSelectedItems(roleHierarchy.items);
      if (selectChildRoles.length === 0) {
        return;
      }

      selectedRoles = selectedRoles.concat(selectChildRoles);
    });

    return selectedRoles;
  }

  private updateSelected(): void {
    if (this.rolesCopy && this.selectedRoles && this.roleTreeView) {
      this.setSelectedItems(this.rolesCopy, this.selectedRoles);
      this.updateRoleValidityPeriodFor(this._selectedRoles);
    }
  }

  private updateRoleValidityPeriodFor(userRoles: Role[]) {
    if (ElementHelper.isNullOrUndefined(userRoles)) {
      return;
    }

    this.setRolesValidityPeriodBasedOnUserAssignements(userRoles);
  }

  private setRolesValidityPeriodBasedOnUserAssignements(userRoles: Role[]) {
    if (ElementHelper.isNullOrUndefined(this.rolesCopy)) {
      return;
    }

    this.rolesCopy.forEach((roleHierarchy) => {
      const userRoleIndex = userRoles.findIndex((ur) => ur.id === roleHierarchy.role.id);

      if (userRoleIndex > -1) {
        const userRole = userRoles[userRoleIndex];
        roleHierarchy.role.from = userRole.from;
        roleHierarchy.role.until = userRole.until;
      }
    });
  }

  private setSelectedItems(rolesHierarchy: RoleHierarchy[], selectedRoles: Role[], parentIsSelected: boolean = false): void {
    rolesHierarchy.forEach((roleHierarchy) => {
      // Using SelectItem and UnSelectItem because the two way binding on datasource doesn't work.
      const isSelected = selectedRoles.find((x) => x.id === roleHierarchy.role.id) !== undefined;
      if (isSelected) {
        this.roleTreeView.instance.selectItem(roleHierarchy);
      } else {
        this.roleTreeView.instance.unselectItem(roleHierarchy);
        this.clearRoleValidityPeriod(roleHierarchy.role);
      }

      if (roleHierarchy.items.length === 0) {
        return;
      }

      this.setSelectedItems(roleHierarchy.items, selectedRoles, isSelected);
    });
  }

  private clearRoleValidityPeriod(role: Role) {
    role.from = null;
    role.until = null;
  }

  public undoChanges() {
    this.updateSelected();
  }

  public saveChanges() {
    const result: Role[] = this.getSelectedItems(this.rolesCopy);
    this.selectedRolesChanged.emit(result);
  }
}
