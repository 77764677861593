<div *ngIf="tabs && tabs.items.length > 0" class="authorization-tabs">
  <div id="tabs">
    <dx-tabs [dataSource]="tabs.items" [keyExpr]="tabs.tabKeyExpr" [showNavButtons]="false" [(selectedItemKeys)]="tabs.selectedTabKeys" (onItemClick)="selectTab($event)"></dx-tabs>
  </div>
</div>
<div class="content dx-fieldset">
  <div *ngFor="let tab of tabs.items">
    <app-auth-contentbase *ngIf="tab.id == this.selectedTab" [authorizationContentType]="tab.content" [component]="this.componentToPush"></app-auth-contentbase>
  </div>
</div>
