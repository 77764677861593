<div *ngIf="contentLoaded" class="outer-div panel-with-header-and-content">
    <div class="inner-div">
        <dx-chart [dataSource]="dataPoints" [title]="title" [valueAxis]="axisConfiguration">
            <dxo-export [enabled]="true"></dxo-export>
            <dxo-legend [visible]="false"></dxo-legend>
            <dxo-common-series-settings argumentField="x">
            </dxo-common-series-settings>
            <dxi-series type="bar" valueField="score" [axis]="scoreAxis.name">
            </dxi-series>
            <dxi-series type="spline" valueField="count" [axis]="countAxis.name">
            </dxi-series>
            <dxo-argument-axis>
            </dxo-argument-axis>
            <dxo-scroll-bar [visible]="showScrollbar" position="bottom"> </dxo-scroll-bar>
        </dx-chart>
    </div>
</div>