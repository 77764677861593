import { Component, Input } from "@angular/core";

import { CMSMessage } from "./../cms-message.model";
import { CmsLanguage } from "../language/cmslanguage.service";

@Component({
  selector: "app-cmsmessageedit-display-component",
  templateUrl: "./cms-message-edit-display.component.html",
  styleUrls: ["./cms-message-edit-display.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class CMSMessageEditDisplayComponent {
  public constructor(public language: CmsLanguage) {}

  @Input() message: CMSMessage;

  _selectedIndex: number;
  @Input()
  public set selectedIndex(value: number) {
    this._selectedIndex = value;
  }
  public get selectedIndex() {
    return this._selectedIndex;
  }
}
