import { Component, OnInit } from "@angular/core";

import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { Sorter } from "../../../angular-common/sorting/sorter";
import { CMSService } from "../../cms/cms.service";
import { EbsDoughnutCreator } from "../ebs-doughnut-creator";
import { EBSService } from "../ebs-services";
import { EbsLanguage } from "../language/ebslanguage.service";
import { EbsOverviewData } from "./ebs-overview-data";

@Component({
  selector: "app-ebs-overview-component",
  templateUrl: "./ebs-overview-component.html",
  styleUrls: ["./ebs-overview-component.scss"],
})
export class EbsOverviewComponent implements OnInit {
  constructor(private ebsService: EBSService, private cmsService: CMSService, public languageService: EbsLanguage, private selectLanguage: PlLanguageSelection) {}

  public ebsOverviewDtos: EbsOverviewData[] = [];

  ngOnInit(): void {
    this.ebsService
      .getEbs(true)
      .pipe(
        map((ebss) => {
          ebss.forEach((ebs) => {
            let ebsOverviewDto = EbsDoughnutCreator.createOverviewDto(ebs, this.selectLanguage, this.cmsService);
            Sorter.sortSortOrderThenAlpha(ebsOverviewDto.ebsResult.groups, (r) => r.title);
            this.ebsOverviewDtos.push(ebsOverviewDto);
          });
        }),
      )
      .subscribe(() => {
        // Items loaded
      });
  }

  printEBSOverview() {
    window.print();
  }
}
