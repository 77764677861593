import { RoleHierarchyDto } from "../../../../../common/models/dto/RoleHierarchyDto-dto";
import { Role } from "../../models/authorization-role-model";

export class RoleHierarchy {
  public constructor() {
    this.role = new Role();
  }

  public role: Role;
  public items: RoleHierarchy[] = [];
  public selected: boolean = false;

  public copyFromDto(dto: RoleHierarchyDto) {
    this.role = Role.copyFromDto(dto.Role);
    dto.Items.forEach((dh) => {
      const newHierarchy = new RoleHierarchy();
      newHierarchy.copyFromDto(dh);
      this.items.push(newHierarchy);
    });
  }

  public copyToDto(): RoleHierarchyDto {
    const result = new RoleHierarchyDto();

    result.Role = this.role.copyToDto();
    result.Items = [];
    this.items.forEach((rh) => {
      result.Items.push(rh.copyToDto());
    });

    return result;
  }

  public getByRoleName(name: string): RoleHierarchy {
    if (this.role.name && this.role.name.toLowerCase() === name.toLowerCase()) {
      return this;
    }

    let result: RoleHierarchy = null;
    this.items.forEach((rh) => {
      const hierarchy = rh.getByRoleName(name);
      if (hierarchy && hierarchy !== null) {
        result = hierarchy;
      }
    });

    return result;
  }

  public delete(roleHierarchy: RoleHierarchy) {
    const idx = this.items.findIndex((x) => x.role.id === roleHierarchy.role.id);
    if (idx > -1) {
      this.items.splice(idx, 1);
    } else {
      this.items.forEach((x) => x.delete(roleHierarchy));
    }
  }
}
