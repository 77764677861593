import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { UserToEmployeeLoginInformationDto } from "../../models/me/dto/UserToEmployeeLoginInformation-dto";

@Injectable()
export class PreferedUserToEmployeeLoginService extends PlBaseService {
  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }
  private readonly endpoint: string = "UserEmployeeResolver";

  retrieveUserEmployeeLoginInformation(): Observable<UserToEmployeeLoginInformationDto> {
    return this.getData<UserToEmployeeLoginInformationDto>(this.endpoint);
  }
}
