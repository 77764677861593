import { IPresentableElementDto } from './IPresentableElement-dto';

// t4tsCodeFromCs": "0.8.2.0 for file PresentableElement.cs
// Don't adjust manually!

export class PresentableElementDto implements IPresentableElementDto {
    public KeyId: number;
    public ShortName: string = "";
    public LongName: string = "";
    public SortOrder: number;
}

