<div class="main-container">
  <div class="login-container">
    <div class="header-container">
      <div class="top-logo">
        <img [src]="logoImageUrl" class="logo" />
      </div>
      <div class="languageSelectorStyle">
        <app-language-selection [showTitles]="false"></app-language-selection>
      </div>
    </div>
    <h2 class="title-container">{{ language.accountLoginTitle }}</h2>
    <div class="control-container">
      <app-form-login *ngIf="utilizeBasicAuth" [login]="login" [loading]="loading" [error]="error"></app-form-login>
      <app-external-auth-login *ngIf="utilizeBasicAuth === false" [login]="loginExternalAuth" [loading]="loading"
        [error]="error"></app-external-auth-login>
    </div>
  </div>
</div>