import { DxButtonModule, DxFormModule, DxPopupModule, DxTemplateModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { LanguageSelectionModule } from "../languageselection/language-selection.module";
import { NgModule } from "@angular/core";
import { PageHeaderComponent } from "./page-header.component";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [CommonModule, DxButtonModule, DxPopupModule, DxTemplateModule, RouterModule, LanguageSelectionModule, DxTextBoxModule, DxValidatorModule, DxFormModule],
  declarations: [PageHeaderComponent],
  exports: [PageHeaderComponent],
})
export class PageHeaderModule {}
