import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { LanguageComponent } from "./language.component";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [LanguageComponent],
  imports: [CommonModule, DxButtonModule, FormsModule, HttpClientModule],
  providers: [],
  exports: [LanguageComponent],
  schemas: [],
})
export class LanguageModule {}
