import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { MenuItemFilterFactory } from "./filters/MenuItemFilterFactory";

@Injectable()
export class MenuVisibilityGuard {
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const menuItemFilter = MenuItemFilterFactory.createFor(next.routeConfig.path);
    return of(menuItemFilter.shouldShow());
  }
}

export const canActivateMenuVisibilityGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(MenuVisibilityGuard).canActivate(route);
};
