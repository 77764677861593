
// t4tsCodeFromCs": "0.8.2.0 for file UserClaim.cs
// Don't adjust manually!

export class UserClaimDto {
    public Value: string = "";
    public Namespace: string = "";
    public UsageDescription: string = "";
    public static RoleNameSpace: string = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
}

