import { NgModule, Type } from "@angular/core";
import { DxButtonModule, DxTemplateModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { SafeDataDirectiveModule } from "../../../angular-common/utils/safe-data-directive-module";
import { CmsFeaturesModel } from "../../cms/cms-features-model";
import { AppComponentSpecificComponentFactory } from "../../components/appcomponentspecific-content/appcomponentspecific-component-factory";
import { AppComponentSpecificSpecification } from "../../components/appcomponentspecific-content/appcomponentspecific-component-specification";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { LabourConditionDetailModule } from "../../labourconditions/labourcondtition-detail/labour-detail.module";
import { ExogeousInputOptionsModule } from "../exogeous-input-options/exogeous-input-options.module";
import { LabourConditionDetailWithChoicesComponent } from "../labourcondtition-detail/labour-detail-with-choices.component";
import { ChoiceSelectionNoneComponent } from "./choice-selection-none.component";
import { ChoiceSelectionComponent } from "./choice-selection.component";
import { ExogenousInputDetailOptionsComponent } from "./detail/exogenous-input-detail-options.component";
import { ExogenousInputOrderProgressComponent } from "./detail/exogenous-input-order-progress.component";
import { ExogenousInputDetailComponent } from "./detail/exogenous-input.component";
import { SourceValuesComponent } from "./detail/source-values.component";
import { TargetDetailSourcesComponent } from "./detail/sources.component";

@NgModule({
  declarations: [
    ChoiceSelectionComponent,
    ExogenousInputDetailComponent,
    ExogenousInputDetailOptionsComponent,
    TargetDetailSourcesComponent,
    SourceValuesComponent,
    ExogenousInputOrderProgressComponent,
  ],
  imports: [CommonModule, FormsModule, HttpClientModule, DxTemplateModule, DxButtonModule, ExogeousInputOptionsModule, SafeDataDirectiveModule],
  providers: [],
  exports: [ChoiceSelectionComponent, ExogenousInputDetailComponent, ExogenousInputDetailOptionsComponent, TargetDetailSourcesComponent, ExogenousInputOrderProgressComponent],
  schemas: [],
})
export class ChoicesModule {
  constructor() {
    var featureTogglesCms = new CmsFeaturesModel();
    if (featureTogglesCms.choices.enabled) {
      ChoicesModule.registerDetailClass(ChoiceSelectionComponent);
      LabourConditionDetailModule.registerDetailClass(LabourConditionDetailWithChoicesComponent);
    } else {
      ChoicesModule.registerDetailClass(ChoiceSelectionNoneComponent);
    }
  }

  public static registerDetailClass(componentClass: Type<any>) {
    AppComponentSpecificComponentFactory.registerComponent(new AppComponentSpecificSpecification(AppComponentSpecificContentType.ChoiceSelection, componentClass));
  }
}
