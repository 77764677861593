<div class="buttons-bottom-margin align-right">
  <dx-button *ngIf="tabContent.isContentUserDetails" id="addButton" icon="fas fa-plus" [hint]="language.ButtonNew" (onClick)="onAddUser()"></dx-button>
  <dx-button *ngIf="tabContent.isContentUserDetails" [disabled]="selectedUser === undefined" id="cloneButton" icon="far fa-copy" [hint]="language.Copy" (onClick)="onCloneUser()"></dx-button>
  <dx-button *ngIf="tabContent.isContentUserDetails" [disabled]="canDelete" id="deleteButton" icon="fas fa-trash" [hint]="language.ButtonDelete" (onClick)="onDeleteUser()"></dx-button>
</div>

<dx-list
  [ngStyle]="!tabContent.isContentUserDetails && { 'padding-top': '37px'}"
  [dataSource]="users"
  [selectionMode]="'single'"
  [showSelectionControls]="true"
  [searchEnabled]="true"
  searchMode="contains"
  searchExpr="userNameIfNoLongname"
  [showScrollbar]="'onHover'"
  (onSelectionChanged)="selectionChanged($event)"
>
  <div *dxTemplate="let item of 'item'">
    <span>{{ item.userNameIfNoLongname }}</span>
  </div>
</dx-list>
