<div class="editMessageBoard">
  <h3 class="panel-header">{{ message.title }}</h3>

  <dx-tabs
    *ngIf="message.isLabourCondition"
    [width]="500"
    [showNavButtons]="false"
    [dataSource]="[
      { id: 0, text: language.f3MeWebApiCMSMessageSummary },
      { id: 1, text: language.f3MeWebApiCMSMessageContent }
    ]"
    [(selectedIndex)]="selectedIndex"
  >
  </dx-tabs>

  <figure class="messageFigure" *ngIf="message.canHaveImage && message.hasImage && message.imageLoaded">
    <img [src]="message.imageURL" />
  </figure>

  <div class="message_content" appSafeData [passedHtmlData]="message.summary" *ngIf="message.isLabourCondition && (selectedIndex === 0 || !selectedIndex)"></div>
  <div class="message_content" appSafeData [passedHtmlData]="message.content" *ngIf="message.isLabourCondition && selectedIndex === 1" class="lcDetail"></div>

  <div class="message_content" appSafeData [passedHtmlData]="message.summary" *ngIf="!message.isLabourCondition && !message.isSimulation"></div>
  <app-cms-link [link]="message.link" *ngIf="message.isLabourCondition === false"></app-cms-link>
</div>
