import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { AppComponentSpecificComponentFactory } from "./appcomponentspecific-component-factory";
import { AppComponentSpecificContentType } from "./appcomponentspecific-content-type";
import { AppComponentSpecificDynamicViewDirective } from "./appcomponentspecific-dynamicview.directive";
import { IAppComponentSpecificComponent } from "./i-appcompnentspecific-component";

@Component({
  selector: "app-appcomponentspecific-content-view",
  template: `
    <div class="link-lc-content-view">
      <ng-template appComponentInjector></ng-template>
    </div>
  `,
})
export class AppComponentSpecificDynamicViewComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() type: AppComponentSpecificContentType;

  currentAdIndex = -1;

  private createdComponent: ComponentRef<IAppComponentSpecificComponent>;

  @ViewChild(AppComponentSpecificDynamicViewDirective, { static: true }) linkContent!: AppComponentSpecificDynamicViewDirective;

  constructor(private linkContentService: AppComponentSpecificComponentFactory) {}

  ngOnInit(): void {
    this.loadComponent();
  }

  ngOnDestroy() {
    this.destroyCreated();
  }

  loadComponent() {
    this.destroyCreated();

    const contentItemSpecification = this.linkContentService.retrieveComponent(this.type);

    const viewContainerRef = this.linkContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<IAppComponentSpecificComponent>(contentItemSpecification.componentClass);
    this.createdComponent = componentRef;
    componentRef.instance.data = this.data;

    var typeValue = AppComponentSpecificContentType[this.type];
    (<any>componentRef.instance).typeName = typeValue;
  }

  destroyCreated() {
    if (this.createdComponent) {
      this.createdComponent.destroy();
    }
  }
}
