<!-- Brand and toggle get grouped for better mobile display -->
<div *ngIf="!isLoginPage" class="navbar-header navmenu" (click)="toggleMenuVisibility()" id="popuplocation">
  <label class="menuheader">{{ title }}</label>

  <i *ngIf="canShowMenu" class="fas fa-th fa-2x"></i>

  <dx-popup
    [width]="300"
    [height]="'auto'"
    [showTitle]="false"
    [dragEnabled]="false"
    [shading]="false"
    [hideOnOutsideClick]="true"
    [(visible)]="popupVisible"
    [position]="{ my: 'right top', at: 'right bottom', of: '#popuplocation' }"
  >
    <div *dxTemplate="let data of 'content'" class="hide-scrollbar">
      <ul *ngFor="let menuItem of menuItems" class="icon-list menuitem">
        <a class="button-link" (click)="switchToMenu(menuItem)">
          <dx-button [icon]="menuItem.icon"></dx-button>
          <div class="menuitemtext">{{ menuItem.displayTitle }}</div>
        </a>
      </ul>
    </div>
  </dx-popup>
</div>
