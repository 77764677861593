import { DxCheckBoxModule, DxDateBoxModule, DxFileUploaderModule, DxNumberBoxModule, DxSelectBoxModule, DxTextBoxModule, DxTooltipModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ExogeousInputAttachmentOptionComponent } from "./exogeous-input-attachment-option.component";
import { ExogeousInputBoolOptionComponent } from "./exogeous-input-bool-option.component";
import { ExogeousInputChoiceOptionComponent } from "./exogeous-input-choice-option.component";
import { ExogeousInputDateOptionComponent } from "./exogeous-input-date-option.component";
import { ExogeousInputNumericOptionComponent } from "./exogeous-input-numeric-option.component";
import { ExogeousInputOptionComponent } from "./exogeous-input-option.component";
import { ExogeousInputTextOptionComponent } from "./exogeous-input-text-option.component";
import { OptionsCast } from "./options-transform.pipe";

@NgModule({
  imports: [CommonModule, DxTextBoxModule, DxDateBoxModule, DxSelectBoxModule, DxNumberBoxModule, DxCheckBoxModule, DxFileUploaderModule, DxTooltipModule],
  exports: [ExogeousInputOptionComponent],
  declarations: [
    ExogeousInputOptionComponent,
    ExogeousInputDateOptionComponent,
    ExogeousInputChoiceOptionComponent,
    ExogeousInputNumericOptionComponent,
    ExogeousInputBoolOptionComponent,
    ExogeousInputTextOptionComponent,
    ExogeousInputAttachmentOptionComponent,
    OptionsCast,
  ],
  providers: [],
})
export class ExogeousInputOptionsModule {}
