import { MenuTypeDto } from "../../../common/models/dto/MenuType-dto";
import { LanguageItem } from "../../language/language-dto.model";
import { PlLanguageSelection } from "../../language/pl-language-selection";
import { ISortOrder } from "../../sorting/sortorder";

export class MenuItem implements ISortOrder {
  public constructor(
    public id: number,
    public title: LanguageItem,
    public route: string,
    public requiredRoles: string[],
    public icon: string,
    public items: MenuItem[],
    public menuType: MenuTypeDto,
    public sortOrder: number,
    private languageSelection: PlLanguageSelection,
  ) {}

  public get hasRoute(): boolean {
    return this.route && this.route !== null && this.route !== "";
  }

  public get hasSubMenuItems(): boolean {
    return this.items && this.items !== null && this.items.length > 0;
  }

  public get displayTitle() {
    return this.languageSelection.currentValueFor(this.title);
  }

  public getAllSubMenuItemsIncludingSelf(): MenuItem[] {
    const result: MenuItem[] = [];
    result.push(this);
    if (this.hasSubMenuItems) {
      this.items.forEach((item) => {
        const children = item.getAllSubMenuItemsIncludingSelf();
        children.forEach((child) => {
          result.push(child);
        });
      });
    }

    return result;
  }
}
