import { DxButtonModule, DxFormModule, DxPopupModule, DxTextBoxModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TextPopupComponent } from "./textpopup.component";

@NgModule({
  declarations: [TextPopupComponent],
  imports: [DxButtonModule, DxPopupModule, DxTextBoxModule, DxFormModule, CommonModule],
  providers: [],
  exports: [TextPopupComponent],
  schemas: [],
})
export class TextPopupModule {}
