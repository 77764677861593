<div *ngIf="message" class="top lcDetail">
  <div class="header col-md-4 col-sm-12 col-xs-12">
    <div>
      <h1>{{ message.title }}</h1>
      <div class="img-block" *ngIf="message.imageLoaded"><img [src]="message.imageURL" /></div>
    </div>
  </div>

  <div class="col-sm-12 col-xs-12 col-lg-8" *ngIf="showMessageContent">
    <app-labour-detail-message-component [message]="message"></app-labour-detail-message-component>
  </div>
</div>
