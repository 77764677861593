import { LanguageItem } from "../../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { LanguageBuilder } from "../../../../common/language/language-builder";
import { Link } from "../../../components/links/link";
import { TargetDefinitionDto } from "../../../models/cms/dto/TargetDefinition-dto";

export class ChoiceDefinition {
  public constructor(private languageSelection: PlLanguageSelection) {}

  public id: number;
  private _title: LanguageItem;
  public link: Link;
  public isWaiting: boolean;
  public IsApproved: boolean;

  public get title(): string {
    return this.languageSelection.currentValueFor(this._title);
  }

  public copyFromDTO(dto: TargetDefinitionDto) {
    this.id = dto.Id;
    const builder = new LanguageBuilder(this.languageSelection);
    this._title = builder.copyLanguageItem(dto.Title);
    this.link = new Link(this.languageSelection);
    this.link.copyFromDTO(dto.Link);
    this.isWaiting = dto.IsWaiting;
    this.IsApproved = dto.IsApproved;
  }
}
