import { EmployeeIdentificationReferenceDto } from './EmployeeIdentificationReferenceDto-dto';
import { UserDto } from './../../../../common/models/dto/UserDto-dto';

// t4tsCode gen": "0.8.2.0 for file UserToEmployeeMapping.cs
// Don't adjust manually!

export class UserToEmployeeMappingDto {
    public User: UserDto;
    public Employee: EmployeeIdentificationReferenceDto;
}

