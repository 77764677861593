import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CmsFeaturesModel } from "../../cms/cms-features-model";
import { AppComponentSpecificComponentFactory } from "../../components/appcomponentspecific-content/appcomponentspecific-component-factory";
import { AppComponentSpecificSpecification } from "../../components/appcomponentspecific-content/appcomponentspecific-component-specification";
import { AppComponentSpecificContentType } from "../../components/appcomponentspecific-content/appcomponentspecific-content-type";
import { RatingModule } from "../components/rating.module";
import { RatingLanguage } from "../language/ratinglanguage.service";
import { MessageRatingFeatureModel } from "./message-rating-feature-model";
import { MessageRatingComponent } from "./message-rating.component";
import { MessageRatingService } from "./message-rating.service";

@NgModule({
  imports: [CommonModule, RatingModule],
  exports: [MessageRatingComponent],
  declarations: [MessageRatingComponent],
  providers: [MessageRatingService, MessageRatingFeatureModel, RatingLanguage],
})
export class MessageRatingModule {
  constructor() {
    const feature = new MessageRatingFeatureModel(new CmsFeaturesModel());

    if (feature.isEnabled) {
      AppComponentSpecificComponentFactory.registerComponent(new AppComponentSpecificSpecification(AppComponentSpecificContentType.MessageRating, MessageRatingComponent));
    }
  }
}
