import { AuthorizationContentType } from "../../models/authorization-content.types";
import { AuthorizationRolesService } from "../../services/authorization-roles.service";
import { User } from "../../services/models";
import { RoleHierarchyList } from "../../services/models/authorization-roleHierarchy-list-model";
import { AuthorizationTabContent } from "./authorization-tabs.tab-content";

export class AuthorizationRolesTabContent extends AuthorizationTabContent {
  public users: User[];
  public roles: RoleHierarchyList;

  constructor(public authorizationService: AuthorizationRolesService, contentType: AuthorizationContentType) {
    super(contentType);
    this.getMetaData();
  }

  public getMetaData() {
    this.authorizationService.getAuthorizationRolesMetaData().subscribe((metaData) => {
      if (metaData) {
        this.users = User.copyFromDtos(metaData.Users);

        const newRoles = new RoleHierarchyList();
        newRoles.copyFromDto(metaData.Roles);
        this.roles = newRoles;
      }
    });
  }
}
