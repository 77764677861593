import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MenuFilterModule } from "./filters/menufilter.module";
import { MenuService } from "./menu.service";

@NgModule({
  imports: [CommonModule, MenuFilterModule],
  providers: [MenuService],
})
export class MenuServiceModule {}
