import { RoleDto } from './RoleDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file RoleHierarchyDto.cs
// Don't adjust manually!

export class RoleHierarchyDto {
    public Role: RoleDto;
    public Items: RoleHierarchyDto [] = [];
}

