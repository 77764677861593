export const CHART_CONFIG = {
  series: {
    argumentField: "title",
    valueField: "value",
    radius: 0.5,
  },
  legend: {
    visible: false,
  },
  size: {
    height: 300,
    width: 300,
  },
  label: {
    visible: true,
    connector: {
      visible: true,
      width: 1,
    },
  },
  tooltip: {
    enabled: true,
    customizeTooltip() {
      return { text: this.argument };
    },
  },
};
