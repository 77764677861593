import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxFormModule, DxLookupModule, DxPopupModule, DxTreeListModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { CMSModule } from "../../cms/cms.module";
import { LanguageItemEditorGridModule } from "../../cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { CmsManagementModule } from "../../cms/management/cms-management.module";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { EbsRoutesDto } from "../../models/ebs/dto/EbsRoutes-dto";
import { EbsEditModule } from "../ebs-edit/ebsedit.module";
import { EbsEditComponent } from "./cms-ebs-edit.component";
import { EbsTreeEditComponent } from "./cms-ebs-tree-editor.component";

const routes: Routes = [
  {
    path: EbsRoutesDto.EbsEdit,
    component: EbsEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: EbsRoutesDto.EbsTreeEdit + "/:id",
    component: EbsTreeEditComponent,
    data: [
      {
        menuType: MenuItemTypesDto.Management,
        inheritRolesFrom: EbsRoutesDto.EbsEdit,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CmsManagementModule,
    DxPopupModule,
    DxTreeListModule,
    DxButtonModule,
    DxFormModule,
    DxLookupModule,
    CMSModule,
    DxDataGridModule,
    LanguageItemEditorGridModule,
    EbsEditModule,
  ],
  exports: [EbsTreeEditComponent, EbsEditComponent],
  declarations: [EbsTreeEditComponent, EbsEditComponent],
  providers: [],
})
export class EbsManagementModule {}
