import { IModelWithIdDto } from './../../../../common/models/dto/IModelWithId-dto';
import { UserToEmployeeMappingDto } from './UserToEmployeeMapping-dto';

// t4tsCode gen": "0.8.2.0 for file PreferedUserEmployeeMappingDto.cs
// Don't adjust manually!

export class PreferedUserEmployeeMappingDto implements IModelWithIdDto {
    public KeyId: number;
    public UserToEmployeeMapping: UserToEmployeeMappingDto;
}

