import { Injectable } from "@angular/core";
import { CmsFeaturesModel } from "../../cms/cms-features-model";

@Injectable()
export class MessageRatingFeatureModel {
  constructor(private featureSettings: CmsFeaturesModel) {}

  public get isEnabled() {
    return this.featureSettings.Rating.enabled;
  }

  public get rateNews() {
    return this.isEnabled && (this.featureSettings.Rating.rate_news || false);
  }

  public get rateLabourConditions() {
    return this.isEnabled && (this.featureSettings.Rating.rate_labourconditions || false);
  }
}
