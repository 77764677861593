<div *ngIf="sources" class="maindiv">
  <div class="header">
    <div>
      <p>{{ languageService.f3MeWebApiTargetSelectSources }}</p>
    </div>
    <div class="grid-container">
      <div></div>
      <div class="optionCurrentValue">
        <p>{{ languageService.f3MeWebApiTargetCurrent }}</p>
      </div>
      <div class="optionValue" id="optionValueHeader">
        <p>{{ languageService.f3MeWebApiTargetInput }}</p>
      </div>
    </div>
    <div *ngFor="let source of sources">
      <app-source-values-component [options]="source.options" [currentValues]="source.currentValues"> </app-source-values-component>
    </div>
  </div>
</div>
