import { Component, Input } from "@angular/core";

import { BaseCMSMessageComponent } from "../shared/cms.basemessage.component";
import { CMSMessage } from "../cms-message.model";

@Component({
  selector: "app-simulationblockmessage-component",
  templateUrl: "./simulationblock-message.component.html",
  styleUrls: ["./simulationblock-message.component.scss", "./../shared/shared-styles.scss"],
})
export class SimulationBlockMessageComponent extends BaseCMSMessageComponent {
  public constructor() {
    super();
  }

  @Input() message: CMSMessage;
  @Input() index: number;
}
