import { SmartFilterItemDto } from './SmartFilterItem-dto';

// t4tsCode gen": "0.8.2.0 for file SmartFilter.cs
// Don't adjust manually!

export class SmartFilterDto {
    public shortName: string;
    public value: string;
    public combined: string;
    public data: SmartFilterItemDto [] = [];
}

