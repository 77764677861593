import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import { EditToggleComponent } from "./edittoggle.component";
import { FormsModule } from "@angular/forms";
import { LanguageModule } from "../language/language.module";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [CommonModule, FormsModule, LanguageModule, DxButtonModule],
  declarations: [EditToggleComponent],
  exports: [EditToggleComponent],
  providers: [],
})
export class EditToggleModule {}
