import { BenefitGroup } from "./benefit-group.model";
import { BenefitValue } from "./benefit-value.model";
import { EbsResultDto } from "../../models/ebs/dto/EbsResult-dto";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { Sorter } from "../../../angular-common";

export class EbsResult {
  public constructor(private selectLanguage: PlLanguageSelection) {}

  public groups: BenefitGroup[];
  public totalLabourCondition: BenefitValue;
  public cmsMessageId: number;

  public copyFromDTO(dto: EbsResultDto) {
    const list = new Array<BenefitGroup>();

    for (const jsonGroup of dto.Groups) {
      const group = new BenefitGroup(this.selectLanguage);
      group.copyFromDTO(jsonGroup);
      list.push(group);
    }

    this.cmsMessageId = dto.CmsMessageId;
    this.totalLabourCondition = new BenefitValue(
      dto.TotalLabourCondition.ValueAsText,
      dto.TotalLabourCondition.ValueAsNumber,
      dto.TotalLabourCondition.AppliesToTotal,
      dto.TotalLabourCondition.Visible,
    );
    this.groups = list.sort(Sorter.compareSortOrder);
  }
}
