import { Sorter } from "../../../../../angular-common/sorting/sorter";
import { ExogenousInputOptionDto } from "../../../../models/me/dto/ExogenousInputOption-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ModifiedExogenousInputOptionAttachmentDto } from "../../../../models/me/dto/ModifiedExogenousInputOptionAttachment-dto";
import { ModifiedExogenousInputOptionsDto } from "../../../../models/me/dto/ModifiedExogenousInputOptions-dto";
import { ExogenousInputOptionAttachment } from "./exogenous-input-option-attachment.model";
import { ExogenousInputOptionBool } from "./exogenous-input-option-bool.model";
import { ExogenousInputOptionChoice } from "./exogenous-input-option-choice.model";
import { ExogenousInputOptionDate } from "./exogenous-input-option-date.model";
import { ExogenousInputOptionNumeric } from "./exogenous-input-option-numeric.model";
import { ExogenousInputOptionObserver } from "./exogenous-input-option-observer";
import { ExogeousInputOptionText } from "./exogenous-input-option-text.model";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";

export class ExogenousInputOptionCreator {
  public static createTargetOption(dto: ExogenousInputOptionDto, observer: ExogenousInputOptionObserver): ExogenousInputOption {
    const dtoType = dto.Type.toUpperCase();
    let result: ExogenousInputOption = null;

    if (dtoType === ExogenousInputOptionTypes.typeText.toUpperCase()) {
      result = new ExogeousInputOptionText();
    } else if (dtoType === ExogenousInputOptionTypes.typeDate.toUpperCase()) {
      result = new ExogenousInputOptionDate();
    } else if (dtoType === ExogenousInputOptionTypes.typeChoice.toUpperCase()) {
      result = new ExogenousInputOptionChoice();
    } else if (dtoType === ExogenousInputOptionTypes.typeAttachment.toUpperCase()) {
      result = new ExogenousInputOptionAttachment();
    } else if (dtoType === ExogenousInputOptionTypes.typeBool.toUpperCase()) {
      result = new ExogenousInputOptionBool();
    } else if (dtoType === ExogenousInputOptionTypes.typeNumeric.toUpperCase()) {
      result = new ExogenousInputOptionNumeric();
    }

    if (result !== null) {
      result.copyFromDTO(dto);
      observer.register(result);
      return result;
    }

    throw Error("Unsupported type: " + dtoType);
  }

  public static createTargetOptions(dto: ExogenousInputOptionDto[], observer: ExogenousInputOptionObserver): ExogenousInputOption[] {
    const newOptions = new Array<ExogenousInputOption>();

    if (dto) {
      dto.forEach((optionDto) => {
        const option = ExogenousInputOptionCreator.createTargetOption(optionDto, observer);
        newOptions.push(option);
      });
    }

    return newOptions.sort(Sorter.compareSortOrder);
  }

  public static toDtoWithoutAttachments(targetOption: ExogenousInputOption[]) {
    const dto = new ModifiedExogenousInputOptionsDto();
    targetOption.forEach((option) => {
      const newDto = new ModifiedExogenousInputOptionDto();
      option.toDTO(newDto);
      dto.Options.push(newDto);
    });
    return dto;
  }

  public static toDTO(source: ExogenousInputOption[], target: ModifiedExogenousInputOptionsDto, includeAttachmentData: boolean) {
    source.forEach((sourceOption) => {
      if (sourceOption.isAttachmentOption) {
        const optionDTO = new ModifiedExogenousInputOptionAttachmentDto();
        (sourceOption as ExogenousInputOptionAttachment).toDTO(optionDTO);
        if (!includeAttachmentData) {
          optionDTO.FileData = null;
        }
        target.Attachments.push(optionDTO);
      } else {
        const optionDTO = new ModifiedExogenousInputOptionDto();
        sourceOption.toDTO(optionDTO);
        target.Options.push(optionDTO);
      }
    });
  }
}
