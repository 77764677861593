import { Component } from "@angular/core";
import { CmsFeaturesModel } from "../cms/cms-features-model";
import { EbsFeaturesModel } from "../ebs/ebs-features-model";
import { MessageTypeDto } from "../models/cms/dto/MessageType-dto";

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  public homgePageBannerEnabled: boolean;
  public ebsEnabled: boolean;
  public courselEnabled: boolean;
  public newsEnabled: boolean;
  public widerWidthNewsBeforeCarousel: boolean;
  public isEbsSummaryEmpty: boolean = true;
  public isCarouselVisible: boolean = false;

  public constructor(ebsFeaturesModel: EbsFeaturesModel, cmsFeaturesModel: CmsFeaturesModel) {
    this.messageTypes = MessageTypeDto;
    this.ebsEnabled = ebsFeaturesModel.ebsEnabled && cmsFeaturesModel.miniEbsEnabled;
    this.homgePageBannerEnabled = cmsFeaturesModel.Cms.homepagebanner_enabled || false;
    this.courselEnabled = cmsFeaturesModel.Cms.carousel_enabled || false;
    this.newsEnabled = cmsFeaturesModel.Cms.news_enabled || false;
    this.widerWidthNewsBeforeCarousel = cmsFeaturesModel.Cms.wider_width_news_before_carousel || false;
  }

  public messageTypes = MessageTypeDto;

  public onEbsSummaryRetreived(event: boolean) {
    this.isEbsSummaryEmpty = event;
  }

  public updateCarouselVisibility(value: boolean) {
    this.isCarouselVisible = value;
  }
}
