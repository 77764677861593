
export declare type EmailTemplateSubjectDto = [string, string];
export declare type EmailTemplateBodyDto = [string, string];
// t4tsCode gen": "0.8.2.0 for file EmailTemplate.cs
// Don't adjust manually!

export class EmailTemplateDto {
    public Id: number;
    public SendTo: string;
    public ShortName: string;
    public Subject: EmailTemplateSubjectDto;
    public Body: EmailTemplateBodyDto;
}

