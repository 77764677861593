import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { EmailTemplate } from "../consume-mail/email-template";
import { EmailTemplateEditService } from "./emailtemplate-edit.service";

@Component({
  selector: "app-emailtemplate-edit-component",
  templateUrl: "./emailtemplate-edit.component.html",
  styleUrls: ["./emailtemplate-edit.component.scss"],
  providers: [],
})
export class EmailTemplateEditComponent implements OnInit {
  public enterInEditMode = false;
  public templates: EmailTemplate[] = [];
  private _currentLanguage: string;
  public constructor(private selectLanguage: PlLanguageSelection, private emailTemplateEditService: EmailTemplateEditService, private activatedRoute: ActivatedRoute) {
    this._currentLanguage = selectLanguage.currentLanguage;
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (!ElementHelper.isNullOrUndefined(params["id"])) {
        this.retrieveSingleTemplate(params["id"]);
        this.enterInEditMode = true;
      } else {
        this.retrieveAllTemplates();
        this.enterInEditMode = false;
      }
    });
  }

  private retrieveAllTemplates() {
    this.emailTemplateEditService.getAll().subscribe((t) => {
      this.templates = t;
    });
  }

  private retrieveSingleTemplate(templateId: number) {
    this.emailTemplateEditService.getSingle(templateId).subscribe((t) => {
      this.templates.push(t);
    });
  }

  public get currentLanguage(): string {
    return this._currentLanguage;
  }

  public languageChanged(newLanguage) {
    this._currentLanguage = newLanguage;
  }
}
