import { Component, Input, OnDestroy } from "@angular/core";
import { MenuItem, MenuService } from "../../../../angular-common/menus";

import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { MenuItemTypesDto } from "../../../models/cms/dto/MenuItemTypes-dto";
import { MenuTypeProvider } from "../menu-type-provider";
import { RoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { Subscription } from "rxjs";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnDestroy {
  public isCollapsed: boolean = false;
  private menuTypeSubscription: Subscription = null;

  @Input()
  public set isLoginPage(value: boolean) {
    if (!value) {
      this.menuTypeSubscription = this.menuTypeProvider.menuItemType.subscribe((type) => {
        if ((type & MenuItemTypesDto.TopMenuItems) === type) {
          this.menuItemType = type;
        }
      });
    }
  }

  public constructor(private menuService: MenuService, public languageService: CmsLanguage, private menuTypeProvider: MenuTypeProvider) {}

  ngOnDestroy() {
    if (this.menuTypeSubscription) {
      this.menuTypeSubscription.unsubscribe();
    }
  }

  public toggleMenuVisibility = (): void => {
    this.isCollapsed = this.isCollapsed ? false : true;
  };

  public getMenuVisibility = (): boolean => this.isCollapsed;

  private _menuItemType: MenuItemTypesDto = MenuItemTypesDto.MainMenu;
  @Input()
  public get menuItemType() {
    return this._menuItemType;
  }
  public set menuItemType(newMenuItemType: MenuItemTypesDto) {
    if (newMenuItemType === MenuItemTypesDto.Unknown) {
      console.log("Ignoring request for setting unknonw");
      return;
    }
    if (newMenuItemType === MenuItemTypesDto.Login) {
      console.log("Ignoring because on loginpage");
      return;
    }

    this._menuItemType = newMenuItemType;
    this.menuService.getSubMenuItems(this._menuItemType).subscribe((m) => {
      this.menuItems = RoleGuard.filterForActiveUser(m);
    });
  }

  private _menuItems: MenuItem[] = [];
  public get menuItems() {
    return this._menuItems;
  }
  public set menuItems(newMenuItems: MenuItem[]) {
    this._menuItems = newMenuItems;
  }
}
