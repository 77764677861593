import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HtmlEditorModule } from "../../../angular-common/components/htmleditor/htmleditor.module";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { EditToggleModule } from "../../components/edittoggle/edittoggle.module";
import { LanguageModule } from "../../components/language/language.module";
import { LanguageItemEditorModule } from "../../components/language/languageitem-editor/languageitem-editor.module";
import { CmsRoutesDto } from "../../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { EmailModule } from "../consume-mail/email-module";
import { EmailTemplateEditTemplateComponent } from "./emailtemplate-edit-template.component";
import { EmailTemplateEditComponent } from "./emailtemplate-edit.component";
import { EmailTemplateEditService } from "./emailtemplate-edit.service";

const routes: Routes = [
  {
    path: CmsRoutesDto.EmailTemplates,
    component: EmailTemplateEditComponent,
    data: [{ menuType: MenuItemTypesDto.CMS }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
  {
    path: CmsRoutesDto.EmailTemplates + "/:id",
    component: EmailTemplateEditComponent,
    data: [
      {
        menuType: MenuItemTypesDto.CMS,
        inheritRolesFrom: CmsRoutesDto.EmailTemplates,
      },
    ],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [EmailTemplateEditComponent, EmailTemplateEditTemplateComponent],
  imports: [CommonModule, FormsModule, RouterModule.forChild(routes), HttpClientModule, HtmlEditorModule, EmailModule, LanguageModule, EditToggleModule, LanguageItemEditorModule],
  providers: [EmailTemplateEditService],
  exports: [EmailTemplateEditComponent, EmailTemplateEditTemplateComponent],
})
export class EmailTemplateEditModule {}
