import { Observable, of } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { UserInformationValuesDto } from "../../common/models/dto/UserInformationValues-dto";
import { TokenData } from "../baseservice/_models/token.model";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { HeaderInterceptor } from "../baseservice/httpinterceptor";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { BrowserStorage } from "../storage/browser-storage";
import { BrowserStorageType } from "../storage/browser-storage-type";

@Injectable()
export class UserInformationService {
  public constructor(public http: HttpClient, public envSettings: EnvironmentSettings) {}

  public checkUserStatus(): Promise<boolean> {
    if (PlUserStorage.hasUser() === false) {
      console.log("No current user set");
      return Promise.resolve(true);
    } else {
      console.log("Current user set, check if it is still valid on the server.");
      return this.retrieveCurrentUserData(PlUserStorage.token)
        .pipe(
          map((r) => {
            if (r) {
              if (r.Authenticated === false) {
                console.log("Retrieved current user with current token; it is no longer valid.");
                PlUserStorage.logOut();
                return false;
              } else {
                return true;
              }
            } else {
              console.log("Failed to retrieve current user with current token; it now marked as invalid.");
              PlUserStorage.logOut();
              return false;
            }
          }),
        )
        .toPromise();
    }
  }

  public reloadCurrentUser(): Observable<boolean> {
    if (PlUserStorage.hasUser()) {
      return this.retrieveCurrentUserValue(PlUserStorage.token);
    } else {
      return of(false);
    }
  }

  public retrieveCurrentUserData(temptoken: TokenData): Observable<UserInformationValuesDto> {
    // Due to a cyclic dependency we can not user our 'Services' class but have to use
    // http directly.
    console.log("retieve user details");

    const apiUri = this.envSettings.combinePath(this.envSettings.baseUri(), "users");
    const header = HeaderInterceptor.getOptionsCustomToken(temptoken);

    const userDataGet = this.http
      .get<UserInformationValuesDto>(apiUri, { headers: header.headers, withCredentials: header.withCredentials, observe: "response" })
      .pipe(
        map((response) => {
          const user = response.body;
          if (user === undefined || user.Authenticated === false) {
            console.log("User is not authenticated");
            return undefined;
          } else {
            console.log("Succesfully retrieved user " + user.Username);
            return response.body;
          }
        }),
      );

    return userDataGet;
  }

  public retrieveCurrentUserValue(temptoken: TokenData): Observable<boolean> {
    const userGetAndSet = this.retrieveCurrentUserData(temptoken).pipe(
      map((user) => {
        if (user === undefined || user.Authenticated === false) {
          console.log("User is not authenticated");
          return false;
        } else {
          console.log("Succesfully retrieved user " + user.Username);
          this.IfSessionStorageKeepModelFromSession(user);
          PlUserStorage.store(user.Username, temptoken, user as any);
          return true;
        }
      }),
    );

    return userGetAndSet;
  }

  private IfSessionStorageKeepModelFromSession(user: UserInformationValuesDto) {
    if (BrowserStorage.currentStorageType() === BrowserStorageType.Session) {
      user.PreferredModel = PlUserStorage.user.PreferredModel;
    }
  }

  public resetUserStorage(): void {
    PlUserStorage.logOut();
  }

  public updateUserInformation(dto: UserInformationValuesDto): Observable<boolean> {
    console.log("Updating user information");

    const apiUri = this.envSettings.combinePath(this.envSettings.baseUri(), "users");
    const userPut = this.http.put<boolean>(apiUri, dto).pipe(
      map((response) => {
        if (response == true) {
          console.log("User information update successful");
          PlUserStorage.store(dto.Username, PlUserStorage.token, dto);
          return true;
        }
        return false;
      }),
    );

    return userPut;
  }
}
