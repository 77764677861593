
// t4tsCodeFromCs": "0.8.2.0 for file DataSetWellKnownVariables.cs
// Don't adjust manually!

export class DataSetWellKnownVariablesDto {
    public static TechnicalIdentificationColumns: string [] = [];
    public static MutationColumns: string [] = [];
    public static SystemControlledMutationColumns: string [] = [];
    public static CumulativePeriodFieldName: string = "CumulPeriod";
    public static VariantIdColumnName: string = "Varia_ID";
    public static PersonTypeColumnName: string = "PersonType";
    public static MutationIdColumnName: string = "MutationId";
    public static StartDateColumnName: string = "StartDate";
    public static EndDateColumnName: string = "EndDate";
    public static MutationReasonColumnName: string = "MutationSource";
    public static MutationChangeDateColumnName: string = "MutationDate";
}

