import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ISortOrder, Sorter } from "../../sorting";

import { ItemReorderedEvent } from "devextreme/ui/list";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { IMove } from "./imove";

@Component({
  selector: "app-list-selector",
  templateUrl: "./listselector.component.html",
})
export class ListSelectorComponent {
  @Output()
  public valuesChanged = new EventEmitter<boolean>(true);
  @Output()
  public sortOrderChanged = new EventEmitter<any[]>(true);
  selectAllModeValue: string = "page";
  selectionModeValue: string = "all";

  constructor(public language: WebLanguage) {
    this.selectedInUseItems = [];
  }

  @Input()
  public moveImplementation: IMove;

  @Input() public set itemsInUse(value: any[]) {
    Sorter.sortSortOrderThenAlpha(value, (i) => i.shortName);
    this._itemsInUse = value;
  }

  @Input()
  public availableItems: any[] = [];
  @Input()
  public displayValue: string;
  @Input()
  public searchValue: string;

  private _itemsInUse: any[] = [];
  public get itemsInUse() {
    return this._itemsInUse;
  }

  private _selectedAvailableItems: any[] = [];
  public get selectedAvailableItems(): any[] {
    return this._selectedAvailableItems;
  }
  public set selectedAvailableItems(newitems: any[]) {
    this._selectedAvailableItems = newitems;
  }

  private _selectedInUseItems: any[];
  public get selectedInUseItems(): any[] {
    return this._selectedInUseItems;
  }
  public set selectedInUseItems(v: any[]) {
    this._selectedInUseItems = v;
  }

  public onItemReordered(val: ItemReorderedEvent) {
    this.itemsInUse.splice(val.fromIndex, 1);
    this.itemsInUse.splice(val.toIndex, 0, val.itemData);

    this.sortOrderChanged.emit(this.itemsInUse);
  }

  updateSortOrder(newSetofElements: any[]) {
    newSetofElements.forEach((element) => {
      if (this.typeGuardForSortOrder(element)) {
        element.sortOrder = newSetofElements.indexOf(element);
      }
    });
  }

  private typeGuardForSortOrder(object: any): object is ISortOrder {
    if (object as ISortOrder) {
      return true;
    }
    return false;
  }

  public retrieveDisplayValue(data) {
    return data[this.displayValue];
  }

  public addItems() {
    if (this.moveImplementation.moveRight(this.selectedAvailableItems)) {
      this.valuesChanged.emit(true);
      this.selectedAvailableItems = [];
    }
  }
  public removeItems() {
    if (this.moveImplementation.moveLeft(this.selectedInUseItems)) {
      this.valuesChanged.emit(true);
      this.selectedInUseItems = [];
    }
  }
}
