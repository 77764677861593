import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { InternalLinkDto } from "../../models/cms/dto/InternalLink-Dto";
import { LinkDto } from "../../models/cms/dto/Link-dto";
import { Link } from "../links/link";
import { InternalLink } from "./internallink";

@Injectable()
export class LinkService extends PlBaseService {
  private endPoint: string = "link";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private selectLanguage: PlLanguageSelection) {
    super(http, envSettings, loadIndicator);
  }

  public storeLink(link: Link): Observable<LinkDto> {
    return this.update(link.toDTO(), this.endPoint);
  }

  public getLink(id: number): Observable<Link> {
    const url = this.endPoint + "/" + id;
    return this.getDataTransformed<LinkDto>(url).pipe(map((r) => this.extractLink(r)));
  }

  extractLink(dto: LinkDto): Link {
    if (dto) {
      const result = new Link(this.selectLanguage);
      result.copyFromDTO(dto);
      return result;
    } else {
      return null;
    }
  }

  public getInternalLinks(): Observable<InternalLink[]> {
    return this.getDataTransformed<InternalLinkDto[]>(this.endPoint).pipe(map((r) => this.transformData(r)));
  }
  transformData(dto: InternalLinkDto[]): InternalLink[] {
    if (dto) {
      const result: InternalLink[] = [];
      dto.forEach((link) => {
        const newLink = new InternalLink(this.selectLanguage);
        newLink.text = link.Text;
        newLink.location = link.Location;
        newLink.isNavigationRoute = link.IsNavigationRoute;
        result.push(newLink);
      });
      return result;
    } else {
      return null;
    }
  }
}
