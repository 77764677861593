import { Injectable } from "@angular/core";
import { AppComponentSpecificSpecification } from "./appcomponentspecific-component-specification";
import { AppComponentSpecificContentType } from "./appcomponentspecific-content-type";
import { AppComponentSpecificEmptyComponent } from "./appcomponentspecific-empty.component";

@Injectable()
export class AppComponentSpecificComponentFactory {
  private static emptyComponent = new AppComponentSpecificSpecification(AppComponentSpecificContentType.None, AppComponentSpecificEmptyComponent);

  private static componentDefintions: AppComponentSpecificSpecification[] = [];

  public retrieveComponent(type: AppComponentSpecificContentType) {
    const ofType = AppComponentSpecificComponentFactory.componentDefintions.filter((c) => c.componentType === type);

    if (ofType.length === 0) {
      console.log("No component of specific type registered, returning empty instead of " + AppComponentSpecificContentType[type]);
      return AppComponentSpecificComponentFactory.emptyComponent;
    }

    const componentDefintion = ofType[ofType.length - 1];
    return componentDefintion;
  }

  public static registerComponent(registration: AppComponentSpecificSpecification) {
    AppComponentSpecificComponentFactory.componentDefintions.push(registration);
  }
}
