import { ExogenousVariable, Formula } from "../../../common/modelelements";

import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { TimePeriod } from "../../../common/modelelements/timeperiod-model";
import { ElementTypeDto } from "../../../common/models/dto/ElementType-dto";
import { ModelPresentableElementDto } from "../../../common/models/dto/ModelPresentableElement-dto";
import { TimePeriodDto } from "../../../common/models/dto/TimePeriodDto-dto";
import { ModelCategory } from "../../modelelements/categories/model-category";
import { ModelCategoryDto } from "../../models/dto/ModelCategoryDto-dto";
import { VariantDto } from "../../models/dto/VariantDTO-dto";
import { ModelPresentableElement } from "./model-presentablel-element-model";
import { Variant } from "./variant-model";

export class ModelElementFactory {
  public static create(dto: ModelPresentableElementDto) {
    if (dto) {
      let element: ModelPresentableElement;
      if (dto.TypeOfElement === ElementTypeDto.Variant) {
        element = new Variant();
      } else if (dto.TypeOfElement === ElementTypeDto.Exogenous) {
        element = new ExogenousVariable();
      } else if (dto.TypeOfElement === ElementTypeDto.Formula) {
        element = new Formula();
      } else {
        element = new ModelPresentableElement(ElementTypeDto.Unknown);
      }

      element.copyFromDto(dto);
      return element;
    } else {
      return new ModelPresentableElement(ElementTypeDto.Unknown);
    }
  }

  public static createVariant(dto: VariantDto) {
    var element = new Variant();
    element.copyFromDto(dto);
    return element;
  }

  public static createPeriod(dto: TimePeriodDto) {
    var element = new TimePeriod();
    if (dto) {
      const nr = (dto.Year || new Date().getFullYear()) * 100 + (dto.PeriodNumber || 1);
      element.id = nr;
      element.sortOrder = nr;
      element.shortName = nr.toString();
      element.longName = dto.YearPeriodText;
      element.startdate = dto.TimePeriodStartDate;
      element.enddate = dto.TimePeriodEndDate;
    }

    return element;
  }

  public static createModelCategory(dto: ModelCategoryDto): ModelCategory {
    const modelCategory = new ModelCategory();

    if (ElementHelper.isNullOrUndefined(dto)) {
      return modelCategory;
    }

    modelCategory.copyFromDto(dto);
    return modelCategory;
  }
}
