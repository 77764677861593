import { Component, Input, OnInit, Predicate, ViewChild } from "@angular/core";

import { alert } from "devextreme/ui/dialog";
import { AuthorizationLanguage } from "../../../../../common/language/Authorizationlanguage.service";
import { UserDeleteResultTypeDto } from "../../../../../common/models/dto/UserDeleteResultType-dto";
import { AuthorizationContentType } from "../../models/authorization-content.types";
import { Role } from "../../models/authorization-role-model";
import { AuthorizationRolesService } from "../../services/authorization-roles.service";
import { User } from "../../services/models/authorization-user-model";
import { UserStatus } from "../../services/models/authorization-user-status-model";
import { AuthorizationRolesTabContent } from "../tabs/authorization-tabs.tab-content-roles";
import { UserListComponent } from "./list/authorization-content.component-userlist";

@Component({
  selector: "app-authorization-content-users",
  templateUrl: "./authorization-content.component-users.html",
  styleUrls: ["./authorization-content.component-users.scss"],
})
export class AuthorizationUsersComponent implements OnInit {
  @ViewChild(UserListComponent) userList: UserListComponent;

  constructor(public language: AuthorizationLanguage, private usersService: AuthorizationRolesService) {
    this.saveUser = this.saveUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  ngOnInit() {
    this._tabContent = new AuthorizationRolesTabContent(this.usersService, this.authorizationContentType);
  }

  public get changeRolesTitle() {
    let name = "..";
    if (this.selectedUser) {
      name = this.selectedUser.userNameIfNoLongname;
    }

    return this.language.authorizationRolesTreeViewTitle(name);
  }

  public get tabContent(): AuthorizationRolesTabContent {
    return this._tabContent;
  }
  private _tabContent: AuthorizationRolesTabContent;

  @Input()
  authorizationContentType: AuthorizationContentType;
  public authorizationContentTypes = AuthorizationContentType;

  public get selectedUser(): User {
    return this._selectedUser;
  }
  public set selectedUser(newUser: User) {
    this._selectedUser = newUser;
  }
  private _selectedUser: User;

  public get selectedUserRoles(): Role[] {
    if (this.selectedUser !== undefined && this.selectedUser !== null && this.selectedUser.assignedRoles && this.selectedUser.assignedRoles !== null) {
      return this.selectedUser.assignedRoles;
    }
    return [];
  }

  public selectedUserRolesChanged(roles: Role[]) {
    if (this.selectedUser !== undefined && this.selectedUser !== null) {
      this.selectedUser.assignedRoles = roles;
      this.saveUser();
    }
  }

  public saveUser() {
    const user = this.selectedUser;
    const userDto = User.copyToDto(user);

    if (user.id) {
      this.tabContent.authorizationService.saveExistingUser(userDto).subscribe(() => {
        this.updateUserRoles(user);
      });
    } else {
      this.tabContent.authorizationService.saveNewUser(userDto).subscribe((result) => {
        if (result.data !== null) {
          user.id = result.data.KeyId;
        }
        this.updateUserRoles(user);
      });
    }
  }

  public deleteUser(user: User) {
    if (user.id) {
      this.tabContent.authorizationService.deleteUser(user.id).subscribe((result) => {
        const index = this.retrieveIndexFromUserInList((x) => x.userName === user.userName);

        if (index === -1) {
          return;
        }

        if (result === UserDeleteResultTypeDto.Deleted) {
          this.removeUser(index);
        } else if (result === UserDeleteResultTypeDto.Inactive) {
          this.selectFirstUser(index);

          const foundUser = this.tabContent.users[index];
          foundUser.status = UserStatus.Inactive;

          if (this.selectedUser.id === foundUser.id) {
            this.selectedUser = foundUser;
          }
          alert(this.language.authorizationRolesActionsDisableAlertMessage, this.language.authorizationRolesActionsDisableAlertHeader);
        }
      });
    } else {
      const index = this.retrieveIndexFromUserInList((x) => x.userName === user.userName);
      this.removeUser(index);
    }
  }

  private retrieveIndexFromUserInList(key: Predicate<User>): number {
    const foundUser = this.tabContent.users.find(key);
    if (foundUser === undefined) {
      return -1;
    }

    return this.tabContent.users.indexOf(foundUser);
  }

  private removeUser(index: number) {
    this.selectFirstUser(index);
    this.tabContent.users.splice(index, 1);
  }

  private selectFirstUser(index: number) {
    if (this.tabContent.users.length > 1) {
      const newSelectedUserIndex = index !== 0 ? 0 : 1;
      this.userList.selectUser(newSelectedUserIndex);
      this.selectedUser = this.tabContent.users[newSelectedUserIndex];
    }
  }

  public updateUserRoles(user: User) {
    const index = this.tabContent.users.indexOf(user);
    this.tabContent.users[index] = user;
  }

  public undoChanges() {
    const foundUser = this.tabContent.users.find((x) => x.id === this.selectedUser.id);
    this.selectedUser = foundUser;
  }
}
