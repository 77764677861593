import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxToastModule, DxTooltipModule } from "devextreme-angular";
import { RatingIconComponent } from "./rating-icon.component";
import { RatingIdService } from "./rating-id.service";
import { RatingComponent } from "./rating.component";
@NgModule({
    imports: [CommonModule, DxTooltipModule, DxToastModule],
    exports: [RatingComponent, RatingIconComponent],
    declarations: [RatingComponent, RatingIconComponent],
    providers: [RatingIdService],
})
export class RatingModule { }
