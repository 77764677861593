import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { ExogenousInputOptionChoiceDto } from "../../../../models/me/dto/ExogenousInputOptionChoice-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ExogenousInputOptionChoiceValue } from "./exogenous-input-option-choice-value.model";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";
import { ExogenousInputOptionType } from "./option-type.model";

export class ExogenousInputOptionChoice extends ExogenousInputOption {
  public constructor() {
    super(ExogenousInputOptionType.Choice);

    this.values = new Array<ExogenousInputOptionChoiceValue>();
  }

  private _value: ExogenousInputOptionChoiceValue;
  public get value(): ExogenousInputOptionChoiceValue {
    return this._value;
  }
  public set value(newValue: ExogenousInputOptionChoiceValue) {
    if (ElementHelper.isNullOrUndefined(this.value) || this._value?.identification !== newValue.identification) {
      this._value = newValue;
      this.changed();
    }
  }

  public values: ExogenousInputOptionChoiceValue[];

  public copyFromDTO(dto: ExogenousInputOptionChoiceDto) {
    super.copyFromDTO(dto);

    const newValues = new Array<ExogenousInputOptionChoiceValue>();
    if (dto.Values === undefined) {
      dto.Values = [];
    }
    dto.Values.forEach((v) => {
      const choice = new ExogenousInputOptionChoiceValue();
      choice.copyFromDTO(v);
      newValues.push(choice);
    });

    if (dto.Value && dto.Value !== null) {
      const currentValue = newValues.find((x) => x.identification === dto.Value.Identification);
      if (currentValue && currentValue !== null) {
        this._value = currentValue;
      }
    }

    this.values = newValues;
  }

  public toDTO(dto: ModifiedExogenousInputOptionDto) {
    super.toDTO(dto);
    dto.Type = ExogenousInputOptionTypes.typeChoice;
    if (ElementHelper.isNullOrUndefined(this._value) === false) {
      dto.Value = this._value.identification.toString();
    }
  }
}
