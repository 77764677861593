<div *ngIf="currentEbsConfig">
  <dx-data-grid
    [dataSource]="currentEbsConfig"
    autoWidth="true"
    [showBorders]="true"
    (onInitNewRow)="onInitNewRow($event)"
    (onEditingStart)="onEditingStart($event.data)"
    (onRowUpdated)="onRowUpdated($event.data, $event.component)"
    (onRowInserted)="onRowInserted($event, $event.component)"
    (onRowRemoved)="onRowDeleted($event)"
  >
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"> </dxo-editing>
    <dxi-column dataField="ShortName" [caption]="language.modelShortName"></dxi-column>
    <dxi-column dataField="VariantId" [caption]="language.VariantNameHeader">
      <dxo-lookup [dataSource]="variants" displayExpr="name" valueExpr="id"> </dxo-lookup>
    </dxi-column>
    <dxi-column dataType="boolean" dataField="IsSummery" [caption]="language.f3MeWebApiEbsIntroIsSummery"> </dxi-column>
    <dxi-column dataField="CmsTitleCopy" [caption]="language.f3MeWebApiEbsIntroEditHeader" editCellTemplate="titleEditCellTemplate" [calculateDisplayValue]="title"></dxi-column>
    <dxi-column [caption]="language.f3MeWebApiEbsEditTreeHeader" cellTemplate="buttonCellTemplate"> </dxi-column>
    <div *dxTemplate="let d of 'buttonCellTemplate'">
      <dx-button (click)="editTree(d)" class="rightalign mr-1" icon="fas fa-edit" [hint]="language.ButtonEdit"> </dx-button>
    </div>
    <div *dxTemplate="let d of 'titleEditCellTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.CmsTitleCopy"></app-languageitem-editor-grid>
    </div>
  </dx-data-grid>
</div>

<dx-popup [width]="300" [height]="200" [showTitle]="true" [title]="language.InvalidShortname" [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <label>{{ language.ShortnameModifiedNotification }}</label>
</dx-popup>
