<div>
  <img *ngIf="fileUpload.hasFile" [src]="fileUpload.fileData" #imageElement (load)="setUploadText()" />
  <div class="image-actions">
    <label aria-hidden="true" *ngIf="readOnly === false">
      <dx-button icon="edit" [text]="language.f3MeWebApiImageEdit" *ngIf="hasFileReady" (click)="popupVisible = true">
      </dx-button>
      <dx-button icon="upload" [text]="language.ImageUpload" *ngIf="hasFileNotReady"></dx-button>
      <input *ngIf="hasFileNotReady" class="file-input span-label" type="file" #selectedImage id="imageMessage"
        (change)="fileUpload.fileChange(input)" [accept]="fileUpload.allowedFileTypesAsString" #input />
    </label>
    <dx-button class="button-spacing" icon="fa fa-trash" (click)="fileUpload.deleteFile()"
      *ngIf="canDelete && fileUpload.hasFile && readOnly === false"> </dx-button>
  </div>
</div>

<dx-popup maxWidth="1300" minHeight="700" maxHeight="1000" [resizeEnabled]="true" [title]="language.f3MeWebApiImageCrop"
  [dragEnabled]="true" [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="popup-content">
      <dx-scroll-view showScrollbar="always">
        <div class="actions">
          <label aria-hidden="true" *ngIf="readOnly === false">
            <span class="fileWarning span-label" *ngIf="this.fileUpload.showFileSizeWarning === true">{{
              maxSizeWarningMessage }}</span>
            <span class="fileWarning span-label" *ngIf="this.fileUpload.showFileExtensionWarning === true">{{
              fileTypeWarningMessage }}</span>
            <input class="file-input span-label" type="file" #selectedImage id="imageMessage"
              (change)="fileUpload.fileChange(input)" [accept]="fileUpload.allowedFileTypesAsString" #input />
          </label>
        </div>
        <app-pl-image-cropper [aspectRatio]="aspectRatio" [imageUrl]="fileUpload.uncroppedImage || fileUpload.fileData"
          (cropperData)="saveCrop($event)"></app-pl-image-cropper>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>