import { LabourConditionLinkDto } from "../../models/cms/dto/LabourConditionLinkDto-dto";

export class LabourConditionLink {
  public static createDefault() {
    const newLink = new LabourConditionLink();
    newLink.cmsMessageId = null;
    newLink.confirmationEmailId = null;
    newLink.emailTemplateId = null;
    newLink.targetId = null;

    return newLink;
  }

  public static toDto(value: LabourConditionLink): LabourConditionLinkDto {
    const dto = new LabourConditionLinkDto();
    dto.KeyId = value.keyId;
    dto.CMSMessageId = value.cmsMessageId;
    dto.ConfirmationEmailId = value.confirmationEmailId;
    dto.EmailTemplateId = value.emailTemplateId;
    dto.TargetId = value.targetId;

    return dto;
  }

  public keyId: number;
  public cmsMessageId: number;
  public targetId: number;
  public emailTemplateId: number;
  public confirmationEmailId: number;

  public fromDto(dto: LabourConditionLinkDto) {
    this.keyId = dto.KeyId;
    this.cmsMessageId = dto.CMSMessageId;
    this.confirmationEmailId = dto.ConfirmationEmailId;
    this.emailTemplateId = dto.EmailTemplateId;
    this.targetId = dto.TargetId;
  }
}
