import { LanguageEditItemDto } from "../../../models/cms/dto/LanguageEditItem-dto";
import { LanguageEditItemRowData } from "./language-edit-item-rowdata.model";

export class LanguageEditItemRow {
  private static languageItemToRowData(item: LanguageEditItemDto): LanguageEditItemRowData {
    const result = new LanguageEditItemRowData();
    result.copyFromDto(item);
    return result;
  }
  public static createFor(item: LanguageEditItemDto): LanguageEditItemRow {
    const result = new LanguageEditItemRow();
    result.original = this.languageItemToRowData(item);
    result.copy = this.languageItemToRowData(item);
    return result;
  }
  public static createEmpty(): LanguageEditItemRow {
    const result = new LanguageEditItemRow();
    result.original = new LanguageEditItemRowData();
    result.copy = new LanguageEditItemRowData();
    return result;
  }
  public constructor() {}

  public original: LanguageEditItemRowData;
  public copy: LanguageEditItemRowData;

  public acceptChanges() {
    this.original.copyFrom(this.copy);
  }
}
