import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { RolesMetaDataDto } from "../../../../common/models/dto/RolesMetaData-dto";
import { UserDeleteResultTypeDto } from "../../../../common/models/dto/UserDeleteResultType-dto";
import { UserDto } from "../../../../common/models/dto/UserDto-dto";
import { PlAuthenticationService } from "../../../authentication/authentication.service";
import { EntryCreated } from "../../../baseservice/entry-created";
import { EnvironmentSettings } from "../../../baseservice/environment-settings";
import { PlBaseService } from "../../../baseservice/plbase.service";
import { PlUserStorage } from "../../../baseservice/pluserstorage";
import { PlLoadIndicator } from "../../../loadindicator/loadindicator";

@Injectable()
export class AuthorizationRolesService extends PlBaseService {
  private endPoint: string = "AuthorizationRoles";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator, private authenticationService: PlAuthenticationService) {
    super(http, envSettings, loadIndicator);
  }

  public getAuthorizationRolesMetaData(): Observable<RolesMetaDataDto> {
    return this.getData<RolesMetaDataDto>(this.endPoint);
  }

  public saveExistingUser(dto: UserDto): Observable<void> {
    const url = this.endPoint + "/" + dto.KeyId;
    const result: Observable<void> = this.update(dto, url);

    return result.pipe(
      tap(() => {
        if (PlUserStorage.isCurrentUserNameEqualTo(dto.ShortName)) {
          this.authenticationService.refreshUserData().subscribe();
        }
      }),
    );
  }

  public saveNewUser(dto: UserDto): Observable<EntryCreated<UserDto>> {
    return this.createData<UserDto>(this.endPoint, dto);
  }

  public deleteUser(dtoId: number): Observable<UserDeleteResultTypeDto> {
    const url = this.endPoint + "/" + dtoId;
    return this.remove(url);
  }
}
