import { EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ExogenousInputOption } from "./exogenous-input-option.model";

export class ExogenousInputOptionObserver {
  private subscriptions: { [identifier: number]: Subscription } = {};

  public constructor() {}

  public register(option: ExogenousInputOption) {
    const subscription = option.onChanged.pipe(debounceTime(500)).subscribe(() => this.doOptionChanged());
    this.subscriptions[option.identification] = subscription;
  }

  public remove(option: ExogenousInputOption) {
    const id = option.identification;
    if (id in this.subscriptions) {
      const subscription = this.subscriptions[id];
      subscription.unsubscribe();
      delete this.subscriptions[id];
    }
  }

  public clear() {
    for (const id in this.subscriptions) {
      if (id) {
        const subscription = this.subscriptions[id];
        subscription.unsubscribe();
      }
    }
    this.subscriptions = {};
  }

  private doOptionChanged() {
    this.optionChanged();
  }

  public onOptionChanged = new EventEmitter();
  protected optionChanged() {
    this.onOptionChanged.emit();
  }
}
