import { CommonRoutesDto } from "../../../../common/models/dto/CommonRoutes-dto";
import { RouteLocations } from "../../../route-locations";

export class RouteData {
  public constructor(public path: string) {}

  public get hasPath(): boolean {
    return this.path && this.path !== null && this.path !== "";
  }

  public get isLoginPage(): boolean {
    if (this.hasPath && RouteLocations.routesAreSame(this.path, CommonRoutesDto.Login)) {
      return true;
    }
    return false;
  }
}
