import { Component, OnInit, ViewChild } from "@angular/core";
import { alert, confirm } from "devextreme/ui/dialog";

import { DxListComponent, DxValidationGroupComponent } from "devextreme-angular";
import { AuthorizationLanguage } from "../../../../../common/language/Authorizationlanguage.service";
import { RoleHierarchyDeleteResultTypeDto } from "../../../../../common/models/dto/RoleHierarchyDeleteResultType-dto";
import { AuthorizationRolesMaintenanceService } from "../../services/authorization-rolesmaintenance.service";
import { RoleHierarchyList } from "../../services/models/authorization-roleHierarchy-list-model";
import { RoleHierarchy } from "../../services/models/authorization-roleHierarchy-model";
import { AuthorizationRolesMaintenanceTabContent } from "../tabs/authorization-tabs.tab-content-rolesmaintenance";

@Component({
  selector: "app-authorization-content-rolesmaintenance",
  templateUrl: "./authorization-content-component-rolesmaintenance.html",
  styleUrls: ["./authorization-content-component-rolesmaintenance.scss"],
})
export class AuthorizationRolesMaintenanceComponent implements OnInit {
  @ViewChild(DxListComponent) rolesList: DxListComponent;
  @ViewChild("roleValidationGroup") roleValidationGroup: DxValidationGroupComponent;

  public constructor(public language: AuthorizationLanguage, private rolesMaintenanceService: AuthorizationRolesMaintenanceService) {}

  ngOnInit() {
    this._tabContent = new AuthorizationRolesMaintenanceTabContent(this.rolesMaintenanceService);
    this.tabContent.getRoles().subscribe(() => {
      this.rolesHierarchy = this.tabContent.roles;
    });
  }

  public rolesHierarchy: RoleHierarchyList;

  public get tabContent(): AuthorizationRolesMaintenanceTabContent {
    return this._tabContent;
  }
  private _tabContent: AuthorizationRolesMaintenanceTabContent;

  public get selectedRoleHierarchy(): RoleHierarchy {
    return this._selectedRoleHierarchy;
  }
  public set selectedRoleHierarchy(newRoleHierarchy: RoleHierarchy) {
    this._selectedRoleHierarchy = newRoleHierarchy;
    this._canDelete = this.canDeleteHierarchy(newRoleHierarchy);
  }
  private _selectedRoleHierarchy: RoleHierarchy;

  private canDeleteHierarchy(roleHierarchy: RoleHierarchy): boolean {
    if (!roleHierarchy || roleHierarchy === null || !this.tabContent || this.tabContent === null) {
      return false;
    }

    if (this.tabContent.roleIsInUse(roleHierarchy.role)) {
      return false;
    }

    return true;
  }

  public get canDelete(): boolean {
    return this._canDelete;
  }
  private _canDelete: boolean = false;

  public selectionChanged(e) {
    let newRoleHierarchy: RoleHierarchy;
    if (e.addedItems.length > 0) {
      newRoleHierarchy = e.addedItems[0];
    }
    this.selectedRoleHierarchy = newRoleHierarchy;
  }

  public saveRoleHierarchy(roleHierarchy: RoleHierarchy) {
    this.rolesMaintenanceService.saveRoleHierarchy(roleHierarchy);
  }

  private selectInUI(roleHierarchy: RoleHierarchy) {
    this.rolesList.instance.reload();
    this.rolesList.instance.selectItem(roleHierarchy);
  }

  private addNewRoleAndSelect(name: string) {
    this.selectedRoleHierarchy = this.rolesHierarchy.addNew(name);
    this.selectInUI(this.selectedRoleHierarchy);
  }

  public addRole() {
    this.addNewRoleAndSelect(this.language.authorizationRolesMaintenanceNewRoleName);
  }

  public copyRole() {
    this.addNewRoleAndSelect(this.selectedRoleHierarchy.role.name);
  }

  private removeSelectedRoleHierarchy() {
    this.rolesHierarchy.delete(this.selectedRoleHierarchy);
    this.selectedRoleHierarchy = undefined;
    this.rolesList.instance.reload();
    this.roleValidationGroup.instance.reset();
  }

  public deleteRole() {
    confirm(this.language.confirmDeleteHeader, this.language.confirmDeleteTitle).then((deleteResult) => {
      if (deleteResult) {
        if (this.selectedRoleHierarchy.role.isNew) {
          this.removeSelectedRoleHierarchy();
        } else {
          this.rolesMaintenanceService.deleteRoleHierarchy(this.selectedRoleHierarchy.role.id).subscribe((result) => {
            if (result === RoleHierarchyDeleteResultTypeDto.Deleted) {
              this.removeSelectedRoleHierarchy();
            } else if (result === RoleHierarchyDeleteResultTypeDto.InUse) {
              alert(this.language.authorizationRolesMaintenanceDeleteInUseMessage, this.language.authorizationRolesMaintenanceNotDeletedHeader);
            } else if (result === RoleHierarchyDeleteResultTypeDto.None) {
              alert(this.language.authorizationRolesMaintenanceDeleteUnknownErrorMessage, this.language.authorizationRolesMaintenanceNotDeletedHeader);
            }
          });
        }
      }
    });
  }
}
