<div class="header">
  <h1 class="col-md-4 col-sm-12 col-xs-12">{{languageService.f3MeWebApiEbsOverview}}</h1>
  <div class="col-sm-7 col-xs-12">
    <p appSafeData [passedHtmlData]="languageService.f3MeWebApiEbsOverviewIntroText"></p>
  </div>
  <div class="col-sm-1 col-xs-12">
    <i class="fa fa-print fa-2x" aria-hidden="true" (click)="printEBSOverview()"></i>
  </div>
</div>

<div class="ebs-ids" *ngFor="let ebsOverviewDto of ebsOverviewDtos">
  <div class="cmsTextContainer">
    <h1 class="col-md-4 col-sm-12 col-xs-12">{{ebsOverviewDto.cmsTitel}}</h1>
    <div class="col-sm-8 col-xs-12">
      <p *ngIf="ebsOverviewDto.cmsIntro" appSafeData [passedHtmlData]="ebsOverviewDto.cmsIntro"></p>
    </div>
  </div>

  <div class="ebsSeperationLine"></div>
  <app-ebs-doughnut-component [ebsOverviewDto]="ebsOverviewDto" [showPrintButton]="false"></app-ebs-doughnut-component>
</div>
