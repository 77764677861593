import { Injectable } from "@angular/core";
import { Link } from "./link";
import { LinkTypeDto } from "../../models/cms/dto/LinkType-dto";
import { Router } from "@angular/router";

@Injectable()
export class LinkHandler {
  public linkType = LinkTypeDto;

  public constructor(private router: Router) {}

  public go(link: Link) {
    console.log("navigating to " + link.location);
    if (link.linkType === this.linkType.InternalLink || link.linkType === this.linkType.PopupLink) {
      this.router.navigate([link.location]);
    } else if (link.linkType === this.linkType.ExternalLink) {
      window.open(link.location, "_blank");
    } else {
      console.log("Unable to navigate to unknown link type");
    }
  }
}
