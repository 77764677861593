import { Component, OnInit } from "@angular/core";
import { TextPopupInteraction } from "../../../angular-common";
import { RouteNavigation } from "../../../angular-common/baseservice/route-navigation";
import { EmployeeAuthenticationService } from "../../auth/employee-auth.service";
import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { ImageService } from "../images/image.service";
import { UserSettingsService } from "../usersettings/usersettings.service";

@Component({
  selector: "app-user-acknowledgement",
  templateUrl: "./user-acknowledgement.component.html",
  styleUrls: ["../../../angular-common/components/login/_login.scss"],
})
export class UserAcknowledgementComponent implements OnInit {
  public model: any = {};
  public loading = false;
  public error = "";

  public constructor(
    public imageService: ImageService,
    public languageService: CmsLanguage,
    private routeNavigation: RouteNavigation,
    private authenticationService: EmployeeAuthenticationService,
    private userSettingsService: UserSettingsService,
  ) {
    this.disclaimerPopup = new TextPopupInteraction(
      this.languageService.f3MeWebApiDisclaimerTitle,
      this.languageService.f3MeWebApiDisclaimerMessageLogin,
      this.languageService.f3MeWebApiDisclaimerAccept,
      this.languageService.ButtonCancel,
    );
    this.disclaimerPopup.confirmedChanged.subscribe((confirmed) => this.disclaimerClosed(confirmed));
    this.checkForDisclaimer = this.checkForDisclaimer.bind(this);
  }
  ngOnInit(): void {
    this.userSettingsService.getSettings().subscribe((_) => {
      this.shouldConfirm = this.userSettingsService.requireAcknowledgeDisclaimer;
    });
  }

  public shouldConfirm = true;

  public disclaimerPopup: TextPopupInteraction;

  public checkForDisclaimer() {
    if (this.userSettingsService.requireAcknowledgeDisclaimer === false) {
      console.debug("Disclaimer has been confirmation not required.");
    } else {
      if (this.userSettingsService.acknowledgedDisclaimer === false) {
        this.showDisclaimerPopup();
      } else {
        console.debug("Disclaimer already confirmed.");
      }
    }
  }

  public showDisclaimerPopup() {
    this.disclaimerPopup.isVisible = true;
  }

  private disclaimerClosed(confirmed: boolean) {
    if (confirmed) {
      console.log("Disclaimer has been confirmed.");
      this.confirmDisclaimer();
    } else {
      console.log("Disclaimer not confirmed, logout.");
      this.authenticationService.logout();
    }
  }

  public get userHasAcknowledged() {
    return this.userSettingsService.requireAcknowledgeDisclaimer && this.userSettingsService.acknowledgedDisclaimer;
  }

  private confirmDisclaimer() {
    if (this.userSettingsService.requireAcknowledgeDisclaimer) {
      this.userSettingsService.acknowledgedDisclaimer = true;
    } else {
      console.log("Confirmation not required");
    }

    this.routeNavigation.gotToLandingPage();
  }
}
