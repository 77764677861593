import { Component, OnInit } from "@angular/core";
import { ExogenousVariables } from "../../../common/modelelements/exogenousvariables/exogenous-variables.model";
import { CmsLanguage } from "../language/cmslanguage.service";
import { EditableUserField } from "./models/editable-userfield.model";
import { UserFieldsEditService } from "./userfieldsedit.service";

@Component({
  selector: "app-cms-user-fields-edit",
  templateUrl: "./cms-user-fields-edit.component.html",
  styleUrls: ["./cms-user-fields-edit.component.scss"],
})
export class UserFieldsEditComponent implements OnInit {
  public constructor(private userFieldsEditService: UserFieldsEditService, public language: CmsLanguage) {}

  ngOnInit(): void {
    this.userFieldsEditService.retrieve().subscribe((md) => {
      md.exogenousVariables.sortSortOrderThenAlpha();
      this.exogenousVariables = md.exogenousVariables;

      const newUserFields = [];
      md.userFields.forEach((uf) => {
        const newUserField = new EditableUserField(uf, md.exogenousVariables);
        newUserField.exogAssigned.subscribe((f) => {
          this.updateUserFieldToExegoAssignment(f);
        });
        newUserField.exogRevoked.subscribe((f) => {
          this.removeUserFieldToExegoAssignment(f);
        });
        newUserFields.push(newUserField);
      });
      this.userFields = newUserFields;
    });
  }

  public exogenousVariables: ExogenousVariables;
  public userFields: EditableUserField[];

  public get contentLoaded(): boolean {
    return this.exogenousVariables !== null && this.exogenousVariables !== undefined && this.userFields !== null && this.userFields !== undefined;
  }

  private updateUserFieldToExegoAssignment(field: EditableUserField) {
    this.userFieldsEditService.updateAssignment(field.userField).subscribe();
  }

  private removeUserFieldToExegoAssignment(field: EditableUserField) {
    this.userFieldsEditService.removeAssignment(field.userField).subscribe();
  }
}
