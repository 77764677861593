import { Variant } from "../../../../common/model/models/variant-model";
import { ModelElementSpecification } from "../../../../common/modelelements/modelelementsspecification";
import { RoleDto } from "../../../../common/models/dto/RoleDto-dto";
import { ReportDefinition } from "./reportdefinition";

export class ReportMetaData {
  reports: ReportDefinition[] = [];
  roles: RoleDto[] = [];
  variants: Variant[] = [];
  modelElements: ModelElementSpecification[] = [];
  reportNames: string[] = [];
  allowedFileTypes: string[] = [];
}
