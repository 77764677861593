import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxValidationGroupModule, DxValidationSummaryModule, DxValidatorModule } from "devextreme-angular";
import { AuditingRoutesDto } from "../../../common/models/dto/AuditingRoutes-dto";
import { MenuItemTypesDto } from "../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { canActivateRoleGuard } from "../../authentication-guard/role-guard";
import { AuditLogsComponent } from "./audit-logs.component";
import { auditLogsResolver } from "./audit-logs.resolver";
import { AuditLogsService } from "./audit-logs.service";

const routes: Routes = [
  {
    path: AuditingRoutesDto.AuditLogs,
    component: AuditLogsComponent,
    data: [{ menuType: MenuItemTypesDto.AuditLogs }],
    canActivate: [canActivateRoleGuard],
    resolve: {
      auditLogsData: auditLogsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, DxButtonModule, DxDataGridModule, DxButtonModule, DxValidatorModule, DxValidationGroupModule, DxValidationSummaryModule],
  providers: [AuditLogsService],
  declarations: [AuditLogsComponent],
  exports: [AuditLogsComponent],
})
export class AuditLogsModule {}
