import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { ImageUploadDTO } from "./image-upload-dto.model";

export class ImageChanges {
  public constructor(originalUrl: string, imageData: string = null) {
    this._imageData = ElementHelper.isNullOrUndefined(imageData) ? originalUrl : imageData;
    this._originalUrl = originalUrl;
    this._deleted = false;
    this.setUploaded(false);
  }

  private _originalUrl: string;
  public get originalUrl() {
    return this._originalUrl;
  }

  private _imageData: any;
  public get imageData() {
    return this._imageData;
  }

  private _deleted: boolean;
  public get deleted() {
    return this._deleted;
  }

  private _fileName: string;
  public get fileName() {
    return this._fileName;
  }

  private _fileType: string;
  public get fileType() {
    return this._fileType;
  }

  public get hasOriginalImage(): boolean {
    return this.originalUrl != null && this.originalUrl !== "";
  }

  public get hasImage(): boolean {
    return this.imageData != null && this.imageData !== "";
  }

  public get mustProcess(): boolean {
    return this._deleted || this.uploaded;
  }

  public deleteImage() {
    this._deleted = true;
    this._imageData = null;

    this.setUploaded(false);
  }

  public setImage(newFileName: string, newFileType: string, newImageData: any) {
    this._deleted = false;
    this._fileName = newFileName;
    this._fileType = newFileType;
    this._imageData = newImageData;

    this.setUploaded(true);
  }

  private _uploaded: boolean;
  public get uploaded(): boolean {
    return this._uploaded;
  }

  private setUploaded(newUploaded: boolean) {
    this._uploaded = newUploaded;

    if (!this._uploaded) {
      this._fileName = "";
      this._fileType = "";
    }
  }

  public toDTO(): ImageUploadDTO {
    const result = new ImageUploadDTO();

    result.fileName = this.fileName;
    result.base64Content = this.imageData;

    return result;
  }
}
