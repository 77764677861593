import { RouterModule, Routes } from "@angular/router";
import {
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxValidationSummaryModule,
  DxValidatorModule,
} from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateAuthGuard } from "../../../angular-common/authentication-guard/authentication-guard";
import { ListSelectorModule } from "../../../angular-common/components/listselector/listselector.module";
import { PlLanguageModule } from "../../../angular-common/language/pl-language-module";
import { LanguageItemEditorGridModule } from "../../cms/languageitem-editor-grid/languageitem-editor-grid.module";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { ReportingRoutingDto } from "../../models/reporting/dto/ReportingRouting-dto";
import { ReportingLanguage } from "../language/reporting.service";
import { ReportingLanguageComponent } from "./reporting-language.component";
import { reportingLanguageResolver } from "./reporting-language.resolver";
import { ReportingLanguageService } from "./reporting-language.service";
import { ReportingManagementComponent } from "./reporting-management.component";
import { reportingManagementResolver } from "./reporting-management.resolver";
import { ReportingManagementService } from "./reporting-management.service";

const routes: Routes = [
  {
    path: ReportingRoutingDto.ReportManagement,
    component: ReportingManagementComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateAuthGuard],
    resolve: {
      metaData: reportingManagementResolver,
    },
  },
  {
    path: ReportingRoutingDto.ReportLanguage,
    component: ReportingLanguageComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateAuthGuard],
    resolve: {
      metaData: reportingManagementResolver,
      reportingLanguage: reportingLanguageResolver,
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DxDataGridModule,
    DxButtonModule,
    LanguageItemEditorGridModule,
    DxPopupModule,
    ListSelectorModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxFileUploaderModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxValidationSummaryModule,
    DxValidatorModule,
    PlLanguageModule,
  ],
  exports: [],
  declarations: [ReportingManagementComponent, ReportingLanguageComponent],
  providers: [ReportingManagementService, ReportingLanguage, ReportingLanguageService],
})
export class ReportingManagementModule {}
