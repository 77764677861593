import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import DxDataGrid from "devextreme/ui/data_grid";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../angular-common/language/pl-language-selection-copier";
import { LongNameShortName } from "../../../angular-common/longnameshortname/longname-shortname";
import { Variant } from "../../../common/model/models/variant-model";
import { ModelElementSpecification } from "../../../common/modelelements/modelelementsspecification";
import { ReportInputLanguageItemDto } from "../../models/reporting/dto/ReportInputLanguageItem-dto";
import { ReportingLanguage } from "../language/reporting.service";
import { ReportDefinition } from "./models/reportdefinition";
import { ReportMetaData } from "./models/reportmetadata";
import { ReportingLanguageService } from "./reporting-language.service";

@Component({
  selector: "app-reporting-language",
  templateUrl: "reporting-language.component.html",
  styleUrls: ["reporting-language.component.scss"],
})
export class ReportingLanguageComponent {
  constructor(private route: ActivatedRoute, private reportingLanguageService: ReportingLanguageService, private plLanguageSelection: PlLanguageSelection, public language: ReportingLanguage) {
    this.text = this.text.bind(this);
    this.getReportName = this.getReportName.bind(this);

    this.route.data.subscribe((data: { metaData: ReportMetaData }) => {
      this.reports = data.metaData.reports;
      this.modelElements = data.metaData.modelElements;
      this.variants = data.metaData.variants;
    });

    this.route.data.subscribe((data: { reportingLanguage: ReportInputLanguageItemDto[] }) => {
      this.reportingInputTranslations = data.reportingLanguage;
    });
  }

  private modelElements: ModelElementSpecification[] = [];
  private reports: ReportDefinition[] = [];
  private variants: Variant[] = [];

  public reportingInputTranslations: ReportInputLanguageItemDto[] = [];

  public text(reportiInputLanguageItem: ReportInputLanguageItemDto) {
    return reportiInputLanguageItem.Text[this.plLanguageSelection.currentLanguage];
  }

  computedExegoName(rowData: any): string {
    if (ElementHelper.isNullOrUndefined(rowData) || ElementHelper.isNullOrUndefined(rowData.ReportId)) {
      return this.language.NotFound;
    }

    const report = this.reports.find((r) => r.keyId === rowData.ReportId);

    if (ElementHelper.isNullOrUndefined(report)) {
      return rowData.ShortName;
    }

    const variant = this.variants.find((v) => v.id === report.variantId);

    if (ElementHelper.isNullOrUndefined(variant)) {
      return rowData.ShortName;
    }

    const exego = this.modelElements.find((e) => e.dataSetId === variant.dataSetId && e.shortName === rowData.ShortName);

    if (ElementHelper.isNullOrUndefined(exego)) {
      return rowData.ShortName;
    }

    return LongNameShortName.NameFor(exego);
  }

  getReportName(rowData) {
    const report = this.reports.find((r) => r.keyId === rowData.ReportId);

    if (ElementHelper.isNullOrUndefined(report)) {
      return this.language.NotFound;
    } else {
      return report.title[this.plLanguageSelection.currentLanguage];
    }
  }

  onEditingStart(reportInput: ReportInputLanguageItemDto) {
    PlLanguageSelectionCopier.copyFromOriginal(reportInput);
  }

  onRowUpdated(reportInput: ReportInputLanguageItemDto, dxGrid: DxDataGrid) {
    PlLanguageSelectionCopier.copyToOriginal(reportInput);
    dxGrid.repaint();
    this.reportingLanguageService.updateInputTranslation(reportInput).subscribe();
  }

  onRowDeleted(reportInput: ReportInputLanguageItemDto) {
    this.reportingLanguageService.deleteInputTranslation(reportInput.ReportInputId).subscribe(() => {
      this.reportingLanguageService.retrieveAllInputsTranslations().subscribe((r) => {
        this.reportingInputTranslations = r;
      });
    });
  }
}
