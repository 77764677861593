import { BehaviorSubject } from "rxjs";
import { LanguageMetaDataDto } from "../../common/models/dto/LanguageMetaData-dto";
import { LanguageMetaDatasDto } from "../../common/models/dto/LanguageMetaDatas-dto";
import { LanguageConstants } from "./language-constants";

export class PlLanguageSelector {
  public constructor(public languageData: LanguageMetaDatasDto, private _currentLanguage: string) {
    if (_currentLanguage === undefined) {
      if (languageData !== undefined) {
        _currentLanguage = languageData.Language;
      }
    }
    console.log("Current language:", _currentLanguage);

    this.languageChanged = new BehaviorSubject(_currentLanguage);
    this.currentLanguage = _currentLanguage;
  }

  public get languages(): LanguageMetaDataDto[] {
    return this.languageData.Languages;
  }

  public get currentLanguage(): string {
    if (this._currentLanguage === undefined) {
      this.currentLanguage = LanguageConstants.DEFAULT_LANGUAGE;
    }
    return this._currentLanguage;
  }
  public set currentLanguage(newLanguage: string) {
    const exists = this.languageData.Languages.find(v => {
      return v.Id === newLanguage;
    });
    if (!exists) {
      console.log(newLanguage + " is not a valid language, using default.");

      const firstLanguage = this.languageData.Languages[0];
      if (firstLanguage !== undefined) {
        newLanguage = firstLanguage.Id;
      }
    }

    if (this._currentLanguage !== newLanguage) {
      this._currentLanguage = newLanguage;
      this.languageChanged.next(this._currentLanguage);
    }
  }

  public languageChanged: BehaviorSubject<string>;
}
