<div *ngIf="contentLoaded">
  <div class="panel-with-header-and-content">
    <div class="panel-content">
      <dx-data-grid
        [dataSource]="rows"
        redrawOnResize="true"
        [rowAlternationEnabled]="true"
        [showColumnHeaders]="true"
        height="100%"
        [allowColumnResizing]="true"
        (onInitNewRow)="onInitNewRow($event)"
        (onRowInserted)="onSaveNewRow($event)"
        (onRowUpdated)="onUpdatedRow($event)"
        (onRowRemoved)="onDeletedRow($event)"
      >
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-editing [allowUpdating]="true" mode="batch" [allowDeleting]="true" [allowAdding]="true"> </dxo-editing>

        <dxi-column dataField="copy.descriptionId" [caption]="language.description">
          <dxo-lookup [dataSource]="keys.store"> </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="copy.id" [caption]="language.id">
          <dxo-lookup [dataSource]="keys.store"> </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="copy.supportsHtml" dataType="boolean" [caption]="language.f3MeWebApiManagementLanguageEditSupportsHtml"> </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
