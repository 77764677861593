import { VariantDto } from "../../../common/models/dto/VariantDTO-dto";

export class Variant {
  public id: number;
  public name: string;
  public datasetId: number;

  public copyFromDto(dto: VariantDto) {
    this.id = dto.KeyId;
    this.name = dto.LongName;
    this.datasetId = dto.DataSetId;
  }
}
