import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthorizationRolesService } from "./authorization-roles.service";
import { AuthorizationRolesMaintenanceService } from "./authorization-rolesmaintenance.service";

@NgModule({
  imports: [CommonModule],
  providers: [AuthorizationRolesService, AuthorizationRolesMaintenanceService],
  declarations: [],
})
export class AuthorizationRolesDataModule {}
