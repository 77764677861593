import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxButtonModule, DxFormModule, DxListModule, DxTooltipModule } from "devextreme-angular";
import { ListSelectorComponent } from "./listselector.component";

@NgModule({
  declarations: [ListSelectorComponent],
  imports: [CommonModule, DxFormModule, DxListModule, DxButtonModule, DxTooltipModule],
  exports: [ListSelectorComponent],
  providers: [],
})
export class ListSelectorModule {}
