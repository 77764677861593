<app-cmsedit-component *ngIf="allowEdit" [messageList]="messageList" [messageType]="messageType"> </app-cmsedit-component>

<div *ngIf="allowEdit === false && visible">
  <app-news-component [useFullWidth]="useFullWidth" *ngIf="messageType === messageTypes.news" [messageList]="messageList"> </app-news-component>

  <app-simulationblock-component *ngIf="messageType === messageTypes.simulationblock" [messageList]="messageList"> </app-simulationblock-component>

  <app-carousel-component [useFullWidth]="useFullWidth" *ngIf="messageType === messageTypes.carousel" [messageList]="messageList"></app-carousel-component>

  <app-homepagebanner-component *ngIf="messageType === messageTypes.homepagebanner" [messageList]="messageList"> </app-homepagebanner-component>
</div>
