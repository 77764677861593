import { Component, Input } from "@angular/core";
import { ExogenousInputOption } from "../targets/exogenous-input/options/exogenous-input-option.model";
import { ExogenousInputOptionType } from "../targets/exogenous-input/options/option-type.model";

@Component({
  selector: "app-exogeous-input-option",
  templateUrl: "./exogeous-input-option.component.html",
  styleUrls: ["./exogeous-input-option.component.scss"],
})
export class ExogeousInputOptionComponent {
  constructor() {}
  public targetOptionType = ExogenousInputOptionType;

  @Input()
  public valueOnly = false;

  @Input() option: ExogenousInputOption;
}
