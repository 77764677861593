import { DateSpanDto } from "../../../../common/models/dto/DateSpanDto-dto";
import { RoleAssignableFromDto } from "../../../../common/models/dto/RoleAssignableFromDto-dto";
import { RoleDto } from "../../../../common/models/dto/RoleDto-dto";

export class Role {
  public get isNew(): boolean {
    return this.id === undefined || this.id === null;
  }

  public static copyFromDto(roleDto: RoleDto): Role {
    const role = new Role();

    role.id = roleDto.KeyId;
    role.name = roleDto.ShortName;
    role.description = roleDto.LongName;
    role.assignableFrom = roleDto.AssignableFrom;
    role.from = roleDto.ValidityPeriod.From;
    role.until = roleDto.ValidityPeriod.Until;

    return role;
  }
  name: string;
  id: number;
  description: string;
  assignableFrom: RoleAssignableFromDto;
  from: string | number | Date = "";
  until: string | number | Date = "";

  public copyToDto(): RoleDto {
    const result = new RoleDto();
    result.KeyId = this.id;
    result.ShortName = this.name;
    result.LongName = this.description;
    result.AssignableFrom = this.assignableFrom;
    result.ValidityPeriod = new DateSpanDto();
    result.ValidityPeriod.From = this.from as Date;
    result.ValidityPeriod.Until = this.until as Date;
    return result;
  }
}
