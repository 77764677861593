<div *ngIf="item && cellInfo">
  <tbody>
    <tr *ngFor="let lang of selectLanguage.languagesMetaData">
      <td>
        <span [class]="lang.ImageUrl"></span>
      </td>
      <td class="tb">
        <dx-text-box [(value)]="item[lang.Id]" [name]="'form' + lang.Id" [disabled]="!editable" (onValueChanged)="onItemChanged(cellInfo)"> </dx-text-box>
      </td>
    </tr>
  </tbody>
</div>
