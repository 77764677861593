import { ChoiceDefinition } from "../targetdefinition/target-definition.model";
import { ChoicesService } from "../choices.service";
import { ExogenousInput } from "../exogenous-input/exogenous-input.model";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";
import { TargetPropertiesDto } from "../../../models/choices/dto/TargetProperties-dto";

export class TargetProperties {
  public constructor(private languageSelection: PlLanguageSelection, private targetsService: ChoicesService) {}

  public definition: ChoiceDefinition;
  public exogenousInput: ExogenousInput;

  public copyFromDTO(dto: TargetPropertiesDto) {
    const definition = new ChoiceDefinition(this.languageSelection);
    definition.copyFromDTO(dto.Definition);
    this.definition = definition;

    const target = new ExogenousInput(this.targetsService);
    target.copyFromDTO(dto.Target);
    this.exogenousInput = target;
  }
}
