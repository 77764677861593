import { FeatureEbsDto } from "../models/ebs/dto/info/ebsFeaturesDto";
import { Injectable } from "@angular/core";

const globalVariableSpace: any = window;
// eslint-disable-next-line @typescript-eslint/naming-convention
const pl_hr_f3_featuresSettings = (globalVariableSpace || {}).pl_hr_f3_featuresSettings || {};

@Injectable()
export class EbsFeaturesModel {
  public data: FeatureEbsDto;

  constructor() {
    this.data = (pl_hr_f3_featuresSettings || {}).Ebs || {};
  }

  public get ebsEnabled() {
    if (this.data && this.data && this.data.ebs_enabled) {
      return true;
    }
    return false;
  }
}
