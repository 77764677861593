import { MessageRatingsDto } from "../../dto/MessageRatings-dto";
import { MessageRatingMetaData } from "./message-rating-metadata.model";
import { MessageRating } from "./message-rating.model";

export class MessageRatings {
  public constructor() {}

  public ratings: MessageRating[] = [];
  public metaData: MessageRatingMetaData = new MessageRatingMetaData();

  public copyFromDto(dto: MessageRatingsDto) {
    const newRatings = [];
    dto.Ratings.forEach((r) => {
      const rating = new MessageRating();
      rating.copyFromDto(r);
      newRatings.push(rating);
    });
    this.ratings = newRatings;

    this.metaData.copyFromDto(dto.MetaData);
  }
}
