import { Component, Input } from "@angular/core";

import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

@Component({
  selector: "app-languageitem-editor-grid",
  styleUrls: ["./languageitem-editor-grid.component.scss"],
  templateUrl: "./languageitem-editor-grid.component.html",
})
export class LanguageItemEditorGridComponent {
  public constructor(public selectLanguage: PlLanguageSelection) {}

  @Input()
  public cellInfo: any;

  @Input()
  public editable: boolean = true;

  @Input()
  public get item(): LanguageItem {
    return this._languageItem;
  }
  public set item(newItem: LanguageItem) {
    this._languageItem = newItem;
  }
  private _languageItem: LanguageItem;

  onItemChanged(inputdata: any) {
    if (inputdata && inputdata.setValue) {
      inputdata.setValue(this.item);
    }
  }
}
