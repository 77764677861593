import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxCheckBoxModule } from "devextreme-angular";
import { TextPopupModule } from "../../../angular-common/components/textpopup/textpopup.module";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { UserAcknowledgementComponent } from "./user-acknowledgement.component";

const routes: Routes = [{ path: CommonRoutesDto.UserAcknowledgement, component: UserAcknowledgementComponent, data: [{ menuType: MenuItemTypesDto.MainMenu }] }];

@NgModule({
  declarations: [UserAcknowledgementComponent],
  imports: [CommonModule, TextPopupModule, DxCheckBoxModule, DxButtonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [UserAcknowledgementComponent],
})
export class UserAcknowledgementModule {}
