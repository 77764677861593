<div class="grid-container">
  <div class="buttonBar">
    <dx-button class="manage-reports-button" (click)="manageReports()" icon="preferences" [text]="language.ReportManage"></dx-button>
    <dx-button class="upload-button" (click)="uploadNewReport()" icon="upload" [text]="language.ReportUpload"> </dx-button>
  </div>

  <dx-popup class="manage-popup" [dragEnabled]="true" [(visible)]="showManagePopup" (onShown)="onManagePopupShow($event)" height="500px">
    <div *dxTemplate="let data of 'content'">
      <div class="dx-field">
        <div class="dx-field-label">{{ language.ReportName }}</div>
        <div class="dx-field-value">
          <dx-select-box [items]="availableReportData" [(value)]="currentReportData" (onItemClick)="onReportSelected($event)" displayExpr="shortName"> </dx-select-box>
        </div>
      </div>
      <div *ngIf="currentReportData" class="report-data-content">
        <div class="dx-field">
          <div class="dx-field-label">{{ language.ReportName }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="currentReportData.shortName" [readOnly]="true"></dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ language.ReportDateAdded }}</div>
          <div class="dx-field-value">
            <dx-date-box type="datetime" [(value)]="currentReportData.dateAdded" [readOnly]="true"></dx-date-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ language.ReportLastEditedBy }}</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="currentReportData.lastEditedBy" [readOnly]="true"></dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ language.ReportLastEditedOn }}</div>
          <div class="dx-field-value">
            <dx-date-box type="datetime" [(value)]="currentReportData.dateModified" [readOnly]="true"></dx-date-box>
          </div>
        </div>
        <div>
          <dx-file-uploader
            #reportUpdateFileUploader
            accept=".repx"
            [allowedFileExtensions]="allowedFileTypes"
            uploadMode="useForm"
            (onValueChanged)="currentReportData.fileUpload.fileChange($event)"
          ></dx-file-uploader>
        </div>
        <div class="buttons">
          <dx-button [disabled]="!canUpdateReport" class="button" (onClick)="saveReportData($event)" icon="save" [text]="language.ButtonSave"> </dx-button>
          <dx-button [disabled]="hasReportName == false" class="button" (onClick)="downloadReportFile()" icon="download" [text]="language.ReportDownload"></dx-button>
          <dx-button [disabled]="hasReportName == false || hasReportAssignment == true" class="button" (onClick)="deleteReportData()" icon="trash" [text]="language.ButtonDelete"> </dx-button>
          <span *ngIf="hasReportAssignment" class="hasReportAssignmentMessage warning"><i class="fas fa-lock"></i> {{ language.ReportDataInUseMessaage }}</span>
        </div>
      </div>
    </div>
  </dx-popup>

  <dx-popup class="upload-popup" [title]="language.ReportUpload" [dragEnabled]="true" [(visible)]="showUploadPopup" [hideOnOutsideClick]="false" height="350px">
    <div *dxTemplate="let data of 'content'">
      <div class="dx-field">
        <div class="dx-field-label">{{ language.ReportName }}</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="currentReportData.shortName" (onValueChanged)="revalidateReportName()">
            <dx-validator [adapter]="reportNameAdapterConfig">
              <dxi-validation-rule type="custom" message="{{ language.ReportNameAlreadyExists }}" [validationCallback]="reportNameAdapterConfig.getValue" [reevaluate]="true"> </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <dx-validation-summary id="summary"></dx-validation-summary>
        </div>
      </div>
      <dx-file-uploader
        accept=".repx"
        [allowedFileExtensions]="currentReportData.fileTypeAllowed"
        uploadMode="useForm"
        (onValueChanged)="currentReportData.fileUpload.fileChange($event)"
      ></dx-file-uploader>
      <div class="buttons">
        <dx-button [disabled]="!canSave" class="savebutton" (onClick)="saveReportData($event)" icon="save" [text]="language.ButtonSave"> </dx-button>
      </div>
    </div>
  </dx-popup>

  <dx-data-grid
    class="data-grid"
    [dataSource]="reports"
    autoWidth="true"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserted)="onRowInserted($event.data)"
    (onRowUpdated)="onRowUpdated($event.data)"
    (onRowRemoved)="onRowDeleted($event.data)"
    (onEditingStart)="onEditingStart($event.data)"
    (onSaved)="onRowSaved()"
  >
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"> </dxo-editing>
    <dxi-column dataField="ShortName" [caption]="language.modelShortName">
      <dxi-validation-rule type="required" [message]="language.ShortNameRequired"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="TitleCopy" editCellTemplate="titleEditCellTemplate" [calculateDisplayValue]="title" [caption]="language.modelTitle"></dxi-column>
    <dxi-column dataField="DescriptionCopy" editCellTemplate="descriptionEditCellTemplate" [calculateDisplayValue]="description" [caption]="language.description"></dxi-column>
    <dxi-column dataField="ReportPath" [caption]="language.ReportingReportPath">
      <dxo-lookup [dataSource]="reportNames"> </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="VariantId" [caption]="language.VariantNameHeader" [setCellValue]="onVariantChanged">
      <dxi-validation-rule type="custom" [validationCallback]="variantValidation" [message]="language.ReportVariantSelectionValidationMessage"></dxi-validation-rule>
      <dxo-lookup [dataSource]="variants" displayExpr="displayName" valueExpr="id"> </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="Inputs" [caption]="language.ReportingReportInputs" editCellTemplate="exogTemplate" cellTemplate="formattedExogenousShortNames"></dxi-column>
    <dxi-column dataField="Roles" [caption]="language.ReportingReportRoles" editCellTemplate="tagBoxEditor" cellTemplate="formattedRoleNames"> </dxi-column>

    <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
      <dx-tag-box
        [dataSource]="roles"
        [value]="cellInfo.value"
        displayExpr="LongName"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        applyValueMode="useButtons"
        (onValueChanged)="cellInfo.setValue($event.value)"
      >
      </dx-tag-box>
    </div>

    <div *dxTemplate="let d of 'formattedRoleNames'">
      <div *ngFor="let item of d.data.Roles">- {{ item.LongName }}</div>
    </div>

    <div *dxTemplate="let d of 'formattedExogenousShortNames'">
      <div *ngFor="let item of d.data.Inputs">- {{ computedExegoName(item.ShortName, d.data) }}</div>
    </div>

    <div *dxTemplate="let d of 'titleEditCellTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.TitleCopy"></app-languageitem-editor-grid>
    </div>

    <div *dxTemplate="let d of 'descriptionEditCellTemplate'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.DescriptionCopy"></app-languageitem-editor-grid>
    </div>

    <div *dxTemplate="let row of 'exogTemplate'">
      <div *ngIf="!isNew(row.data); else noVariantSelected">
        <dx-button class="config-button" icon="fas fa-cog" [text]="language.Config" (click)="editExog(row)"> </dx-button>
        <dx-popup [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="showExogPopup" (onContentReady)="onExogPopUpContentReady($event)" height="auto" width="900px">
          <div *dxTemplate="let data of 'content'">
            <app-list-selector
              [itemsInUse]="exogForEditing.currentConfig"
              [availableItems]="exogForEditingAllElement"
              [displayValue]="displayValue"
              [searchValue]="searchValue"
              [moveImplementation]="exogForEditing"
              (valuesChanged)="updateExogElements()"
              (sortOrderChanged)="inputsOrderChanged($event)"
            ></app-list-selector>
            <dx-button icon="save" [text]="language.ButtonSave" (click)="closeEditExogsPopup(row)"></dx-button>
          </div>
        </dx-popup>
      </div>
      <ng-template #noVariantSelected>
        <div>
          <span style="color: red; text-align: center">
            <p>{{ language.NoVariantSelected }}</p>
          </span>
        </div>
      </ng-template>
    </div>
  </dx-data-grid>
</div>
