import { UserSettingsDto } from "../../models/cms/dto/UserSettings-dto";

export class UserSettings {
  public constructor(public acknowledgedDisclaimer: boolean) {}

  public copyFromDTO(dto: UserSettingsDto) {
    this.acknowledgedDisclaimer = dto.AcknowledgedDisclaimer;
  }

  public toDTO(): UserSettingsDto {
    const result = new UserSettingsDto();
    result.AcknowledgedDisclaimer = this.acknowledgedDisclaimer;
    return result;
  }
}
