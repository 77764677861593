import { SelectableItem } from "../selectionbase/selectable-item";
import { ISortOrder } from "../sorting/sortorder";

export class Tab extends SelectableItem implements ISortOrder {
  public sortOrder: number = 0;

  public constructor(public id: number, public text: string) {
    super(id, text);
  }
}
