import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { StringComparer } from "../../../angular-common/stringcomparison";
import { LanguageBuilder } from "../../../common/language/language-builder";
import { EmailTemplateDto } from "../../models/cms/dto/EmailTemplate-dto";
import { UserEmailTemplateDto } from "../../models/cms/dto/UserEmailTemplate-dto";

export class EmailTemplate {
  public id: number;
  public shortName: string;
  public sendTo: string;
  public subject: LanguageItem;
  public body: LanguageItem;

  public constructor(private selectLanguage: PlLanguageSelection) {
    this.id = -1;
    this.shortName = "Default Name";
    this.sendTo = "";

    const builder = new LanguageBuilder(this.selectLanguage);
    this.subject = builder.createLanguageItem();
    this.body = builder.createLanguageItem();
  }

  public subjectCopy: LanguageItem;

  public fromDto(value: EmailTemplateDto) {
    if (value) {
      const builder = new LanguageBuilder(this.selectLanguage);

      this.id = value.Id;
      this.shortName = value.ShortName;
      this.sendTo = value.SendTo;
      this.subject = builder.copyLanguageItem(value.Subject);
      this.body = builder.copyLanguageItem(value.Body);
    }
  }

  public toDTO(): EmailTemplateDto {
    const result = new EmailTemplateDto();
    this.setValuesToDto(result);
    return result;
  }

  public toUserDTO(): UserEmailTemplateDto {
    const result = new UserEmailTemplateDto();
    this.setValuesToDto(result);
    return result;
  }

  private setValuesToDto(result: EmailTemplateDto) {
    const builder = new LanguageBuilder(this.selectLanguage);

    result.Id = this.id;
    result.SendTo = this.sendTo;
    result.Subject = builder.copyLanguageItem(this.subject);
    result.Body = builder.copyLanguageItem(this.body);
    result.ShortName = this.shortName;
  }

  public get title() {
    const title = this.selectLanguage.currentValueFor(this.subject);
    if (StringComparer.isNullUndefinedOrEmpty(title)) {
      return this.shortName || this.id;
    }
    return title;
  }

  public copyMessageTitleCopyToOriginal() {
    if (this.subjectCopy) {
      LanguageBuilder.copy(this.subjectCopy, this.subject);
    }
  }

  public createMessageTitleCopy() {
    this.subjectCopy = LanguageBuilder.duplicate(this.subject);
  }
}
