<div *ngIf="templates">
  <app-language-component (languageChange)="languageChanged($event)"></app-language-component>
  <div class="tablediv">
    <ul class="list">
      <li *ngFor="let template of templates">
        <app-emailtemplate-edit-template-component [template]="template" [currentLanguage]="currentLanguage"
          [enterInEditMode]="enterInEditMode"> </app-emailtemplate-edit-template-component>
      </li>
    </ul>
  </div>
</div>