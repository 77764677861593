<div class="panel-content">
  <div class="button-container">
    <dx-button
      class="button-margin"
      [text]="language.f3MeWebApiUserToEmployeeButtonRevokeAssignment"
      (click)="revokeAssignement()"
      [disabled]="!isUserToEmployeeMappingSet || !currentUserDataAvailable"
    ></dx-button>
  </div>
</div>

<app-employee [currentUserMapping]="currentUserMapping.UserToEmployeeMapping" (assignClicked)="assign($event)"></app-employee>
