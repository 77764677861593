import { RoleHierarchy } from ".";
import { Sorter } from "../../../../../angular-common/sorting/sorter";
import { RoleHierarchyDto } from "../../../../../common/models/dto/RoleHierarchyDto-dto";

export class RoleHierarchyList {
  private root: RoleHierarchy = new RoleHierarchy();

  public constructor() {}

  public get all(): RoleHierarchy[] {
    return this.root.items;
  }

  public copyFromDto(dto: RoleHierarchyDto[]) {
    if (!dto || dto === null) {
      return;
    }

    dto.forEach((dtoHierarchy) => {
      const newHierarchy = new RoleHierarchy();
      newHierarchy.copyFromDto(dtoHierarchy);
      this.root.items.push(newHierarchy);
    });
    Sorter.sortAlphabetically(this.root.items, (v) => v.role.name);
  }

  public getByRoleName(name: string): RoleHierarchy {
    return this.root.getByRoleName(name);
  }

  public addNew(roleName: string): RoleHierarchy {
    const result: RoleHierarchy = new RoleHierarchy();
    result.role.name = roleName;

    this.root.items.unshift(result);

    return result;
  }

  public delete(roleHierarchy: RoleHierarchy) {
    this.root.delete(roleHierarchy);
  }
}
