import { Injectable } from "@angular/core";

const baseApiUri = "/api";
const authUri = "/token";
const externalAuthentication = false;
const loginRoute = "/login";
const signOffRoute = "";
const autoRedirectOnExternalLogin = true;

const globalVariableSpace: any = window;
const pl_hr_f3_endpointConfiguration = (globalVariableSpace || {}).pl_hr_f3_endpointConfiguration || {};

@Injectable()
export class EnvironmentSettings {
  private static UriOrEmpty(value: string) {
    if (value && value.length > 0) {
      return value;
    }
    return "";
  }
  private _baseApiUri: string;
  private _authUri: string;
  private _externalAuthentication: boolean = false;
  private _loginRoute: string;
  private _customAuthenticationPath: string;
  private _customSignOffPath: string;
  private _autoRedirectOnExternalLogin: boolean;
  public appkey: string;
  public maxRequestSize: number;
  public virtualPath: string;

  constructor() {
    this._baseApiUri = pl_hr_f3_endpointConfiguration.ApiEndPoint || baseApiUri;
    this._authUri = pl_hr_f3_endpointConfiguration.AuthenticationEndPoint || authUri;
    this._externalAuthentication = pl_hr_f3_endpointConfiguration.ExternalAuthentication || externalAuthentication;
    this._loginRoute = pl_hr_f3_endpointConfiguration.LoginRoute || loginRoute;
    this._customSignOffPath = pl_hr_f3_endpointConfiguration.SignOffRoute || signOffRoute;
    this._autoRedirectOnExternalLogin = pl_hr_f3_endpointConfiguration.AutoRedirectOnExternalLogin || autoRedirectOnExternalLogin;
    this.maxRequestSize = pl_hr_f3_endpointConfiguration.MaxRequestSizeBytes || 25 * 1024 * 1024;
    this.appkey = pl_hr_f3_endpointConfiguration.AppKey || "";
    this.virtualPath = pl_hr_f3_endpointConfiguration.VirtualPath || "";
  }

  public baseUri = () => this._baseApiUri;
  public authUri = () => this._authUri;
  public useExternalAuthentication = () => this._externalAuthentication;
  public loginRoute = () => this._loginRoute;

  public baseUriNoneApi = () => {
    let baseUri = this.baseUri();
    if (baseUri.endsWith("/")) {
      baseUri = baseUri.substring(0, baseUri.length - 1);
    }
    if (baseUri.endsWith("api")) {
      baseUri = baseUri.substring(0, baseUri.length - 3);
    }
    return baseUri;
  };

  public hasCustomAuthenticationPath() {
    return this.customAuthenticationPath().length > 0;
  }
  public autoRedirectOnExternalLogin() {
    return this._autoRedirectOnExternalLogin;
  }

  public customAuthenticationPath() {
    return EnvironmentSettings.UriOrEmpty(this._customAuthenticationPath);
  }

  public hasCustomSignOffPath() {
    return this.customSignOffPath().length > 0;
  }

  public customSignOffPath() {
    return EnvironmentSettings.UriOrEmpty(this._customSignOffPath);
  }

  public combinePath = (basePath: string, localPath: string) => {
    if (localPath === undefined || localPath.length === 0) {
      return basePath;
    }

    const lowerLocalPath = localPath.toLowerCase();
    if (lowerLocalPath.startsWith("http://") || lowerLocalPath.startsWith("https://")) {
      // the location is not local. Continue without prefixing.
      return localPath;
    }

    let _basePath = basePath;
    if (localPath.startsWith("/")) {
      localPath = localPath.substring(1);
    }
    if (_basePath.endsWith("/") === false) {
      _basePath = _basePath + "/";
    }

    return _basePath + localPath;
  };

  public getUri = (localPath: string) => {
    return this.combinePath(this._baseApiUri, localPath);
  };

  public get maxUploadSizeMb() {
    return this.maxRequestSize / 1024;
  }
}
