import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { PlBaseServiceModule, PlErrorHandlerModule, PlLoadIndicatorModule, TextPopupModule } from ".";

import { EnvironmentSettings } from "./baseservice/environment-settings";
import { PlErrorHandler } from "./errorhandler/plerrorhandler";
import { PlLanguageModule } from "./language/pl-language-module";
import { MenuServiceModule } from "./menus/menuservice.module";

export function checkEnvironment(environmentSettings: EnvironmentSettings) {
  return () => {
    if (environmentSettings) {
      console.log("Current env settings:", environmentSettings.baseUri());
    }
  };
}

@NgModule({
  imports: [PlErrorHandlerModule, PlLoadIndicatorModule, PlBaseServiceModule, MenuServiceModule, TextPopupModule, PlLanguageModule],
  exports: [PlErrorHandlerModule, PlLoadIndicatorModule, PlBaseServiceModule, MenuServiceModule, TextPopupModule, PlLanguageModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: PlErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkEnvironment,
      deps: [EnvironmentSettings],
      multi: true,
    },
  ],
})
export class CommonAppConfigurationModule {}
