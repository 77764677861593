import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { Router } from "@angular/router";
import { PlLanguageSelection } from "../../angular-common/language/pl-language-selection";
import { CmsLanguage } from "../cms/language/cmslanguage.service";
import { Link } from "../components/links/link";
import { EBSService } from "../ebs/ebs-services";
import { EbsSummary } from "../ebs/_models/ebs-summary.model";
import { EbsRoutesDto } from "../models/ebs/dto/EbsRoutes-dto";

@Component({
  selector: "app-capital",
  templateUrl: "./capital.component.html",
  styleUrls: ["./capital.component.scss"],
})
export class CapitalComponent implements OnInit {
  private static createForLocation(ebs: EbsSummary, description: string, selectLanguage: PlLanguageSelection): Link {
    const link = Link.createDefaultInternalLink(selectLanguage, description);
    link.location = CapitalComponent.createLocationForEbsDetail(ebs);
    return link;
  }

  private static createLocationForEbsDetail(ebs: EbsSummary) {
    if (ebs) {
      return EbsRoutesDto.Ebs + "/" + ebs.shortName;
    }
    return "";
  }

  @Output() isEbsSummaryAvailable = new EventEmitter<boolean>();

  public loaded: boolean = false;
  public ebsSummary: EbsSummary;
  public ebsLink: Link;

  public constructor(private ebsService: EBSService, private router: Router, public language: CmsLanguage, private languageSelection: PlLanguageSelection) {}

  public get linkToDetails() {
    if (this.ebsSummary) {
      return EbsRoutesDto.Ebs + "/" + this.ebsSummary.shortName;
    }
    return "";
  }

  public gotoLink(url: string) {
    this.router.navigate([url]);
  }

  public ngOnInit(): void {
    this.ebsService.getEbsSummary().subscribe((result) => {
      this.ebsSummary = result;
      this.ebsLink = CapitalComponent.createForLocation(result, this.language.f3MeWebApiMiniEbsDetail, this.languageSelection);
      this.loaded = true;
      this.isEbsSummaryAvailable.emit(result.isEmpty);
    });
  }
}
