
// t4tsCodeFromCs": "0.8.2.0 for file WellKnownLanguage.cs
// Don't adjust manually!
export enum WellKnownLanguageDto {
        Unknown = 0,
        NL = 1,
        EN = 2,
        FR = 3,
        FI = 4,
        IT = 5,
        DE = 6,
}

