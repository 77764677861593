import { Subject } from "rxjs";
import { ExogenousVariable } from "../../../../common/modelelements/exogenousvariables/exogenous-variable.model";
import { ExogenousVariables } from "../../../../common/modelelements/exogenousvariables/exogenous-variables.model";
import { UserField } from "./userfield.model";

export class EditableUserField {
  public constructor(public userField: UserField, public exogenousVariables: ExogenousVariables) {}

  public get exogenousVariable(): ExogenousVariable {
    return this.exogenousVariables.find(this.userField.exogenousVariableShortName);
  }
  public set exogenousVariable(newExogenousVariable: ExogenousVariable) {
    if (newExogenousVariable && newExogenousVariable !== null) {
      this.userField.exogenousVariableShortName = newExogenousVariable.shortName;
      this._exogAssigned.next(this);
    } else {
      if (newExogenousVariable === null) {
        this._exogRevoked.next(this);
        this.userField.exogenousVariableShortName = "";
      }
    }
  }

  private _exogRevoked = new Subject<EditableUserField>();
  public exogRevoked = this._exogRevoked.asObservable();

  private _exogAssigned = new Subject<EditableUserField>();
  public exogAssigned = this._exogAssigned.asObservable();
}
