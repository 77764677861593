import { ModelElement } from "../../model/models/model-element.model";
import { FormulaDto } from "../../models/dto/FormulaDto-dto";

export class Formula extends ModelElement {
  constructor() {
    super();
  }

  public copyFromDto(formulaDto: FormulaDto) {
    super.copyFromDto(formulaDto);
  }
}
