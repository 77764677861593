<div>
  <div *ngIf="message.summary" id="labour-detail-summary" appSafeData [passedHtmlData]="message.summary"></div>
</div>

<div>
  <div *ngIf="message.content" appSafeData [passedHtmlData]="message.content"></div>
</div>

<div>
  <app-cms-link *ngIf="message.link.isExternal" [link]="message.link"></app-cms-link>
</div>

<div>
  <app-email-component [buttonTitle]="language.f3MeWebApiLCOrder" [emailTemplateId]="message.emailTemplateId"> </app-email-component>
</div>
