export class FixedImageModel {
  public constructor(_originalUri: string, _description) {
    this.originalUri = _originalUri;
    this.description = _description;
  }

  public static createEmpty() {
    const model = new FixedImageModel("", "");
    model.isLoaded = false;
    model.protectedUri = "";
    model.description = "";
    return model;
  }

  public originalUri: string;
  public description: string;
  public protectedUri: string;
  public isLoaded: boolean;
}
