import { PresentableElementDto } from './PresentableElement-dto';
import { TimePeriodDto } from './TimePeriodDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file VariantCalculationDto.cs
// Don't adjust manually!

export class VariantCalculationDto {
    public Start: TimePeriodDto;
    public End: TimePeriodDto;
    public Mode: PresentableElementDto;
}

