import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { LongNameShortName } from "../../../angular-common/longnameshortname/longname-shortname";
import { ElementTypeDto } from "../../models/dto/ElementType-dto";
import { ModelElementDto } from "../../models/dto/ModelElementDto-dto";
import { ModelElementOption } from "./model-element-option.model";
import { ModelPresentableElement } from "./model-presentablel-element-model";

export class ModelElement extends ModelPresentableElement {
  public get hasOptions(): boolean {
    return this.options && this.options !== null && this.options.length > 0;
  }

  public dataSetId: number;
  public options: ModelElementOption[];
  public referenceCategoryId: number;
  public modelCategoryId: number;

  constructor() {
    super(ElementTypeDto.Unknown);
  }

  public get displayName(): string {
    return LongNameShortName.NameFor(this);
  }

  public copyFromDto(modelElementDto: ModelElementDto) {
    super.copyFromDto(modelElementDto);

    if (modelElementDto.Options) {
      const options = modelElementDto.Options.map((option) => new ModelElementOption(option.Id, option.Text));
      this.options = options;
    } else {
      this.options = [];
    }

    this.referenceCategoryId = modelElementDto.ReferenceCategoryId;
    this.modelCategoryId = modelElementDto.ModelCategoryId;
  }

  public toDto(): ModelElementDto {
    const modelElementDto = new ModelElementDto();
    this.copyPropertiesToDto(modelElementDto);

    return modelElementDto;
  }

  public findOption(id: number): ModelElementOption {
    if (!this.hasOptions) {
      return null;
    }

    return this.options.find((x) => x.id === id);
  }

  public copyPropertiesToDto(dto: ModelElementDto) {
    super.assignDto(dto);
    (<any>dto).DataSetId = this.dataSetId;
    dto.ReferenceCategoryId = this.referenceCategoryId;
    dto.Options = this.options.map((o) => {
      return o.copyToDto();
    });
  }

  public get hasReferenceCategory(): boolean {
    return ElementHelper.isNullOrUndefined(this.referenceCategoryId) === false && this.referenceCategoryId > 0;
  }
}
