<div class="buttonBar">
  <dx-button (click)="newEmailTemplate()" icon="fas fa-plus" [hint]="language.ButtonNew" [text]="language.ButtonNew">
  </dx-button>
</div>
<div>
  <dx-data-grid [dataSource]="templates" (onRowRemoved)="deleteEmailTemplate($event)"
    (onRowUpdated)="saveTemplate($event.data, $event.component)" (onEditingStart)="onEditingStart($event.data)">
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false">
    </dxo-editing>
    <dxi-column dataField="ShortName" [caption]="language.ShortName"></dxi-column>
    <dxi-column dataField="Subject" [calculateDisplayValue]="title" [caption]="language.f3MeWebApiEmailSubject"
      editCellTemplate="emailTitleEdit"></dxi-column>
    <div *dxTemplate="let d of 'emailTitleEdit'">
      <app-languageitem-editor-grid [cellInfo]="d" [item]="d.data.SubjectCopy"></app-languageitem-editor-grid>
    </div>
    <dxi-column cellTemplate="editTemplateTemplate"></dxi-column>
    <div *dxTemplate="let d of 'editTemplateTemplate'">
      <dx-button (click)="routeToEdit(d)" icon="fas fa-edit" [hint]="language.ButtonEdit"></dx-button>
    </div>
  </dx-data-grid>
</div>

<dx-popup [width]="300" [height]="200" [showTitle]="true" [title]="language.InvalidShortname" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <label>{{ language.ShortnameModifiedNotification }}</label>
</dx-popup>