import { Component, Input, OnInit } from "@angular/core";
import { alert, confirm } from "devextreme/ui/dialog";

import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { TargetStatusDto } from "../../../models/choices/dto/TargetStatus-dto";
import { TargetProperties } from "../targetproperties/target-properties.model";

@Component({
  selector: "app-exogenous-input-detail-component",
  templateUrl: "./exogenous-input.component.html",
  styleUrls: ["./exogenous-input.component.scss", "./shared.scss"],
  providers: [],
})
export class ExogenousInputDetailComponent implements OnInit {
  public constructor(public languageService: CmsLanguage) {}

  public ngOnInit(): void {
    if (this.showOptionControls === false) {
      console.log("hiding target options; the target state is not valid for choosing options.");
    }
  }

  @Input()
  public targetProperties: TargetProperties;

  public submitted: boolean = false;
  public submitConfirmation: string = "";

  public get showOptionControls(): boolean {
    return this.targetProperties.exogenousInput.status !== TargetStatusDto.Unknown && this.targetProperties.exogenousInput.status !== TargetStatusDto.NotAbleToSelect;
  }

  public submit() {
    if (this.targetProperties.exogenousInput.submitAllowed === false || this.submitted) {
      return;
    }

    confirm(this.languageService.f3MeWebApiTargetConfirmOrder, this.languageService.AppName).then((answer) => {
      if (answer) {
        this.targetProperties.exogenousInput.submit().subscribe((r) => {
          if (r.isSuccess) {
            this.submitted = true;
            this.submitConfirmation = r.message;
          } else {
            alert(r.message, this.languageService.AppName);
          }
        });
      }
    });
  }
}
