import { ModifiedExogenousInputOptionDto } from './ModifiedExogenousInputOption-dto';
import { ModifiedExogenousInputOptionAttachmentDto } from './ModifiedExogenousInputOptionAttachment-dto';

// t4tsCode gen": "0.8.2.0 for file ModifiedExogenousInputOptions.cs
// Don't adjust manually!

export class ModifiedExogenousInputOptionsDto {
    public Options: ModifiedExogenousInputOptionDto [] = [];
    public Attachments: ModifiedExogenousInputOptionAttachmentDto [] = [];
}

