export class ButtonOptions {
  constructor() {}

  public passwordMode = "password";
  public buttonIcon = "fas fa-eye";

  options = {
    icon: this.buttonIcon,
    onClick: e => {
      this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      this.buttonIcon = this.passwordMode === "text" ? "fas fa-eye-slash" : "fas fa-eye";
      e.component.option("icon", this.buttonIcon);
    },
  };
}
