import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResultDto } from "../../common/models/dto/ApiResult-dto";
import { PlLoadIndicator } from "../loadindicator/loadindicator";
import { EntryCreated } from "./entry-created";
import { EnvironmentSettings } from "./environment-settings";
import { ApiResult } from "./_models/apiresult.model";

@Injectable()
export class PlBaseService {
  public constructor(public http: HttpClient, public envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {}

  public isEmptyLocation(location: string): boolean {
    if (location === undefined || location === null) {
      return true;
    } else if (location.trim() === "") {
      return true;
    } else {
      return false;
    }
  }

  public getDataTransformed<T>(endPoint: string): Observable<T> {
    this.assertValidEndpoint(endPoint);

    const obs = this.http.get<T>(this.envSettings.getUri(endPoint));

    return this.loadIndicator.register<T>(obs);
  }

  public getDataAsBlob(endPoint: string): Observable<Blob> {
    this.assertValidEndpoint(endPoint);

    const obs = this.http.get(this.envSettings.getUri(endPoint), { responseType: "blob" });

    return this.loadIndicator.register(obs);
  }

  public getDataAsBlobWithParams(endPoint: string, params: HttpParams): Observable<Blob> {
    this.assertValidEndpoint(endPoint);

    const obs = this.http.get(this.envSettings.getUri(endPoint), { responseType: "blob", params: params });

    return this.loadIndicator.register(obs);
  }

  public getDataAsArrayBuffer(endPoint: string): Observable<ArrayBuffer> {
    this.assertValidEndpoint(endPoint);

    const obs = this.http.get(this.envSettings.getUri(endPoint), { responseType: "arraybuffer" });

    return this.loadIndicator.register(obs);
  }

  public getDataTransformedWithoutLoader<T>(endPoint: string): Observable<T> {
    return this.http.get<T>(this.envSettings.getUri(endPoint));
  }

  public getData<T>(endPoint: string): Observable<T> {
    return this.getDataTransformed(endPoint);
  }

  public getRawData(endPoint: string): Observable<string> {
    return this.http.get(this.envSettings.getUri(endPoint), { responseType: "text" });
  }

  public postData<T>(endPoint: string, body: any) {
    this.assertValidEndpoint(endPoint);

    const url = this.envSettings.getUri(endPoint);
    const obs = this.http
      .post<T>(url, body, { observe: "response" })
      .pipe(map((r) => r.body));

    return this.loadIndicator.register<T>(obs);
  }

  public postDataTransformed<TToResolve, TResolvesTo>(endPoint: string, body: any, transform: (response: HttpResponse<TToResolve>) => TResolvesTo): Observable<TResolvesTo> {
    this.assertValidEndpoint(endPoint);

    const url = this.envSettings.getUri(endPoint);

    const obs = this.http
      .post<TToResolve>(url, body, { observe: "response" })
      .pipe(map((r) => transform(r)));

    return this.loadIndicator.register<TResolvesTo>(obs);
  }

  public createDataTransformed<T>(endPoint: string, body: any): Observable<EntryCreated<T>> {
    return this.postDataTransformed<T, EntryCreated<T>>(endPoint, body, (response) => {
      if (response !== null && response.headers !== null) {
        const location = response.headers.get("Location");
        const data = response.body;

        if (location !== null) {
          return new EntryCreated<T>(location, data);
        }
      }

      const result = new EntryCreated<T>("", null);
      return result;
    });
  }

  public createData<T>(endPoint: string, body: any): Observable<EntryCreated<T>> {
    return this.createDataTransformed<T>(endPoint, body);
  }

  public updateTransformedResponse<T>(obj: any, endPoint: string) {
    this.assertValidEndpoint(endPoint);

    const obs = this.http.put<T>(this.envSettings.getUri(endPoint), obj);

    return this.loadIndicator.register(obs);
  }

  public update<T>(obj: any, endPoint: string) {
    return this.updateTransformedResponse<T>(obj, endPoint);
  }

  public remove<T>(endPoint: string) {
    this.assertValidEndpoint(endPoint);

    const obs = this.http.delete<T>(this.envSettings.getUri(endPoint));

    return this.loadIndicator.register(obs);
  }

  public removeWithOptions<T>(endPoint: string, data: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    this.assertValidEndpoint(endPoint);

    const obs = this.http.delete<T>(this.envSettings.getUri(endPoint), options);

    return this.loadIndicator.register(obs);
  }

  private assertValidEndpoint(endPoint: string) {
    if (this.isEmptyLocation(endPoint)) {
      console.error("No endpoint passed.");
    }
  }

  public extractApiResult(response: HttpResponse<ApiResultDto>): ApiResult {
    if (response.body) {
      const dto = response.body;
      const result = new ApiResult();
      result.copyFromDTO(dto);
      return result;
    } else {
      const result = new ApiResult();
      result.isSuccess = false;
      return result;
    }
  }
}
