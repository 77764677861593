import { HttpClientModule } from "@angular/common/http";
import { LabourgroupService } from "./labourgroup.service";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [LabourgroupService],
  exports: [],
})
export class LabourGroupModule {}
