import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { canActivateUserAcknowledgementGuard } from "../auth/user-acknowledgemen.guard";
import { CMSModule } from "../cms/cms.module";
import { EditToggleModule } from "../components/edittoggle/edittoggle.module";
import { LanguageModule } from "../components/language/language.module";
import { CmsRoutesDto } from "../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../models/cms/dto/MenuItemTypes-dto";
import { FixedImageEditComponent } from "./fixedimage-edit.component";
import { FixedImagesComponent } from "./fixedimages.component";

const routes: Routes = [
  {
    path: CmsRoutesDto.FixedImages,
    component: FixedImagesComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [FixedImagesComponent, FixedImageEditComponent],
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, HttpClientModule, LanguageModule, CMSModule, EditToggleModule],
  exports: [FixedImagesComponent, FixedImageEditComponent],
})
export class FixedImagesModule {}
