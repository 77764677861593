import { Component, Input } from "@angular/core";

import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { EditToggle } from "./edittoggle";

@Component({
  selector: "app-edit-toggle",
  styleUrls: ["./edittoggle.component.scss"],
  templateUrl: "./edittoggle.component.html",
})
export class EditToggleComponent {
  public constructor(public languageService: CmsLanguage) {}

  @Input() editToggle: EditToggle;
}
