import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { PreferedUserEmployeeMappingMetaDataDto } from "../../../models/cms/dto/PreferedUserEmployeeMappingMetaData-dto";
import { UserEmployeeMappingService } from "./user-employee-mapping.service";

export const userEmployeeMappingResolver: ResolveFn<PreferedUserEmployeeMappingMetaDataDto> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const service = inject(UserEmployeeMappingService);
  service.configureEndpoint(route.routeConfig.path);

  return service.retrieveUserData();
};
