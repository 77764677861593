import { LanguageItem } from "../../../../angular-common/language/language-dto.model";
import { MenuItemTypesDto } from "../../../models/cms/dto/MenuItemTypes-dto";
import { PlLanguageSelection } from "../../../../angular-common/language/pl-language-selection";

export class PortalMenuItem {
  constructor(public title: LanguageItem, public type: MenuItemTypesDto, public icon: string, private languageSelection: PlLanguageSelection) {}
  public get displayTitle() {
    return this.languageSelection.currentValueFor(this.title);
  }
}
