<div class="authorization-users" *ngIf="tabContent">
  <div class="grid grid-template">
    <div class="grid-item column-one userlist">
      <app-authorization-content-userlist [tabContent]="tabContent" [users]="tabContent.users" [(selectedUser)]="selectedUser" [deleteUser]="deleteUser"></app-authorization-content-userlist>
    </div>
    <div class="grid-item column-two treeview">
      <app-authorization-content-roles-treeview
        *ngIf="authorizationContentType === authorizationContentTypes.Roles"
        [roles]="tabContent.roles"
        [isEnabled]="selectedUser && selectedUser !== null"
        [title]="changeRolesTitle"
        [selectedRoles]="selectedUserRoles"
        (selectedRolesChanged)="selectedUserRolesChanged($event)"
      >
      </app-authorization-content-roles-treeview>

      <app-authorization-content-user-details *ngIf="authorizationContentType === authorizationContentTypes.UserDetails" [selectedUser]="selectedUser" [saveUser]="saveUser" [users]="tabContent.users">
      </app-authorization-content-user-details>
    </div>
  </div>
</div>
