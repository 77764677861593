import { NgModule } from "@angular/core";
import { DxLoadIndicatorModule } from "devextreme-angular";
import { PlLoadIndicator } from "./loadindicator";
import { PlLoadIndicatorComponent } from "./loadindicator.component";

@NgModule({
  declarations: [PlLoadIndicatorComponent],
  imports: [DxLoadIndicatorModule],
  providers: [PlLoadIndicator],
  exports: [PlLoadIndicatorComponent],
  schemas: [],
})
export class PlLoadIndicatorModule {}
