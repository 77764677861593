import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import DxDataGrid from "devextreme/ui/data_grid";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../angular-common/language/pl-language-selection-copier";
import { LanguageMetaDataDto } from "../../../common/models/dto/LanguageMetaData-dto";
import { CMSService } from "../../cms/cms.service";
import { SharedExpressions } from "../../cms/shared/shared-expressions";
import { EbsConfigurationDto } from "../../models/ebs/dto/EbsConfigurationDto-dto";
import { CmsModel } from "../ebs-edit/cmsmodel";
import { EbsEditService } from "../ebs-edit/ebsedit.service";
import { EbsConfigurationMetaData } from "../ebs-edit/ebsgrouping";
import { Variant } from "../ebs-edit/variant";
import { EbsTreeEditService } from "../ebstree-edit/ebstree-edit.service";
import { EbsLanguage } from "../language/ebslanguage.service";

@Component({
  selector: "app-cms-ebs-edit-component",
  templateUrl: "./cms-ebs-edit.component.html",
  styleUrls: ["./cms-ebs-edit.component.scss"],
  providers: [EbsTreeEditService, EbsEditService],
})
export class EbsEditComponent implements OnInit {
  public currentEbsConfig: EbsConfigurationDto[] = [];
  public _originalConfig: EbsConfigurationDto[] = [];
  public variants: Variant[];
  public cmsModels: CmsModel[];
  public languages: LanguageMetaDataDto[] = [];
  public popupVisible: boolean = false;

  // eslint-disable-next-line max-len
  public constructor(
    private router: Router,
    public language: EbsLanguage,
    private selectLanguage: PlLanguageSelection,
    private ebsEditService: EbsEditService,
    public cmsService: CMSService,
    public ebsTreeEditService: EbsTreeEditService,
    private plLanguageSelection: PlLanguageSelection,
  ) {
    this.title = this.title.bind(this);
  }

  ngOnInit(): void {
    this.ebsEditService.getEbsConfig().subscribe((m) => {
      this.createConfig(m);
      this.createVariants(m);
      this.createCmsModels(m);
    });
    this.languages = this.selectLanguage.languagesMetaData;
  }

  public isNew(ebs: EbsConfigurationDto): boolean {
    return ebs.Id <= 0;
  }

  onRowInserted(row: { data: EbsConfigurationDto }, dxGrid: DxDataGrid) {
    var cleanedName = SharedExpressions.cleanUpShortName(row.data.ShortName);
    this.popupVisible = SharedExpressions.checkforCleanedShortName(row.data.ShortName, cleanedName);
    row.data.ShortName = cleanedName;

    PlLanguageSelectionCopier.copyToOriginal(row.data);

    this.ebsEditService.saveEbsConfig(row.data).subscribe((node) => {
      row.data.CmsGroupingId = node.cmsGroupingId;
      dxGrid.repaint();
    });
  }

  onInitNewRow(row: any) {
    const newEntry = this.ebsEditService.getDefault().toDto();
    PlLanguageSelectionCopier.copyFromOriginal(newEntry);
    row.data = newEntry;
  }

  onEditingStart(ebsConfig: EbsConfigurationDto) {
    PlLanguageSelectionCopier.copyFromOriginal(ebsConfig);
  }

  public title(ebsConfig: EbsConfigurationDto) {
    return ebsConfig.CmsTitle[this.plLanguageSelection.currentLanguage];
  }

  onRowUpdated(ebsConfig: EbsConfigurationDto, dxGrid: DxDataGrid) {
    var cleanedName = SharedExpressions.cleanUpShortName(ebsConfig.ShortName);
    this.popupVisible = SharedExpressions.checkforCleanedShortName(ebsConfig.ShortName, cleanedName);
    ebsConfig.ShortName = cleanedName;

    PlLanguageSelectionCopier.copyToOriginal(ebsConfig);
    dxGrid.repaint();
    this.ebsEditService.saveEbsConfig(ebsConfig).subscribe(() => {});
  }

  onRowDeleted(row: { data: EbsConfigurationDto }) {
    if (!this.isNew(row.data)) {
      this.ebsEditService.deleteConfiguration(row.data.Id).subscribe();
    }
  }

  createCmsModels(m: EbsConfigurationMetaData) {
    const newModels: CmsModel[] = [];
    m.cmsGroupModel.forEach((model) => {
      newModels.push(model);
    });
    this.cmsModels = newModels;
  }

  createVariants(m: EbsConfigurationMetaData) {
    const newVariants: Variant[] = [];
    m.variants.forEach((variant) => {
      newVariants.push(variant);
    });
    this.variants = newVariants;
  }

  createConfig(m: EbsConfigurationMetaData) {
    const newConfigs: EbsConfigurationDto[] = [];
    m.ebsConfiguration.forEach((config) => {
      PlLanguageSelectionCopier.copyFromOriginal(config);
      this._originalConfig.push(config.toDto());
      newConfigs.push(config.toDto());
    });
    this.currentEbsConfig = newConfigs;
  }

  public editTree(input: { data: EbsConfigurationDto }) {
    const cmsId = input.data.CmsGroupingId;
    this.router.navigate(["/ebstreeedit/" + cmsId]);
  }
}
