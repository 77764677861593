import { NgModule } from "@angular/core";
import { WebLanguage } from "../../common/language/weblanguage.service";
import { PlLanguageSelection } from "./pl-language-selection";
import { PlLanguageService } from "./pllanguage.service";

@NgModule({
    imports: [
    ],
    exports: [
    ],
    providers: [
        PlLanguageService,
        PlLanguageSelection,
        WebLanguage,
    ],
})
export class PlLanguageModule {
}
