import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DxSelectBoxModule } from "devextreme-angular";
import { canActivateUserAcknowledgementGuard } from "../../auth/user-acknowledgemen.guard";
import { CmsRoutesDto } from "../../models/cms/dto/CmsRoutes-dto";
import { MenuItemTypesDto } from "../../models/cms/dto/MenuItemTypes-dto";
import { UserFieldsEditComponent } from "./cms-user-fields-edit.component";
import { UserFieldsEditService } from "./userfieldsedit.service";

const routes: Routes = [
  {
    path: CmsRoutesDto.UserFieldsEdit,
    component: UserFieldsEditComponent,
    data: [{ menuType: MenuItemTypesDto.Management }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [UserFieldsEditComponent],
  imports: [RouterModule.forChild(routes), HttpClientModule, CommonModule, DxSelectBoxModule],
  exports: [],
  providers: [UserFieldsEditService],
})
export class UserFieldsEditModule {}
