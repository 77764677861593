import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

import { LogDownloadService } from "./log-download.service";
import { TextPopupInteraction } from "../../textpopup/textpopup-interaction";
import { WebLanguage } from "../../../../common/language/weblanguage.service";

@Component({
  selector: "app-logdownload",
  templateUrl: "./log-download.component.html",
  styleUrls: ["./log-download.component.scss"],
})
export class LogDownloadComponent implements OnInit, OnDestroy {
  public constructor(public language: WebLanguage, private logDownloadService: LogDownloadService, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.logDownloadService.getMetaData().subscribe((metaData) => {
      this.logItems = [this.logDownloadService.latestLogItemId].concat(metaData.HistoryItems);
      this.contentReady = true;
      this.allowDirectApiLink = metaData.AllowAnonymousDownloads;
    });
  }

  ngOnDestroy(): void {
    this.objectUrls.forEach((url) => {
      window.URL.revokeObjectURL(url);
    });
  }

  public get numberOfItems(): number {
    if (this.contentReady && this.logItems && this.logItems !== null) {
      return this.logItems.length;
    }
    return 0;
  }

  public contentReady: boolean = false;
  public logItems: string[] = [];
  public downloadableLogs: [string, SafeUrl] = {} as [string, SafeUrl];
  private objectUrls: string[] = [];
  public allowDirectApiLink: boolean = false;

  public getDirectLink(logItem: string): string {
    return this.logDownloadService.getDirectLink(logItem);
  }

  public hasDownloadUrl(logItem: string): boolean {
    return this.downloadableLogs[logItem] !== undefined;
  }

  public openInNewTab(logItem: string) {
    this.downloadOrNewTab(logItem, 10000, true);
  }

  public downloadContent(logItem: string) {
    this.downloadOrNewTab(logItem, 0, false);
  }

  public downloadOrNewTab(logItem: string, maxLen: number, tab: boolean) {
    this.logDownloadService.getLogItem(logItem, maxLen).subscribe((content) => {
      const fileAsBlob = new Blob([content], { type: "text/plain" });
      const url = window.URL.createObjectURL(fileAsBlob);
      if (tab === true) {
        window.open(url, "_blank");
      } else {
        this.downloadableLogs[logItem] = this.domSanitizer.bypassSecurityTrustUrl(url);
        this.objectUrls.push(url);
      }
    });
  }

  public showContent(logItem: string) {
    this.logDownloadService.getLogItem(logItem, 10000).subscribe((content) => {
      this.popupInteraction = new TextPopupInteraction(logItem, content, "", this.language.ButtonClose);
      this.popupInteraction.showOkButton = false;
      this.popupInteraction.isVisible = true;
    });
  }

  public popupInteraction: TextPopupInteraction;
}
