import { Component } from "@angular/core";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";

@Component({
  selector: "app-what-if",
  templateUrl: "./what-if.component.html",
  styleUrls: ["./what-if.component.scss"],
})
export class WhatIfComponent {
  public messageTypes = MessageTypeDto;

  public constructor() {}
}
