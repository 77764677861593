import { Component, Input, OnInit } from "@angular/core";

import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { CMSMessage } from "../../cms/cms-message.model";
import { CMSService } from "../../cms/cms.service";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { RatingLanguage } from "../language/ratinglanguage.service";
import { MessageRatingService } from "./../messagerating/message-rating.service";
import { MessageRating } from "./../messagerating/models/message-rating.model";
import { RatingAxis } from "./models/rating-axis.model";
import { RatingDataPoint } from "./models/rating-data-point.model";

@Component({
  selector: "app-message-rating-chart",
  templateUrl: "./message-rating-chart.component.html",
  styleUrls: ["./message-rating-chart.component.scss"],
})
export class MessageRatingChartComponent implements OnInit {
  constructor(private messageRatingService: MessageRatingService, private cmsService: CMSService, private languageService: RatingLanguage) {}

  ngOnInit() {
    this.initAxis();

    const getMessages = this.cmsService.getMessages(this.messageType);
    const getRatings = this.messageRatingService.getRatings(this.messageType);

    forkJoin([getMessages, getRatings])
      .pipe(
        map(([m, r]) => {
          this.messages = m;
          this.ratings = r.ratings;
          this.update();
        }),
      )
      .subscribe();
  }

  @Input()
  public messageType: MessageTypeDto;

  @Input()
  public title: string;

  public get showScrollbar(): boolean {
    return this.contentLoaded && this.dataPoints.length > 10;
  }

  private messages: CMSMessage[];
  private ratings: MessageRating[];

  private findRating(messageId: number): MessageRating {
    const rating = this.ratings.find((r) => r.messageId === messageId);
    if (ElementHelper.isNullOrUndefined(rating) === false) {
      return rating;
    }
    return undefined;
  }

  private update() {
    const newPoints = [];
    this.messages.forEach((lc) => {
      const rating = this.findRating(lc.id);
      if (ElementHelper.isNullOrUndefined(rating) === false) {
        const point = new RatingDataPoint();
        point.score = rating.averageScore;
        point.count = rating.numberOfScores;
        point.x = lc.title;
        newPoints.push(point);
      }
    });
    this.dataPoints = newPoints.sort(this.compareRating);
  }

  public compareRating(a: RatingDataPoint, b: RatingDataPoint): number {
    if (a.score > b.score) {
      return -1;
    }
    if (a.score < b.score) {
      return 1;
    }

    return 0;
  }

  public dataPoints: RatingDataPoint[];

  public get contentLoaded(): boolean {
    return ElementHelper.isNullOrUndefined(this.dataPoints) === false;
  }

  public axisConfiguration: RatingAxis[] = [];
  public scoreAxis: RatingAxis;
  public countAxis: RatingAxis;

  private initAxis() {
    const scoreAxis = new RatingAxis();
    scoreAxis.name = "score";
    scoreAxis.position = "left";
    scoreAxis.title = this.languageService.f3MeWebApiAxisRating;
    scoreAxis.tickInterval = 0.5;
    this.scoreAxis = scoreAxis;

    const countAxis = new RatingAxis();
    countAxis.name = "count";
    countAxis.position = "right";
    countAxis.title = this.languageService.f3MeWebApiAxisNumberOfRatings;
    countAxis.tickInterval = 1;
    this.countAxis = countAxis;

    this.axisConfiguration = [countAxis, scoreAxis];
  }
}
