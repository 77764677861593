import { Component, Input } from "@angular/core";
import { AuthorizationLanguage } from "../../../../../../common/language/Authorizationlanguage.service";
import { EnvironmentSettings } from "../../../../../baseservice/environment-settings";
import { User } from "../../../services/models/authorization-user-model";
import { UserStatus } from "../../../services/models/authorization-user-status-model";
import { AuthorizationRolesTabContent } from "../../tabs/authorization-tabs.tab-content-roles";

@Component({
  selector: "app-authorization-content-user-details",
  templateUrl: "./authorization-content.component-user-details.html",
  styleUrls: ["./authorization-content.component-user-details.scss"],
})
export class UserDetailsFormComponent {
  @Input("selectedUser")
  public get selectedUser(): User {
    return this._selectedUser;
  }
  public set selectedUser(newSelectedUser: User) {
    this._selectedUser = newSelectedUser;

    if (newSelectedUser !== undefined) {
      this.resetValues();
    }
  }

  @Input() users: User[];

  @Input()
  public saveUser: () => void;

  private _username: string;
  public get username(): string {
    return this._username;
  }
  public set username(value: string) {
    this._username = value;
  }
  public longname: string;
  public osAuthenticated: boolean = false;
  public userStatus: UserStatus;

  private _selectedUser: User;
  private _tabContent: AuthorizationRolesTabContent;

  constructor(public language: AuthorizationLanguage, private envSettings: EnvironmentSettings) {
    this.userStatuses = this.extractUserStatusesDescriptionFromEnum();
  }

  public onSaveUser() {
    if (this.username !== "" && this.isUsernameUnique(this.username)) {
      this.saveValues();
      this.saveUser();
    }
  }

  public isUsernameUnique(username: string) {
    if (this.users !== undefined) {
      const foundUser = this.users.find((x) => x.userName === username && x.id !== this.selectedUser.id);
      return foundUser === undefined;
    }

    return true;
  }

  public get canEdit(): boolean {
    return this.selectedUser !== undefined;
  }

  public get allowAdjustingExternalAuth(): boolean {
    return this.canEdit && this.envSettings.useExternalAuthentication() === false;
  }

  public onUndoChanges() {
    this.undoChanges();
    this.resetValues();
  }

  public undoChanges() {
    const foundUser = this.users.find((x) => x.id === this.selectedUser.id);
    this.selectedUser = foundUser;
  }

  public saveValues() {
    this.selectedUser.userName = this.username;
    this.selectedUser.longName = this.longname;
    this.selectedUser.osAuthenticated = this.osAuthenticated;
    this.selectedUser.status = this.userStatus;
  }

  public resetValues() {
    this.username = this.selectedUser.userName;
    this.longname = this.selectedUser.longName;
    this.osAuthenticated = this.selectedUser.osAuthenticated;
    this.userStatus = this.selectedUser.status;
  }

  validateUsername = (e) => this.isUsernameUnique(e.value);

  public get getUserStatus() {
    if (this.userStatus !== undefined) {
      return UserStatus[this.userStatus];
    }

    return "";
  }

  public onUserStatusChanged(e) {
    this.userStatus = (UserStatus as any)[e.value];
  }

  private extractUserStatusesDescriptionFromEnum() {
    const options = [];

    for (const status in UserStatus) {
      if (isNaN(Number(status))) {
        options.push(status);
      }
    }

    return options;
  }

  public userStatuses: string[] = [];
}
