import { Component, OnInit } from "@angular/core";
import { AboutInformation } from "./about-info";
import { ApplicationInformationService } from "./applicationinformation.service";

@Component({
  selector: "app-application-information",
  templateUrl: "applicationinformation.component.html",
  styleUrls: ["./applicationinformation.component.scss"],
})
export class ApplicationAboutInformationComponent implements OnInit {
  constructor(private appinfoservice: ApplicationInformationService) {}
  public aboutInfo: AboutInformation;

  ngOnInit() {
    this.appinfoservice.retrieveAppInformation().subscribe(info => {
      this.aboutInfo = info;
    });
  }
}
