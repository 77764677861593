import { UserDto } from "../../../../../common/models/dto/UserDto-dto";
import { UserStatusDto } from "../../../../../common/models/dto/UserStatus-dto";
import { LongNameShortName } from "../../../../longnameshortname/longname-shortname";
import { ILongNameShortName } from "../../../../longnameshortname/longname-shortname.interface";
import { Role } from "../../models/authorization-role-model";
import { UserStatus } from "./authorization-user-status-model";

export class User implements ILongNameShortName {
  public static newEmptyUser(): User {
    const user = new User();

    user.userName = "newuser";
    user.assignedRoles = [];
    user.osAuthenticated = true;
    user.status = UserStatus.Active;

    return user;
  }

  public get userNameIfNoLongname() {
    return LongNameShortName.NameFor(this);
  }

  public static cloneUser(user: User): User {
    const newUser = this.newEmptyUser();

    newUser.assignedRoles = user.assignedRoles;
    newUser.osAuthenticated = user.osAuthenticated;
    newUser.longName = user.longName;
    newUser.userName = user.userName;
    newUser.status = user.status;

    return newUser;
  }

  public static copyFromDto(userDto: UserDto): User {
    const user = this.newEmptyUser();

    userDto.AssignedRoles.forEach((rd) => {
      user.assignedRoles.push(Role.copyFromDto(rd));
    });

    user.id = userDto.KeyId;
    user.longName = userDto.LongName;
    user.osAuthenticated = userDto.OSAuthenticated;
    user.userName = userDto.ShortName;
    user.status = this.getStatus(userDto.Status);

    return user;
  }

  public static copyFromDtos(userDtos: UserDto[]): User[] {
    const result = userDtos.map((x) => this.copyFromDto(x));

    return result;
  }

  public static copyToDto(user: User): UserDto {
    const userDto = new UserDto();

    userDto.AssignedRoles = [];
    user.assignedRoles.forEach((ar) => {
      userDto.AssignedRoles.push(ar.copyToDto());
    });

    userDto.KeyId = user.id;
    userDto.LongName = user.longName;
    userDto.OSAuthenticated = user.osAuthenticated;
    userDto.ShortName = user.userName;
    userDto.Status = this.getStatusDto(user.status);

    return userDto;
  }

  public static getStatus(statusDto: UserStatusDto): UserStatus {
    const userStatus: UserStatus = statusDto.valueOf();
    return userStatus;
  }

  public static getStatusDto(status: UserStatus): UserStatusDto {
    const userStatusDto: UserStatusDto = status.valueOf();
    return userStatusDto;
  }

  id: number;
  userName: string;
  longName: string;
  osAuthenticated: boolean;
  assignedRoles: Role[];
  status: UserStatus;
  public get shortName() {
    return this.userName;
  }
}
