import { ISortOrder } from "../../../angular-common";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { LanguageBuilder } from "../../../common/language/language-builder";
import { GroupOptionsDto } from "../../../employee-common/models/cms/dto/GroupOptions-dto";
import { LabourGroupDto } from "../../../employee-common/models/cms/dto/LabourGroupDto-dto";
import { CmsLanguage } from "../language/cmslanguage.service";

export class LabourConditionGroup implements ISortOrder {
  public get title(): string {
    return this.groupTitle[this.selectLanguage.currentLanguage];
  }

  public static newDefaultGroup(languageService: CmsLanguage, selectLanguage: PlLanguageSelection): LabourConditionGroup {
    const result = new LabourConditionGroup();
    result.selectLanguage = selectLanguage;
    result.id = -1;
    result.groupTitle = languageService.getEntry(languageService.key_f3MeWebApiNoGroup);
    result.options = GroupOptionsDto.Read;
    result.messageIds = new Array();
    result.sortOrder = 0;
    result.color = "";

    return result;
  }
  public constructor() {}

  public selectLanguage: PlLanguageSelection;
  public id: number;
  public groupTitle: LanguageItem;
  public options: GroupOptionsDto;
  public messageIds: number[];
  public sortOrder: number;
  public color: string;
  public groupTitleCopy: LanguageItem;

  public copyValuesExceptId(value: LabourConditionGroup) {
    const builder = new LanguageBuilder(this.selectLanguage);
    this.groupTitle = builder.copyLanguageItem(value.groupTitle);
    this.options = value.options;

    const ids: number[] = [];
    value.messageIds.forEach((v) => {
      ids.push(v);
    });
    this.messageIds = ids;
    this.sortOrder = value.sortOrder;
    this.color = value.color;
  }

  public copyFromDto(dto: LabourGroupDto, selectLanguage: PlLanguageSelection) {
    this.selectLanguage = selectLanguage;
    this.id = dto.Id;
    this.groupTitle = dto.Title;
    this.options = dto.Options;

    const newMessageIds: number[] = [];
    dto.MessageIds.forEach((m) => {
      newMessageIds.push(m);
    });

    this.messageIds = newMessageIds;
    this.sortOrder = dto.SortOrder;
    this.color = dto.Color;
  }

  public toDto(): LabourGroupDto {
    const newDto = new LabourGroupDto();
    newDto.Id = this.id;
    newDto.Title = this.groupTitle;
    newDto.Options = this.options;
    newDto.SortOrder = this.sortOrder;
    newDto.Color = this.color;

    const newMessages: number[] = [];
    this.messageIds.forEach((m) => {
      newMessages.push(m);
    });

    newDto.MessageIds = newMessages;

    return newDto;
  }

  public copyMessageTitleCopyToOriginal() {
    if (this.groupTitleCopy) {
      LanguageBuilder.copy(this.groupTitleCopy, this.groupTitle);
    }
  }

  public createMessageTitleCopy() {
    this.groupTitleCopy = LanguageBuilder.duplicate(this.groupTitle);
  }

  public isNew(): boolean {
    return this.id === -1 || this.id === undefined;
  }
}
