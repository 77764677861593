import { Component, OnInit } from "@angular/core";

import { PlUserStorage } from "../../index";
import { Router } from "@angular/router";
import { UserInformationService } from "../../authentication/user-information.service";
import { UserInformationValuesDto } from "../../../common/models/dto/UserInformationValues-dto";
import { WebLanguage } from "../../../common/language/weblanguage.service";

@Component({
  selector: "app-user-information-screen",
  templateUrl: "./user-information-screen.component.html",
  styleUrls: ["./user-information-screen.component.scss"],
})
export class UserInformationScreenComponent implements OnInit {
  constructor(private userInformationService: UserInformationService, public language: WebLanguage, private router: Router) {}

  currentUser: UserInformationValuesDto;
  ngOnInit(): void {
    this.retrieveCurrentUser();
  }

  retrieveCurrentUser() {
    const hasUser = PlUserStorage.hasUser();
    if (hasUser) {
      this.currentUser = PlUserStorage.user;
    }
  }

  public get invalid() {
    return this.currentUser.LongName === "";
  }

  saveUserInfo() {
    if (this.currentUser.LongName !== "") {
      this.userInformationService.updateUserInformation(this.currentUser).subscribe((result) => {
        if (result) {
          const route = "/home";
          this.router.navigate([route]);
        }
      });
    }
  }
}
