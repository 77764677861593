export class LinkPopupSettings {
  constructor() {
    this.isVisible = false;
  }
  public messageId: number = -1;
  public targetId: number = -1;

  public windowWidth: number;
  public windowHeight: number;

  private _isVisible: boolean;
  public get isVisible(): boolean {
    return this._isVisible;
  }
  public set isVisible(v: boolean) {
    this._isVisible = v;
  }
}
