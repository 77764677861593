import { Component, Input } from "@angular/core";
import { AuthorizationContentType } from "../../../models/authorization-content.types";
import { IAuthorizationContent } from "./IAuthorizationContent";

@Component({
  selector: "app-authorization-content",
  templateUrl: "./authorization-content.component.html",
  styleUrls: ["./authorization-content.component.scss"],
})
export class AuthorizationContentComponent implements IAuthorizationContent {
  @Input() authorizationContentType: AuthorizationContentType;
  public authorizationContentTypes = AuthorizationContentType;
}
