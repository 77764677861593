<div>
  <dx-button id="language-selector" [text]="showTitles ? currentLanguage.DisplayName : ''" [icon]="currentLanguage.ImageUrl" [hint]="language.selectLanguage" (onClick)="showPopup()"> </dx-button>
  <dx-popup
    [width]="300"
    [height]="'auto'"
    [showTitle]="false"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [(visible)]="popupVisible"
    [position]="{ my: 'right top', at: 'right bottom', of: '#language-selector' }"
  >
    <div *dxTemplate="let data of 'content'" class="hide-scrollbar">
      <p>{{ language.languageSelectionSelectLanguage }}</p>
      <ul class="list-styled">
        <li *ngFor="let lang of languageSelection.languagesMetaData">
          <dx-button class="languageButtonStyle" width="100%" [icon]="lang.ImageUrl" [text]="lang.DisplayName" [hint]="lang.DisplayName" (onClick)="selectLanguage(lang); hidePopup()"> </dx-button>
        </li>
      </ul>
    </div>
  </dx-popup>
</div>
