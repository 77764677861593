import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { LabourConditionLinkDto } from "../../models/cms/dto/LabourConditionLinkDto-dto";
import { LabourConditionLink } from "./labourcondition-link.model";

@Injectable()
export class LabourConditionLinkService extends PlBaseService {
  endpoint = "LabourConditionLink";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveLinks(): Observable<LabourConditionLink[]> {
    const thisService = this;
    return this.getDataTransformed<LabourConditionLinkDto[]>(this.endpoint).pipe(map((r) => thisService.extractLinks(r)));
  }

  extractLinks(dto: LabourConditionLinkDto[]): LabourConditionLink[] {
    if (dto) {
      const result: LabourConditionLink[] = [];
      dto.forEach((lc) => {
        const newLc = new LabourConditionLink();
        newLc.fromDto(lc);
        result.push(newLc);
      });

      return result;
    }
    return null;
  }

  public createLink(lcLink: LabourConditionLink) {
    const url = this.endpoint;
    return this.createData<LabourConditionLinkDto>(url, LabourConditionLink.toDto(lcLink));
  }

  public updateLink(lcLink: LabourConditionLink) {
    const url = this.endpoint + "/" + lcLink.keyId;
    return this.update<LabourConditionLinkDto>(LabourConditionLink.toDto(lcLink), url);
  }

  public deleteLink(lcLink: LabourConditionLink) {
    const url = this.endpoint + "/" + lcLink.keyId;
    return this.remove<number>(url);
  }
}
