<div>
  <div>
    <div class="editButtons">
      <dx-button [hint]="language.f3MeWebApiCMSEditMessage" icon="edit" (click)="startEdit()" *ngIf="editMode === false"></dx-button>
      <dx-button [hint]="language.f3MeWebApiCMSDeleteMessage" icon="fas fa-trash" (click)="delete()" *ngIf="editMode === false && message.canDelete"></dx-button>
      <dx-button [hint]="language.ButtonSave" icon="save" (click)="saveEdit()" *ngIf="editMode"> </dx-button>
      <dx-button [hint]="language.ButtonCancel" icon="undo" (click)="cancelEdit()" *ngIf="editMode"> </dx-button>
    </div>
    <div *ngIf="editMode === false">
      <app-cmsmessageedit-display-component [message]="message"></app-cmsmessageedit-display-component>
    </div>

    <div *ngIf="editMode === true">
      <app-cmsmessageedit-edit-component [message]="messageCopy" [groups]="groups" [imageChanges]="imageChanges"> </app-cmsmessageedit-edit-component>
    </div>
    <div></div>
  </div>
</div>
