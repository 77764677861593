import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

import { map } from "rxjs/operators";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { ImageFileupload } from "../../../angular-common/components/fileupload/image-fileupload";
import { AspectRatio } from "../../../angular-common/components/image-cropper/aspect-ratio.enum";
import { ImageChanges } from "../../components/images/image-changes.model";
import { ImageService } from "../../components/images/image.service";
import { CmsLanguage } from "../language/cmslanguage.service";

@Component({
  selector: "app-cms-image-edit-edit-component",
  templateUrl: "./cms-image-edit-edit.component.html",
  styleUrls: ["./cms-image-edit-edit.component.scss"],
  providers: [],
})
export class CmsImageEditEditComponent implements OnInit {
  public popupVisible = false;
  public showImageSizeWarning = false;
  public fileUpload: ImageFileupload;

  private _imageChanges: ImageChanges;
  private _uploadText: string;

  @Input()
  public get imageChanges(): ImageChanges {
    return this._imageChanges;
  }
  public set imageChanges(newImageChanges: ImageChanges) {
    this._imageChanges = newImageChanges;
    this.initializeUpload();
  }
  @Input() readOnly = false;
  @Input() canDelete: boolean;
  @Input() showCurrentDimensions = false;
  @Input() aspectRatio: AspectRatio = AspectRatio.WIDE;

  @ViewChild("selectedImage") inputElementForImage: ElementRef;
  @ViewChild("imageElement", { static: true }) imageElement: ElementRef;

  private static MaxImagineSizeMb = 5;

  public constructor(public language: CmsLanguage, private imageService: ImageService, envSettings: EnvironmentSettings) {
    const maxUploadSize = Math.min(envSettings.maxUploadSizeMb, CmsImageEditEditComponent.MaxImagineSizeMb);
    this.fileUpload = new ImageFileupload(maxUploadSize, "", this.imageService.defaultImagineExtensions);
  }

  public ngOnInit(): void {
    this.initializeUpload();
    this.setUploadText();
    this.imageService.allowedImageExtensions().pipe(
      map((ext) => {
        this.fileUpload.allowedFileTypes = ext;
      }),
    );

    this.imageService.urlDefaultImage.subscribe((data) => {
      this.fileUpload.urlDefaultImage = data.protectedUri;
    });
  }

  public get uploadText(): string {
    return this._uploadText;
  }

  public get hasFileNotReady() {
    if (this.fileUpload) {
      return !this.fileUpload.hasFile || !this.fileUpload.fileName;
    }
    return false;
  }

  public get maxSizeWarningMessage() {
    return this.language.f3MeWebApiCMSMessageImageToLarge(this.fileUpload.maxFileSizeMb);
  }

  public get fileTypeWarningMessage() {
    return this.language.FileExtNotAllowed(this.fileUpload.allowedFileTypesAsString);
  }

  public get hasFileReady() {
    return !this.hasFileNotReady;
  }

  public saveCrop(imageData: string) {
    this.fileUpload.fileData = imageData;

    this.imageChanges.setImage(this.fileUpload.fileName, this.fileUpload.fileType, this.fileUpload.fileDataOrDefaultImage);
    this.popupVisible = false;
  }

  public setUploadText() {
    if (this.showCurrentDimensions && this.imageElement && this.imageElement.nativeElement && this.imageElement.nativeElement.naturalWidth > 0) {
      this._uploadText = this.language.f3MeWebApiCMSMessageUploadImage + " (" + this.imageElement.nativeElement.naturalWidth + " x " + this.imageElement.nativeElement.naturalHeight + ")";
    } else {
      this._uploadText = this.language.f3MeWebApiCMSMessageUploadImage;
    }
  }

  private initializeUpload() {
    this.fileUpload.fileData = this.imageChanges.imageData;
    this.fileUpload.fileName = this.imageChanges.fileName;
    this.fileUpload.fileType = this.imageChanges.fileType;

    this.fileUpload.onFileUploaded.subscribe(() => this.fileUploadImageChanged());
    this.fileUpload.onFileDeleted.subscribe(() => this.fileUploadImageDeleted());
  }

  private fileUploadImageChanged() {
    this.imageChanges.setImage(this.fileUpload.fileName, this.fileUpload.fileType, this.fileUpload.fileDataOrDefaultImage);
    this.popupVisible = true;
  }

  private fileUploadImageDeleted() {
    this.imageChanges.deleteImage();
    this.inputElementForImage.nativeElement.value = "";
  }
}
