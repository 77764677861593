import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DxButtonModule } from "devextreme-angular";
import { CardViewModule } from "../../../components/cardview";
import { LoginLandingPageComponent } from "./login.landingpage.component";

@NgModule({
  imports: [CommonModule, CardViewModule, DxButtonModule, RouterModule],
  exports: [LoginLandingPageComponent],
  declarations: [LoginLandingPageComponent],
  providers: [],
})
export class LoginLandingPageModule {}
