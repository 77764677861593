import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { ReportInputLanguageItemDto } from "../../models/reporting/dto/ReportInputLanguageItem-dto";
import { ReportingLanguage } from "../language/reporting.service";

@Injectable()
export class ReportingLanguageService extends PlBaseService {
  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator, private languageService: ReportingLanguage) {
    super(http, envSettings, loadIndicator);
  }
  private endpoint = "reportinputvaluestranslations";

  public retrieveAllInputsTranslations(): Observable<ReportInputLanguageItemDto[]> {
    return this.getDataTransformed<ReportInputLanguageItemDto[]>(this.endpoint);
  }

  public updateInputTranslation(inputValueTranslation: ReportInputLanguageItemDto): Observable<ReportInputLanguageItemDto> {
    return this.updateTransformedResponse<ReportInputLanguageItemDto>(inputValueTranslation, this.endpoint);
  }

  public deleteInputTranslation(id: number): Observable<void> {
    const url = `${this.endpoint}/${id}`;
    return this.remove(url);
  }
}
