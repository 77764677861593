import { Component, Input, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { LinkPopupSettings } from "../../../../../employee-common/components/linkpopup/linkpop.settings";
import { Link } from "../../../../../employee-common/components/links/link";
import { LinkHandler } from "../../../../../employee-common/components/links/linkhandler";
import { LinkTypeDto } from "../../../../../employee-common/models/cms/dto/LinkType-dto";

@Component({
  selector: "app-cms-link",
  templateUrl: "./inspire-cms-link.component.html",
  styleUrls: ["./inspire-cms-link.component.scss"],
  providers: [],
})
export class InspireCmsLinkComponent implements OnInit {
  private linkHandler: LinkHandler;
  public popupSettings: LinkPopupSettings = new LinkPopupSettings();
  public constructor(private router: Router) {
    this.linkHandler = new LinkHandler(router);
  }

  ngOnInit(): void {
    if (this.isValidPopupLink) {
      this.applyWindowSize();
    }
  }

  @Input() public link: Link;
  @Input() private supportPopup: boolean = false;
  @Input() public displayText: string;
  @Input() public displayStyle: string = "contained";

  private get isValidPopupLink() {
    return this.link.linkType === LinkTypeDto.PopupLink && this.supportPopup === true;
  }

  public gotoLink(): void {
    if (this.link && this.link !== null) {
      if (this.isValidPopupLink) {
        this.applyIds();
        this.popupSettings.isVisible = true;
      } else {
        this.linkHandler.go(this.link);
      }
    }
  }

  public get icon() {
    switch (this.link.linkType) {
      case LinkTypeDto.InternalLink:
        return "fas fa-angle-right";
      case LinkTypeDto.ExternalLink:
        return "fas fa-external-link-alt";
      case LinkTypeDto.PopupLink:
        return "far fa-window-restore";
      default:
        return "link";
    }
  }

  private applyIds() {
    const urlTree = this.router.parseUrl(this.link.location).root.children["primary"];
    this.popupSettings.messageId = parseInt(urlTree.segments[1].path, 10);
    if (urlTree.segments.length > 2) {
      this.popupSettings.targetId = parseInt(urlTree.segments[2].path, 10);
    }
  }

  private applyWindowSize() {
    this.popupSettings.windowWidth = window.innerWidth;
    this.popupSettings.windowHeight = window.innerHeight;
  }
}
