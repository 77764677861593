import { Component, OnInit } from "@angular/core";

import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { CmsRoutesDto } from "../../models/cms/dto/CmsRoutes-dto";
import DxDataGrid from "devextreme/ui/data_grid";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { EmailTemplateDto } from "../../models/cms/dto/EmailTemplate-dto";
import { EmailTemplateEditService } from "../emailtemplateedit/emailtemplate-edit.service";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";
import { PlLanguageSelectionCopier } from "../../../angular-common/language/pl-language-selection-copier";
import { Router } from "@angular/router";
import { SharedExpressions } from "../../cms/shared/shared-expressions";
import { StringComparer } from "../../../angular-common/stringcomparison/stringcomparer";

@Component({
  selector: "app-emailtemplate-config",
  templateUrl: "./cms-etemplate-config.component.html",
  styleUrls: ["./cms-etemplate-config.component.scss"],
})
export class EmailTemplateConfigComponent implements OnInit {
  public templates: EmailTemplateDto[] = [];
  public popupVisible: boolean = false;

  constructor(public language: CmsLanguage, private templateService: EmailTemplateEditService, private router: Router, private plLanguageSelection: PlLanguageSelection) {
    this.title = this.title.bind(this);
  }
  ngOnInit() {
    this.retrieveTemplates();
  }

  public title(et: EmailTemplateDto) {
    let title = et.Subject[this.plLanguageSelection.currentLanguage];
    if (StringComparer.isNullUndefinedOrEmpty(title)) {
      return et.ShortName || et.Id;
    }
    return title;
  }

  retrieveTemplates() {
    this.templateService.getAll().subscribe((t) => {
      t.forEach((template) => {
        this.templates.push(template.toDTO());
      });
    });
  }

  public newEmailTemplate() {
    this.templateService.newTemplate().subscribe((t) => {
      this.templates.push(t.toDTO());
    });
  }

  public deleteEmailTemplate(input: { data: EmailTemplateDto }) {
    const template = input.data;
    if (!ElementHelper.isNullOrUndefined(template)) {
      this.templateService.deleteTemplate(template).subscribe();
    }
  }

  public routeToEdit(data: { key: EmailTemplateDto }) {
    const route = [`/${CmsRoutesDto.EmailTemplates}/${data.key.Id}`];
    this.router.navigate(route);
  }

  public onEditingStart(template: EmailTemplateDto) {
    PlLanguageSelectionCopier.copyFromOriginal(template);
  }

  public saveTemplate(template: EmailTemplateDto, dxGrid: DxDataGrid) {
    var cleanedName = SharedExpressions.cleanUpShortName(template.ShortName);
    this.popupVisible = SharedExpressions.checkforCleanedShortName(template.ShortName, cleanedName);
    template.ShortName = cleanedName;

    PlLanguageSelectionCopier.copyToOriginal(template);
    if (!ElementHelper.isNullOrUndefined(template)) {
      this.templateService.saveExistingTemplate(template).subscribe((r) => {
        dxGrid.repaint();
      });
    }
  }
}
