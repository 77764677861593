<div *ngIf="!valueOnly" class="dx-field">
  <div class="dx-field-label">{{ option.name }}:</div>
  <div class="dx-field-value">
    <dx-file-uploader
      [allowedFileExtensions]="option.fileUpload.allowedFileTypes"
      [disabled]="option.isEnabled === false"
      [maxFileSize]="option.fileUpload.maxFileSizeBytes"
      [invalidMaxFileSizeMessage]="language.f3MeWebApiTargetFileToLarge"
      uploadMode="useForm"
      [disabled]="option.isEnabled === false"
      (onValueChanged)="option.fileUpload.fileChange($event)"
      labelText=""
    >
    </dx-file-uploader>
  </div>
</div>
<div *ngIf="valueOnly" class="dx-fi">
  <dx-file-uploader
    [allowedFileExtensions]="option.fileUpload.allowedFileTypes"
    [disabled]="option.isEnabled === false"
    [maxFileSize]="option.fileUpload.maxFileSizeBytes"
    [invalidMaxFileSizeMessage]="language.f3MeWebApiTargetFileToLarge"
    uploadMode="useForm"
    [disabled]="option.isEnabled === false"
    (onValueChanged)="option.fileUpload.fileChange($event)"
    labelText=""
  >
  </dx-file-uploader>
</div>
