import { CMSModule } from "../../../../employee-common/cms/cms.module";
import { EbsModule } from "../../../../employee-common/ebs/ebs.module";
import { MainComponent } from "./main.component";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [EbsModule, CMSModule],
  exports: [],
  declarations: [MainComponent],
  providers: [],
})
export class MainModule {}
