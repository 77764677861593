export class SharedExpressions {
  static replaceWhiteSpace(name: string): string {
    let res = name.replace(/\s+/g, "_");
    return res;
  }

  static replaceSpecialCharachters(name: string): string {
    let pattern = /[^\w]/gi;
    return name.replace(pattern, "_");
  }

  static cleanUpShortName(name: string): string {
    var nameToModify = name;

    nameToModify = this.replaceSpecialCharachters(nameToModify);

    return nameToModify;
  }

  static checkforCleanedShortName(initialName: string, updatedName: string): boolean {
    if (initialName != updatedName) {
      return true;
    }
    return false;
  }
}
