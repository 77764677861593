<div *ngIf="template">
  <div class="panel-with-header-and-content">
    <h3 class="panel-header">
      <div class="subject" [class.singleline-editor]="editToggle.editing">
        <app-languageitem-editor [currentLanguage]="currentLanguage" [isEditing]="editToggle.editing" [multiLine]="false" [supportsHtml]="false" [languageItem]="subject"></app-languageitem-editor>
      </div>
      <div class="email-test">
        <app-email-component
          *ngIf="!editToggle.editing"
          [hintText]="languageService.f3MeWebApiEmailTest"
          [refreshMailOnPopup]="true"
          [emailTemplateId]="template.id"
          [disableHtmlEditor]="true"
        ></app-email-component>
      </div>
      <app-edit-toggle [editToggle]="editToggle"></app-edit-toggle>
    </h3>

    <div class="panel-content">
      <span *ngIf="editToggle.editing">{{ languageService.f3MeWebApiEmailEditTo }}</span>
      <span *ngIf="!editToggle.editing">{{ languageService.f3MeWebApiEmailEditTo }} {{ sendTo }}</span>
      <input *ngIf="editToggle.editing" type="text" maxlength="512" class="textinput singleline-editor" [(ngModel)]="sendTo" />
    </div>

    <div class="panel-content" [class.email-body-preview]="!editToggle.editing">
      <app-languageitem-editor [currentLanguage]="currentLanguage" [isEditing]="editToggle.editing" [multiLine]="true" [supportsHtml]="true" [languageItem]="body"></app-languageitem-editor>
    </div>
  </div>
</div>
