<div *ngIf="groups">
  <div class="buttonBar" *ngIf="canAddNew">
    <dx-button (click)="messageList.addNewMessage(messageType)" icon="fas fa-plus" [text]="language.ButtonNew"> </dx-button>
  </div>

  <ul class="cms-messages">
    <li class="col-lg-12" *ngFor="let message of messageList.messages">
      <app-cmsmessageedit-component [message]="message" [groups]="groups" (editingFinished)="messageList.onEditingFinished($event)" (hasBeenDelete)="messageList.onDelete($event)">
      </app-cmsmessageedit-component>
    </li>
  </ul>
</div>
