<div *ngIf="contentReady && language">
  <dx-tree-list
    id="gridContainer"
    [dataSource]="treeRow"
    [showBorders]="true"
    [rowAlternationEnabled]="true"
    [autoExpandAll]="true"
    [wordWrapEnabled]="true"
    keyExpr="Details.CmsGroupId"
    parentIdExpr="Details.ParentId"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onEditingStart)="onEditingStart($event.data)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowInserted)="onRowInserted($event)"
    (onRowRemoved)="onRowRemoved($event)"
    (onRowUpdated)="onRowUpdated($event.data, $event.component)"
  >
    <dxo-load-panel [enabled]="true"> </dxo-load-panel>
    <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"> </dxo-editing>
    <dxi-column
      width="125"
      dataField="Details.MessageTitleCopy"
      [caption]="language.f3MeWebApiEbsIntroEditHeader"
      [calculateDisplayValue]="title"
      editCellTemplate="titleEditCellTemplate"
    ></dxi-column>
    <div *dxTemplate="let titleEditCell of 'titleEditCellTemplate'">
      <app-languageitem-editor-grid [cellInfo]="titleEditCell" [item]="titleEditCell.data.Details.MessageTitleCopy"> </app-languageitem-editor-grid>
    </div>
    <dxi-column dataField="Details.ModelElementShortName" [calculateDisplayValue]="getModelShortNameDisplayValue" caption="{{ language.f3MeWebApiEbsTreeModelElement }}">
      <dxo-lookup [dataSource]="paginatedModelElements" displayExpr="displayName" valueExpr="shortName" [allowClearing]="true"> </dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="Details.SortOrder" [caption]="language.SortOrder"> </dxi-column>
    <dxi-column
      dataField="Details"
      name="modelSettings"
      caption="{{ language.f3MeWebApiEbsTreeModelElementSettings }}"
      cellTemplate="modelSettingsDisplayTemplate"
      editCellTemplate="modelSettingsTemplate"
      [encodeHtml]="false"
      width="150"
      [setCellValue]="setModelSettingsCellValue"
    ></dxi-column>
    <div *dxTemplate="let modelSettingsDisplay of 'modelSettingsDisplayTemplate'">
      <i [ngClass]="modelSettingsDisplay.data.Details.ModelElementHideValue === false ? 'notSelected' : 'selected'" class="fas fa-eye-slash"></i>&nbsp;
      <i [ngClass]="modelSettingsDisplay.data.Details.ModelElementAppliesToTotal === false ? 'notSelected' : 'selected'" class="fas fa-chart-pie"></i>&nbsp;
      <i [ngClass]="modelSettingsDisplay.data.Details.VisibilityModelElementShortName === '' ? 'notSelected' : 'selected'" class="fas fa-filter"></i>&nbsp;
    </div>
    <dxi-column
      dataField="Details"
      name="modelExtraSettings"
      cellTemplate="extraModelSettingsDisplayTemplate"
      caption="{{ language.f3MeWebApiEbsTreeAdditionalModelElement }}"
      editCellTemplate="extraModelSettingTemplate"
      width="150"
      [setCellValue]="setModelExtraSettingsCellValue"
    >
    </dxi-column>
    <div *dxTemplate="let extraModelSettingsDisplay of 'extraModelSettingsDisplayTemplate'">
      <i [ngClass]="extraModelSettingsDisplay.data.Details.AdditionalModelElementHideValue === false ? 'notSelected' : 'selected'" class="fas fa-eye-slash"></i>&nbsp;
      <i [ngClass]="extraModelSettingsDisplay.data.Details.AdditionalModelElementShortName === '' ? 'notSelected' : 'selected'" class="fas fa-filter"></i>&nbsp;
    </div>
    <dxi-column
      dataField="Details.LinkId"
      dataType="string"
      cellTemplate="linkDisplayTemplate"
      [caption]="language.f3MeWebApiEbsTreeHasLink"
      editCellTemplate="editLinkTemplate"
      width="150"
    ></dxi-column>
    <div *dxTemplate="let linkDisplay of 'linkDisplayTemplate'">
      <i [ngClass]="linkDisplayValue(linkDisplay.data) === true ? 'notSelected' : 'selected'" class="fas fa-link"></i>
    </div>
    <div *dxTemplate="let linkEdit of 'editLinkTemplate'">
      <dx-button *ngIf="isNewLink(linkEdit)" icon="fas fa-plus" [text]="language.f3MeWebApiEbsTreeNewLink" (click)="editLink(linkEdit)" [disabled]="!isContentNodeLevel"> </dx-button>
      <dx-button *ngIf="!isNewLink(linkEdit)" icon="edit" [text]="language.f3MeWebApiEbsTreeEditLink" (click)="editLink(linkEdit)" [disabled]="!isContentNodeLevel"> </dx-button>
      <dx-popup [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="showLinkEditPopup" height="auto" width="auto">
        <div *dxTemplate="let data of 'content'">
          <div class="linkEditComponentStyle">
            <app-cms-link-edit-edit-component [link]="link" [addLinkToSelf]="false" [currentLanguage]="currentLanguage"></app-cms-link-edit-edit-component>
          </div>
          <dx-button [text]="language.ButtonSave" icon="save" (click)="saveLink(linkEdit)"> </dx-button>
        </div>
      </dx-popup>
    </div>
    <dxi-column showEditorAlways="true" dataField="Details.Color" caption="{{ language.f3MeWebApiEbsTreeColor }}" width="150"></dxi-column>

    <div *dxTemplate="let modelSettings of 'modelSettingsTemplate'">
      <dx-button icon="fas fa-cog" [text]="language.Config" (click)="editModelElement()"> </dx-button>
      <dx-popup [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="showEditModelPopup" height="auto" width="auto">
        <div *dxTemplate="let popupTemplateData of 'content'">
          <dx-form [formData]="modelSettings.data.Details">
            <dxi-item dataField="ModelElementHideValue" [label]="language.f3MeWebApiEbsTreeHideModelElement"></dxi-item>
            <dxi-item dataField="ModelElementAppliesToTotal" [label]="language.f3MeWebApiEbsTreeAppliesToTotal"> </dxi-item>
            <dxi-item
              dataField="VisibilityModelElementShortName"
              [label]="language.f3MeWebApiEbsTreeVisibilityModelElement"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: paginatedModelElements,
                displayExpr: 'displayName',
                valueExpr: 'shortName',
                showClearButton: true,
                width: '300px'
              }"
            ></dxi-item>
          </dx-form>
          <dx-button [text]="language.ButtonClose" (click)="closePopupModelSettings(modelSettings)"> </dx-button>
        </div>
      </dx-popup>
    </div>
    <div *dxTemplate="let extraModelSettings of 'extraModelSettingTemplate'">
      <dx-button icon="fas fa-cog" [text]="language.Config" (click)="editExtraModel()"> </dx-button>
      <dx-popup [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="showExtraModelPopup" height="auto" width="auto">
        <div *dxTemplate="let popupExtraSettings of 'content'">
          <dx-form [formData]="extraModelSettings.data.Details">
            <dxi-item dataField="AdditionalModelElementHideValue" [label]="language.f3MeWebApiEbsTreeHideAdditionalModelElement"></dxi-item>
            <dxi-item
              dataField="AdditionalModelElementShortName"
              [label]="language.f3MeWebApiEbsTreeAdditionalModelElement"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: paginatedModelElements,
                displayExpr: 'displayName',
                valueExpr: 'shortName',
                showClearButton: true,
                width: '300px'
              }"
            ></dxi-item>
          </dx-form>
          <dx-button [text]="language.ButtonClose" (click)="closePopupModelExtraSettings(extraModelSettings)"> </dx-button>
        </div>
      </dx-popup>
    </div>
  </dx-tree-list>
</div>
