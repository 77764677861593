import { LanguageBuilder } from "../../../common/language/language-builder";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";
import { LinkDto } from "../../models/cms/dto/Link-dto";
import { LinkTypeDto } from "../../models/cms/dto/LinkType-dto";
import { PlLanguageSelection } from "../../../angular-common/language/pl-language-selection";

export class Link {
  static isNew(linkId: number) {
    return linkId === -1;
  }

  public get linkDescription(): string {
    return this.selectLanguage.currentValueFor(this.description);
  }

  public get isExternal(): boolean {
    return this.linkType === LinkTypeDto.ExternalLink;
  }

  public get canShow(): boolean {
    if (this.visible) {
      if (this.linkDescription !== undefined && this.linkDescription.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get hasVisibleLocation(): boolean {
    if (this.visible) {
      if (this.location !== undefined && this.location !== null && this.location.length > 0) {
        return true;
      }
    }
    return false;
  }

  public static createDefaultInternalLink(selectLanguage: PlLanguageSelection, text: string) {
    const link = Link.createDefault(selectLanguage);
    link.linkType = LinkTypeDto.InternalLink;

    const langBuilder = new LanguageBuilder(selectLanguage);
    link.description = langBuilder.createLanguageItemWithValue(text);

    return link;
  }

  public static createDefault(selectLanguage: PlLanguageSelection): Link {
    const link = new Link(selectLanguage);
    link.id = -1;
    link.visible = true;
    return link;
  }
  public linkType: LinkTypeDto = LinkTypeDto.None;
  public location = "";
  public description: LanguageItem;
  public visible: boolean = false;
  public id: number;
  private languageBuilder: LanguageBuilder;

  public constructor(private selectLanguage: PlLanguageSelection) {
    this.languageBuilder = new LanguageBuilder(selectLanguage);
    this.description = this.languageBuilder.createLanguageItem();
  }

  public copyFrom(source: Link) {
    if (source) {
      this.linkType = source.linkType;
      this.location = source.location;
      this.description = this.languageBuilder.copyLanguageItem(source.description);
      this.visible = source.visible;
    }
  }

  public copyFromDTO(dto: LinkDto) {
    if (dto) {
      this.linkType = dto.LinkType;
      this.location = dto.Location;
      this.visible = dto.Visible;
      this.id = dto.KeyId;
      this.description = this.languageBuilder.copyLanguageItem(dto.Description);
    }
  }

  public getCopy(): Link {
    const result = new Link(this.selectLanguage);
    result.linkType = this.linkType;
    result.location = this.location;
    result.id = this.id;
    result.description = this.languageBuilder.copyLanguageItem(this.description);
    result.visible = this.visible;
    return result;
  }

  public toDTO(): LinkDto {
    const dto = new LinkDto();
    dto.LinkType = this.linkType;
    dto.Location = this.location;
    dto.Description = this.languageBuilder.copyLanguageItem(this.description);
    dto.Visible = this.visible;
    dto.KeyId = this.id;
    return dto;
  }
}
