export class RouteLocations {
  public static moveToLocation(path: string) {
    if (path && path.length > 0) {
      if (RouteLocations.isNoneRelativeLocation(path)) {
        RouteLocations.setCurrentLocation(path);
      } else {
        if (path.charAt(0) !== "/") {
          path = "/" + path;
        }
        const location = (window as any).location;
        path = location.protocol + "//" + location.host + path;
        RouteLocations.setCurrentLocation(path);
      }
    }
  }

  private static setCurrentLocation(path: string) {
    if (path && path.length > 0) {
      return ((window as any).location = path);
    }
  }

  private static isNoneRelativeLocation(path: string) {
    path = path.toLowerCase();
    return path.startsWith("http");
  }

  public static routesAreSame(pathA: string, pathB: string) {
    if (pathA.startsWith("/") === false) {
      pathA = "/" + pathA;
    }
    if (pathB.startsWith("/") === false) {
      pathB = "/" + pathB;
    }

    if (pathA.toLowerCase() === pathB.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  }
}
