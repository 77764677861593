import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DxTextBoxModule } from 'devextreme-angular';
import { LanguageItemEditorGridComponent } from "./languageitem-editor-grid.component";

@NgModule({
    imports: [CommonModule, FormsModule, DxTextBoxModule],
    declarations: [LanguageItemEditorGridComponent],
    exports: [LanguageItemEditorGridComponent],
    providers: [],
})
export class LanguageItemEditorGridModule { }
