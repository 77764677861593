<div *ngIf="contentLoaded">
    <div class="buttonBar">
        {{language.f3MeWebApiUserFieldsHeader}}
    </div>

    <ul class="list">
        <li *ngFor="let userField of userFields">
            <div class="panel-with-header-and-content">
                <h3 class="panel-header">{{userField.userField.title}}</h3>
                <div class="panel-content">
                    <dx-select-box [dataSource]="userField.exogenousVariables.all" [(value)]="userField.exogenousVariable" displayExpr="displayName" [showClearButton]="true"></dx-select-box>
                </div>
            </div>
        </li>
    </ul>

</div>