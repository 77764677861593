<div class="appinfo-container" *ngIf="aboutInfo">
  <div>
    <h3>{{ aboutInfo.userInformationHeader }}</h3>
    <pre>{{ aboutInfo.userInformationText }}</pre>
  </div>
  <div>
    <h3>{{ aboutInfo.productInformationHeader }}</h3>
    <pre>{{ aboutInfo.productInformationText }}</pre>
  </div>
  <div>
    <h3>{{ aboutInfo.connectionsHeader }}</h3>
    <pre>{{ aboutInfo.connectionsText }}</pre>
  </div>
</div>
