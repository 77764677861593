import { EnvironmentSettings } from "../../../../angular-common/baseservice/environment-settings";
import { FileUpload } from "../../../../angular-common/components/fileupload/fileupload";
import { ReportDataDto } from "../../../models/reporting/dto/ReportDataDto-dto";

export class ReportData {
  constructor() {
    const envSetting = new EnvironmentSettings();
    this.fileUpload = new FileUpload(envSetting.maxUploadSizeMb, this.fileTypeAllowed);
    this.fileUpload.onFileUploaded.subscribe(() => this.fileUploaded());
  }
  keyid: number = -1;
  shortName: string = "";
  fileName: string = "";
  dateAdded: string | number | Date = new Date(Date.now());
  dateModified: string | number | Date = new Date(Date.now());
  lastEditedBy: string = "";
  fileTypeAllowed: string[] = [];
  data: string = "";

  public fileUpload: FileUpload;

  private fileUploaded() {
    this.data = this.fileUpload.fileData;
    this.fileName = this.fileUpload.fileName;
  }

  public get isNew() {
    return this.keyid == -1;
  }

  toDto(): ReportDataDto {
    const dto = new ReportDataDto();
    dto.KeyId = this.keyid;
    dto.DateAdded = this.dateAdded as Date;
    dto.DateModified = this.dateModified as Date;
    dto.LastEditedBy = this.lastEditedBy;
    dto.Content = this.data;
    dto.ShortName = this.shortName;
    dto.FileName = this.fileName;

    return dto;
  }

  fromDto(dto: ReportDataDto) {
    this.keyid = dto.KeyId;
    this.shortName = dto.ShortName;
    this.lastEditedBy = dto.LastEditedBy;
    this.dateAdded = dto.DateAdded;
    this.dateModified = dto.DateModified;
  }
}
