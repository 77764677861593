import { ReplaySubject } from "rxjs";

export class TextPopupInteraction {
  public constructor(public title: string, public message: string, public _okButton: string, public _cancelButton: string) {}

  public get isVisible(): boolean {
    return this._isVisible;
  }
  public set isVisible(newIsVisible: boolean) {
    if (newIsVisible) {
      // Reset status of confirmed property
      this.confirmed = false;
    } else {
      if (this._isVisible) {
        const fire: boolean = this.ignoreCancelOnConfirmedChanged === false || this.confirmed === true;

        // Popup was open, not anymore. Emit new status.
        if (fire) {
          this._confirmedChangedSource.next(this.confirmed);
        }
      }
    }

    this._isVisible = newIsVisible;
  }
  public get okButton() {
    return this._okButton;
  }

  public get cancelButton() {
    return this._cancelButton;
  }

  private _isVisible: boolean = false;

  public closePopup(newConfirmed: boolean) {
    this.confirmed = newConfirmed;
    this.isVisible = false;
  }

  public confirmed = false;

  public showTextInput: boolean = false;
  public textInput: string = "";
  public context: any;

  // When true, the confirmedChanged is only fired when the user pressed 'ok'
  public ignoreCancelOnConfirmedChanged = true;

  public get okEnabled(): boolean {
    const result = this.showTextInput === false || (this.textInput && this.textInput !== null && this.textInput.length > 0);
    return result;
  }

  public showOkButton: boolean = true;

  private _confirmedChangedSource = new ReplaySubject<boolean>();
  public confirmedChanged = this._confirmedChangedSource.asObservable();
}
