import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PluginSpecificationDto } from "../models/dto/PluginSpecificationDto-dto";
import { PluginSpecificationMetaDataDto } from "../models/dto/PluginSpecificationMetaDataDto-dto";

@Injectable({
  providedIn: "root",
})
export class PluginService extends PlBaseService {
  private endPoint: string = "plugin";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  getPlugins(): Observable<PluginSpecificationMetaDataDto> {
    return this.getData<PluginSpecificationMetaDataDto>(this.endPoint);
  }

  getPlugin(shortName: string): Observable<PluginSpecificationDto> {
    return this.getData<PluginSpecificationDto>(`${this.endPoint}/${shortName}`);
  }

  createPlugin(plugin: PluginSpecificationDto): Observable<EntryCreated<PluginSpecificationDto>> {
    return this.createData<PluginSpecificationDto>(this.endPoint, plugin);
  }

  updatePlugin(plugin: PluginSpecificationDto): Observable<PluginSpecificationDto> {
    return this.update<PluginSpecificationDto>(plugin, `${this.endPoint}/${plugin.KeyId}`);
  }

  deletePlugin(id: number): Observable<any> {
    return this.remove(`${this.endPoint}/${id}`);
  }
}
