import { DxButtonModule, DxPopupModule, DxTextBoxModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LanguageSelectionModule } from "../../../angular-common/components/languageselection/language-selection.module";
import { CompanyLogo } from "./company-logo/company-logo.component";
import { ShowInitials } from "./initialsOnly.pipe";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { PersonalMenuComponent } from "./personal-menu/personal-menu.component";
import { PortalMenuComponent } from "./portal-menu/portal-menu.component";

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, LanguageSelectionModule, DxPopupModule, DxButtonModule, DxTextBoxModule],
  declarations: [PersonalMenuComponent, MainMenuComponent, PortalMenuComponent, ShowInitials, CompanyLogo],
  exports: [PersonalMenuComponent, MainMenuComponent, PortalMenuComponent, CompanyLogo],
})
export class MenusModule {}
