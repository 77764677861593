import { ExogenousInputNumericOptionDto } from "../../../../models/me/dto/ExogenousInputNumericOption-dto";
import { ModifiedExogenousInputOptionDto } from "../../../../models/me/dto/ModifiedExogenousInputOption-dto";
import { ExogenousInputOptionTypes } from "./exogenous-input-option-types.model";
import { ExogenousInputOption } from "./exogenous-input-option.model";
import { ExogenousInputOptionType } from "./option-type.model";

export class ExogenousInputOptionNumeric extends ExogenousInputOption {
  public constructor() {
    super(ExogenousInputOptionType.Numeric);
  }

  private _value: string;
  public get value(): string {
    return this._value;
  }
  public set value(newValue: string) {
    this._value = newValue;
    this.changed();
  }

  public unit: string;
  public valueAsNumber: number;

  public copyFromDTO(dto: ExogenousInputNumericOptionDto) {
    super.copyFromDTO(dto);
    this._value = dto.Value;
    this._previousValue = this._value;
    this.unit = dto.Unit;
    this.valueAsNumber = dto.ValueAsNumber;
  }

  public toDTO(dto: ModifiedExogenousInputOptionDto) {
    super.toDTO(dto);
    dto.Type = ExogenousInputOptionTypes.typeNumeric;
    dto.Value = this.value;
  }

  public changed() {
    if (this._value !== this._previousValue) {
      super.changed();
      this._previousValue = this._value;
    }
  }

  private _previousValue: string;
}
