import { Component, Input } from "@angular/core";

import { CMSMessageList } from "../cms-messagelist.model";
import { CmsLanguage } from "../language/cmslanguage.service";

@Component({
  selector: "app-simulationblock-component",
  templateUrl: "./simulationblock.component.html",
  styleUrls: ["./simulationblock.component.scss", "./../shared/shared-styles.scss"],
  providers: [],
})
export class SimulationBlockComponent {
  @Input() messageList: CMSMessageList;

  public constructor(public language: CmsLanguage) {}
}
