import { CmsLanguage } from "../../cms/language/cmslanguage.service";
import { LanguageEditItemBase } from "./languageedititembase.model";
import { LanguageEditService } from "./languageedit.service";
import { LanguageItem } from "../../../angular-common/language/language-dto.model";

export class LanguageEditItem extends LanguageEditItemBase {
  public constructor(
    private languageService: CmsLanguage,
    private languageEditService: LanguageEditService,
    id: string,
    descriptionId: string,
    editable: boolean,
    supportsHtml: boolean,
    public value: LanguageItem,
  ) {
    super();

    this.descriptionId = descriptionId;
    this.editable = editable;
    this.id = id;
    this.supportsHtml = supportsHtml;
  }

  public get description(): string {
    if (this.descriptionId !== this.id) {
      const desc = this.languageService.getStringDef(this.descriptionId, "");
      if (desc === undefined || desc.length === 0) {
        return this.descriptionId;
      } else {
        return desc;
      }
    } else {
      return this.languageService.f3MeWebApiAppHeaderFor(this.id);
    }
  }

  public setValueAndSendToServer(newValue: LanguageItem) {
    const builder = this.languageService.languageBuilder();
    this.value = builder.copyLanguageItem(newValue);
    this.languageEditService.save(this.id, this.value);
  }
}
