import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { MenuItem, MenuService } from "../menus";

import { Injectable, inject } from "@angular/core";
import { EnvironmentSettings } from "../baseservice/environment-settings";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { MenuFunctions } from "../menus/menu-functions";
import { AuthGuard } from "./authentication-guard";

// Implementation of CanActivate is not integrated into the AuthenticationService because that
// results in a cyclic dependency for the router instance (router depends on AuthGuard, AuthenticationService depends on router)

@Injectable()
export class RoleGuard extends AuthGuard {
  public static currentUserCanActivateMenuItem(menuItem: MenuItem): boolean {
    return RoleGuard.currentUserMissingRoles(menuItem).length === 0;
  }

  public static filterForActiveUser(menuItems: MenuItem[]): MenuItem[] {
    if (menuItems === undefined || menuItems === null || menuItems.length === 0) {
      return [];
    }

    const result: MenuItem[] = [];
    menuItems.forEach((subMenuItem) => {
      if (RoleGuard.currentUserCanActivateMenuItem(subMenuItem)) {
        result.push(subMenuItem);
      }
    });
    return result;
  }

  public static currentUserMissingRoles(menuItem: MenuItem): string[] {
    if (menuItem.requiredRoles.length === 0) {
      return [];
    } else {
      const missingRoles: string[] = [];
      let anyCorrectRole = false;
      menuItem.requiredRoles.forEach((requiredRole) => {
        if (PlUserStorage.hasRole(requiredRole)) {
          anyCorrectRole = true;
        } else {
          missingRoles.push(requiredRole);
        }
      });
      if (anyCorrectRole) {
        return [];
      } else {
        return missingRoles;
      }
    }
  }
  public constructor(router: Router, environmentSettings: EnvironmentSettings, private menuService: MenuService) {
    super(router, environmentSettings);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return super.canActivate(route, state).pipe(
      mergeMap((r) => {
        if (r === false) {
          return of(false);
        }

        const routeLocation = this.basicLocationFrom(route, state);
        return this.menuService.getMenuItems().pipe(
          map((ms) => {
            const missingRoles = this.currentUserCanActivateMissingRoles(routeLocation, ms);
            return missingRoles.length === 0;
          }),
        );
      }),
    );
  }

  private basicLocationFrom(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    if (route.data && route.data[0]) {
      const inheritedRolesFrom = route.data[0]["inheritRolesFrom"];
      if (inheritedRolesFrom && inheritedRolesFrom !== null) {
        return inheritedRolesFrom;
      }
    }

    let routeLocation = state.url;
    if (routeLocation !== undefined) {
      const routeLocationIndex = routeLocation.indexOf(";");
      if (routeLocationIndex > 0) {
        routeLocation = routeLocation.substring(0, routeLocationIndex);
      }

      const routeParamIndex = routeLocation.indexOf("?");
      if (routeParamIndex > 0) {
        routeLocation = routeLocation.substring(0, routeParamIndex);
      }
    }

    return routeLocation;
  }

  private currentUserCanActivateMissingRoles(route: string, menuItemsFromApi: MenuItem[]): string[] {
    let missingRoles: string[] = ["unknown"];
    const menuItemForRoute = MenuFunctions.findMenuItem(route, menuItemsFromApi);
    if (menuItemForRoute && menuItemForRoute !== null) {
      missingRoles = RoleGuard.currentUserMissingRoles(menuItemForRoute);
      if (missingRoles.length > 0) {
        console.log("User can not activate route: '" + route + "' menu item requires: " + menuItemForRoute.requiredRoles);
      }
    } else {
      console.log("No menu item matching route: " + route);
      return [];
    }
    return missingRoles;
  }
}

export const canActivateRoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(RoleGuard).canActivate(route, state);
};
