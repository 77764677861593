import { Component, Input } from "@angular/core";

import { MessageRatingFeatureModel } from "../../rating/messagerating/message-rating-feature-model";
import { CmsFeaturesModel } from "../cms-features-model";
import { CMSMessage } from "../cms-message.model";
import { BaseCMSMessageComponent } from "../shared/cms.basemessage.component";

@Component({
  selector: "app-newsmessage-component",
  templateUrl: "./news-message.component.html",
  styleUrls: ["./news-message.component.scss", "./../shared/shared-styles.scss"],
})
export class NewsMessageComponent extends BaseCMSMessageComponent {
  private ratingFeatures: MessageRatingFeatureModel;

  public constructor(features: CmsFeaturesModel) {
    super();
    this.ratingFeatures = new MessageRatingFeatureModel(features);
  }

  public get ratingEnabled(): boolean {
    return this.ratingFeatures.rateNews;
  }

  @Input() message: CMSMessage;
  @Input() index: number;
}
