import { RouterModule, Routes } from "@angular/router";

import { NgModule } from "@angular/core";
import { LoginLandingPageModule } from "../../../angular-common";
import { AuditLogsModule } from "../../../angular-common/components/auditing/audit-logs.module";
import { AuthorizationEditModule } from "../../../angular-common/components/authorization/authorization-edit.module";
import { PlLoginComponent } from "../../../angular-common/components/login/login.component";
import { PreferredModelModule } from "../../../angular-common/components/preferred-model/preferred-model.module";
import { TechnicalMaintainerModule } from "../../../angular-common/components/technicalmaintainer/technicalmaintainer.module";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { CmsRoutingModule } from "../../../employee-common/cms/cms-routing.module";
import { CMSModule } from "../../../employee-common/cms/cms.module";
import { CmsManagementModule } from "../../../employee-common/cms/management/cms-management.module";
import { PluginManagementModule } from "../../../employee-common/cms/plugin/manage/plugin-management.module";
import { UserFieldsEditModule } from "../../../employee-common/cms/user-fields/userfieldsedit.module";
import { EmployeeLoginModule } from "../../../employee-common/components/login/employeelogin.module";
import { EbsModule } from "../../../employee-common/ebs/ebs.module";
import { EbsManagementModule } from "../../../employee-common/ebs/management/ebs-management.module";
import { EmployeeEmailModule } from "../../../employee-common/email/employee-email.module";
import { FixedImagesModule } from "../../../employee-common/fixedimages/fixedimages.module";
import { HomeModule } from "../../../employee-common/home/home.module";
import { LabourConditionsContentModule } from "../../../employee-common/labourconditions/labourcondtions-content.module";
import { LabourConditionDetailModule } from "../../../employee-common/labourconditions/labourcondtition-detail/labour-detail.module";
import { UserEmployeeMappingModule } from "../../../employee-common/management/mapping/prefered-user-employee-mapping/user-employee-mapping.module";
import { RatingModule } from "../../../employee-common/rating/components/rating.module";
import { MessageRatingDashboardsModule } from "../../../employee-common/rating/dashboard/message-rating-dashboard.module";
import { MessageRatingModule } from "../../../employee-common/rating/messagerating/message-rating.module";
import { ReportingManagementModule } from "../../../employee-common/reporting/management/reporting-management.module";

const routes: Routes = [
  { path: CommonRoutesDto.Login, component: PlLoginComponent },
  { path: "**", redirectTo: CommonRoutesDto.LandingPage },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    ReportingManagementModule,
    CMSModule,
    EbsModule,
    HomeModule,
    EbsManagementModule,
    RatingModule,
    MessageRatingModule,
    MessageRatingDashboardsModule,
    EmployeeEmailModule,
    LoginLandingPageModule,
    EmployeeLoginModule,
    CmsRoutingModule,
    CmsManagementModule,
    LabourConditionDetailModule,
    LabourConditionsContentModule,
    TechnicalMaintainerModule,
    AuditLogsModule,
    AuthorizationEditModule,
    UserEmployeeMappingModule,
    FixedImagesModule,
    PluginManagementModule,
    PreferredModelModule,
    UserFieldsEditModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
