export class HTMLEditorLinkPrefixHelper {
  public static addHttpsPrefixWhenRequired(url: string, protocolsWhiteList: string[]): string {
    if (this.startsWithWhitelistedProtocol(url, protocolsWhiteList)) {
      return url;
    } else {
      return `https://${url}`;
    }
  }

  private static startsWithWhitelistedProtocol(url: string, whiteList: string[]): boolean {
    return whiteList.some((item) => url.startsWith(item));
  }
}
