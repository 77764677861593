import { EbsGroupInformationDto } from './EbsGroupInformationDto-dto';

// t4tsCode gen": "0.8.2.0 for file EbsNodeDto.cs
// Don't adjust manually!

export class EbsNodeDto {
    public Details: EbsGroupInformationDto;
    public Children: EbsNodeDto [] = [];
}

