
// t4tsCodeFromCs": "0.8.2.0 for file LanguageMetaData.cs
// Don't adjust manually!

export class LanguageMetaDataDto {
    public DisplayName: string;
    public Id: string;
    public ImageUrl: string;
}

