import { Component, Input } from "@angular/core";

import { LanguageItem } from "../../../../angular-common/language/language-dto.model";

@Component({
  selector: "app-languageitem-editor",
  styleUrls: ["./languageitem-editor.component.scss"],
  templateUrl: "./languageitem-editor.component.html",
})
export class LanguageItemEditorComponent {
  public constructor() {}

  @Input() languageItem: LanguageItem = undefined;
  @Input() isEditing: boolean = false;
  @Input() supportsHtml: boolean = true;
  @Input() currentLanguage: string = undefined;
  @Input() multiLine: boolean = true;

  public get currentText(): string {
    if (this.languageItem === undefined || this.languageItem === null) {
      return "";
    }

    const inCurrentLang = this.languageItem[this.currentLanguage];
    if (inCurrentLang === undefined) {
      if (this.languageItem.length > 0) {
        return this.languageItem[0];
      } else {
        return "";
      }
    }
    return inCurrentLang;
  }
  public set currentText(newText: string) {
    if (this.languageItem !== undefined && this.languageItem !== null) {
      this.languageItem[this.currentLanguage] = newText;
    }
  }

  public get showReadonlyText(): boolean {
    return this.isEditing === false;
  }

  public get showHtmlEditor(): boolean {
    return this.isEditing === true && this.multiLine === true && this.supportsHtml;
  }

  public get showMultiLineEditor(): boolean {
    return this.isEditing === true && this.multiLine === true && this.supportsHtml === false;
  }

  public get showSingleLineEditor(): boolean {
    return this.isEditing === true && this.multiLine === false && this.supportsHtml === false;
  }
}
