import { Directive, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { RouteNavigation } from "../../../../../baseservice/route-navigation";
import { ElementHelper } from "../../../../../helpers/element.helper";
import { Tabs } from "../../../../../tabs/tabs";
import { AuthorizationContentType } from "../../../models/authorization-content.types";

@Directive()
export abstract class AuthorizationTabsBaseDirective implements OnInit {
  public tabs: Tabs;
  public selectedTab: number;
  public authorizationContentTypes = AuthorizationContentType;

  public constructor(private routeName: string, private routeNavigation: RouteNavigation, private route: ActivatedRoute) {}

  protected abstract createTabs(tabstoBuild: Tabs): void;

  ngOnInit() {
    const newtabs = new Tabs();
    this.createTabs(newtabs);
    this.tabs = newtabs;
    this.navigateToItemFromUri();
  }

  private navigateToItemFromUri() {
    if (this.tabs.items.length > 0) {
      this.tabs.selectedTab = this.tabs.items[0];
    }

    if (this.tabs.items.length > 0) {
      this.route.params.subscribe((params) => {
        if ("id" in params) {
          const paramsId = parseInt(params.id, 10);
          if (this.tabs.items.find((tab) => tab.id === paramsId)) {
            this.selectedTab = paramsId;
            const selectedTab = this.tabs.items.find((x) => x.id === paramsId);
            this.tabs.selectedTab = selectedTab;
          } else {
            this.selectedTab = this.tabs.items[0].id;
          }
        }
      });

      if (ElementHelper.isNullOrUndefined(this.selectedTab)) {
        this.navigateToTabId(this.tabs.items[0].id);
      }
    }
  }

  selectTab(e) {
    const tabData = e.itemData;
    this.navigateToTabId(tabData.id);
  }

  private navigateToTabId(idOfDataSupplyTab: number) {
    this.routeNavigation.navigateWithParameterId(this.routeName, idOfDataSupplyTab);
    this.selectedTab = idOfDataSupplyTab;
  }
}
