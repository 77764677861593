import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PlBaseService, PlUserStorage } from "../../../angular-common";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { UISettingsDto } from "../../models/cms/dto/UISettings-dto";
import { UISettings } from "./ui-settings";

@Injectable()
export class UserSettingsService extends PlBaseService {
  private endPoint: string = "settings";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);

    PlUserStorage.userChanged.subscribe((v) => {
      this.buffer = null;
    });
  }

  private extractUISettings(uiSettingsDTO: UISettingsDto): UISettings {
    this.buffer = new UISettings();
    this.buffer.copyFromDTO(uiSettingsDTO);
    return this.buffer;
  }

  public getSettings(): Observable<UISettings> {
    return this.getData<UISettingsDto>(this.endPoint).pipe(map(this.extractUISettings, this));
  }

  private buffer: UISettings = null;

  public reset() {
    this.buffer = null;
  }

  public shouldRequireValidateActual() {
    if (this.buffer && this.buffer !== null) {
      return of(this.shouldRequireValidatedFromBuffer);
    } else {
      return this.getSettings().pipe(
        map((_) => {
          return this.shouldRequireValidatedFromBuffer;
        }, this),
      );
    }
  }

  public get shouldRequireValidatedFromBuffer() {
    if (this.buffer && this.buffer !== null) {
      if (this.buffer.application.requireAcknowledgeDisclaimer) {
        return this.buffer.user.acknowledgedDisclaimer === false;
      } else {
        return false;
      }
    }
    return true;
  }

  public get requireAcknowledgeDisclaimer(): boolean {
    if (this.buffer && this.buffer !== null) {
      return this.buffer.application.requireAcknowledgeDisclaimer;
    } else {
      return false;
    }
  }

  public get acknowledgedDisclaimer(): boolean {
    if (this.buffer && this.buffer !== null) {
      return this.buffer.user.acknowledgedDisclaimer;
    } else {
      return false;
    }
  }
  public set acknowledgedDisclaimer(newValue: boolean) {
    if (this.buffer && this.buffer !== null) {
      this.buffer.user.acknowledgedDisclaimer = newValue;

      const dto = this.buffer.user.toDTO();
      this.update(dto, this.endPoint).subscribe();
    }
  }
}
