import { Component, DoCheck, Input, OnInit } from "@angular/core";

import { CmsLanguage } from "../../../cms/language/cmslanguage.service";
import { ExogenousInputComputedField } from "../exogenous-input/exogenous-input-computedfield";
import { ExogenousInputOption } from "../exogenous-input/options/exogenous-input-option.model";
import { IGetOptionCurrentValue } from "./get-option-current-value.model";
import { OptionWithCurrentValue } from "./option-with-current-value";

@Component({
  selector: "app-source-values-component",
  templateUrl: "./source-values.component.html",
  styleUrls: ["./source-values.component.scss", "./shared.scss"],
  providers: [],
})
export class SourceValuesComponent implements OnInit, DoCheck, IGetOptionCurrentValue {
  public constructor(public languageService: CmsLanguage) {
    this.currentValues = null;
  }

  public ngOnInit(): void {
    this.refreshCombinedOptions();
  }

  public ngDoCheck() {
    if (this.options && this.combinedOptions) {
      let ok = "";
      this.options.forEach((x) => (ok += x.identification));
      let co = "";
      this.combinedOptions.forEach((x) => (co += x.option.identification));

      if (ok !== co) {
        this.refreshCombinedOptions();
      }
    }
  }

  private refreshCombinedOptions() {
    const list = new Array<OptionWithCurrentValue>();

    this.options.forEach((option) => {
      const ocv = new OptionWithCurrentValue(this.languageService, option, this);
      list.push(ocv);
    });

    this.combinedOptions = list;
  }

  @Input()
  public get options(): ExogenousInputOption[] {
    return this._options;
  }
  public set options(newOptions: ExogenousInputOption[]) {
    this._options = newOptions;
  }
  private _options: ExogenousInputOption[];

  @Input()
  public currentValues: ExogenousInputComputedField[];

  public getOptionCurrentValue(optionShortName: string): ExogenousInputComputedField {
    if (this.currentValues && this.currentValues !== null) {
      return this.currentValues.find((x) => x.relatedSourceName === optionShortName);
    }
    return null;
  }

  public combinedOptions: OptionWithCurrentValue[];
}
