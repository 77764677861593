import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { RatingLanguage } from "../language/ratinglanguage.service";
import { RatingIconStyle } from "./rating-icon-style";
import { RatingIdService } from "./rating-id.service";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
})
export class RatingComponent implements OnInit {
  constructor(ratingId: RatingIdService, public language: RatingLanguage) {
    this.componentId = "rating" + ratingId.next;
  }

  ngOnInit() {}

  public componentId: string;

  @Input()
  public get numberOfIcons(): number {
    return this._numberOfIcons;
  }
  public set numberOfIcons(newNumber: number) {
    if (newNumber > 0) {
      this._numberOfIcons = newNumber;
    }
  }
  private _numberOfIcons: number = 5;

  @Input()
  public toolTip: string = undefined;

  @Input()
  public get score(): number {
    return this._score;
  }
  public set score(newScore: number) {
    if (newScore >= 0) {
      this._score = newScore;
    }
  }
  private _score: number = 0;

  @Input()
  public get maximumScore(): number {
    return this._maximumScore;
  }
  public set maximumScore(newMax: number) {
    if (newMax > 0) {
      this._maximumScore = newMax;
    }
  }
  private _maximumScore: number = 5;

  public get numbers(): number[] {
    const result: number[] = [];
    for (let i = 0; i < this.numberOfIcons; i++) {
      result.push(i);
    }
    return result;
  }

  private getScoreForIconStyle(): number {
    if (this.isMouseOverIcon) {
      return this.scoreForMouseOver;
    }
    return this.score;
  }

  public getIconStyle(iconIndex: number): RatingIconStyle {
    const score = this.getScoreForIconStyle();
    const scorePerIcon = this.maximumScore / this.numberOfIcons;
    const lowerBound = iconIndex * scorePerIcon;
    const upperBound = lowerBound + scorePerIcon;

    if (score <= lowerBound) {
      return RatingIconStyle.Empty;
    } else if (score >= upperBound) {
      return RatingIconStyle.Solid;
    } else {
      const threshold = scorePerIcon / 3;
      if (score <= lowerBound + threshold) {
        return RatingIconStyle.Empty;
      } else if (score >= upperBound - threshold) {
        return RatingIconStyle.Solid;
      }
    }
    return RatingIconStyle.Half;
  }

  private calculateScore(iconIndex: number) {
    return (this.maximumScore / this.numberOfIcons) * (iconIndex + 1);
  }

  public hoverOver(iconIndex: number, isMouseOver: boolean) {
    this.isMouseOverIcon = isMouseOver;
    this.scoreForMouseOver = this.calculateScore(iconIndex);
  }
  private isMouseOverIcon: boolean = false;
  private scoreForMouseOver: number = 0;

  public click(iconIndex: number) {
    this.showThanks = true;
    const score = this.calculateScore(iconIndex);
    this.onScore.emit(score);
  }

  public showThanks: boolean = false;

  @Output()
  public onScore = new EventEmitter<number>();
}
