import { Injectable } from "@angular/core";
import { FeatureChoicesDto, FeatureCmsDto } from "./../models/cms/dto/info/cmsFeaturesDto";
import { FeatureRatingDto, FeatureReportingDto } from "./../models/ebs/dto/info/ebsFeaturesDto";

const globalVariableSpace: any = window;
// eslint-disable-next-line @typescript-eslint/naming-convention
const pl_hr_f3_featuresSettings = (globalVariableSpace || {}).pl_hr_f3_featuresSettings || {};

@Injectable()
export class CmsFeaturesModel {
  public data: {};

  public get Cms(): FeatureCmsDto {
    return (<any>this.data).Cms || {};
  }

  public get Rating(): FeatureRatingDto {
    const ratingData: FeatureRatingDto = (<any>this.data).Rating;
    if (ratingData && ratingData.enabled) {
      return ratingData;
    } else {
      return <FeatureRatingDto>{};
    }
  }
  public get choices(): FeatureChoicesDto {
    const choicesData: FeatureChoicesDto = (<any>this.data).Choices;
    if (choicesData && choicesData.enabled) {
      return choicesData;
    } else {
      return <FeatureChoicesDto>{};
    }
  }

  public get Reporting(): FeatureReportingDto {
    return (<any>this.data).Reporting || {};
  }

  constructor() {
    this.data = pl_hr_f3_featuresSettings || {} || {};
  }

  public searchEnabled() {
    const cms = this.Cms;
    if (cms && cms.enabled) {
      if (cms.search_enabled) {
        return true;
      }
    }
    return false;
  }

  public widerWidthNewsBeforeCarouselEnabled() {
    const cms = this.Cms;
    if (cms && cms.enabled) {
      if (cms.news_enabled && cms.wider_width_news_before_carousel) {
        return true;
      }
    }
    return false;
  }

  public get miniEbsEnabled() {
    const cms = this.Cms;
    if (cms && cms.enabled) {
      const miniEbsValue = cms.mini_ebs_enabled;
      if (miniEbsValue === undefined) {
        return true;
      }
      return miniEbsValue;
    }
    return false;
  }
}
