import { ElementTypeDto } from './ElementType-dto';
import { PresentableElementDto } from './PresentableElement-dto';

// t4tsCodeFromCs": "0.8.2.0 for file ModelPresentableElement.cs
// Don't adjust manually!

export class ModelPresentableElementDto extends PresentableElementDto {
    public TypeOfElement: ElementTypeDto;
}

