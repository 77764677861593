import { ElementHelper } from "./element.helper";

export class StringHelper {
  static onlyAlfaNumericReplaceWith(value: string, replaceWith: string): string {
    if (value) {
      return value.replace(/[\W_]+/g, replaceWith);
    } else {
      return value;
    }
  }
  public static onlyAlfaNumeric(value: string): string {
    return StringHelper.onlyAlfaNumericReplaceWith(value, "");
  }

  public static hasNonEmptyText(text: string) {
    if (ElementHelper.isNullOrUndefined(text)) {
      return false;
    }
    if (typeof text !== "string") {
      return false;
    }

    return text.trim().length > 0;
  }

  public static equalsIgnoreCase(first: string, second: string) {
    if (first.toLowerCase() === second.toLowerCase()) {
      return true;
    }
    return false;
  }
}
