import { Component, OnInit } from "@angular/core";
import { RouteNavigation } from "../../../../angular-common/baseservice/route-navigation";
import { WarningProvider } from "../../../../angular-common/warning-provider/warning-provider";
import { WarningType } from "../../../../angular-common/warning-provider/warning-type";
import { FixedImageModel } from "../../../fixedimages/fixedimage.model";
import { ImageService } from "../../images/image.service";

@Component({
  selector: "app-company-logo",
  templateUrl: "./company-logo.component.html",
  styleUrls: ["./company-logo.component.scss"],
})
export class CompanyLogo implements OnInit {
  public constructor(private imageService: ImageService, private routeNavigation: RouteNavigation, private warningProvider: WarningProvider) {}

  public urlLogo: FixedImageModel = FixedImageModel.createEmpty();

  ngOnInit(): void {
    this.imageService.urlLogo.subscribe((data) => {
      this.urlLogo = data;
    });
  }

  public goHome(): void {
    this.routeNavigation.goToRoot();
  }

  public get preferredModelFromSession(): string {
    return this.warningProvider.warningFor(WarningType.SessionModelActive);
  }
}
