import { Sorter } from "../../angular-common/sorting";
import { ModelElement } from "../model/models/model-element.model";

export class ModelElements<T extends ModelElement> {
  public constructor(private _modelElements: T[]) {
    if (_modelElements === undefined || _modelElements === null) {
      _modelElements = [];
    }
  }

  public get all(): T[] {
    return this._modelElements;
  }

  public find(shortName: string): T {
    if (shortName === undefined || shortName === null || this.all === undefined || this.all === null) {
      return null;
    }

    return this.all.find(x => x.shortName.toLowerCase() === shortName.toLowerCase());
  }

  public add(element: T) {
    this._modelElements.push(element);
  }

  public sortSortOrderThenAlpha() {
    Sorter.sortSortOrderThenAlpha(this._modelElements, i => i.longName);
  }
}
