import { BehaviorSubject, Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { EnvironmentSettings } from "../../../angular-common/baseservice/environment-settings";
import { PlBaseService } from "../../../angular-common/baseservice/plbase.service";
import { PlLoadIndicator } from "../../../angular-common/loadindicator/loadindicator";
import { MessageTypeDto } from "../../models/cms/dto/MessageType-dto";
import { MessageRatingsDto } from "../dto/MessageRatings-dto";
import { UserRatingDto } from "../dto/UserRating-dto";
import { MessageRatingBuffer } from "./message-rating-buffer";
import { MessageRatingMetaData } from "./models/message-rating-metadata.model";
import { MessageRating } from "./models/message-rating.model";
import { MessageRatings } from "./models/message-ratings.model";

@Injectable()
export class MessageRatingService extends PlBaseService {
  private endPoint: string = "messagerating";
  private buffer: MessageRatingBuffer;

  constructor(http: HttpClient, envSettings: EnvironmentSettings, loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
    this.buffer = new MessageRatingBuffer(this);
  }

  private extractRatings(dto: MessageRatingsDto): MessageRatings {
    const result = new MessageRatings();
    result.copyFromDto(dto);
    return result;
  }

  public getMetaData(messageType: MessageTypeDto): MessageRatingMetaData {
    return this.buffer.getRatingMetaDataFor(messageType);
  }

  public getRating(messageType: MessageTypeDto, messageId: number): MessageRating {
    return this.buffer.getRatingFor(messageType, messageId);
  }

  public getRatings(messageType: MessageTypeDto): Observable<MessageRatings> {
    const url = this.envSettings.combinePath(this.endPoint, messageType.toString());
    return this.getData<MessageRatingsDto>(url).pipe(map(this.extractRatings, this));
  }

  public postRating(messageType: MessageTypeDto, messageId: number, score: number) {
    const userRating = new UserRatingDto();
    userRating.MessageId = messageId;
    userRating.UserScore = score;
    const obs = this.postData(this.endPoint, userRating).pipe(
      map((r) => {
        this.buffer.invalidateFor(messageType);
      }),
    );
    return obs;
  }

  public notifyDataAvailable() {
    this._dataAvailable.next(null);
  }

  public get dataAvailable(): BehaviorSubject<void> {
    return this._dataAvailable;
  }
  private _dataAvailable = new BehaviorSubject(null);
}
