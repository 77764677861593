import { Injectable } from "@angular/core";
import { WebLanguage } from "../../common/language/weblanguage.service";
import { PlUserStorage } from "../baseservice/pluserstorage";
import { StringHelper } from "../helpers/string.helper";
import { BrowserStorageType } from "../storage/browser-storage-type";
import { WarningType } from "./warning-type";

@Injectable()
export class WarningProvider {
  constructor(private webLanguage: WebLanguage) {}

  public warningFor(warningType: WarningType): string {
    switch (warningType) {
      case WarningType.SessionModelActive:
        return this.activeSessionModelWarning();
      default:
        return "";
    }
  }

  private activeSessionModelWarning() {
    const sessionPreferredModel = PlUserStorage.getPreferredModelFrom(BrowserStorageType.Session);

    if (StringHelper.hasNonEmptyText(sessionPreferredModel)) {
      return this.webLanguage.preferredModelCurrentSessioNWarningMessage(sessionPreferredModel);
    }

    return "";
  }
}
