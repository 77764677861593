<div *ngIf="!valueOnly" class="dx-field">
  <div class="dx-field-label">{{ option.name }}:</div>
  <div class="dx-field-value">
    <dx-select-box
      [dataSource]="option.values"
      [(value)]="option.value"
      [disabled]="option.isEnabled === false"
      displayExpr="text"
      [dropDownOptions]="dropDownOptions"
      (onContentReady)="onContentReady($event)"
    ></dx-select-box>
  </div>
</div>
<div *ngIf="valueOnly">
  <dx-select-box
    [dataSource]="option.values"
    [(value)]="option.value"
    [disabled]="option.isEnabled === false"
    displayExpr="text"
    [dropDownOptions]="dropDownOptions"
    (onContentReady)="onContentReady($event)"
  ></dx-select-box>
</div>
