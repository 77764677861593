import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuditLogUserEntryDto } from "../../../common/models/dto/AuditLogUserEntryDto-dto";
import { EnvironmentSettings } from "../../baseservice/environment-settings";
import { PlBaseService } from "../../baseservice/plbase.service";
import { PlLoadIndicator } from "../../loadindicator/loadindicator";

@Injectable({ providedIn: "root" })
export class AuditLogsService extends PlBaseService {
  readonly endpoint = "auditing";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAllLogs(): Observable<AuditLogUserEntryDto[]> {
    return this.getData<AuditLogUserEntryDto[]>(this.endpoint);
  }
}
