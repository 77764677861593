import { EnvironmentSettings } from "../baseservice/environment-settings";
import { StringHelper } from "../helpers/string.helper";
import { BrowserStorageType } from "./browser-storage-type";
const globalVariableSpace: any = window;
const pl_hr_f3_endpointConfiguration = (globalVariableSpace || {}).pl_hr_f3_endpointConfiguration || {};

export class BrowserStorage {
  private static readonly storageTypeKey: string = "StorageType";

  private static getStorageFor(storageType: BrowserStorageType): Storage {
    switch (storageType) {
      case BrowserStorageType.Local:
        return localStorage;
      case BrowserStorageType.Session:
        return sessionStorage;
      default:
        return undefined;
    }
  }

  public static setItem(key: string, value: string) {
    this.getStorageFor(this.currentStorageType()).setItem(this.keyWithPrefix(key), value);
  }

  public static getItem(key: string): string {
    return this.getStorageFor(this.currentStorageType()).getItem(this.keyWithPrefix(key));
  }

  public static getItemFor(storageType: BrowserStorageType, key: string): string {
    return this.getStorageFor(storageType).getItem(this.keyWithPrefix(key));
  }

  public static removeItem(key: string) {
    this.getStorageFor(this.currentStorageType()).removeItem(this.keyWithPrefix(key));
  }

  public static clearStorageFrom(key: string) {
    this.getStorageFor(BrowserStorageType.Local).removeItem(this.keyWithPrefix(key));
    this.getStorageFor(BrowserStorageType.Session).removeItem(this.keyWithPrefix(key));
  }

  public static setDate(key: string, value: Date) {
    const storage = this.getStorageFor(this.currentStorageType());
    storage[this.keyWithPrefix(key)] = value;
  }

  public static getDate(key: string, defaultValue: Date): Date {
    const storage = this.getStorageFor(this.currentStorageType());
    const json = storage[this.keyWithPrefix(key)];

    if (json !== undefined && json !== null) {
      return new Date(Date.parse(json));
    }
    return defaultValue;
  }

  private static keyWithPrefix(key: string): string {
    let prefix = BrowserStorage.retrieveApplicationStoragePrefix();
    if (prefix) {
      return prefix + "_" + key;
    } else {
      return key;
    }
  }

  private static retrieveApplicationStoragePrefix() {
    var envSettings = new EnvironmentSettings();

    let prefix = envSettings.appkey;
    if (envSettings.virtualPath && envSettings.virtualPath.length > 0) {
      const virtualAppPrefix = StringHelper.onlyAlfaNumericReplaceWith(envSettings.virtualPath, "_");
      prefix = virtualAppPrefix + "_" + prefix;
    }
    return prefix;
  }

  public static currentStorageType(): BrowserStorageType {
    const storageTypeFromSession = this.getStorageFor(BrowserStorageType.Session).getItem(BrowserStorage.storageTypeKey);

    if (StringHelper.hasNonEmptyText(storageTypeFromSession)) {
      return BrowserStorageType.Session;
    }

    return BrowserStorageType.Local;
  }

  public static clearSessionStorage() {
    if (this.currentStorageType() === BrowserStorageType.Session) {
      sessionStorage.clear();
    }
  }

  public static setSessionStorage() {
    if (this.currentStorageType() === BrowserStorageType.Local) {
      this.getStorageFor(BrowserStorageType.Session).setItem(BrowserStorage.storageTypeKey, BrowserStorageType.Session);
      this.copyLocalStorageToSession();
    }
  }

  private static copyLocalStorageToSession() {
    const keys = Object.keys(localStorage);

    keys.forEach((key) => {
      const value = localStorage.getItem(key);
      if (value !== null) {
        sessionStorage.setItem(key, value);
      }
    });
  }
}
