import { SelectableItemsBase } from "../selectionbase/selectable-items-base";
import { Sorter } from "../sorting/sorter";
import { Tab } from "./tab";

export class Tabs extends SelectableItemsBase<Tab> {
  public get selectedTabKeys(): number[] {
    return this._selectedTabKeys;
  }
  public set selectedTabKeys(newKeys: number[]) {
    this._selectedTabKeys = newKeys;
    this.doSelectedItemChanged(this.selectedTab);
  }
  private _selectedTabKeys: number[] = [];

  public get selectedTab(): Tab {
    let result: Tab = null;
    this.items.forEach(t => {
      if (this.selectedTabKeys.indexOf(t.id) >= 0) {
        result = t;
      }
    });

    return result;
  }
  public set selectedTab(tab: Tab) {
    if (!tab || tab === null) {
      this.selectedTabKeys = [];
      return;
    }

    if (this.selectedTabKeys.length === 0) {
      this.selectedTabKeys.push(tab.id);
    } else {
      this.selectedTabKeys[0] = tab.id;
    }
  }

  public get tabKeyExpr(): string {
    return "id";
  }

  public addNew(id: number, text: string, icon: string, content: any): Tab {
    const result = new Tab(id, text);
    result.icon = icon;
    result.content = content;
    this.addItem(result);
    return result;
  }

  public sort() {
    this.items.sort(Sorter.compareSortOrder);
  }

  public setSortOrder(tab: Tab, newSortOrder: number) {
    tab.sortOrder = newSortOrder;
    this.sort();
  }
}
