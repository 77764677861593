import { BrowserStorage } from "../../storage/browser-storage";

const externalLoginRoundTripKey = "externalLoginAutoRedirectInProgress";
const externalLoginRoundTripActiveValue = "roundtrip";
const externalLoginRoundTripBlockValue = "do-not-roundtrip";

export class LoginStorageSettings {
  public static get externalLoginRoundTripRunning() {
    return this.externalProgressValueIsValid(externalLoginRoundTripActiveValue);
  }

  public static get externalLoginRoundTripWait() {
    return this.externalProgressValueIsValid(externalLoginRoundTripBlockValue);
  }

  private static externalProgressValueIsValid(value: string) {
    if (BrowserStorage.getItem(externalLoginRoundTripKey) === value) {
      return true;
    }

    return false;
  }

  public static get externalLoginAutoRedirectEnabled() {
    if (this.externalLoginRoundTripRunning) {
      return false;
    }
    if (this.externalLoginRoundTripWait) {
      return false;
    }
    return true;
  }

  public static markExternalLoginRoundTripRunning() {
    BrowserStorage.setItem(externalLoginRoundTripKey, externalLoginRoundTripActiveValue);
  }

  public static markExternalLoginWaitForRoundTripRunning() {
    BrowserStorage.setItem(externalLoginRoundTripKey, externalLoginRoundTripBlockValue);
  }

  public static clearExternalLoginRoundTripRunning() {
    console.log("clear round trip marker; succesfull login");
    BrowserStorage.removeItem(externalLoginRoundTripKey);
  }
}
